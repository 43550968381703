<template>
  <div class="guardian-vacation-request-container">
    <h2 v-if="!isMobile">
      {{ 'PRESENCE_REPORT_VACATION_REQUEST' | fromTextKey }}
    </h2>
    <div v-else class="mobile-box-title mb-3">
      {{ 'PRESENCE_REPORT_VACATION_REQUEST' | fromTextKey }}
      <help-box
        id="vacation-registration-help-box"
        :title="$options.filters.fromTextKey('PRESENCE_REPORT_VACATION_REQUEST')"
        :body="$options.filters.fromTextKey('PRESENCE_VACATION_REQUEST_DESCRIPTION_1')"
      />
    </div>
    <div v-if="!isMobile" class="mb-3">
      {{ 'PRESENCE_VACATION_REQUEST_DESCRIPTION_1' | fromTextKey }}
    </div>
    <aula-spinner v-if="isLoading" />
    <template v-else-if="splitVacationRegistrationData.length > 0">
      <div
        v-for="(subVacationRegistrations, i) in splitVacationRegistrationData"
        :key="i"
        class="table"
        :class="{ 'w-50': subVacationRegistrations.length === 1 && !isMobile }"
      >
        <div class="table-row header">
          <div v-for="(childData, j) in subVacationRegistrations" :key="j" class="table-cell">
            <div v-if="childData.child" class="d-flex align-items-center">
              <div>
                <user-icon
                  :id="childData.child.id"
                  :name="childData.child.name"
                  :short-name="childData.child.shortName"
                  :picture-object="childData.child.profilePicture"
                  user-icon-class="small"
                  :show-inline="true"
                />
              </div>
              <div class="ml-3 flex-grow-1">
                {{ childData.child.name }}
                <span class="font-weight-normal">({{ childData.child.metadata }})</span>
              </div>
            </div>
            <hr v-if="isMobile" />
          </div>
        </div>
        <div
          v-for="(index, j) in getNumberOfRowsForSubGroupTable(subVacationRegistrations)"
          :key="j"
          class="table-row body"
        >
          <div
            v-for="(childData, k) in subVacationRegistrations"
            :key="k"
            class="table-cell"
            :tabindex="childData.vacationRegistrations[index - 1] ? 0 : -1"
            :class="{
              'pointer-cursor': childData.vacationRegistrations[index - 1],
            }"
            @keyup.enter="showVacationRegistration(childData.vacationRegistrations[index - 1], childData.child, true)"
            @click="showVacationRegistration(childData.vacationRegistrations[index - 1], childData.child, true)"
          >
            <div v-if="childData.vacationRegistrations[index - 1]" class="d-flex justify-content-between">
              <div>
                <div class="word-wrap mt-1 mb-1 mt-lg-2 mb-lg-3">
                  <b>{{ childData.vacationRegistrations[index - 1].title }}</b>
                </div>
                <div class="d-flex">
                  <div class="absence-label">{{ 'PRESENCE_REPORT_PERIOD' | fromTextKey }}:</div>
                  {{
                    getFormatDatePeriod(
                      childData.vacationRegistrations[index - 1].startDate,
                      childData.vacationRegistrations[index - 1].endDate
                    )
                  }}
                </div>
                <div v-if="childData.vacationRegistrations[index - 1].responseDeadline" class="d-flex">
                  <div class="absence-label">{{ 'PRESENCE_REPORT_DEADLINE' | fromTextKey }}:</div>
                  {{ convertDateFormat(childData.vacationRegistrations[index - 1].responseDeadline) }}
                </div>
                <div v-if="childData.vacationRegistrations[index - 1].noteToGuardian" class="word-wrap d-flex">
                  <div class="absence-label">{{ 'PRESENCE_COMMENT' | fromTextKey }}:</div>
                  {{ childData.vacationRegistrations[index - 1].noteToGuardian }}
                </div>
                <div v-if="childData.vacationRegistrations[index - 1].isMissingAnswer" class="missing-answer-badge">
                  <i class="icon-Aula_bell" />
                  {{ 'CALENDAR_MISSING_RESPOND_TITLE' | fromTextKey }}
                </div>
              </div>
              <i v-if="isMobile" class="icon icon-Aula_forward-arrow align-self-center" />
              <template v-else-if="canEdit(childData.child.id, childData.vacationRegistrations[index - 1])">
                <b-btn
                  variant="link"
                  class="edit-button mt-lg-3"
                  :aria-label="'ARIA_LABEL_EDIT' | fromTextKey"
                  @keyup.enter.stop="
                    showVacationRegistration(childData.vacationRegistrations[index - 1], childData.child, false)
                  "
                  @click.stop="
                    showVacationRegistration(childData.vacationRegistrations[index - 1], childData.child, false)
                  "
                >
                  <i class="icon icon-Aula_edit_pen" />
                </b-btn>
              </template>
            </div>
            <hr v-if="isMobile" />
          </div>
        </div>
      </div>
    </template>
    <div v-else class="d-flex my-3 my-lg-0 font-weight-bold">
      {{ 'PRESENCE_WARNING_VACATION_REGISTRATIONS_NO_RESULTS' | fromTextKey }}
    </div>
    <register-vacation-request-drawer ref="registerVacationRequestDrawer" @onClosed="loadData" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { types } from '../../store/types/types';
import RegisterVacationRequestDrawer from './RegisterVacationRequestDrawer';
import dateTimeFormatMixin from '../../../shared/mixins/dateTimeFormatMixin';
import dataFormat from '../../../shared/mixins/dataFormatMixin';
import { VacationRegistration } from '../../business/vacationRegistration';
import { isTodayOrAfter } from '../../../shared/utils/dateUtil';

export default {
  components: { RegisterVacationRequestDrawer },
  mixins: [dateTimeFormatMixin, dataFormat],
  data: function () {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
      activeChildren: types.GET_ACTIVE_CHILDREN,
      vacationRegistrationList: types.GET_VACATION_REGISTRATIONS_BY_CHILDREN,
      notifications: types.GET_NOTIFICATIONS,
      hasCustodyOrExtendedAccess: types.GET_HAS_CUSTODY_OR_EXTENDED_ACCESS,
    }),
    splitVacationRegistrationData() {
      const chunk = this.isMobile ? 1 : 2;
      return this.splitDataToChunks(this.vacationRegistrationList, chunk);
    },
  },
  methods: {
    ...mapActions({
      loadVacationRegistrations: types.LOAD_VACATION_REGISTRATIONS_BY_CHILDREN,
    }),
    getNumberOfRowsForSubGroupTable(subGroups) {
      const groupLengthArr = subGroups.map(item => item.vacationRegistrations.length);
      return Math.max(...groupLengthArr);
    },
    showVacationRegistration(vacationRequest, child, isReadonly = true) {
      if (vacationRequest) {
        const vacationRegistrationRequest = new VacationRegistration(vacationRequest);
        this.$refs.registerVacationRequestDrawer.show(vacationRegistrationRequest, child, isReadonly);
      }
    },
    loadData() {
      this.loadVacationRegistrations({ childIds: this.activeChildren }).then(() => {
        this.isLoading = false;
      });
    },
    canEdit(childId, vacationRegistration) {
      return (
        vacationRegistration.isEditable &&
        this.hasCustodyOrExtendedAccess(childId) &&
        isTodayOrAfter(vacationRegistration.responseDeadline)
      );
    },
  },
  watch: {
    notifications: {
      handler() {
        this.loadData();
      },
      deep: true,
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/presence/_presence.scss';
</style>
