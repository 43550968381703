<template>
  <b-card
    class="search-result-item margin-bottom-20"
    tabindex="0"
    @keypress.enter="openUser(result.id)"
    @click="openUser(result.id)"
  >
    <div class="profile-picture-container">
      <Avatar class="profile-picture" :profile="result" />
    </div>
    <div class="search-result-text-col">
      <template v-if="result.portalRole === portalRoles.CHILD || result.portalRole === portalRoles.GUARDIAN">
        <div v-if="result.portalRole === portalRoles.CHILD" class="card-header-text">
          <keyword-highlight :queries="searchString">
            {{ result | displayProfileNameWithMetadata }}
          </keyword-highlight>
        </div>
        <div v-if="result.portalRole === portalRoles.GUARDIAN" class="card-header-text">
          <keyword-highlight :queries="searchString">
            {{ result | displayProfileNameWithMetadata }}
          </keyword-highlight>
          - {{ 'SEARCH_RESULT_PROFILE_GUARDIAN_ROLE' | fromTextKey }}
        </div>
        <div class="card-text">
          {{ result.institutionName }}
        </div>
      </template>
      <template v-if="result.portalRole === portalRoles.EMPLOYEE">
        <div class="card-header-text">
          <keyword-highlight :queries="searchString">
            {{ result | displayProfileNameWithMetadata }}
          </keyword-highlight>
        </div>
        <div class="card-text">
          {{ result.institutionName }}
        </div>
      </template>
    </div>
  </b-card>
</template>

<script>
import { mapMutations } from 'vuex';
import { types } from '../../store/types/types';
import KeywordHighlight from 'vue-text-highlight';
import { institutionRole } from '../../../shared/enums/institutionRole';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import Avatar from '../../../shared/components/Avatar';

export default {
  props: {
    result: { type: Object, default: () => {} },
    parent: { type: String, default: 'profile' },
    searchString: { type: String, default: null },
  },
  data: function () {
    return {
      portalRoles: portalRoles,
      institutionRole: institutionRole,
    };
  },
  methods: {
    ...mapMutations({
      setPrefilledNewEmailContact: types.MUTATE_PREFILLED_NEW_EMAIL_CONTACT,
    }),
    openUser(userId) {
      if (this.parent === 'profile') {
        this.$router.push({
          name: 'userview',
          params: { id: userId },
          query: { parent: parentTypes.SEARCH },
        });
      }
    },
    sendEmail(user) {
      this.setPrefilledNewEmailContact(user);
      this.$router.push({ path: 'beskeder/opret' });
    },
  },
  components: {
    Avatar,
    KeywordHighlight,
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.search-result-item {
  .profile-picture-container {
    background-color: var(--color-primary-dark);
    display: flex;
    width: 130px;
    align-items: center;
  }

  .profile-picture {
    --width: 100%;
    --height: 130px;
    --border-radius: unset;
  }
}
.card-text {
  text-transform: capitalize;
}

.contact-info-icon {
  background-color: $color-grey-base;
  text-align: center;
  border-radius: 50%;
  display: inline;
  &:hover {
    background-color: $color-white;
  }
  &.phone {
    padding: 8px 12px;
    font-size: 1.5em;
  }
  &.email {
    padding: 12px 10px;
    font-size: 1em;
  }
}
</style>
