<template>
  <b-form-checkbox
    v-sr-only:pre="receiveEmailAriaText"
    v-sr-only="selectedOnOffAriaText"
    :checked="checked"
    :disabled="isViewMode"
    :class="isViewMode ? 'no-disabled-style' : ''"
    :aria-label="checkBoxAriaText"
    @change="emitChange"
  >
    {{ textKeyName | fromTextKey }}
  </b-form-checkbox>
</template>

<script>
import srOnly from '../../directives/sr-only';

export default {
  directives: {
    srOnly,
  },
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    id: { type: String, required: true },
    checked: { type: Boolean, required: true },
    isViewMode: { type: Boolean, default: false },
    textKeyName: { type: String, required: true },
  },
  data: function () {
    return {
      currentValue: this.checked,
    };
  },
  computed: {
    checkBoxAriaText() {
      const ariaTexts = [this.receiveEmailAriaText, this.$options.filters.fromTextKey(this.textKeyName)];
      return ariaTexts.join(' ');
    },
    receiveEmailAriaText() {
      const ariaTexts = [
        this.$options.filters.fromTextKey('ARIA_NOTIFICATION_RECEIVE_EMAIL'),
        this.$options.filters.fromTextKey('ARIA_NOTIFICATION_EVERY'),
      ];
      return ariaTexts.join(' ');
    },
    selectedOnOffAriaText() {
      return this.currentValue
        ? this.$options.filters.fromTextKey('ARIA_NOTIFICATION_SETTINGS_SELECTED_ON')
        : this.$options.filters.fromTextKey('ARIA_NOTIFICATION_SETTINGS_SELECTED_OFF');
    },
  },
  methods: {
    emitChange(value) {
      this.currentValue = value;
      return this.$emit('change', value);
    },
  },
};
</script>
