<template>
  <div class="download-toolbar">
    <div class="download-info-container">
      <i class="icon-Aula_check" />{{ amountOfSelected }}
      {{ amountText | fromTextKey }}
      <b-btn
        variant="primary"
        :disabled="amountOfSelected == 0"
        class="btn-download-album ml-3"
        @click="activateDownload"
      >
        <i class="icon-Aula_download mr-2" />{{ 'GALLERY_TOOLBAR_DOWNLOAD' | fromTextKey }}
        <aula-spinner v-if="isDownloading" />
      </b-btn>
    </div>
    <i
      class="icon-Aula_close pull-right"
      tabindex="0"
      role="button"
      :aria-label="'ARIA_LABEL_CLOSE' | fromTextKey"
      @click="cancelDownload"
      @keydown.enter="cancelDownload"
    />
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  props: {
    amountOfSelected: { type: Number, default: 0 },
    isDownloading: { type: Boolean, default: false },
    amountText: {
      type: String,
      default: 'GALLERY_TOOLBAR_DOWNLOAD_MEDIA_AMOUNT',
    },
  },
  mounted() {
    window.addEventListener('scroll', this.moveDownloadBarOnScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.moveDownloadBarOnScroll);
  },
  methods: {
    cancelDownload() {
      this.$emit('cancelDownload');
    },
    activateDownload() {
      this.$emit('activateDownload');
    },
    moveDownloadBarOnScroll() {
      const distanceY = window.pageYOffset || document.documentElement.scrollTop;
      const shrinkOn = 100;
      if (distanceY > shrinkOn) {
        $('.download-info-container').addClass('to-top');
      } else {
        $('.download-info-container').removeClass('to-top');
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/gallery/gallery';
</style>
