<template>
  <div class="search-result-container page-layout-padding" :class="getStatusSearchBar ? 'active' : ''">
    <div
      v-if="searchString != ''"
      class="search-result-header-mobile search-result-header-mobile-variables"
      :class="searchResultHeaderMobileClasses"
    >
      <b-row class="row-no-margin">
        <b-col cols="8">
          {{ 'SEARCH_RESULT_MOBILE_LABEL' | fromTextKey }}<strong>{{ searchString }} </strong>
        </b-col>
        <b-col v-if="showResult === docTypes.PROFILE" cols="4">
          <dropdown-filter-portal-roles />
        </b-col>
        <b-col v-if="showResult === docTypes.GROUP" cols="4">
          <dropdown-filter-group-types />
        </b-col>
      </b-row>
    </div>

    <template v-if="searchString != ''">
      <div class="search-result-header" tabindex="-1">
        <h1>{{ 'SEARCH_RESULT_LABEL' | fromTextKey }}</h1>
        <span class="keysearch">{{ searchString }}</span>
      </div>
      <div v-if="!isLoading && searchResults.length === 0" class="search-result-label">
        <p>{{ 'SEARCH_RESULT_NO_RESULT' | fromTextKey }}</p>
      </div>
    </template>
    <template v-else>
      <div class="search-result-header">
        <h1>{{ 'SEARCH_RESULT_NO_SEARCH_TERM_HEADER' | fromTextKey }}</h1>
      </div>
      <div class="search-result-label">
        <p>{{ 'SEARCH_RESULT_NO_SEARCH_TERM_BODY' | fromTextKey }}</p>
      </div>
    </template>
    <ObservingContainer class="search-results" :options="observerOptions">
      <div v-for="(result, index) in searchResults" :key="index">
        <search-result-group-item
          v-if="result.docType === docTypes.GROUP && result.status != 'inactive'"
          :is-child="isChild"
          :search-string="searchString"
          :result="result"
        />
        <search-result-message-item
          v-if="result.docType === docTypes.THREAD_MESSAGE"
          :is-child="isChild"
          :search-string="searchString"
          :result="result"
        />
        <search-result-document-item
          v-if="
            result.docType === docTypes.INTERNAL_SECURE_FILE ||
            result.docType === docTypes.EXTERNAL_SECURE_FILE ||
            result.docType === docTypes.IMPORTANT_FILE
          "
          :is-child="isChild"
          :search-string="searchString"
          :result="result"
        />
        <search-result-profile-item
          v-if="result.docType === docTypes.PROFILE"
          :search-string="searchString"
          :result="result"
        />
        <search-result-event-item
          v-if="result.docType === docTypes.EVENT"
          :is-child="isChild"
          :event-item="result"
          :search-string="searchString"
        />
        <search-result-post-item
          v-if="result.docType === docTypes.POST"
          :is-child="isChild"
          :post="result"
          :search-string="searchString"
        />
        <search-result-media-item
          v-if="result.docType === docTypes.MEDIA"
          :media="result"
          :search-string="searchString"
          @openMedia="openMedia"
        />
      </div>
      <aula-spinner v-if="isLoading" />
      <ObservedTarget v-if="searchResults.length > 0" @onIntersecting="onBottomReached" />
    </ObservingContainer>
    <media-detail
      v-if="
        showResult == docTypes.MEDIA &&
        ($route.path.includes('soeg/medie') || $route.name == 'group-search-media-detail')
      "
      :key="'profile-' + index"
      :album="mediaDetail"
      :parent="parentTypes.SEARCH"
      @tagsUpdated="tagsUpdated"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { types } from '../../store/types/types';
import SearchResultEventItem from './SearchResultEventItem';
import SearchResultProfileItem from './SearchResultProfileItem';
import SearchResultDocumentItem from './SearchResultDocumentItem';
import SearchResultMessageItem from './SearchResultMessageItem';
import SearchResultGroupItem from './SearchResultGroupItem';
import SearchResultPostItem from './SearchResultPostItem';
import SearchResultMediaItem from './SearchResultMediaItem';
import MediaDetail from '../gallery/MediaDetail';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import DropdownFilterPortalRoles from './DropdownFilterPortalRoles';
import { groupAccessTypes } from '../../../shared/enums/groupAccessTypes';
import { groupMembershipRoles } from '../../../shared/enums/groupMembershipRoles';
import { institutionRole } from '../../../shared/enums/institutionRole';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { docTypes } from '../../../shared/enums/docTypes';
import { groupTypes } from '../../../shared/enums/groupTypes';
import DropdownFilterGroupTypes from './DropdownFilterGroupTypes';
import { messageOwnerTypes } from '../../../shared/enums/messageOwnerTypes';
import clone from 'lodash/clone';
import ObservingContainer from '../../../shared/libs/intersection-observer/components/Container.vue';
import ObservedTarget from '../../../shared/libs/intersection-observer/components/Target.vue';

export default {
  data: function () {
    return {
      groupAccessTypes: groupAccessTypes,
      messageOwnerTypes: messageOwnerTypes,
      parentTypes: parentTypes,
      groupMembershipRoles: groupMembershipRoles,
      institutionRole: institutionRole,
      portalRoles: portalRoles,
      docTypes: docTypes,
      groupTypes: groupTypes,
      joiningGroupName: '',
      mediaDetail: [],
      showSubresults: [portalRoles.CHILD, portalRoles.GUARDIAN, portalRoles.EMPLOYEE],
      aulaGroupTypes: [groupTypes.INSTITUTIONAL, groupTypes.MUNICIPAL],
      isGroupButtonDisabled: false,
    };
  },
  computed: {
    ...mapGetters({
      institutions: types.GET_INSTITUTIONS,
      searchResults: types.GET_GLOBAL_SEARCH_RESULTS,
      profile: types.GET_CURRENT_PROFILE,
      groups: types.GET_USER_GROUPS,
      searchString: types.GET_SEARCH_STRING,
      showResult: types.GET_SHOW_RESULT,
      showSearchDropdown: types.GET_SHOW_SEARCH_DROPDOWN,
      searchOffset: types.GET_SEARCH_OFFSET,
      numberOfResults: types.GET_NUMBER_OF_RESULTS,
      hasMoreResults: types.GET_LOAD_MORE,
      getStatusSearchBar: types.GET_STATUS_SEARCH_BAR,
      userGroups: types.GET_USER_GROUPS,
      isLoading: types.GET_SEARCH_LOADING_STATE,
    }),
    observerOptions() {
      return { marginRoot: '50%' };
    },
    searchResultHeaderMobileClasses() {
      const classes = [];
      if (this.showSearchDropdown && this.numberOfResults.countTotal > 0) {
        classes.push('with-search-and-filter');
      } else if (this.showSearchDropdown) {
        classes.push('with-search');
      }
      return classes;
    },
    isChild() {
      return this.profile.institutionRoles.includes(this.institutionRole.EARLY_STUDENT);
    },
  },
  methods: {
    ...mapMutations({
      setLoadingState: types.MUTATE_SEARCH_LOADING_STATE,
    }),
    onBottomReached() {
      if (this.isLoading || !this.hasMoreResults) {
        return;
      }
      this.loadMoreSearchResults();
    },
    tagsUpdated(tags) {
      for (const res of this.searchResults) {
        if (res.id == this.mediaDetail[0].id && res.docType == docTypes.MEDIA) {
          res.tags = tags;
        }
      }
    },
    openMedia(originalMedia) {
      const media = clone(originalMedia);
      media.album = { name: media.albumTitle, id: media.albumId };
      media.fromSearch = true;
      this.mediaDetail = [media];
      this.$router.push({ name: 'search-media-detail' });
    },
    loadMoreSearchResults() {
      this.setLoadingState(true);
      this.loadMoreResults().then(() => {
        this.setLoadingState(false);
      });
    },
    ...mapActions({
      loadMoreResults: types.LOAD_MORE_GLOBAL_RESULTS,
    }),
  },
  mounted() {
    setTimeout(function () {
      document.querySelector('.search-result-container .search-result-header').focus();
    }, 500);
  },
  components: {
    ObservedTarget,
    ObservingContainer,
    DropdownFilterGroupTypes,
    SearchResultEventItem,
    SearchResultProfileItem,
    DropdownFilterPortalRoles,
    SearchResultPostItem,
    SearchResultDocumentItem,
    SearchResultMediaItem,
    SearchResultMessageItem,
    SearchResultGroupItem,
    MediaDetail,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.search-results {
  @include breakpoint-lg-down() {
    padding-bottom: var(--menu-item-height);
  }
}

.margin-top-120 {
  width: 100%;
  margin: 120px 0 0 0;
}

.search-result-container /deep/ .menu-icon {
  position: relative;
  top: -12px;
  max-width: 80%;
}
</style>
