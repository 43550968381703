<template>
  <div v-click-outside="resetRenameFolder" class="folders">
    <a
      v-for="folder in folders"
      :key="folder.id"
      class="dropdown-item dropdown-folder"
      :class="{
        active: currentRenameFolderId !== folder.id && chosenFolderAndMailOwner.folderId === folder.id,
        common: folder.type === messageFiltersEnum.FOLDER_TYPE_COMMON,
      }"
      tabindex="0"
      @click="selectFolder(folder)"
      @keydown.enter="selectFolder(folder)"
    >
      <div v-if="folder.unread >= 1" class="new-content-icon" aria-hidden="true">{{ folder.unread }}</div>
      <b-form-input
        v-if="currentRenameFolderId === folder.id"
        ref="inputRenameFolder"
        v-model="newFolderName"
        class="rename-folder"
        size="sm"
        maxlength="100"
        :class="{
          'red-border': newFolderName === '' || newFolderName.length > maxFolderNameLength,
        }"
        :aria-label="'MESSAGE_TOOLBAR_CREATE_NEW_FOLDER_INPUT' | fromTextKey"
        :placeholder="'MESSAGE_TOOLBAR_CREATE_NEW_FOLDER_INPUT' | fromTextKey"
        @keyup.enter="updateFolderName(folder)"
      />
      <div v-else class="folder-container">
        <i class="icon" :class="getFolderIcon(folder.type)" aria-hidden="true" />
        <span v-b-tooltip.hover :title="folder.name" class="folder-title">{{ folder.name }}</span>
        <badge-notification aria-hidden="true" :amount="calculateAmountOfBadges(folder.id)" />
      </div>
      <div class="folder-options" :class="{ 'is-editing': currentRenameFolderId === folder.id }">
        <b-btn
          v-if="currentRenameFolderId === folder.id"
          variant="link"
          class="mr-2"
          :aria-label="'MESSAGE_TOOLBAR_UPDATE_MODAL_TITLE' | fromTextKey"
          @click.stop="updateFolderName(folder)"
          @keydown.enter="updateFolderName(folder)"
        >
          {{ 'BUTTON_SAVE' | fromTextKey }}
        </b-btn>
        <template v-else-if="currentRenameFolderId !== folder.id && folder.type === folderTypes.NORMAL">
          <b-btn
            class="mr-2"
            variant="link"
            :aria-label="'MESSAGE_TOOLBAR_UPDATE_MODAL_TITLE' | fromTextKey"
            @click.stop="renameFolder(folder)"
            @keydown.enter="renameFolder(folder)"
          >
            <i class="icon-Aula_edit_pen" aria-hidden="true" />
          </b-btn>
          <b-btn
            variant="link"
            :aria-label="'MESSAGE_TOOLBAR_DELETE_MODAL_TITLE' | fromTextKey"
            @click.stop="showDeleteFolderModal(folder.id)"
            @keydown.enter="showDeleteFolderModal(folder.id)"
          >
            <i class="icon-Aula_bin" aria-hidden="true" />
          </b-btn>
        </template>
      </div>
    </a>
    <aula-modal
      ref="modalConfirmDeleteFolder"
      header-text="MESSAGE_TOOLBAR_DELETE_MODAL_TITLE"
      ok-text="MESSAGE_TOOLBAR_DELETE_MODAL_YES"
      @cancelClicked="$refs.modalConfirmDeleteFolder.hide()"
      @okClicked="deleteFolder"
    >
      <p>{{ 'MESSAGE_TOOLBAR_DELETE_FOLDER_TEXT1' | fromTextKey }}</p>
      <p>{{ 'MESSAGE_TOOLBAR_DELETE_FOLDER_TEXT2' | fromTextKey }}</p>
    </aula-modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { types } from '../../store/types/types';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import { messageFiltersEnum } from '../../../shared/enums/messageFiltersEnum';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { messageOwnerTypes } from '../../../shared/enums/messageOwnerTypes';
import { notificationAreas } from '../../../shared/enums/notificationAreas';
import BadgeNotification from '../../../shared/components/BadgeNotification.vue';
import { folderTypes } from '../../../shared/enums/folderTypes';

export default {
  props: {
    folders: { type: Array, default: () => [] },
    commonInboxFolder: { type: Boolean, default: false },
    commonInboxId: { type: Number, default: null },
  },
  data: function () {
    return {
      folderTypes: folderTypes,
      tempFolderId: '',
      newFolder: '',
      showFolderCreate: false,
      createNewMessagePath: '/beskeder/opret',
      portalRoles: portalRoles,
      permissionEnum: permissionEnum,
      notificationAreas: notificationAreas,
      messageFiltersEnum: messageFiltersEnum,
      messageOwnerTypes: messageOwnerTypes,
      collapseFolders: false,
      currentRenameFolderId: null,
      newFolderName: null,
      maxFolderNameLength: 100,
    };
  },
  computed: {
    ...mapGetters({
      notifications: types.GET_NOTIFICATIONS,
      chosenFolderAndMailOwner: types.MESSAGES_GET_CHOSEN_FOLDER_AND_MAIL_OWNER,
      commonInboxes: types.MESSAGES_GET_COMMON_INBOXES,
    }),
  },
  methods: {
    ...mapActions({
      deleteFolderAction: types.DELETE_THREADS_FOLDER,
      changeFolderName: types.CHANGE_FOLDER_NAME,
      deleteNotifications: types.DELETE_NOTIFICATIONS,
    }),
    ...mapMutations({
      setChosenFolderAndMailOwner: types.MUTATE_CHOSEN_FOLDER_AND_MAIL_OWNER,
      showErrorText: types.MUTATE_ERROR_TEXT,
    }),
    getFolderIcon(folderType) {
      return folderType === folderTypes.NORMAL ? 'icon-Aula_folder' : 'icon-Aula_bin';
    },
    selectFolder(folder) {
      if (this.currentRenameFolderId !== folder.id) {
        this.resetRenameFolder();
        folder.commonInboxId = this.commonInboxId;
        this.$emit('selectFolder', folder, !this.commonInboxFolder);
      }
    },
    deleteFolderBadges(folderId) {
      const notifications = [];
      for (const notification of this.notifications) {
        if (
          notification.notificationArea === this.notificationAreas.MESSAGES &&
          notification.folderId === String(folderId)
        ) {
          notifications.push({
            notificationId: notification.notificationId,
            institutionProfileId: notification.institutionProfileId,
          });
        }
      }
      if (notifications.length > 0) {
        this.deleteNotifications({ notifications: notifications });
      }
    },
    updateFolderName(folder) {
      if (
        this.newFolderName !== '' &&
        this.currentRenameFolderId === folder.id &&
        this.newFolderName.length <= this.maxFolderNameLength
      ) {
        const folder = {
          folderId: this.currentRenameFolderId,
          folderName: this.newFolderName,
          isCommonInboxFolder: this.commonInboxFolder,
          commonInboxId: this.commonInboxId,
        };
        this.changeFolderName(folder).then(() => {
          this.resetRenameFolder();
        });
      } else {
        this.showErrorText('MESSAGE_TOOLBAR_INVALID_NAME');
      }
    },
    renameFolder(folder) {
      this.newFolderName = folder.name;
      this.currentRenameFolderId = folder.id;
    },
    resetRenameFolder() {
      this.currentRenameFolderId = null;
      this.newFolderName = null;
    },
    showDeleteFolderModal(folderId) {
      this.tempFolderId = folderId;
      this.$refs.modalConfirmDeleteFolder.show();
    },
    deleteFolder() {
      this.deleteFolderAction({
        id: this.tempFolderId,
        isCommonInboxFolder: this.commonInboxFolder,
        commonInboxId: this.commonInboxId,
      }).then(() => {
        this.deleteFolderBadges(this.tempFolderId);
      });
      if (this.chosenFolderAndMailOwner.folderId === this.tempFolderId) {
        if (this.chosenFolderAndMailOwner.mailOwnerType === messageOwnerTypes.COMMON_INBOX) {
          this.chosenFolderAndMailOwner.folderId = null;
          this.chosenFolderAndMailOwner.folderName = this.commonInboxes.find(
            c => c.id === this.chosenFolderAndMailOwner.mailOwnerId
          ).name;
        } else {
          this.chosenFolderAndMailOwner.folderId = messageFiltersEnum.DEFAULT_FOLDER;
          this.chosenFolderAndMailOwner.folderName = null;
        }
        this.setChosenFolderAndMailOwner(this.chosenFolderAndMailOwner);
        this.$emit('updateThreads');
      }
      this.$refs.modalConfirmDeleteFolder.hide();
    },
    closeFolderMenu() {
      this.resetRenameFolder();
      this.$emit('closeFolderMenu');
    },
    calculateAmountOfBadges(folderId) {
      return this.notifications.filter(
        n => n.notificationArea === this.notificationAreas.MESSAGES && n.folderId === folderId
      ).length;
    },
  },
  components: {
    BadgeNotification,
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';

.dropdown-item {
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  &.dropdown-folder {
    padding-left: 2.25rem;
  }

  &.active {
    font-weight: bold;

    &.date-DESC:before,
    &.from-DESC:before,
    &.subject-DESC:before {
      content: '\2191';
    }

    &.date-ASC:before,
    &.from-ASC:before,
    &.subject-ASC:before {
      content: '\2193';
    }
  }

  .folder-container {
    display: flex;
    align-items: center;
    gap: 6px;
    .badge-notification {
      --position: relative;
    }
  }

  .folder-title {
    max-width: 175px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .folder-options {
    margin-top: 2px;
    margin-right: 10px;
    font-size: 15px;
    float: right;
    &.is-editing {
      margin-top: 18px;
    }
  }

  .rename-folder {
    width: 80%;
    float: left;
    margin-left: -25px;
    margin-top: 5px;
    border: 1px solid $color-form-border;
    padding: 8px 9px;
    &:focus-visible {
    }
  }
}
</style>
