<template>
  <div class="aula-widget-placeholder page-layout-padding">
    <template v-if="widgetData.type === widgetTypesEnum.SSO">
      <widget-link
        placement="full"
        :supplier="widgetData.widgetSupplier"
        :icon="widgetData.icon"
        :s-s-oaction="widgetData.url"
        :widget-title="widgetData.name"
        widget-type="SSO"
        :widget-i-d="widgetData.widgetId"
        :widget-version="widgetData.widgetVersion"
        :badge-count="getWidgetNotificationCount(widgetData)"
      />
    </template>
    <template v-else>
      <widget-content
        placement="full"
        :name="widgetData.name"
        :supplier="widgetData.widgetSupplier"
        :restricted-groups="widgetData.restrictedGroups"
        :widget-type="widgetData.type"
        :widget-i-d="widgetData.widgetId"
        :widget-version="widgetData.widgetVersion"
        :widget-url="widgetData.url"
        :badge-count="getWidgetNotificationCount(widgetData)"
        :notifications="getWidgetNotifications(widgetData)"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { types } from '../../store/types/types';
import WidgetContent from './WidgetContent';
import WidgetLink from './WidgetLink';
import { moduleWidgetPlacements } from '../../../shared/enums/moduleWidgetPlacements';
import { widgetTypes } from '../../../shared/enums/widgetTypes';
import {widgetUtil} from "../../../shared/utils/widgetUtil";

export default {
  components: {
    WidgetContent,
    WidgetLink,
  },
  data: function () {
    return {
      widgetTypesEnum: widgetTypes,
    };
  },
  computed: {
    ...mapGetters({
      menuItems: types.GET_MENU_ITEMS,
      notifications: types.GET_NOTIFICATIONS,
    }),
    widgetData() {
      if (this.menuItems) {
        const widget = this.menuItems[moduleWidgetPlacements.OWN_PAGE].filter(
          w => 'W' + w.widgetId + 'V' + w.widgetVersion == this.$route.params.id
        );
        if (widget.length > 0) {
          widget[0].component = this.$route.params.id;
          return widget[0];
        }
      }
      return {};
    },
  },
  methods: {
    getWidgetNotificationCount(widget) {
      return this.getWidgetNotifications(widget).length;
    },
    getWidgetNotifications(widget) {
      return widgetUtil.getNotifications(this.notifications, {widgetId: widget.id});
    },
  }
};
</script>
