const dataFormat = {
  methods: {
    splitDataToChunks(data, chunkSize) {
      const splitList = [];

      if (!Array.isArray(data)) {
        return [];
      }

      for (let i = 0; i < data.length; i += chunkSize) {
        const subList = data.slice(i, i + chunkSize);
        splitList.push(subList);
      }
      return splitList;
    },
  },
};

export default dataFormat;
