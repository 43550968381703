



















import Avatar from '../../components/Avatar.vue';
import { PropType } from 'vue';
import { InstitutionProfileModel } from '../../models/institutionProfile.model';
import uniqueId from 'lodash/uniqueId';

const AVATAR_LIMIT = 3;
export default {
  components: { Avatar },
  props: {
    recipients: { type: Array as PropType<InstitutionProfileModel[]>, required: true },
    extraRecipientsCount: { type: Number, default: 0 },
    small: { type: Boolean, default: false },
  },
  computed: {
    recipientListAriaLabel() {
      const recipientLabel = this.visibleProfiles.map(profile => profile.fullName + profile.metadata).join(', ');
      return recipientLabel + (this.extraRecipientsCount > 0 ? ` +${this.extraRecipientsCount}` : '');
    },
    visibleProfiles() {
      return this.recipients.slice(0, AVATAR_LIMIT);
    },
    extraCount() {
      let hiddenAvatar = 0;
      if (this.extraRecipientsCount > 0) {
        hiddenAvatar = 1;
      }
      return this.extraRecipientsCount + hiddenAvatar;
    },
  },
  methods: {
    getUniqueKey() {
      return uniqueId();
    },
  },
};
