<template>
  <div class="aula-presence-employee">
    <div v-if="!isMobile" class="presence-toolbar">
      <b-row>
        <b-col cols="3" class="pr-0">
          <aula-select
            id="departmentFilter"
            v-model="departmentId"
            :aria-label="'ARIA_LABEL_PRESENCE_CHOOSE_DEPARTMENT' | fromTextKey"
            :placeholder="
              (departments.length == 0
                ? 'PRESENCE_CHOOSE_NO_DEPARTMENT_PLACEHOLDER'
                : 'PRESENCE_CHOOSE_DEPARTMENT_PLACEHOLDER') | fromTextKey
            "
            :disabled="departments.length == 1"
            @change="departmentUpdated()"
          >
            <aula-option
              v-for="department in departments"
              :key="department.departmentId"
              :label="department.mainGroup.name"
              :value="department.id"
            />
          </aula-select>
        </b-col>
        <b-col cols="4" class="group-filter-search">
          <aula-search
            id="groupFilter"
            :results="filteringGroups"
            :existing-participants="existingGroups"
            :reset-search-input="resetGroupFilters"
            :remote-search="false"
            :select-all="false"
            placeholder-textkey="PRESENCE_FILTERING_ALL"
            @emitSelected="groupsUpdated"
          />
        </b-col>
      </b-row>
    </div>
    <div class="presence-main-content page-layout-padding">
      <div class="presence-header-container">
        <template v-if="!isMobile">
          <div class="presence-header">
            <h1 class="presence-title" tabindex="0">
              {{ 'PRESENCE_PAGETITLE' | fromTextKey }}
            </h1>
          </div>
          <div class="config-sections" tabindex="0">
            <ul class="nav mt-3">
              <li v-for="(menu, i) in menus" :key="i" class="nav-item">
                <div
                  class="nav-link text-uppercase"
                  tabindex="0"
                  role="button"
                  :class="{ active: menu === defaultTab }"
                  @click="setDefaultTab(menu)"
                  @keydown.enter="setDefaultTab(menu)"
                >
                  {{ menu | fromTextKey }}
                </div>
              </li>
            </ul>
          </div>
        </template>
        <template v-else>
          <b-navbar class="mobile-page-navbar">
            <b-navbar-nav>
              <b-nav-item
                v-for="(menu, i) in menus"
                :key="'mobile' + i"
                :class="{ active: menu === defaultTab }"
                @click="defaultTab = menu"
              >
                {{ menu | fromTextKey | toUpperCase }}
              </b-nav-item>
            </b-navbar-nav>
          </b-navbar>
          <presence-toolbar-mobile
            :next-activities="nextActivities"
            :active-presence-states="activePresenceStates"
            :default-tab="defaultTab"
          />
        </template>
        <aula-spinner v-if="isLoading" />
        <template v-else>
          <template v-if="defaultTab === 'PRESENCE_ACTIVITY_LIST_TODAY'">
            <b-row v-if="!isMobile" class="mt-3">
              <b-col cols="6" xl="4" lg="5">
                <label>{{ 'PRESENCE_FILTERING_STATUS' | fromTextKey }}</label>
                <aula-search
                  id="statusFilter"
                  :results="activePresenceStates"
                  :reset-selected="resetSelectedStates"
                  :reset-search-input="resetStateFilters"
                  :remote-search="false"
                  popper-class="popper-higher"
                  placeholder-textkey="PRESENCE_FILTERING_ALL"
                  @emitSelected="statesUpdated"
                />
              </b-col>
              <b-col v-if="nextActivities.length > 0" cols="6" xl="4" lg="5">
                <label>{{ 'PRESENCE_FILTERING_ACTIVITY' | fromTextKey }}</label>
                <aula-select
                  id="activityFilter"
                  v-model="activityId"
                  :placeholder="'PRESENCE_DASHBOARD_FILTER_BY_ACTIVITY' | fromTextKey"
                  :aria-label="'ARIA_LABEL_PRESENCE_DASHBOARD_FILTER_BY_ACTIVITY' | fromTextKey"
                  :disabled="false"
                  @change="activityUpdated"
                >
                  <!-- TODO ADD isEditMode -->
                  <aula-option key="all" :value="null" :label="'PRESENCE_FILTERING_ALL' | fromTextKey" />
                  <aula-option
                    v-for="nextActivity in nextActivities"
                    :key="nextActivity.value"
                    :label="nextActivity.label"
                    :value="nextActivity.value"
                  />
                </aula-select>
              </b-col>
              <b-col cols="6" xl="4" lg="5" class="daily-note">
                <el-switch v-model="hasDailyNote" :width="(width = 48)" @change="hasDailyNoteUpdated" />
                <label>{{ 'PRESENCE_FILTERING_DAILY_NOTE_DESCRIPTION' | fromTextKey }}</label>
              </b-col>
            </b-row>
            <activity-list />
          </template>
          <template v-if="defaultTab === 'PRESENCE_ACTIVITY_LIST_WEEK'">
            <week-overview />
          </template>
          <template v-if="defaultTab === 'PRESENCE_REPORT_VACATION_REQUEST'">
            <vacation-requests />
          </template>
          <template v-if="defaultTab === 'PRESENCE_OPENING_HOURS_AND_CLOSED_DAYS'">
            <opening-hours-and-closed-days />
          </template>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { types } from '../../store/types/types';
import { presenceStates } from '../../../shared/enums/presenceStates';
import { mapMutations, mapActions, mapGetters } from 'vuex';
import ActivityList from './ActivityList';
import WeekOverview from './WeekOverview';
import { presenceModules } from '../../../shared/enums/presenceModules';
import { portalRoles } from '../../../shared/enums/portalRoles';
import moment from 'moment-timezone';
import cloneDeep from 'lodash/cloneDeep';
import Vue from 'vue';
import PresenceToolbarMobile from './PresenceToolbarMobile';
import VacationRequests from './VacationRequests';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import OpeningHoursAndClosedDays from '../presence_shared/OpeningHoursAndClosedDays';

export default {
  data: function () {
    return {
      isLoading: false,
      defaultTab: 'PRESENCE_ACTIVITY_LIST_TODAY',
      presenceModules: presenceModules,
      menus: [
        'PRESENCE_ACTIVITY_LIST_TODAY',
        'PRESENCE_ACTIVITY_LIST_WEEK',
        'PRESENCE_REPORT_VACATION_REQUEST',
        'PRESENCE_OPENING_HOURS_AND_CLOSED_DAYS',
      ],
      hasDailyNote: false,
      departmentId: null,
      existingGroups: [],
      resetSelectedStates: false,
      resetStateFilters: false,
      resetGroupFilters: false,
      activePresenceStates: [],
      activityId: null,
      date: moment().format('YYYY-MM-DD'),
    };
  },
  computed: {
    ...mapGetters({
      profile: types.GET_CURRENT_PROFILE,
      isMobile: types.GET_IS_MOBILE,
      defaultDateFormat: types.GET_DEFAULT_DATE_FORMAT,
      defaultDateValueFormat: types.GET_DEFAULT_DATE_VALUE_FORMAT,
      institutions: types.GET_INSTITUTIONS,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
      activityList: types.GET_ACTIVITY_LIST,
      departments: types.GET_DEPARTMENTS,
      selectedDepartment: types.GET_SELECTED_DEPARTMENT,
      departmentGroups: types.GET_DEPARTMENT_GROUPS,
      filterOptions: types.GET_FILTER_OPTIONS,
      isEditMode: types.GET_ACTIVITY_LIST_EDIT_MODE,
      activities: types.GET_PRESENCE_ACTIVITIES,
      states: types.GET_PRESENCE_STATES,
      getPresenceFilters: types.GET_PRESENCE_FILTERS,
      hasPermissionInstitution: types.HAS_PERMISSION_ON_INSTITUTION,
    }),
    filteringGroups() {
      const groupOptions = [];
      for (const group of this.departmentGroups) {
        groupOptions.push({ value: group.id, label: group.name });
      }
      return [
        {
          label: Vue.filter('fromTextKey')('PRESENCE_FILTERING_GROUPS'),
          options: groupOptions,
          value: 'all',
        },
      ];
    },
    nextActivities() {
      const filteringNextActivities = [];
      const activities = this.selectedDepartment != null ? this.selectedDepartment.presenceNextActivities : [];
      if (activities.length > 0) {
        for (const activity of this.activities) {
          if (activities.includes(activity.value)) {
            filteringNextActivities.push(activity);
          }
        }
      }
      return filteringNextActivities;
    },
    activePresenceInstitutions() {
      return this.getPresenceFilters
        .filter(
          p => p.departments != null && p.departments.length > 0 && this.activeInstitutions.includes(p.institutionCode)
        )
        .map(p => p.institutionCode);
    },
    activeInstWithVacationRequestPermission() {
      return this.institutions.filter(
        inst =>
          this.activePresenceInstitutions.includes(inst.institutionCode) &&
          this.hasPermissionInstitution(permissionEnum.HANDLE_VACATION_REQUESTS, inst.institutionCode)
      );
    },
    canHandleVacationRequests() {
      return this.profile.role === portalRoles.EMPLOYEE && this.activeInstWithVacationRequestPermission.length > 0;
    },
  },
  methods: {
    ...mapMutations({
      updateFilterOptions: types.MUTATE_FILTER_OPTIONS,
      updateSelectedDepartment: types.MUTATE_SELECTED_DEPARTMENT,
    }),
    ...mapActions({
      loadPresenceFilters: types.LOAD_PRESENCE_FILTER,
    }),
    setActivePresenceStates() {
      let activePresenceStates = [];
      if (this.selectedDepartment != null) {
        activePresenceStates = cloneDeep(this.states);
        const departmentStates = this.selectedDepartment.presenceStates;

        activePresenceStates.forEach(groupStatus => {
          groupStatus.options = groupStatus.options.filter(status => departmentStates.includes(status.value));
          groupStatus.options.forEach(state => {
            state.value = state.value.toString();
          });
          if (groupStatus.value === 'present' && departmentStates.includes(presenceStates.PHYSICAL_PLACEMENT)) {
            const locations = this.selectedDepartment.locations.map(location => ({
              value: 'location-' + location.id,
              label: location.name,
              location: true,
            }));
            groupStatus.options = [...groupStatus.options, ...locations];
          }
        });
        activePresenceStates = activePresenceStates.filter(groupStatus => groupStatus.options.length > 0);
      }
      this.activePresenceStates = activePresenceStates;
    },
    groupsUpdated(selected) {
      this.updateFilterOptions({
        groupIds: selected.map(group => group.value),
      });
    },
    toggleToResetSelectedStates() {
      this.resetSelectedStates = !this.resetSelectedStates;
    },
    toggleToResetGroupFilters() {
      this.resetGroupFilters = !this.resetGroupFilters;
    },
    toggleToResetStateFilters() {
      this.resetStateFilters = !this.resetStateFilters;
    },
    departmentUpdated() {
      if (this.profile.role === portalRoles.EMPLOYEE) {
        this.initPresenceFilters(false);
      }
      this.existingGroups = [];
      this.toggleToResetSelectedStates();
      this.updateFilterOptions({ departmentId: this.departmentId });
    },
    dateUpdated() {
      this.updateFilterOptions({ date: this.date });
    },
    statesUpdated(selectedStates) {
      const locationIds = selectedStates
        .filter(state => state.location != null)
        .map(location => location.value.split('-')[1]);
      this.updateFilterOptions({
        locationIds,
        states: selectedStates.filter(state => state.location == null).map(s => s.value),
      });
    },
    activityUpdated() {
      this.updateFilterOptions({ nextActivity: this.activityId });
    },
    hasDailyNoteUpdated() {
      this.updateFilterOptions({
        hasDailyNote: this.hasDailyNote,
      });
    },
    resetAllFilters() {
      this.departmentId = null;
      this.existingGroups = [];
      this.activePresenceStates = [];
      this.toggleToResetStateFilters();
      this.toggleToResetSelectedStates();
      this.toggleToResetGroupFilters();
    },
    populateAllFilters() {
      this.departmentId = this.filterOptions.departmentId;
      this.existingGroups = [];
      if (this.filterOptions.states.length == 0) {
        this.resetSelectedStates = !this.resetSelectedStates;
      }
      if (this.filterOptions.groupIds.length > 0) {
        const chosenGroups = this.departmentGroups.filter(g => this.filterOptions.groupIds.includes(g.id));
        for (const group of chosenGroups) {
          this.existingGroups.push({
            value: group.id,
            name: group.name,
            label: group.name,
          });
        }
      }
      this.setActivePresenceStates();
    },
    initPresenceFilters(resetFilterOptions = true) {
      if (this.activeInstitutions.length > 0) {
        this.loadPresenceFilters({
          institutionCodes: this.activeInstitutions,
          resetFilterOptions: resetFilterOptions,
        }).then(() => {
          if (this.departments.length === 0) {
            this.resetAllFilters();
          } else {
            this.populateAllFilters();
          }
        });
      }
    },
    setDefaultTab(tab) {
      this.defaultTab = tab;
      this.activityId = null;
      this.date = moment().format('YYYY-MM-DD');
      this.dateUpdated();
      this.activityUpdated();
      this.statesUpdated([]);
      this.hasDailyNote = false;
      this.hasDailyNoteUpdated();
    },
  },
  watch: {
    activeInstitutions() {
      if (this.profile.role === portalRoles.EMPLOYEE) {
        this.initPresenceFilters(false);
      }
    },
    'filterOptions.nextActivity'() {
      this.activityId = this.filterOptions.nextActivity;
    },
    'filterOptions.date'() {
      this.date = this.filterOptions.date;
    },
    'filterOptions.hasDailyNote'() {
      this.hasDailyNote = this.filterOptions.hasDailyNote;
    },
  },
  mounted() {
    this.initPresenceFilters();

    setTimeout(function () {
      document.querySelector('.presence-header-container .presence-title').focus();
    }, 500);
  },
  components: {
    OpeningHoursAndClosedDays,
    VacationRequests,
    PresenceToolbarMobile,
    ActivityList,
    WeekOverview,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/presence/_presence-employee.scss';
</style>
