<template>
  <b-form-checkbox
    :id="id"
    v-sr-only:pre="settingsGroupAriaText"
    v-sr-only="selectedOnOffAriaText"
    :checked="checked"
    :class="isViewMode ? 'no-disabled-style' : 'editable-checkbox'"
    class="inline-block mr-2"
    :disabled="isViewMode"
    :aria-label="checkBoxAriaText"
    @change="
      $event => {
        currentValue = $event;
        return $emit('change', $event);
      }
    "
  >
    {{ textKeyName | fromTextKey }}
  </b-form-checkbox>
</template>

<script>
import srOnly from '../../directives/sr-only';

export default {
  directives: {
    srOnly,
  },
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    id: { type: String, required: true },
    checked: { type: Boolean, required: true },
    isViewMode: { type: Boolean, default: false },
    groupTextKeyName: { type: String, required: true },
    textKeyName: { type: String, required: true },
  },
  data: function () {
    return {
      currentValue: this.checked,
    };
  },
  computed: {
    checkBoxAriaText() {
      const ariaTexts = [this.settingsGroupAriaText, this.$options.filters.fromTextKey(this.textKeyName)];
      return ariaTexts.join(' ');
    },
    settingsGroupAriaText() {
      const ariaTexts = [
        this.$options.filters.fromTextKey('ARIA_NOTIFICATION_SETTINGS_NOTIFICATION'),
        this.$options.filters.fromTextKey(this.groupTextKeyName),
      ];
      return ariaTexts.join(' ');
    },
    selectedOnOffAriaText() {
      return this.currentValue
        ? this.$options.filters.fromTextKey('ARIA_NOTIFICATION_SETTINGS_SELECTED_ON')
        : this.$options.filters.fromTextKey('ARIA_NOTIFICATION_SETTINGS_SELECTED_OFF');
    },
  },
};
</script>
