import AES from 'crypto-js/aes';
import ENC from 'crypto-js/enc-utf8';

export class StorageService {
  /**
   * @desc Get item from storage
   * @param {String} key
   * @param options
   * @param options.encryption Crypto encryption key value
   * @param {*} fallback
   * @return {*}
   */
  getItem(key, options, fallback = null) {
    const item = localStorage.getItem(key);
    if (item == null) {
      return fallback;
    }

    try {
      return JSON.parse(this.#decrypt(item, options.encryption));
    } catch {
      return fallback;
    }
  }

  /**
   * @desc Set item in storage
   * @param {String} key
   * @param options
   * @param options.encryption Crypto encryption key value
   * @param {*} value
   */
  setItem(key, options, value) {
    localStorage.setItem(key, this.#encrypt(JSON.stringify(value), options.encryption));
  }

  /**
   * @param {String} text - text to encrypt
   * @param {String} key - encryption key
   * @return {String}
   */
  #encrypt(text, key) {
    return AES.encrypt(text, key).toString();
  }

  /**
   * @param {String} text - text to decipher
   * @param {String} key - encryption key
   * @return {String}
   */
  #decrypt(text, key) {
    try {
      const bytes = AES.decrypt(text, key);
      return bytes.toString(ENC);
    } catch {
      return text;
    }
  }
}
