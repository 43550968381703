<template>
  <ContactsFragmentNoResult v-if="contacts.length === 0" />
  <TableContainer v-else>
    <TableHead>
      <TableRow>
        <TableHeading
          class="color-grey-base profile-avatar-cell"
          :collapse-directions="[collapseDirectionEnum.RIGHT]"
        />
        <TableHeading class="color-grey-base" :collapse-directions="[collapseDirectionEnum.LEFT]">
          <ContactsFragmentSorting
            :direction="orderDirection"
            :sort-by="contactSortingTypes.NAME"
            :sorting-by="sortBy"
            @sort="onSortBy"
          >
            {{ 'CONTACT_NAME' | fromTextKey }}
          </ContactsFragmentSorting>
        </TableHeading>
        <TableHeading class="color-grey-base">
          {{ 'CONTACT_CHILD_PHONE' | fromTextKey }}
        </TableHeading>
        <TableHeading class="color-grey-base">
          {{ 'CONTACT_CONTACT' | fromTextKey }}
        </TableHeading>
        <TableHeading class="color-grey-base">
          {{ 'CONTACT_PHONE' | fromTextKey }}
        </TableHeading>
      </TableRow>
    </TableHead>
    <TableBody>
      <template v-for="contact in contacts">
        <template v-for="(relation, relationIndex) in contact.relations">
          <TableRow
            :key="
              contact.id + '_' + contact.fullName + '_' + relation.role + '_' + relation.fullName + '_' + relationIndex
            "
            :collapse-directions="getBorderCollapseDirections(relationIndex, contact)"
          >
            <TableCell
              v-if="relationIndex === 0"
              class="color-grey-base"
              :collapse-directions="[]"
              :rowspan="getRowSpan(contact)"
            >
              <ContactAvatar :profile="contact" class="user-avatar" />
            </TableCell>
            <TableCell v-if="relationIndex === 0" :collapse-directions="[]" :rowspan="getRowSpan(contact)">
              <ContactsFragmentContactMetadata :profile="contact">
                {{ contact | displayProfileNameWithMetadata }}
              </ContactsFragmentContactMetadata>
            </TableCell>
            <TableCell v-if="relationIndex === 0" :collapse-directions="[]" :rowspan="getRowSpan(contact)">
              <ContactsFragmentDisplayProfilePhoneNumbers
                v-if="contact.userHasGivenConsentToShowContactInformation"
                :profile="contact"
              />
            </TableCell>
            <TableCell>
              <ContactsFragmentDisplayProfileRelationName :profile="relation" />
            </TableCell>
            <TableCell>
              <ContactsFragmentDisplayProfilePhoneNumbers
                v-if="relation.userHasGivenConsentToShowContactInformation"
                :profile="relation"
              />
            </TableCell>
          </TableRow>
        </template>
      </template>
    </TableBody>
  </TableContainer>
</template>
<script lang="js">

import TableCell from "../../libs/table/components/TableCell";
import TableBody from "../../libs/table/components/TableBody";
import ContactsFragmentSorting from "./ContactsFragmentSorting";
import TableHeading from "../../libs/table/components/TableHeading";
import TableRow from "../../libs/table/components/TableRow";
import TableHead from "../../libs/table/components/TableHead";
import TableContainer from "../../libs/table/components/TableContainer";
import {providerKeyTypes} from "../../enums/providerKeyTypes";
import {collapseDirectionEnum} from "../../libs/table/enums/collapseDirectionEnum";
import {contactSortingTypes} from "../../enums/contactSortingTypes";
import ContactsFragmentNoResult from "./ContactsFragmentNoResult";
import ContactsFragmentDisplayProfilePhoneNumbers from "./ContactsFragmentDisplayProfilePhoneNumbers";
import ContactsFragmentDisplayProfileRelationName from "./ContactsFragmentDisplayProfileRelationName";
import ContactsFragmentContactMetadata from "./ContactsFragmentContactMetadata";
import ContactAvatar from "../../../src/components/contacts/ContactAvatar.vue";

export default {
  name: 'ContactsFragmentDesktopPhoneList',
  components: {
    ContactAvatar,
    ContactsFragmentDisplayProfileRelationName,
    ContactsFragmentDisplayProfilePhoneNumbers,
    ContactsFragmentContactMetadata,
    ContactsFragmentNoResult,
    TableCell,
    TableBody,
    ContactsFragmentSorting,
    TableHeading,
    TableRow,
    TableHead,
    TableContainer,
  },
  inject: {
    getContacts: providerKeyTypes.contacts,
    getOrderDirection: providerKeyTypes.orderDirection,
    getSortBy: providerKeyTypes.sortBy,
    changeSortBy: providerKeyTypes.onSortBy,
  },
  data() {
    return {
      collapseDirectionEnum,
      contactSortingTypes,
    }
  },
  computed: {
    contacts() {
      return this.getContacts();
    },
    orderDirection() {
      return this.getOrderDirection();
    },
    sortBy() {
      return this.getSortBy();
    },
  },
  methods: {
    onSortBy(sortBy) {
      this.changeSortBy(sortBy);
    },
    getBorderCollapseDirections(relationIndex, institutionProfileContact) {
      const lastIndex = this.getRowSpan(institutionProfileContact) - 1;
      let directions = [];

      if (relationIndex === 0 && lastIndex === 0) {
        return directions;
      }

      if (relationIndex === 0) {
        directions = [collapseDirectionEnum.BOTTOM];
      } else if (relationIndex === lastIndex) {
        directions = [collapseDirectionEnum.TOP];
      } else if (relationIndex < lastIndex) {
        directions = [collapseDirectionEnum.BOTTOM, collapseDirectionEnum.TOP];
      }

      return directions;
    },
    getShortName(institutionProfile) {
      return this.$options.filters.displayProfileInitials(institutionProfile);
    },
    getRowSpan(institutionProfileContact) {
      return institutionProfileContact.relations.length;
    },
  }
};
</script>
