<template>
  <message-type-message
    class="auto-reply"
    :message="message"
    :shown-subscription="shownSubscription"
    @openDocumentDrawer="openDocumentDrawer"
  />
</template>

<script>
import MessageTypeMessage from './MessageTypeMessage.vue';

export default {
  components: {
    MessageTypeMessage,
  },
  props: {
    message: {
      type: Object,
      default: null,
    },
    shownSubscription: {
      type: Object,
      default: null,
    },
  },
  methods: {
    openDocumentDrawer(data) {
      this.$emit('openDocumentDrawer', data);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
