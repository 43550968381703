import { portal } from '../../assets/plugins/axios/axios.js';
import { AxiosError } from 'axios';
import { HttpError } from '../../../src/business/httpError';
import { GetMessagesForThreadRequest } from '../../models/requests/getMessagesForThread.request';
import { GetThreadInfoLightRequest } from '../../models/requests/getThreadInfoLight.request';
import { ThreadWithMessagesModel } from '../../models/threadWithMessages.model';
import { ThreadInfoLightModel } from '../../models/threadInfoLight.model';
import { SetThreadsMutedRequest } from '../../models/requests/setThreadsMuted.request';

class MessagingService {
  public getMessagesForThread = async (payload: GetMessagesForThreadRequest): Promise<ThreadWithMessagesModel> => {
    try {
      const { data } = await portal.get('?method=messaging.getMessagesForThread', { params: payload });
      return data.data;
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };

  public getThreadInfoLight = async (payload: GetThreadInfoLightRequest): Promise<ThreadInfoLightModel> => {
    try {
      const { data } = await portal.get('?method=messaging.getThreadInfoLight', { params: payload });
      return data.data;
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };

  public setThreadsMuted = async (payload: SetThreadsMutedRequest): Promise<null> => {
    try {
      const { data } = await portal.post('?method=messaging.setThreadsMuted', payload);
      return data;
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };
}

export const messagingService = new MessagingService();
