<template>
  <div>
    <AulaSwitchButton v-if="canViewParentCustody" v-model="showParentCustody" class="my-4 d-print-none">
      {{ 'CONTACT_SHOW_PARENT_CUSTODY' | fromTextKey }}
    </AulaSwitchButton>
    <ContactsFragmentNoResult v-if="contacts.length === 0" />
    <TableContainer v-else>
      <TableHead>
        <TableRow>
          <TableHeading class="color-grey-base" :collapse-directions="[collapseDirectionEnum.RIGHT]" />
          <TableHeading class="color-grey-base" :collapse-directions="[collapseDirectionEnum.LEFT]">
            <ContactsFragmentSorting
              :direction="orderDirection"
              :sort-by="contactSortingTypes.NAME"
              :sorting-by="sortBy"
              @sort="onSortBy"
            >
              {{ 'CONTACT_NAME' | fromTextKey }}
            </ContactsFragmentSorting>
          </TableHeading>
          <TableHeading class="color-grey-base">
            {{ 'CONTACT_CONTACT' | fromTextKey }}
          </TableHeading>
          <TableHeading class="color-grey-base address-cell">
            {{ 'CONTACT_ADDRESS' | fromTextKey }}
          </TableHeading>
          <TableHeading class="color-grey-base">
            {{ 'CONTACT_PHONE' | fromTextKey }}
          </TableHeading>
          <TableHeading class="color-grey-base">
            {{ 'CONTACT_EMAIL' | fromTextKey }}
          </TableHeading>
        </TableRow>
      </TableHead>
      <TableBody>
        <template v-for="(contact, contactIndex) in contacts">
          <template v-for="(relation, relationIndex) in contactRelations[contactIndex]">
            <TableRow
              :key="
                contact.id +
                '_' +
                contact.fullName +
                '_' +
                relation.role +
                '_' +
                relation.fullName +
                '_' +
                relationIndex
              "
              :collapse-directions="getBorderCollapseDirections(relationIndex, contact)"
              :e2e="contact.fullName"
            >
              <TableCell
                v-if="relationIndex === 0"
                class="color-grey-base profile-avatar-cell"
                :collapse-directions="[]"
                :rowspan="getRowSpan(contact)"
              >
                <ContactAvatar :profile="contact" class="user-avatar" />
              </TableCell>
              <TableCell v-if="relationIndex === 0" :collapse-directions="[]" :rowspan="getRowSpan(contact)">
                {{ contact | displayProfileNameWithMetadata }}
              </TableCell>
              <TableCell>
                <ContactsFragmentDisplayProfileRelationName
                  :profile="relation"
                  :show-parent-custody="showParentCustody"
                />
              </TableCell>
              <TableCell>
                <address v-if="relation.userHasGivenConsentToShowContactInformation && relation.address != null">
                  {{ relation.address.street }},
                  <br />
                  {{ relation.address.postalCode }}
                  {{ relation.address.postalDistrict }}
                </address>
              </TableCell>
              <TableCell>
                <ContactsFragmentDisplayProfilePhoneNumbers
                  v-if="relation.userHasGivenConsentToShowContactInformation"
                  :profile="relation"
                />
              </TableCell>
              <TableCell>
                <a
                  v-if="relation.userHasGivenConsentToShowContactInformation && relation.email"
                  class="color-primary-darker"
                  :href="'mailto:' + relation.email"
                >
                  {{ relation.email }}
                </a>
              </TableCell>
            </TableRow>
          </template>
        </template>
      </TableBody>
    </TableContainer>
  </div>
</template>
<script lang="js">

import TableCell from "../../libs/table/components/TableCell";
import TableBody from "../../libs/table/components/TableBody";
import ContactsFragmentSorting from "./ContactsFragmentSorting";
import TableHeading from "../../libs/table/components/TableHeading";
import TableRow from "../../libs/table/components/TableRow";
import TableHead from "../../libs/table/components/TableHead";
import TableContainer from "../../libs/table/components/TableContainer";
import {providerKeyTypes} from "../../enums/providerKeyTypes";
import {collapseDirectionEnum} from "../../libs/table/enums/collapseDirectionEnum";
import {contactSortingTypes} from "../../enums/contactSortingTypes";
import ContactsFragmentNoResult from "./ContactsFragmentNoResult";
import AulaSwitchButton from "../../components/AulaSwitchButton.vue";
import ContactsFragmentDisplayProfileRelationName from "./ContactsFragmentDisplayProfileRelationName";
import ContactsFragmentDisplayProfilePhoneNumbers from "./ContactsFragmentDisplayProfilePhoneNumbers";
import ContactAvatar from "../../../src/components/contacts/ContactAvatar.vue";

export default {
  name: 'ContactsFragmentDesktopContactList',
  components: {
    ContactAvatar,
    ContactsFragmentDisplayProfilePhoneNumbers,
    ContactsFragmentDisplayProfileRelationName,
    AulaSwitchButton,
    ContactsFragmentNoResult,
    TableCell,
    TableBody,
    ContactsFragmentSorting,
    TableHeading,
    TableRow,
    TableHead,
    TableContainer,
  },
  inject: {
    getContacts: providerKeyTypes.contacts,
    getOrderDirection: providerKeyTypes.orderDirection,
    getSortBy: providerKeyTypes.sortBy,
    changeSortBy: providerKeyTypes.onSortBy,
    getProfile: providerKeyTypes.profile,
    getCanViewParentCustody: providerKeyTypes.canViewParentCustody,
  },
  data() {
    return {
      collapseDirectionEnum,
      contactSortingTypes,
      showParentCustody: false,
    }
  },
  computed: {
    canViewParentCustody() {
      return this.getCanViewParentCustody();
    },
    contacts() {
      return this.getContacts();
    },
    orderDirection() {
      return this.getOrderDirection();
    },
    sortBy() {
      return this.getSortBy();
    },
    profile() {
      return this.getProfile();
    },
    contactRelations() {
      const empty = '';
      return this.contacts.map(
        contact =>  contact.relations.length !== 0 ?
        contact.relations :
        [ { role: empty, fullName: empty, ...contact }]
      );
    }
  },
  methods: {
    onSortBy(sortBy) {
      this.changeSortBy(sortBy);
    },
    getBorderCollapseDirections(relationIndex, institutionProfileContact) {
      const lastIndex = this.getRowSpan(institutionProfileContact) - 1;
      let directions = [];

      if (relationIndex === 0 && lastIndex === 0) {
        return directions;
      }

      if (relationIndex === 0) {
        directions = [collapseDirectionEnum.BOTTOM];
      } else if (relationIndex === lastIndex) {
        directions = [collapseDirectionEnum.TOP];
      } else if (relationIndex < lastIndex) {
        directions = [collapseDirectionEnum.BOTTOM, collapseDirectionEnum.TOP];
      }

      return directions;
    },
    getShortName(institutionProfile) {
      return this.$options.filters.displayProfileInitials(institutionProfile);
    },
    getRowSpan(institutionProfileContact) {
      return institutionProfileContact.relations.length === 0 ? 1 : institutionProfileContact.relations.length;
    },
  }
};
</script>
<style lang="scss" scoped>
.address-cell {
  width: 200px;
  max-width: 200px;
  min-width: 200px;
}
</style>
