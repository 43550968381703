<template>
  <div class="onboarding-step2" :class="{ 'has-warning': $parent.showWarningConsent }">
    <div class="step2-content scrollbar">
      <consent-additional ref="consentTable" :data="getConsentResponses" type="consent" @showWarning="showWarning" />
    </div>
    <b-alert variant="danger" class="warning" :show="$parent.showWarningConsent">
      {{ 'WARNING_ANSWER_CONSENT' | fromTextKey }}
    </b-alert>
  </div>
</template>

<script>
import { types } from '../../store/types/types';
import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';
import ConsentAdditional from '../../../shared/components/consentAdditional/ConsentAdditional';

export default {
  data: function () {
    return {
      isLoading: true,
      consentResponses: null,
      stepIndex: null,
    };
  },
  computed: {
    ...mapGetters({
      getConsentResponses: types.GET_MYPROFILE_PENDING_CONSENTS,
    }),
  },
  methods: {
    ...mapActions({
      loadConsentResponses: types.ACTION_GET_CONSENTS_RESPONSE,
    }),
    validatedConsentAnswer() {
      return this.$refs.consentTable.validateConsentAnswered(true);
    },
    showWarning(isPassed) {
      if (this.$parent.showWarningConsent) {
        this.$parent.showWarningConsent = !isPassed;
      }
    },
    declinedSpecialQuestionEdited() {
      return this.$refs.consentTable.declinedSpecialQuestionEdited();
    },
    submitConsent() {
      return this.$refs.consentTable.submitConsentResponse();
    },
  },
  mounted() {
    const em = this;
    this.stepIndex = this.$parent.stepStatus.findIndex(s => s.step == 'consent');
    if (this.stepIndex != -1 && this.getConsentResponses == null) {
      this.loadConsentResponses({
        returnOnlyPendingConsentResponses: true,
      }).then(() => {
        em.isLoading = false;
      });
    } else {
      em.isLoading = false;
    }
  },
  components: {
    'consent-additional': ConsentAdditional,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/core/animations.scss';
@import '../../../shared/assets/scss/components/login/onboarding.scss';
</style>
