export const CONTACT_PARENT = 'Kontaktforældre';
export const SORT_BY = 'Sorter efter';
export const SORT_BY_ALPHABET = 'Alfabetisk';
export const SORT_BY_ANSWER = 'Svar';
export const SORT_BY_BIRTHDAY = 'Fødselsdag';
export const CONTACT_BIRTHDAY = 'Fødselsdag';
export const CONTACT_FILTER_GUARDIAN = 'Forældre';
export const MASTER_DATA_INFORMATION = 'Stamkortoplysninger';
export const MASTER_DATA_INFORMATION_TITLE = 'Stamkortoplysninger';
export const ARIA_LABEL_USER_GROUP = 'Søg bruger eller gruppe for at se Stamkort';
export const ARIA_LABEL_CHOOSE_GROUP_TO_VIEW_CONTACTS = 'Vælg en gruppe for at se kontakter';
export const CONTACT_FILTER_AND_SORT = 'Filter og sorter';
export const CONTACT_NO_RESULTS = 'Der er ingen {profileType} i denne kontaktliste.';
export const CONTACT_CHILD = 'børn';
export const CONTACT_BOY = 'drenge';
export const CONTACT_GIRL = 'piger';
export const CONTACT_GUARDIAN = 'forældre';
export const CONTACT_EMPLOYEE = 'medarbejdere';
export const CONTACT_LIST = 'Kontaktliste';
export const CONTACT_PHONE_LIST = 'Telefonliste';
export const CONTACT_PHOTO_LIST = 'Fotoliste';
export const CONTACT_CHECKBOX_LIST = 'Afkrydsningsliste';
export const CONTACT_BIRTHDAY_LIST = 'Fødselsdagsliste';
export const CONTACT_NAME = 'Navn';
export const CONTACT_CONTACT = 'Kontakt';
export const CONTACT_WORK = 'Arbejde';
export const CONTACT_CHILD_PHONE = 'Barnets telefon';
export const CONTACT_ADDRESS = 'Adresse';
export const CONTACT_PHONE = 'Kontakters telefon';
export const CONTACT_EMAIL = 'Email';
export const CONTACT_SHOW_PARENT_CUSTODY = 'Vis forældremyndighed i tabellen';
export const CONTACT_LIST_TYPE_PLACEHOLDER = 'Vælg liste';
export const CHOOSE_LIST_TYPE_TO_VIEW_CONTACTS = 'Vælg hvilken liste du vil have vist.';

export const ARIA_LABEL_ALL_CHILDREN = 'Se alle børn';
export const ARIA_LABEL_BOYS = 'Se kun drenge';
export const ARIA_LABEL_GIRLS = 'Se kun piger';
export const ARIA_LABEL_PARENTS = 'Se kun værger';
export const ARIA_LABEL_EMPLOYEE = 'Se kun medarbejdere';
export const ADDITIONAL_DATA_LABEL = 'Tilladelse';
export const CONTACT_DOES_NOT_APPEAR_IN_AULA = 'kontaktoplysninger vises ikke i Aula.';
export const CONTACT_TOO_MUCH_FILTERING_GROUP_RESULTS =
  'Vær opmærksom på, at du kun kan se de første 200 søgeresultater. Prøv at afgrænse søgningen.';
export const CONTACT_INSTITUTION = 'Institution';
export const CONTACT_CHOOSE_COLUMNS = 'Vælg antal kolonner';
export const CONTACT_GROUP = 'Gruppe';
export const CONTACT_FILTER = 'Filter';
export const CONTACT_LIST_CROSS_INSTITUTIONAL_GROUP = 'Tværgående';
