import { MessageSearchModel } from '../../shared/models/messageSearch.model';
import { messageFiltersEnum } from '../../shared/enums/messageFiltersEnum';
import { AulaSearchInstitutionProfileModel } from '../../shared/models/aulaSearchInstitutionProfile.model';
import { MailBoxOwnerModel } from '../../shared/models/mailBoxOwner.Model';
import { messageOwnerTypes } from '../../shared/enums/messageOwnerTypes';

export class NewMessageSearch implements MessageSearchModel {
  filter: messageFiltersEnum;
  query: string;
  content: string;
  fromDate: Date;
  hasAttachments: boolean;
  participants: MailBoxOwnerModel[];
  subject: string;
  threadCreators: MailBoxOwnerModel[];
  toDate: Date;
  offset: number;

  constructor(model: MessageSearchModel) {
    this.filter = model.filter;
    this.query = model.query;
    this.content = model.content;
    this.fromDate = model.fromDate;
    this.hasAttachments = model.hasAttachments;
    this.participants = model.participants;
    this.subject = model.subject;
    this.threadCreators = model.threadCreators;
    this.toDate = model.toDate;
    this.offset = model.offset;
  }

  getIsUsingAdvancedSearch() {
    let isUsingAdvancedSearch = true;
    if (
      this.subject === '' &&
      this.content === '' &&
      this.fromDate == null &&
      this.toDate == null &&
      this.threadCreators.length === 0 &&
      this.participants.length === 0 &&
      this.hasAttachments === false
    ) {
      isUsingAdvancedSearch = false;
    }
    return isUsingAdvancedSearch;
  }

  resetSearchParams() {
    this.filter = messageFiltersEnum.FILTER_ALL;
    this.query = '';
    this.content = '';
    this.toDate = null;
    this.fromDate = null;
    this.hasAttachments = false;
    this.participants = [];
    this.subject = '';
    this.threadCreators = [];
  }

  setFilter(filter: messageFiltersEnum) {
    this.filter = filter;
  }

  getFilter() {
    return this.filter;
  }

  setQuery(query: string) {
    this.query = query;
  }

  getQuery() {
    return this.query || '';
  }

  setContent(content: string | undefined) {
    this.content = content;
  }

  getContent() {
    if (!this.content) {
      return null;
    }

    return this.content;
  }

  setFromDate(fromDate: Date | undefined) {
    this.fromDate = fromDate;
  }

  getFromDate() {
    if (!this.fromDate) {
      return null;
    }

    return this.fromDate;
  }

  setToDate(toDate: Date | undefined) {
    this.toDate = toDate;
  }

  getToDate() {
    return this.toDate;
  }

  setHasAttachments(hasAttachments: boolean | undefined) {
    this.hasAttachments = hasAttachments;
  }

  getHasAttachments() {
    if (!this.hasAttachments) {
      return null;
    }
    return this.hasAttachments;
  }

  setParticipants(participants: AulaSearchInstitutionProfileModel[] = []) {
    this.participants = participants.map(participant => this.mutateSearchToMailboxOwner(participant));
  }

  getParticipants() {
    return this.participants;
  }

  setSubject(subject: string | undefined) {
    this.subject = subject;
  }

  getSubject() {
    if (!this.subject) {
      return null;
    }

    return this.subject;
  }

  setThreadCreators(threadCreators: AulaSearchInstitutionProfileModel[] = []) {
    this.threadCreators = threadCreators.map(threadCreator => this.mutateSearchToMailboxOwner(threadCreator));
  }

  getThreadCreators() {
    return this.threadCreators;
  }

  private mutateSearchToMailboxOwner(searchProfile: AulaSearchInstitutionProfileModel): MailBoxOwnerModel {
    return {
      id: searchProfile.id,
      mailBoxOwnerType: this.getMailBoxOwnerTypeFromSearchProfile(searchProfile),
    };
  }

  private getMailBoxOwnerTypeFromSearchProfile(searchProfile: AulaSearchInstitutionProfileModel) {
    let mailBoxOwnerType = messageOwnerTypes.INSTITUTION_PROFILE;
    switch (searchProfile.type) {
      case 'commoninbox':
        mailBoxOwnerType = messageOwnerTypes.COMMON_INBOX;
        break;
    }
    return mailBoxOwnerType;
  }

  setOffset(offset: number) {
    this.offset = offset;
  }

  getOffset() {
    return this.offset;
  }
}
