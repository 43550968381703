var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (Object.keys(_vm.profile).length > 0)?_c('div',{staticClass:"profile-settings filter-row",attrs:{"title":_vm._f("fromTextKey")('MY_PROFILE_LABEL_CHILD')}},[_c('div',{staticClass:"header-dropdown",class:{ active: _vm.showProfileDropdown },attrs:{"id":"dropdown-profile-settings","aria-label":_vm._f("fromTextKey")('ARIA_LABEL_MY_PROFILE'),"tabindex":"0","role":"button"},on:{"click":function($event){!_vm.isPreviewMode
        ? _vm.toggleHeaderDropdown({
            dropdown: 'profile',
            visible: _vm.showProfileDropdown,
          })
        : ''},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }!_vm.isPreviewMode
        ? _vm.toggleHeaderDropdown({
            dropdown: 'profile',
            visible: _vm.showProfileDropdown,
          })
        : ''}}},[_c('Avatar',{attrs:{"profile":_vm.profile}})],1),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade","leave-active-class":_vm.isClickedCurrentTab ? 'slide-fade-leave-active' : 'slide-fade-leave-active-hide',"leave-to-class":_vm.isClickedCurrentTab ? 'slide-fade-leave-to' : 'leave-to'}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showProfileDropdown),expression:"showProfileDropdown"}],staticClass:"header-dropdown-menu",attrs:{"role":"dialog"}},[(!_vm.isLoadingProfileContext)?_c('UserProfile'):_vm._e()],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }