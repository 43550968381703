<template>
  <div class="box">
    <slot />
  </div>
</template>
<script>
export default {
  name: 'UnsubscribeFragmentCard',
};
</script>
<style lang="scss" scoped>
.box {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-right: 40px;
  padding-left: 40px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-white);
}
</style>
