import moment from 'moment-timezone';

const dateTimeFormatMixin = {
  methods: {
    convertDateFormat(date) {
      return moment(date).format('DD-MM-YYYY');
    },
    getFormatDatePeriod(startDate, endDate) {
      const momentStartDate = moment(startDate);
      const momentEndDate = moment(endDate);

      if (momentStartDate.isSame(momentEndDate)) {
        return this.convertDateFormat(momentStartDate);
      }

      return `${this.convertDateFormat(startDate)} - ${this.convertDateFormat(endDate)}`;
    },
  },
};

export default dateTimeFormatMixin;
