// CALENDARFEED
export const CALENDARFEED_ITEM_TITLE = 'Kalendersynkronisering';
export const CALENDARFEED_TITLE = 'KALENDERSYNKRONISERING';

export const CALENDARFEED_OVERVIEW_CREATE_LINK = 'Opret kalenderlink';
export const CALENDARFEED_OVERVIEW_CREATE_LINKS = 'Opret kalenderlinks';
export const CALENDARFEED_OVERVIEW_SAVE_LINK = 'Gem kalenderlink';
export const CALENDARFEED_OVERVIEW_NO_FILTERS_ERROR = 'Vælg mindst én type';
export const CALENDARFEED_OVERVIEW_LINK_COPIED = 'Kalenderlink er kopieret til udklipsholderen';

export const CALENDARFEED_DELETE_FEED_WARNING =
  'Er du sikker på, at du vil slette kalenderlinket. Hvis du sletter kalenderlinket, vil du ikke længere modtage opdateringer fra linket i din eksterne kalender.';
export const CALENDARFEED_DELETED_TOASTR_TEXT = 'Kalenderlinket blev slettet';
export const CALENDARFEED_DELETE_FEED_YES = 'Ja';

export const CALENDARFEED_REMOVE_CONSENT_WARNING = `Hvis du fjerner dit samtykke, vil dine kalendersynkroniseringer blive slettet og din kalenderkonto vil ikke længere modtage opdateringer fra kalendersynkroniseringen.

Er du sikker på, at du vil fjerne dit samtykke?`;

export const CALENDARFEED_CONSENT_YES = 'Ja';

export const CALENDARFEED_INTRODUCTION_HEADER = 'Hvad er kalendersynkronisering';
export const CALENDARFEED_INTRODUCTION_BODY = `Du har mulighed for at overføre dine kalenderbegivenheder fra Aula til din egen eksterne kalenderkonto. Dette sker ikke automatisk, så du skal selv oprette et kalenderlink og indsætte det i en ekstern kalenderkonto, før Aula begynder at overføre kalenderbegivenheder fra Aula til den eksterne kalenderkonto. Du vælger selv hvilke typer af kalenderbegivenheder, som må overføres fra Aula, når du opretter kalendersynkroniseringen.

Din egen kalender bliver løbende opdateret med nye kalenderbegivenheder og ændringer til eksisterende kalenderbegivenheder på baggrund af opdateringer i Aula.`;

export const CALENDARFEED_CONSENT_TITLE = 'Samtykkeerklæring';
export const CALENDARFEED_CONSENT_DESCRIPTION =
  'Svarer du ja til dette samtykke, giver du Aula lov til at overføre dine kalenderbegivenheder fra Aula til en eller flere eksterne kalenderkonti. Dette sker ikke automatisk, så du skal selv oprette et kalenderlink og indlæse det i en ekstern kalenderkonto, før Aula begynder at sende data til den eksterne kalenderkonto. Du kan til enhver tid tilbagekalde dit samtykke ved blot at fjerne markeringen i nedenstående boks. Herefter deaktiveres alle dine kalenderlinks, dvs. at Aula stopper med at sende data til de eksterne kalenderkonti, hvor kalenderbegivenhederne fra Aula er indlæst. Hvis du ikke giver samtykke, vil du ikke kunne overføre dine kalenderbegivenheder fra Aula til eksterne kalenderkonti.';
export const CALENDARFEED_CONSENT_ACCEPT =
  'Jeg giver hermed samtykke til, at Aula må overføre mine kalenderbegivenheder fra Aula til en eller flere eksterne kalenderkonti';
export const CALENDARFEED_CONSENT_MISSING =
  'Hvis du ikke giver samtykke, vil du ikke kunne overføre dine kalenderbegivenheder fra Aula til eksterne kalenderkonti';
export const CALENDARFEED_OVERVIEW_YEAR_FEED_TITLE = 'Årskalender';
export const CALENDARFEED_OVERVIEW_FEED_COPY_LINK_TEXT = 'Kopier link';
export const CALENDARFEED_OVERVIEW_NO_FEED = 'Der er endnu ikke oprettet et link til kalendersynkronisering.';
export const CALENDARFEED_OVERVIEW_GUARDIAN_TITLE = 'Opret links til kalendersynkronisering';
export const CALENDARFEED_OVERVIEW_GUARDIAN_DESCRIPTION =
  'Her kan du oprette links til kalendersynkronisering for dine børns kalenderbegivenheder i Aula. Når du har oprettet et link til kalendersynkronisering, kan du kopiere det det over i en ekstern kalenderkonto. Vær opmærksom på, at der skal oprettes et link pr. barn til kalendersynkronisering.';
export const CALENDARFEED_OVERVIEW_GUARDIAN_EDIT_CONSENT_TEXT =
  'Du kan til hver en tid redigere i det samtykke, du har givet';
export const CALENDARFEED_OVERVIEW_GUARDIAN_EDIT_CONSENT = 'Rediger samtykke';
export const CALENDARFEED_OVERVIEW_GUARDIAN_YEAR_FEED_DESCRIPTION_FOR_GUARDIAN =
  'Linket overfører alle de valgte typer af kalenderbegivenheder du er inviteret til eller du selv har oprettet i Aula et år frem. Ændringer til kalenderen vil blive opdateret en gang i døgnet eller så ofte som dit kalenderprogram tillader. Du kan altid redigere eller slette kalenderlinket.';
export const CALENDARFEED_OVERVIEW_GUARDIAN_YEAR_FEED_DESCRIPTION_FOR_CHILD =
  'Linket overfører alle de valgte typer af kalenderbegivenheder i Aula et år frem. Ændringer til kalenderen vil blive opdateret en gang i døgnet eller så ofte som dit kalenderprogram tillader. Du kan altid redigere eller slette kalenderlinket.';
export const CALENDARFEED_OVERVIEW_GUARDIAN_FEED_DELETE_TEXT = 'Slet kalenderlink';
export const CALENDARFEED_OVERVIEW_GUARDIAN_FEED_EDIT_TEXT = 'Rediger kalenderlink';
export const CALENDARFEED_OVERVIEW_GUARDIAN_CREATE_FILTER_TITLE = 'Vælg typer af kalenderbegivenheder';
export const CALENDARFEED_OVERVIEW_GUARDIAN_CREATE_FILTER_DESCRIPTION =
  'Vælg hvilke typer af kalenderbegivenheder, du ønsker at synkronisere til din eksterne kalender. Du skal vælge mindst én type.';
export const CALENDARFEED_OVERVIEW_GUARDIAN_NOTICE_WARNING =
  'BEMÆRK! Visse kalenderkonti er længere om at opdatere end oplyst nedenfor grundet begrænsninger hos udbyderen. Google oplyser eksempelvis, at der kan gå op til 12 timer før deres kalenderkonti opdaterer.';

export const CALENDARFEED_OVERVIEW_EMPLOYEE_TITLE = 'Opret links til kalendersynkronisering';
export const CALENDARFEED_OVERVIEW_EMPLOYEE_DESCRIPTION =
  'Her kan du oprette links til kalendersynkronisering for dine kalenderbegivenheder i Aula. Når du har oprettet links til kalendersynkroniseringen, kan du kopiere dem over i en ekstern kalenderkonto. Vær opmærksom på, at der skal oprettes links pr. institution til kalendersynkroniseringen.';
export const CALENDARFEED_OVERVIEW_EMPLOYEE_EDIT_CONSENT_TEXT =
  'Du kan til hver en tid redigere i det samtykke, du har givet';
export const CALENDARFEED_OVERVIEW_EMPLOYEE_EDIT_CONSENT = 'Rediger samtykke';
export const CALENDARFEED_OVERVIEW_EMPLOYEE_YEAR_FEED_DESCRIPTION =
  'Linket overfører alle de valgte typer af kalenderbegivenheder fra Aula fra om 7 dage og et år frem. Ændringer til kalenderen vil blive opdateret en gang i døgnet eller så ofte som dit kalenderprogram  tillader. Du kan altid redigere eller slette kalenderlinket.';
export const CALENDARFEED_OVERVIEW_EMPLOYEE_WEEK_FEED_TITLE = 'Ugekalender';
export const CALENDARFEED_OVERVIEW_EMPLOYEE_WEEK_FEED_DESCRIPTION =
  'Linket overfører alle de valgte typer af kalenderbegivenheder fra Aula de næste 7 dage. Ændringer til kalenderen vil blive opdateret hvert 5. minut eller så ofte som dit kalenderprogram  tillader. Du kan altid redigere eller slette kalendersynkroniseringen.';
export const CALENDARFEED_OVERVIEW_EMPLOYEE_FEED_DELETE_TEXT = 'Slet kalenderlinks';
export const CALENDARFEED_OVERVIEW_EMPLOYEE_FEED_EDIT_TEXT = 'Rediger kalenderlinks';
export const CALENDARFEED_OVERVIEW_EMPLOYEE_CREATE_FILTER_TITLE = 'Vælg typer af kalenderbegivenheder';
export const CALENDARFEED_OVERVIEW_EMPLOYEE_CREATE_FILTER_DESCRIPTION =
  'Vælg hvilke typer af kalenderbegivenheder, du ønsker at synkronisere til din eksterne kalender. Du skal vælge mindst én type.';
export const CALENDARFEED_OVERVIEW_EMPLOYEE_NOTICE_WARNING =
  'BEMÆRK! Årskalender og Ugekalender skal bruges sammen, så husk at kopiere begge links over i din eksterne kalenderkonto. Visse kalenderkonti er længere om at opdatere end oplyst nedenfor grundet begrænsninger hos udbyderen. Google oplyser eksempelvis, at der kan gå op til 12 timer før deres kalenderkonti opdaterer.';
