<template>
  <div class="presence-time" :class="{ 'mt-3': !isMobile }">
    <b-row v-if="isMobile && viewPresenceProfile == null">
      <b-col cols="12">
        <div v-for="(child, index) in presenceChildren" :key="'pc' + index" class="box">
          <div
            class="box-title"
            tabindex="0"
            role="button"
            @click="viewPresenceByChild(child.id)"
            @keydown.enter="viewPresenceByChild(child.id)"
          >
            {{ child.name }}
            <template v-if="child.isMultipleInstitution"> ({{ child.institutionName }}) </template>
            <i class="icon icon-Aula_forward-arrow pull-right" />
          </div>
        </div>
      </b-col>
    </b-row>
    <aula-spinner v-if="isLoading" />
    <div v-else class="presence" :class="isMobile && viewPresenceProfile != null ? 'full-height' : ''">
      <div v-if="isMobile && viewPresenceProfile != null" class="mobile-header">
        <i
          class="icon-Aula_app_arrow_slideback time-back"
          tabindex="0"
          role="button"
          :aria-label="'ARIA_LABEL_BACK' | fromTextKey"
          @click="viewPresenceProfile = null"
          @keydown.enter="viewPresenceProfile = null"
        />
        {{ 'PRESENCE_TIME' | fromTextKey }}
        <div class="profile-icon">
          <user-icon
            :id="viewPresenceProfile.id"
            :name="viewPresenceProfile.name"
            :short-name="viewPresenceProfile.shortName"
            :picture-object="viewPresenceProfile.profilePicture"
            :show-inline="true"
          />
        </div>
      </div>
      <template v-for="(presenceTime, presenceIndex) in presenceTimes">
        <div
          v-if="!isMobile || (isMobile && viewPresenceProfile != null)"
          :key="'pi' + presenceIndex"
          class="presence-week mt-3"
        >
          <div v-if="isMobile" class="presence-header">
            <div
              v-for="(profile, index) in presenceTime.presenceTimes"
              :key="index"
              class="header-col item mobile"
              :class="presenceColsCss"
            >
              <span class="view-month">{{ presenceTime.presenceMonth }}</span>
              <span class="view-week"
                >{{ 'PRESENCE_WEEK' | fromTextKey }}
                {{ presenceTime.presenceWeek }}
                <el-date-picker
                  ref="chooseWeekDatepicker"
                  v-model="presenceTimes[presenceIndex].chosenWeek"
                  :clearable="false"
                  :picker-options="availableDate"
                  type="week"
                  popper-class="presence-choose-week-popper"
                  class="presence-choose-week-picker"
                  @change="changeWeekView(presenceIndex)"
                  @focus="datePickerModifier()"
                />
                <b-btn
                  variant="link"
                  class="presence-choose-week-icon"
                  :aria-label="'ARIA_LABEL_PRESENCE_CHOOSE_WEEK' | fromTextKey"
                  @click="openDatePicker()"
                >
                  <i class="icon-Aula_down-arrow" />
                </b-btn>
              </span>
            </div>
          </div>
          <div v-if="!isMobile && presenceTime.presenceTimes.length > 0" class="presence-header">
            <div class="header-col choose-week-col item week">
              {{ 'PRESENCE_WEEK' | fromTextKey }}
              {{ presenceTime.presenceWeek }}
              <el-date-picker
                v-if="presenceIndex === 0"
                ref="chooseWeekDatepicker"
                v-model="presenceTimes[presenceIndex].chosenWeek"
                :clearable="false"
                :picker-options="availableDate"
                type="week"
                popper-class="presence-choose-week-popper"
                class="presence-choose-week-picker"
                @change="changeWeekView(presenceIndex)"
                @focus="datePickerModifier()"
              />
              <b-btn
                v-if="presenceIndex === 0"
                variant="link"
                class="presence-choose-week-icon"
                :aria-label="'ARIA_LABEL_PRESENCE_CHOOSE_WEEK' | fromTextKey"
                @click="openDatePicker()"
              >
                <i class="icon-Aula_down-arrow" />
              </b-btn>
            </div>
            <div
              v-for="(profile, index) in presenceTime.presenceTimes"
              :key="index"
              class="header-col item header-profile"
              :class="presenceColsCss"
            >
              <user-icon
                :id="profile.institutionProfile.id"
                :name="profile.institutionProfile.name"
                :short-name="profile.institutionProfile.shortName"
                :picture-object="profile.institutionProfile.profilePicture"
                user-icon-class="small"
                :show-inline="true"
              />
              <span class="profile-name"
                >{{ profile.institutionProfile.name }}
                <template v-if="profile.institutionProfile.isMultipleInstitution"
                  >({{ profile.institutionProfile.institutionName }})</template
                ></span
              >
            </div>
          </div>
          <aula-spinner v-if="isLoadingTemplate" />
          <template v-else>
            <template v-for="(presenceDate, dayIndex) in presenceTime.presenceDates">
              <div v-if="presenceTime.presenceTimes.length > 0" :key="'di' + dayIndex" class="presence-content">
                <div class="presence-date item" :aria-current="getIsCurrentDate(presenceDate.date)">
                  <span v-if="isMobile">{{ presenceDate.dayOfWeek }}<br />{{ presenceDate.shortDate }}</span>
                  <span v-else
                    >{{ presenceDate.dateOfWeekFull }}<br />{{ moment(presenceDate.date).format('DD-MM-YYYY') }}</span
                  >
                </div>
                <template v-for="(profile, profileIndex) in presenceTime.presenceTimes">
                  <template v-if="isClosedDay(presenceDate.date, profile.institutionProfile.institutionCode)">
                    <div :key="profileIndex" :class="presenceColsCss" class="presence-item item closed-day-item">
                      <span class="closed-day-text">
                        {{ 'PRESENCE_CLOSED_DAY_DAILY_OVERVIEW' | fromTextKey }}:
                        {{ isClosedDayString(presenceDate.date, profile.institutionProfile.institutionCode) }}
                      </span>
                    </div>
                  </template>
                  <template v-else>
                    <div
                      v-if="presenceTime.presenceTimes != null && presenceTime.presenceTimes.length > 0"
                      :key="profileIndex"
                      class="presence-item item"
                      :class="presenceColsCss"
                    >
                      <div class="presence-item-content">
                        <div
                          v-if="
                            getSpecificOpenHours(
                              profile.dayTemplates[dayIndex],
                              profile.institutionProfile.institutionCode
                            ) != null && profile.dayTemplates[dayIndex].isOnVacation === false
                          "
                          class="specific-open-hours-info-container"
                        >
                          <div class="aula-alert mb-3">
                            <div class="icon-box-exclamation">
                              <div class="circle">
                                <i class="icon-Aula_exclamation_sign" />
                              </div>
                            </div>
                            <div class="alert-text">
                              <strong> {{ 'PRESENCE_NOTE' | fromTextKey }}: </strong>
                              {{ 'PRESENCE_SPECIFIC_TIME_INFO' | fromTextKey }}:
                              {{
                                getSpecificOpenHours(
                                  profile.dayTemplates[dayIndex],
                                  profile.institutionProfile.institutionCode
                                )
                              }}
                            </div>
                          </div>
                        </div>
                        <template v-if="profile.dayTemplates[dayIndex].isOnVacation">
                          <strong>{{ profile.dayTemplates[dayIndex].vacation.title }}</strong>
                        </template>
                        <template v-else>
                          <strong>{{ 'PRESENCE_TIME' | fromTextKey }}:</strong>
                          <span
                            v-if="profile.dayTemplates[dayIndex] != null"
                            :class="{
                              'alert-specific-time':
                                profile.dayTemplates[dayIndex].isPlannedTimesOutsideOpeningHours === true &&
                                !isTimeBetween(
                                  profile.dayTemplates[dayIndex].entryTime,
                                  profile.dayTemplates[dayIndex],
                                  profile.institutionProfile.institutionCode,
                                  profile.dayTemplates[dayIndex].activityType
                                ),
                            }"
                          >
                            <template
                              v-if="
                                profile.dayTemplates[dayIndex].isDefaultEntryTime &&
                                profile.dayTemplates[dayIndex].isDefaultExitTime
                              "
                            >
                              {{ 'PRESENCE_TIME_NOT_SPECIFIED' | fromTextKey }}
                            </template>
                            <template v-else>
                              {{ profile.dayTemplates[dayIndex].entryTime }}
                              <template v-if="profile.dayTemplates[dayIndex].activityType != exitTypes.SELF_DECIDER">
                                -
                                {{ profile.dayTemplates[dayIndex].exitTime }}
                              </template>
                              <template v-else>
                                -
                                {{ profile.dayTemplates[dayIndex].selfDeciderEndTime }}
                              </template>
                            </template>
                          </span>
                          <br />
                          <span
                            v-if="
                              profile.dayTemplates[dayIndex].activityType == exitTypes.PICKED_UP_BY &&
                              profile.dayTemplates[dayIndex].exitWith
                            "
                          >
                            <strong>{{ 'PRESENCE_PICKUP_TYPE_PICKUP' | fromTextKey }}:</strong>
                            {{ profile.dayTemplates[dayIndex].exitWith }}
                            <br />
                          </span>
                          <span v-if="profile.dayTemplates[dayIndex].activityType == exitTypes.GO_HOME_WITH">
                            <strong>{{ 'PRESENCE_PICKUP_TYPE_GO_HOME_WITH_STATUS' | fromTextKey }}:</strong>
                            {{ profile.dayTemplates[dayIndex].exitWith }}
                            <br />
                          </span>
                          <span v-if="profile.dayTemplates[dayIndex].activityType == exitTypes.SEND_HOME">
                            <strong>{{ 'PRESENCE_PICKUP_TYPE_SEND_HOME' | fromTextKey }}</strong>
                            <br />
                          </span>
                          <span v-if="profile.dayTemplates[dayIndex].activityType == exitTypes.SELF_DECIDER">
                            <strong>{{ 'PRESENCE_PICKUP_TYPE_SELF_DECIDER' | fromTextKey }}</strong>
                            <span
                              v-if="showSelfDecider(profile)"
                              :class="{
                                'alert-specific-time': getSelfDeciderClass(profile, dayIndex),
                              }"
                            >
                              :
                              {{ profile.dayTemplates[dayIndex].selfDeciderStartTime }}
                              -
                              {{ profile.dayTemplates[dayIndex].selfDeciderEndTime }}
                            </span>
                            <br />
                          </span>
                          <span v-if="profile.dayTemplates[dayIndex].spareTimeActivity != null"
                            ><strong>{{ 'PRESENCE_SPARE_TIME' | fromTextKey }}:</strong>
                            {{ profile.dayTemplates[dayIndex].spareTimeActivity.startTime }}
                            -
                            {{ profile.dayTemplates[dayIndex].spareTimeActivity.endTime }}
                            <span v-if="profile.dayTemplates[dayIndex].spareTimeActivity.comment"
                              >- {{ profile.dayTemplates[dayIndex].spareTimeActivity.comment }}</span
                            >
                            <br
                          /></span>
                          <span v-if="profile.dayTemplates[dayIndex].comment"
                            ><strong>{{ 'PRESENCE_COMMENT' | fromTextKey }}:</strong>
                            {{ profile.dayTemplates[dayIndex].comment }}<br
                          /></span>
                          <span v-if="profile.dayTemplates[dayIndex].isRepeating">
                            <template v-if="profile.dayTemplates[dayIndex].repeatToDate == null">
                              {{ 'PRESENCE_DASHBOARD_REPEATED_WEEKLY' | fromTextKey }}
                            </template>
                            <template v-else>
                              {{ 'PRESENCE_DASHBOARD_REPEATED_FROM' | fromTextKey }}
                              {{ moment(profile.dayTemplates[dayIndex].repeatToDate).format('DD-MM-YYYY') }}
                            </template>
                            <b-btn
                              variant="link"
                              class="ml-2"
                              :title="'PRESENCE_ARIA_LABEL_DELETE_REPETITION' | fromTextKey"
                              @click="confirmToDeleteRepetition(presenceIndex, profileIndex, dayIndex)"
                            >
                              <i class="icon-Aula_bin" />
                            </b-btn>
                          </span>
                          <br />
                        </template>
                        <div
                          v-if="
                            profile.dayTemplates[dayIndex].isPlannedTimesOutsideOpeningHours === true &&
                            profile.dayTemplates[dayIndex].isOnVacation === false
                          "
                          class="specific-open-hours-alert-container mt-1"
                        >
                          <div class="aula-alert mb-3">
                            <div class="icon-box-exclamation">
                              <div class="circle">
                                <i class="icon-Aula_exclamation_sign" />
                              </div>
                            </div>
                            <div class="alert-text">
                              {{ 'PRESENCE_ALERT_OUTSIDE_SPECIFIC_HOURS' | fromTextKey }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <template v-if="getCanEditPresenceTemplate(presenceDate, profile, dayIndex)">
                        <b-dropdown
                          v-if="profile.dayTemplates[dayIndex].isRepeating"
                          class="dropdown-select"
                          variant="link"
                          right
                        >
                          <template #button-content>
                            <Icon :name="iconClassEnum.EDIT_PEN" />
                          </template>
                          <b-dropdown-item-btn @click="editingDayTemplate(presenceIndex, profileIndex, dayIndex)">
                            {{ 'PRESENCE_TEMPLATE_EDIT_SINGLE_DAY' | fromTextKey }}
                          </b-dropdown-item-btn>
                          <b-dropdown-item-btn @click="editingDayTemplate(presenceIndex, profileIndex, dayIndex, true)">
                            {{ 'PRESENCE_TEMPLATE_EDIT_SERIES' | fromTextKey }}
                          </b-dropdown-item-btn>
                        </b-dropdown>
                        <AulaButton
                          v-else
                          class="px-2"
                          variant="link"
                          @click="editingDayTemplate(presenceIndex, profileIndex, dayIndex)"
                        >
                          <Icon :name="iconClassEnum.EDIT_PEN" />
                        </AulaButton>
                      </template>
                    </div>
                  </template>
                </template>
              </div>
            </template>
          </template>
        </div>
      </template>
    </div>
    <aula-modal
      ref="editTemplate"
      :css-class="isMobile ? 'register-absence-modal modal-fullscreen' : 'register-absence-modal'"
      :header-text="'PRESENCE_TIME_TITLE_FORM'"
      :hide-header="isMobile"
      :disable-close="isMobile"
      :hide-footer="true"
      :ok-text="'BUTTON_CLOSE'"
      @hidden="closeEditDayTemplate"
    >
      <div class="register-absence">
        <div v-if="isEditingTemplate" class="register-absence-form">
          <b-row v-if="isMobile">
            <b-col cols="3">
              <b-btn variant="link" :aria-label="'ARIA_LABEL_BACK' | fromTextKey" @click="closeEditDayTemplate">
                <i class="icon-Aula_app_arrow_slideback" />
              </b-btn>
            </b-col>
            <b-col class="title" cols="6">
              {{ 'PRESENCE_TIME_TITLE_FORM' | fromTextKey }}
            </b-col>
            <b-col cols="3" class="text-right">
              <b-btn variant="link" class="save-button" @click="onBeforeSubmitEditTemplate()">
                {{ 'BUTTON_SAVE' | fromTextKey }}
              </b-btn>
            </b-col>
          </b-row>
          <div
            v-if="isSpecificOpenHours === true"
            class="specific-open-hours-alert-container"
            :class="{ 'mt-2': isMobile }"
          >
            <div class="aula-alert mb-3">
              <div class="icon-box-exclamation">
                <div class="circle">
                  <i class="icon-Aula_exclamation_sign" />
                </div>
              </div>
              <div class="alert-text">
                {{ 'PRESENCE_NOTE' | fromTextKey }}: {{ 'PRESENCE_SPECIFIC_TIME_INFO' | fromTextKey }}:
                {{ getInstitutionSpecificOpenHours }}
              </div>
            </div>
          </div>
          <b-row class="mt-4">
            <b-col md="6" class="editing-profile">
              <user-icon
                :id="editPresenceProfile.id"
                :name="editPresenceProfile.name"
                :short-name="editPresenceProfile.shortName"
                :picture-object="editPresenceProfile.profilePicture"
                :show-inline="true"
              />
              <span class="profile-name"
                >{{ editPresenceProfile.name }}
                <template v-if="editPresenceProfile.isMultipleInstitution"
                  >({{ editPresenceProfile.institutionName }})</template
                ></span
              >
            </b-col>
          </b-row>
          <b-row :class="{ 'mt-4': !isMobile }">
            <b-col md="6" class="mt-3">
              <label v-if="!isMobile"
                >{{ 'PRESENCE_CHOSEN_DATE' | fromTextKey }}:
                {{ moment(templateForm.byDate).format('DD-MM-YYYY') }}</label
              >
              <label v-else
                >{{ 'PRESENCE_CHOSEN_DATE_MOBILE' | fromTextKey }}:
                <span class="mobile-short-date">{{ templateForm.shortDate }}</span></label
              >
            </b-col>
          </b-row>
          <b-row v-if="activeModules.length > 0" class="mt-4">
            <b-col lg="6" sm="12">
              <label>{{ 'PRESENCE_PICKUP_TYPES' | fromTextKey }}</label>
              <aula-select
                id="pick-up-type"
                v-model="templateForm.activityType"
                :placeholder="'SELECT' | fromTextKey"
                :aria-label="'ARIA_LABEL_PRESENCE_PICKUP_TYPES' | fromTextKey"
                @change="updatePickUpType"
              >
                <aula-option
                  v-for="(module, index) of activeModules"
                  :key="index"
                  :label="module.label"
                  :value="module.value"
                />
              </aula-select>
            </b-col>
          </b-row>
          <b-row
            v-if="templateForm.config.selfDecider && templateForm.activityType == exitTypes.SELF_DECIDER"
            class="mt-4"
          >
            <b-col>
              <label>{{ 'PRESENCE_SELF_DECIDER_START_TIME' | fromTextKey }}</label>
              <timepicker
                id="self-decider-start-time"
                v-model="templateForm.selfDeciderStartTime"
                format="HH:mm"
                hour-label="Time"
                minute-label="Minut"
                :placeholder="'PRESENCE_PLACEHOLDER' | fromTextKey"
                :aria-label="'ARIA_LABEL_PRESENCE_SELF_DECIDER_START_TIME' | fromTextKey"
                :class="{
                  'red-border': isExitTimeOutsideOpenHoursAlertVisible,
                }"
                hide-clear-button
                :minute-interval="timepickerSettings.DEFAULT_INTERVAL"
                :start="timepickerStart"
                :end="timepickerEnd"
                advanced-keyboard
                @change="changeExitTime()"
              />
            </b-col>
            <b-col>
              <label>{{ 'PRESENCE_SELF_DECIDER_END_TIME' | fromTextKey }}</label>
              <timepicker
                id="self-decider-end-time"
                v-model="templateForm.selfDeciderEndTime"
                format="HH:mm"
                hour-label="Time"
                minute-label="Minut"
                :placeholder="'PRESENCE_PLACEHOLDER' | fromTextKey"
                :aria-label="'ARIA_LABEL_PRESENCE_SELF_DECIDER_END_TIME' | fromTextKey"
                :class="{
                  'red-border': isExitTimeOutsideOpenHoursAlertVisible || showInvalidSelfDeciderExitTimeAlert,
                }"
                hide-clear-button
                :minute-interval="timepickerSettings.DEFAULT_INTERVAL"
                :start="timepickerStart"
                :end="timepickerEnd"
                advanced-keyboard
                @change="changeExitTime()"
              />
              <b-alert variant="danger" :show="isInvalidSelfDeciderTimesAlertVisible">
                {{ 'PRESENCE_ALERT_INVALID_SELF_DECIDER_TIMES' | fromTextKey }}
              </b-alert>
            </b-col>
            <b-col cols="12"> </b-col>
            <b-col
              v-if="isExitTimeOutsideOpenHoursAlertVisible && !showInvalidSelfDeciderExitTimeAlert"
              cols="12"
              class="mt-4"
            >
              <b-alert
                variant="danger"
                :show="isExitTimeOutsideOpenHoursAlertVisible && !showInvalidSelfDeciderExitTimeAlert"
              >
                {{ 'PRESENCE_ALERT_OUTSIDE_OPEN_HOURS' | fromTextKey }}
              </b-alert>
            </b-col>
          </b-row>
          <b-row
            v-if="
              (templateForm.config.pickup || templateForm.config.goHomeWith) &&
              (templateForm.activityType == exitTypes.GO_HOME_WITH ||
                templateForm.activityType == exitTypes.PICKED_UP_BY)
            "
            class="mt-4"
          >
            <b-col
              v-if="
                templateForm.activityType == exitTypes.PICKED_UP_BY ||
                templateForm.activityType == exitTypes.GO_HOME_WITH
              "
              cols="12"
            >
              <label v-if="templateForm.activityType == exitTypes.PICKED_UP_BY"
                >{{ 'PRESENCE_CHOOSE_PICKUP_TYPE_LABEL' | fromTextKey }} <span class="mandatory">*</span></label
              >
              <aula-select
                v-if="templateForm.activityType == exitTypes.PICKED_UP_BY && pickupResponsibles.length > 0"
                id="exit-with"
                v-model="templateForm.exitWith"
                :placeholder="'SELECT' | fromTextKey"
                :aria-label="'ARIA_LABEL_PRESENCE_CHOOSE_PICKUP_TYPE' | fromTextKey"
                @change="isMissingExitWithAlertVisible = false"
              >
                <aula-option
                  v-for="(option, i) in pickupResponsibles[0].relatedPersons"
                  :key="'rp' + i"
                  :label="option.name + ' (' + option.relation + ')'"
                  :value="option.name + ' (' + option.relation + ')'"
                />
                <aula-option
                  v-for="(option, i) in pickupResponsibles[0].pickupSuggestions"
                  :key="'ps' + i"
                  :label="option.pickupName"
                  :value="option.pickupName"
                />
                <div class="new-pickup">
                  <b-btn
                    v-if="newPickupResponsible.trim().length > 0"
                    class="pickup-responsible-save"
                    @click="saveNewPickupResponsible()"
                  >
                    {{ 'SUBMIT' | fromTextKey }}
                  </b-btn>
                  <b-form-input
                    id="new-exit-with"
                    v-model="newPickupResponsible"
                    class="new-pickup-responsible"
                    :class="{
                      'red-border': showAlertTooShortName,
                      'show-button': newPickupResponsible.trim().length > 0,
                    }"
                    :placeholder="'PRESENCE_PICKUP_RESPONSIBLE_NAME' | fromTextKey"
                    :aria-label="'ARIA_LABEL_PRESENCE_PICKUP_RESPONSIBLE_NAME' | fromTextKey"
                    maxlength="100"
                    @keydown.enter="saveNewPickupResponsible()"
                    @input="showAlertTooShortName = false"
                  />
                  <b-alert variant="danger" :show="showAlertTooShortName">
                    {{ 'PRESENCE_WARNING_PICKUP_NAME_TOO_SHORT' | fromTextKey }}
                  </b-alert>
                </div>
              </aula-select>
              <label v-if="templateForm.activityType == exitTypes.GO_HOME_WITH"
                >Vælg hvem {{ editPresenceProfile.name }} skal gå hjem med <span class="mandatory">*</span></label
              >
              <aula-select
                v-if="templateForm.activityType == exitTypes.GO_HOME_WITH"
                id="go-home-with"
                v-model="templateForm.exitWith"
                filterable
                :placeholder="'SELECT' | fromTextKey"
                :aria-label="'ARIA_LABEL_PRESENCE_CHOOSE_GO_HOME_WITH' | fromTextKey"
                @change="isMissingExitWithAlertVisible = false"
              >
                <aula-option
                  v-for="(option, i) in goHomeWithList"
                  :key="'hw' + i"
                  :label="option.fullName + ' (' + option.mainGroup + ')'"
                  :value="option.fullName + ' (' + option.mainGroup + ')'"
                />
              </aula-select>
              <b-alert variant="danger" :show="isMissingExitWithAlertVisible">
                {{ 'PRESENCE_ALERT_NO_EXIT_WITH' | fromTextKey }}
              </b-alert>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col v-if="templateForm.config.dropOffTime">
              <label>{{ 'PRESENCE_DELIVERY_TIME' | fromTextKey }}</label>
              <timepicker
                id="entry-time"
                v-model="templateForm.entryTime"
                format="HH:mm"
                hour-label="Time"
                minute-label="Minut"
                :placeholder="'PRESENCE_PLACEHOLDER' | fromTextKey"
                :aria-label="'ARIA_LABEL_PRESENCE_DELIVERY_TIME' | fromTextKey"
                :class="{
                  'red-border': isMissingEntryTimeAlertVisible || isEntryTimeOutsideOpenHoursAlertVisible,
                }"
                hide-clear-button
                :minute-interval="timepickerSettings.DEFAULT_INTERVAL"
                :start="timepickerStart"
                :end="timepickerEnd"
                advanced-keyboard
                @change="
                  changeEntryTime();
                  isMissingEntryTimeAlertVisible = false;
                "
              />
              <b-alert variant="danger" :show="isMissingEntryTimeAlertVisible">
                {{ 'PRESENCE_WARNING_NO_EXIT_TIME' | fromTextKey }}
              </b-alert>
              <b-alert variant="danger" :show="isInvalidExitTimeAlertVisible">
                {{ 'PRESENCE_ALERT_INVALID_PICK_UP_TIME' | fromTextKey }}
              </b-alert>
              <b-alert variant="danger" :show="isEntryTimeOutsideOpenHoursAlertVisible">
                {{ 'PRESENCE_ALERT_OUTSIDE_OPEN_HOURS' | fromTextKey }}
              </b-alert>
            </b-col>
            <b-col v-if="templateForm.activityType != exitTypes.SELF_DECIDER && templateForm.config.pickupTime">
              <label>{{ 'PRESENCE_PICK_UP_TIME' | fromTextKey }}</label>
              <timepicker
                id="exit-time"
                v-model="templateForm.exitTime"
                format="HH:mm"
                hour-label="Time"
                minute-label="Minut"
                :placeholder="'PRESENCE_PLACEHOLDER' | fromTextKey"
                :aria-label="'ARIA_LABEL_PRESENCE_PICK_UP_TIME' | fromTextKey"
                :class="{
                  'red-border':
                    isMissingExitTimeAlertVisible ||
                    isInvalidExitTimeAlertVisible ||
                    isExitTimeOutsideOpenHoursAlertVisible,
                }"
                hide-clear-button
                :minute-interval="timepickerSettings.DEFAULT_INTERVAL"
                :start="timepickerStart"
                :end="timepickerEnd"
                advanced-keyboard
                @change="changeExitTime()"
              />
              <b-alert variant="danger" :show="isMissingExitTimeAlertVisible">
                {{ 'PRESENCE_WARNING_NO_EXIT_TIME' | fromTextKey }}
              </b-alert>
              <b-alert variant="danger" :show="isExitTimeOutsideOpenHoursAlertVisible">
                {{ 'PRESENCE_ALERT_OUTSIDE_OPEN_HOURS' | fromTextKey }}
              </b-alert>
            </b-col>
          </b-row>
          <b-row v-if="templateForm.config.spareTimeActivity" class="mt-4 pb-2 white-border-bottom">
            <b-col cols="12">
              <b-form-checkbox id="spare-activity" v-model="isSpareTimeActivityVisible">
                {{ 'PRESENCE_SEND_TO_SPARETIME_ACTIVITY' | fromTextKey }}
              </b-form-checkbox>
            </b-col>
            <b-col v-if="isSpareTimeActivityVisible" cols="12" class="mt-2">
              <b-row>
                <b-col>
                  <label>{{ 'PRESENCE_SELF_DECIDER_START_TIME' | fromTextKey }}</label>
                  <timepicker
                    id="spare-activity-start-time"
                    v-model="spareTimeActivity.startTime"
                    format="HH:mm"
                    hour-label="Time"
                    minute-label="Minut"
                    :placeholder="'PRESENCE_PLACEHOLDER' | fromTextKey"
                    :aria-label="'ARIA_LABEL_PRESENCE_SPARE_ACTIVITY_FROM' | fromTextKey"
                    hide-clear-button
                    :minute-interval="timepickerSettings.DEFAULT_INTERVAL"
                    :start="timepickerStart"
                    :end="timepickerEnd"
                    advanced-keyboard
                  />
                </b-col>
                <b-col>
                  <label>{{ 'PRESENCE_SELF_DECIDER_END_TIME' | fromTextKey }}</label>
                  <timepicker
                    id="spare-activity-end-time"
                    v-model="spareTimeActivity.endTime"
                    format="HH:mm"
                    hour-label="Time"
                    minute-label="Minut"
                    :placeholder="'PRESENCE_PLACEHOLDER' | fromTextKey"
                    :aria-label="'ARIA_LABEL_PRESENCE_SPARE_ACTIVITY_TO' | fromTextKey"
                    hide-clear-button
                    :minute-interval="timepickerSettings.DEFAULT_INTERVAL"
                    :start="timepickerStart"
                    :end="timepickerEnd"
                    advanced-keyboard
                  />
                </b-col>
              </b-row>
            </b-col>
            <b-col v-if="showInvalidActivityExitTimeAlert" cols="12" class="mt-4">
              <b-alert variant="danger" :show="showInvalidActivityExitTimeAlert">
                {{ 'PRESENCE_ALERT_INVALID_START_AND_END_TIME' | fromTextKey }}
              </b-alert>
            </b-col>
            <b-col v-if="isSpareTimeActivityVisible" cols="12" class="mt-4">
              <label>{{ 'PRESENCE_COMMENT_SPARETIME_ACTIVITY' | fromTextKey }}</label>
              <b>{{ 'PRESENCE_COMMENT_MAX_CHARACTERS' | fromTextKey }}</b>
              <b-textarea
                id="spare-activity-comment"
                v-model="spareTimeActivity.comment"
                :aria-label="'ARIA_LABEL_PRESENCE_COMMENT_SPARETIME_ACTIVITY' | fromTextKey"
                maxlength="250"
                max-rows="1"
                rows="1"
                class="absence-input"
              />
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col cols="12">
              <label>{{ 'PRESENCE_COMMENT' | fromTextKey }}</label>
              <b>{{ 'PRESENCE_COMMENT_MAX_CHARACTERS' | fromTextKey }}</b>
              <b-textarea
                id="comment"
                v-model="templateForm.comment"
                maxlength="250"
                max-rows="1"
                rows="1"
                class="absence-input"
                :placeholder="'PRESENCE_COMMENT_PLACE_HOLDER' | fromTextKey"
                :aria-label="'ARIA_LABEL_PRESENCE_COMMENT_PLACE_HOLDER' | fromTextKey"
              />
              <b-alert variant="danger" :show="hasCommentReachedMaxCharacterLimit" class="mt-1 mb-0">
                {{ 'PRESENCE_COMMENT_CHARACTER_LIMIT_REACHED' | fromTextKey }}
              </b-alert>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col cols="12" lg="6" class="weekly-repeat">
              <b-form-checkbox
                id="repeating"
                v-model="templateForm.isRepeating"
                :value="true"
                @change="onRepeatWeeklyChange()"
              >
                {{ 'PRESENCE_REPETITION' | fromTextKey }}
              </b-form-checkbox>
              <help-box
                id="tooltip-repetition"
                class="presence-repitition-helpbox"
                :title="$options.filters.fromTextKey('PRESENCE_REPETITION_HELP_TITLE')"
                :body="$options.filters.fromTextKey('PRESENCE_REPETITION_HELP_TEXT')"
              />
            </b-col>
            <b-col v-if="templateForm.isRepeating" cols="12" lg="6">
              <label>{{ 'PRESENCE_REPETITION_END_DATE' | fromTextKey }}</label>
              <el-date-picker
                id="deadline-date"
                v-model="templateForm.repeatToDate"
                class="endDate"
                :format="defaultDateFormat"
                :value-format="defaultDateValueFormat"
                type="date"
                :aria-label="'ARIA_LABEL_PRESENCE_REPETITION_END_DATE' | fromTextKey"
                :placeholder="'PRESENCE_REPETITION_END_DATE' | fromTextKey"
                :picker-options="availableEndDate"
                @input="onUpdateToDate"
                @focus="datePickerModifier()"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="!isMobile" cols="12" class="text-right mt-5">
              <b-button variant="link" class="mr-4" @click="closeEditDayTemplate">
                {{ 'CANCEL' | fromTextKey }}
              </b-button>
              <aula-spinner v-if="isEditingLoading" class="editing-loading" />
              <b-button
                variant="primary"
                class="send-btn"
                :disabled="isEditingLoading"
                @click="onBeforeSubmitEditTemplate"
              >
                {{ 'BUTTON_SAVE' | fromTextKey }}
              </b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </aula-modal>
    <aula-modal
      ref="warningSaveOverlappingTemplate"
      @okClicked="onSaveOverlappingTemplate()"
      @cancelClicked="cancelSaveOverlappingTemplate"
    >
      <span v-if="isOverlappingOldTemplate()">
        <template v-if="isEndDateDefinedInOverlappingPresenceTemplate">
          {{
            'PRESENCE_WARNING_DATES_OVERLAPPING'
              | fromTextKey({
                start: moment(overlappingPresenceTemplates.startDate).format('DD/MM/YYYY'),
                end: moment(overlappingPresenceTemplates.endDate).format('DD/MM/YYYY'),
              })
          }}
        </template>
        <template v-else>
          {{
            'PRESENCE_WARNING_DATES_OVERLAPPING_MISSING_END_DATE'
              | fromTextKey({
                dateStart: moment(overlappingPresenceTemplates.startDate).format('DD/MM/YYYY'),
              })
          }}
        </template>
      </span>
    </aula-modal>
    <aula-modal
      ref="warningDeleteRepetition"
      ok-text="YES"
      @okClicked="deleteRepetition"
      @cancelClicked="cancelDeleteRepetition"
    >
      {{ warningDeleteRepeat }}
    </aula-modal>
    <aula-modal ref="warningUpdate" :show-cancel="false" ok-text="YES" @okClicked="$refs.warningUpdate.hide()">
      {{ 'PRESNECE_CANNOT_UPDATE_TEMPLATE_IN_THE_PAST' | fromTextKey }}
    </aula-modal>
  </div>
</template>
<script>
import { types } from '../../store/types/types';
import { exitTypes } from '../../../shared/enums/exitTypes';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment-timezone';
import Vue from 'vue';
import { presenceModules } from '../../../shared/enums/presenceModules';
import { pickupTypes } from '../../../shared/enums/pickupTypes';
import { dateFormatTypes } from '../../../shared/enums/dateFormatTypes';
import { presenceOpenHours } from '../../../shared/enums/presenceOpenHours';
import cloneDeep from 'lodash/cloneDeep';
import $ from 'jquery';
import { comeGoUtil } from '../../../shared/utils/comeGoUtil';
import Timepicker from '../../../shared/components/Timepicker';
import { timepickerSettings } from '../../../shared/enums/timepickerSettings';
import Icon from '../../../shared/components/Icon.vue';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import * as dateUtil from '../../../shared/utils/dateUtil';
import AulaButton from '../../../shared/components/AulaButton.vue';
import { dateFormatEnum } from '../../../shared/enums/dateFormatEnum';

export default {
  components: { AulaButton, Icon, Timepicker },
  data: function () {
    return {
      repeatDateFormat: dateFormatEnum.COMPLETE_DATE,
      timepickerSettings: timepickerSettings,
      presenceOpenHours: presenceOpenHours,
      exitTypes: exitTypes,
      presenceModules: presenceModules,
      isLoading: false,
      isLoadingTemplate: false,
      datepickerDate: null,
      presenceTimes: [],
      viewPresenceProfile: null,
      editPresenceProfile: null,
      isEditingLoading: false,
      isEditingTemplate: false,
      newPickupResponsible: '',
      showAlertTooShortName: false,
      currentWeekView: [],
      matchedNames: [],
      editTemplateProfileIndex: null,
      editTemplatePresenceIndex: null,
      editTemplateDayIndex: null,
      availableDate: {
        disabledDate(time) {
          return (
            time.getTime() + 86400000 < moment().startOf('week').toDate().getTime() ||
            time.getTime() + 86400000 > moment().add(1, 'year').toDate().getTime()
          );
        },
        firstDayOfWeek: 1,
      },
      isMissingEntryTimeAlertVisible: false,
      isMissingExitTimeAlertVisible: false,
      isInvalidExitTimeAlertVisible: false,
      isMissingExitWithAlertVisible: false,
      isInvalidSelfDeciderTimesAlertVisible: false,
      isEntryTimeClicked: false,
      isExitTimeClicked: false,
      isSpareTimeActivityVisible: false,
      isEntryTimeOutsideOpenHoursAlertVisible: false,
      isExitTimeOutsideOpenHoursAlertVisible: false,
      dateOfWeekFull: ['Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag'],
      templateForm: {
        config: {
          pickup: false,
          goHomeWith: false,
          selfDecider: false,
          sendHome: false,
          spareTimeActivity: false,
          dropOffTime: false,
        },
        shortDate: null,
        dayOfWeek: null,
        byDate: null,
        institutionProfileId: null,
        entryTime: '',
        exitTime: '',
        activityType: null,
        exitWith: null,
        selfDeciderStartTime: '',
        selfDeciderEndTime: '',
        comment: null,
        isRepeating: false,
        repeatToDate: null,
        defaultEndDate: null,
      },
      availableEndDate: {},
      spareTimeActivity: {
        startTime: '',
        endTime: '',
        comment: '',
      },
      activeSFOChildrenIds: [],
      activeModules: [],
      deleteRepeatTemplate: null,
      warningDeleteRepeat: null,
      selectedDate: null,
      selectedTemplateInstitutionCode: null,
      defaultEntryTime: '08:00',
      defaultExitTime: '16:00',
      defaultSelfDeciderStartTime: '15:00',
      defaultSelfDeciderEndTime: '16:00',
    };
  },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
    ...mapGetters({
      children: types.GET_CHILDREN,
      activeChildren: types.GET_ACTIVE_CHILDREN,
      institutions: types.GET_INSTITUTIONS,
      isMobile: types.GET_IS_MOBILE,
      getPresenceTimes: types.GET_PRESENCE_TIMES,
      defaultDateFormat: types.GET_DEFAULT_DATE_FORMAT,
      defaultDateValueFormat: types.GET_DEFAULT_DATE_VALUE_FORMAT,
      defaultTimeFormat: types.GET_DEFAULT_TIME_FORMAT,
      defaultTimeValuesFormat: types.GET_DEFAULT_TIME_VALUE_FORMAT,
      pickupResponsibles: types.GET_PICKUP_RESPONSIBLES,
      goHomeWithList: types.GET_GO_HOME_WITH_LIST,
      getPresenceStates: types.GET_PRESENCE_STATES_GUARDIAN,
      getPresenceActiveModules: types.GET_PRESENCE_ACTIVE_MODULES,
      openHours: types.GET_PRESENCE_OPEN_HOURS_GUARDIAN_DASHBOARD,
      holidayAndClosedDays: types.GET_HOLIDAY_CLOSED_DAY,
      selectedPresenceWeek: types.GET_SELECTED_PRESENCE_WEEK,
      overlappingPresenceTemplates: types.GET_OVERLAPPING_PRESENCE_TEMPLATES,
      hasCustodyOrExtendedAccess: types.GET_HAS_CUSTODY_OR_EXTENDED_ACCESS,
    }),
    presenceChildrenIds() {
      return this.presenceTimes.flatMap(presenceTime => presenceTime.presenceTimes.map(t => t.institutionProfile.id));
    },
    hasCommentReachedMaxCharacterLimit() {
      const comment = this.templateForm.comment;
      if (typeof comment !== 'string') {
        return false;
      }
      return comment.length >= 250;
    },
    timepickerStart() {
      const selectedInstitution = this.openHours.openingHoursOverviewDto.find(
        institution => institution.institutionCode == this.selectedTemplateInstitutionCode
      );
      const dateOfWeek = selectedInstitution?.openingHoursDto.find(
        hour => hour.date.localeCompare(this.selectedDate) === 0
      );
      if (
        dateOfWeek &&
        (dateOfWeek.type === presenceOpenHours.SPECIFIC_OPEN_HOURS ||
          dateOfWeek.type === presenceOpenHours.GENERAL_OPEN_HOURS)
      ) {
        return dateOfWeek.openTime;
      }
      return comeGoUtil.defaultStart();
    },
    timepickerEnd() {
      const selectedInstitution = this.openHours.openingHoursOverviewDto.find(
        institution => institution.institutionCode == this.selectedTemplateInstitutionCode
      );
      const dateOfWeek = selectedInstitution?.openingHoursDto.find(
        hour => hour.date.localeCompare(this.selectedDate) === 0
      );
      if (
        dateOfWeek &&
        (dateOfWeek.type === presenceOpenHours.SPECIFIC_OPEN_HOURS ||
          dateOfWeek.type === presenceOpenHours.GENERAL_OPEN_HOURS)
      ) {
        return dateOfWeek.closeTime;
      }
      return comeGoUtil.defaultEnd();
    },
    selectedDateOpenHours() {
      if (!this.selectedTemplateInstitutionCode && !this.selectedDate) {
        return null;
      }
      const selectedInstitutionOpenHours = this.openHours.openingHoursOverviewDto.find(
        institution => institution.institutionCode === this.selectedTemplateInstitutionCode
      );
      return selectedInstitutionOpenHours?.openingHoursDto.find(hour => moment(hour.date).isSame(this.selectedDate));
    },
    isSpecificOpenHours() {
      return this.selectedDateOpenHours?.type === presenceOpenHours.SPECIFIC_OPEN_HOURS;
    },
    getInstitutionSpecificOpenHours() {
      const selectedInstitution = this.openHours.openingHoursOverviewDto.find(
        institution => institution.institutionCode == this.selectedTemplateInstitutionCode
      );
      const dateOfWeek = selectedInstitution?.openingHoursDto.find(
        hour => hour.date.localeCompare(this.selectedDate) === 0
      );
      if (dateOfWeek && dateOfWeek.type === presenceOpenHours.SPECIFIC_OPEN_HOURS) {
        return dateOfWeek.openTime + ' - ' + dateOfWeek.closeTime;
      }
      return '';
    },
    presenceChildren() {
      let activeChildren = [];
      const children = this.getPresenceStates.filter(child => this.activeChildren.includes(child.uniStudentId));
      const presenceChildIds = children.map(i => i.uniStudentId);
      activeChildren = this.children.filter(child => presenceChildIds.includes(child.id));
      return activeChildren;
    },
    showInvalidSelfDeciderExitTimeAlert() {
      if (!this.isSelfDeciderEnabledModule) {
        return false;
      }
      if (
        this.templateForm.selfDeciderStartTime &&
        this.templateForm.selfDeciderEndTime &&
        this.templateForm.activityType == exitTypes.SELF_DECIDER
      ) {
        const startTime = moment(this.templateForm.selfDeciderStartTime, 'HH:mm', true);
        const endTime = moment(this.templateForm.selfDeciderEndTime, 'HH:mm', true);
        if (!startTime.isValid() || !endTime.isValid() || endTime.isSameOrBefore(startTime)) {
          return true;
        }
      }
      return false;
    },
    showInvalidActivityExitTimeAlert() {
      if (
        this.isSpareTimeActivityVisible &&
        this.spareTimeActivity &&
        this.spareTimeActivity.startTime &&
        this.spareTimeActivity.endTime
      ) {
        const startTime = moment(this.spareTimeActivity.startTime, 'HH:mm', true);
        const endTime = moment(this.spareTimeActivity.endTime, 'HH:mm', true);
        if (!startTime.isValid() || !endTime.isValid() || endTime.isSameOrBefore(startTime)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    presenceColsCss() {
      let css = 'coloumn1';
      if (!this.isSeparately) {
        css = 'coloumn' + this.getPresenceTimes.presenceWeekTemplates.length;
      }
      return css;
    },
    isSeparately() {
      return this.getPresenceTimes.presenceWeekTemplates.length > 3 || this.isMobile;
    },
    showInvalidSelfDeciderStartTimeAlert() {
      if (!this.isSelfDeciderEnabledModule) {
        return false;
      }

      if (!this.templateForm.selfDeciderStartTime) {
        return true;
      }

      if (!this.templateForm.entryTime) {
        return false;
      }

      if (this.templateForm.activityType === exitTypes.SELF_DECIDER) {
        const [selfDeciderStartHours, selfDeciderStartMinutes] = this.templateForm.selfDeciderStartTime.split(':');
        const [entryTimeHours, entryTimeMinutes] = this.templateForm.entryTime.split(':');
        const selfDeciderStart = moment().hours(selfDeciderStartHours).minutes(selfDeciderStartMinutes);
        const entryTime = moment().hours(entryTimeHours).minutes(entryTimeMinutes);
        return selfDeciderStart.isSameOrBefore(entryTime);
      }

      return false;
    },
    isSelfDeciderEnabledModule() {
      return this.activeModules.includes(exitTypes.SELF_DECIDER);
    },
    isEndDateDefinedInOverlappingPresenceTemplate() {
      const endDate = this.overlappingPresenceTemplates?.endDate;
      return endDate != null && endDate !== '';
    },
  },
  methods: {
    ...mapMutations({
      setSelectedPresenceWeek: types.MUTATE_SELECTED_PRESENCE_WEEK,
    }),
    ...mapActions({
      loadPresenceTimes: types.LOAD_PRESENCE_TIMES,
      updatePresenceTemplate: types.UPDATE_PRESENCE_TEMPLATE,
      loadPickupResponsibles: types.LOAD_PICKUP_RESPONSIBLES,
      loadGoHomeWithList: types.LOAD_GO_HOME_WITH_LIST,
      loadPresenceConfigurationByChildIds: types.LOAD_PRESENCE_CONFIGURATIONS_CHILD_IDS,
      savePickupResponsibles: types.SAVE_PICKUP_RESPONSIBLES,
      deleteRepeatingPresenceTemplate: types.DELETE_REPEATING_PRESENCE_TEMPLATE,
      loadOpenHoursForDay: types.LOAD_PRESENCE_OPEN_HOURS_GUARDIAN_DASHBOARD,
      loadHolidayAndClosedDays: types.LOAD_HOLIDAY_CLOSED_DAY,
      loadOverlappingPresenceTemplates: types.LOAD_OVERLAPPING_PRESENCE_TEMPLATES,
      resetOverlappingPresenceTemplates: types.RESET_OVERLAPPING_PRESENCE_TEMPLATES,
    }),
    getCanEditPresenceTemplate(presenceDate, profile, dayIndex) {
      const isOnVacation = Boolean(profile.dayTemplates[dayIndex]?.isOnVacation);
      return (
        !isOnVacation &&
        dateUtil.isTodayOrAfter(presenceDate.date) &&
        this.hasCustodyOrExtendedAccess(profile.institutionProfile.id)
      );
    },
    onRepeatWeeklyChange() {
      if (this.templateForm.isRepeating) {
        this.updateOverlappingPresenceTemplates();
      } else {
        this.resetOverlappingPresenceTemplates();
      }
    },
    onUpdateToDate() {
      this.updateOverlappingPresenceTemplates();
    },
    updateOverlappingPresenceTemplates() {
      let endDate = null;
      if (this.templateForm.repeatToDate != null) {
        endDate = moment(this.templateForm.repeatToDate, this.repeatDateFormat).format(dateFormatEnum.COMPLETE_DATE);
      }
      this.loadOverlappingPresenceTemplates({
        institutionProfileId: this.templateForm.institutionProfileId,
        startDate: moment(this.templateForm.byDate, this.repeatDateFormat).format(dateFormatEnum.COMPLETE_DATE),
        endDate,
      });
    },
    getSelfDeciderClass(profile, dayIndex) {
      return (
        profile.dayTemplates[dayIndex].isPlannedTimesOutsideOpeningHours === true &&
        (!this.isTimeBetween(
          profile.dayTemplates[dayIndex].selfDeciderStartTime,
          profile.dayTemplates[dayIndex],
          profile.institutionProfile.institutionCode,
          profile.dayTemplates[dayIndex].activityType
        ) ||
          !this.isTimeBetween(
            profile.dayTemplates[dayIndex].selfDeciderEndTime,
            profile.dayTemplates[dayIndex],
            profile.institutionProfile.institutionCode,
            profile.dayTemplates[dayIndex].activityType
          ))
      );
    },
    showSelfDecider(child) {
      return !!(
        this.getPresenceActiveModules.selfDecider.includes(child.institutionProfile.id) &&
        child.selfDeciderStartTime &&
        child.selfDeciderEndTime
      );
    },
    confirmToDeleteRepetition(presenceIndex, profileIndex, dayIndex) {
      this.deleteRepeatTemplate = this.presenceTimes[presenceIndex].presenceTimes[profileIndex].dayTemplates[dayIndex];
      if (this.deleteRepeatTemplate.repeatToDate == null) {
        this.warningDeleteRepeat = Vue.filter('fromTextKey')('PRESENCE_WARNING_DELETE_A_REPETITION_WITHOUT_END_DATE', {
          byDate: this.deleteRepeatTemplate.byDate,
        });
      } else {
        this.warningDeleteRepeat = Vue.filter('fromTextKey')('PRESENCE_WARNING_DELETE_A_REPETITION_WITH_END_DATE', {
          byDate: this.deleteRepeatTemplate.byDate,
          repeatToDate: moment(this.deleteRepeatTemplate.repeatToDate).format('DD-MM-YYYY'),
        });
      }
      this.$refs.warningDeleteRepetition.show();
    },
    cancelDeleteRepetition() {
      this.deleteRepeatTemplate = null;
      this.warningDeleteRepeat = null;
      this.$refs.warningDeleteRepetition.hide();
    },
    async deleteRepetition() {
      const deleteFromDay = moment(this.deleteRepeatTemplate.byDate, 'DD-MM-YYYY').format(dateFormatEnum.COMPLETE_DATE);
      await this.deleteRepeatingPresenceTemplate({
        presentTemplateId: this.deleteRepeatTemplate.id,
        deleteFromDay: deleteFromDay,
      });
      this.$refs.warningDeleteRepetition.hide();
      this.currentWeekView = this.presenceTimes.map(p => p.chosenWeek);
      const childIds = this.presenceChildren.map(child => child.id);
      if (childIds.length === 0) {
        return;
      }
      await this.loadChildrenPresenceTimes(
        childIds,
        dateUtil.startOfWeek(deleteFromDay),
        dateUtil.endOfWeek(deleteFromDay)
      );
      await this.keepCurrentWeekView();
    },
    openDatePicker() {
      if (this.$refs.chooseWeekDatepicker) {
        if ($('.presence-choose-week-popper').is(':visible')) {
          this.$refs.chooseWeekDatepicker[0].blur();
        } else {
          this.$refs.chooseWeekDatepicker[0].handleFocus();
        }
      }
    },
    saveNewPickupResponsible() {
      if (this.newPickupResponsible.trim().length < 2) {
        this.showAlertTooShortName = true;
      } else {
        this.savePickupResponsibles({
          uniStudentIds: [this.templateForm.institutionProfileId],
          pickupName: this.newPickupResponsible,
        }).then(() => {
          this.loadPickupResponsibles({
            uniStudentIds: [this.templateForm.institutionProfileId],
          });
          this.templateForm.exitWith = this.newPickupResponsible;
          this.newPickupResponsible = '';
        });
      }
    },
    changeEntryTime() {
      if (!this.templateForm.entryTime) {
        this.isMissingEntryTimeAlertVisible = true;
      } else {
        this.isInvalidExitTimeAlertVisible = false;
        this.validateTimeRange();
        if (this.templateForm.activityType === exitTypes.SELF_DECIDER) {
          this.validateEntryTimeAgainstSelfDeciderTimes();
        }
        if (this.templateForm.activityType !== exitTypes.SELF_DECIDER) {
          this.validateEntryTimeAgainstExitTime();
        }
      }
    },
    changeExitTime() {
      this.isMissingExitTimeAlertVisible = false;
      this.isExitTimeOutsideOpenHoursAlertVisible = false;
      if (
        !this.templateForm.exitTime &&
        !this.templateForm.selfDeciderStartTime &&
        !this.templateForm.selfDeciderEndTime
      ) {
        this.isMissingExitTimeAlertVisible = true;
        this.isInvalidExitTimeAlertVisible = false;
        return;
      }
      this.validateTimeRange();
      if (this.templateForm.activityType === exitTypes.SELF_DECIDER) {
        this.validateSelfDeciderTimes();
      } else {
        this.validateEntryTimeAgainstExitTime();
      }
    },
    validateEntryTimeAgainstSelfDeciderTimes() {
      if (this.templateForm.selfDeciderStartTime != null && this.templateForm.selfDeciderEndTime != null) {
        const entryTime = moment()
          .hours(this.templateForm.entryTime.split(':')[0])
          .minutes(this.templateForm.entryTime.split(':')[1]);
        const selfDeciderStartTime = moment()
          .hours(this.templateForm.selfDeciderStartTime.split(':')[0])
          .minutes(this.templateForm.selfDeciderStartTime.split(':')[1]);
        const selfDeciderEndTime = moment()
          .hours(this.templateForm.selfDeciderEndTime.split(':')[0])
          .minutes(this.templateForm.selfDeciderEndTime.split(':')[1]);
        if (entryTime.isAfter(selfDeciderStartTime) || entryTime.isSameOrAfter(selfDeciderEndTime)) {
          this.isMissingEntryTimeAlertVisible = false;
          this.isInvalidExitTimeAlertVisible = true;
        } else {
          this.isInvalidExitTimeAlertVisible = false;
        }
      }
    },
    validateEntryTimeAgainstExitTime() {
      if (this.templateForm.entryTime && this.templateForm.exitTime) {
        const entryTime = moment()
          .hours(this.templateForm.entryTime.split(':')[0])
          .minutes(this.templateForm.entryTime.split(':')[1]);
        const exitTime = moment()
          .hours(this.templateForm.exitTime.split(':')[0])
          .minutes(this.templateForm.exitTime.split(':')[1]);
        if (entryTime.isSameOrAfter(exitTime)) {
          this.isInvalidExitTimeAlertVisible = true;
          this.isMissingExitTimeAlertVisible = false;
          this.isMissingEntryTimeAlertVisible = false;
        } else {
          this.isInvalidExitTimeAlertVisible = false;
          this.isMissingExitTimeAlertVisible = false;
          this.isMissingEntryTimeAlertVisible = false;
        }
      }
    },
    validateSelfDeciderTimes() {
      if (this.templateForm.selfDeciderStartTime != null && this.templateForm.selfDeciderEndTime != null) {
        const selfDeciderStartTime = moment()
          .hours(this.templateForm.selfDeciderStartTime.split(':')[0])
          .minutes(this.templateForm.selfDeciderStartTime.split(':')[1]);
        const selfDeciderEndTime = moment()
          .hours(this.templateForm.selfDeciderEndTime.split(':')[0])
          .minutes(this.templateForm.selfDeciderEndTime.split(':')[1]);

        this.isInvalidSelfDeciderTimesAlertVisible = selfDeciderEndTime.isSameOrBefore(selfDeciderStartTime);

        if (this.templateForm.entryTime) {
          const entryTime = moment()
            .hours(this.templateForm.entryTime.split(':')[0])
            .minutes(this.templateForm.entryTime.split(':')[1]);

          if (entryTime.isAfter(selfDeciderStartTime) || entryTime.isSameOrAfter(selfDeciderEndTime)) {
            this.isInvalidExitTimeAlertVisible = true;
            this.isMissingExitTimeAlertVisible = false;
            this.isMissingEntryTimeAlertVisible = false;
          } else {
            this.isMissingExitTimeAlertVisible = false;
            this.isInvalidExitTimeAlertVisible = false;
          }
        }
      }
    },
    isOverlappingOldTemplate() {
      return this.overlappingPresenceTemplates != null;
    },
    onBeforeSubmitEditTemplate() {
      if (this.templateForm.isRepeating && this.isOverlappingOldTemplate()) {
        this.$refs.warningSaveOverlappingTemplate.show();
        return;
      }

      this.validateEditingTemplate();
    },
    validateEditingTemplate() {
      let isSave = true;
      if (this.isInvalidSelfDeciderTimesAlertVisible) {
        isSave = false;
      }
      if (this.showInvalidSelfDeciderStartTimeAlert === true) {
        isSave = false;
      }
      if (this.isInvalidExitTimeAlertVisible === true) {
        isSave = false;
      }
      if (
        moment(this.templateForm.byDate, dateFormatEnum.COMPLETE_DATE).isBefore(moment(), 'days') ||
        (this.templateForm.isRepeating && moment(this.templateForm.repeatToDate).isBefore(moment(), 'days'))
      ) {
        isSave = false;
        this.$refs.warningUpdate.show();
      }
      if (!this.templateForm.entryTime && this.templateForm.config.dropOffTime) {
        isSave = false;
        this.isMissingEntryTimeAlertVisible = true;
      }

      this.validateTimeRange();
      if (
        (this.templateForm.config.dropOffTime === true && this.isEntryTimeOutsideOpenHoursAlertVisible) ||
        (this.templateForm.config.pickupTime && this.isExitTimeOutsideOpenHoursAlertVisible)
      ) {
        isSave = false;
      }

      if (
        this.templateForm.config.pickupTime &&
        !this.templateForm.exitTime &&
        ((this.templateForm.config.sendHome && this.templateForm.activityType == exitTypes.SEND_HOME) ||
          (this.templateForm.config.goHomeWith && this.templateForm.activityType == exitTypes.GO_HOME_WITH) ||
          (this.templateForm.config.pickup && this.templateForm.activityType == exitTypes.PICKED_UP_BY))
      ) {
        isSave = false;
        this.isMissingExitTimeAlertVisible = true;
      }
      if (
        !this.templateForm.exitWith &&
        ((this.templateForm.config.goHomeWith && this.templateForm.activityType == exitTypes.GO_HOME_WITH) ||
          (this.templateForm.config.pickup && this.templateForm.activityType == exitTypes.PICKED_UP_BY))
      ) {
        isSave = false;
        this.isMissingExitWithAlertVisible = true;
      }
      if (this.showInvalidSelfDeciderExitTimeAlert) {
        isSave = false;
      }
      if (this.showInvalidActivityExitTimeAlert) {
        isSave = false;
      }
      if (
        isSave &&
        this.templateForm.entryTime &&
        this.templateForm.config.dropOffTime &&
        this.templateForm.exitTime &&
        (this.templateForm.activityType == exitTypes.SEND_HOME ||
          this.templateForm.activityType == exitTypes.GO_HOME_WITH ||
          this.templateForm.activityType == exitTypes.PICKED_UP_BY)
      ) {
        const entryTime = moment()
          .hours(this.templateForm.entryTime.split(':')[0])
          .minutes(this.templateForm.entryTime.split(':')[1]);
        const exitTime = moment()
          .hours(this.templateForm.exitTime.split(':')[0])
          .minutes(this.templateForm.exitTime.split(':')[1]);
        if (entryTime.isSameOrAfter(exitTime)) {
          isSave = false;
          this.isInvalidExitTimeAlertVisible = true;
        }
      }

      if (isSave) {
        this.updateTemplate();
      }
    },
    preparePresenceTemplateParams() {
      const params = {
        institutionProfileId: this.templateForm.institutionProfileId,
        byDate: this.templateForm.byDate,
        presenceActivity: {},
        comment: this.templateForm.comment,
        repeatTemplate: this.templateForm.isRepeating,
        expiresAt: this.templateForm.repeatToDate,
      };

      const presenceActivityData = {};
      if (this.templateForm.activityType !== null) {
        presenceActivityData.activityType = this.templateForm.activityType;
        const entryTime = this.templateForm.config.dropOffTime ? this.templateForm.entryTime : null;
        const exitTime = this.templateForm.config.pickupTime ? this.templateForm.exitTime : null;

        switch (this.templateForm.activityType) {
          case exitTypes.PICKED_UP_BY:
            presenceActivityData.pickup = {
              entryTime: entryTime,
              exitTime: exitTime,
              exitWith: this.templateForm.exitWith,
            };
            break;
          case exitTypes.SELF_DECIDER:
            presenceActivityData.selfDecider = {
              entryTime: entryTime,
              exitStartTime: this.templateForm.selfDeciderStartTime,
              exitEndTime: this.templateForm.selfDeciderEndTime,
            };
            break;
          case exitTypes.SEND_HOME:
            presenceActivityData.sendHome = {
              entryTime: entryTime,
              exitTime: exitTime,
            };
            break;
          case exitTypes.GO_HOME_WITH:
            presenceActivityData.goHomeWith = {
              entryTime: entryTime,
              exitTime: exitTime,
              exitWith: this.templateForm.exitWith,
            };
            break;
          default:
            presenceActivityData.activityType = null;
            presenceActivityData.entryTime = entryTime;
            presenceActivityData.exitTime = exitTime;
            break;
        }
      }

      if (this.isSpareTimeActivityVisible) {
        params.spareTimeActivity = {
          startDate: this.spareTimeActivity.startTime,
          endDate: this.spareTimeActivity.endTime,
          comment: this.spareTimeActivity.comment,
        };
      }

      params.presenceActivity = presenceActivityData;
      return params;
    },
    async updateTemplate() {
      this.isEditingLoading = true;
      const params = this.preparePresenceTemplateParams();

      this.currentWeekView = this.presenceTimes.map(p => p.chosenWeek);
      const institutionProfileIds =
        this.activeSFOChildrenIds.length > 0 ? this.activeSFOChildrenIds : [this.viewPresenceProfile.id];

      await this.updatePresenceTemplate(params);
      this.isEditingLoading = false;
      this.isEditingTemplate = false;
      this.closeEditDayTemplate();

      await this.loadChildrenPresenceTimes(
        institutionProfileIds,
        dateUtil.startOfWeek(params.byDate),
        dateUtil.endOfWeek(params.byDate)
      );
      await this.keepCurrentWeekView();
    },
    async keepCurrentWeekView() {
      this.isLoadingTemplate = true;
      this.initPresenceTimes();
      if (this.viewPresenceProfile) {
        const presenceDates = this.getPresenceDates();
        this.populateChildPresenceTimes(this.viewPresenceProfile.id, presenceDates);
      }
      if (this.currentWeekView.length === 0) {
        return;
      }

      let index = 0;
      for (const week of this.currentWeekView) {
        if (week === null || this.presenceTimes[index] === null) {
          index++;
          continue;
        }
        this.presenceTimes[index].chosenWeek = this.currentWeekView[index];
        const chosenDate = this.presenceTimes[index].chosenWeek ?? this.getPresenceTimes.currentDate;
        const viewWeek = moment(chosenDate).format('W');
        if (viewWeek === this.presenceTimes[index].presenceWeek.toString()) {
          continue;
        }
        this.changeWeekViewPresenceTimes(index);
        this.updateDayTemplates();
        index++;
      }

      await this.getOpenHoursForDay();
      this.resetLoading();
    },
    resetForm() {
      this.isMissingEntryTimeAlertVisible = false;
      this.isMissingExitTimeAlertVisible = false;
      this.isInvalidExitTimeAlertVisible = false;
      this.isMissingExitWithAlertVisible = false;
      this.isInvalidSelfDeciderTimesAlertVisible = false;
    },
    isTimeBetween(time, date, institutionCode, type) {
      if (type === exitTypes.SELF_DECIDER || type == null) {
        const specificOpenHours = cloneDeep(this.getSpecificOpenHours(date, institutionCode));

        if (specificOpenHours == null) {
          return false;
        }

        const momentOpenHour = moment(specificOpenHours.split(' - ')[0], 'HH:mm:ss');
        const momentCloseHour = moment(specificOpenHours.split(' - ')[1], 'HH:mm:ss');
        return moment(time, 'HH:mm:ss').isBetween(momentOpenHour, momentCloseHour, undefined, '[]');
      }
      return false;
    },
    closeEditDayTemplate() {
      this.$refs.editTemplate.hide();
      this.resetOverlappingPresenceTemplates();
      this.isExitTimeOutsideOpenHoursAlertVisible = false;
      this.isEntryTimeOutsideOpenHoursAlertVisible = false;
    },
    editingDayTemplate(presenceIndex, profileIndex, dayIndex, isRepeating = false) {
      this.editTemplatePresenceIndex = presenceIndex;
      this.editTemplateProfileIndex = profileIndex;
      this.editTemplateDayIndex = dayIndex;
      const dayTemplate = this.presenceTimes[presenceIndex].presenceTimes[profileIndex].dayTemplates[dayIndex];
      const chosenDate = this.presenceTimes[presenceIndex].presenceDates[dayIndex].date;
      const profileTemplates = this.presenceTimes[presenceIndex].presenceTimes[profileIndex];
      let templateId = null;
      const editTemplate = profileTemplates.dayTemplates.filter(t => t.byDate == chosenDate);

      this.selectedDate = chosenDate;
      this.selectedTemplateInstitutionCode = profileTemplates.institutionProfile.institutionCode;

      const selectedInstitution = this.openHours.openingHoursOverviewDto.find(
        institution => institution.institutionCode == this.selectedTemplateInstitutionCode
      );
      const dateOfWeek = selectedInstitution?.openingHoursDto.find(
        hour => hour.date.localeCompare(this.selectedDate) === 0
      );

      if (editTemplate.length > 0) {
        templateId = editTemplate[0].id;
      }
      this.activeModules = [];
      if (profileTemplates.pickup) {
        this.activeModules.push({
          value: exitTypes.PICKED_UP_BY,
          label: this.$options.filters.fromTextKey('PRESENCE_PICKUP_TYPE_PICKUP'),
        });
      }
      if (profileTemplates.selfDecider) {
        this.activeModules.push({
          value: exitTypes.SELF_DECIDER,
          label: this.$options.filters.fromTextKey('PRESENCE_PICKUP_TYPE_SELF_DECIDER'),
        });
      }
      if (profileTemplates.sendHome) {
        this.activeModules.push({
          value: exitTypes.SEND_HOME,
          label: this.$options.filters.fromTextKey('PRESENCE_PICKUP_TYPE_SEND_HOME'),
        });
      }
      if (profileTemplates.goHomeWith) {
        this.activeModules.push({
          value: exitTypes.GO_HOME_WITH,
          label: this.$options.filters.fromTextKey('PRESENCE_PICKUP_TYPE_GO_HOME_WITH'),
        });
      }
      this.editPresenceProfile = profileTemplates.institutionProfile;
      dayTemplate.institutionProfileId = profileTemplates.institutionProfile.id;
      dayTemplate.byDate = this.presenceTimes[presenceIndex].presenceDates[dayIndex].date;
      dayTemplate.shortDate = moment(dayTemplate.byDate).format('DD.MMMM');
      dayTemplate.config = {};
      dayTemplate.profileIndex = profileIndex;
      dayTemplate.config.pickup = profileTemplates.pickup;
      dayTemplate.config.goHomeWith = profileTemplates.goHomeWith;
      dayTemplate.config.selfDecider = profileTemplates.selfDecider;
      dayTemplate.config.sendHome = profileTemplates.sendHome;
      dayTemplate.config.spareTimeActivity = profileTemplates.spareTimeActivity;
      dayTemplate.config.dropOffTime = profileTemplates.dropOffTime;
      dayTemplate.config.pickupTime = profileTemplates.pickupTime;
      dayTemplate.id = templateId;
      this.templateForm = Object.assign(this.templateForm, dayTemplate);
      this.templateForm.exitTime = this.templateForm.exitTime ?? '';
      this.templateForm.entryTime = this.templateForm.entryTime ?? '';
      this.templateForm.selfDeciderStartTime = this.templateForm.selfDeciderStartTime ?? '';
      this.templateForm.selfDeciderEndTime = this.templateForm.selfDeciderEndTime ?? '';
      this.templateForm.isRepeating = isRepeating;
      this.availableEndDate = {
        firstDayOfWeek: 1,
        disabledDate(time) {
          return moment(time).isBefore(moment(dayTemplate.byDate));
        },
      };
      this.templateForm.defaultEndDate = moment(dayTemplate.byDate, 'DD-MM-YYYY').format(dateFormatEnum.COMPLETE_DATE);
      if (this.templateForm.config.goHomeWith) {
        this.loadGoHomeWithList({
          institutionProfileId: this.templateForm.institutionProfileId,
        });
      }

      if (
        dateOfWeek &&
        (dateOfWeek.type === presenceOpenHours.SPECIFIC_OPEN_HOURS ||
          dateOfWeek.type === presenceOpenHours.GENERAL_OPEN_HOURS)
      ) {
        this.validateTimeRange();

        if (this.templateForm.config.selfDecider === true) {
          const closeTime = dateOfWeek.closeTime;
          if (this.templateForm.selfDeciderEndTime == null && this.templateForm.selfDeciderStartTime == null) {
            this.templateForm.selfDeciderEndTime = closeTime;
            this.templateForm.selfDeciderStartTime = closeTime.split(':')[0] - 1 + ':' + closeTime.split(':')[1];
          }
        }
      } else {
        if (this.templateForm.selfDeciderEndTime == null && this.templateForm.selfDeciderStartTime == null) {
          this.templateForm.selfDeciderStartTime = this.defaultSelfDeciderStartTime;
          this.templateForm.selfDeciderEndTime = this.defaultSelfDeciderEndTime;
        }
      }
      if (this.templateForm.config.pickup) {
        this.loadPickupResponsibles({
          uniStudentIds: [this.templateForm.institutionProfileId],
        });
      }
      if (this.templateForm.config.spareTimeActivity && this.templateForm.spareTimeActivity == null) {
        this.isSpareTimeActivityVisible = false;
        this.spareTimeActivity.startTime = '13:00';
        this.spareTimeActivity.endTime = '14:00';
        this.spareTimeActivity.comment = '';
      } else if (
        this.templateForm.config.spareTimeActivity &&
        this.templateForm.spareTimeActivity != null &&
        this.templateForm.spareTimeActivity.startTime != '' &&
        this.templateForm.spareTimeActivity.endTime != ''
      ) {
        this.spareTimeActivity.startTime = this.templateForm.spareTimeActivity.startTime;
        this.spareTimeActivity.endTime = this.templateForm.spareTimeActivity.endTime;
        this.spareTimeActivity.comment = this.templateForm.spareTimeActivity.comment;
        this.isSpareTimeActivityVisible = true;
      } else if (!this.templateForm.config.spareTimeActivity) {
        this.isSpareTimeActivityVisible = false;
        this.spareTimeActivity = {
          startTime: null,
          endTime: null,
          comment: '',
        };
      }
      if (this.templateForm.activityType == null && this.activeModules.length > 0) {
        this.templateForm.activityType = this.activeModules[0].value;
      } else {
        if (this.templateForm.activityType === exitTypes.PICKED_UP_BY && !this.templateForm.config.pickup) {
          this.templateForm.activityType = exitTypes.SELF_DECIDER;
        }
        if (this.templateForm.activityType === exitTypes.SELF_DECIDER && !this.templateForm.config.selfDecider) {
          this.templateForm.activityType = exitTypes.SEND_HOME;
        }
        if (this.templateForm.activityType === exitTypes.SEND_HOME && !this.templateForm.config.sendHome) {
          this.templateForm.activityType = exitTypes.GO_HOME_WITH;
        }
      }

      if (this.templateForm.byDate && this.templateForm.isRepeating) {
        this.updateOverlappingPresenceTemplates();
      }

      this.resetForm();
      this.isEditingTemplate = true;
      this.$refs.editTemplate.show();
      this.setUpDefaultTimeForTemplate();
    },
    setUpDefaultTimeForTemplate() {
      if (!this.selectedDateOpenHours || this.templateForm.entryTime) {
        return;
      }
      if (this.selectedDateOpenHours.type === presenceOpenHours.DEFAULT_OPEN_HOURS) {
        this.templateForm.entryTime = this.defaultEntryTime;
        this.templateForm.exitTime = this.defaultExitTime;
        this.templateForm.selfDeciderStartTime = this.defaultSelfDeciderStartTime;
        this.templateForm.selfDeciderEndTime = this.defaultSelfDeciderEndTime;
      } else {
        this.templateForm.entryTime = this.selectedDateOpenHours.openTime;
        this.templateForm.exitTime = this.selectedDateOpenHours.closeTime;
      }
    },
    validateTimeRange() {
      const momentOpenHoursTime = moment(this.selectedDateOpenHours?.openTime, 'HH:mm:ss');
      const momentCloseHoursTime = moment(this.selectedDateOpenHours?.closeTime, 'HH:mm:ss');
      const momentEntryTime = moment(this.templateForm.entryTime, 'HH:mm:ss');
      const momentExitTime = moment(this.templateForm.exitTime, 'HH:mm:ss');
      const dateWeekIsOpeningHours = [
        presenceOpenHours.SPECIFIC_OPEN_HOURS,
        presenceOpenHours.GENERAL_OPEN_HOURS,
      ].includes(this.selectedDateOpenHours?.type);

      this.isEntryTimeOutsideOpenHoursAlertVisible = false;
      this.isExitTimeOutsideOpenHoursAlertVisible = false;

      if (dateWeekIsOpeningHours === false) {
        return;
      }

      if (this.templateForm.activityType === exitTypes.SELF_DECIDER) {
        if (this.templateForm.selfDeciderEndTime && this.templateForm.selfDeciderStartTime) {
          const momentSelftStart = moment(this.templateForm.selfDeciderStartTime, 'HH:mm:ss');
          const momentSelftEnd = moment(this.templateForm.selfDeciderEndTime, 'HH:mm:ss');

          // Sefl decider type has 2 departure time fields
          if (
            !momentSelftStart.isBetween(momentOpenHoursTime, momentCloseHoursTime, undefined, '[]') ||
            !momentSelftEnd.isBetween(momentOpenHoursTime, momentCloseHoursTime, undefined, '[]')
          ) {
            this.isExitTimeOutsideOpenHoursAlertVisible = true;
          }
          if (
            this.templateForm.entryTime &&
            !momentEntryTime.isBetween(momentOpenHoursTime, momentCloseHoursTime, undefined, '[]')
          ) {
            this.isEntryTimeOutsideOpenHoursAlertVisible = true;
          }
        }
      } else {
        if (
          this.templateForm.entryTime &&
          !momentEntryTime.isBetween(momentOpenHoursTime, momentCloseHoursTime, undefined, '[]')
        ) {
          this.isEntryTimeOutsideOpenHoursAlertVisible = true;
        }
        if (
          this.templateForm.exitTime &&
          !momentExitTime.isBetween(momentOpenHoursTime, momentCloseHoursTime, undefined, '[]')
        ) {
          this.isExitTimeOutsideOpenHoursAlertVisible = true;
        }
      }
    },
    updatePickUpType() {
      this.templateForm.exitWith = '';
      this.resetForm();
      this.isEntryTimeOutsideOpenHoursAlertVisible = false;
      this.isExitTimeOutsideOpenHoursAlertVisible = false;
    },
    getPresenceDates(date = null) {
      const viewDay =
        date === null
          ? moment().format(dateFormatEnum.COMPLETE_DATE_TIME)
          : moment(date).format(dateFormatEnum.COMPLETE_DATE_TIME);
      const presenceDays = [];
      let presenceDay = moment(viewDay).startOf('isoWeek');
      const endOfWeek = moment(viewDay).endOf('isoWeek');
      let dayOfWeek = presenceDay.isoWeekday();
      while (presenceDay.isSameOrBefore(endOfWeek) && dayOfWeek < 6) {
        presenceDays.push({
          date: presenceDay.format(dateFormatEnum.COMPLETE_DATE),
          shortDate: this.$options.filters.date(dateFormatTypes.monthDay, presenceDay),
          dayOfWeek: this.$options.filters.date(dateFormatTypes.shortWeekDateName, presenceDay),
          dateOfWeekFull: this.dateOfWeekFull[dayOfWeek - 1],
        });
        presenceDay = presenceDay.add(1, 'days');
        dayOfWeek = presenceDay.isoWeekday();
      }
      return presenceDays;
    },
    populateChildPresenceTimes(childId, presenceDates) {
      const presenceTemplate = this.getPresenceTimes.presenceWeekTemplates.find(
        t => t.institutionProfile.id == childId
      );
      if (presenceTemplate === null) {
        return;
      }
      if (this.getPresenceActiveModules != null) {
        for (const pickupType in pickupTypes) {
          presenceTemplate[pickupTypes[pickupType].paramName] =
            this.getPresenceActiveModules[pickupTypes[pickupType].paramName] != null &&
            this.getPresenceActiveModules[pickupTypes[pickupType].paramName].includes(
              presenceTemplate.institutionProfile.id
            );
        }
        presenceTemplate.dropOffTime =
          this.getPresenceActiveModules[presenceModules.DROP_OFF_TIME] != null &&
          this.getPresenceActiveModules[presenceModules.DROP_OFF_TIME].includes(presenceTemplate.institutionProfile.id);
        presenceTemplate.spareTimeActivity =
          this.getPresenceActiveModules[presenceModules.SPARE_TIME_ACTIVITY] != null &&
          this.getPresenceActiveModules[presenceModules.SPARE_TIME_ACTIVITY].includes(
            presenceTemplate.institutionProfile.id
          );
        presenceTemplate.pickupTime =
          this.getPresenceActiveModules[presenceModules.PICKUP_TIMES] != null &&
          this.getPresenceActiveModules[presenceModules.PICKUP_TIMES].includes(presenceTemplate.institutionProfile.id);
      }
      const presenceTime = {
        presenceDates: presenceDates,
        presenceWeek: moment().isoWeek(),
        presenceMonth: moment().format('MMMM'),
        chosenWeek: null,
        presenceTimes: [presenceTemplate],
      };
      this.presenceTimes = [presenceTime];
    },
    async loadChildrenPresenceTimes(childrenIds, fromDate, toDate) {
      await this.loadPresenceTimes({
        institutionProfileIds: childrenIds,
        fromDate: fromDate,
        toDate: toDate,
      });
    },
    async viewPresenceByChild(childId) {
      const presenceDates = this.getPresenceDates();
      this.viewPresenceProfile = this.children.find(c => c.id == childId);
      this.isLoadingTemplate = true;

      await this.loadChildrenPresenceTimes([childId], dateUtil.startOfWeek(), dateUtil.endOfWeek());
      this.populateChildPresenceTimes(childId, presenceDates);
      await this.getOpenHoursForDay();
      this.resetLoading();
    },
    setCurrentViewTemplate() {
      let presenceTimes = [];
      const presenceDates = this.getPresenceDates();
      if (!this.isSeparately) {
        for (let presenceTemplate of this.getPresenceTimes.presenceWeekTemplates) {
          presenceTemplate = this.setPresenceTemplateProps(presenceTemplate);
        }
        presenceTimes = [
          {
            presenceWeek: this.selectedPresenceWeek.presenceWeek
              ? this.selectedPresenceWeek.presenceWeek
              : moment().isoWeek(),
            presenceMonth: this.selectedPresenceWeek.presenceMonth
              ? this.selectedPresenceWeek.presenceMonth
              : moment().format('MMMM'),
            presenceDates: this.selectedPresenceWeek.presenceDates
              ? this.selectedPresenceWeek.presenceDates
              : presenceDates,
            chosenWeek: this.selectedPresenceWeek.chosenWeek ? this.selectedPresenceWeek.chosenWeek : null,
            presenceTimes: this.getPresenceTimes.presenceWeekTemplates,
          },
        ];
      } else {
        for (let presenceTemplate of this.getPresenceTimes.presenceWeekTemplates) {
          presenceTemplate = this.setPresenceTemplateProps(presenceTemplate);
          const presenceTime = {
            presenceWeek: this.selectedPresenceWeek.presenceWeek
              ? this.selectedPresenceWeek.presenceWeek
              : moment().isoWeek(),
            presenceMonth: this.selectedPresenceWeek.presenceMonth
              ? this.selectedPresenceWeek.presenceMonth
              : moment().format('MMMM'),
            presenceDates: this.selectedPresenceWeek.presenceDates
              ? this.selectedPresenceWeek.presenceDates
              : presenceDates,
            chosenWeek: this.selectedPresenceWeek.chosenWeek ? this.selectedPresenceWeek.chosenWeek : null,
            presenceTimes: [presenceTemplate],
          };
          presenceTimes.push(presenceTime);
        }
      }
      return presenceTimes;
    },
    setPresenceTemplateProps(presenceTemplate) {
      if (this.getPresenceActiveModules != null) {
        for (const pickupType in pickupTypes) {
          presenceTemplate[pickupTypes[pickupType].paramName] =
            this.getPresenceActiveModules[pickupTypes[pickupType].paramName] != null &&
            this.getPresenceActiveModules[pickupTypes[pickupType].paramName].includes(
              presenceTemplate.institutionProfile.id
            );
        }
        presenceTemplate.dropOffTime =
          this.getPresenceActiveModules[presenceModules.DROP_OFF_TIME] != null &&
          this.getPresenceActiveModules[presenceModules.DROP_OFF_TIME].includes(presenceTemplate.institutionProfile.id);
        presenceTemplate.spareTimeActivity =
          this.getPresenceActiveModules[presenceModules.SPARE_TIME_ACTIVITY] != null &&
          this.getPresenceActiveModules[presenceModules.SPARE_TIME_ACTIVITY].includes(
            presenceTemplate.institutionProfile.id
          );
        presenceTemplate.pickupTime =
          this.getPresenceActiveModules[presenceModules.PICKUP_TIMES] != null &&
          this.getPresenceActiveModules[presenceModules.PICKUP_TIMES].includes(presenceTemplate.institutionProfile.id);
      }
      return presenceTemplate;
    },
    initPresenceTimes() {
      if (this.getPresenceTimes.presenceWeekTemplates != null) {
        this.presenceTimes = this.setCurrentViewTemplate();
      }
      $('.presence-choose-week-picker').click(() => {
        if ($('.presence-choose-week-popper').is(':visible')) {
          this.$refs.chooseWeekDatePicker.blur();
        } else {
          this.$refs.chooseWeekDatePicker.handleFocus();
        }
      });
    },
    getIsCurrentDate(presenceDate) {
      return dateUtil.isToday(presenceDate);
    },
    changeWeekViewPresenceTimes(weekIndex) {
      const chosenDate = this.presenceTimes[weekIndex].chosenWeek ?? this.getPresenceTimes.currentDate;
      const viewWeek = moment(chosenDate).format('W');

      for (const presenceTime of this.presenceTimes) {
        presenceTime.presenceDates = this.getPresenceDates(chosenDate);
        presenceTime.presenceWeek = viewWeek;
        presenceTime.presenceMonth = moment(chosenDate).format('MMMM');
      }

      this.setSelectedPresenceWeek(this.presenceTimes[weekIndex]);
    },
    updateDayTemplates() {
      for (const presenceTime of this.presenceTimes) {
        for (const template of presenceTime.presenceTimes) {
          const child = this.getPresenceTimes.presenceWeekTemplates.find(
            t => t.institutionProfile.id === template.institutionProfile.id
          );
          if (child != null) {
            template.dayTemplates = child.dayTemplates;
          }
        }
      }
    },
    async changeWeekView(index) {
      const chosenDate = this.presenceTimes[index].chosenWeek ?? this.getPresenceTimes.currentDate;
      const viewWeek = moment(chosenDate).format('W');

      if (viewWeek === this.presenceTimes[index].presenceWeek.toString()) {
        return;
      }
      this.isLoadingTemplate = true;
      this.changeWeekViewPresenceTimes(index);
      await this.loadChildrenPresenceTimes(
        this.presenceChildrenIds,
        dateUtil.startOfWeek(chosenDate),
        dateUtil.endOfWeek(chosenDate)
      );
      this.updateDayTemplates();
      await this.getOpenHoursForDay();
      this.resetLoading();
    },
    loadPresenceConfiguration() {
      const activeSFOChildren = this.getPresenceStates.map(child => child.uniStudentId);
      if (activeSFOChildren.length > 0) {
        this.loadPresenceConfigurationByChildIds({
          childIds: activeSFOChildren,
        }).then(() => {
          this.getOpenHoursForDay();
          this.resetLoading();
        });
      }
    },
    loadPresenceTemplateByChildIds() {
      if (this.activeChildren.length > 0) {
        this.isLoading = true;
        this.isLoadingTemplate = true;
        const activeSFOChildren = this.getPresenceStates.filter(child =>
          this.activeChildren.includes(child.uniStudentId)
        );
        if (activeSFOChildren.length > 0) {
          this.activeSFOChildrenIds = activeSFOChildren.map(child => child.uniStudentId);
          const fromDate = dateUtil.startOfWeek();
          const toDate = dateUtil.endOfWeek();
          this.loadPresenceConfigurationByChildIds({
            childIds: this.activeSFOChildrenIds,
          }).then(() => {
            this.loadPresenceTimes({
              institutionProfileIds: this.activeSFOChildrenIds,
              fromDate: fromDate,
              toDate: toDate,
            }).then(() => {
              this.initPresenceTimes();
              this.getOpenHoursForDay();
              this.resetLoading();

              const activeChildren = this.children.filter(child => this.activeSFOChildrenIds.includes(child.id));
              const childInstitutionCodeList = Array.from(new Set(activeChildren.map(child => child.institutionCode)));

              this.loadHolidayAndClosedDays({
                institutionCodes: childInstitutionCodeList,
              });
            });
          });
        } else {
          this.resetLoading();
        }
      }
    },
    async getOpenHoursForDay() {
      if (this.presenceTimes.length === 0) {
        return;
      }
      const startDate = this.presenceTimes[0].presenceTimes[0].dayTemplates[0].byDate;
      const endDate = this.presenceTimes[0].presenceTimes[0].dayTemplates[4].byDate;
      const activeSFOChildren = this.getPresenceStates.filter(child =>
        this.activeChildren.includes(child.uniStudentId)
      );
      const activeSFOChildrenIds = activeSFOChildren.map(child => child.uniStudentId);
      const activeChildren = this.children.filter(child => activeSFOChildrenIds.includes(child.id));
      const childInstitutionCodeList = Array.from(new Set(activeChildren.map(child => child.institutionCode)));

      await this.loadOpenHoursForDay({
        startDate: startDate,
        endDate: endDate,
        institutionCodes: childInstitutionCodeList,
      });
    },
    resetLoading() {
      this.isLoading = false;
      this.isLoadingTemplate = false;
    },
    getSpecificOpenHours(date, institutionCode) {
      const selectedInstitution = this.openHours.openingHoursOverviewDto.find(
        institution => institution.institutionCode == institutionCode
      );
      let formattedDate;
      if (date.byDate.split('-')[0].length == 2) {
        formattedDate = date.byDate.split('-').reverse().join('-');
      } else {
        formattedDate = date.byDate;
      }
      const dateOfWeek = selectedInstitution?.openingHoursDto.find(
        hour => hour.date.localeCompare(formattedDate) === 0
      );
      if (dateOfWeek && dateOfWeek.type === presenceOpenHours.SPECIFIC_OPEN_HOURS) {
        return dateOfWeek.openTime + ' - ' + dateOfWeek.closeTime;
      }

      return null;
    },
    isClosedDayString(date, institutionCode) {
      const currentInstitution = this.holidayAndClosedDays.find(date => date.institutionCode === institutionCode);
      if (currentInstitution) {
        date = dateUtil.format(date, dateFormatEnum.COMPLETE_DATE);
        const isClosedDay = currentInstitution.closedDaysOverview.closedDays.find(closeDate => {
          const momentStartDate = moment(closeDate.startDate);
          const momentEndDate = moment(closeDate.endDate);

          return moment(date).isBetween(momentStartDate, momentEndDate, undefined, '[]');
        });

        if (isClosedDay) {
          return isClosedDay.name;
        }

        return null;
      }
    },
    isClosedDay(date, institutionCode) {
      const currentInstitution = this.holidayAndClosedDays.find(date => date.institutionCode === institutionCode);
      if (currentInstitution) {
        date = dateUtil.format(date, dateFormatEnum.COMPLETE_DATE);
        const isClosedDay = currentInstitution.closedDaysOverview.closedDays.find(closeDate => {
          const momentStartDate = moment(closeDate.startDate);
          const momentEndDate = moment(closeDate.endDate);

          return moment(date).isBetween(momentStartDate, momentEndDate, undefined, '[]');
        });

        if (isClosedDay) {
          return true;
        }
        return false;
      }
    },
    onSaveOverlappingTemplate() {
      this.$refs.warningSaveOverlappingTemplate.hide();
      this.validateEditingTemplate();
    },
    cancelSaveOverlappingTemplate() {
      this.$refs.warningSaveOverlappingTemplate.hide();
    },
  },
  watch: {
    children: function () {
      this.loadPresenceTemplateByChildIds();
    },
    isMobile: function () {
      this.viewPresenceProfile = null;
      if (!this.isMobile) {
        this.loadPresenceTemplateByChildIds();
      }
    },
    activeChildren: function () {
      this.loadPresenceTemplateByChildIds();
    },
    getPresenceActiveModules: function () {
      this.initPresenceTimes();
    },
  },
  mounted() {
    if (!this.isMobile) {
      this.loadPresenceTemplateByChildIds();
    } else {
      this.loadPresenceConfiguration();
    }
  },
  beforeDestroy() {
    this.setSelectedPresenceWeek({});
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/presence/_presence.scss';
.editing-loading {
  &.aula-spinner {
    width: 70px;
    height: 70px;
    position: absolute;
    right: 22px;
    top: -12px;
  }
}
.new-pickup {
  padding: 15px 25px;
  @include breakpoint-lg-down() {
    padding-right: 40px;
  }
  .pickup-responsible-save {
    float: right;
    margin-bottom: -60px;
    padding: 14px 20px !important;
  }
  .new-pickup-responsible {
    border-radius: 0px !important;
    background-color: $color-grey-light !important;
    &.show-button {
      width: calc(100% - 85px);
    }
  }
}
.endDate {
  width: 100% !important;
}
.weekly-repeat {
  display: inline-flex;
  align-items: center;
  .help-tooltip {
    /deep/.questionmark-icon {
      border: 1px solid $color-grey-base !important;
    }
  }
}
.dropdown-select {
  --dropdown-max-width: auto;
  align-self: center;
}

.presence-repitition-helpbox {
  --questionmark-icon-background-color: var(--color-grey-light);
}
</style>
