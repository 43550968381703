import { NotificationSettingModel } from '../../shared/models/notificationSetting.model';
import { ModuleTypeEnum } from '../../shared/enums/moduleTypeEnum';
import { WidgetSettingModel } from '../../shared/models/widgetSetting.model';

export class NotificationSetting implements NotificationSettingModel {
  appDisabled: boolean;
  calendar: boolean;
  emailDisabled: boolean;
  friday: boolean;
  gallery: boolean;
  instant: boolean;
  messagesFromChildren: boolean;
  messagesFromEmployees: boolean;
  messagesFromGuardians: boolean;
  monday: boolean;
  notifyAssignedAsSubstituteTeacher: boolean;
  notifyLessonNoteChanged: boolean;
  notifyMessages: boolean;
  pickUpActivity: boolean;
  post: boolean;
  saturday: boolean;
  scheduledTime: string | null;
  selectedAreas: Array<ModuleTypeEnum>;
  sunday: boolean;
  thursday: boolean;
  tuesday: boolean;
  vacationRequest: boolean;
  wednesday: boolean;
  appAvailable: boolean;
  emailAvailable: boolean;
  widgetSettings: Array<WidgetSettingModel>;

  constructor() {
    this.appDisabled = false;
    this.calendar = false;
    this.appAvailable = false;
    this.emailAvailable = false;
    this.emailDisabled = false;
    this.friday = false;
    this.gallery = false;
    this.instant = false;
    this.messagesFromChildren = false;
    this.messagesFromEmployees = false;
    this.messagesFromGuardians = false;
    this.monday = false;
    this.notifyAssignedAsSubstituteTeacher = false;
    this.notifyLessonNoteChanged = false;
    this.notifyMessages = false;
    this.pickUpActivity = false;
    this.post = false;
    this.saturday = false;
    this.scheduledTime = '08:00';
    this.selectedAreas = [];
    this.sunday = false;
    this.thursday = false;
    this.tuesday = false;
    this.vacationRequest = false;
    this.wednesday = false;
    this.widgetSettings = [];
  }

  getAppDisabled() {
    return this.appDisabled;
  }

  setAppDisabled(appDisabled: boolean) {
    this.appDisabled = appDisabled;
  }

  getCalendar() {
    return this.calendar;
  }

  setCalendar(calendar: boolean) {
    this.calendar = calendar;
  }

  getEmailDisabled() {
    return this.emailDisabled;
  }

  setEmailDisabled(emailDisabled: boolean) {
    this.emailDisabled = emailDisabled;
  }

  getGallery() {
    return this.gallery;
  }
  setGallery(gallery: boolean) {
    this.gallery = gallery;
  }

  getInstant() {
    return this.instant;
  }
  setInstant(instant: boolean) {
    this.instant = instant;
  }

  getMessagesFromChildren() {
    return this.messagesFromChildren;
  }
  setMessagesFromChildren(messagesFromChildren: boolean) {
    this.messagesFromChildren = messagesFromChildren;
  }

  getMessagesFromEmployees() {
    return this.messagesFromEmployees;
  }
  setMessagesFromEmployees(messagesFromEmployees: boolean) {
    this.messagesFromEmployees = messagesFromEmployees;
  }

  getMessagesFromGuardians() {
    return this.messagesFromGuardians;
  }
  setMessagesFromGuardians(messagesFromGuardians: boolean) {
    this.messagesFromGuardians = messagesFromGuardians;
  }

  getMonday() {
    return this.monday;
  }
  setMonday(monday: boolean) {
    this.monday = monday;
  }

  getNotifyAssignedAsSubstituteTeacher() {
    return this.notifyAssignedAsSubstituteTeacher;
  }
  setNotifyAssignedAsSubstituteTeacher(notifyAssignedAsSubstituteTeacher: boolean) {
    this.notifyAssignedAsSubstituteTeacher = notifyAssignedAsSubstituteTeacher;
  }

  getNotifyLessonNoteChanged() {
    return this.notifyLessonNoteChanged;
  }
  setNotifyLessonNoteChanged(notifyLessonNoteChanged: boolean) {
    this.notifyLessonNoteChanged = notifyLessonNoteChanged;
  }

  getNotifyMessages() {
    return this.notifyMessages;
  }
  setNotifyMessages(notifyMessages: boolean) {
    this.notifyMessages = notifyMessages;
  }

  getPost() {
    return this.post;
  }
  setPost(post: boolean) {
    this.post = post;
  }

  getSaturday() {
    return this.saturday;
  }
  setSaturday(saturday: boolean) {
    this.saturday = saturday;
  }

  getScheduledTime() {
    return this.scheduledTime;
  }
  setScheduledTime(scheduledTime: string | null) {
    this.scheduledTime = scheduledTime;
  }

  getSunday() {
    return this.sunday;
  }
  setSunday(sunday: boolean) {
    this.sunday = sunday;
  }

  getThursday() {
    return this.thursday;
  }
  setThursday(thursday: boolean) {
    this.thursday = thursday;
  }

  getFriday() {
    return this.friday;
  }
  setFriday(friday: boolean) {
    this.friday = friday;
  }

  getTuesday() {
    return this.tuesday;
  }
  setTuesday(tuesday: boolean) {
    this.tuesday = tuesday;
  }

  getWednesday() {
    return this.wednesday;
  }
  setWednesday(wednesday: boolean) {
    this.wednesday = wednesday;
  }

  getSelectedAreas() {
    return this.selectedAreas;
  }
  setSelectedAreas(selectedAreas: Array<ModuleTypeEnum>) {
    this.selectedAreas = selectedAreas;
  }

  getPickUpActivity() {
    return this.pickUpActivity;
  }
  setPickUpActivity(pickUpActivity: boolean) {
    this.pickUpActivity = pickUpActivity;
  }

  getVacationRequest() {
    return this.vacationRequest;
  }
  setVacationRequest(vacationRequest: boolean) {
    this.vacationRequest = vacationRequest;
  }

  getAppAvailable() {
    return this.appAvailable;
  }
  setAppAvailable(appAvailable: boolean) {
    this.appAvailable = appAvailable;
  }

  getEmailAvailable() {
    return this.emailAvailable;
  }
  setEmailAvailable(EmailAvailable: boolean) {
    this.emailAvailable = EmailAvailable;
  }

  getWidgetSettings(): Array<WidgetSettingModel> {
    return this.widgetSettings;
  }
  setWidgetSettings(widgetSettings: Array<WidgetSettingModel>) {
    this.widgetSettings = widgetSettings;
  }
  setWidgetSettingById(widgetId: number, isActive: boolean) {
    const widgetSetting = this.widgetSettings.find(setting => setting.widgetId === widgetId);
    if (!widgetSetting) {
      return;
    }
    widgetSetting.isActive = isActive;
  }
}
