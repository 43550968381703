<template>
  <message-type-message
    :class="[senderIsSelf ? 'forwarded-self' : 'forwarded']"
    :message="message"
    :shown-subscription="shownSubscription"
    @openDocumentDrawer="openDocumentDrawer"
  />
</template>

<script>
import MessageTypeMessage from './MessageTypeMessage.vue';
import { messageOwnerTypes } from '../../../shared/enums/messageOwnerTypes';
import { types } from '../../store/types/types';
import { mapGetters } from 'vuex';

export default {
  props: {
    message: {
      type: Object,
      default: null,
    },
    shownSubscription: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      profile: types.GET_CURRENT_PROFILE,
      chosenFolderAndMailOwner: types.MESSAGES_GET_CHOSEN_FOLDER_AND_MAIL_OWNER,
    }),
    senderIsSelf() {
      if (
        this.message.sender.mailBoxOwner.mailBoxOwnerType == messageOwnerTypes.COMMON_INBOX &&
        this.chosenFolderAndMailOwner.mailBoxOwnerType == messageOwnerTypes.COMMON_INBOX
      ) {
        return this.message.sender.mailBoxOwner.id == this.chosenFolderAndMailOwner.mailOwnerId;
      }
      if (
        this.message.sender.mailBoxOwner.mailBoxOwnerType == messageOwnerTypes.INSTITUTION_PROFILE &&
        this.chosenFolderAndMailOwner.mailBoxOwnerType == messageOwnerTypes.INSTITUTION_PROFILE
      ) {
        return this.message.sender.mailBoxOwner.profileId == this.profile.id;
      }
      return false;
    },
  },
  methods: {
    openDocumentDrawer(data) {
      this.$emit('openDocumentDrawer', data);
    },
  },
  components: {
    MessageTypeMessage,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.forwarded-self /deep/ {
  .message {
    .sender-name {
      visibility: visible;
    }
    .message-body {
      background-color: $color-grey-base !important;
      color: var(--color-primary-darker) !important;
      &:after {
        border-left: 10px solid $color-grey-base !important;
      }
    }
  }
}

.forwarded /deep/ {
  .message {
    .sender-name {
      visibility: visible;
    }
    .message-body {
      background-color: $color-grey-base !important;
      color: var(--color-primary-darker) !important;
      &:after {
        border-right: 10px solid $color-grey-base !important;
      }
    }
  }
}
</style>
