<template>
  <div class="no-results">
    {{ 'CONTACT_NO_RESULTS' | fromTextKey({ profileType: profileTypeText }) }}
  </div>
</template>
<script lang="js">
import {providerKeyTypes} from "../../enums/providerKeyTypes";
import {contactsProfileTypeFilters} from "../../enums/contactsProfileTypeFilters";

export default {
  name: 'ContactsFragmentNoResult',
  inject: {
    getProfileType: providerKeyTypes.profileType,
  },
  computed: {
    profileTypeText() {
      const profileType = this.getProfileType();
      let translationKey = '';
      switch (profileType) {
        case contactsProfileTypeFilters.GUARDIAN:
          translationKey = 'CONTACT_GUARDIAN';
          break;
        case contactsProfileTypeFilters.GIRL:
          translationKey = 'CONTACT_GIRL';
          break;
        case contactsProfileTypeFilters.BOY:
          translationKey = 'CONTACT_BOY';
          break;
        case contactsProfileTypeFilters.CHILD:
          translationKey = 'CONTACT_CHILD';
          break;
        case contactsProfileTypeFilters.EMPLOYEE:
          translationKey = 'CONTACT_EMPLOYEE';
          break;
      }

      return this.$options.filters.fromTextKey(translationKey);
    }
  },
}
</script>
