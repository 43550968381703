<template>
  <ThreadCompact class="thread-compact-header">
    <template #recipients>
      <strong class="recipient-header">{{ 'MESSAGE_SENT_TO' | fromTextKey }}</strong>
    </template>
    <template #name>
      <strong>{{ 'MESSAGE_LABEL_SUBJECT' | fromTextKey }}</strong>
    </template>
    <template #date>
      <strong>{{ 'MESSAGE_LABEL_DATE' | fromTextKey }}</strong>
    </template>
  </ThreadCompact>
</template>
<script>
import ThreadCompact from './ThreadCompact';
export default {
  components: { ThreadCompact },
};
</script>
<style scoped lang="scss">
.thread-compact-header {
  position: sticky;
  top: 0;
  z-index: var(--sticky-header-z-index);
  .recipient-header {
    padding-left: 52px;
  }
}
</style>
