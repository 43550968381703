<template>
  <b-col cols="9" class="p-0 mt-5 ml-5 mb-4">
    <Post v-if="canViewPost" :post="post" @edit="editPost" @delete="showDeleteModal" @report="reportPostClicked" />
    <b-alert variant="danger" :show="notFoundError">
      {{ 'POST_NOT_FOUND' | fromTextKey }}
    </b-alert>
    <aula-modal
      ref="deleteModal"
      ok-text="BUTTON_YES"
      @cancelClicked="$refs.deleteModal.hide()"
      @okClicked="deletePostButton()"
    >
      {{ 'POST_DELETE_POST_INFO' | fromTextKey }}<br />
      {{ 'POST_DELETE_POST_INFO_2' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="reportContentModal"
      ok-text="REPORTED_CONTENT_SEND"
      header-text="REPORTED_CONTENT_HEADER"
      @okClicked="saveReportPost()"
      @cancelClicked="
        $refs.reportContentModal.hide();
        $refs.cancelReportModal.show();
      "
    >
      {{ 'REPORTED_CONTENT_WRITE_A_REASON' | fromTextKey }}
      <b-form-textarea
        v-model="reportedContentReason"
        :placeholder="'REPORTED_CONTENT_FIELDS_REASON' | fromTextKey"
        @input="showWarningReasonLength = false"
      />
      <b-alert variant="danger" :show="showWarningReasonLength">
        {{ warningReasonLength | fromTextKey }}
      </b-alert>
      {{ 'REPORTED_CONTENT_NOTE' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="cancelReportModal"
      @okClicked="$refs.cancelReportModal.hide()"
      @cancelClicked="
        $refs.cancelReportModal.hide();
        $refs.reportContentModal.show();
      "
    >
      {{ 'REPORTED_CONTENT_CANCEL_REPORT_1' | fromTextKey }}<br />
      {{ 'REPORTED_CONTENT_CANCEL_REPORT_2' | fromTextKey }}
    </aula-modal>
    <drawer :show="drawerShow" @change-show="changeDrawerShow">
      <template slot="drawer-title">
        <div class="title">
          <i class="icon-Aula_calendar hide-tablet-down mr-3" aria-hidden="true" style="font-size: 1.3em" />
          {{ 'POSTS_NEW_DRAWER_TITLE' | fromTextKey }}
        </div>
      </template>
      <template slot="drawer-content">
        <new-post v-if="drawerShow" :id="editPostId" @cancel="closeDrawer" @postSubmitted="postSubmitted" />
      </template>
    </drawer>
  </b-col>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';
import NewPost from './NewPost.vue';
import { types } from '../../store/types/types';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import Post from './Post.vue';

export default {
  data: function () {
    return {
      notFoundError: false,
      drawerShow: false,
      postIdToDelete: '',
      editPostId: '',
      reportedContentReason: '',
      showWarningReasonLength: false,
      warningReasonLength: null,
      parent: parentTypes.PROFILE,
    };
  },
  computed: {
    ...mapGetters({
      post: types.GET_SINGLE_POST,
      group: types.GET_ACTIVE_GROUP,
      profile: types.GET_CURRENT_PROFILE,
      institutions: types.GET_INSTITUTIONS,
    }),
    canViewPost() {
      return this.post.id != null && !this.notFoundError;
    },
  },
  methods: {
    ...mapActions({
      fetchPosts: types.ACTION_GET_POST,
      deletePost: types.ACTIONS_DELETE_POST,
      reportPost: types.REPORT_POST,
    }),
    reportPostClicked(postId) {
      const institution = this.institutions.find(
        institution => institution.institutionCode === this.post.ownerProfile.institution.institutionCode
      );
      if (institution?.communicationBlock) {
        this.$refs.blockedCommunicationModal.show();
      } else {
        this.postIdToDelete = postId;
        this.reportedContentReason = '';
        this.showWarningReasonLength = true;
        this.resetCommentForm();
        this.$refs.reportContentModal.show();
      }
    },
    resetCommentForm() {
      this.showWarningReasonLength = false;
      this.warningReasonLength = null;
    },
    saveReportPost() {
      if (this.reportedContentReason.length < 10 || this.reportedContentReason.length > 255) {
        this.showWarningReasonLength = true;
        this.warningReasonLength =
          this.reportedContentReason.length < 10
            ? 'REPORTED_CONTENT_REASON_LENGTH_WARNING_TOO_SHORT'
            : 'REPORTED_CONTENT_REASON_LENGTH_WARNING_TOO_LONG';
      } else {
        this.resetCommentForm();
        this.reportPost({
          id: this.postIdToDelete,
          reportReason: this.reportedContentReason,
        }).then(() => {
          this.$refs.reportContentModal.hide();
          this.reportedContentReason = '';
          this.$router.push({ name: 'overblik' });
        });
      }
    },
    editPost(postId) {
      this.editPostId = postId;
      this.drawerShow = true;
      this.$eventHub.$emit('editPostFromSearch', postId);
    },
    postSubmitted() {
      this.closeDrawer();
    },
    changeDrawerShow() {
      this.drawerShow = !this.drawerShow;
    },
    closeDrawer() {
      this.drawerShow = false;
      this.editPostId = undefined;
    },
    handle() {
      this.notFoundError = false;
      if (this.$route.params.groupId != null) {
        this.parent = parentTypes.GROUP;
      }
      this.fetchPosts({
        id: parseInt(this.$route.params.id),
      }).catch(() => {
        this.notFoundError = true;
      });
    },
    deletePostButton() {
      this.deletePost({ id: this.postIdToDelete }).then(() => {
        this.$refs.deleteModal.hide();
        this.notFoundError = true;
        this.$router.push({ name: 'overblik' });
      });
    },
    showDeleteModal(id) {
      this.postIdToDelete = id;
      this.$refs.deleteModal.show();
    },
  },
  components: {
    Post,
    NewPost,
  },
  watch: {
    $route(to) {
      if (to.path.includes('/overblik/')) {
        this.handle();
      }
    },
  },
  created() {
    this.handle();
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
</style>
