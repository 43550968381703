export const MASTER_DATA_USER = 'NAVN';
export const MASTER_DATA_ANWSER = 'SVAR';
export const MASTER_DATA_OPTION_QUESTION = 'UDDYBENDE SPØRGSMÅL';
export const MASTER_DATA_USER_HAS_NOT_RECEIVED_YET = 'Ikke besvaret';
export const MASTER_DATA_USER_NOT_RELATED = 'Brugeren har ikke modtaget stamkortoplysningen';
export const MASTER_DATA_OPTIONAL_QUESTION_FOR_YES = "Uddybende spørgsmål ved 'Nej'";
export const MASTER_DATA_NO_RESULTS = 'Der er ingen {profileType} i denne stamkortoplysninger.';
export const MASTER_DATA_PERMISSION = 'Tilladelser';
export const MASTER_DATA_CONSENTS = 'Deling af kontaktoplysninger';
export const MASTER_DATA_ADDITIONAL_DATA = 'Supplerende Stamdata';

export const PERSONAL_REFERENCE_DATA_CHOOSE_INSTITUTION = 'Vælg institution';
export const PERSONAL_REFERENCE_DATA_CHOOSE_USER_GROUP = 'Vælg bruger eller gruppe';
export const PERSONAL_REFERENCE_DATA_CHOOSE_QUESTION = 'Vælg spørgsmål';
export const PERSONAL_REFERENCE_DATA_LOADING = 'Henter tilladelser og samtykker for gruppen...';
export const ARIA_LABEL_PERSONAL_REFERENCE_DATA_CHOOSE_QUESTION = 'Vælg spørgsmål';
export const NO_PERMISSION_TO_VIEW_ADDITIONAL_OR_CONSENT = 'Du har ikke tilladelse til at se Stamkort eller samtykke.';
