<template>
  <div class="presence-toolbar-mobile">
    <ul class="navbar-nav mr-auto">
      <li
        class="nav-item dropdown mr-4"
        tabindex="0"
        role="button"
        @click="showToolbar = true"
        @keydown.enter="showToolbar = true"
      >
        <i class="icon icon-Aula_back-arrow mr-2" />
        {{ 'PRESENCE_FILTERING_MENU' | fromTextKey }}
      </li>
    </ul>
    <transition name="slide-only">
      <div v-if="showToolbar" class="presence-toolbar-menu">
        <mobile-topbar :show-ok="false" text-key="PRESENCE_FILTERING_MENU" @leftIconClicked="showToolbar = false" />
        <div class="menu-body">
          <div class="toolbar-title">
            {{ 'PRESENCE_FILTERING_DEPARTMENT' | fromTextKey }}
          </div>
          <b-form-radio-group v-model="departmentId" :disabled="departments.length == 1" @change="departmentUpdated">
            <b-form-radio
              v-for="(department, i) in departments"
              :key="'department' + i"
              :value="department.id"
              class="toolbar-item"
            >
              {{ department.mainGroup.name }}
            </b-form-radio>
          </b-form-radio-group>
          <div class="toolbar-title" @click="showGroupsMenu = true">
            {{ 'PRESENCE_FILTERING_GROUPS' | fromTextKey }}
            <i class="pull-right icon icon-Aula_forward-arrow" />
          </div>
          <template v-if="defaultTab === 'PRESENCE_ACTIVITY_LIST_TODAY'">
            <div class="toolbar-title" @click="showStatesMenu = true">
              {{ 'PRESENCE_FILTERING_STATUS' | fromTextKey }}
              <i class="pull-right icon icon-Aula_forward-arrow" />
            </div>
            <div class="toolbar-title" @click="showActivityMenu = true">
              {{ 'PRESENCE_FILTERING_ACTIVITY' | fromTextKey }}
              <i class="pull-right icon icon-Aula_forward-arrow" />
            </div>
            <div class="toolbar-title" @click="showSortMenu = true">
              {{ 'DOCUMENTS_SHARED_OVERVIEW_SORT' | fromTextKey }}
              <i class="pull-right icon icon-Aula_forward-arrow" />
            </div>
            <div class="toolbar-title" @click="showFilterDailyNoteMenu = true">
              {{ 'PRESENCE_FILTERING_DAILY_NOTE' | fromTextKey }}
              <i class="pull-right icon icon-Aula_forward-arrow" />
            </div>
          </template>
          <div v-if="defaultTab === 'PRESENCE_ACTIVITY_LIST_WEEK'" class="toolbar-title" @click="showStatusMenu = true">
            {{ 'PRESENCE_FILTERING_STATUS' | fromTextKey }}
            <i class="pull-right icon icon-Aula_forward-arrow" />
          </div>
        </div>
      </div>
    </transition>

    <transition name="slide-only">
      <div v-if="showGroupsMenu" class="presence-toolbar-menu">
        <mobile-topbar
          :show-ok="false"
          text-key="PRESENCE_FILTERING_GROUPS"
          @leftIconClicked="showGroupsMenu = false"
        />
        <div class="menu-body">
          <b-form-checkbox-group v-model="groupIds" @change="groupsUpdated">
            <b-form-checkbox
              v-for="(group, i) in departmentGroups"
              :key="'group' + i"
              :value="group.id"
              class="toolbar-item"
            >
              {{ group.name }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </div>
      </div>
    </transition>

    <transition name="slide-only">
      <div v-if="showStatesMenu" class="presence-toolbar-menu">
        <mobile-topbar
          :show-ok="false"
          text-key="PRESENCE_FILTERING_STATUS"
          @leftIconClicked="showStatesMenu = false"
        />
        <div class="menu-body">
          <template v-for="(stateGroup, i) in activePresenceStates">
            <b-form-checkbox
              :key="'stateGroup' + i"
              :checked="
                containsAll(
                  stateGroup.options.map(option => option.value),
                  stateIds
                )
              "
              class="toolbar-title"
              @change="
                selectAllSubStates(
                  $event,
                  stateGroup.options.map(option => option.value)
                )
              "
            >
              {{ stateGroup.label }}
            </b-form-checkbox>
            <b-form-checkbox
              v-for="(state, j) in stateGroup.options"
              :key="'state' + i + '-' + j"
              v-model="stateIds"
              :value="state.value"
              class="toolbar-item"
              @change="statesUpdated"
            >
              {{ state.label }}
            </b-form-checkbox>
          </template>
        </div>
      </div>
    </transition>

    <transition name="slide-only">
      <div v-if="showActivityMenu" class="presence-toolbar-menu">
        <mobile-topbar
          :show-ok="false"
          text-key="PRESENCE_FILTERING_ACTIVITY"
          @leftIconClicked="showActivityMenu = false"
        />
        <div class="menu-body">
          <b-form-radio-group v-model="activityId" @change="activityUpdated">
            <b-form-radio :value="null" class="toolbar-item">
              {{ 'PRESENCE_FILTERING_ALL' | fromTextKey }}
            </b-form-radio>
            <b-form-radio
              v-for="(activity, i) in nextActivities"
              :key="'activity' + i"
              :value="activity.value"
              class="toolbar-item"
            >
              {{ activity.label }}
            </b-form-radio>
          </b-form-radio-group>
        </div>
      </div>
    </transition>

    <transition name="slide-only">
      <div v-if="showSortMenu" class="presence-toolbar-menu">
        <mobile-topbar
          :show-ok="false"
          text-key="DOCUMENTS_SHARED_OVERVIEW_SORT"
          @leftIconClicked="showSortMenu = false"
        />
        <div class="menu-body">
          <b-form-radio-group v-model="activeSort" @change="sortUpdated">
            <b-form-radio
              v-for="(sortType, i) in activityListSortTypes"
              :key="'sort' + i"
              :value="sortType"
              class="toolbar-item"
            >
              {{ sortedTranslation[sortType] | fromTextKey }}
            </b-form-radio>
          </b-form-radio-group>
        </div>
      </div>
    </transition>

    <transition name="slide-only">
      <div v-if="showStatusMenu" class="presence-toolbar-menu">
        <mobile-topbar
          :show-ok="false"
          text-key="PRESENCE_FILTERING_STATUS"
          @leftIconClicked="showStatusMenu = false"
        />
        <div class="menu-body">
          <template v-for="(optionGroup, i) in weekOverviewFilterOptions">
            <b-form-checkbox
              :key="'stateGroup' + i"
              :checked="
                containsAll(
                  optionGroup.options.map(option => option.value),
                  selectedWeekFilters
                )
              "
              class="toolbar-title"
              @change="
                selectAllSubWeekFilter(
                  $event,
                  optionGroup.options.map(option => option.value)
                )
              "
            >
              {{ optionGroup.label }}
            </b-form-checkbox>
            <template v-for="(option, j) in optionGroup.options">
              <b-form-checkbox
                v-if="!option.isHidden"
                :key="'state' + i + '-' + j"
                v-model="selectedWeekFilters"
                :value="option.value"
                class="toolbar-item"
                @change="weekFiltersUpdated"
              >
                {{ option.label }}
              </b-form-checkbox>
            </template>
          </template>
        </div>
      </div>
    </transition>

    <transition name="slide-only">
      <div v-if="showFilterDailyNoteMenu" class="presence-toolbar-menu">
        <mobile-topbar
          :show-ok="false"
          text-key="PRESENCE_FILTERING_DAILY_NOTE"
          @leftIconClicked="showFilterDailyNoteMenu = false"
        />
        <div class="menu-body">
          <b-form-radio-group v-model="hasDailyNote" @change="hasDailyNoteUpdated">
            <b-form-radio :value="false" class="toolbar-item">
              {{ 'PRESENCE_FILTERING_ALL' | fromTextKey }}
            </b-form-radio>
            <b-form-radio :value="true" class="toolbar-item">
              {{ 'PRESENCE_FILTERING_CHILDREN_WITH_DAILY_NOTE' | fromTextKey }}
            </b-form-radio>
          </b-form-radio-group>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { types } from '../../store/types/types';
import { activityListSortTypes } from '../../../shared/enums/activityListSortTypes';
import { mapMutations, mapGetters } from 'vuex';
import moment from 'moment-timezone';
import Vue from 'vue';
import MobileTopbar from '../../../shared/components/MobileTopbar';
import $ from 'jquery';
import { weekOverviewPresenceOptions } from '../../../shared/enums/weekOverviewPresenceOptions';

export default {
  props: {
    activePresenceStates: { type: Array, required: true },
    nextActivities: { type: Array, required: true },
    defaultTab: { type: String, default: 'PRESENCE_ACTIVITY_LIST_TODAY' },
  },
  data: function () {
    return {
      sortedTranslation: {
        [activityListSortTypes.NAME]: `ACTIVITY_LIST_SORT_NAME`,
        [activityListSortTypes.STATUS]: 'ACTIVITY_LIST_SORT_STATUS',
        [activityListSortTypes.NEXT_ACTIVITY_DATE]: 'ACTIVITY_LIST_SORT_CHRONOLOGY',
      },
      activityListSortTypes: activityListSortTypes,
      showToolbar: false,
      showGroupsMenu: false,
      showStatesMenu: false,
      showActivityMenu: false,
      showSortMenu: false,
      showStatusMenu: false,
      showFilterDailyNoteMenu: false,
      groupIds: [],
      departmentId: null,
      hasDailyNote: false,
      activityId: null,
      stateIds: [],
      activeSort: activityListSortTypes.NEXT_ACTIVITY_DATE,
      date: moment().format('YYYY-MM-DD'),
      weekOverviewFilterOptions: [],
      selectedWeekFilters: [],
    };
  },
  computed: {
    ...mapGetters({
      defaultDateFormat: types.GET_DEFAULT_DATE_FORMAT,
      defaultDateValueFormat: types.GET_DEFAULT_DATE_VALUE_FORMAT,
      institutions: types.GET_INSTITUTIONS,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
      activityList: types.GET_ACTIVITY_LIST,
      departments: types.GET_DEPARTMENTS,
      selectedDepartment: types.GET_SELECTED_DEPARTMENT,
      departmentGroups: types.GET_DEPARTMENT_GROUPS,
      filterOptions: types.GET_FILTER_OPTIONS,
      isEditMode: types.GET_ACTIVITY_LIST_EDIT_MODE,
      activities: types.GET_PRESENCE_ACTIVITIES,
      states: types.GET_PRESENCE_STATES,
    }),
  },
  methods: {
    ...mapMutations({
      updateFilterOptions: types.MUTATE_FILTER_OPTIONS,
    }),
    updateWeekOverviewOptions(selectedOptions) {
      const statusFilters = selectedOptions.map(option => option.value);
      this.updateFilterOptions({ statusFilters });
    },
    initWeekOverviewOptions() {
      this.weekOverviewFilterOptions = [
        {
          value: weekOverviewPresenceOptions.PRESENT,
          label: Vue.filter('fromTextKey')('PRESENCE_STATE_PRESENT'),
          options: [
            {
              value: weekOverviewPresenceOptions.PRESENT,
              label: Vue.filter('fromTextKey')('PRESENCE_STATE_PRESENT'),
              isHidden: true,
            },
          ],
        },
        {
          value: weekOverviewPresenceOptions.NOT_PRESENT,
          label: Vue.filter('fromTextKey')('PRESENCE_STATE_NOT_PRESENT'),
          options: [
            {
              value: weekOverviewPresenceOptions.VACATION,
              label: Vue.filter('fromTextKey')('PRESENCE_STATE_VACATION'),
            },
            {
              value: weekOverviewPresenceOptions.SICK,
              label: Vue.filter('fromTextKey')('PRESENCE_STATE_SICK'),
            },
          ],
        },
      ];
    },
    containsAll(needles, haystack) {
      for (let i = 0; i < needles.length; i++) {
        if ($.inArray(needles[i], haystack) == -1) {
          return false;
        }
      }
      return true;
    },
    selectAllSubStates(selected, stateIds) {
      if (selected) {
        this.stateIds = [...new Set(this.stateIds.concat(stateIds))];
      } else {
        for (const stateId of stateIds) {
          this.stateIds = this.stateIds.filter(id => id !== stateId);
        }
      }
      this.statesUpdated();
    },
    selectAllSubWeekFilter(selected, filterOptions) {
      if (selected) {
        this.selectedWeekFilters = [...new Set(this.selectedWeekFilters.concat(filterOptions))];
      } else {
        for (const option of filterOptions) {
          this.selectedWeekFilters = this.selectedWeekFilters.filter(id => id !== option);
        }
      }
      this.weekFiltersUpdated();
    },
    groupsUpdated() {
      Vue.nextTick(() => {
        this.updateFilterOptions({ groupIds: this.groupIds });
      });
    },
    departmentUpdated() {
      Vue.nextTick(() => {
        this.updateFilterOptions({ departmentId: this.departmentId });
      });
    },
    statesUpdated() {
      Vue.nextTick(() => {
        const locationIds = this.stateIds
          .filter(stateId => stateId.indexOf('location') !== -1)
          .map(locationId => locationId.split('-')[1]);
        this.updateFilterOptions({ locationIds: locationIds });
        const stateIds = this.stateIds.filter(stateId => stateId.indexOf('location') === -1);
        this.updateFilterOptions({ states: stateIds });
      });
    },
    weekFiltersUpdated() {
      Vue.nextTick(() => {
        this.updateFilterOptions({ statusFilters: this.selectedWeekFilters });
      });
    },
    activityUpdated() {
      Vue.nextTick(() => {
        this.updateFilterOptions({ nextActivity: this.activityId });
      });
    },
    hasDailyNoteUpdated() {
      Vue.nextTick(() => {
        this.updateFilterOptions({ hasDailyNote: this.hasDailyNote });
      });
    },
    sortUpdated() {
      Vue.nextTick(() => {
        this.updateFilterOptions({ activityListSort: this.activeSort });
      });
    },
  },
  watch: {
    'filterOptions.departmentId'() {
      this.departmentId = this.filterOptions.departmentId;
    },
    'filterOptions.groupIds'() {
      this.groupIds = this.filterOptions.groupIds;
      this.departmentId = this.filterOptions.departmentId;
    },
    'filterOptions.nextActivity'() {
      this.activityId = this.filterOptions.nextActivity;
    },
    'filterOptions.activityListSort'() {
      this.activeSort = this.filterOptions.activityListSort;
    },
    'filterOptions.hasDailyNote'() {
      this.hasDailyNote = this.filterOptions.hasDailyNote;
    },
    defaultTab(value) {
      if (value === 'PRESENCE_ACTIVITY_LIST_WEEK') {
        this.selectedWeekFilters = [];
      }
    },
    showToolbar(isShown) {
      if (isShown) {
        const transitionTimeInMs = 1000;
        setTimeout(() => {
          $('.presence-activity-list').hide();
        }, transitionTimeInMs);
      } else {
        $('.presence-activity-list').show();
      }
    },
  },
  mounted() {
    this.initWeekOverviewOptions();
  },
  components: { MobileTopbar },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/presence/_presence-employee.scss';
</style>
