<template>
  <div class="employee-vacation-request-container mb-lg-3">
    <div v-if="!isMobile" class="d-flex mt-4 justify-content-between">
      <div>
        {{ 'PRESENCE_VACATION_REQUEST_DESCRIPTION_2' | fromTextKey }}
      </div>
      <div>
        <b-btn
          v-if="canCreateVacationRequest"
          variant="primary"
          class="pull-right cancel-edit"
          @click="onCreateClicked"
        >
          {{ 'BUTTON_CREATE' | fromTextKey }}
        </b-btn>
      </div>
    </div>
    <aula-spinner v-if="isLoading" />
    <template v-else>
      <div id="vacationRegistrationList" class="table mt-0 mt-lg-3 mb-0">
        <div v-if="!isMobile" class="table-row header">
          <div class="table-cell">
            {{ 'PRESENCE_VACATION_REGISTRATION_TITLE' | fromTextKey }}
          </div>
          <div class="table-cell">
            {{ 'PRESENCE_VACATION_REGISTRATION_DEPARTMENT_GROUPS' | fromTextKey }}
          </div>
          <div class="table-cell">
            {{ 'PRESENCE_VACATION_REGISTRATION_PERIOD' | fromTextKey }}
          </div>
          <div class="table-cell">
            {{ 'PRESENCE_VACATION_REGISTRATION_DEADLINE' | fromTextKey }}
          </div>
        </div>
        <template v-if="vacationRegistrationData">
          <div
            v-for="(registration, i) in vacationRegistrationData.vacationRegistrations"
            :key="i"
            class="table-row body"
            tabindex="0"
            role="row"
            @keyup.enter="openVacationRequestView(registration)"
            @click="openVacationRequestView(registration)"
          >
            <template v-if="isMobile">
              <div class="table-cell">
                <b>{{ registration.title }}</b>
                <div class="presence-institution-icon">
                  <user-icon
                    v-if="institutions.length > 1"
                    :short-name="registration.shortName"
                    :show-inline="true"
                    user-icon-class="light-background"
                  />
                </div>
                <div>{{ registration.subtitle }}</div>
                <div class="my-2">
                  <span v-for="(text, i) in registration.regardingDepartmentAndGroupsText" :key="i"
                    >{{ text }}<br
                  /></span>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <div class="d-flex my-1">
                      <span class="date-label-mobile"
                        >{{ 'PRESENCE_VACATION_REGISTRATION_PERIOD' | fromTextKey }}:</span
                      >
                      {{ getFormatDatePeriod(registration.startDate, registration.endDate) }}
                    </div>
                    <div v-if="registration.deadline" class="d-flex">
                      <span class="date-label-mobile">{{ 'PRESENCE_REPORT_DEADLINE' | fromTextKey }}:</span>
                      {{ convertDateFormat(registration.deadline) }}
                    </div>
                  </div>
                  <div class="arrow-button d-flex align-items-center justify-content-center">
                    <i class="icon icon-Aula_forward-arrow" />
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="table-cell">
                <b>{{ registration.title }}</b
                ><br />
                {{ registration.subtitle }}
              </div>
              <div class="table-cell">
                <span v-for="(text, i) in registration.regardingDepartmentAndGroupsText" :key="i"
                  >{{ text }}<br
                /></span>
              </div>
              <div class="table-cell text-nowrap">
                {{ getFormatDatePeriod(registration.startDate, registration.endDate) }}
              </div>
              <div class="table-cell text-nowrap">
                <span v-if="registration.deadline">{{ convertDateFormat(registration.deadline) }}</span>
              </div>
            </template>
          </div>
          <div
            v-if="departments.length > 0 && vacationRegistrationData.vacationRegistrations.length === 0"
            class="mt-2 ml-2"
          >
            {{ 'PRESENCE_WARNING_NO_RESULTS_FOUND' | fromTextKey }}
          </div>
        </template>
      </div>
      <div v-if="departments.length === 0" class="px-2 px-lg-0">
        {{ 'PRESENCE_WARNING_NOT_MEMBER_OF_PRESENCE' | fromTextKey }}
      </div>
      <aula-spinner v-if="isLoadingMore" />
    </template>
    <multi-button-mobile
      v-if="isMobile && multiButtonItems.length > 0"
      :items="multiButtonItems"
      @onCreateClicked="onCreateClicked"
    />
    <RegisterVacationRequestDrawer ref="registerVacationRequestDrawer" @onClosed="showDrawer" />
    <drawer :show="isDrawerShown" @change-show="changeDrawerState">
      <template #drawer-title>
        <div class="presence-drawer-header">
          <Icon :name="iconClassEnum.EXPORT" class="drawer-title-icon hide-tablet-down mr-3" aria-hidden="true" />
          <span> {{ drawerTitle }} </span>
        </div>
      </template>
      <template #header-right>
        <AulaButton
          v-if="canEditCurrentVacationRequest && !isCreating && isReadonly"
          variant="link"
          class="drawer-edit-button mt-0"
          :aria-label="'ARIA_LABEL_EDIT' | fromTextKey"
          @click="toggleEditMode"
        >
          <Icon :name="iconClassEnum.EDIT_PEN" class="icon" />
          <span v-if="!isMobile"> {{ 'BUTTON_EDIT' | fromTextKey }}</span>
        </AulaButton>
      </template>
      <template #drawer-content>
        <vacation-request
          v-if="isDrawerShown && !isReadonly"
          :parent="parentTypes.PRESENCE"
          :is-edit-mode="!isReadonly && !isCreating && selectedVacationRequestId != null"
          :selected-vacation-request-id="selectedVacationRequestId"
          @onClosed="hideDrawer"
        />
        <vacation-request-details
          v-else-if="isDrawerShown && vacationRequest"
          :vacation-request="vacationRequest"
          @showChildVacationRequest="onShowChildVacationRequest"
          @onClosed="hideDrawer"
        />
      </template>
    </drawer>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { types } from '../../store/types/types';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import moment from 'moment-timezone';
import MultiButtonMobile from '../shared/MultiButtonMobile';
import VacationRequest from '../calendar/VacationRequest';
import VacationRequestDetails from '../calendar/VacationRequestDetails';
import $ from 'jquery';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import { portalRoles } from '../../../shared/enums/portalRoles';
import dateTimeFormatMixin from '../../../shared/mixins/dateTimeFormatMixin';
import RegisterVacationRequestDrawer from '../presence/RegisterVacationRequestDrawer';
import { VacationRegistration } from '../../business/vacationRegistration';
import { ChildVacationRegistration } from '../../business/childVacationRegistration';
import Icon from '../../../shared/components/Icon.vue';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import AulaButton from '../../../shared/components/AulaButton.vue';

export default {
  components: {
    AulaButton,
    Icon,
    VacationRequestDetails,
    VacationRequest,
    MultiButtonMobile,
    RegisterVacationRequestDrawer,
  },
  mixins: [dateTimeFormatMixin],
  data: function () {
    return {
      isLoading: true,
      isLoadingMore: false,
      isDrawerShown: false,
      isCreating: true,
      isReadonly: false,
      selectedVacationRequestId: null,
      selectedVacationRequestTitle: '',
      parentTypes,
      vacationRequest: null,
    };
  },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
      profile: types.GET_CURRENT_PROFILE,
      institutions: types.GET_INSTITUTIONS,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
      vacationRegistrationData: types.GET_VACATION_REGISTRATION_DATA,
      filterOptions: types.GET_FILTER_OPTIONS,
      hasMoreVacationRegistrations: types.GET_HAS_MORE_VACATION_REGISTRATIONS,
      departments: types.GET_DEPARTMENTS,
      getPresenceFilters: types.GET_PRESENCE_FILTERS,
      hasPermissionOnInstitution: types.HAS_PERMISSION_ON_INSTITUTION,
      activeEvent: types.GET_CURRENT_EVENT,
    }),
    drawerTitle() {
      let title = '';
      if (this.isCreating) {
        title = Vue.filter('fromTextKey')('CALENDAR_TOOLBAR_REQUEST_VACATION_HEADER');
      } else {
        title = this.selectedVacationRequestTitle;
      }
      return title;
    },
    activePresenceInstitutionCodes() {
      return this.getPresenceFilters
        .filter(item => Array.isArray(item.departments) && this.activeInstitutions.includes(item.institutionCode))
        .map(item => item.institutionCode);
    },
    activeInstWithVacationRequestPermission() {
      return this.institutions.filter(
        inst =>
          this.activePresenceInstitutionCodes.includes(inst.institutionCode) &&
          this.hasPermissionOnInstitution(permissionEnum.HANDLE_VACATION_REQUESTS, inst.institutionCode)
      );
    },
    canCreateVacationRequest() {
      return this.profile.role === portalRoles.EMPLOYEE && this.activeInstWithVacationRequestPermission.length > 0;
    },
    canEditCurrentVacationRequest() {
      if (this.activeEvent) {
        const hasEditPermission = this.hasPermissionOnInstitution(
          permissionEnum.HANDLE_VACATION_REQUESTS,
          this.activeEvent.institutionCode
        );

        if (hasEditPermission && moment().isBefore(this.activeEvent.startDateTime, 'days')) {
          return true;
        }
      }
      return false;
    },
    multiButtonItems() {
      if (this.canCreateVacationRequest) {
        return [
          {
            icon: 'icon-Aula_plus',
            text: 'CALENDAR_BUTTON_CREATE',
            action: 'onCreateClicked',
          },
        ];
      }
      return [];
    },
  },
  methods: {
    ...mapMutations({
      updateFilterOptions: types.MUTATE_FILTER_OPTIONS,
    }),
    ...mapActions({
      loadVacationRegistrations: types.LOAD_VACATION_REGISTRATIONS,
    }),
    getData(loadMore = false) {
      this.isLoading = !loadMore;
      this.isLoadingMore = loadMore;
      this.loadVacationRegistrations({ loadMore }).finally(() => {
        this.isLoading = false;
        this.isLoadingMore = false;
      });
    },
    hideDrawer() {
      this.isDrawerShown = false;
      this.getData();
    },
    showDrawer() {
      this.isDrawerShown = true;
    },
    changeDrawerState(state) {
      if (!state) {
        this.hideDrawer();
      }
    },
    openVacationRequestView(vacationRequest) {
      this.isCreating = false;
      this.isReadonly = true;
      this.vacationRequest = vacationRequest;
      this.selectedVacationRequestId = vacationRequest.vacationRegistrationId;
      this.selectedVacationRequestTitle = vacationRequest.title;
      this.isDrawerShown = true;
    },
    onCreateClicked() {
      this.isCreating = true;
      this.isReadonly = false;
      this.isDrawerShown = true;
    },
    toggleEditMode() {
      this.isCreating = false;
      this.isReadonly = false;
    },
    handleScroll() {
      if (this.hasMoreVacationRegistrations && !this.isLoadingMore && !this.isLoading) {
        const scrollDiv = $('#vacationRegistrationList');
        if (scrollDiv.length > 0) {
          const scrollHeight = $(window).scrollTop() + $(window).height();
          const contentHeight = scrollDiv.offset().top + scrollDiv.height();
          if (scrollHeight >= contentHeight) {
            this.getData(true);
          }
        }
      }
    },
    onShowChildVacationRequest({ child, hasAnswered, registrationResponseId }) {
      const vacationRegistration = new VacationRegistration({
        endDate: this.activeEvent.end,
        isEditable: true,
        isMissingAnswer: !hasAnswered,
        isPresenceTimesRequired: this.activeEvent.vacationRegistration.isPresenceTimesRequired,
        noteToGuardian: this.activeEvent.vacationRegistration.noteToGuardians,
        responseDeadline: this.activeEvent.responseDeadline,
        responseId: registrationResponseId,
        startDate: this.activeEvent.start,
        title: this.activeEvent.title,
        vacationRegistrationId: this.vacationRequest.vacationRegistrationId,
      });
      const childInstance = new ChildVacationRegistration({
        ...child,
        institutionCode: this.activeEvent.institutionCode,
      });

      this.$refs.registerVacationRequestDrawer.show(vacationRegistration, childInstance, false);
      this.isDrawerShown = false;
    },
  },
  watch: {
    filterOptions: {
      handler() {
        this.getData(false);
      },
      deep: true,
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.getData();
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
    this.updateFilterOptions({ statusFilters: null });
  },
};
</script>
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/presence/_presence-employee.scss';
</style>
