<template>
  <div class="message-functionalities">
    <b-dropdown class="dropdown-select px-2" variant="link" no-caret @click.prevent>
      <template #button-content>
        <span class="sr-only"> {{ $t('messages.functionalities') }}</span>
        <Icon :name="iconClassEnum.ELIPSES" />
      </template>
      <b-dropdown-item v-if="showDelete" @click="onDeleteMessageClicked">
        {{ 'DELETE' | fromTextKey }}
      </b-dropdown-item>
      <b-dropdown-item v-if="canEditMessage" @click="onEditMessageClicked">
        {{ 'EDIT' | fromTextKey }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>
<script>
import { messageProviderKeyEnum } from '../../../shared/enums/messageProviderKeyEnum';
import { messageTypes } from '../../../shared/enums/messageTypes';
import Icon from "../../../shared/components/Icon.vue";
import {iconClassEnum} from "../../../shared/enums/iconClassEnum";
import button from '../../../shared/directives/button.js';

export default {
  components: {Icon},
  directives: {
    button,
  },
  inject: {
    onDeleteMessage: messageProviderKeyEnum.ON_DELETE_MESSAGE,
    onEditMessage: messageProviderKeyEnum.ON_EDIT_MESSAGE,
  },
  props: {
    message: { type: Object, default: () => {} },
    showDelete: { type: Boolean, default: true },
  },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
    canEditMessage() {
      const allowedMessageTypes = [messageTypes.MESSAGE, messageTypes.MESSAGE_EDITED];
      return allowedMessageTypes.includes(this.message.messageType);
    },
  },
  methods: {
    onDeleteMessageClicked() {
      this.onDeleteMessage();
    },
    onEditMessageClicked() {
      this.onEditMessage(this.message);
    },
  },
};
</script>
