<template>
  <div class="phone-container">
    <div v-if="profile.homePhoneNumber && profile.contactType !== contactTypeTypes.GROUP_HOME" class="d-flex">
      <p class="mb-0 phone-number">{{ 'PROFILE_HOME_PHONE_LABEL' | fromTextKey }}:</p>
      <p class="mb-0">
        <a class="color-primary-darker" :href="'tel:' + profile.homePhoneNumber">
          {{ profile.homePhoneNumber }}
        </a>
      </p>
    </div>
    <div v-if="profile.mobilePhoneNumber" class="d-flex">
      <p class="mb-0 phone-number">
        <template v-if="profile.contactType === contactTypeTypes.GROUP_HOME">
          {{ 'GROUP_HOME_PHONE_PRIMARY_LABEL' | fromTextKey }}
        </template>
        <template v-else> {{ 'PROFILE_MOBILE_PHONE_LABEL' | fromTextKey }}</template>
        :
      </p>
      <p class="mb-0">
        <a class="color-primary-darker" :href="'tel:' + profile.mobilePhoneNumber">
          {{ profile.mobilePhoneNumber }}
        </a>
      </p>
    </div>
    <div v-if="profile.workPhoneNumber" class="d-flex">
      <p class="mb-0 phone-number">
        <template v-if="profile.contactType === contactTypeTypes.GROUP_HOME">
          {{ 'GROUP_HOME_PHONE_SECONDARY_LABEL' | fromTextKey }}
        </template>
        <template v-else> {{ 'PROFILE_WORK_PHONE_LABEL' | fromTextKey }}</template>
        :
      </p>
      <p class="mb-0">
        <a class="color-primary-darker" :href="'tel:' + profile.workPhoneNumber">
          {{ profile.workPhoneNumber }}
        </a>
      </p>
    </div>
  </div>
</template>
<script lang="js">
import {contactTypeTypes} from "../../enums/contactTypeTypes";

export default {
  name: 'ContactsFragmentDisplayProfilePhoneNumbers',
  props: {
    profile: {
      required: true,
      type: Object,
    }
  },
  data() {
    return {
      contactTypeTypes
    }
  }
};
</script>
<style lang="scss" scoped>
.phone-container {
  white-space: nowrap;
}
.phone-number {
  width: 100px;
}
</style>
