<template>
  <div class="aula-documentsCommonList-container page-layout-padding">
    <h1 class="document-title" tabindex="0">
      <router-link :to="{ name: 'documents' }">{{ 'DOCUMENTS_TYPES_PAGE_TITLE' | fromTextKey }}</router-link>
      /
      <b>{{ 'DOCUMENTS_TYPES_COMMON_FILES_TITLE' | fromTextKey }}</b>
    </h1>
    <div class="sort pull-right">
      <b-dropdown class="dropdown-select" right>
        <template slot="button-content">
          <span class="sort ml-n1" :class="sortDirection" />
          {{ sortTexKeyMap.get(sort) | fromTextKey }}
          <i class="icon icon-Aula_down-arrow" />
        </template>
        <b-dropdown-item :class="sort === sortTypes.TIME ? sortDirection : ''" @click="setSortOrder(sortTypes.TIME)">
          {{ 'DOCUMENTS_SHARED_OVERVIEW_TABLE_CREATED_DATE' | fromTextKey }}
        </b-dropdown-item>
        <b-dropdown-item
          :class="sort === sortTypes.TITLE ? sortDirection : ''"
          @click="setSortOrder(sortTypes.TITLE)"
          >{{ 'DOCUMENTS_SHARED_OVERVIEW_TABLE_TITLE' | fromTextKey }}</b-dropdown-item
        >
      </b-dropdown>
    </div>
    <ObservingContainer :options="observerOptions">
      <div class="table">
        <div class="table-row header cursor-default">
          <div class="table-cell" />
          <div class="table-cell">
            {{ 'DOCUMENTS_SHARED_OVERVIEW_TABLE_TITLE' | fromTextKey }}
          </div>
          <div class="table-cell">
            {{ 'DOCUMENTS_SHARED_OVERVIEW_TABLE_INSTITUTION' | fromTextKey }}
          </div>
          <div class="table-cell fit-content">
            {{ 'DOCUMENTS_SHARED_OVERVIEW_TABLE_CREATED_DATE' | fromTextKey }}
          </div>
        </div>
        <div
          v-for="(item, i) in documents"
          :key="i"
          class="table-row body"
          tabindex="0"
          role="button"
          :aria-label="'ARIA_LABEL_DOWNLOAD_DOCUMENT' | fromTextKey"
          @click="downloadItem(item)"
          @keydown.enter="downloadItem(item)"
        >
          <div class="table-cell not-collapsed check">
            <i class="icon-Aula_paper" style="font-size: 1.5em" />
          </div>
          <div class="table-cell title not-collapsed">
            <badge-notification
              v-if="notifications.filter(not => not.commonFileId == item.id).length > 0"
              :show-exclamation-icon-instead-of-amount="true"
              class="documents badge-notification"
            />
            <b>
              {{ item.title }}
              <template v-if="item.isDataPolicy">{{ 'COMMON_FILE_DATAPOLICY' | fromTextKey }}</template>
            </b>
          </div>
          <div class="table-cell not-collapsed">
            {{ item.institution.name }}
          </div>
          <div class="table-cell not-collapsed">
            {{ item.created | shortDate(true) }}
            kl {{ item.created | time }}
          </div>
          <div class="table-cell collapsed-only">
            <div class="item">
              <badge-notification
                v-if="
                  notifications.filter(
                    n =>
                      (n.notificationEventType == notificationEventTypes.NEW_COMMON_FILE ||
                        n.notificationEventType == notificationEventTypes.UPDATED_SHARING_COMMON_FILE ||
                        n.notificationEventType == notificationEventTypes.NEW_OR_UPDATED_COMMON_FILE) &&
                      n.notificationArea == notificationAreas.DOCUMENTS &&
                      n.notificationType == notificationTypes.BADGE &&
                      n.commonFileId == item.id
                  ).length > 0
                "
                :show-exclamation-icon-instead-of-amount="true"
                class="documents badge-notification"
              />
              <b
                >{{ item.title }}
                <template v-if="item.isDataPolicy">{{ 'COMMON_FILE_DATAPOLICY' | fromTextKey }}</template></b
              >
            </div>
            <div class="item">
              <span>{{ 'DOCUMENTS_SHARED_OVERVIEW_TABLE_INSTITUTION' | fromTextKey }}</span>
              {{ item.institution.name }}
            </div>
            <div class="item">
              <span>{{ 'DOCUMENTS_SHARED_OVERVIEW_TABLE_EDITED' | fromTextKey }}</span>
              {{ item.created | shortDate(true) }}
              kl. {{ item.created | time }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="isLoading" class="spinHolder">
        <aula-spinner />
      </div>
      <ObservedTarget v-if="documents.length > 0" @onIntersecting="onBottomReached" />
    </ObservingContainer>
  </div>
</template>

<script>
import { types } from '../../store/types/types';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import BadgeNotification from '../../../shared/components/BadgeNotification.vue';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { notificationEventTypes } from '../../../shared/enums/notificationEventTypes';
import { notificationAreas } from '../../../shared/enums/notificationAreas';
import { notificationTypes } from '../../../shared/enums/notificationTypes';
import { sortTypes } from '../../../shared/enums/sortTypes';
import ObservingContainer from '../../../shared/libs/intersection-observer/components/Container.vue';
import ObservedTarget from '../../../shared/libs/intersection-observer/components/Target.vue';

export default {
  data: function () {
    return {
      portalRole: portalRoles,
      permissionEnum: permissionEnum,
      isLoading: false,
      notificationEventTypes: notificationEventTypes,
      notificationAreas: notificationAreas,
      notificationTypes: notificationTypes,
      sortTypes,
      paginationStart: 0,
      pageSize: 20,
      sort: sortTypes.TITLE,
      sortDirection: 'asc',
      sortTexKeyMap: new Map([
        [sortTypes.TITLE, 'DOCUMENTS_SHARED_OVERVIEW_TABLE_TITLE'],
        [sortTypes.TIME, 'DOCUMENTS_SHARED_OVERVIEW_TABLE_CREATED_DATE'],
      ]),
    };
  },
  computed: {
    ...mapGetters({
      profile: types.GET_CURRENT_PROFILE,
      selectedItem: types.GET_SELECTED_ITEM,
      breadcrumb: types.GET_BREADCRUMB,
      hasPermission: types.HAS_PERMISSION,
      documentItems: types.GETTER_COMMON_FILES_LIST,
      institutions: types.GET_INSTITUTIONS,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
      activeChildren: types.GET_ACTIVE_CHILDREN,
      children: types.GET_CHILDREN,
      notifications: types.GET_NOTIFICATIONS,
    }),
    documents() {
      return this.documentItems?.commonFiles || [];
    },
    hasMoreDocuments() {
      return this.documentItems.totalAmount > (this.paginationStart + 1) * this.pageSize;
    },
    observerOptions() {
      return { rootMargin: '50%' };
    },
  },
  methods: {
    ...mapActions({
      loadDocuments: types.ACTION_GET_COMMON_FILES_LIST,
      appendDocuments: types.ACTION_GET_MORE_COMMON_FILES,
      deleteNotifications: types.DELETE_NOTIFICATIONS,
      loadNotifications: types.LOAD_NOTIFICATIONS,
    }),
    ...mapMutations({
      mutateData: types.MUTATION_COMMON_FILES_SET_LIST,
    }),
    onBottomReached() {
      if (this.isLoading || !this.hasMoreDocuments) {
        return;
      }
      this.getMoreDocuments();
    },
    downloadItem(item) {
      if (this.notifications.filter(not => not.commonFileId == item.id).length > 0) {
        this.deleteBadges(item.id);
      }
      window.open(item.file.file.url);
    },
    deleteBadges(commonFileId) {
      const notifications = [];
      const documentNotifications = this.notifications.filter(
        n =>
          (n.notificationEventType == notificationEventTypes.NEW_COMMON_FILE ||
            n.notificationEventType == notificationEventTypes.UPDATED_SHARING_COMMON_FILE ||
            n.notificationEventType == notificationEventTypes.NEW_OR_UPDATED_COMMON_FILE) &&
          n.notificationArea == notificationAreas.DOCUMENTS &&
          n.notificationType == notificationTypes.BADGE &&
          n.commonFileId == commonFileId
      );

      if (documentNotifications.length > 0) {
        for (const notification of documentNotifications) {
          notifications.push({
            notificationId: notification.notificationId,
            institutionProfileId: notification.institutionProfileId,
          });
        }
      }

      if (notifications.length > 0) {
        this.deleteNotifications({ notifications: notifications }).then(() => {
          this.loadNotifications({
            activeChildrenIds: this.activeChildren,
            activeInstitutionCodes: this.activeInstitutions,
          });
        });
      }
    },
    setSortOrder(sortItem) {
      this.paginationStart = 0;
      if (this.sort == sortItem) {
        this.sortDirection = this.sortDirection == 'asc' ? 'desc' : 'asc';
      } else {
        this.sort = sortItem;
        if (sortItem === sortTypes.TIME) {
          this.sortDirection = 'desc';
        } else {
          this.sortDirection = 'asc';
        }
      }
      this.getDocuments();
    },
    async getDocuments() {
      const payload = {
        index: this.paginationStart * this.pageSize,
        limit: this.pageSize,
        institutionCodes: this.getActiveInstitutionCodesForProfile(),
        orderDirection: this.sortDirection,
        orderField: this.sort,
      };

      this.isLoading = true;
      if (this.paginationStart == 0) {
        await this.loadDocuments(payload);
      } else {
        await this.appendDocuments(payload);
      }
      this.isLoading = false;
    },
    getActiveInstitutionCodesForProfile() {
      if (this.profile.role === this.portalRole.EMPLOYEE) {
        return this.activeInstitutions;
      } else if (this.profile.role === this.portalRole.CHILD) {
        return this.institutions.map(institution => institution.institutionCode);
      } else {
        const institutions = this.institutions.filter(institution =>
          institution.children.some(child => this.activeChildren.includes(child.id))
        );
        return institutions
          .map(institution => institution.institutionCode)
          .filter((value, index, self) => self.indexOf(value) === index);
      }
    },
    getMoreDocuments() {
      this.paginationStart++;
      this.getDocuments();
    },
  },
  watch: {
    activeInstitutions: function () {
      this.getDocuments();
    },
    activeChildren() {
      this.getDocuments();
    },
  },
  mounted() {
    const em = this;
    const checkInstitutionIsLoaded = setInterval(function () {
      if (em.activeInstitutions.length > 0) {
        em.getDocuments();
        clearInterval(checkInstitutionIsLoaded);
      }
    }, 100);
    setTimeout(function () {
      document.querySelector('.aula-documentsCommonList-container .document-title').focus();
    }, 500);
  },
  components: {
    ObservedTarget,
    ObservingContainer,
    BadgeNotification,
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/documents/_overview.scss';

.document-title {
  outline: none;
}
.badge-notification.documents {
  --position: unset;
  margin-inline-end: 10px;
  float: left;

  /deep/ .aula-badge {
    width: 18px;
    height: 18px;
    i:before {
      content: '';
    }
  }
}

.table-cell.collapsed-only /deep/ .badge {
  position: relative;
  top: 9px;
  left: 30px;
}

.upload-link {
  cursor: pointer;
  float: left;
  margin-top: 12px;
}
.submit-row {
  margin-top: 15px;
}
.modalSharedWith {
  .row {
    .col,
    .col-1,
    .col-2,
    .col-4 {
      padding: 10px 10px 5px 10px;
      border-bottom: solid 1px #eee;
    }
    .text {
      padding-top: 13px;
    }
    .check {
      text-align: center;
      label {
        margin-right: 0;
      }
    }
    .delete {
      text-align: right;
      padding-top: 14px;
    }
  }
}
.file-badge {
  position: absolute;
  left: -6px;
  top: -8px;
}
.aula-documentsCommonList-container {
  .breadcrumbs {
    cursor: pointer;
  }

  .fit-content {
    width: fit-content !important;
  }

  .spinHolder {
    clear: both;
  }
  .show-more {
    padding: 20px 0;
    text-align: center;
    button {
      float: none;
    }
  }
}

.sort {
  margin-top: auto;
  &.asc::before,
  &.desc::before {
    margin-left: 3px !important;
    margin-right: -3px !important;
    color: unset !important;
  }
}
</style>
