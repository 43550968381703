<template>
  <aula-modal
    ref="modal"
    header-text="MESSAGE_MOVE_TO_FOLDER"
    :always-visible="true"
    :hide-footer="true"
    :disable-submit="submitButtonIsDisabled"
    :width="!isMobile ? '500px' : ''"
    class="message-move-to-folder"
    @closeClicked="emitVisibility(false)"
    @cancelClicked="emitVisibility(false)"
    @okClicked="moveMessageToFolder"
  >
    {{ 'MESSAGE_CHOOSE_FOLDER' | fromTextKey }}
    <div class="container-scroll">
      <b-dropdown-item
        size="sm"
        :disabled="chosenFolderAndMailOwner.folderId === 'inbox' || !chosenFolderAndMailOwner.folderId"
        class="no-bullet"
        :class="folderClass('inbox')"
        @click="folderSelected('inbox')"
      >
        <span class="folder-icon"><i class="icon-Aula_folder" /></span>
        <template
          v-if="
            chosenFolderAndMailOwner != null && chosenFolderAndMailOwner.mailOwnerType == messageOwnerTypes.COMMON_INBOX
          "
        >
          {{ chosenFolderAndMailOwner.commonInboxName }}
        </template>
        <template v-else>
          {{ 'MESSAGE_TOOLBAR_INBOX' | fromTextKey }}
        </template>
      </b-dropdown-item>
      <b-dropdown-item
        v-for="folder in normalFolders"
        :key="folder.id"
        size="sm"
        :disabled="folder.id == chosenFolderAndMailOwner.folderId"
        :class="folderClass(folder.id)"
        class="sub-folder no-bullet"
        @click="folderSelected(folder.id)"
      >
        <span class="folder-icon"><i class="icon-Aula_folder" /></span>{{ folder.name }}
      </b-dropdown-item>
    </div>
    <div v-if="!isMobile" class="modal-footer">
      <b-btn variant="link" @click="emitVisibility(false)">
        {{ 'MESSAGE_BUTTON_CANCEL' | fromTextKey }}
      </b-btn>
      <b-btn size="sm" variant="primary" :disabled="submitButtonIsDisabled" @click="moveMessageToFolder">
        {{ 'MESSAGE_MOVE' | fromTextKey }}
      </b-btn>
    </div>
  </aula-modal>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { mapGetters } from 'vuex';
import { types } from '../../store/types/types';
import { messageOwnerTypes } from '../../../shared/enums/messageOwnerTypes';
import { folderTypes } from '../../../shared/enums/folderTypes';

export default {
  props: {
    threadIds: {
      type: Array,
      default: () => [],
    },
    subscriptionIds: {
      type: Array,
      default: () => [],
    },
    folders: {
      type: Array,
      required: true,
    },
    chosenFolderAndMailOwner: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      selectedFolder: null,
      messageOwnerTypes: messageOwnerTypes,
      currentFolder: this.$store.state.messages.currentFolder,
    };
  },
  computed: {
    submitButtonIsDisabled: function () {
      return this.selectedFolder === null;
    },
    ...mapGetters({
      hasPermission: types.HAS_PERMISSION,
      isMobile: types.GET_IS_MOBILE,
      latestThreadsMoved: types.MESSAGES_GET_LATEST_MOVED_THREADS,
      activeChildren: types.GET_ACTIVE_CHILDREN,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
      isInSearch: types.MESSAGES_GET_IN_SEARCH,
    }),
    normalFolders() {
      return this.folders.filter(folder => folder.type === folderTypes.NORMAL);
    },
  },
  methods: {
    ...mapActions({
      moveMessagesToFolderAction: types.MOVE_THREADS_TO_FOLDER,
      updateThreadViewAction: types.UPDATE_THREADS_VIEW,
      loadNotifications: types.LOAD_NOTIFICATIONS,
      resetThreadBundles: types.RESET_THREAD_BUNDLES,
    }),
    ...mapMutations({
      setSuccessMessage: types.MUTATE_SUCCESS_TEXT,
    }),
    folderClass(folderId) {
      if (this.currentFolder === folderId) {
        return {
          'chosen-item': this.selectedFolder === folderId,
        };
      }
      return {
        'chosen-item': this.selectedFolder === folderId,
        'current-folder': this.currentFolder === folderId,
      };
    },
    folderSelected(folderId) {
      // If user clicks twice on same folder it gets unchosen
      this.selectedFolder = this.selectedFolder === folderId ? null : folderId;
    },
    async moveMessageToFolder() {
      this.$router.push({ name: 'messages' });
      await this.moveMessagesToFolderAction({
        threadIds: this.threadIds,
        subscriptionIds: this.subscriptionIds,
        folderId: this.selectedFolder,
      });
      const successMessage =
        this.threadIds.length + this.subscriptionIds.length > 1
          ? 'SUCCESS_TOAST_MESSAGES_MOVE_MULTI_THREADS'
          : 'SUCCESS_TOAST_MESSAGES_MOVE_THREAD';
      this.setSuccessMessage(successMessage);
      this.resetThreadBundles();

      if (!this.isInSearch) {
        await this.updateThreadViewAction({
          folderId: this.chosenFolderAndMailOwner.folderId,
          filterType: this.chosenFolderAndMailOwner.filter,
          mailOwnerId: this.chosenFolderAndMailOwner.mailOwnerId,
          sort: this.chosenFolderAndMailOwner.sort,
          order: this.chosenFolderAndMailOwner.order,
        });
      }

      this.emitVisibility(false);
      this.searchIsLoading = false;
      this.loadNotifications({
        activeChildrenIds: this.activeChildren,
        activeInstitutionCodes: this.activeInstitutions,
      });
    },
    // This component should be used as a child component. By emitting a Bool to the parent about its visibility
    // the parent can remove this component totally and not just hide it.
    emitVisibility(value) {
      this.$emit('hide', value);
    },
  },
  mounted() {
    this.$refs.modal.show();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/elements/modal.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.folder-icon {
  padding-right: 1em;
}

.chosen-item,
.dropdown-item.active,
.dropdown-item:active {
  background-color: $color-primary-darker !important;
  color: $color-white !important;
  &:hover {
    color: $color-white !important;
    background-color: $color-primary-darker !important;
  }
}

.sub-folder {
  padding: 0.25em 3em;
}

.no-bullet {
  list-style: none;
}
.current-folder {
  font-weight: bold;
  background-color: $color-grey-light;
}
</style>
