





























import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import { mapActions, mapGetters } from 'vuex';
import { types } from '../../store/types/types.js';
import { InstitutionModel } from '../../../shared/models/institution.model';
import button from '../../../shared/directives/button.js';

export default {
  directives: {
    button,
  },
  data() {
    return {
      selectedProfiles: [],
    };
  },
  computed: {
    ...mapGetters({
      institutions: types.GET_INSTITUTIONS,
      activeInstitutionCodes: types.GET_ACTIVE_INSTITUTIONS,
    }),
    iconClassEnum() {
      return iconClassEnum;
    },
    profileOptions() {
      return this.institutions.map((institution: InstitutionModel) => ({
        text: institution.name,
        value: institution.institutionCode,
      }));
    },
  },
  mounted() {
    this.selectedProfiles = this.activeInstitutionCodes;
  },
  methods: {
    ...mapActions({
      setActiveInstitutions: types.SET_ACTIVE_INSTITUTIONS,
      loadNotifications: types.LOAD_NOTIFICATIONS,
    }),
    getOptionTooltip(institution: InstitutionModel) {
      if (this.getCheckboxDisabledState(institution)) {
        return this.$options.filters.fromTextKey('LIMIT_NUMBER_OF_ACTIVE_INSTITUTION_PROFILES');
      }
      return '';
    },
    handleProfilesSelect(institutionCodes: string[]) {
      if (institutionCodes.length < 1) {
        this.selectedProfiles = this.activeInstitutionCodes;
        return;
      }
      const activeInstitutions = this.institutions.filter((institution: InstitutionModel) =>
        institutionCodes.includes(institution.institutionCode)
      );
      this.setActiveInstitutions(activeInstitutions);
      this.loadNotifications({ activeInstitutionCodes: this.activeInstitutionCodes });
    },
    toggleCheckbox(event: Event) {
      const checkbox: HTMLInputElement = (event.currentTarget as HTMLElement).querySelector('input[type="checkbox"]');
      checkbox.click();
    },
    getCheckboxDisabledState(profile) {
      return this.activeInstitutionCodes.length === 6 && this.activeInstitutionCodes.includes(profile.id) === false;
    },
  },
};
