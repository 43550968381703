<template>
  <div class="subscription-container subscription-container-variables" role="list">
    <subscription
      v-button
      :subscription="subscriptionData"
      :is-bundle-open="isBundleOpen"
      type="button"
      class="thread"
      role="listitem"
      @click.native="onSubscriptionClicked"
    />
    <transition name="toggle">
      <bundled-subscriptions v-if="isBundleOpen" :subscription-data="subscriptionData" />
    </transition>
  </div>
</template>

<script>
import Subscription from './Subscription';
import BundledSubscriptions from './BundledSubscriptions';
import { mapGetters } from 'vuex';
import { types } from '../../store/types/types';
import { subscriptionTypes } from '../../../shared/enums/subscriptionTypes';
import Button from '../../../shared/directives/button';

export default {
  directives: {
    Button,
  },
  props: {
    subscriptionData: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      isBundleOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      profile: types.GET_CURRENT_PROFILE,
      otp: types.MESSAGES_GET_OTP_INBOXES,
      threadBundles: types.MESSAGES_GET_THREAD_BUNDLES,
      chosenFolderAndMailOwner: types.MESSAGES_GET_CHOSEN_FOLDER_AND_MAIL_OWNER,
    }),
  },
  methods: {
    onSubscriptionClicked() {
      if (this.subscriptionData.subscriptionType === subscriptionTypes.BUNDLE) {
        this.isBundleOpen = !this.isBundleOpen;
      }
    },
  },
  components: { BundledSubscriptions, Subscription },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/animations.scss';
.subscription-container-variables {
  --thread-item-gap: 2px;
}
.subscription-container {
  margin-top: var(--thread-item-gap);
}
</style>
