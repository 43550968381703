<template>
  <div :class="includePersonalDataPolicies ? 'privacy-policy-container' : ''" class="page-layout-padding">
    <h1 class="page-title" tabindex="0">
      {{ 'POLICY_TITLE' | fromTextKey }}
    </h1>
    <p>{{ 'AULA_PRIVACY_POLICY_MAIN' | fromTextKey }}</p>
    <p>{{ 'POLICY_SUMMARY_1_1' | fromTextKey }}</p>

    <b>{{ 'PRIVATE_POLICY' | fromTextKey | firstLetterUppercase }}</b>
    <p>
      {{ 'POLICY_SUMMARY_2_1' | fromTextKey }}
    </p>
    <p>
      {{ 'POLICY_SUMMARY_3_1' | fromTextKey }}
      <a
        v-for="policy in getPersonalDataPolicies"
        :key="policy.institutionCode"
        :href="policy.commonFile.file.file.url"
        class="policy-link"
        download
        target="_blank"
      >
        {{ policy.commonFile.title }}
        ({{ policy.institution.institutionName }}), </a
      >{{ 'POLICY_SUMMARY_3_2' | fromTextKey }}
    </p>
    <b>{{ 'POLICY_TITLE_DATAEHTICS' | fromTextKey }}</b>
    <p>
      {{ 'POLICY_SUMMARY_4_1' | fromTextKey }}
      <a href="javascript:void(0)" @click="$refs.privacyDataEhtics.show()">{{ 'AULA_DATA_EHTICS' | fromTextKey }}</a>
      {{ 'POLICY_SUMMARY_4_2' | fromTextKey }}
    </p>
    <aula-modal
      ref="privacyPolicyModal"
      :disable-portal="true"
      :show-cancel="false"
      header-text="PRIVATE_POLICY"
      @okClicked="$refs.privacyPolicyModal.hide()"
    >
      <div v-if="dataPolicy.content && dataPolicy.content.html" v-html="dataPolicy.content.html" />
    </aula-modal>
    <aula-modal
      ref="privacyDataEhtics"
      :disable-portal="true"
      :show-cancel="false"
      header-text="AULA_DATA_EHTICS"
      @okClicked="$refs.privacyDataEhtics.hide()"
    >
      <div v-html="Vue.filter('fromTextKey')('AULA_DATA_ETHICS_DETAILS')" />
    </aula-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import { types } from '../../store/types/types';
import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';

export default {
  props: {
    includePersonalDataPolicies: { type: Boolean, default: true },
  },
  data: function () {
    return {
      Vue: Vue,
    };
  },
  computed: {
    ...mapGetters({
      getPersonalDataPolicies: types.GET_PERSONAL_DATA_POLICIES,
      dataPolicy: types.GET_DATA_POLICY,
    }),
  },
  methods: {
    ...mapActions({
      loadPersonalDataPolicies: types.LOAD_PERSONAL_DATA_POLICIES,
      loadDataPolicy: types.LOAD_DATA_POLICY,
    }),
  },
  mounted() {
    this.loadDataPolicy();
    if (!this.getPersonalDataPolicies && this.includePersonalDataPolicies) {
      this.loadPersonalDataPolicies();
    }
    setTimeout(function () {
      document.querySelector('.privacy-policy-container .page-title').focus();
    }, 500);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.privacy-policy-container {
  a {
    text-decoration: underline;
  }
}
</style>
