<template>
  <div class="documents-revision-history-container">
    <router-view name="sidebar" @hidden="navigateToRevisionHistory" />
    <document-secure-toolbar />
    <div class="aula-document-history-container page-layout-padding">
      <h1 class="document-title">
        <b-btn variant="link" class="file-document" :to="{ name: 'documents' }">
          {{ 'DOCUMENTS_TYPES_PAGE_TITLE' | fromTextKey }}
        </b-btn>
        <span class="breadcumb-spacer">/</span>
        <b-btn variant="link" class="file-document" :to="{ name: 'documentsSecureList' }">
          {{ 'DOCUMENTS_TYPES_SECURE_FILES_TITLE' | fromTextKey }}
        </b-btn>
        <span class="breadcumb-spacer">/</span>
        <b>{{ documentTitle }}</b>
      </h1>
      <ObservingContainer :options="observerOptions">
        <div class="table">
          <div class="table-row header">
            <div class="table-cell text-left">
              {{ 'DOCUMENTS_HISTORY_USER' | fromTextKey }}
            </div>
            <div class="table-cell text-left">
              {{ 'DOCUMENTS_HISTORY_EDITION' | fromTextKey }}
            </div>
            <div class="table-cell text-left">
              {{ 'DOCUMENTS_HISTORY_CHANGE_TYPE' | fromTextKey }}
            </div>
            <div class="table-cell text-left">
              {{ 'DOCUMENTS_HISTORY_VERSION' | fromTextKey }}
            </div>
          </div>
          <div v-for="(item, index) in revisions" :key="index" class="table-row body">
            <div class="table-cell not-collapsed">
              {{ item.createdBy }}
            </div>
            <div class="table-cell date not-collapsed">
              <nobr>{{ item.createdAt | shortDate(true, true) }}</nobr>
            </div>
            <div class="table-cell not-collapsed">
              {{
                revisionChangeTextKeys[item.changeType]
                  | fromTextKey({
                    userName:
                      item.sharedWith.map(item => getNameAndMeta(item)).join(', ') ||
                      item.unsharedWith.map(item => getNameAndMeta(item)).join(', '),
                  })
              }}
            </div>
            <div class="table-cell not-collapsed">
              <div
                v-if="item.isAvailable"
                class="media-data-container"
                @click="onRevisionClick(item.id)"
                @keydown.enter="onRevisionClick(item.id)"
              >
                <div tabindex="0" class="album-media-data" :aria-label="documentTitle">
                  <div class="media-item">
                    <div class="media-thumbnail">
                      <i class="icon-Aula_note" />
                    </div>
                    <div class="media-name">
                      {{ item.title }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                {{ 'DOCUMENTS_HISTORY_NOT_AVAILABLE' | fromTextKey }}
              </div>
            </div>
            <div class="table-cell collapsed-only">
              <div>
                <strong>{{ 'DOCUMENTS_HISTORY_DATE' | fromTextKey }}:</strong>
                {{ item.createdAt | shortDate(true, true) }}
              </div>
              <div>
                <strong>{{ 'DOCUMENTS_HISTORY_USER' | fromTextKey }}:</strong>
                {{ item.createdBy }}
              </div>
              <div>
                <strong>{{ 'DOCUMENTS_HISTORY_CHANGE_TYPE' | fromTextKey }}:</strong>
                {{
                  revisionChangeTextKeys[item.changeType]
                    | fromTextKey({
                      userName:
                        item.sharedWith.map(item => getNameAndMeta(item)).join(', ') ||
                        item.unsharedWith.map(item => getNameAndMeta(item)).join(', '),
                    })
                }}
              </div>
              <div
                v-if="item.isAvailable"
                class="media-data-container"
                @click="onRevisionClick(item.id)"
                @keydown.enter="onRevisionClick(item.id)"
              >
                <div class="album-media-data">
                  <div class="media-item">
                    <div class="media-thumbnail">
                      <i class="icon-Aula_note" />
                    </div>
                    <div class="media-name">
                      {{ documentTitle }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <aula-spinner v-if="isLoading" />
        <ObservedTarget v-if="revisions.length > 0" @onIntersecting="onBottomReached" />
      </ObservingContainer>
    </div>
  </div>
</template>
<script>
import { types } from '../../store/types/types';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';
import { documentTypes } from '../../../shared/enums/documentTypes';
import DocumentSecureToolbar from './DocumentSecureToolbar.vue';
import Vue from 'vue';
import { revisionChangeTypeEnum } from '../../../shared/enums/RevisionChangeTypeEnum';
import ObservingContainer from '../../../shared/libs/intersection-observer/components/Container.vue';
import ObservedTarget from '../../../shared/libs/intersection-observer/components/Target.vue';

export default {
  data: function () {
    return {
      documentTitle: '',
      isLoading: true,
      documentTypes,
      paginationStart: 0,
      pageSize: 20,
      revisionChangeTextKeys: {
        [revisionChangeTypeEnum.SHARED]: 'DOCUMENTS_HISTORY_TYPE_SHARED',
        [revisionChangeTypeEnum.UNSHARED]: 'DOCUMENTS_HISTORY_TYPE_UNSHARED',
        [revisionChangeTypeEnum.IMPLICIT_UNSHARED]: 'DOCUMENTS_HISTORY_TYPE_IMPLICIT_UNSHARED',
        [revisionChangeTypeEnum.LOCKED]: 'DOCUMENTS_HISTORY_TYPE_LOCKED',
        [revisionChangeTypeEnum.UNLOCKED]: 'DOCUMENTS_HISTORY_TYPE_UNLOCKED',
        [revisionChangeTypeEnum.EDITED]: 'DOCUMENTS_HISTORY_TYPE_EDITED',
        [revisionChangeTypeEnum.EXPORTED]: 'DOCUMENTS_HISTORY_TYPE_EXPORTED',
        [revisionChangeTypeEnum.CREATED]: 'DOCUMENTS_HISTORY_TYPE_CREATED',
        [revisionChangeTypeEnum.EDIT_PERMISSION_ADDED]: 'DOCUMENTS_HISTORY_TYPE_PERMISSION_ADDED',
        [revisionChangeTypeEnum.EDIT_PERMISSION_REMOVED]: 'DOCUMENTS_HISTORY_TYPE_PERMISSION_REMOVED',
        [revisionChangeTypeEnum.RESTORED]: 'DOCUMENTS_HISTORY_TYPE_RESTORED',
        [revisionChangeTypeEnum.DELETED]: 'DOCUMENTS_HISTORY_TYPE_DELETED',
      },
    };
  },
  computed: {
    ...mapGetters({
      profile: types.GET_CURRENT_PROFILE,
      isMobile: types.GET_IS_MOBILE,
      liveDocument: types.GET_DOCUMENTS_LIVE,
      revisionData: types.GET_DOCUMENTS_REVISION,
    }),
    observerOptions() {
      return { rootMargin: '50%' };
    },
    revisions() {
      return this.revisionData?.documentRevisionDtos || [];
    },
    hasMoreRevisions() {
      if (!this.revisionData) {
        return false;
      }
      return this.revisionData.totalCount > (this.paginationStart + 1) * this.pageSize;
    },
  },
  methods: {
    onBottomReached() {
      if (this.isLoading || !this.hasMoreRevisions) {
        return;
      }
      this.getMoreRevisions();
    },
    getNameAndMeta(person) {
      return Vue.filter('displayProfileNameWithMetadata')(person);
    },
    async getRevisions() {
      const documentId = this.$route.params.documentId;
      const payload = {
        documentId,
        offset: this.pageSize * this.paginationStart,
        limit: this.pageSize,
      };
      this.isLoading = true;
      if (this.paginationStart === 0) {
        await this.loadRevisions(payload);
      } else {
        await this.loadMoreRevisions(payload);
      }
      this.isLoading = false;
    },
    onRevisionClick(revisionId) {
      this.$router.push({
        name: 'revisionHistoryDocument',
        params: { revisionId },
      });
    },
    navigateToRevisionHistory() {
      this.$router.push({ name: 'documentsRevisionHistory' });
    },
    getMoreRevisions() {
      this.paginationStart++;
      this.getRevisions();
    },
    ...mapActions({
      loadRevisions: types.ACTIONS_GET_DOCUMENT_REVISIONS,
      loadMoreRevisions: types.ACTIONS_GET_MORE_DOCUMENT_REVISIONS,
      loadInternalDocument: types.ACTION_LOAD_INTERNAL_DOCUMENT,
      loadExternalDocument: types.ACTION_LOAD_EXTERNAL_DOCUMENT,
    }),
  },
  created() {
    const id = this.$route.params.documentId;
    const documentType = this.$route.params.documentType;
    const params = { id };
    if (documentType === documentTypes.EXTERNAL) {
      this.loadExternalDocument(params).then(response => {
        this.documentTitle = response.title;
      });
    } else {
      this.loadInternalDocument(params).then(response => {
        this.documentTitle = response.title;
      });
    }
    this.getRevisions();
  },
  components: {
    ObservedTarget,
    ObservingContainer,
    'document-secure-toolbar': DocumentSecureToolbar,
  },
};
</script>
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/documents/_overview.scss';

.show-more {
  padding: 20px 0;
  text-align: center;
  button {
    float: none;
    margin-left: 0;
  }
}
.document-title {
  display: flex;
  flex-wrap: wrap;
  .file-document {
    width: auto;
    text-transform: unset;
    font-size: 18px;
    font-weight: 400;
    height: unset;
    margin-top: -5px;
    margin-left: 0;
    color: $color-primary-dark-guardian;
    .theme-employee & {
      color: $color-primary-dark-employee;
    }
  }
}
.aula-document-history-container {
  .media-data-container {
    cursor: pointer;
    @include breakpoint-xl-down() {
      margin-top: 5px;
    }
    .album-media-data {
      background-color: $color-grey-light;
      flex: unset;
    }
  }
  @include breakpoint-lg-down() {
    padding-bottom: 50px !important;
    padding: 15px;
  }
  @include breakpoint-sm-down() {
    padding: 10px;
  }
  h1 {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 40px;
  }
  .breadcrumbs {
    cursor: pointer;
  }
  .breadcumb-spacer {
    padding: 0 10px;
    display: inline-block;
  }
  .document-history-title-header {
    background-color: $color-grey-dark;
    padding: 0;
    margin: 0;
    h5 {
      margin-bottom: 0;
      line-height: 60px;
    }
    .icon-Aula_back-arrow {
      font-size: 1.5em;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .icon-header {
      color: $color-bg-black;
      cursor: pointer;
      @include breakpoint-lg() {
        display: inline-block;
        padding: 20px 0px 15px 0;
        margin-right: -17px;
      }
    }
  }
  .table {
    border-collapse: collapse;
    display: table;
    width: 100%;
    font-size: 14px;
    .table-row {
      background: #fff;
      display: table-row;
      .not-collapsed {
        display: none !important;

        @include breakpoint-xl() {
          display: table-cell !important;
        }
      }
      .collapsed-only {
        display: block !important;

        @include breakpoint-xl() {
          display: none !important;
        }
      }
      &.header {
        @include breakpoint-xl-down() {
          display: none;
        }
        background-color: $color-grey-dark;
        .table-cell {
          display: table-cell;
          padding: 15px;
          border-right: solid 2px transparent;
          border-top: solid 2px $color-grey-light;
          border-bottom: solid 2px $color-grey-light;
          text-transform: uppercase;
          text-align: center;
        }
      }
      &.body {
        .table-cell {
          border: solid 2px $color-grey-light;
          display: table-cell;
          padding: 15px;
          &.icon {
            background-color: $color-grey-base;
            vertical-align: middle;
            text-align: center;
            padding-top: 20px;
          }
          b {
            font-weight: 900;
          }
          .icon-Aula_elipses {
            display: block;
            margin-bottom: 15px;
            cursor: pointer;
          }
          .icon-Aula_write {
            display: block;
            cursor: pointer;
          }
        }
        &:hover {
          .table-cell {
            background: $color-grey-lighter;
            &.icon {
              background: $color-grey-dunkel;
              .icons {
                .user {
                  background: $color-grey-dunkel;
                  border: solid 3px $color-grey-dunkel;
                }
              }
            }
            b {
              font-weight: 900;
            }
          }
        }
      }
    }
  }
}

.aula-document-history-container {
  .table {
    .table-row {
      &.header {
        .table-cell {
          &.icon {
            span {
              .theme-guardian & {
                background-color: $color-primary-light-guardian;
              }
              .theme-employee & {
                background-color: $color-primary-light-employee;
              }
              .theme-child & {
                background-color: $color-primary-light-child;
              }
            }
          }
        }
      }
    }
  }
}
</style>
