<template>
  <div class="presence-time" :class="{ 'mt-2': !isMobile }">
    <multi-button-mobile
      v-if="pickupResponsibleChildren.length > 0 && hasCustodyOverActiveChildren"
      :items="multiButtonItems"
      @addPickupResponsible="addPickupResponsible"
    />
    <b-row v-if="isMobile && viewPickupProfile == null">
      <b-col cols="12">
        <div v-for="(child, index) in pickupResponsibleChildren" :key="index" class="box">
          <div
            class="box-title"
            tabindex="0"
            role="button"
            @click="viewPickupByChild(child)"
            @keydown.enter="viewPickupByChild(child)"
          >
            {{ child.name }}
            <template v-if="child.isMultipleInstitution"> ({{ child.institutionName }}) </template>
            <i class="icon icon-Aula_forward-arrow pull-right" />
          </div>
        </div>
      </b-col>
    </b-row>
    <template v-if="pickupResponsibleChildren.length > 0 && (!isMobile || (isMobile && viewPickupProfile != null))">
      <div class="presence" :class="isMobile && viewPickupProfile != null ? 'full-height' : ''">
        <b-btn
          v-if="!isMobile && hasCustodyOverActiveChildren"
          variant="primary"
          class="mt-3"
          @click="addPickupResponsible"
        >
          {{ 'PRESENCE_NEW_PICKUP_RESPONSIBLE' | fromTextKey }}
        </b-btn>
        <div v-if="isMobile && viewPickupProfile != null" class="mobile-header">
          <i
            class="icon-Aula_app_arrow_slideback time-back"
            tabindex="0"
            role="button"
            :aria-label="'ARIA_LABEL_BACK' | fromTextKey"
            @click="viewPickupProfile = null"
            @keydown.enter="viewPickupProfile = null"
          />
          {{ 'PRESENCE_PICKUP_RESPONSIBLE_TITLE_MOBILE' | fromTextKey }}
          <div class="profile-icon">
            <user-icon
              :id="viewPickupProfile.id"
              :name="viewPickupProfile.name"
              :short-name="viewPickupProfile.shortName"
              :picture-object="viewPickupProfile.profilePicture"
              :show-inline="true"
              user-icon-class="small"
            />
          </div>
        </div>
        <aula-spinner v-if="isLoading" />
        <b-row v-else>
          <b-col v-for="(child, index) in getPickupResponsibles" :key="index" cols="12" lg="6" class="mt-3 mb-4">
            <div class="box">
              <div v-if="!isMobile" class="box-title pickup" tabindex="0">
                <user-icon
                  :id="child.id"
                  :name="child.institutionProfile.name"
                  :short-name="child.institutionProfile.shortName"
                  :picture-object="child.institutionProfile.profilePicture"
                  :show-inline="true"
                  user-icon-class="small"
                />
                <span class="ml-2"
                  >{{ child.institutionProfile.name }}
                  <template v-if="child.institutionProfile.isMultipleInstitution"
                    >({{ child.institutionProfile.institutionName }})</template
                  ></span
                >
              </div>
              <div class="box-content">
                <template v-if="child.relatedPersons.length > 0">
                  <div class="pickup-responsile-title" tabindex="0" role="button">
                    {{ 'PRESENCE_PICKUP_RESPONSIBLE_PARENT' | fromTextKey }}
                  </div>
                  <template v-for="(relation, j) in child.relatedPersons">
                    <div v-if="child.relatedPersons.length > 0" :key="'relation' + j" class="pickup-responsile">
                      {{ relation.name }} ({{ relation.relation }})
                    </div>
                  </template>
                </template>
                <template v-if="child.pickupSuggestions.length > 0">
                  <div class="pickup-responsile-title" tabindex="0" role="button">
                    {{ 'PRESENCE_PICKUP_RESPONSIBLE_OTHERS' | fromTextKey }}
                  </div>
                  <div
                    v-for="(other, index) in child.pickupSuggestions"
                    :key="'others' + index"
                    class="pickup-responsile"
                  >
                    <b-row>
                      <b-col cols="10" class="truncate">
                        {{ other.pickupName }}
                      </b-col>
                      <b-col cols="2">
                        <b-btn
                          v-if="hasCustodyOrExtendedAccess(other.uniStudentId)"
                          variant="link"
                          :aria-label="'PRESENCE_REMOVE_PICKUP_RESPONSIBLE' | fromTextKey"
                          @click="remove(other)"
                        >
                          <i class="icon-Aula_bin" />
                        </b-btn>
                      </b-col>
                    </b-row>
                  </div>
                </template>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </template>
    <template v-if="pickupResponsibleChildren.length === 0">
      <div class="mt-3">
        {{ 'PRESENCE_WARNING_HAS_NOT_PRESENCE_CHILD' | fromTextKey }}
      </div>
    </template>
    <aula-modal
      ref="warningRemove"
      :ok-text="'BUTTON_YES'"
      @cancelClicked="$refs.warningRemove.hide()"
      @okClicked="removePickupResponsible"
    >
      {{ 'PRESENCE_WARNING_REMOVE_PICKUP_RESPONSIBLE' | fromTextKey }}
      <template v-if="removedPickupResponsible">
        {{ removedPickupResponsible.pickupName }}
      </template>
      {{ 'PRESENCE_WARNING_REMOVE_PICKUP_RESPONSIBLE_2' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="newPickupResponsible"
      :css-class="isMobile ? 'register-absence-modal modal-fullscreen' : 'register-absence-modal'"
      :header-text="'PRESENCE_NEW_PICKUP_RESPONSIBLE'"
      :hide-header="isMobile"
      :disable-close="isMobile"
      :hide-footer="true"
      :ok-text="'BUTTON_CLOSE'"
    >
      <div class="register-absence pick-up-responsible">
        <div class="register-absence-form">
          <b-row v-if="isMobile">
            <b-col cols="2">
              <b-btn
                variant="link"
                :aria-label="'ARIA_LABEL_BACK' | fromTextKey"
                @click="cancelCreatePickupResponsible"
              >
                <i class="icon-Aula_app_arrow_slideback" />
              </b-btn>
            </b-col>
            <b-col class="title">
              {{ 'PRESENCE_NEW_PICKUP_RESPONSIBLE' | fromTextKey }}
            </b-col>
            <b-col cols="2">
              <b-btn variant="link" class="save-button" :disabled="isAdding" @click="savePickupResponsible">
                {{ 'BUTTON_SAVE' | fromTextKey }}
                <aula-spinner v-if="isAdding" class="mobile-aula-spinner" />
              </b-btn>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="mobile">
              <label>{{ 'PRESENCE_PICKUP_CHILDREN' | fromTextKey }} <span class="mandatory">*</span></label>
              <b-form-checkbox-group
                id="children"
                v-model="uniStudentIdsWithCustody"
                name="children"
                @input="warningChildren = uniStudentIdsWithCustody.length === 0"
              >
                <template v-for="(child, index) in pickupResponsibleChildren">
                  <b-form-checkbox
                    v-if="hasCustodyOrExtendedAccess(child.id)"
                    :key="index"
                    class="choose-children"
                    :value="child.id"
                  >
                    {{ child.name }}
                    <template v-if="child.isMultipleInstitution"> ({{ child.institutionName }}) </template>
                  </b-form-checkbox>
                </template>
              </b-form-checkbox-group>
              <b-alert variant="danger" :show="warningChildren">
                {{ 'PRESENCE_WARNING_CHOOSE_CHILD' | fromTextKey }}
              </b-alert>
            </b-col>
            <b-col cols="12" class="mobile">
              <label>{{ 'PRESENCE_PICKUP_RESPONSIBLE_NAME' | fromTextKey }} <span class="mandatory">*</span></label>
              <b-input
                v-model="pickupName"
                maxlength="100"
                class="absence-input"
                :class="{
                  'red-border': warningPickupName,
                  'red-border': warningPickupNameTooShort,
                }"
                :placeholder="'PRESENCE_PICKUP_RESPONSIBLE_NAME' | fromTextKey"
                @input="
                  warningPickupName = !pickupName;
                  warningPickupNameTooShort = false;
                "
              />
              <b-alert variant="danger" :show="warningPickupName">
                {{ 'PRESENCE_WARNING_PICKUP_NAME' | fromTextKey }}
              </b-alert>
              <b-alert variant="danger" :show="warningPickupNameTooShort">
                {{ 'PRESENCE_WARNING_PICKUP_NAME_TOO_SHORT' | fromTextKey }}
              </b-alert>
            </b-col>
            <b-col v-if="!isMobile" cols="12" class="text-right mt-5">
              <b-button variant="link" class="mr-3" @click="cancelCreatePickupResponsible">
                {{ 'CANCEL' | fromTextKey }}
              </b-button>
              <b-button variant="primary" class="send-btn" :disabled="isAdding" @click="savePickupResponsible">
                {{ 'BUTTON_SAVE' | fromTextKey }}
                <aula-spinner v-if="isAdding" class="submit-loading" />
              </b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </aula-modal>
  </div>
</template>
<script>
import { types } from '../../store/types/types';
import { mapActions, mapGetters } from 'vuex';
import MultiButtonMobile from '../shared/MultiButtonMobile.vue';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { pickupTypes } from '../../../shared/enums/pickupTypes';
export default {
  data: function () {
    return {
      portalRoles: portalRoles,
      pickupName: null,
      warningPickupName: false,
      warningPickupNameTooShort: false,
      isLoading: false,
      isAdding: false,
      uniStudentIds: [],
      uniStudentIdsWithCustody: [],
      warningChildren: false,
      viewPickupProfile: null,
      removedPickupResponsible: null,
    };
  },
  computed: {
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
      pickupResponsibles: types.GET_PICKUP_RESPONSIBLES,
      activeChildren: types.GET_ACTIVE_CHILDREN,
      children: types.GET_CHILDREN,
      getPresenceActiveModules: types.GET_PRESENCE_ACTIVE_MODULES,
      hasCustodyOrExtendedAccess: types.GET_HAS_CUSTODY_OR_EXTENDED_ACCESS,
    }),
    getPickupResponsibles() {
      let pickupResponsibles = [];
      if (this.isMobile && this.viewPickupProfile != null) {
        pickupResponsibles = this.pickupResponsibles.filter(child => child.uniStudentId == this.viewPickupProfile.id);
      } else {
        pickupResponsibles = this.pickupResponsibles.filter(
          child =>
            this.getPresenceActiveModules[pickupTypes.PICKUP.key] != null &&
            this.getPresenceActiveModules[pickupTypes.PICKUP.key].includes(child.uniStudentId)
        );
      }
      for (const child of pickupResponsibles) {
        const index = this.children.findIndex(c => c.id == child.uniStudentId);
        child.institutionProfile = this.children[index];
      }
      return pickupResponsibles;
    },
    pickupResponsibleChildren() {
      let childen = [];
      if (
        this.getPresenceActiveModules[pickupTypes.PICKUP.key] != null &&
        this.getPresenceActiveModules[pickupTypes.PICKUP.key].length > 0
      ) {
        childen = this.children.filter(child =>
          this.getPresenceActiveModules[pickupTypes.PICKUP.key].includes(child.id)
        );
      }
      return childen;
    },
    multiButtonItems() {
      const items = [];
      items.push({
        icon: 'icon-Aula_write',
        text: 'MESSAGE_TOOLBAR_CREATE_NEW_MESSAGE',
        action: 'addPickupResponsible',
      });
      return items;
    },
    hasCustodyOverActiveChildren() {
      return this.activeChildren.filter(childId => this.hasCustodyOrExtendedAccess(childId)).length > 0;
    },
  },
  methods: {
    ...mapActions({
      loadPickupResponsibles: types.LOAD_PICKUP_RESPONSIBLES,
      deletePickupResponsibles: types.DELETE_PICKUP_RESPONSIBLES,
      savePickupResponsibles: types.SAVE_PICKUP_RESPONSIBLES,
    }),
    viewPickupByChild(child) {
      this.viewPickupProfile = child;
    },
    addPickupResponsible() {
      this.resetForm();
      this.$refs.newPickupResponsible.show();
    },
    resetForm() {
      this.warningPickupName = false;
      this.warningPickupNameTooShort = false;
      this.warningChildren = false;
      this.pickupName = null;
      this.uniStudentIds = this.pickupResponsibleChildren.map(child => child.id);
    },
    cancelCreatePickupResponsible() {
      this.$refs.newPickupResponsible.hide();
    },
    remove(pickup) {
      this.removedPickupResponsible = pickup;
      this.$refs.warningRemove.show();
    },
    removePickupResponsible() {
      this.deletePickupResponsibles({
        pickupId: this.removedPickupResponsible.id,
        uniStudentId: this.removedPickupResponsible.uniStudentId,
      }).then(() => {
        this.removedPickupResponsible = null;
        this.$refs.warningRemove.hide();
      });
    },
    savePickupResponsible() {
      let isSave = true;
      if (this.uniStudentIdsWithCustody.length === 0) {
        this.warningChildren = true;
        isSave = false;
      } else {
        this.warningChildren = false;
      }
      if (!this.pickupName) {
        this.warningPickupName = true;
        isSave = false;
      } else {
        this.warningPickupName = false;
        if (this.pickupName.trim().length < 2) {
          this.warningPickupNameTooShort = true;
          isSave = false;
        } else {
          this.warningPickupNameTooShort = false;
        }
      }

      if (isSave) {
        this.isAdding = true;
        this.savePickupResponsibles({
          uniStudentIds: this.uniStudentIdsWithCustody,
          pickupName: this.pickupName,
        }).then(() => {
          this.$refs.newPickupResponsible.hide();
          this.isAdding = false;
          this.viewPickupProfile = null;
          this.init();
        });
      }
    },
    init() {
      this.isLoading = true;
      this.warningPickupName = false;
      this.warningPickupNameTooShort = false;
      this.warningChildren = false;
      this.uniStudentIds = this.pickupResponsibleChildren.map(child => child.id);
      this.uniStudentIdsWithCustody = this.pickupResponsibleChildren
        .filter(child => this.hasCustodyOrExtendedAccess(child.id))
        .map(child => child.id);
      this.pickupName = null;
      this.loadPickupResponsibles({ uniStudentIds: this.uniStudentIds }).then(() => {
        this.isLoading = false;
      });
    },
  },
  mounted() {
    this.init();
  },
  components: {
    MultiButtonMobile,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/presence/_presence.scss';
.mobile-aula-spinner {
  position: absolute;
  width: 60px;
  height: 60px;
  top: -15px !important;
  left: 5px;
}
.submit-loading {
  position: absolute;
  width: 80px;
  height: 80px;
}
.choose-children {
  display: block;
}
</style>
