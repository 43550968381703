<template>
  <Sorting :direction="sortDirection" @sort="onSort">
    <slot />
  </Sorting>
</template>
<script>
import Sorting from '../../components/Sorting';
import { contactSortingTypes } from '../../enums/contactSortingTypes';

export default {
  name: 'ContactsFragmentSorting',
  components: {
    Sorting,
  },
  props: {
    direction: {
      type: String,
      required: false,
      default: () => null,
    },
    sortingBy: {
      type: String,
      validator(sortBy) {
        return Array.from(Object.values(contactSortingTypes)).includes(sortBy);
      },
    },
    sortBy: {
      type: String,
      validator(sortBy) {
        return Array.from(Object.values(contactSortingTypes)).includes(sortBy);
      },
    },
  },
  computed: {
    sortDirection() {
      let direction = null;
      if (this.sortBy === this.sortingBy) {
        direction = this.direction;
      }
      return direction;
    },
  },
  methods: {
    onSort() {
      this.$emit('sort', this.sortBy);
    },
  },
};
</script>
