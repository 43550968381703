<template>
  <b-dropdown class="dropdown-select right">
    <template #button-content>
      {{ 'SEARCH_FILTER_LABEL_MOBILE' | fromTextKey }}
      <i class="icon-arrow icon-Aula_down-arrow" />
      <i class="icon-arrow icon-Aula_up-arrow" />
    </template>
    <b-dropdown-item
      :active="showSubresults.includes(groupTypes.INSTITUTIONAL)"
      @click="changeSubresults(groupTypes.INSTITUTIONAL)"
    >
      {{ 'INSTITUTIONAL_GROUP' | fromTextKey }}
    </b-dropdown-item>
    <b-dropdown-item
      :active="showSubresults.includes(groupTypes.MUNICIPAL)"
      @click="changeSubresults(groupTypes.MUNICIPAL)"
    >
      {{ 'MUNICIPAL_GROUP' | fromTextKey }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';
import { types } from '../../store/types/types';
import { groupTypes } from '../../../shared/enums/groupTypes';
import { docTypes } from '../../../shared/enums/docTypes';

export default {
  props: {
    parent: { type: String, default: 'profile' },
  },
  data: function () {
    return {
      groupTypes: groupTypes,
      showSubresults: [groupTypes.INSTITUTIONAL, groupTypes.MUNICIPAL],
    };
  },
  computed: {
    ...mapGetters({
      numberOfResultsGroup: types.GET_NUMBER_OF_RESULTS_GROUP,
      numberOfResults: types.GET_NUMBER_OF_RESULTS,
    }),
  },
  methods: {
    ...mapActions({
      setShowSubresultsGroup: types.SET_SHOW_SUBRESULTS_GROUP,
      setShowSubresults: types.SET_SHOW_SUBRESULTS,
    }),
    changeSubresults(groupType) {
      const index = this.showSubresults.indexOf(groupType);
      if (index > -1) {
        this.showSubresults.splice(index, 1);
      } else {
        this.showSubresults.push(groupType);
      }
      this.setShowSubresults({
        groupTypes: this.showSubresults,
        docType: docTypes.GROUP,
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
