<template>
  <b-card
    class="search-result-item margin-bottom-20"
    tabindex="0"
    @keypress.enter="result.currentUserCanAccessGroupDashboard ? openGroup(result.id) : ''"
    @click="result.currentUserCanAccessGroupDashboard ? openGroup(result.id) : ''"
  >
    <div class="search-result-icon-col">
      <template v-if="isChild">
        <img class="menu-icon" :src="'static/icons/icon-Aula_group_child.svg'" />
      </template>
      <i v-else class="icon-Aula_group" style="font-size: 0.9em" />
    </div>
    <div class="search-result-text-col">
      <div class="card-header-text result-group-header">
        <keyword-highlight :queries="searchString">
          {{ result.name }}
        </keyword-highlight>
      </div>
      <div class="card-text result-group-description">
        <keyword-highlight :queries="searchString">
          {{ result.description }}
        </keyword-highlight>
      </div>
    </div>
    <div class="search-result-right-col-text">
      <b-button
        v-if="canJoinGroup"
        class="search-result-group-button"
        variant="primary"
        :disabled="isGroupButtonDisabled"
        @click.stop="onGroupActionClicked(groupMembershipRoles.MEMBER)"
      >
        {{ 'SEARCH_RESULT_JOIN_GROUP' | fromTextKey }}
      </b-button>
      <b-button
        v-if="canLeaveGroup"
        class="search-result-group-button"
        variant="primary"
        :disabled="isGroupButtonDisabled"
        @click.stop="onGroupActionClicked(groupMembershipRoles.INACTIVE)"
      >
        {{ 'SEARCH_RESULT_LEAVE_GROUP' | fromTextKey }}
      </b-button>

      <b-button
        v-if="canApplyForGroup"
        class="search-result-group-button"
        variant="primary"
        :disabled="isGroupButtonDisabled"
        @click.stop="onGroupActionClicked(groupMembershipRoles.APPLIED)"
      >
        {{ 'SEARCH_RESULT_APPLY_GROUP' | fromTextKey }}
      </b-button>

      <b-button
        v-if="canCancelApplyForGroup"
        class="search-result-group-button"
        variant="primary"
        :disabled="isGroupButtonDisabled"
        @click.stop="onGroupActionClicked(groupMembershipRoles.APPLICATIONREMOVED)"
      >
        {{ 'SEARCH_RESULT_CANCEL_GROUP_APPLICATION' | fromTextKey }}
      </b-button>
    </div>
    <aula-modal
      ref="institutionModal"
      :header-text="
        result.access === groupAccessTypes.APPLICATION ? 'SEARCH_RESULT_APPLY_GROUP' : 'SEARCH_RESULT_JOIN_GROUP'
      "
      :ok-text="result.access === groupAccessTypes.APPLICATION ? 'REPLY_APPLY_GROUP' : 'ALERT_REPLY'"
      @cancelClicked="$refs.institutionModal.hide()"
      @okClicked="onInstitutionModalOkClicked"
    >
      <label>{{
        result.access === groupAccessTypes.APPLICATION
          ? 'SELECT_INSTITUTION_DESCRIPTION_APPLY_GROUP'
          : 'SELECT_INSTITUTION_DESCRIPTION' | fromTextKey
      }}</label>
      <aula-select
        v-model="selectedInstitutionProfileId"
        :placeholder="'SELECT' | fromTextKey"
        :aria-label="'ARIA_LABEL_POST_CHOOSE_INSTITUTION' | fromTextKey"
      >
        <aula-option
          v-for="(institution, index) in applicableInstitutions"
          :id="institution.institutionCode"
          :key="index"
          :label="institution.name"
          :value="institution.institutionProfileId"
        />
      </aula-select>
    </aula-modal>
    <toastr ref="joinedGroup" variant="success" icon="icon-Aula_check">
      {{ 'SEARCH_RESULT_TOASTR_JOINED_GROUP' | fromTextKey }}
      {{ joiningGroupName }}
    </toastr>
    <toastr ref="appliedGroup" variant="success" icon="icon-Aula_check">
      {{ 'SEARCH_RESULT_TOASTR_APPLIED_GROUP' | fromTextKey }}
      {{ joiningGroupName }}
    </toastr>
  </b-card>
</template>

<script>
import KeywordHighlight from 'vue-text-highlight';
import { groupAccessTypes } from '../../../shared/enums/groupAccessTypes';
import { groupMembershipRoles } from '../../../shared/enums/groupMembershipRoles';
import { groupMembershipActions } from '../../../shared/enums/groupMembershipActions';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { types } from '../../store/types/types';
import { groupTypes } from '../../../shared/enums/groupTypes';

export default {
  props: {
    result: { type: Object, default: () => {} },
    parent: { type: String, default: 'profile' },
    searchString: { type: String, default: '' },
    isChild: { default: false },
  },
  data: function () {
    return {
      groupAccessTypes: groupAccessTypes,
      groupMembershipRoles: groupMembershipRoles,
      groupTypes: groupTypes,
      joiningGroupName: '',
      isGroupButtonDisabled: false,
      selectedInstitutionProfileId: [],
      selectedGroupRole: null,
    };
  },
  computed: {
    ...mapGetters({
      institutions: types.GET_INSTITUTIONS,
      profile: types.GET_CURRENT_PROFILE,
    }),
    applicableInstitutions() {
      if (this.result.type === groupTypes.MUNICIPAL) {
        return this.institutions.filter(inst => this.result.municipalityCode === inst.municipalityCode);
      }
      if (this.result.type === groupTypes.CROSS_INSTITUTIONAL) {
        return this.institutions.filter(inst =>
          inst.administrativeAuthority?.institutionCodes.includes(this.result.institutionCode)
        );
      }
      return this.institutions.filter(inst => this.result.institutionCode === inst.institutionCode);
    },
    canJoinGroup() {
      return (
        this.result.access === groupAccessTypes.OPEN &&
        (!this.result.membershipRole || this.result.membershipRole == groupMembershipRoles.INACTIVE)
      );
    },
    canApplyForGroup() {
      return (
        this.result.access === groupAccessTypes.APPLICATION &&
        (!this.result.membershipRole ||
          this.result.membershipRole == groupMembershipRoles.INACTIVE ||
          this.result.membershipRole == groupMembershipRoles.APPLICATIONREMOVED)
      );
    },
    canLeaveGroup() {
      return (
        (this.result.access === groupAccessTypes.OPEN || this.result.access === groupAccessTypes.APPLICATION) &&
        this.result.membershipRole === groupMembershipRoles.MEMBER &&
        this.result.type !== groupTypes.UNI &&
        (this.result.admins == null ||
          (this.result.admins != null &&
            this.result.admins.filter(
              adm => this.institutions.find(i => i.institutionProfileId == adm.institutionProfileId) != null
            ).length === 0))
      );
    },
    canCancelApplyForGroup() {
      return (
        this.result.access === groupAccessTypes.APPLICATION &&
        this.result.membershipRole == groupMembershipRoles.APPLIED
      );
    },
  },
  methods: {
    ...mapActions({
      joinOrLeaveGroup: types.SET_JOIN_OR_LEAVE_GROUP,
      removeGroupFromUserGroups: types.REMOVE_GROUP_FROM_USER_GROUPS,
      setActiveGroup: types.SET_ACTIVE_GROUP,
    }),
    ...mapMutations({
      addGroup: types.MUTATE_ADD_GROUP,
    }),
    onGroupActionClicked(groupRole) {
      this.selectedGroupRole = groupRole;
      const action =
        groupRole == groupMembershipRoles.INACTIVE || groupRole == groupMembershipRoles.APPLICATIONREMOVED
          ? groupMembershipActions.LEAVE
          : groupMembershipActions.JOIN;
      if (action === groupMembershipActions.JOIN) {
        this.selectedInstitutionProfileId = this.applicableInstitutions[0].institutionProfileId;
      }
      if (action === groupMembershipActions.JOIN && this.applicableInstitutions.length > 1) {
        this.$refs.institutionModal.show();
      } else {
        this.joinOrLeaveGroupAction(groupRole, action);
      }
    },
    onInstitutionModalOkClicked() {
      this.joinOrLeaveGroupAction(this.selectedGroupRole, groupMembershipActions.JOIN);
      this.$refs.institutionModal.hide();
    },
    joinOrLeaveGroupAction(groupRole, action) {
      this.isGroupButtonDisabled = true;
      const institutionProfileIds =
        action === groupMembershipActions.LEAVE
          ? this.applicableInstitutions.map(inst => inst.institutionProfileId)
          : [this.selectedInstitutionProfileId];
      this.joinOrLeaveGroup({
        groupId: this.result.id,
        institutionProfileIds,
        groupAccessType: this.result.access,
        action: action,
        groupRole: groupRole,
      }).then(() => {
        this.joiningGroupName = this.result.name;
        if (groupRole === groupMembershipRoles.MEMBER) {
          this.$refs.joinedGroup.show();
          this.addGroup(this.result);
        } else if (groupRole === groupMembershipRoles.APPLIED) {
          this.$refs.appliedGroup.show();
        } else if (groupRole === groupMembershipRoles.INACTIVE) {
          this.removeGroupFromUserGroups(this.result.id);
        }
        this.isGroupButtonDisabled = false;
      });
    },
    openGroup(groupId) {
      this.setActiveGroup({
        groupId: groupId,
        institutionCode: this.profile.institutionCode,
        isOpennedGroupFromGlobalSearch: true,
      }).then(() => {
        this.$router.push({
          name: 'group-dashboard',
          params: { groupId: groupId },
        });
      });
    },
  },
  components: {
    KeywordHighlight,
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/_breakpoints.scss';

.search-result-item .card-body {
  @include breakpoint-sm-down() {
    --card-body-flex-direction: column;
  }
}
</style>
