<template>
  <div
    v-if="
      getPresenceActiveModules[presenceModules.REPORT_SICK] &&
      getPresenceActiveModules[presenceModules.REPORT_SICK].length > 0
    "
    class="sick-report-container"
  >
    <h2 v-if="!isMobile">{{ 'PRESENCE_REPORT_SICK_SHORT' | fromTextKey }}</h2>
    <div v-else class="mobile-box-title">
      {{ 'PRESENCE_REPORT_SICK_SHORT' | fromTextKey }}
      <help-box
        id="sickness-report-help-box"
        :title="$options.filters.fromTextKey('PRESENCE_REPORT_SICK_SHORT')"
        :body="$options.filters.fromTextKey('PRESENCE_ABSENCE_DESCRIPTION')"
      />
    </div>
    <div v-if="!isMobile" class="mb-3">
      {{ 'PRESENCE_ABSENCE_DESCRIPTION' | fromTextKey }}
    </div>
    <div class="mb-3">
      <b-alert variant="danger" show>
        {{ 'PRESENCE_ABSENCE_DESCRIPTION_WARNING' | fromTextKey }}
      </b-alert>
    </div>
    <b-row>
      <b-col v-if="reportedChildren.length > 0" cols="12">
        <div class="box">
          <div
            v-if="!isMobile"
            class="box-title"
            role="button"
            :class="{
              'w-50': reportedChildren.length === 1 && !isLoadingPresenceState,
            }"
          >
            {{ 'PRESENCE_REPORT_SICK' | fromTextKey }}
          </div>
          <div v-if="isLoadingPresenceState" class="spinner-container">
            <aula-spinner />
          </div>
          <div v-else class="box-content child-sick-grid-container">
            <div v-for="(child, childIndex) in reportedChildren" :key="childIndex" class="child-sick">
              <b-row>
                <b-col cols="9">
                  <div class="child-info">
                    <user-icon
                      :id="child.id"
                      :name="child.name"
                      :short-name="child.shortName"
                      :picture-object="child.profilePicture"
                      user-icon-class="small"
                      :show-inline="true"
                    />
                    <div class="child-name">
                      {{ child.name }}
                      <span class="font-weight-normal">({{ child.metadata }})</span>
                    </div>
                  </div>
                </b-col>
                <b-col v-if="childrenWithSickStatus" cols="3">
                  <el-switch
                    v-if="isMobile"
                    v-model="childrenWithSickStatus[childIndex].sickStatus"
                    class="mobile-switch"
                    :width="48"
                    :disabled="!hasCustodyOrExtendedAccess(child.id)"
                    @change="updateChildSick(childIndex)"
                  />
                  <b-form-checkbox
                    v-else
                    v-model="childrenWithSickStatus[childIndex].sickStatus"
                    class="mr-28"
                    :disabled="!hasCustodyOrExtendedAccess(child.id)"
                    @change="updateChildSick(childIndex)"
                  >
                    {{ 'PRESENCE_SICK' | fromTextKey }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { presenceModules } from '../../../shared/enums/presenceModules';
import { presenceStates } from '../../../shared/enums/presenceStates';
import { mapActions, mapGetters } from 'vuex';
import { types } from '../../store/types/types';

export default {
  props: {
    isLoadingPresenceState: { type: Boolean, default: true },
  },
  data: function () {
    return {
      presenceModules,
      childrenWithSickStatus: null,
    };
  },
  computed: {
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
      getPresenceStates: types.GET_PRESENCE_STATES_GUARDIAN,
      getPresenceActiveModules: types.GET_PRESENCE_ACTIVE_MODULES,
      hasCustodyOrExtendedAccess: types.GET_HAS_CUSTODY_OR_EXTENDED_ACCESS,
    }),
    reportedPresenceData() {
      let reportedPresenceData = [];
      if (
        this.getPresenceActiveModules[presenceModules.REPORT_SICK] &&
        this.getPresenceActiveModules[presenceModules.REPORT_SICK].length > 0
      ) {
        reportedPresenceData = this.getPresenceStates.filter(item =>
          this.getPresenceActiveModules[presenceModules.REPORT_SICK].includes(item.uniStudent.id)
        );
      }
      return reportedPresenceData;
    },
    reportedChildren() {
      return this.reportedPresenceData.map(item => item.uniStudent);
    },
  },
  methods: {
    ...mapActions({
      updateSick: types.UPDATE_SICK,
      loadPresenceStates: types.LOAD_PRESENCE_STATES,
      loadPresenceConfigurationByChildIds: types.LOAD_PRESENCE_CONFIGURATIONS_CHILD_IDS,
    }),
    getChildrenWithSickStatus() {
      this.childrenWithSickStatus = this.reportedPresenceData.map(item => ({
        id: item.uniStudent.id,
        sickStatus: item.state === presenceStates.SICK,
      }));
    },
    updateChildSick(childIndex) {
      const child = this.childrenWithSickStatus[childIndex];
      const sickStatus = child.sickStatus;
      const status = sickStatus ? presenceStates.SICK : presenceStates.NOT_PRESENT;
      this.updateSick({
        institutionProfileIds: [child.id],
        status,
      });
    },
  },
  watch: {
    isLoadingPresenceState(value) {
      if (!value) {
        this.getChildrenWithSickStatus();
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/presence/_presence.scss';

.alert.alert-danger {
  font-weight: 900;
  color: $color-primary-darker-guardian;
  &::before {
    color: $color-alert;
    background-color: $color-white;
  }
}
</style>
