<template>
  <drawer :show="isEditingChildStatus" focus-target="button" @change-show="changeChildStatusDrawerShow">
    <template slot="drawer-title">
      <div class="title">
        <i class="icon-Aula_export hide-tablet-down mr-3" aria-hidden="true" style="font-size: 1.3em" />
        {{ 'PRESENCE_DASHBOARD_UPDATE_STATUS' | fromTextKey }}
      </div>
    </template>
    <template slot="drawer-content">
      <div class="children-registration">
        <aula-spinner v-if="isLoading" />
        <div v-if="!isLoading">
          <b-row class="line">
            <b-col>
              <selected-children
                :children="selectedRegistrations.map(r => r.uniStudent)"
                :status="childrenStatus"
                :is-employee-dashboard="true"
                @updateChildStatus="checkCompatibleStatusForUpdatingStatus"
                @removeChild="removeChild"
              />
            </b-col>
          </b-row>
          <b-row
            v-if="
              presenceConfiguration[presenceModules.LOCATION] != null &&
              presenceConfiguration[presenceModules.LOCATION].readable &&
              locations.length > 0
            "
            class="line"
          >
            <b-col>
              <physical-location-form
                :locations="locations"
                :active-location-id="childrenLocationId"
                :configuration="presenceConfiguration"
                @updateChildLocation="checkCompatibleStatusForUpdatingLocation"
              />
            </b-col>
          </b-row>
          <b-row
            v-if="
              presenceConfiguration[presenceModules.SLEEP] != null &&
              presenceConfiguration[presenceModules.SLEEP].readable
            "
            class="line"
          >
            <b-col>
              <SleepTimeList
                :configuration="presenceConfiguration"
                :is-employee-dashboard="true"
                :can-edit="selectedRegistrations.length === 1"
                :sleep-intervals="sleepIntervals"
                @saveSleepInterval="handleSavingSleepInterval"
                @editSleepInterval="editSleepInterval"
                @removeSleepInterval="removeSleepInterval"
              />
            </b-col>
          </b-row>
          <b-row
            v-if="
              (presenceConfiguration[presenceModules.FIELD_TRIP] != null &&
                presenceConfiguration[presenceModules.FIELD_TRIP].readable) ||
              (presenceConfiguration[presenceModules.SPARE_TIME_ACTIVITY] != null &&
                presenceConfiguration[presenceModules.SPARE_TIME_ACTIVITY].readable)
            "
            class="line"
          >
            <b-col>
              <activity-form
                :status="childrenStatus"
                :comment="
                  childrenPresenceRegistrations.length === 1 &&
                  childrenPresenceRegistrations[0].spareTimeActivity != null
                    ? childrenPresenceRegistrations[0].spareTimeActivity.comment
                    : null
                "
                :configuration="presenceConfiguration"
                @updateChildStatus="checkCompatibleStatusForUpdatingStatus"
              />
            </b-col>
          </b-row>
          <b-row
            v-if="
              (presenceConfiguration[presenceModules.VACATION] != null &&
                presenceConfiguration[presenceModules.VACATION].readable) ||
              (presenceConfiguration[presenceModules.REPORT_SICK] != null &&
                presenceConfiguration[presenceModules.REPORT_SICK].readable)
            "
            class="line"
          >
            <b-col cols="12">
              <absence-form
                :status="childrenStatus"
                :configuration="presenceConfiguration"
                :child-ids="selectedRegistrations.map(r => r.uniStudent.id)"
                @updateChildStatus="checkCompatibleStatusForUpdatingStatus"
                @saveVacation="closeForm"
              />
            </b-col>
          </b-row>
          <b-row
            v-if="
              selectedRegistrations.length === 1 &&
              (presenceConfiguration.pickup ||
                presenceConfiguration.selfDecider ||
                presenceConfiguration.sendHome ||
                presenceConfiguration.goHomeWith)
            "
          >
            <b-col
              v-if="presenceConfiguration[presenceModules.PICKUP_TYPE].readable"
              cols="12"
              md="6"
              :class="{ 'mt-3': isMobile }"
            >
              <label>{{ 'PRESENCE_SELECT_EXIT_TYPE' | fromTextKey }}</label>
              <aula-select
                id="pick-up-type"
                v-model="exitType"
                class="align-left"
                popper-class="align-left"
                :aria-label="'ARIA_LABEL_CHOOSE_PICKUP_TYPE' | fromTextKey"
                :disabled="!presenceConfiguration[presenceModules.PICKUP_TYPE].editable"
                @change="changeExitType"
              >
                <aula-option
                  v-for="(module, index) of activeModules"
                  :key="index"
                  :label="module.label"
                  :value="module.value"
                  :disabled="module.value == disabledExitType"
                />
              </aula-select>
              <b-alert variant="danger" :show="isAlertInvalidConfigurationVisible">
                {{ 'PRESENCE_WARNING_INVALID_CONFIGURATION' | fromTextKey }}
              </b-alert>
            </b-col>
            <b-col
              v-if="
                (exitType === exitTypes.PICKED_UP_BY || exitType === exitTypes.GO_HOME_WITH) &&
                presenceConfiguration[presenceModules.PICKUP_TYPE].readable
              "
              cols="12"
              md="6"
              :class="{ 'mt-3': isMobile }"
            >
              <label>{{ 'PRESENCE_SELECT_GO_HOME_WITH' | fromTextKey }}</label>
              <span v-if="exitType === exitTypes.GO_HOME_WITH" class="mandatory">*</span>
              <aula-select
                v-if="exitType === exitTypes.PICKED_UP_BY && pickupResponsibles[0]"
                id="exit-with"
                v-model="exitWith"
                :filterable="true"
                :aria-label="'ARIA_LABEL_CHOOSE_EXIT_WITH' | fromTextKey"
                :disabled="!presenceConfiguration[presenceModules.PICKUP_TYPE].editable"
                :placeholder="'PRESENCE_PLACEHOLDER' | fromTextKey"
              >
                <aula-option
                  v-for="(option, i) in pickupResponsibles[0].relatedPersons"
                  :key="'rp' + i"
                  :label="option.name + ' (' + option.relation + ')'"
                  :value="option.name + ' (' + option.relation + ')'"
                />
                <aula-option
                  v-for="(option, i) in pickupResponsibles[0].pickupSuggestions"
                  :key="'pr' + i"
                  :label="option.pickupName"
                  :value="option.pickupName"
                />
              </aula-select>
              <aula-select
                v-if="exitType === exitTypes.GO_HOME_WITH"
                id="go-home-with"
                v-model="exitWith"
                :aria-label="'ARIA_LABEL_CHOOSE_EXIT_WITH' | fromTextKey"
                :filterable="true"
                :disabled="!presenceConfiguration[presenceModules.PICKUP_TYPE].editable"
                :placeholder="'PRESENCE_PLACEHOLDER' | fromTextKey"
              >
                <aula-option
                  v-for="(option, i) in goHomeWithList"
                  :key="'hw' + i"
                  :label="option.fullName + ' (' + option.mainGroup + ')'"
                  :value="option.fullName + ' (' + option.mainGroup + ')'"
                />
              </aula-select>
              <b-alert variant="danger" :show="showAlertNoExitWith">
                {{ 'PRESENCE_NO_EXIT_WITH_WARNING' | fromTextKey }}
              </b-alert>
            </b-col>
            <template
              v-if="exitType === exitTypes.SELF_DECIDER && presenceConfiguration[presenceModules.PICKUP_TIMES].readable"
            >
              <b-col cols="12" md="3" :class="{ 'mt-3': isMobile }">
                <label>{{ 'PRESENCE_SELF_DECIDER_START_TIME' | fromTextKey }}</label>
                <span class="mandatory"> * </span>
                <timepicker
                  id="check-in-time"
                  v-model="selfDeciderStartTime"
                  format="HH:mm"
                  hour-label="Time"
                  minute-label="Minut"
                  :placeholder="'PRESENCE_PLACEHOLDER' | fromTextKey"
                  :aria-label="'ARIA_LABEL_PRESENCE_SELF_DECIDER_START_TIME' | fromTextKey"
                  hide-clear-button
                  :disabled="!presenceConfiguration[presenceModules.PICKUP_TIMES].editable"
                  :minute-interval="timepickerSettings.DEFAULT_INTERVAL"
                  :start="comeGoUtil.defaultStart()"
                  :end="comeGoUtil.defaultEnd()"
                  advanced-keyboard
                  @change="onSelfDeciderTimeChanged"
                />
                <b-alert variant="danger" :show="showAlertSelfDeciderNoStartTime" class="mb-0">
                  {{ 'PRESENCE_WARNING_NO_EXIT_TIME' | fromTextKey }}
                </b-alert>
                <b-alert variant="danger" :show="showAlertSelfDeciderEndTime" class="mb-0">
                  {{ 'PRESENCE_SELF_DECIDER_TIME_WARNING' | fromTextKey }}
                </b-alert>
                <b-alert variant="danger" :show="showAlertExitTimeIsBeforeEntryTime" class="mb-0">
                  {{ 'PRESENCE_ALERT_INVALID_START_AND_END_TIME' | fromTextKey }}
                </b-alert>
              </b-col>
              <b-col cols="12" md="3" :class="{ 'mt-3': isMobile }">
                <label>{{ 'PRESENCE_SELF_DECIDER_END_TIME' | fromTextKey }}</label>
                <span class="mandatory"> * </span>
                <timepicker
                  id="check-out-time"
                  v-model="selfDeciderEndTime"
                  format="HH:mm"
                  hour-label="Time"
                  minute-label="Minut"
                  :placeholder="'PRESENCE_PLACEHOLDER' | fromTextKey"
                  :aria-label="'ARIA_LABEL_PRESENCE_SELF_DECIDER_END_TIME' | fromTextKey"
                  hide-clear-button
                  :disabled="!presenceConfiguration[presenceModules.PICKUP_TIMES].editable"
                  :minute-interval="timepickerSettings.DEFAULT_INTERVAL"
                  :start="comeGoUtil.defaultStart()"
                  :end="comeGoUtil.defaultEnd()"
                  advanced-keyboard
                  @change="onSelfDeciderTimeChanged"
                />
                <b-alert variant="danger" :show="showAlertSelfDeciderNoEndTime">
                  {{ 'PRESENCE_WARNING_NO_EXIT_TIME' | fromTextKey }}
                </b-alert>
              </b-col>
            </template>
            <b-col
              v-if="
                presenceConfiguration[presenceModules.DROP_OFF_TIME] !== null &&
                presenceConfiguration[presenceModules.DROP_OFF_TIME].readable
              "
              cols="12"
              md="6"
              :class="{ 'mt-3': isMobile }"
            >
              <label for="drop-off-time">
                {{ 'PRESENCE_DELIVERY_TIME' | fromTextKey }}
              </label>
              <timepicker
                id="drop-off-time"
                v-model="entryTime"
                format="HH:mm"
                hour-label="Time"
                minute-label="Minut"
                :placeholder="'PRESENCE_PLACEHOLDER' | fromTextKey"
                :aria-label="'ARIA_LABEL_CHOOSE_CHECK_OUT_TIME' | fromTextKey"
                hide-clear-button
                :disabled="!presenceConfiguration[presenceModules.DROP_OFF_TIME].editable"
                :minute-interval="timepickerSettings.DEFAULT_INTERVAL"
                :start="comeGoUtil.defaultStart()"
                :end="comeGoUtil.defaultEnd()"
                advanced-keyboard
                @change="showAlertExitTimeIsBeforeEntryTime = false"
              />
            </b-col>
            <b-col
              v-if="
                exitType !== exitTypes.SELF_DECIDER &&
                presenceConfiguration[presenceModules.PICKUP_TIMES] != null &&
                presenceConfiguration[presenceModules.PICKUP_TIMES].readable
              "
              cols="12"
              md="6"
              :class="{ 'mt-3': isMobile }"
            >
              <label>{{ 'PRESENCE_SELECT_PICK_UP_TIME' | fromTextKey }}</label>
              <span v-if="isPickupTimeMandatory" class="mandatory">*</span>
              <timepicker
                id="pickup-time"
                v-model="exitTime"
                format="HH:mm"
                hour-label="Time"
                minute-label="Minut"
                :placeholder="'PRESENCE_PLACEHOLDER' | fromTextKey"
                :aria-label="'ARIA_LABEL_CHOOSE_CHECK_OUT_TIME' | fromTextKey"
                hide-clear-button
                :disabled="!presenceConfiguration[presenceModules.PICKUP_TIMES].editable"
                :minute-interval="timepickerSettings.DEFAULT_INTERVAL"
                :start="comeGoUtil.defaultStart()"
                :end="comeGoUtil.defaultEnd()"
                advanced-keyboard
                @change="
                  showAlertNoExitTime = false;
                  showAlertExitTimeIsBeforeEntryTime = false;
                "
              />
              <b-alert variant="danger" :show="showAlertNoExitTime">
                {{ 'PRESENCE_WARNING_NO_EXIT_TIME' | fromTextKey }}
              </b-alert>
              <b-alert variant="danger" :show="showAlertExitTimeIsBeforeEntryTime" class="mt-1 mb-0">
                {{ 'PRESENCE_ALERT_INVALID_START_AND_END_TIME' | fromTextKey }}
              </b-alert>
            </b-col>
          </b-row>
          <b-row
            v-if="
              selectedRegistrations.length === 1 &&
              presenceConfiguration[presenceModules.DAILY_MESSAGE] &&
              presenceConfiguration[presenceModules.DAILY_MESSAGE].readable
            "
            class="separating-line"
          >
            <b-col cols="12">
              <label for="comment">
                {{ 'PRESENCE_COMMENT' | fromTextKey }}
              </label>
              <span class="font-weight-bold">
                {{ 'PRESENCE_COMMENT_MAX_CHARACTERS' | fromTextKey }}
              </span>
              <b-textarea
                id="comment"
                v-model="templateComment"
                maxlength="250"
                max-rows="5"
                rows="5"
                :disabled="!presenceConfiguration[presenceModules.DAILY_MESSAGE].editable"
                :placeholder="'PRESENCE_COMMENT_PLACE_HOLDER' | fromTextKey"
                :aria-label="'ARIA_LABEL_PRESENCE_COMMENT_PLACE_HOLDER' | fromTextKey"
              />
              <b-alert variant="danger" :show="templateComment.length >= 250" class="mt-1 mb-0">
                {{ 'PRESENCE_COMMENT_CHARACTER_LIMIT_REACHED' | fromTextKey }}
              </b-alert>
            </b-col>
          </b-row>
          <aula-form-footer
            v-if="
              selectedRegistrations.length === 1 &&
              (presenceConfiguration.pickup ||
                presenceConfiguration.selfDecider ||
                presenceConfiguration.sendHome ||
                presenceConfiguration.goHomeWith)
            "
            :is-loading="isUpdating"
            submit-text="BUTTON_SAVE"
            @cancelClicked="closeForm"
            @submitClicked="updateExitTypeForChild"
          />
        </div>
        <aula-modal
          ref="incompatibleWarning"
          @cancelClicked="$refs.incompatibleWarning.hide()"
          @okClicked="handleActionAfterCheckCompatibleStatus"
        >
          {{ 'PRESENCE_WARNING_INCOMPATIBLE_STATUS' | fromTextKey }}<br />
          <span v-for="(name, index) in incompatibleRegistrationNames" :key="index" class="d-block">
            {{ name }}
          </span>
          {{ 'PRESENCE_WARNING_INCOMPATIBLE_STATUS_2' | fromTextKey }}
        </aula-modal>
        <aula-modal
          ref="vacationRemovingWarning"
          @cancelClicked="$refs.vacationRemovingWarning.hide()"
          @okClicked="handleActionRemovingVacation"
        >
          {{ 'PRESENCE_WARNING_REMOVE_VACATION' | fromTextKey }}
        </aula-modal>
      </div>
    </template>
  </drawer>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { types } from '../../store/types/types';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import { presenceModules } from '../../../shared/enums/presenceModules';
import { presenceStates } from '../../../shared/enums/presenceStates';
import PhysicalLocationForm from '../../../shared/components/presence/PhysicalLocationForm';
import ActivityForm from '../../../shared/components/presence/ActivityForm';
import AbsenceForm from '../../../shared/components/presence/AbsenceForm';
import SelectedChildren from '../../../shared/components/presence/SelectedChildren';
import { presenceDashboardContext } from '../../../shared/enums/presenceDashboardContext';
import { presenceModuleStatus } from '../../../shared/enums/presenceModuleStatus';
import { exitTypes } from '../../../shared/enums/exitTypes';
import moment from 'moment-timezone';
import { chainPromise } from '../../../shared/utils/promiseUtil';
import Timepicker from '../../../shared/components/Timepicker';
import { timepickerSettings } from '../../../shared/enums/timepickerSettings';
import { comeGoUtil } from '../../../shared/utils/comeGoUtil';
import SleepTimeList from '../../../shared/components/presence/SleepTimeList';

export default {
  props: {
    parent: { type: String, default: parentTypes.PROFILE },
  },
  data() {
    return {
      comeGoUtil: comeGoUtil,
      timepickerSettings: timepickerSettings,
      isEditingChildStatus: false,
      presenceStates: presenceStates,
      presenceModules: presenceModules,
      exitTypes: exitTypes,
      isLoading: false,
      isUpdating: false,
      childrenLocationId: null,
      presenceConfiguration: {},
      childrenStatus: null,
      exitType: null,
      disabledExitType: null,
      activeModules: [],
      checkoutTime: null,
      incompatibleRegistrationsToUpdate: [],
      incompatibleRegistrationNames: [],
      compatibleRegistrationsToUpdate: [],
      action: null,
      locationToUpdate: null,
      statusToUpdate: null,
      sleepIntervalToUpdate: null,
      closeModal: true,
      sleepIntervals: [],
      exitWith: '',
      exitTime: '',
      selfDeciderStartTime: '',
      selfDeciderEndTime: '',
      showAlertSelfDeciderEndTime: false,
      showAlertSelfDeciderNoStartTime: false,
      showAlertSelfDeciderNoEndTime: false,
      showAlertNoExitWith: false,
      showAlertNoExitTime: false,
      isAlertInvalidConfigurationVisible: false,
      templateComment: '',
      entryTime: '',
      showAlertExitTimeIsBeforeEntryTime: false,
    };
  },
  computed: {
    ...mapGetters({
      getDepartments: types.GET_DEPARTMENTS,
      filterOptions: types.GET_FILTER_OPTIONS,
      isMobile: types.GET_IS_MOBILE,
      getPresenceConfiguration: types.GET_PRESENCE_CONFIGURATION,
      selectedRegistrations: types.GET_SELECTED_REGISTRATION_TO_EDIT,
      childrenPresenceRegistrations: types.GET_CHILDREN_PRESENCE_REGISTRATION,
      pickupResponsibles: types.GET_PICK_UP_RESPONSIBLES,
      goHomeWithList: types.GET_GO_HOME_WITH_LIST,
    }),
    isPickupTimeMandatory() {
      return this.exitType === exitTypes.GO_HOME_WITH || this.exitType === exitTypes.SEND_HOME;
    },
    chosenDepartment() {
      return this.getDepartments.find(d => d.id == this.filterOptions.departmentId);
    },
    locations() {
      return this.chosenDepartment ? this.chosenDepartment.locations : [];
    },
  },
  methods: {
    ...mapActions({
      updatePresenceRegistration: types.UPDATE_PRESENCE_REGISTRATION,
      updateLocationForChildren: types.UPDATE_LOCATION_FOR_CHILDREN,
      addSleepIntervalForChildren: types.ADD_SLEEP_INTERVAL_FOR_CHILDREN,
      updatePresenceStatusForChildren: types.UPDATE_PRESENCE_STATUS_FOR_CHILDREN,
      loadPresenceConfiguration: types.LOAD_PRESENCE_CONFIGURATION,
      removeSleepIntervalForChildren: types.REMOVE_VACATION_FOR_CHILDREN,
      updateSleepIntervalForChild: types.UPDATE_SLEEP_INTERVAL_FOR_CHILD,
      loadPresenceRegistration: types.LOAD_PRESENCE_REGISTRATION_BY_IDS,
      loadPickupResponsibles: types.LOAD_PICK_UP_RESPONSIBLES,
      loadGoHomeWithList: types.LOAD_GO_HOME_WITH_LIST,
    }),
    ...mapMutations({
      setSelectedRegistrations: types.MUTATE_SELECTED_REGISTRATION_TO_EDIT,
      onToastSuccess: types.MUTATE_SUCCESS_TEXT,
    }),
    onSelfDeciderTimeChanged() {
      this.showAlertSelfDeciderEndTime = false;
      this.showAlertSelfDeciderNoStartTime = false;
      this.showAlertExitTimeIsBeforeEntryTime = false;
    },
    show() {
      this.isEditingChildStatus = true;
      if (this.selectedRegistrations.length > 0) {
        this.isLoading = true;
        this.loadPresenceConfiguration({
          institutionCode: this.chosenDepartment.institutionCode,
        }).then(() => {
          this.loadPresenceRegistration({
            presenceRegistrationIds: this.selectedRegistrations.map(r => r.presenceRegistrationId),
          })
            .then(() => {
              if (this.selectedRegistrations.length === 1) {
                this.populateChildRegistration();
              }
              this.init();
              this.isLoading = false;
            })
            .catch(() => {
              this.$emit('uriToLong');
              this.closeForm();
            });
        });
      } else {
        this.closeForm();
      }
    },
    changeChildStatusDrawerShow(isShown) {
      if (!isShown) {
        this.closeForm();
      }
    },
    checkCompatibleStatus(status) {
      if (this.childrenPresenceRegistrations.length > 0) {
        this.incompatibleRegistrationNames = [];
        this.incompatibleRegistrationsToUpdate = [];
        this.compatibleRegistrationsToUpdate = [];
        this.compatibleRegistrationsToUpdate = this.childrenPresenceRegistrations.filter(
          r =>
            r.editablePresenceStates != null &&
            r.editablePresenceStates.length > 0 &&
            r.editablePresenceStates.includes(status)
        );
        this.incompatibleRegistrationsToUpdate = this.childrenPresenceRegistrations.filter(
          r => this.compatibleRegistrationsToUpdate.find(c => c.id == r.id) == null
        );
        if (this.incompatibleRegistrationsToUpdate.length > 0) {
          this.incompatibleRegistrationNames = this.incompatibleRegistrationsToUpdate.map(r =>
            this.$options.filters.displayProfileNameWithMetadata(r.institutionProfile)
          );
          this.$refs.incompatibleWarning.show();
        }
      }
    },
    handleSavingSleepInterval(sleepInterval) {
      const hasAbsentChildren =
        this.childrenPresenceRegistrations.find(item => item.status == presenceStates.REPORTED_ABSENT) != null;
      if ((this.childrenStatus == presenceStates.REPORTED_ABSENT && !sleepInterval.end) || hasAbsentChildren) {
        this.$refs.vacationRemovingWarning.show();
        this.action = 'sleep';
        this.sleepIntervalToUpdate = sleepInterval;
      } else {
        this.saveSleepInterval(sleepInterval);
      }
      this.closeForm();
    },
    handleActionRemovingVacation() {
      this.$refs.vacationRemovingWarning.hide();
      switch (this.action) {
        case 'location':
          this.updateChildrenLocation(this.locationToUpdate);
          break;
        case 'status':
          this.updateChilrenStatus(this.statusToUpdate, this.closeModal);
          break;
        case 'sleep':
          this.saveSleepInterval(this.sleepIntervalToUpdate);
          break;
      }
    },
    handleActionAfterCheckCompatibleStatus() {
      this.$refs.incompatibleWarning.hide();
      switch (this.action) {
        case 'location':
          if (this.childrenStatus == presenceStates.REPORTED_ABSENT) {
            this.$refs.vacationRemovingWarning.show();
          } else {
            this.updateChildrenLocation(this.locationToUpdate);
          }
          break;
        case 'status':
          if (this.childrenStatus == presenceStates.REPORTED_ABSENT && this.childrenStatus != status) {
            this.$refs.vacationRemovingWarning.show();
          } else {
            this.updateChilrenStatus(this.statusToUpdate, this.closeModal);
          }
          break;
      }
    },
    closeForm() {
      this.sleepIntervals = [];
      this.$emit('onClosed', false);
      this.showAlertNoExitWith = false;
      this.isEditingChildStatus = false;
      this.showAlertNoExitTime = false;
      this.showAlertSelfDeciderNoStartTime = false;
      this.showAlertSelfDeciderNoEndTime = false;
      this.showAlertSelfDeciderEndTime = false;
      this.isAlertInvalidConfigurationVisible = false;
      this.showAlertExitTimeIsBeforeEntryTime = false;
    },
    removeChild(child) {
      this.setSelectedRegistrations({
        selectedRegistrations: this.selectedRegistrations.filter(r => r.uniStudent.id != child.id),
      });
      this.loadPresenceRegistration({
        presenceRegistrationIds: this.selectedRegistrations.map(r => r.presenceRegistrationId),
      }).then(() => {
        this.calculateStatusForChildren();
      });
      if (this.selectedRegistrations.length === 1) {
        this.loadPickupResponsibles({
          uniStudentIds: this.selectedRegistrations.map(r => r.uniStudent.id),
        });
      }
    },
    checkCompatibleStatusForUpdatingLocation(location) {
      this.checkCompatibleStatus(location != null ? presenceStates.PHYSICAL_PLACEMENT : presenceStates.PRESENT);
      if (this.incompatibleRegistrationsToUpdate.length === 0) {
        const hasAbsentChildren =
          this.childrenPresenceRegistrations.find(item => item.status == presenceStates.REPORTED_ABSENT) != null;
        if (this.childrenStatus == presenceStates.REPORTED_ABSENT || hasAbsentChildren) {
          this.$refs.vacationRemovingWarning.show();
          this.action = 'location';
          this.locationToUpdate = location;
        } else {
          this.updateChildrenLocation(location);
        }
      } else {
        this.action = 'location';
        this.locationToUpdate = location;
      }
    },
    updateChildrenLocation(location) {
      if (this.compatibleRegistrationsToUpdate.length > 0) {
        let locationId;
        if (location != null && location.id != this.childrenLocationId) {
          locationId = location.id;
        } else {
          locationId = null;
        }

        const params = {
          childIds: this.compatibleRegistrationsToUpdate.map(r => r.institutionProfile.id),
          locationId: locationId,
        };
        this.isUpdating = true;
        this.updateLocationForChildren(params).then(() => {
          this.isUpdating = false;
          this.closeForm();
        });
      }
    },
    saveSleepInterval(sleepInterval) {
      sleepInterval.childIds = this.selectedRegistrations.map(r => r.uniStudent.id);
      this.addSleepIntervalForChildren(sleepInterval).then(response => {
        if (this.selectedRegistrations.length > 1) {
          const newSleepInterval = {
            id: response.join('-'),
            startTime: sleepInterval.start,
            endTime: sleepInterval.end != null ? sleepInterval.end : '',
            childIds: sleepInterval.childIds,
          };
          this.sleepIntervals.push(newSleepInterval);
        } else {
          this.loadPresenceRegistration({
            presenceRegistrationIds: this.selectedRegistrations.map(r => r.presenceRegistrationId),
          }).then(() => {
            this.sleepIntervals = this.childrenPresenceRegistrations[0].sleepIntervals;
            for (const sleepInterval of this.sleepIntervals) {
              sleepInterval.startTime = sleepInterval.startTime.slice(0, -3);
              sleepInterval.endTime = sleepInterval.endTime.slice(0, -3);
            }
            this.calculateStatusForChildren();
          });
        }
      });
    },
    editSleepInterval(sleepInterval) {
      const params = {
        sleepIntervalIds: [sleepInterval.id],
        start: sleepInterval.startTime,
        end: sleepInterval.endTime,
      };
      this.updateSleepIntervalForChild(params).then(() => {
        this.loadPresenceRegistration({
          presenceRegistrationIds: this.selectedRegistrations.map(r => r.presenceRegistrationId),
        }).then(() => {
          this.sleepIntervals = this.childrenPresenceRegistrations[0].sleepIntervals;
          for (const sleepInterval of this.sleepIntervals) {
            sleepInterval.startTime = sleepInterval.startTime.slice(0, -3);
            sleepInterval.endTime = sleepInterval.endTime.slice(0, -3);
          }
        });
      });
      this.closeForm();
    },
    removeSleepInterval(sleepInterval) {
      this.removeSleepIntervalForChildren({
        ids: sleepInterval.id.toString().split('-'),
      }).then(() => {
        if (this.selectedRegistrations.length > 1) {
          this.sleepIntervals = this.sleepIntervals.filter(s => s.id != sleepInterval.id);
        } else {
          this.loadPresenceRegistration({
            presenceRegistrationIds: this.selectedRegistrations.map(r => r.presenceRegistrationId),
          }).then(() => {
            this.sleepIntervals = this.childrenPresenceRegistrations[0].sleepIntervals;
            this.calculateStatusForChildren();
          });
        }
      });
    },
    checkCompatibleStatusForUpdatingStatus(status, closeModal = true) {
      this.checkCompatibleStatus(status);
      if (this.incompatibleRegistrationsToUpdate.length === 0) {
        const hasAbsentChildren =
          this.childrenPresenceRegistrations.find(item => item.status == presenceStates.REPORTED_ABSENT) != null;
        if (
          (this.childrenStatus == presenceStates.REPORTED_ABSENT && this.childrenStatus != status) ||
          hasAbsentChildren
        ) {
          this.$refs.vacationRemovingWarning.show();
          this.action = 'status';
          this.statusToUpdate = status;
          this.closeModal = closeModal;
        } else {
          this.updateChilrenStatus(status, closeModal);
        }
      } else {
        this.action = 'status';
        this.statusToUpdate = status;
        this.closeModal = closeModal;
      }
    },
    changeExitType() {
      this.exitWith = null;
      this.showAlertNoExitTime = false;
      this.showAlertNoExitWith = false;
      this.isAlertInvalidConfigurationVisible = false;
      this.calculateTimeProperties();
    },
    calculateTimeProperties() {
      // if selfdecider times are set, exitTime will be set to selfDeciderEndTime
      if (this.selfDeciderEndTime && this.exitTime === '23:59') {
        this.exitTime = this.selfDeciderEndTime;
      }
      // if exitTime is set but no selfDecider then selfDecider times are calculated based on exitTime
      if (this.exitTime && !this.selfDeciderEndTime) {
        const exitTime = this.exitTime.split(':');
        this.selfDeciderEndTime = this.exitTime;
        this.selfDeciderStartTime = moment()
          .hours(exitTime[0])
          .minutes(exitTime[1])
          .subtract(1, 'hours')
          .format('HH:mm');
      }
    },
    updateChilrenStatus(status, closeModal = true) {
      if (this.compatibleRegistrationsToUpdate.length > 0) {
        const params = {
          presenceRegistrationIds: this.compatibleRegistrationsToUpdate.map(r => r.id),
          status:
            this.childrenStatus != status || this.childrenStatus == presenceStates.REPORTED_ABSENT
              ? status
              : presenceStates.PRESENT,
        };
        this.isUpdating = true;
        this.updatePresenceStatusForChildren(params).then(() => {
          this.isUpdating = false;
          if (closeModal) {
            this.closeForm();
          }
        });
      }
    },
    populatePresenceConfiguration() {
      const employeeDashboard = this.getPresenceConfiguration.dashboardModuleSettings.find(
        d => d.presenceDashboardContext == presenceDashboardContext.EMPLOYEE_DASHBOARD
      );
      for (const presenceModule of employeeDashboard.presenceModules) {
        this.presenceConfiguration[presenceModule.moduleType] = {};
        this.presenceConfiguration[presenceModule.moduleType].readable =
          (presenceModule.permission != presenceModuleStatus.DEACTIVATED &&
            presenceModule.permission == presenceModuleStatus.READABLE) ||
          presenceModule.permission == presenceModuleStatus.EDITABLE;
        this.presenceConfiguration[presenceModule.moduleType].editable =
          presenceModule.permission == presenceModuleStatus.EDITABLE;
      }
      this.presenceConfiguration.goHomeWith = this.getPresenceConfiguration.goHomeWith;
      this.presenceConfiguration.pickup = this.getPresenceConfiguration.pickup;
      this.presenceConfiguration.selfDecider = this.getPresenceConfiguration.selfDecider;
      this.presenceConfiguration.sendHome = this.getPresenceConfiguration.sendHome;
      this.activeModules = [];
      if (this.presenceConfiguration.pickup || this.exitType === exitTypes.PICKED_UP_BY) {
        this.activeModules.push({
          value: exitTypes.PICKED_UP_BY,
          label: this.$options.filters.fromTextKey('PRESENCE_PICK_UP_BY'),
          isDisabled: this.presenceConfiguration.pickup,
        });
      }
      if (this.presenceConfiguration.selfDecider || this.exitType === exitTypes.SELF_DECIDER) {
        this.activeModules.push({
          value: exitTypes.SELF_DECIDER,
          label: this.$options.filters.fromTextKey('PRESENCE_SELF_DECIDER'),
          isDisabled: this.presenceConfiguration.selfDecider,
        });
      }
      if (this.presenceConfiguration.sendHome || this.exitType === exitTypes.SEND_HOME) {
        this.activeModules.push({
          value: exitTypes.SEND_HOME,
          label: this.$options.filters.fromTextKey('PRESENCE_SEND_HOME'),
          isDisabled: this.presenceConfiguration.sendHome,
        });
      }
      if (this.presenceConfiguration.goHomeWith || this.exitType === exitTypes.GO_HOME_WITH) {
        this.activeModules.push({
          value: exitTypes.GO_HOME_WITH,
          label: this.$options.filters.fromTextKey('PRESENCE_GO_HOME_WITH_MODULE'),
          isDisabled: this.presenceConfiguration.goHomeWith,
        });
      }
      this.activeModules.map(item => (!item.isDisabled ? (this.disabledExitType = item.value) : null));
    },
    calculateStatusForChildren() {
      if (this.childrenPresenceRegistrations.length === 1) {
        this.childrenStatus = this.childrenPresenceRegistrations[0].status;
        this.childrenLocationId = this.childrenPresenceRegistrations[0].location
          ? this.childrenPresenceRegistrations[0].location.id
          : null;
      } else {
        this.childrenStatus = null;
        this.childrenLocationId = null;
      }
    },
    isPickUpTimesHidden() {
      return (
        !this.presenceConfiguration[presenceModules.PICKUP_TIMES].readable &&
        !this.presenceConfiguration[presenceModules.PICKUP_TIMES].editable
      );
    },
    exitIsSendHomeOrSelfDecider() {
      return [exitTypes.SELF_DECIDER, exitTypes.SEND_HOME].includes(this.exitType);
    },
    updateExitTypeForChild() {
      if (this.exitType === this.disabledExitType) {
        this.closeForm();
        return;
      }
      const isValid = this.validate();
      if (!isValid) {
        return;
      }

      const exitTime = this.exitTime;
      const exitWith = this.getExitWith();
      const params = {
        checkoutType: this.exitType,
        remark: this.templateComment,
        entryTime: this.entryTime,
      };
      if (this.exitType === exitTypes.SELF_DECIDER) {
        if (!this.presenceConfiguration[presenceModules.PICKUP_TYPE].editable) {
          this.closeForm();
          return;
        }

        params.selfDecider = {
          selfDeciderStartTime: this.selfDeciderStartTime,
          selfDeciderEndTime: this.selfDeciderEndTime,
        };
      }
      if (this.exitType === exitTypes.PICKED_UP_BY) {
        if (exitTime && !moment(exitTime, 'HH:mm', true).isValid()) {
          this.showAlertNoExitTime = true;
          return;
        }
        params.pickupBy = {
          exitTime,
          exitWith,
        };
      }
      if (this.exitType === exitTypes.SEND_HOME) {
        if (!exitTime || !moment(exitTime, 'HH:mm', true).isValid()) {
          this.showAlertNoExitTime = true;
          return;
        }
        params.sendHome = {
          exitTime,
        };
      }
      if (this.exitType === exitTypes.GO_HOME_WITH) {
        const isExitTimeInvalid = !exitTime || !moment(exitTime, 'HH:mm', true).isValid();
        const isExitWithMissing = !exitWith && this.presenceConfiguration[presenceModules.PICKUP_TYPE].editable;

        this.showAlertNoExitTime = isExitTimeInvalid;
        this.showAlertNoExitWith = isExitWithMissing;

        if (isExitTimeInvalid || isExitWithMissing) {
          return;
        }
        params.goHomeWith = {
          exitTime,
          exitWith,
        };
      }
      let promiseChain = Promise.resolve();
      for (const childPresenceRegistration of this.childrenPresenceRegistrations) {
        promiseChain = chainPromise(
          () =>
            this.updatePresenceRegistration({
              ...params,
              registrationId: childPresenceRegistration.id,
            }),
          promiseChain
        );
      }

      promiseChain.then(() => {
        this.onToastSuccess('SUCCESS_TOAST_UPDATE_STATUS');
        this.closeForm();
      });
    },
    getExitWith() {
      let exitWith = null;
      if (this.presenceConfiguration[presenceModules.PICKUP_TYPE].editable) {
        exitWith = this.exitWith;
      }
      return exitWith;
    },
    validate() {
      let isValid = true;
      // if exitTime is hidden (configurations) and the exitTime has not been specified before
      // an employee should not be able to save with exitType "send home" or "self decider".
      if (this.isPickUpTimesHidden() && this.exitIsSendHomeOrSelfDecider()) {
        if (
          (this.exitType === exitTypes.SELF_DECIDER && !this.selfDeciderStartTime) ||
          (this.exitType === exitTypes.SEND_HOME && !this.exitTime)
        ) {
          this.isAlertInvalidConfigurationVisible = true;
          isValid = false;
        }
      }

      if (this.exitType === exitTypes.SELF_DECIDER) {
        if (!this.selfDeciderStartTime || !moment(this.selfDeciderStartTime, 'HH:mm', true).isValid()) {
          this.showAlertSelfDeciderNoStartTime = true;
          isValid = false;
        }

        if (!this.selfDeciderEndTime || !moment(this.selfDeciderEndTime, 'HH:mm', true).isValid()) {
          this.showAlertSelfDeciderNoEndTime = true;
          isValid = false;
        }

        const [startHours, startMinutes] = this.selfDeciderStartTime.split(':');
        const [endHours, endMinutes] = this.selfDeciderEndTime.split(':');
        const entryTime = moment().hours(this.entryTime.split(':')[0]).minutes(this.entryTime.split(':')[1]);
        const startTime = moment().hours(startHours).minutes(startMinutes);
        const endTime = moment().hours(endHours).minutes(endMinutes);

        if (endTime.isSameOrBefore(startTime)) {
          this.showAlertSelfDeciderEndTime = true;
          isValid = false;
        }
        if (startTime.isSameOrBefore(entryTime)) {
          this.showAlertExitTimeIsBeforeEntryTime = true;
          isValid = false;
        }
      }

      if (this.showExitTimeIsBeforeEntryTimeAlert()) {
        isValid = false;
      }

      return isValid;
    },
    showExitTimeIsBeforeEntryTimeAlert() {
      if (this.exitType === exitTypes.SELF_DECIDER || !this.entryTime || !this.exitTime) {
        return false;
      }
      let show = false;
      const [entryHours, entryMinutes] = this.entryTime.split(':');
      const [exitHours, exitMinutes] = this.exitTime.split(':');
      if (entryHours > exitHours || (entryHours === exitHours && entryMinutes >= exitMinutes)) {
        this.showAlertExitTimeIsBeforeEntryTime = true;
        show = true;
      }
      return show;
    },
    populateChildRegistration() {
      this.sleepIntervals = this.childrenPresenceRegistrations[0].sleepIntervals;
      for (const sleepInterval of this.sleepIntervals) {
        sleepInterval.startTime = sleepInterval.startTime.slice(0, -3);
        sleepInterval.endTime = sleepInterval.endTime.slice(0, -3);
      }
      this.exitType = this.childrenPresenceRegistrations[0].activityType;
      if (this.childrenPresenceRegistrations[0].isDefaultExitTime === true) {
        this.exitTime = '';
        this.selfDeciderEndTime = '';
      } else {
        this.exitTime = this.childrenPresenceRegistrations[0].exitTime
          ? this.childrenPresenceRegistrations[0].exitTime.slice(0, -3)
          : '';
        this.selfDeciderEndTime = this.childrenPresenceRegistrations[0].selfDeciderEndTime
          ? this.childrenPresenceRegistrations[0].selfDeciderEndTime.slice(0, -3)
          : '';
      }
      if (this.childrenPresenceRegistrations[0].isDefaultExitTime) {
        this.selfDeciderStartTime = '';
      } else {
        this.selfDeciderStartTime = this.childrenPresenceRegistrations[0].selfDeciderStartTime
          ? this.childrenPresenceRegistrations[0].selfDeciderStartTime.slice(0, -3)
          : '';
      }

      if (this.childrenPresenceRegistrations[0].isDefaultEntryTime || this.selectedRegistrations.length > 1) {
        this.entryTime = '';
      } else {
        this.entryTime = this.childrenPresenceRegistrations[0].entryTime?.slice(0, -3) || '';
      }

      if (this.selectedRegistrations.length > 1) {
        this.templateComment = '';
      } else {
        this.templateComment = this.childrenPresenceRegistrations[0].comment || '';
      }

      this.exitWith = this.childrenPresenceRegistrations[0].exitWith;

      this.$nextTick(() => {
        if (this.presenceConfiguration[presenceModules.PICKUP_TYPE].readable) {
          if (this.getPresenceConfiguration.pickup) {
            this.loadPickupResponsibles({
              uniStudentIds: this.selectedRegistrations.map(r => r.uniStudent.id),
            });
          }
          if (this.getPresenceConfiguration.goHomeWith) {
            this.loadGoHomeWithList({
              institutionProfileId: this.childrenPresenceRegistrations[0].institutionProfile.id,
            });
          }
        }
      });
    },
    init() {
      this.populatePresenceConfiguration();
      this.calculateStatusForChildren();
    },
  },
  components: {
    SleepTimeList,
    Timepicker,
    PhysicalLocationForm,
    ActivityForm,
    AbsenceForm,
    SelectedChildren,
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/presence/presence-checkin-dashboard';
@import '../../../shared/assets/scss/components/presence/presence-employee';

.line {
  padding-bottom: 30px;
  margin-bottom: 20px;
  border-bottom: 1px solid $color-grey-dark;
}

.children-registration {
  --sleep-time-border-color: var(--color-primary-darker);
}
</style>
