<template>
  <b-card class="search-result-item margin-bottom-20" tabindex="0" @keypress.enter="openPost()" @click="openPost()">
    <div class="search-result-icon-col">
      <template v-if="isChild">
        <img class="menu-icon" :src="'static/icons/icon-Aula_home_child.svg'" />
      </template>
      <i v-else class="icon-Aula_home" />
    </div>
    <div class="search-result-text-col">
      <div class="card-header-text">
        <keyword-highlight :queries="searchString">
          {{ post.title }}
        </keyword-highlight>
      </div>
      <div class="card-text">
        {{ 'SEARCH_RESULT_MESSAGE_BY' | fromTextKey }}
        <keyword-highlight :queries="searchString">
          {{ post.creator.firstName + ' ' + post.creator.lastName }}
        </keyword-highlight>
        <template v-if="post.content.length > 0">
          <div class="truncate" v-html="content" />
        </template>
        <div>
          {{ publishedAt }}
          <span v-if="editedAt">{{ 'POSTS_EDITED' | fromTextKey({ editedDate: editedAt }) }}</span>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { types } from '../../store/types/types';
import KeywordHighlight from 'vue-text-highlight';
import * as dateUtil from '../../../shared/utils/dateUtil';
import { dateFormatEnum } from '../../../shared/enums/dateFormatEnum';

export default {
  props: {
    post: { type: Object, default: () => {} },
    parent: { type: String, default: 'profile' },
    searchString: { type: String, default: '' },
    isChild: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters({
      group: types.GET_ACTIVE_GROUP,
    }),
    content() {
      const content = this.$options.filters.stripHTML(this.post.content);
      return this.$options.filters.highlightKeyword(content, this.searchString);
    },
    publishedAt() {
      return dateUtil.format(this.post.publishAt, dateFormatEnum.SHORT_DATE_TIME);
    },
    editedAt() {
      if (!this.post.editedAt) {
        return null;
      }
      return dateUtil.format(this.post.editedAt, dateFormatEnum.SHORT_DATE_TIME);
    },
  },
  methods: {
    openPost() {
      this.$router.push({
        name: this.parent === 'group' ? 'group-single-post' : 'singlepost',
        params: { id: this.post.id, groupId: this.group.id },
      });
    },
  },
  components: {
    KeywordHighlight,
  },
};
</script>

<style scoped lang="scss">
.card-text {
  text-transform: capitalize;
}
</style>
