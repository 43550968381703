<template>
  <div>
    <div v-if="!folderButtonClicked" class="centerbox">
      <b-row class="header-style">
        <b-col class="text-center"> {{ numberOfSelectedThreads }}{{ 'MESSAGE_CHOSEN_HEADER' | fromTextKey }} </b-col>
      </b-row>
      <div class="center-buttons">
        <b-row>
          <b-col class="text-center">
            <template v-if="!hasDraftThreadsSelected">
              <button v-if="!showMarkButton" type="button" class="big-button" @click="markSelectedMessages()">
                <i class="icon-Aula_star" />
                <div class="button-text text-center">
                  {{ 'MESSAGE_MARK' | fromTextKey }}
                </div>
              </button>
              <button v-else type="button" class="big-button" @click="markSelectedMessages(false)">
                <i class="icon-Aula_star_solid colored" />
                <div class="button-text">
                  <span class="unmark-text">{{ 'MESSAGE_REMOVE_MARK' | fromTextKey }}</span>
                  <span class="small-unmark-text">{{ 'MESSAGE_REMOVE' | fromTextKey }}</span>
                </div>
              </button>
              <button type="button" class="big-button" @click="onChangeSubscriptionStatus">
                <i :class="isAllUnread ? 'icon-Aula_envelope_open' : 'icon-Aula_envelope_closed'" />
                <div class="button-text text-center">
                  {{ isAllUnread ? 'MESSAGE_MARK_AS_READ_SHORT' : 'MESSAGE_MARK_AS_UNREAD_SHORT' | fromTextKey }}
                </div>
              </button>
              <button v-if="canMoveToFolder" type="button" class="big-button" @click="folderButtonClicked = true">
                <i class="icon-Aula_folder" />
                <div class="button-text text-center">
                  {{ 'MESSAGE_ARCHIVATE' | fromTextKey }}
                </div>
              </button>
            </template>
            <button type="button" class="big-button" @click="$refs.deleteModal.show()">
              <i class="icon-Aula_bin" />
              <div class="button-text text-center">
                {{ 'MESSAGE_BUTTON_DELETE' | fromTextKey }}
              </div>
            </button>
          </b-col>
        </b-row>
      </div>
    </div>
    <move-to-folder-modal
      v-if="folderButtonClicked"
      :thread-ids="selectedThreadIds"
      :subscription-ids="selectedBundleIds"
      :chosen-folder-and-mail-owner="chosenFolderAndMailOwner"
      :folders="!!chosenFolderAndMailOwner.mailOwnerId ? commonInboxFolders : folders"
      @hide="changeMoveToFolderVisibility"
    />
    <aula-modal
      ref="deleteModal"
      header-text="MESSAGE_DELETE_MORE_THREADS_HEADER"
      ok-text="MESSAGE_DELETE_OK"
      data-size="small"
      @cancelClicked="$refs.deleteModal.hide()"
      @okClicked="deleteSelectedMessages()"
    >
      {{ 'MESSAGE_DELETE_MORE_THREADS_WARNING_1' | fromTextKey }}<br />
      {{ deletedThreadWarningMessage }} <br />
      {{ 'MESSAGE_DELETE_MORE_THREADS_WARNING_2' | fromTextKey }}
    </aula-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { types } from '../../store/types/types';
import moment from 'moment-timezone';
import MessageMoveToFolderModal from './MessageMoveToFolderModal.vue';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import { subscriptionStatus } from '../../../shared/enums/subscriptionStatus';
import { subscriptionTypes } from '../../../shared/enums/subscriptionTypes';
import difference from 'lodash/difference';
import { messageProviderKeyEnum } from '../../../shared/enums/messageProviderKeyEnum';
import { portalRoles } from '../../../shared/enums/portalRoles';

export default {
  inject: {
    getDeletedFolderId: messageProviderKeyEnum.GET_DELETED_FOLDER_ID,
    getCanMoveToDeletedMessageFolder: messageProviderKeyEnum.CAN_MOVE_TO_DELETED_MESSAGE_FOLDER,
    deleteOtpSubscriptions: messageProviderKeyEnum.DELETE_OTP_SUBSCRIPTIONS,
  },
  data: function () {
    return {
      moment: moment,
      folderButtonClicked: false,
      selectedFolder: null,
      isAllUnread: false,
    };
  },
  computed: {
    ...mapGetters({
      profile: types.GET_CURRENT_PROFILE,
      chosenFolderAndMailOwner: types.MESSAGES_GET_CHOSEN_FOLDER_AND_MAIL_OWNER,
      selectedThreadIds: types.MESSAGES_GET_CHOSEN_THREAD_IDS,
      selectedSubscriptionIds: types.MESSAGES_GET_CHOSEN_SUBSCRIPTION_IDS,
      selectedBundleIds: types.MESSAGES_GET_CHOSEN_BUNDLE_IDS,
      commonInboxFolders: types.MESSAGES_GET_FOLDERS_FOR_CURRENT_COMMON_INBOX,
      folders: types.MESSAGES_GET_FOLDERS,
      threadBundles: types.MESSAGES_GET_THREAD_BUNDLES,
      hasPermission: types.HAS_PERMISSION,
      activeChildren: types.GET_ACTIVE_CHILDREN,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
      subscriptionsAndBundleThreads: types.MESSAGES_GET_SUBSCRIPTIONS_AND_BUNDLE_THREADS,
    }),
    canMoveToDeletedMessageFolder() {
      return this.getCanMoveToDeletedMessageFolder();
    },
    deletedFolderId() {
      return this.getDeletedFolderId();
    },
    deletedThreadWarningMessage() {
      let message = this.$options.filters.fromTextKey('MESSAGE_DELETE_MORE_THREADS_INFO_2');

      if (this.chosenFolderAndMailOwner?.mailOwnerId) {
        message = this.$options.filters.fromTextKey('MESSAGE_DELETE_MORE_THREADS_INFO_2_COMMON');
      }
      if (this.canMoveToDeletedMessageFolder) {
        message += ` ${this.$options.filters.fromTextKey('MESSAGE_DELETE_MORE_THREADS_INFO_FROM_NORMAL_FOLDER')}`;
      }
      return message;
    },
    numberOfSelectedThreads() {
      // AULA-37947: count the number of draft messages also
      let count = this.selectedThreadIds.length;
      this.selectedBundleIds.forEach(bundleId => {
        const bundleExists = this.threadBundles.some(bundle => bundle.bundleId === bundleId);
        if (bundleExists === false) {
          const bundle = this.subscriptionsAndBundleThreads.find(
            item => item.subscriptionId === bundleId && item.subscriptionType === subscriptionTypes.BUNDLE
          );
          if (bundle != null) {
            count = count + bundle.numberOfBundleItems;
          }
        }
      });
      return count;
    },
    showMarkButton() {
      let containsUnmarkedMessages = false;
      for (const subscription of this.selectedThreadIds) {
        const subscriptionChosen = this.subscriptionsAndBundleThreads.filter(
          item => item.id === subscription && item.marked
        );
        if (subscriptionChosen.length > 0) {
          containsUnmarkedMessages = true;
          return containsUnmarkedMessages;
        }
      }
      return containsUnmarkedMessages;
    },
    canMoveToFolder() {
      return this.hasPermission(permissionEnum.INBOX_FOLDERS);
    },
    hasDraftThreadsSelected() {
      const draftThreads = this.selectedThreadIds.filter(subscription => subscription.toString().match(/^DRAFT/));
      return draftThreads.length > 0;
    },
  },
  methods: {
    ...mapActions({
      updateSelectedThreadIds: types.ACTION_UPDATE_SELECTED_THREAD_IDS,
      updateSelectedSubscriptionIds: types.ACTION_UPDATE_SELECTED_SUBSCRIPTION_IDS,
      updateSelectedBundleIds: types.ACTION_UPDATE_SELECTED_BUNDLE_IDS,
      switchMarkMessages: types.SWITCH_MARK_SUBSCRIPTIONS,
      deleteSubscriptions: types.DELETE_SUBSCRIPTIONS,
      updateSubscriptionStatus: types.MESSAGES_UPDATE_SUBSCRIPTION_STATUS,
      loadNotifications: types.LOAD_NOTIFICATIONS,
      leaveThreads: types.MESSAGES_LEAVE_THREADS,
      moveThreadsToFolder: types.MOVE_THREADS_TO_FOLDER,
    }),
    ...mapMutations({
      uncheckAllSubscriptions: types.MUTATE_UNCHECK_ALL_SUBSCRIPTIONS,
      setSuccessMessage: types.MUTATE_SUCCESS_TEXT,
      mutateDeleteSubscriptions: types.MUTATE_DELETE_SUBSCRIPTIONS,
      mutateDeleteDraftThread: types.MUTATE_DELETE_DRAFT_THREAD,
    }),
    changeMoveToFolderVisibility(value) {
      this.folderButtonClicked = value;
    },
    folderSelected(folderId) {
      this.selectedFolder = folderId;
    },
    async deleteSelectedMessages() {
      this.$refs.deleteModal.hide();
      const draftThreadIds = this.selectedThreadIds.filter(item => item.match && item.match(/^DRAFT/));
      const allSelectedSubscriptionIds = [...this.selectedBundleIds, ...this.selectedSubscriptionIds];
      if (this.canMoveToDeletedMessageFolder) {
        if (allSelectedSubscriptionIds.length > 0) {
          await this.leaveThreads({
            subscriptionIds: allSelectedSubscriptionIds,
          });
          await this.moveThreadsToFolder({
            subscriptionIds: allSelectedSubscriptionIds,
            folderId: this.deletedFolderId,
          });
          this.setSuccessMessage('SUCCESS_TOAST_MESSAGES_DELETE_THREADS_FROM_NORMAL_FOLDER');
        }
        for (const threadId of draftThreadIds) {
          this.mutateDeleteDraftThread({
            id: this.profile.id,
            key: threadId,
          });
        }
        this.mutateDeleteSubscriptions({
          threadIds: difference(this.selectedThreadIds, draftThreadIds),
          subscriptionIds: this.selectedBundleIds,
          id: this.profile.id,
        });
      } else if (this.profile.role == portalRoles.OTP) {
        this.deleteOtpSubscriptions(this.subscriptionsAndBundleThreads, this.deleteSubscriptions);
      } else {
        this.deleteSubscriptions({
          threadIds: this.selectedThreadIds,
          subscriptionIds: this.selectedBundleIds,
        });
      }
      this.loadNotifications({
        activeChildrenIds: this.activeChildren,
        activeInstitutionCodes: this.activeInstitutions,
      });
      this.unselectMessages();
    },
    markSelectedMessages(isMarked = true) {
      this.switchMarkMessages({
        threadIds: this.selectedThreadIds,
        subscriptionIds: this.selectedBundleIds,
        isMarked: isMarked,
      }).then(() => {
        this.unselectMessages();
      });
    },
    async onChangeSubscriptionStatus() {
      const selectedSubscriptions = this.subscriptionsAndBundleThreads.filter(
        subscription =>
          this.selectedThreadIds.includes(subscription.id) && subscription.subscriptionType !== subscriptionTypes.BUNDLE
      );
      const selectedSubscriptionIds = selectedSubscriptions.map(subscription => subscription.subscriptionId);
      const subscriptionIdsToUpdate = selectedSubscriptionIds.concat(this.selectedBundleIds);
      const statusToUpdate = this.isAllUnread ? subscriptionStatus.READ : subscriptionStatus.UNREAD;

      await this.updateSubscriptionStatus({
        subscriptionIds: subscriptionIdsToUpdate,
        status: statusToUpdate,
      });

      this.isAllUnread = !this.isAllUnread;
      this.unselectMessages();

      this.loadNotifications({
        activeChildrenIds: this.activeChildren,
        activeInstitutionCodes: this.activeInstitutions,
      });
    },
    unselectMessages() {
      this.uncheckAllSubscriptions();
      this.updateSelectedThreadIds([]);
      this.updateSelectedSubscriptionIds([]);
      this.updateSelectedBundleIds([]);
    },
    setIsAllUnreadStatus() {
      const selectedSubscriptions = this.subscriptionsAndBundleThreads.filter(subscription => {
        if (subscription.subscriptionType === subscriptionTypes.BUNDLE) {
          return this.selectedBundleIds.includes(subscription.subscriptionId);
        }
        return this.selectedThreadIds.includes(subscription.id);
      });
      this.isAllUnread = selectedSubscriptions.every(subscription => subscription.read === false);
    },
  },
  watch: {
    selectedThreadIds() {
      this.setIsAllUnreadStatus();
    },
    selectedBundleIds() {
      this.setIsAllUnreadStatus();
    },
  },
  mounted() {
    this.setIsAllUnreadStatus();
  },
  components: {
    'move-to-folder-modal': MessageMoveToFolderModal,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/elements/modal.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.big-button {
  border: none;
  background: none;
  font-size: 42px;
  margin-right: 20px;
  display: inline;
  .theme-employee & {
    color: $color-primary-darker-employee;
  }
  .theme-guardian & {
    color: $color-primary-darker-guardian;
  }
  .theme-child & {
    color: $color-primary-darker-child;
  }
  .theme-admin & {
    color: $color-primary-darker-admin;
  }
  div {
    padding-top: 15px;
  }
}

.button-text {
  font-size: 15px;
  width: 100%;
  .theme-employee & {
    color: $color-primary-darker-employee;
  }
  .theme-guardian & {
    color: $color-primary-darker-guardian;
  }
  .theme-child & {
    color: $color-primary-darker-child;
  }
  .theme-admin & {
    color: $color-primary-darker-admin;
  }
}

.center-buttons {
  margin-top: 3%;
}

.centerbox {
  margin-top: 140px;
}

.header-style {
  margin-top: 10%;
}

.container-scroll {
  overflow: auto;
}

.colored {
  color: $color_alert;
}

.link-style {
  color: $color-primary-dark;
  font-weight: bold;
}

.unmark-text {
  display: none;
  @include breakpoint-xs-up() {
    display: initial;
  }
}

.small-unmark-text {
  display: initial;
  @include breakpoint-sm-up() {
    display: none;
  }
}

.sletTextWidth {
  width: 50px !important;
}
</style>
