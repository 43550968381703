import { portalRoles } from '../../shared/enums/portalRoles';
import { messageOwnerTypes } from '../../shared/enums/messageOwnerTypes';
import { MailOwnerModel } from '../../shared/models/mailOwner.model';
import { SenderModel } from '../../shared/models/sender.model';
import { MessageBoxModel } from '../../shared/models/messageBox.model';
import { docTypes } from '../../shared/enums/docTypes';
import { RecipientModel } from '../../shared/models/recipient.model';

export class MessageRecipient implements RecipientModel {
  public id: number;
  public label?: string;
  public institutionCode?: string;
  public mailBoxOwnerType?: messageOwnerTypes;
  public firstName?: string;
  public lastName?: string;
  public value?: string;
  public name?: string;
  public fullName?: string;
  public type?: string; // 'profile' | 'commoninbox';   //todo: speerate type ?
  public groupId?: number;
  public portalRole?: portalRoles;
  public profileId?: number;
  public metadata?: string;
  public otpInboxId?: number;
  public commonInboxId?: number;
  public mailBoxOwner?: MessageBoxModel;
  public role?: portalRoles;
  public aulaEmail?: string;
  public address?: string;
  public shortName: string;

  // if there is something more we dont use, but we need it to properly cast to the interface
  [key: string]: unknown;

  constructor(recipientArg?: RecipientModel) {
    if (!recipientArg) {
      return;
    }
    const recipient = { ...recipientArg };
    this.value = recipient.value;
    this.mailBoxOwnerType = recipient.mailBoxOwnerType;
    this.portalRole = recipient.portalRole;
    this.otpInboxId = recipient.otpInboxId;
    this.commonInboxId = recipient.commonInboxId;
    this.type = recipient.type;
    this.id = recipient.id;
    this.label = recipient.label;
    this.institutionCode = recipient.institutionCode;
    this.firstName = recipient.firstName;
    this.lastName = recipient.lastName;
    this.name = recipient.name;
    this.fullName = recipient.fullName;
    this.groupId = recipient.groupId;
    this.profileId = recipient.profileId;
    this.metadata = recipient.metadata;
    this.mailBoxOwner = recipient.mailBoxOwner;
    this.role = recipient.role;
    this.aulaEmail = recipient.aulaEmail;
    this.address = recipient.aulaEmail;
    this.shortName = recipient.shortName;

    if (this.otpInboxId) {
      this.mailBoxOwnerType = messageOwnerTypes.OTP_INBOX;
    }

    if (this.commonInboxId) {
      this.mailBoxOwnerType = messageOwnerTypes.COMMON_INBOX;
    }

    if (this.type == null) {
      this.type = docTypes.PROFILE.toLowerCase();
    }
  }

  /**
   * @description if recipients has  OTP mailbox sets its id from different sources: otpInboxId, chosenFolderAndMailOwner, message selectedOtpInbox
   * @param  {MailOwnerModel} chosenFolderAndMailOwner  - chosen folder and mail box
   * @param  {number} selectedOtpInbox - list of recipients
   * @return {MessageRecipient} recipient with set id for Otp recipient, otherwise the same Recipient
   */
  public setRecipientIdWhenOtpMailBox(
    chosenFolderAndMailOwner: MailOwnerModel,
    selectedOtpInbox: number
  ): MessageRecipient {
    if (this.mailBoxOwnerType !== messageOwnerTypes.OTP_INBOX) {
      return this;
    }

    if (this.otpInboxId > 0) {
      this.id = this.otpInboxId;
      return this;
    }
    if (chosenFolderAndMailOwner.otpInboxId > 0) {
      this.id = chosenFolderAndMailOwner.otpInboxId;
      return this;
    }
    if (selectedOtpInbox > 0) {
      this.id = selectedOtpInbox;
    }
    return this;
  }

  /**
   * @description if recipients has otpInboxId  sets mailBoxOwnerType to OTP_INBOX
   * @return {MessageRecipient} recipient with set mailBoxOwnerType set to OTP_INBOX for Otp recipient , otherwise the same Recipient
   */
  public setOtpRecipientMailOwner(): MessageRecipient {
    if (this.otpInboxId) {
      this.mailBoxOwnerType = messageOwnerTypes.OTP_INBOX;
    }
    return this;
  }

  /**
   * @description creates new instance of Recipient from Sender
   * @param  {SenderModel} sender
   * @returns {MessageRecipient} new instance of Recipient
   */
  public static from(sender: SenderModel): MessageRecipient {
    const recipient = new MessageRecipient();
    recipient.id = sender.id;
    recipient.fullName = sender.fullName;
    recipient.firstName = sender.firstName;
    recipient.lastName = sender.lastName;
    recipient.mailBoxOwnerType = sender.mailBoxOwnerType;
    recipient.metadata = sender.metadata;
    recipient.mailBoxOwner = sender.mailBoxOwner;
    return recipient;
  }

  public existsGroupUserEqualRecipient(users: RecipientModel[]): boolean {
    return (
      users.find(
        user =>
          user.type == 'group' &&
          user.id == this.groupId &&
          (user.portalRole == null || user.portalRole == this.portalRole)
      ) != null
    );
  }

  public setMailBoxOwnerType() {
    if (this.otpInboxId != null) {
      this.mailBoxOwnerType = messageOwnerTypes.OTP_INBOX;
      return;
    }
    if (this.type === docTypes.PROFILE.toLowerCase()) {
      this.mailBoxOwnerType = messageOwnerTypes.INSTITUTION_PROFILE;
      return;
    }
    if (this.type === docTypes.COMMON_INBOX.toLowerCase()) {
      this.mailBoxOwnerType = messageOwnerTypes.COMMON_INBOX;
    }
  }

  public get hasProfileValue() {
    return this.value && this.value.toLowerCase().includes(docTypes.PROFILE.toLowerCase());
  }

  public get hasGroupValue() {
    return this.value && this.value.toLowerCase().includes(docTypes.GROUP.toLowerCase());
  }

  public get hasCommonBoxValue() {
    return this.value && this.value.toLowerCase().includes(docTypes.COMMON_INBOX.toLowerCase());
  }

  public get hasGuardianRole() {
    return this.portalRole === portalRoles.GUARDIAN;
  }
}
