










import { mapActions, mapGetters } from 'vuex';
import { types } from '../../store/types/types.js';

export default {
  emits: ['change'],
  data() {
    return {
      selectedGroupHomeId: null,
    };
  },
  computed: {
    ...mapGetters({
      groupHomes: types.GET_GROUP_HOMES,
      activeGroupHome: types.GET_ACTIVE_GROUP_HOME,
    }),
    groupHomeOptions() {
      return this.groupHomes.map(group => ({
        text: group.name,
        value: group.id,
      }));
    },
  },
  mounted() {
    this.selectedGroupHomeId = this.activeGroupHome.id;
  },
  methods: {
    ...mapActions({
      setActiveGroupHome: types.SET_ACTIVE_GROUP_HOME,
    }),
    handleGroupHomeSelect() {
      this.setActiveGroupHome(this.selectedGroupHomeId);
      this.$emit('change');
    },
  },
};
