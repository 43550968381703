<template>
  <div class="profile-frame">
    <div class="profile-avatar-container">
      <AulaAvatar :img-url="url" :initials="profile | displayProfileInitials" />
    </div>
    <div class="profile-name-bar">
      <slot name="name" />
    </div>
  </div>
</template>

<script lang="js">
import AulaAvatar from "./AulaAvatar";
export default {
  name: "AulaProfileFrame",
  components: {AulaAvatar},
  props: {
    profile: {
      required: true,
      type: Object,
    },
  },
  computed: {
    url() {
      return this.profile.profilePicture?.url || null;
    }
  }
}
</script>
<style lang="scss" scoped>
.profile-frame {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.profile-avatar-container {
  flex: 1;
  overflow: hidden;
}

.profile-name-bar {
  background-color: var(--color-grey-dark);
  padding: 10px 7px;
}
</style>
