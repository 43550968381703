// ---------- SETTINGS ----------

export const NOTIFICATION_SETTINGS_TITLE = 'Notifikationsindstillinger';
export const NOTIFICATION_SETTINGS_FROM = 'Jeg ønsker notifikationer om:';
export const NOTIFICATION_SETTINGS_ON = 'Jeg ønsker notifikationer på:';
export const NOTIFICATION_SETTINGS_ON_CONSENT =
  'Vær opmærksom på, at det fulde indhold i beskeder sendes ud med notifikationer. Ved at slå notifikationer til samtykker du til denne behandling.';
export const NOTIFICATION_SETTINGS_POST = 'Opslag';
export const NOTIFICATION_SETTINGS_OVERVIEW = 'Overblik';
export const NOTIFICATION_SETTINGS_PRESENCE = 'Komme/gå aktiviteter';
export const NOTIFICATION_SETTINGS_MESSAGES = 'Beskeder';
export const NOTIFICATION_SETTINGS_WIDGET = 'Widget';
export const NOTIFICATION_SETTINGS_WIDGET_ACTIVATED = 'Aktivitet';
export const NOTIFICATION_SETTINGS_PICKUP_ACTIVITIES = 'Henteaktiviteter';
export const NOTIFICATION_SETTINGS_VACATION_REQUESTS = 'Ferieanmodninger';
export const NOTIFICATION_SETTINGS_CALENDAR_EVENTS = 'Begivenheder og møder';
export const NOTIFICATION_SETTINGS_SUBSTITUTE_TEACHER = 'Vikardækninger';
export const NOTIFICATION_SETTINGS_LESSON_NOTE_CHANGED = 'Skemanoter';
export const NOTIFICATION_SETTINGS_EMPLOYEES = 'Fra medarbejdere';

export const NOTIFICATION_SETTINGS_GUARDIANS = 'Fra forældre';
export const NOTIFICATION_SETTINGS_CHILDREN = 'Fra børn';
export const NOTIFICATION_SETTINGS_CALENDAR = 'Kalender';
export const NOTIFICATION_SETTINGS_GALLERY = 'Galleri';
export const NOTIFICATION_SETTINGS_MEDIA = 'Medier';
export const NOTIFICATION_SETTINGS_APP = 'Mobil';
export const NOTIFICATION_SETTINGS_YES = 'Ja tak';
export const NOTIFICATION_SETTINGS_NO = 'Nej tak';
export const NOTIFICATION_SETTINGS_SHORT_YES = 'Ja';
export const NOTIFICATION_SETTINGS_SHORT_NO = 'Nej';
export const NOTIFICATION_SETTINGS_EMAIL = 'E-mail';
export const NOTIFICATION_SETTINGS_FREQUENCY = 'Vælg hvor ofte:';
export const NOTIFICATION_SETTINGS_ALWAYS = 'Med det samme';
export const NOTIFICATION_SETTINGS_INTERVAL = 'En gang om dagen';
export const NOTIFICATION_SETTINGS_TIME = 'Vælg dage og tid:';
export const NOTIFICATION_SETTINGS_SELECT_DAYS = 'Vælg dage';
export const NOTIFICATION_SETTINGS_EVERYDAY = 'Alle dage';
export const NOTIFICATION_SETTINGS_SPECIFIC_DAYS = 'På bestemte ugedage';
export const NOTIFICATION_SETTINGS_MONDAY = 'Mandag';
export const NOTIFICATION_SETTINGS_TUESDAY = 'Tirsdag';
export const NOTIFICATION_SETTINGS_WEDNESDAY = 'Onsdag';
export const NOTIFICATION_SETTINGS_THURSDAY = 'Torsdag';
export const NOTIFICATION_SETTINGS_FRIDAY = 'Fredag';
export const NOTIFICATION_SETTINGS_SATURDAY = 'Lørdag';
export const NOTIFICATION_SETTINGS_SUNDAY = 'Søndag';
export const NOTIFICATION_SETTINGS_NO_APP =
  "Det ser ud til, at du ikke kan modtage push notifikationer på app, fordi du ikke har downloadet app'en.";
export const NOTIFICATION_SETTINGS_NO_EMAIL = 'Det ser ud til, at du ikke kan modtage push notifikationer på email.';
export const NOTIFICATION_SETTINGS_NO_EMAIL_2 =
  'Hvis du vil modtage notifikationer på email, skal du angive en. Det kan du gøre under kontaktinformationer under Min profil';
export const NOTIFICATION_SETTINGS_DOWNLOAD = "Download app'en til Android eller iOS:";
export const NOTIFICATION_SETTINGS_ACTIVATE_PUSH = "Du kan downloade app'en til IOS eller Android her";
export const NOTIFICATION_SETTINGS_ENTER_EMAIL = 'Vil du modtage push notifikationer på email?';
export const NOTIFICATION_SETTINGS_ENTER_EMAIL_LINK = 'Indtast email';
export const NOTIFICATION_SETTINGS_READ_MORE = 'Læs hvordan du aktiverer push notifikationer på din app.';
export const NOTIFICATION_SETTINGS_SAVE_CHANGES = 'GEM';
export const NOTIFICATION_SETTINGS_SAVED = 'Indstillingerne er gemt';
export const NOTIFICATION_SETTINGS_SAVE_FAILED = 'Vælg mindst én dag og et tidspunkt';
export const NOTIFICATION_SETTINGS_GETTING = 'Henter notifikationsindstillinger';
export const NOTIFICATION_SETTINGS_ALL_KALENDAR_EVENTS = 'Begivenheder';
export const NOTIFICATION_SETTINGS_ALL_MEDIA = 'Medier';
export const NOTIFICATION_SETTINGS_ALL_POSTS = 'Opslag';
export const NOTIFICATION_CLEAR_ALL_TITLE = 'Ryd notifikationer';
export const NOTIFICATION_CLEAR_FROM = 'Ryd notifikationer fra';
export const NOTIFICATION_CLEAR_ALL_BUTTON = 'Ryd notifikationer';
export const NOTIFICATION_CLEAR_MODULE_MESSAGES = 'Beskeder';
export const NOTIFICATION_CLEAR_MODULE_CALENDAR = 'Kalender';
export const NOTIFICATION_CLEAR_MODULE_OVERVIEW = 'Overblik';
export const NOTIFICATION_CLEAR_MODULE_GALLERY = 'Galleri';
export const NOTIFICATION_CLEAR_MODULE_DOCUMENT = 'Dokumenter';
export const NOTIFICATION_CLEAR_MODULE_PRESENCE = 'Komme/gå';
export const NOTIFICATION_CLEAR_ALL_WARNING_1 = 'Du er ved at ryde ALLE notifikationer for de valgte områder.';
export const NOTIFICATION_CLEAR_ALL_WARNING_2 = 'Er du sikker på at du vil fortsætte?';
export const NOTIFICATION_CLEAR_ALL_MOBILE_BUTTON = 'Ryd';

export const APP_PRIVATE = 'Forælder app';
export const APP_EMPLOYEE = 'Medarbejder app';
export const APP_UNKNOWN_DEVICE = 'Ukendt enhed';
export const APP_LAST_LOGIN = 'Seneste aktivitet:';
export const APP_PUSH_NOTIFICATION = 'Push notifikationer:';
export const APP_REMOVE_DEVICE = 'Fjern enhed';
export const APP_REMOVE_ALL = 'Log ud af alle enheder';
export const APP_NO_DEVICES = 'Ingen enheder er registreret';
export const APP_MODAL_CONFIRM_1 =
  'Du er ved at logge af Aula appen på alle enheder, hvor du er logget på. Det betyder, at du skal logge ind i Aula appen igen på hver enkel enhed.';
export const APP_MODAL_CONFIRM_2 = 'Er du sikker på, at du ønsker at fortsætte?';

export const ARIA_NOTIFICATION_SETTINGS_NOTIFICATION = 'notifikationer om';
export const ARIA_NOTIFICATION_SETTINGS_SELECTED_ON = 'valgt til';
export const ARIA_NOTIFICATION_SETTINGS_SELECTED_OFF = 'valgt fra';
export const ARIA_NOTIFICATION_RECEIVE_EMAIL = 'modtag e-mail';
export const ARIA_NOTIFICATION_EVERY = 'hver';
export const ARIA_NOTIFICATION_FOR_EMAIL = 'til e-mail notifikationer';
