var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideDropdown),expression:"hideDropdown"}],staticClass:"aula-groups",attrs:{"title":_vm._f("fromTextKey")('GROUPS_LABEL_CHILD')}},[_c('div',{staticClass:"header-dropdown",class:{ active: _vm.showGroupsDropdown },attrs:{"id":"dropdown-groups","role":"button","tabindex":"0","aria-label":_vm._f("fromTextKey")('ARIA_LABEL_MY_GROUPS')},on:{"click":function($event){!_vm.isPreviewMode
        ? _vm.toggleHeaderDropdown({
            dropdown: 'groups',
            visible: _vm.showGroupsDropdown,
          })
        : ''},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }!_vm.isPreviewMode
        ? _vm.toggleHeaderDropdown({
            dropdown: 'groups',
            visible: _vm.showGroupsDropdown,
          })
        : ''}}},[(!_vm.isChild)?_c('i',{staticClass:"icon-Aula_group"}):_c('img',{staticClass:"menu-icon",attrs:{"src":"static/icons/icon-Aula_group_child.svg"}}),_vm._v(" "),(_vm.badgeAmount > 0)?_c('badge-notification',{attrs:{"amount":_vm.badgeAmount,"border":true}}):_vm._e()],1),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade","leave-active-class":_vm.isClickedCurrentTab ? 'slide-fade-leave-active' : 'slide-fade-leave-active-hide',"leave-to-class":_vm.isClickedCurrentTab ? 'slide-fade-leave-to' : 'leave-to'}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showGroupsDropdown),expression:"showGroupsDropdown"}],staticClass:"header-dropdown-menu",attrs:{"role":"dialog"}},[_c('groups-list')],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }