export const messageProviderKeyEnum = Object.freeze({
  UPDATE_MESSAGE_NEW_COMPONENT_KEY: Symbol(),
  SET_CONVERSATION_LOADING_STATE: Symbol(),
  RESTART_POLLING_SERVICE: Symbol(),
  ON_DELETE_MESSAGE: Symbol(),
  ON_EDIT_MESSAGE: Symbol(),
  RESET_MESSAGE_TEXTAREA: Symbol(),
  ON_CANCEL_EDITING_MESSAGE: Symbol(),
  SET_CURRENT_MESSAGE: Symbol(),
  SET_CLEAN_EDITOR: Symbol(),
  SET_SHOW_TEXTAREA: Symbol(),
  ON_SUBSCRIPTION_CLICKED: Symbol(),
  SET_ACTIVE_EDITOR: Symbol(),
  CAN_DISCARD_EDITING_MESSAGE: Symbol(),
  UPDATE_HAS_MESSAGE_CHANGED: Symbol(),
  IS_THREAD_CREATOR: Symbol(),
  IS_DRAFT_SELECTED: Symbol(),
  SEARCH_MODEL: Symbol(),
  SELECTED_THREAD_VIEW: Symbol(),
  SET_SELECTED_THREAD_VIEW: Symbol(),
  GET_DELETED_FOLDER_ID: Symbol(),
  CAN_MOVE_TO_DELETED_MESSAGE_FOLDER: Symbol(),
  GET_IS_REMINDER_THREAD: Symbol(),
  DELETE_OTP_SUBSCRIPTIONS: Symbol(),
  SET_SELECTED_MESSAGE: Symbol(),
  GET_SELECTED_MESSAGE: Symbol(),
  SET_MESSAGE_FORM_DATA: Symbol(),
  GET_MESSAGE_FORM_DATA: Symbol(),
});
