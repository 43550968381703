<template>
  <span class="post-time">
    {{ formattedTime }}
  </span>
</template>

<script>
export default {
  props: {
    time: { type: String, default: '' },
    variation: { type: String, default: 'short' },
    isCapitalized: { type: Boolean, default: false },
  },
  computed: {
    timeVariation() {
      return this.variation === 'short'
        ? this.$options.filters.shortDate(this.time, true)
        : this.$options.filters.longDate(this.time, true, true);
    },
    formattedTime() {
      return this.isCapitalized ? this.capitalize(this.timeVariation) : this.timeVariation;
    },
  },
  methods: {
    capitalize(time) {
      return this.$options.filters.firstLetterUppercase(time);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.post-time {
  font-size: 0.75rem;
}
</style>
