<template>
  <div v-if="isSectionVisible" class="holiday-closed-days-container">
    <div v-if="isMobile" class="mobile-box-title">
      {{ 'PRESENCE_HOLIDAY_CLOSED_DAYS' | fromTextKey }}
    </div>
    <h2 v-else>{{ 'PRESENCE_HOLIDAY_CLOSED_DAYS' | fromTextKey }}</h2>
    <template v-for="(subList, key) in splitHolidayClosedDaysData">
      <div
        :key="'table-' + key"
        :class="{
          'w-50': subList.length === 1 && selectedInstitutions.length !== 1,
        }"
        class="table"
      >
        <div class="table-row header">
          <template v-if="selectedInstitutions.length === 1">
            <div class="table-cell date-header">
              {{ selectedInstitutions.length === 1 ? 'PRESENCE_DATE_LABEL' : '' | fromTextKey }}
            </div>
            <div class="table-cell institution-header">
              {{ 'PRESENCE_HOLIDAY_CLOSED_DAYS_NAME' | fromTextKey }}
            </div>
          </template>
          <template v-else>
            <div
              v-for="institutionHolidayClosedDaysData in subList"
              :key="institutionHolidayClosedDaysData.institutionCode"
              class="table-cell institution-header w-50"
            >
              {{ getInstitutionName(institutionHolidayClosedDaysData.institutionCode) }}
            </div>
          </template>
        </div>
        <div v-for="index in getNumberOfRowsForSubGroupTable(subList)" :key="index" class="table-row body">
          <template v-if="selectedInstitutions.length === 1 || isMobile">
            <template v-if="subList[0].closedDaysOverview.closedDays[index - 1]">
              <div class="table-cell date-cell">
                {{
                  getFormatDatePeriod(
                    subList[0].closedDaysOverview.closedDays[index - 1].startDate,
                    subList[0].closedDaysOverview.closedDays[index - 1].endDate
                  )
                }}
              </div>
              <div class="table-cell">
                {{ subList[0].closedDaysOverview.closedDays[index - 1].name }}
              </div>
            </template>
          </template>
          <template v-else>
            <div
              v-for="institutionHolidayClosedDaysData in subList"
              :key="'cell-' + institutionHolidayClosedDaysData.institutionCode"
              class="table-cell"
            >
              <template v-if="institutionHolidayClosedDaysData.closedDaysOverview.closedDays[index - 1]">
                <div class="d-flex mb-1">
                  <div class="label">{{ 'PRESENCE_DATE_LABEL' | fromTextKey }}:</div>
                  {{
                    getFormatDatePeriod(
                      institutionHolidayClosedDaysData.closedDaysOverview.closedDays[index - 1].startDate,
                      institutionHolidayClosedDaysData.closedDaysOverview.closedDays[index - 1].endDate
                    )
                  }}
                </div>
                <div class="d-flex">
                  <div class="label">{{ 'PRESENCE_HOLIDAY_CLOSED_DAYS_NAME' | fromTextKey }}:</div>
                  {{ institutionHolidayClosedDaysData.closedDaysOverview.closedDays[index - 1].name }}
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { types } from '../../store/types/types';
import dateTimeFormatMixin from '../../../shared/mixins/dateTimeFormatMixin';
import dataFormat from '../../../shared/mixins/dataFormatMixin';

export default {
  mixins: [dateTimeFormatMixin, dataFormat],
  props: {
    selectedInstitutions: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
      holidayAndClosedDays: types.GET_HOLIDAY_CLOSED_DAY,
    }),
    splitHolidayClosedDaysData() {
      const chunk = this.isMobile ? 1 : 2;
      const closedDaysNotEmptyForInstitution = this.holidayAndClosedDays.filter(
        x => x.closedDaysOverview.closedDays.length > 0
      );
      return this.splitDataToChunks(closedDaysNotEmptyForInstitution, chunk);
    },
    isSectionVisible() {
      return this.holidayAndClosedDays != null && this.holidayAndClosedDays.length > 0;
    },
  },
  methods: {
    getNumberOfRowsForSubGroupTable(subGroup) {
      const groupLengthArr = subGroup.map(item => item.closedDaysOverview.closedDays.length);
      return Math.max(...groupLengthArr);
    },
    getInstitutionName(institutionCode) {
      const inst = this.selectedInstitutions.find(institution => institution.institutionCode === institutionCode);
      if (inst) {
        return inst.name;
      }
      return '';
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../../shared/assets/scss/core/variables';
@import '../../../shared/assets/scss/core/breakpoints';
@import '../../../shared/assets/scss/components/presence/presence';
</style>
