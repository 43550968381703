


















import AulaButton from '../../../shared/components/AulaButton.vue';
import Icon from '../../../shared/components/Icon.vue';
import ProfileFilterChildren from './ProfileFilterChildren.vue';
import ProfileFilterCompact from './ProfileFilterCompact.vue';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import { mapGetters } from 'vuex';
import { types } from '../../store/types/types.js';
import { portalRoles } from '../../../shared/enums/portalRoles';
import ProfileFilterStandard from './ProfileFilterStandard.vue';

const COMPACT_VIEW_NUMBER_PROFILE_LIMIT = 20;
export default {
  components: { ProfileFilterStandard, ProfileFilterCompact, ProfileFilterChildren, Icon, AulaButton },
  emits: ['close'],
  computed: {
    ...mapGetters({
      profile: types.GET_CURRENT_PROFILE,
      groupHomes: types.GET_GROUP_HOMES,
      institutions: types.GET_INSTITUTIONS,
    }),
    iconClassEnum() {
      return iconClassEnum;
    },
    heading() {
      if (this.canUseGroupHomeFilter) {
        return this.$options.filters.fromTextKey('FILTER_SELECT_PROFILE');
      }
      return this.$options.filters.fromTextKey('FILTER_SHOW_CONTENT');
    },
    canUseGroupHomeFilter() {
      return this.profile.role === portalRoles.OTP && this.groupHomes.length > 1;
    },
    isChildFilter() {
      return this.profile.role !== portalRoles.EMPLOYEE;
    },
    isCompactFilter() {
      return (
        this.profile.role === portalRoles.EMPLOYEE && this.institutions.length <= COMPACT_VIEW_NUMBER_PROFILE_LIMIT
      );
    },
    isStandardFilter() {
      return this.profile.role === portalRoles.EMPLOYEE && this.institutions.length > COMPACT_VIEW_NUMBER_PROFILE_LIMIT;
    },
  },
  methods: {
    emitClose() {
      this.$emit('close');
    },
  },
};
