<template>
  <b-dropdown class="dropdown-select right">
    <template #button-content>
      {{ 'SEARCH_FILTER_LABEL_MOBILE' | fromTextKey }}
      <i class="icon-arrow icon-Aula_down-arrow" />
      <i class="icon-arrow icon-Aula_up-arrow" />
    </template>
    <b-dropdown-item :active="showSubresults.includes(portalRoles.CHILD)" @click="changeSubresults(portalRoles.CHILD)">
      {{ 'SEARCH_FILTER_CHILDREN_LABEL' | fromTextKey }}
    </b-dropdown-item>
    <b-dropdown-item
      :active="showSubresults.includes(portalRoles.GUARDIAN)"
      @click="changeSubresults(portalRoles.GUARDIAN)"
    >
      {{ 'SEARCH_FILTER_GUARDIANS_LABEL' | fromTextKey }}
    </b-dropdown-item>
    <b-dropdown-item
      :active="showSubresults.includes(portalRoles.EMPLOYEE)"
      @click="changeSubresults(portalRoles.EMPLOYEE)"
    >
      {{ 'SEARCH_FILTER_EMPLOYEES_LABEL' | fromTextKey }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';
import { types } from '../../store/types/types';
import { portalRoles } from '../../../shared/enums/portalRoles';

export default {
  props: {
    parent: { type: String, default: 'profile' },
  },
  data: function () {
    return {
      portalRoles: portalRoles,
      showSubresults: [portalRoles.CHILD, portalRoles.GUARDIAN, portalRoles.EMPLOYEE],
    };
  },
  computed: {
    ...mapGetters({
      numberOfResultsGroup: types.GET_NUMBER_OF_RESULTS_GROUP,
      numberOfResults: types.GET_NUMBER_OF_RESULTS,
    }),
  },
  methods: {
    ...mapActions({
      setShowSubresultsGroup: types.SET_SHOW_SUBRESULTS_GROUP,
      setShowSubresults: types.SET_SHOW_SUBRESULTS,
    }),
    changeSubresults: function (value) {
      if (value === 'reload') {
        if (this.parent == 'profile') {
          this.setShowSubresults({
            roles: (this.showSubresults = [portalRoles.CHILD, portalRoles.GUARDIAN, portalRoles.EMPLOYEE]),
            docType: 'Profile',
          });
        } else {
          this.setShowSubresultsGroup({
            roles: (this.showSubresults = [portalRoles.CHILD, portalRoles.GUARDIAN, portalRoles.EMPLOYEE]),
            docType: 'Profile',
          });
        }
      } else {
        const index = this.showSubresults.indexOf(value);
        if (index > -1) {
          this.showSubresults.splice(index, 1);
        } else {
          this.showSubresults.push(value);
        }
        if (this.parent == 'profile') {
          this.setShowSubresults({
            roles: this.showSubresults,
            docType: 'Profile',
          });
        } else {
          this.setShowSubresultsGroup({
            roles: this.showSubresults,
            docType: 'Profile',
          });
        }
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
