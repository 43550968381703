<template>
  <div class="styleguide-form-elements-container">
    <h2>Form elements</h2>

    <div class="styleguide-form-elements-container">
      <div v-for="(example, name) in examples">
        <h3>{{ name }}</h3>
        <div v-for="singleExample in example">
          <component :is="singleExample.componentName" />
          <pre class="prettyprint"><code>{{ singleExample.code }}</code></pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { types } from '../../store/types/types';
import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';

// ###### Code-examples
var examples = {
  'Input fields': [
    `<p>Styling for input types: text, password, email, number, url, tel, date, time.</p>
<b-row>
    <b-col md="6" lg="4">
        <label for="sg-input-1">someinput:</label>
        <b-form-input id="sg-input-1" placeholder="someinput" type="text"></b-form-input>
        <div class="error-text"><span class="icon-Aula_exclamation_sign in-alert-circle"></span>Here is some error text</div>
    </b-col>
    <b-col md="6" lg="4">
        <label for="sg-input-1d">someinput disabled:</label>
        <b-form-input id="sg-input-1d" disabled placeholder="someinput disabled" type="text"></b-form-input>
        <div class="error-text"><span class="icon-Aula_exclamation_sign in-alert-circle"></span>Here is some error text</div>
    </b-col>
    <b-col md="6" lg="4" class="error">
        <label for="sg-input-1e">someinput error:</label>
        <b-form-input id="sg-input-1e" placeholder="someinput error" type="text"></b-form-input>
        <div class="error-text"><span class="icon-Aula_exclamation_sign in-alert-circle"></span>Here is some error text</div>
    </b-col>
</b-row>`,
  ],
  Buttons: [
    `<p>
  <b-btn>Opret</b-btn>
  <b-btn disabled>Opret disabled</b-btn>
  <b-btn variant="primary">Opret primary</b-btn>
</p>`,
  ],
  Datepicker: [
    `<p>
  <el-date-picker
    type="date"
    placeholder="pick a date">
  </el-date-picker>
</p>`,
  ],
  Checkboxes: [
    `<b-form-checkbox id="checkbox1">checkbox</b-form-checkbox>
<b-form-checkbox id="checkbox2" disabled>checkbox disabled</b-form-checkbox>
<b-form-checkbox id="checkbox3" checked="true">checkbox checked</b-form-checkbox>`,
  ],
  'Radio buttons': [
    `<b-form-radio-group id="radios" v-model="selected">
    <b-form-radio value="first">radio button</b-form-radio>
    <b-form-radio value="second" disabled>radio button disabled</b-form-radio>
    <b-form-radio value="third" checked="true" :checked="true">radio button selected</b-form-radio>
</b-form-radio-group>`,
  ],
};

// ###### Data for above code examples
var data = {
  selected: 'third',
};

// Parse examples and create components from them
var exampleComponents = {};
var components = {};
var componentNumber = 0;

for (var groupName in examples) {
  exampleComponents[groupName] = [];
  for (let singleExample of examples[groupName]) {
    var componentName = 'codeExample' + componentNumber++;
    components[componentName] = {
      template: '<div>' + singleExample + '</div>',
      data: function () {
        return data;
      },
    };
    exampleComponents[groupName].push({
      componentName: componentName,
      code: singleExample,
    });
  }
}

export default {
  data: function () {
    return {
      ...data,
      examples: exampleComponents,
    };
  },
  components: components,
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.styleguide-form-elements-container {
}
</style>
