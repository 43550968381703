<template>
  <b-input-group class="search-input-group">
    <b-input
      type="text"
      :value="value"
      :placeholder="placeholder"
      :aria-label="placeholder"
      @input="handleInput"
      @keyup.enter="handleEnterKey"
    />
    <b-input-group-addon class="addons">
      <span
        v-if="value !== '' || searchModel.getIsUsingAdvancedSearch()"
        class="icon-Aula_close"
        tabindex="0"
        :aria-label="'ARIA_LABEL_CLEAR_SEARCH' | fromTextKey"
        @click="resetSearch()"
      />
      <span class="icon-Aula_search" @click="emitSelected" />
      <span
        class="icon-aula-advanced-search filter-icon d-inline-block"
        :class="{ 'is-advanced-search-active': searchModel.getIsUsingAdvancedSearch() }"
        role="button"
        tabindex="0"
        @keyup.enter="emitAdvancedSearchShow"
        @keyup.space="emitAdvancedSearchShow"
        @click="emitAdvancedSearchShow"
      />
    </b-input-group-addon>
  </b-input-group>
</template>

<script>
import { types } from '../../store/types/types';
import { mapGetters } from 'vuex';
import { messageProviderKeyEnum } from '../../../shared/enums/messageProviderKeyEnum';

export default {
  inject: {
    getSearchModel: messageProviderKeyEnum.SEARCH_MODEL,
  },
  props: {
    value: { type: String, default: '' },
    placeholder: { type: String, default: '' },
  },
  data() {
    return {
      debounceTimeout: null,
    };
  },
  computed: {
    ...mapGetters({
      messageSearchQuery: types.MESSAGES_GET_ACTIVE_SEARCH_QUERY,
    }),
    searchModel() {
      return this.getSearchModel();
    },
  },
  watch: {
    messageSearchQuery() {
      this.updateValueInput(this.messageSearchQuery);
    },
  },
  methods: {
    resetSearch() {
      this.updateValueInput('');
      this.$emit('resetSearch', '');
    },
    updateValueInput(value) {
      this.$emit('updateValueInput', value);
    },
    resetSearchWithoutUpdate() {
      this.updateValueInput('');
    },
    emitSelected() {
      this.$emit('selected', this.value);
    },
    emitAdvancedSearchShow() {
      this.$emit('advanceSearchClick');
    },
    handleInput(val) {
      this.updateValueInput(val);
      this.$emit('inputChanged', val);
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.emitSelected();
      }, 1000);
    },
    handleEnterKey() {
      clearTimeout(this.debounceTimeout);
      this.emitSelected();
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.search-input-group {
  --input-padding-right: 120px;

  .form-control {
    border-radius: 0;
  }

  .icon-Aula_search {
    font-size: 22px;
    cursor: pointer;
  }

  .filter-icon {
    font-size: 22px;
    cursor: pointer;
    margin-left: 10px;
    &.is-advanced-search-active {
      color: var(--color-primary-dark);
      font-weight: bold;
    }
  }

  .icon-Aula_close {
    font-size: 14px;
    margin-top: 5px;
    margin-right: 10px;
    cursor: pointer;
  }

  .addons {
    z-index: 100;
  }
}
</style>
