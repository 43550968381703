var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user"},[_c('user-icon',{key:_vm.instProfile.id,staticClass:"icon",attrs:{"id":_vm.instProfile.id,"name":_vm.instProfile.firstName + ' ' + _vm.instProfile.lastName,"short-name":_vm.instProfile.shortName,"picture-object":_vm.instProfile.profilePicture}}),_vm._v(" "),_c('div',{staticClass:"user-info"},[_c('div',{staticClass:"user-fullname",class:{ main: !_vm.isChild }},[_vm._v("\n      "+_vm._s(_vm.instProfile.firstName)+" "+_vm._s(_vm.instProfile.lastName)+"\n    ")]),_vm._v(" "),(_vm.showInstName)?_c('div',{staticClass:"user-institution"},[_vm._v("\n      "+_vm._s(_vm.instProfile.institutionName)+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.instProfile.role != _vm.portalRoles.EMPLOYEE)?_c('div',{staticClass:"user-address"},[_vm._v("\n      "+_vm._s(_vm._f("generateAdress")(_vm.instProfile.adress))+"\n    ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"user-update"},[(_vm.instProfile.role != _vm.portalRoles.EMPLOYEE)?[_c('b-input-group',[_c('b-input-group-prepend',[_c('i',{staticClass:"icon icon-Aula_phone",attrs:{"role":"figure","aria-label":_vm._f("fromTextKey")('ARIA_LABEL_HOME_PHONE')}}),_vm._v(" "),_c('div',{staticClass:"contact-type"},[_vm._v(_vm._s(_vm._f("fromTextKey")('PROFILE_HOME_PHONE_LABEL'))+":")])]),_vm._v(" "),_c('b-form-input',{staticClass:"onboarding-input",class:{ 'red-border': !_vm.isHomePhoneNumberValid },attrs:{"autocomplete":"tel","type":"tel","aria-label":_vm.Vue.filter('fromTextKey')('ARIA_LABEL_INPUT_HOME_PHONENUMBER', {
              profileName: _vm.instProfile.firstName + ' ' + _vm.instProfile.lastName,
              institutionName: _vm.instProfile.institutionName,
            })},model:{value:(_vm.instProfile.homePhoneNumber),callback:function ($$v) {_vm.$set(_vm.instProfile, "homePhoneNumber", $$v)},expression:"instProfile.homePhoneNumber"}}),_vm._v(" "),_c('b-input-group-append',[(_vm.showSubmitButton('homePhoneNumber'))?_c('i',{staticClass:"icon icon-Aula_edit_pen pen",attrs:{"role":"figure","aria-label":_vm._f("fromTextKey")('ARIA_LABEL_EDIT')}}):(_vm.isHomePhoneNumberValid)?_c('b-btn',{attrs:{"variant":"link"},on:{"click":function($event){return _vm.updateProfile('homePhoneNumber')}}},[_vm._v("\n            "+_vm._s(_vm._f("fromTextKey")('OK'))+"\n          ")]):_vm._e(),_vm._v(" "),_c('b-alert',{attrs:{"variant":"danger","show":!_vm.isHomePhoneNumberValid}},[_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm._f("fromTextKey")('ARIA_LABEL_INVALID_HOME_PHONENUMBER',{
                  profileName: _vm.instProfile.firstName + ' ' + _vm.instProfile.lastName,
                  institutionName: _vm.instProfile.institutionName,
                })))])])],1)],1),_vm._v(" "),_c('b-input-group',[_c('b-input-group-prepend',[_c('i',{staticClass:"icon icon-Aula_phone",attrs:{"aria-label":_vm._f("fromTextKey")('ARIA_LABEL_MOBILE_PHONE'),"role":"figure"}}),_vm._v(" "),_c('div',{staticClass:"contact-type"},[_vm._v(_vm._s(_vm._f("fromTextKey")('PROFILE_MOBILE_PHONE_LABEL'))+":")])]),_vm._v(" "),_c('b-form-input',{staticClass:"onboarding-input",class:{ 'red-border': !_vm.isMobilePhoneNumberValid },attrs:{"type":"tel","autocomplete":"tel","aria-label":_vm.Vue.filter('fromTextKey')('ARIA_LABEL_INPUT_MOBILE_PHONENUMBER', {
              profileName: _vm.instProfile.firstName + ' ' + _vm.instProfile.lastName,
              institutionName: _vm.instProfile.institutionName,
            })},model:{value:(_vm.instProfile.mobilePhoneNumber),callback:function ($$v) {_vm.$set(_vm.instProfile, "mobilePhoneNumber", $$v)},expression:"instProfile.mobilePhoneNumber"}}),_vm._v(" "),_c('b-input-group-append',[(_vm.showSubmitButton('mobilePhoneNumber'))?_c('i',{staticClass:"icon icon-Aula_edit_pen pen",attrs:{"aria-label":_vm._f("fromTextKey")('ARIA_LABEL_EDIT'),"role":"figure"}}):(_vm.isMobilePhoneNumberValid)?_c('b-btn',{attrs:{"variant":"link"},on:{"click":function($event){return _vm.updateProfile('mobilePhoneNumber')}}},[_vm._v("\n            "+_vm._s(_vm._f("fromTextKey")('OK'))+"\n          ")]):_vm._e(),_vm._v(" "),_c('b-alert',{attrs:{"variant":"danger","show":!_vm.isMobilePhoneNumberValid}},[_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm._f("fromTextKey")('ARIA_LABEL_INVALID_MOBILE_PHONENUMBER',{
                  profileName: _vm.instProfile.firstName + ' ' + _vm.instProfile.lastName,
                  institutionName: _vm.instProfile.institutionName,
                })))])])],1)],1)]:_vm._e(),_vm._v(" "),(!_vm.isChild)?_c('b-input-group',[_c('b-input-group-prepend',[_c('i',{staticClass:"icon icon-Aula_phone",attrs:{"aria-label":_vm._f("fromTextKey")('ARIA_LABEL_WORK_PHONE'),"role":"figure"}}),_vm._v(" "),_c('div',{staticClass:"contact-type"},[_vm._v(_vm._s(_vm._f("fromTextKey")('PROFILE_WORK_PHONE_LABEL'))+":")])]),_vm._v(" "),_c('b-form-input',{staticClass:"onboarding-input",class:{ 'red-border': !_vm.isWorkPhoneNumberValid },attrs:{"type":"tel","autocomplete":"tel","aria-label":_vm.Vue.filter('fromTextKey')('ARIA_LABEL_INPUT_WORK_PHONENUMBER', {
            profileName: _vm.instProfile.firstName + ' ' + _vm.instProfile.lastName,
            institutionName: _vm.instProfile.institutionName,
          })},model:{value:(_vm.instProfile.workPhoneNumber),callback:function ($$v) {_vm.$set(_vm.instProfile, "workPhoneNumber", $$v)},expression:"instProfile.workPhoneNumber"}}),_vm._v(" "),_c('b-input-group-append',[(_vm.showSubmitButton('workPhoneNumber'))?_c('i',{staticClass:"icon icon-Aula_edit_pen pen",attrs:{"role":"figure","aria-label":_vm._f("fromTextKey")('ARIA_LABEL_EDIT')}}):(_vm.isWorkPhoneNumberValid)?_c('b-btn',{attrs:{"variant":"link"},on:{"click":function($event){return _vm.updateProfile('workPhoneNumber')}}},[_vm._v("\n          "+_vm._s(_vm._f("fromTextKey")('OK'))+"\n        ")]):_vm._e(),_vm._v(" "),_c('b-alert',{attrs:{"variant":"danger","show":!_vm.isWorkPhoneNumberValid}},[_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm._f("fromTextKey")('ARIA_LABEL_INVALID_WORK_PHONENUMBER',{
                profileName: _vm.instProfile.firstName + ' ' + _vm.instProfile.lastName,
                institutionName: _vm.instProfile.institutionName,
              })))])])],1)],1):_vm._e(),_vm._v(" "),_c('b-input-group',[_c('b-input-group-prepend',[_c('i',{staticClass:"icon icon-Aula_email email",attrs:{"aria-label":_vm._f("fromTextKey")('ARIA_LABEL_EMAIL'),"role":"figure"}}),_vm._v(" "),_c('div',{staticClass:"contact-type"},[_vm._v(_vm._s(_vm._f("fromTextKey")('PROFILE_EMAIL_LABEL'))+":")])]),_vm._v(" "),_c('b-form-input',{staticClass:"onboarding-input last",class:{ 'red-border': !_vm.isEmailValid },attrs:{"type":"email","autocomplete":"email","aria-label":_vm.Vue.filter('fromTextKey')('ARIA_LABEL_INPUT_EMAIL', {
            profileName: _vm.instProfile.firstName + ' ' + _vm.instProfile.lastName,
            institutionName: _vm.instProfile.institutionName,
          })},model:{value:(_vm.instProfile.email),callback:function ($$v) {_vm.$set(_vm.instProfile, "email", $$v)},expression:"instProfile.email"}}),_vm._v(" "),_c('b-input-group-append',[(_vm.showSubmitButton('email'))?_c('i',{staticClass:"icon icon-Aula_edit_pen pen",attrs:{"role":"figure","aria-label":_vm.Vue.filter('fromTextKey')('ARIA_LABEL_EDIT', {
              profileName: _vm.instProfile.firstName + ' ' + _vm.instProfile.lastName,
              institutionName: _vm.instProfile.institutionName,
            })}}):(_vm.isEmailValid)?_c('b-btn',{attrs:{"variant":"link"},on:{"click":function($event){return _vm.updateProfile('email')}}},[_vm._v("\n          "+_vm._s(_vm._f("fromTextKey")('OK'))+"\n        ")]):_vm._e(),_vm._v(" "),_c('b-alert',{attrs:{"variant":"danger","show":!_vm.isEmailValid}},[_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm._f("fromTextKey")('ARIA_LABEL_INVALID_EMAIL',{
                profileName: _vm.instProfile.firstName + ' ' + _vm.instProfile.lastName,
                institutionName: _vm.instProfile.institutionName,
              })))])])],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }