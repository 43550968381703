<template>
  <div class="content">
    <p>
      {{ 'AULA_NOTIFICATION_CONFIRM' | fromTextKey }}
    </p>
    <AulaButton class="btn-light text-uppercase font-weight-bold" type="button" @click="confirm">
      {{ 'AULA_NOTIFICATION_UNSUBSCRIBE' | fromTextKey }}
    </AulaButton>
  </div>
</template>
<script lang="js">
import AulaButton from "../../components/AulaButton";
import {notificationProviderKeyEnum} from "../../enums/notificationProviderKeyEnum";

export default {
  name: 'UnsubscribeFragmentConfirm',
  components: {AulaButton},
  inject: {
    onConfirm: notificationProviderKeyEnum.ON_UNSUBSCRIBE,
  },
  methods: {
    confirm() {
      this.onConfirm();
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  text-align: center;
}

.icon {
  font-size: var(--icon-font-size);
  margin-bottom: 15px;
  display: inline-block;
}
</style>
