<template>
  <div class="aula-calendar-aside-container">
    <widget-placeholder v-if="!isMobile" :list-of-widgets="getListWidgets" placement="narrow" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { types } from '../../store/types/types';
import WidgetPlaceholder from '../widgets/WidgetPlaceholder';
import { moduleWidgetPlacements } from '../../../shared/enums/moduleWidgetPlacements';
import $ from 'jquery';

export default {
  data: function () {
    return {};
  },
  computed: {
    ...mapGetters({
      menuItems: types.GET_MENU_ITEMS,
      isMobile: types.GET_IS_MOBILE,
      profile: types.GET_CURRENT_PROFILE,
    }),
    getListWidgets() {
      return this.menuItems != undefined ? this.menuItems[moduleWidgetPlacements.RIGHT_OF_CALENDAR] : [];
    },
  },
  watch: {
    getListWidgets() {
      this.hideAside();
    },
  },
  mounted() {
    this.hideAside();
  },
  beforeDestroy() {
    $('.content-wrapper aside').show();
  },
  methods: {
    hideAside() {
      if (this.getListWidgets.length == 0) {
        $('.content-wrapper aside').hide();
      } else {
        $('.content-wrapper aside').show();
      }
    },
  },
  components: {
    WidgetPlaceholder,
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.aula-calendar-aside-container {
  display: none;
  background-color: $color-grey-base;
  height: 100%;
  @include breakpoint-lg() {
    display: block;
  }
  & > div {
    font-size: 18px;
    padding: 28px 25px 0 25px;
  }
  /deep/ .quick-access-item {
    margin-top: 10px;
    height: 70px;
    line-height: 70px;
    padding: 0 25px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-size: 15px;
    background: $color-white;
    &:hover {
      background: $color-grey-light;
    }
  }
}
</style>
