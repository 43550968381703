import Vue from 'vue';
import Router from 'vue-router';
import Login from '../../shared/components/login/Login.vue';
import ChoosePortalRole from '../../shared/components/login/ChoosePortalRole.vue';
import NoAccess from '../../shared/components/login/NoAccess.vue';
import StepUpFailure from '../../shared/components/stepup/StepUpFailure.vue';
import Onboarding from '../components/onboarding/Onboarding.vue';
import Posts from '../components/posts/Posts.vue';
import PostsAside from '../components/posts/PostsAside.vue';
import StyleGuidePage from '../components/styleguide/StyleGuidePage.vue';
import MessagePage from '../components/messages/MessagePage.vue';
import Messages from '../components/messages/ShownSubscription.vue';
import MessageNew from '../components/messages/MessageNew.vue';
import MessageAutoreply from '../components/messages/AutoReply.vue';
import PostNew from '../components/posts/NewPost.vue';
import PostSingle from '../components/posts/SinglePost.vue';
import Calendar from '../components/calendar/Calendar.vue';
import CalendarAside from '../components/calendar/CalendarAside.vue';
import Gallery from '../components/gallery/Gallery.vue';
import AlbumDetail from '../components/gallery/AlbumDetail.vue';
import Documents from '../components/documents/Documents.vue';
import DocumentsSecureList from '../components/documents/DocumentsSecureList.vue';
import DocumentsCommonList from '../components/documents/DocumentsCommonList.vue';
import DocumentRevisionHistory from '../components/documents/DocumentRevisionHistory.vue';
import SearchResults from '../components/searches/SearchResults.vue';
import User from '../../shared/components/UserDetailsPortal.vue';
import AppSettings from '../components/settings/AppSettings.vue';
import PrivacyPolicy from '../components/users/PrivacyPolicy.vue';
import OpenPrivacyPolicy from '../components/users/OpenPrivacyPolicy';
import GroupSearch from '../components/groups/GroupSearch.vue';
import GroupAdministration from '../components/groups/GroupAdministration.vue';
import GroupMembers from '../components/groups/GroupMembers.vue';
import GroupPage from '../components/groups/GroupPage';
import Contacts from '../components/contacts/Contacts';
import PersonalReferenceData from '../components/personalreferencedata/PersonalReferenceData';
import AdditionalProfileData from '../components/additionalprofiledata/AdditionalProfileData';
import WidgetMobile from '../components/widgets/WidgetMobile';
import WidgetPage from '../components/widgets/WidgetPage';
import WidgetGroup from '../components/widgets/WidgetGroup';
import Presence from '../components/presence/Presence';
import EmployeeDashboard from '../components/presence_employee/EmployeeDashboard';
import { moduleTypes } from '../../shared/enums/moduleTypes.ts';
import NotificationSettings from '../components/settings/NotificationSettings.vue';
import DocumentDrawer from '../components/documents/DocumentDrawer.vue';
import { documentTypes } from '../../shared/enums/documentTypes';

Vue.use(Router);

export default new Router({
  // mode: 'history',
  routes: [
    {
      path: '/kommegaa',
      name: 'presence',
      components: {
        default: Presence,
      },
      meta: {
        module: moduleTypes.PRESENCE,
        title: 'Komme/gå',
      },
    },
    {
      path: '/kommegaa/medarbejder',
      name: 'presenceEmployee',
      components: {
        default: EmployeeDashboard,
      },
      meta: {
        module: moduleTypes.PRESENCE,
        title: 'Komme/gå',
      },
    },
    {
      path: '/widgetMobile/:id',
      name: 'widgetMobile',
      components: {
        default: WidgetMobile,
      },
    },
    {
      path: '/login',
      name: 'login',
      components: {
        default: Login,
      },
      meta: {
        title: 'AULA Login',
      },
      children: [
        {
          path: 'other',
          component: Login,
          name: 'otherOptionsLogin',
          meta: {
            title: 'AULA Login',
          },
        },
        {
          path: 'municipal-idp',
          component: Login,
          name: 'municipalIdP',
          meta: {
            title: 'AULA Login',
          },
        },
      ],
    },
    {
      path: '/choosePortalRole',
      name: 'choosePortalRole',
      components: {
        default: ChoosePortalRole,
      },
      meta: {
        title: 'AULA - Choose profile',
      },
    },
    {
      path: '/noaccess',
      name: 'NoAccess',
      components: {
        default: NoAccess,
      },
      meta: {
        title: 'AULA - No access',
      },
    },
    {
      path: '/stepupfailure',
      name: 'StepUpFailure',
      components: {
        default: StepUpFailure,
      },
      meta: {
        title: 'AULA - Stepup failure',
      },
    },
    {
      path: '/onboarding',
      name: 'initOnboarding',
      components: {
        default: Onboarding,
      },
      meta: {
        title: 'Onboarding',
      },
    },
    {
      path: '/onboarding/:step',
      name: 'stepOnboarding',
      components: {
        default: Onboarding,
      },
      meta: {
        title: 'Onboarding',
      },
    },
    {
      path: '/widgets/:id',
      name: 'widget',
      components: {
        default: WidgetPage,
      },
    },
    {
      path: '/styleguide',
      components: {
        default: StyleGuidePage,
      },
      meta: 'menu',
    },
    {
      meta: {
        module: moduleTypes.OVERVIEW,
        menu: 'true',
        title: 'AULA',
      },
      path: '/overblik',
      name: 'overblik',
      components: {
        default: Posts,
        aside: PostsAside,
      },
    },
    {
      meta: {
        module: moduleTypes.OVERVIEW,
        title: 'Opret opslag',
      },
      path: '/overblik/opret',
      name: 'newpost',
      components: {
        default: PostNew,
        aside: PostsAside,
      },
    },
    {
      meta: {
        module: moduleTypes.OVERVIEW,
        title: 'Rediger et indlæg',
      },
      path: '/overblik/rediger/:id',
      name: 'editpost',
      component: PostNew,
    },
    {
      meta: {
        module: moduleTypes.OVERVIEW,
        title: 'Se opslag',
      },
      path: '/overblik/:id',
      name: 'singlepost',
      component: PostSingle,
    },
    {
      path: '/soeg',
      component: SearchResults,
      name: 'soeg',
      meta: {
        title: 'Søg',
      },
    },
    {
      path: '/soeg/medie',
      component: SearchResults,
      name: 'search-media-detail',
      meta: {
        title: 'Søg',
      },
    },
    {
      path: '/notifikation',
      component: NotificationSettings,
      name: 'notifikation',
      meta: {
        title: 'notifikation',
      },
    },
    {
      path: '/appindstillinger',
      component: AppSettings,
      name: 'appindstillinger',
      meta: {
        title: 'Appindstillinger',
      },
    },
    {
      path: '/privatlivspolitik',
      component: PrivacyPolicy,
      name: 'privacypolicy',
      meta: {
        title: 'Privatlivspolitik',
      },
    },
    {
      path: '/openPrivacy',
      component: OpenPrivacyPolicy,
      name: 'openprivacy',
      meta: {
        title: 'Openprivacy',
      },
    },
    {
      path: '/visbrugers/:id',
      components: {
        default: User,
      },
      name: 'userview',
      meta: {
        title: 'Kontaktoplysninger',
      },
    },
    // CALENDAR
    {
      meta: {
        module: moduleTypes.CALENDAR,
        title: 'Kalender',
        menu: 'true',
      },
      path: '/kalender',
      components: {
        default: Calendar,
        aside: CalendarAside,
      },
      name: 'calendar',
    },
    {
      meta: {
        module: moduleTypes.CALENDAR,
        title: 'Opret begivenhed',
      },
      path: '/kalender/opretbegivenhed',
      name: 'createEvent',
      components: {
        default: Calendar,
      },
    },
    {
      meta: {
        module: moduleTypes.CALENDAR,
        title: 'Rediger Begivenhed',
      },
      path: '/kalender/:eventId/redigerbegivenhed',
      name: 'editEvent',
      components: {
        default: Calendar,
        aside: CalendarAside,
      },
    },
    {
      meta: {
        module: moduleTypes.CALENDAR,
        title: 'Se begivenhed',
      },
      path: '/kalender/begivenhed/:eventId',
      name: 'viewEvent',
      components: {
        default: Calendar,
        aside: CalendarAside,
      },
    },
    {
      meta: {
        module: moduleTypes.CALENDAR,
        title: 'Se begivenhed',
      },
      path: '/kalender/begivenhed/:eventId/medie/:mediaId',
      name: 'viewMediaEvent',
      components: {
        default: Calendar,
        aside: CalendarAside,
      },
    },
    {
      meta: {
        module: moduleTypes.CALENDAR,
        title: 'Opret samtale',
      },
      path: '/kalender/opretsamtale',
      name: 'createMeetingEvent',
      components: {
        default: Calendar,
        aside: CalendarAside,
      },
    },
    {
      meta: {
        module: moduleTypes.CALENDAR,
        title: 'Rediger Samtale',
      },
      path: '/kalender/:eventId/redigersamtale',
      name: 'editMeetingEvent',
      components: {
        default: Calendar,
        aside: CalendarAside,
      },
    },
    {
      meta: {
        module: moduleTypes.CALENDAR,
        title: 'Se samtale',
      },
      path: '/kalender/samtale/:eventId',
      name: 'viewMeetingEvent',
      components: {
        default: Calendar,
        aside: CalendarAside,
      },
    },
    {
      meta: {
        module: moduleTypes.CALENDAR,
        title: 'Se samtale',
      },
      path: '/kalender/samtale/:eventId/medie/:mediaId',
      name: 'viewMediaMeetingEvent',
      components: {
        default: Calendar,
        aside: CalendarAside,
      },
    },
    {
      meta: {
        module: moduleTypes.CALENDAR,
        title: 'Ferieanmodning',
      },
      path: '/kalender/:id/ferieanmodning',
      name: 'viewVacationRequest',
      components: {
        default: Calendar,
        aside: CalendarAside,
      },
    },
    {
      meta: {
        module: moduleTypes.CALENDAR,
        title: 'Rediger Ferieanmodning',
      },
      path: '/kalender/:id/redigerferieanmodning',
      name: 'editVacationRequest',
      components: {
        default: Calendar,
        aside: CalendarAside,
      },
    },
    // GALLERY
    {
      meta: {
        module: moduleTypes.GALLERY,
        menu: 'true',
        title: 'Galleri',
      },
      path: '/galleri',
      name: 'gallery',
      components: {
        default: Gallery,
      },
    },
    {
      meta: {
        module: moduleTypes.GALLERY,
        title: 'Album detaljer',
      },
      path: '/galleri/:albumId',
      name: 'albumDetails',
      components: {
        default: AlbumDetail,
      },
    },
    {
      meta: {
        module: moduleTypes.GALLERY,
        title: 'Medier af dig',
      },
      path: '/galleri/minemedier',
      name: 'myMedias',
      components: {
        default: AlbumDetail,
      },
    },
    {
      meta: {
        module: moduleTypes.GALLERY,
        title: 'Medier for profile',
      },
      path: '/galleri/minemedier/:institutionProfileId',
      name: 'institutionProfileMedias',
      components: {
        default: AlbumDetail,
      },
    },
    {
      meta: {
        module: moduleTypes.GALLERY,
        menu: 'true',
        title: 'Opret album',
      },
      path: '/galleri/opret',
      name: 'createAlbum',
      components: {
        default: Gallery,
      },
    },
    {
      meta: {
        module: moduleTypes.GALLERY,
        title: 'Rediger album',
      },
      path: '/galleri/rediger/:albumId',
      name: 'editAlbum',
      components: {
        default: AlbumDetail,
      },
    },
    {
      meta: {
        module: moduleTypes.GALLERY,
        title: 'Tilføj medier',
      },
      path: '/galleri/tilfoej/:albumId',
      name: 'addMedia',
      components: {
        default: AlbumDetail,
      },
    },
    {
      meta: {
        module: moduleTypes.GALLERY,
        title: 'Medie detaljer',
      },
      path: '/galleri/medie/minemedier/:mediaId',
      name: 'myMediaDetails',
      components: {
        default: AlbumDetail,
      },
    },
    {
      meta: {
        module: moduleTypes.GALLERY,
        title: 'Medie detaljer',
      },
      path: '/galleri/medie/:mediaId/:albumId',
      name: 'mediaDetails',
      components: {
        default: AlbumDetail,
      },
    },
    {
      meta: {
        module: moduleTypes.MESSAGES,
      },
      path: '/beskeder/:id/medie/:mediaId',
      name: 'messageMediaDetails',
      components: {
        default: MessagePage,
      },
    },
    // DOCUMENTS
    {
      meta: {
        module: moduleTypes.DOCUMENTS,
        menu: 'true',
        title: 'Filer & dokumenter',
      },
      path: '/dokumenter',
      name: 'documents',
      components: {
        default: Documents,
      },
    },
    {
      meta: {
        module: moduleTypes.DOCUMENTS,
        menu: 'true',
        title: 'Sikker Fildeling',
      },
      path: '/dokumenter/sikre',
      name: 'documentsSecureList',
      component: DocumentsSecureList,
      children: [
        {
          path: 'nyt-dokument',
          components: { sidebar: DocumentDrawer },
          name: 'documentsSecureNew',
          props: {
            sidebar: { type: documentTypes.RICHDOCUMENT },
          },
          meta: {
            title: 'Opret sikker fil',
          },
        },
        {
          path: 'nyt-note',
          components: { sidebar: DocumentDrawer },
          name: 'createDocumentNote',
          props: {
            sidebar: { type: documentTypes.NOTE },
          },
          meta: {
            title: 'Opret note',
          },
        },
        {
          path: 'importer',
          components: { sidebar: DocumentDrawer },
          name: 'documentsSecureImport',
          props: {
            sidebar: { type: documentTypes.EXTERNAL },
          },
          meta: {
            module: moduleTypes.DOCUMENTS,
            menu: 'true',
            title: 'Importer fil',
          },
        },
        {
          path: ':documentType/:documentId/rediger',
          components: { sidebar: DocumentDrawer },
          name: 'documentsSecureEdit',
          props: {
            sidebar: route => ({
              id: parseInt(route.params.documentId),
              type: route.params.documentType,
              isEditMode: true,
            }),
          },
          meta: {
            module: moduleTypes.DOCUMENTS,
            menu: 'true',
            title: 'Rediger sikker fil',
          },
        },
        {
          path: ':documentType/:documentId',
          components: { sidebar: DocumentDrawer },
          name: 'viewDocument',
          props: {
            sidebar: route => ({
              id: parseInt(route.params.documentId),
              type: route.params.documentType,
            }),
          },
          meta: {
            module: moduleTypes.DOCUMENTS,
            menu: 'true',
            title: 'Se fil',
          },
        },
      ],
    },
    {
      meta: {
        module: moduleTypes.DOCUMENTS,
        menu: 'true',
        title: 'Fælles filer',
      },
      path: '/dokumenter/faelles',
      name: 'documentsCommonList',
      components: {
        default: DocumentsCommonList,
      },
    },
    {
      meta: {
        module: moduleTypes.DOCUMENTS,
        menu: 'true',
        title: 'Se revisionshistorik',
      },
      path: '/dokumenter/:documentType/:documentId/historie',
      name: 'documentsRevisionHistory',
      components: {
        default: DocumentRevisionHistory,
      },
      children: [
        {
          path: ':revisionId',
          name: 'revisionHistoryDocument',
          components: { sidebar: DocumentDrawer },
          props: {
            sidebar: route => ({
              id: parseInt(route.params.revisionId),
              isRevision: true,
              type: route.params.documentType,
            }),
          },
        },
      ],
    },
    // MESSAGES
    {
      path: '/beskeder',
      components: {
        default: MessagePage,
      },
      meta: {
        module: moduleTypes.MESSAGES,
        menu: 'true',
        title: 'Beskeder',
      },
      name: 'messages',
      children: [
        {
          path: 'opret',
          component: MessageNew,
          name: 'newMessage',
          meta: {
            title: 'Opret besked',
          },
        },
        {
          path: 'autosvar',
          component: MessageAutoreply,
          name: 'messageAutoreply',
          meta: {
            title: 'Rediger autosvar',
          },
        },
        {
          path: ':id',
          component: Messages,
          name: 'uniqueThread',
          meta: {
            title: 'Se samtale',
          },
        },
      ],
    },
    // GROUP ROUTES
    {
      path: '/gruppe/:groupId',
      components: {
        default: Posts,
        group: GroupPage,
      },
      name: 'group',
      meta: {
        title: 'Gruppeside',
      },
    },
    {
      path: '/gruppe/:groupId/admin',
      components: {
        default: Posts,
        group: GroupPage,
      },
      name: 'fromAdminGroup',
      meta: {
        title: 'Administration',
      },
    },
    {
      path: '/gruppe/:groupId/soeg',
      name: 'group-search-container',
      components: {
        default: Posts,
        group: GroupSearch,
      },
      meta: {
        title: 'Søg',
      },
    },
    {
      path: '/gruppe/:groupId/soeg/medie',
      name: 'group-search-media-detail',
      components: {
        default: SearchResults,
        group: GroupSearch,
      },
      meta: {
        title: 'Søg',
      },
    },
    {
      path: '/gruppe/:groupId/kalender',
      name: 'group-calendar',
      components: {
        default: Posts,
        group: Calendar,
      },
      meta: {
        title: 'Kalender',
      },
    },
    {
      path: '/gruppe/:groupId/kalender/begivenhed/:eventId',
      name: 'group-calendar-view-event',
      components: {
        default: Posts,
        group: Calendar,
      },
      meta: {
        title: 'Kalender',
      },
    },
    {
      path: '/gruppe/:groupId/kalender/begivenhed/:eventId/medie/:mediaId',
      name: 'group-calendar-view-media-event',
      components: {
        default: Posts,
        group: Calendar,
      },
      meta: {
        title: 'Kalender',
      },
    },
    {
      path: '/gruppe/:groupId/kalender/samtale/:eventId',
      name: 'group-calendar-view-meeting-event',
      components: {
        default: Posts,
        group: Calendar,
      },
      meta: {
        title: 'Kalender',
      },
    },
    {
      path: '/gruppe/:groupId/kalender/samtale/:eventId/medie/:mediaId',
      name: 'group-calendar-view-media-meeting-event',
      components: {
        default: Posts,
        group: Calendar,
      },
      meta: {
        title: 'Kalender',
      },
    },
    {
      path: '/gruppe/:groupId/kalender/opretbegivenhed',
      name: 'group-calendar-create-event',
      components: {
        default: Posts,
        group: Calendar,
      },
      meta: {
        title: 'Opret begivenhed',
      },
    },
    {
      path: '/gruppe/:groupId/kalender/:eventId/redigerbegivenhed',
      name: 'group-calendar-edit-event',
      components: {
        default: Posts,
        group: Calendar,
      },
      meta: {
        title: 'Rediger begivenhed',
      },
    },
    {
      path: '/gruppe/:groupId/kalender/opretsamtale',
      name: 'group-calendar-create-meeting-event',
      components: {
        default: Posts,
        group: Calendar,
      },
      meta: {
        title: 'Opret samtale',
      },
    },
    {
      path: '/gruppe/:groupId/kalender/:eventId/redigersamtale',
      name: 'group-calendar-edit-meeting-event',
      components: {
        default: Posts,
        group: Calendar,
      },
      meta: {
        title: 'Rediger samtale',
      },
    },
    {
      path: '/gruppe/:groupId',
      name: 'group-dashboard',
      meta: {
        title: 'Gruppe',
      },
    },
    {
      path: '/gruppe/:groupId/overblik',
      name: 'group-overview',
      components: {
        default: Posts,
        group: Posts,
      },
      meta: {
        title: 'Overblik',
      },
    },
    {
      path: '/gruppe/:groupId/overblik/:id',
      name: 'group-single-post',
      components: {
        default: Posts,
        group: PostSingle,
      },
      meta: {
        title: 'Se opslag',
      },
    },
    {
      path: '/gruppe/:groupId/overblik/opret',
      name: 'group-create-post',
      components: {
        default: Posts,
        group: PostNew,
      },
      meta: {
        title: 'Opret opslag',
      },
    },
    {
      path: '/gruppe/:groupId/overblik/rediger/:id',
      name: 'group-edit-post',
      components: {
        default: Posts,
        group: PostNew,
      },
      meta: {
        title: 'Rediger opslag',
      },
    },
    {
      path: '/gruppe/:groupId/galleri',
      name: 'group-gallery',
      components: {
        default: Posts,
        group: Gallery,
      },
      meta: {
        title: 'Galleri',
      },
    },
    {
      path: '/gruppe/:groupId/galleri/opret',
      name: 'group-create-album',
      components: {
        default: Posts,
        group: Gallery,
      },
      meta: {
        title: 'Opret galleri',
      },
    },
    {
      path: '/gruppe/:groupId/galleri/rediger/:albumId',
      name: 'group-edit-album',
      components: {
        default: Posts,
        group: Gallery,
      },
      meta: {
        title: 'Rediger galleri',
      },
    },
    {
      path: '/gruppe/:groupId/galleri/tilfoej/:albumId',
      name: 'group-add-media',
      components: {
        default: Posts,
        group: Gallery,
      },
      meta: {
        title: 'Tilføj medier',
      },
    },
    {
      path: '/gruppe/:groupId/galleri/:albumId',
      name: 'group-album-details',
      components: {
        default: Posts,
        group: AlbumDetail,
      },
      meta: {
        title: 'Album detaljer',
      },
    },
    {
      path: '/gruppe/:groupId/medie/:mediaId/:albumId',
      name: 'group-media-details',
      components: {
        default: Posts,
        group: AlbumDetail,
      },
      meta: {
        title: 'Medie detaljer',
      },
    },
    {
      path: '/gruppe/:groupId/administration',
      name: 'group-administration',
      components: {
        default: Posts,
        group: GroupAdministration,
      },
      meta: {
        title: 'Administration',
      },
    },
    {
      path: '/gruppe/:groupId/medlemmer',
      name: 'group-members',
      components: {
        default: Posts,
        group: GroupMembers,
      },
      meta: {
        title: 'Medlemmer',
      },
    },
    {
      path: '/gruppe/:groupId/widget/:id',
      name: 'groupWidget',
      components: {
        default: Posts,
        group: WidgetGroup,
      },
    },
    {
      path: '/kontakter',
      components: {
        default: Contacts,
      },
      name: 'contacts',
      meta: {
        title: 'Kontakter',
      },
    },
    {
      path: '/stamkortoplysninger',
      components: {
        default: PersonalReferenceData,
      },
      name: 'personalReferenceData',
      meta: {
        title: 'Stamkortoplysninger',
      },
    },
    {
      path: '/stamkort',
      component: AdditionalProfileData,
      name: 'additional-profile-data',
      meta: {
        title: 'Stamkortoplysninger',
      },
    },
    {
      path: '/unsubscribe',
      name: 'unsubscribe',
      meta: {
        title: 'Afmeld notifikationer',
      },
    },
    {
      path: '*',
      redirect: '/overblik',
    },
  ],
});
