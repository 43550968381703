












































import { types } from '../../store/types/types.js';
import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';
import Avatar from '../../../shared/components/Avatar.vue';
import UserProfile from './UserProfile.vue';
import { InstitutionProfileModel } from '../../../shared/models/institutionProfile.model';

export default {
  components: {
    UserProfile,
    Avatar,
  },
  data: function () {
    return {
      userProfile: null as InstitutionProfileModel,
    };
  },
  computed: {
    ...mapGetters({
      isPreviewMode: types.GET_IS_PREVIEW_MODE,
      profile: types.GET_CURRENT_PROFILE,
      showGroupsDropdown: types.GET_SHOW_GROUPS_DROPDOWN,
      showSearchDropdown: types.GET_SHOW_SEARCH_DROPDOWN,
      showProfileDropdown: types.GET_SHOW_PROFILE_DROPDOWN,
      isLoadingProfileContext: types.GET_IS_LOADING_PROFILE_CONTEXT,
    }),
    isClickedCurrentTab: function () {
      return this.showProfileDropdown || (!this.showGroupsDropdown && !this.showSearchDropdown);
    },
  },
  methods: {
    ...mapActions({
      toggleHeaderDropdown: types.TOGGLE_HEADER_DROPDOWN,
    }),
  },
};
