<template>
  <div class="search-result-container page-layout-padding" data-layout="group-search">
    <template v-if="numberOfResults.countTotal > 0">
      <div class="search-filter-container hide-tablet-up">
        <template v-if="numberOfResults.countEvents > 0">
          <div
            class="header-dropdown-item-search"
            :class="{ active: showResult === docTypes.EVENT }"
            role="button"
            tabindex="0"
            @click="onSearchOptionClicked((filterValue = docTypes.EVENT))"
            @keydown.enter="onSearchOptionClicked((filterValue = docTypes.EVENT))"
          >
            <b-row>
              <b-col>
                {{ 'SEARCH_FILTER_EVENTS_LABEL' | fromTextKey }}
                <span class="number-of-results-mobile">({{ numberOfResults.countEvents }})</span>
              </b-col>
            </b-row>
          </div>
        </template>
        <template v-if="numberOfResults.countPosts > 0">
          <div
            class="header-dropdown-item-search"
            :class="{ active: showResult === docTypes.POST }"
            role="button"
            tabindex="0"
            @click="onSearchOptionClicked((filterValue = docTypes.POST))"
            @keydown.enter="onSearchOptionClicked((filterValue = docTypes.POST))"
          >
            <b-row>
              <b-col>
                {{ 'SEARCH_FILTER_POSTS_LABEL' | fromTextKey }}
                <span class="number-of-results-mobile">({{ numberOfResults.countPosts }})</span>
              </b-col>
            </b-row>
          </div>
        </template>
        <template v-if="numberOfResults.countMedia > 0">
          <div
            class="header-dropdown-item-search"
            :class="{ active: showResult === docTypes.MEDIA }"
            role="button"
            tabindex="0"
            @click="onSearchOptionClicked((filterValue = docTypes.MEDIA))"
            @keydown.enter="onSearchOptionClicked((filterValue = docTypes.MEDIA))"
          >
            <b-row>
              <b-col>
                {{ 'SEARCH_FILTER_MEDIA_LABEL' | fromTextKey }}
                <span class="number-of-results-mobile">({{ numberOfResults.countMedia }})</span>
              </b-col>
            </b-row>
          </div>
        </template>
      </div>
    </template>
    <div class="search-result-header-mobile hide-tablet-up">
      <b-row>
        <b-col cols="8">
          {{ 'SEARCH_RESULT_MOBILE_LABEL' | fromTextKey }}
          <strong>{{ searchString }}</strong>
        </b-col>
      </b-row>
    </div>
    <div class="search-result-header hide-tablet-down">
      <strong>{{ 'SEARCH_RESULT_LABEL' | fromTextKey }}</strong>
      {{ searchString }}
    </div>
    <div class="header-dropdown-menu-search hide-tablet-down">
      <div class="searchFilterContainer">
        <template v-if="numberOfResults.countTotal > 0">
          <div class="search-filter-container">
            <div class="search-filter">
              {{ 'SEARCH_FILTER_LABEL' | fromTextKey }} ({{ numberOfResults.countTotal }})
            </div>
            <div
              v-if="numberOfResults.countEvents > 0"
              class="header-dropdown-item-search"
              :class="{ active: showResult === docTypes.EVENT }"
              role="button"
              tabindex="0"
              @click="onSearchOptionClicked((filterValue = docTypes.EVENT))"
              @keydown.enter="onSearchOptionClicked((filterValue = docTypes.EVENT))"
            >
              <b-row class="search-filter-results">
                {{ 'SEARCH_FILTER_EVENTS_LABEL' | fromTextKey }}
                <div class="text-right number-of-results">
                  {{ numberOfResults.countEvents }}
                </div>
              </b-row>
            </div>
            <div
              v-if="numberOfResults.countPosts > 0"
              class="header-dropdown-item-search"
              :class="{ active: showResult === docTypes.POST }"
              role="button"
              tabindex="0"
              @click="onSearchOptionClicked((filterValue = docTypes.POST))"
              @keydown.enter="onSearchOptionClicked((filterValue = docTypes.POST))"
            >
              <b-row class="search-filter-results">
                {{ 'SEARCH_FILTER_POSTS_LABEL' | fromTextKey }}
                <div class="text-right number-of-results">
                  {{ numberOfResults.countPosts }}
                </div>
              </b-row>
            </div>
            <div
              v-if="numberOfResults.countMedias > 0"
              class="header-dropdown-item-search"
              :class="{ active: showResult === docTypes.MEDIA }"
              role="button"
              tabindex="0"
              @click="onSearchOptionClicked((filterValue = docTypes.MEDIA))"
              @keydown.enter="onSearchOptionClicked((filterValue = docTypes.MEDIA))"
            >
              <b-row class="search-filter-results">
                {{ 'SEARCH_FILTER_MEDIA_LABEL' | fromTextKey }}
                <div class="text-right number-of-results">
                  {{ numberOfResults.countMedias }}
                </div>
              </b-row>
            </div>
          </div>
        </template>
      </div>
    </div>
    <user-details-portal v-if="Object.keys(selectedUser).length > 0" />
    <ObservingContainer :options="observerOptions" class="pb-4">
      <template v-for="result in searchResults">
        <search-result-event-item
          v-if="result.docType === docTypes.EVENT"
          :event-item="result"
          parent-source="GROUP"
          :search-string="searchString"
          :valid-group-modules="validGroupModules"
          :number-of-menu-items="numberOfMenuItems"
        />
        <search-result-post-item v-if="result.docType === docTypes.POST" :post="result" parent="group" />
        <search-result-media-item
          v-if="result.docType === docTypes.MEDIA"
          parent="group"
          :media="result"
          :search-string="searchString"
          @openMedia="openMedia"
        />
      </template>
      <AulaSpinner v-if="isLoading" />
      <ObservedTarget v-if="searchResults.length" @onIntersecting="onBottomReached" />
    </ObservingContainer>
    <media-detail
      v-if="
        showResult == docTypes.MEDIA &&
        ($route.path.includes('soeg/medie') || $route.name == 'group-search-media-detail')
      "
      :album="mediaDetail"
      :parent="parentTypes.SEARCH"
    />
  </div>
</template>

<script>
import { types } from '../../store/types/types';
import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';
import UserDetailsPortal from '../../../shared/components/UserDetailsPortal.vue';
import SearchResultEventItem from '../searches/SearchResultEventItem';
import SearchResultPostItem from '../searches/SearchResultPostItem';
import SearchResultMediaItem from '../searches/SearchResultMediaItem';
import MediaDetail from '../gallery/MediaDetail';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { docTypes } from '../../../shared/enums/docTypes';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import ObservingContainer from '../../../shared/libs/intersection-observer/components/Container.vue';
import ObservedTarget from '../../../shared/libs/intersection-observer/components/Target.vue';

export default {
  props: {
    numberOfMenuItems: { type: Number, default: 4 },
  },
  data: function () {
    return {
      portalRoles: portalRoles,
      docTypes: docTypes,
      parentTypes: parentTypes,
      mediaDetail: [],
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      group: types.GET_ACTIVE_GROUP,
      searchString: types.GET_SEARCH_STRING_GROUP,
      searchLimit: types.GET_SEARCH_LIMIT_GROUP,
      searchResults: types.GET_GROUP_SEARCH_RESULTS,
      numberOfResults: types.GET_NUMBER_OF_RESULTS_GROUP,
      showResult: types.GET_SHOW_RESULT_GROUP,
      selectedUser: types.GET_SELECTED_PROFILE,
      hasMoreResults: types.GET_LOAD_MORE_GROUP,
    }),
    observerOptions() {
      return { rootMargin: '50%' };
    },
    validGroupModules() {
      if (this.group) {
        return this.group.validGroupModules;
      } else {
        return [];
      }
    },
  },
  methods: {
    ...mapActions({
      setShowResult: types.SET_SHOW_RESULT_GROUP,
      loadMoreResults: types.LOAD_MORE_GROUP_RESULTS,
    }),
    onBottomReached() {
      if (this.isLoading || !this.hasMoreResults) {
        return;
      }
      this.getMoreSearchResults();
    },
    openMedia(media) {
      media.album = { name: media.albumTitle };
      media.file = { url: media.thumbnailUrl };
      media.currentUserCanDelete = false;
      media.currentUserCanEditMetaData = false;
      media.currentUserCanEditTags = false;
      this.mediaDetail = [media];
      this.$router.push({
        name: 'group-search-media-detail',
        params: { groupId: this.group.id },
      });
    },
    async getMoreSearchResults() {
      this.isLoading = true;
      await this.loadMoreResults();
      this.isLoading = false;
    },
    async onSearchOptionClicked(option) {
      this.isLoading = true;
      await this.setShowResult(option);
      this.isLoading = false;
    },
  },
  components: {
    ObservedTarget,
    ObservingContainer,
    UserDetailsPortal,
    SearchResultEventItem,
    SearchResultPostItem,
    SearchResultMediaItem,
    MediaDetail,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.header-dropdown-menu-search {
  width: 243px;
  top: 0 !important;
  .search-filter {
    margin: 22px 0 13px;
  }
  .search-filter-container {
    padding: 10px;
  }
}

.search-result-text-col {
  @include breakpoint-lg() {
    width: auto;
  }
}

.search-result-right-col-text {
  @include breakpoint-lg() {
    padding: 52px 25px 0;
    font-size: 15px;
  }
}

.search-result-group-button {
  font-size: small;
  @include breakpoint-lg() {
    font-size: 15px;
  }
}

.search-filter-results {
  justify-content: space-between;
  margin-left: unset;
  margin-right: unset;
}
</style>
