<template>
  <aula-modal
    ref="stepUpNotificationPopup"
    :header-text="titleMessage"
    :show-cancel="isAuthorized"
    :disable-close="true"
    :ok-text="submitButtonLabel"
    @cancelClicked="cancelStepUpNotification(true)"
    @hidden="cancelStepUpNotification"
    @okClicked="openStepUpLogin"
  >
    <span v-html="getStepUpMessage()" />
  </aula-modal>
</template>
<script>
import { types } from '../../store/types/types';
import { mapMutations, mapGetters } from 'vuex';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import { profileUtil } from '../../../shared/utils/profileUtil';

export default {
  data: function () {
    return {
      parentTypes: parentTypes,
      contentMessage: 'MESSAGE_STEP_UP_NOTIFICATION_AUTHORIZED',
      titleMessage: 'WARNING',
      submitButtonLabel: 'BUTTON_YES',
      isAuthorized: true,
    };
  },
  computed: {
    ...mapGetters({
      stepUpNotification: types.GET_ACTIVE_STEP_UP_NOTIFICATION,
      profile: types.GET_CURRENT_PROFILE,
      isStepUp: types.GET_GLOBAL_STEPPED_UP,
    }),
    youngerThan15() {
      return !profileUtil.getCanUseSecureLoginServices({ profile: this.profile });
    },
  },
  methods: {
    ...mapMutations({
      setStepUpNotification: types.MUTATE_SET_NOTIFICATION_STEP_UP,
    }),
    openStepUpLogin() {
      this.$refs.stepUpNotificationPopup.hide();
      if (!this.youngerThan15) {
        localStorage.setItem('redirectedurlafterstepup', this.stepUpNotification.redirectedUrl);
        localStorage.setItem('redirectedurlbeforestepup', window.location.href);
        window.location.href = '/auth/stepup.php';
      } else {
        this.cancelStepUpNotification(true);
      }
    },
    cancelStepUpNotification(isCancelled = false) {
      if (isCancelled) {
        if (this.stepUpNotification.cancelUrl != null) {
          this.$router.push(this.stepUpNotification.cancelUrl);
        }
        this.setStepUpNotification({
          showStepUpNotification: false,
          redirectedUrl: null,
          cancelUrl: null,
        });
      }
    },
    getStepUpMessage() {
      let stepUpMessage = '';
      if (this.stepUpNotification.showStepUpForOnboarding) {
        this.submitButtonLabel = 'OK';
        this.titleMessage = 'MESSAGE_STEP_UP_TITLE_FOR_ONBOARDING';
        stepUpMessage =
          this.$options.filters.fromTextKey('MESSAGE_STEP_UP_FOR_ONBOARDING_1') +
          '</br>' +
          this.$options.filters.fromTextKey('MESSAGE_STEP_UP_FOR_ONBOARDING_2');
      } else {
        if (this.stepUpNotification.showStepUpMessage) {
          stepUpMessage = this.$options.filters.fromTextKey(this.stepUpNotification.showStepUpMessage);
        } else {
          if (this.isAuthorized) {
            if (this.stepUpNotification.parent == parentTypes.MESSAGES) {
              stepUpMessage =
                this.$options.filters.fromTextKey('MESSAGE_STEP_UP_NOTIFICATION_AUTHORIZED_MESSAGE') +
                '</br>' +
                this.$options.filters.fromTextKey('MESSAGE_STEP_UP_NOTIFICATION_AUTHORIZED_2');
            } else {
              stepUpMessage =
                this.$options.filters.fromTextKey('MESSAGE_STEP_UP_NOTIFICATION_AUTHORIZED_1') +
                '</br>' +
                this.$options.filters.fromTextKey('MESSAGE_STEP_UP_NOTIFICATION_AUTHORIZED_2');
            }
          } else {
            if (this.youngerThan15) {
              this.submitButtonLabel = 'OK';
              stepUpMessage = this.$options.filters.fromTextKey('MESSAGE_UNDER_FIFTEEN_WARNING');
            } else {
              stepUpMessage = this.$options.filters.fromTextKey('MESSAGE_STEP_UP_NOTIFICATION_UNAUTHORIZED');
            }
          }
        }
      }
      return stepUpMessage;
    },
  },
  watch: {
    stepUpNotification() {
      if (this.stepUpNotification.showStepUpNotification) {
        if (!this.isStepUp) {
          if (this.youngerThan15 || this.stepUpNotification.showStepUpForOnboarding) {
            this.isAuthorized = false;
          }
          this.$refs.stepUpNotificationPopup.show();
        }
      } else {
        this.$refs.stepUpNotificationPopup.hide();
      }
    },
  },
};
</script>
