





import AulaImg from './AulaImg.vue';
import { iconClassEnum } from '../enums/iconClassEnum';
export default {
  components: { AulaImg },
  props: {
    url: { type: String, default: '' },
    title: { type: String, default: '' },
  },
  data() {
    return {
      hasImage: false,
    };
  },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
  },
  mounted() {
    this.hasImage = this.url != null;
  },
};
