<template>
  <div class="onboarding-step1" :class="{ 'has-warning': $parent.showWarningAcceptPolicy }">
    <div class="step1-content scrollbar">
      <aula-spinner v-if="isLoading" />
      <template v-else>
        <h1 class="step1-title">
          {{ 'POLICY_TITLE' | fromTextKey }}
        </h1>
        <template v-if="dataPolicy.changesDescription && dataPolicy.changesDescription.html">
          <div class="privacy-policy-changes">
            <div v-html="dataPolicy.changesDescription.html" />
          </div>
        </template>
        <p>{{ 'POLICY_SUMMARY_1_1' | fromTextKey }}</p>

        <b>{{ 'PRIVATE_POLICY' | fromTextKey | firstLetterUppercase }}</b>
        <p>
          {{ 'POLICY_SUMMARY_2_1' | fromTextKey }}
        </p>
        <p>
          {{ 'POLICY_SUMMARY_3_1' | fromTextKey }}
          <a
            v-for="policy in getPersonalDataPolicies"
            :key="policy.institutionCode"
            :href="policy.commonFile.file.file.url"
            class="policy-link"
            download
            target="_blank"
          >
            {{ policy.commonFile.title }}
            ({{ policy.institution.institutionName }}), </a
          >{{ 'POLICY_SUMMARY_3_2' | fromTextKey }}
        </p>
        <b>{{ 'POLICY_TITLE_DATAEHTICS' | fromTextKey }}</b>
        <p>
          {{ 'POLICY_SUMMARY_4_1' | fromTextKey }}
          <a
            href="#"
            @click="
              $refs.privacyDataEhtics.show();
              return false;
            "
            >{{ 'AULA_DATA_EHTICS' | fromTextKey }}</a
          >
          {{ 'POLICY_SUMMARY_4_2' | fromTextKey }}
        </p>
      </template>
    </div>
    <div class="accept-container">
      <b-form-checkbox v-model="$parent.isPolicyAccepted" class="accept" @input="acceptChange">
        {{ 'READ_AND_AGREE' | fromTextKey }}
      </b-form-checkbox>
      <b-alert variant="danger" class="warning" :show="$parent.showWarningAcceptPolicy">
        {{ 'WARNING_ACCEPT_THE_POLICY' | fromTextKey }}
      </b-alert>
    </div>
    <aula-modal
      ref="privacyPolicyModal"
      :disable-portal="true"
      :show-ok="false"
      :show-cancel="isMobile"
      header-text="PRIVATE_POLICY"
      @cancelClicked="$refs.privacyPolicyModal.hide()"
    >
      <div v-if="dataPolicy.content && dataPolicy.content.html" v-html="dataPolicy.content.html" />
    </aula-modal>
    <aula-modal
      ref="privacyDataEhtics"
      :disable-portal="true"
      :show-ok="false"
      :show-cancel="isMobile"
      header-text="AULA_DATA_EHTICS"
      @cancelClicked="$refs.privacyDataEhtics.hide()"
    >
      <div class="p-4" v-html="Vue.filter('fromTextKey')('AULA_DATA_ETHICS_DETAILS')" />
    </aula-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import { types } from '../../store/types/types';
import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';

export default {
  data: function () {
    return {
      isLoading: false,
      Vue: Vue,
    };
  },
  computed: {
    ...mapGetters({
      getPersonalDataPolicies: types.GET_PERSONAL_DATA_POLICIES,
      dataPolicy: types.GET_DATA_POLICY,
      isMobile: types.GET_IS_MOBILE,
    }),
  },
  methods: {
    acceptChange() {
      if (!this.$parent.isPolicyAccepted) {
        this.$parent.showWarningAcceptPolicy = true;
      } else {
        this.$parent.showWarningAcceptPolicy = false;
      }
    },
    ...mapActions({
      loadPersonalDataPolicies: types.LOAD_PERSONAL_DATA_POLICIES,
      loadDataPolicy: types.LOAD_DATA_POLICY,
    }),
  },
  mounted() {
    this.isLoading = true;
    this.loadDataPolicy();
    if (!this.getPersonalDataPolicies) {
      this.loadPersonalDataPolicies().then(() => {
        this.isLoading = false;
      });
    } else {
      this.isLoading = false;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.onboarding-step1 {
  .accept-container {
    @include breakpoint-lg-down() {
      width: 100%;
      background: $color-primary-base;
      position: fixed;
      left: 0;
      bottom: 0;
      padding: 15px 20px;
      color: $color-white;
    }
  }
  /deep/ a {
    font-weight: bold;
  }
  height: 100%;
  @include breakpoint-lg-down() {
    height: auto;
  }
  .privacy-policy-changes {
    background: $color-grey-base;
    padding: 10px;
    margin-bottom: 20px;
  }
  .step1-title {
    font-size: 36px;
    font-weight: 900;
    line-height: 40px;
    margin-bottom: 30px;
    @include breakpoint-lg-down() {
      font-size: 24px;
    }
  }
  .step1-content {
    overflow-x: auto;
    height: 95%;
    margin-bottom: 20px;
    padding-right: 20px;
    @include breakpoint-lg-down() {
      height: 90%;
    }
    @include breakpoint-lg-down() {
      height: auto;
    }
  }
  .scrollbar {
    @include breakpoint-lg-down() {
      overflow-y: hidden;
    }
  }
  a {
    .theme-employee & {
      color: $color-primary-dark-employee;
    }
    .theme-guardian & {
      color: $color-primary-dark-guardian;
    }
    .theme-child & {
      color: $color-primary-dark-child;
    }
    .theme-admin & {
      color: $color-primary-dark-admin;
    }

    text-decoration: underline;
  }
  &.has-warning {
    .step1-content {
      height: 87% !important;
      @include breakpoint-lg-down() {
        height: 82% !important;
      }
    }
    .accept-container {
      @include breakpoint-lg-down() {
        padding-left: 30px !important;
        padding-top: 10px;
      }
      .warning {
        margin-bottom: 5px;
        padding-bottom: 0px;
        margin-left: -2px;
      }
    }
  }
}
</style>
