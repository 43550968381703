<template>
  <div id="aula-modals">
    <b-modal :visible="browserCheck" centered ok-only>
      <div slot="modal-header" class="w-100">
        <a class="modal-header-text"> {{ 'BROWSER_CHECK_HEADER' | fromTextKey }}</a>
      </div>
      <div class="modal-content-text">
        {{ 'BROWSER_CHECK_TEXT_1' | fromTextKey }}
        <br />
        {{ 'BROWSER_CHECK_TEXT_2' | fromTextKey }}
        <ul>
          <li>
            <a class="browser-link" href="https://www.google.dk/chrome/browser/desktop/index.html">
              {{ 'BROWSER_CHROME_DOWNLOAD' | fromTextKey }}
              <i class="icon-Aula_forward-arrow"
            /></a>
          </li>
          <li>
            <a class="browser-link" href="https://www.microsoft.com/da-dk/windows/microsoft-edge">
              {{ 'BROWSER_EDGE_DOWNLOAD' | fromTextKey }}
              <i class="icon-Aula_forward-arrow"
            /></a>
          </li>
          <li>
            <a class="browser-link" href="https://support.apple.com/downloads/safari">
              {{ 'BROWSER_SAFARI_DOWNLOAD' | fromTextKey }}
              <i class="icon-Aula_forward-arrow"
            /></a>
          </li>
          <li>
            <a class="browser-link" href="https://www.mozilla.org/da/firefox/new/">
              {{ 'BROWSER_FIREFOX_DOWNLOAD' | fromTextKey }}
              <i class="icon-Aula_forward-arrow"
            /></a>
          </li>
        </ul>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { types } from '../../store/types/types';
import { mapGetters } from 'vuex';
export default {
  data: function () {
    return {};
  },
  computed: {
    ...mapGetters({
      browserCheck: types.GET_BROWSER_CHECK,
      profile: types.GET_CURRENT_PROFILE,
    }),
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.browser-link {
  color: #2091a2;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }

  .icon-Aula_forward-arrow {
    font-size: 10px;
  }
}
</style>
