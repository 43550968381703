<template>
  <ContactsFragmentContactMetadata :profile="profile" :show-parent-custody="showParentCustody">
    <p class="m-0">
      {{ profile | displayProfileName }}
      ({{ relationText }})
    </p>
  </ContactsFragmentContactMetadata>
</template>
<script lang="js">
import ContactsFragmentContactMetadata from "./ContactsFragmentContactMetadata";

export default {
  name: 'ContactsFragmentDisplayProfileRelationName',
  components: {ContactsFragmentContactMetadata},
  props: {
    profile: {
      type: Object,
      required: true,
    },
    showParentCustody: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    relationText() {
      let text = this.profile.relation;
      if (text == null) {
        text = this.$options.filters.fromTextKey('GROUP_HOME');
      }
      return text;
    },
  },
}
</script>
