<template>
  <b-row>
    <b-col cols="9">
      <span v-if="isInboxFolder">
        <span> {{ 'MESSAGE_ADVANCED_SEARCH_INBOX' | fromTextKey }} </span>
        <b> {{ 'MESSAGE_TOOLBAR_INBOX' | fromTextKey }} </b>
        <span v-if="isSubFolder">
          {{ 'MESSAGE_ADVANCED_SEARCH_INBOX_2' | fromTextKey }}
          <b> {{ subFolderName }} </b>
        </span>
        <span v-else>
          {{ 'MESSAGE_ADVANCED_SEARCH_INBOX_1' | fromTextKey }}
        </span>
      </span>
      <span v-else>
        <span> {{ 'MESSAGE_ADVANCED_SEARCH_COMMON_INBOX' | fromTextKey }} </span>
        <b> {{ folderName }} </b>
        <span v-if="isSubFolder">
          {{ 'MESSAGE_ADVANCED_SEARCH_COMMON_INBOX_2' | fromTextKey }}
          <b> {{ subFolderName }} </b>
        </span>
        <span v-else>
          {{ 'MESSAGE_ADVANCED_SEARCH_COMMON_INBOX_1' | fromTextKey }}
        </span>
      </span>
    </b-col>
    <b-col cols="3" class="d-flex justify-content-end align-items-start">
      <b-button variant="link" class="text-decoration-none" @click="clearAllInput">
        <i class="icon-Aula_close mr-1" />
        <span class="text-decoration-underline">{{ 'MESSAGE_ADVANCED_SEARCH_CLEAR_ALL' | fromTextKey }}</span>
      </b-button>
    </b-col>
    <b-col class="col-12 col-md-6">
      <MessageAdvancedSearchInput
        :value="query"
        :placeholder="'MESSAGE_ADVANCED_SEARCH_KEYWORD_PLACEHOLDER' | fromTextKey"
        @input="updateQuery"
      >
        <template #label>
          {{ 'MESSAGE_ADVANCED_SEARCH_KEYWORD' | fromTextKey }}
        </template>
      </MessageAdvancedSearchInput>
    </b-col>
    <b-col class="col-12 col-md-6">
      <MessageAdvancedSearchInput
        :value="subject"
        :placeholder="'MESSAGE_ADVANCED_SEARCH_SUBJECT_PLACEHOLDER' | fromTextKey"
        @input="updateSubject"
      >
        <template #label>
          {{ 'MESSAGE_ADVANCED_SEARCH_SUBJECT' | fromTextKey }}
        </template>
      </MessageAdvancedSearchInput>
    </b-col>
    <b-col class="col-12 col-md-6">
      <MessageAdvancedSearchInput
        :value="messageContent"
        :placeholder="'MESSAGE_ADVANCED_SEARCH_CONTENT_PLACEHOLDER' | fromTextKey"
        @input="updateMessageContent"
      >
        <template #label>
          {{ 'MESSAGE_ADVANCED_SEARCH_CONTENT' | fromTextKey }}
        </template>
      </MessageAdvancedSearchInput>
    </b-col>
    <b-col class="col-12 col-md-6">
      <MessageAdvancedSearchSelect :value="filter" :options="filterOptions" @input="updateSelectedFilter">
        <template #select>
          {{ 'MESSAGE_ADVANCED_SEARCH_FILTER' | fromTextKey }}
        </template>
        <template #option="{ value, textKey }">
          <aula-option :value="value" :label="textKey | fromTextKey" />
        </template>
      </MessageAdvancedSearchSelect>
    </b-col>
    <b-col class="col-12 col-md-6">
      <MessageAdvancedSearchDatePicker :value="fromDate" :picker-options="fromDatePickerOption" @input="updateFromDate">
        <template #date-picker>
          {{ 'MESSAGE_LABEL_FROM' | fromTextKey }}
        </template>
      </MessageAdvancedSearchDatePicker>
    </b-col>
    <b-col class="col-12 col-md-6">
      <MessageAdvancedSearchDatePicker :value="toDate" :picker-options="toDatePickerOption" @input="updateToDate">
        <template #date-picker>
          {{ 'MESSAGE_LABEL_TO' | fromTextKey }}
        </template>
      </MessageAdvancedSearchDatePicker>
    </b-col>
    <b-col class="col-12 col-md-6">
      <MessageAdvancedSearchAulaSearch
        :existing-participants="threadCreators"
        :show-search-icon="true"
        :doc-types-api="[docTypes.PROFILE, docTypes.COMMON_INBOX]"
        :portal-roles="[portalRoles.EMPLOYEE, portalRoles.CHILD, portalRoles.GUARDIAN]"
        :is-multiple-institution-profile-allowed="false"
        :close-dropdown-after-select="false"
        :allow-all-common-inbox="true"
        :include-self="true"
        :from-module="moduleTypes.MESSAGES"
        :placeholder-textkey="'MESSAGE_ADVANCED_SEARCH_PEOPLE_PLACEHOLDER'"
        @change="updateThreadCreators"
      >
        <template #aula-search>
          {{ 'MESSAGE_ADVANCED_SEARCH_CREATOR' | fromTextKey }}
        </template>
      </MessageAdvancedSearchAulaSearch>
    </b-col>
    <b-col class="col-12 col-md-6">
      <MessageAdvancedSearchAulaSearch
        :existing-participants="participants"
        :show-search-icon="true"
        :doc-types-api="[docTypes.PROFILE, docTypes.COMMON_INBOX]"
        :portal-roles="[portalRoles.EMPLOYEE, portalRoles.CHILD, portalRoles.GUARDIAN]"
        :is-multiple-institution-profile-allowed="false"
        :close-dropdown-after-select="false"
        :allow-all-common-inbox="true"
        :include-self="true"
        :from-module="moduleTypes.MESSAGES"
        :placeholder-textkey="'MESSAGE_ADVANCED_SEARCH_PEOPLE_PLACEHOLDER'"
        @change="updateParticipants"
      >
        <template #aula-search>
          {{ 'MESSAGE_SENT_TO' | fromTextKey }}
        </template>
      </MessageAdvancedSearchAulaSearch>
    </b-col>
    <b-col>
      <div class="mt-2 mt-lg-4 mb-4 mb-lg-0">
        <b-form-checkbox :checked="withAttachments" @change="updateWithAttachments">
          {{ 'MESSAGE_ADVANCED_SEARCH_ATTACHMENT' | fromTextKey }}
        </b-form-checkbox>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import MessageAdvancedSearchInput from './MessageAdvancedSearchInput';
import MessageAdvancedSearchSelect from './MessageAdvancedSearchSelect';
import MessageAdvancedSearchDatePicker from './MessageAdvancedSearchDatePicker';
import MessageAdvancedSearchAulaSearch from './MessageAdvancedSearchAulaSearch';
import { moduleTypes } from '../../enums/moduleTypes';
import { docTypes } from '../../enums/docTypes';
import { portalRoles } from '../../enums/portalRoles';
import { messageFilterTypes } from '../../enums/messageFilterTypes';
import { isAfter, isDateBetween, now } from '../../utils/dateUtil';
export default {
  name: 'MessageAdvancedSearch',
  components: {
    MessageAdvancedSearchAulaSearch,
    MessageAdvancedSearchDatePicker,
    MessageAdvancedSearchSelect,
    MessageAdvancedSearchInput,
  },
  props: {
    query: { required: true, type: String },
    subject: { required: true, type: String },
    messageContent: { required: true, type: String },
    filter: { required: true, type: String },
    fromDate: { required: false, type: Date, default: () => null },
    toDate: { required: false, type: Date, default: () => null },
    threadCreators: { required: true, type: Array },
    participants: { required: true, type: Array },
    withAttachments: { required: true, type: Boolean },
    folders: { required: true, type: Object },
  },
  data: function () {
    return {
      fromDatePickerOption: undefined,
      toDatePickerOption: undefined,
      moduleTypes,
      docTypes,
      portalRoles,
      filterOptions: messageFilterTypes,
    };
  },
  computed: {
    subFolderName() {
      let subFolderName;
      if (this.folders.folderName && this.folders.commonInboxName !== this.folders.folderName) {
        subFolderName = this.folders.folderName;
      }
      return subFolderName;
    },
    folderName() {
      let folderName = this.folders.folderName;
      if (this.folders.commonInboxName) {
        folderName = this.folders.commonInboxName;
      }
      return folderName;
    },
    isInboxFolder() {
      if (this.folders.commonInboxName === this.folders.folderName && this.folders.commonInboxName) {
        return false;
      } else if (this.folders.folderName && !this.folders.commonInboxName) {
        return true;
      } else if (!this.folders.commonInboxName) {
        return true;
      }
      return false;
    },
    isSubFolder() {
      if (this.folders.commonInboxName === this.folders.folderName && this.folders.commonInboxName) {
        return false;
      } else if (this.folders.folderName && !this.folders.commonInboxName) {
        return true;
      } else if (!this.folders.commonInboxName) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.initializeDatePickerOptions();
  },
  methods: {
    initializeDatePickerOptions() {
      const vm = this;
      this.fromDatePickerOption = {
        disabledDate(time) {
          if (vm.toDate) {
            return isAfter(time, vm.toDate);
          }
          return isAfter(time, now());
        },
      };
      this.toDatePickerOption = {
        disabledDate(time) {
          if (vm.fromDate) {
            const fromDate = new Date(vm.fromDate);
            return !isDateBetween(time, fromDate.setDate(fromDate.getDate() - 1), now());
          } else {
            return isAfter(time, now());
          }
        },
      };
    },
    updateQuery(value) {
      this.$emit('updateSearchQuery', value);
    },
    clearAllInput() {
      this.$emit('clearAllInput');
    },
    updateSubject(value) {
      this.$emit('updateSearchSubject', value);
    },
    updateMessageContent(value) {
      this.$emit('updateSearchMessageContent', value);
    },
    updateSelectedFilter(value) {
      this.$emit('updateSearchSelectedFilter', value);
    },
    updateFromDate(value) {
      this.$emit('updateSearchFromDate', value);
    },
    updateToDate(value) {
      this.$emit('updateSearchToDate', value);
    },
    updateThreadCreators(value) {
      this.$emit('updateSearchThreadCreators', value);
    },
    updateParticipants(value) {
      this.$emit('updateSearchParticipants', value);
    },
    updateWithAttachments(value) {
      this.$emit('updateSearchWithAttachments', value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../shared/assets/scss/core/breakpoints.scss';

[class*='col'] {
  margin-top: 20px;

  @include breakpoint-lg() {
    margin-top: 30px;
  }
}

.advanced-search-folder-label {
  text-transform: unset;
  font-weight: unset;
}
</style>
