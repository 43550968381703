<template>
  <div class="content">
    <i aria-hidden="true" class="icon-presence-check icon" />
    <p class="text">
      {{ 'AULA_NOTIFICATION_CANCELLED' | fromTextKey }}
    </p>
  </div>
</template>
<script lang="js">
export default {
  name: 'UnsubscribeFragmentCancelled'
}
</script>
<style lang="scss" scoped>
.content {
  text-align: center;
}

.icon {
  font-size: var(--icon-font-size);
  margin-bottom: 15px;
  display: inline-block;
}

.text {
  margin: 0;
}
</style>
