<template>
  <b-row class="unsubscribe-container">
    <b-col cols="10" md="5" class="content">
      <div role="heading" class="aula-logo">
        <span class="sr-only">{{ 'ARIA_LABEL_AULA_LOGIN' | fromTextKey }}</span>
        <i aria-hidden="true" class="icon-Logo" />
      </div>
      <UnsubscribeFragmentCard class="card-wrapper">
        <template v-if="!loadingValidation">
          <UnsubscribeFragmentConfirm v-if="unsubscribeStateTypes.CONFIRM === page" />
          <UnsubscribeFragmentCancelled v-else-if="unsubscribeStateTypes.CANCELLED === page" />
          <UnsubscribeFragmentInvalidLink v-else-if="unsubscribeStateTypes.INVALID === page" />
        </template>
      </UnsubscribeFragmentCard>
      <div class="text-center mt-3">
        <a :href="aulaHref" class="go-to-aula">
          {{ 'AULA_GO_TO_AULA' | fromTextKey }}
        </a>
      </div>
    </b-col>
    <div class="separator" />
    <div class="footer">
      <a href="https://www.was.digst.dk/aula-dk" target="_blank">
        {{ 'ACCESSIBILITY_STATEMENT' | fromTextKey }}
      </a>
    </div>
  </b-row>
</template>
<script lang="js">
import UnsubscribeFragmentCard from "../../../shared/PageFragments/UnsubscribesFragment/UnsubscribeFragmentCard";
import UnsubscribeFragmentInvalidLink
  from "../../../shared/PageFragments/UnsubscribesFragment/UnsubscribesFragmentInvalidLink";
import UnsubscribeFragmentCancelled
  from "../../../shared/PageFragments/UnsubscribesFragment/UnsubscribesFragmentCancelled";
import UnsubscribeFragmentConfirm from "../../../shared/PageFragments/UnsubscribesFragment/UnsubscribesFragmentConfirm";
import {unsubscribeStateTypes} from "../../../shared/enums/unsubscribeStateTypes";
import {mapActions, mapGetters} from "vuex";
import {types} from '../../store/types/types';
import {notificationProviderKeyEnum} from "../../../shared/enums/notificationProviderKeyEnum";

export default {
  name: 'Unsubscribe',
  components: {
    UnsubscribeFragmentConfirm,
    UnsubscribeFragmentCancelled,
    UnsubscribeFragmentInvalidLink,
    UnsubscribeFragmentCard
  },
  provide() {
    return {
      [notificationProviderKeyEnum.ON_UNSUBSCRIBE]: () => {
        this.onConfirmUnsubscribe();
      },
    }
  },
  data() {
    return {
      aulaHref: this.$options.filters.getSiteUrl(),
      unsubscribeStateTypes,
      token: null,
      unsubscribedSuccessfully: false,
      loadingValidation: true,
    }
  },
  computed: {
    ...mapGetters({
      isUnsubscribeTokenValid: types.GET_VALIDATE_UNSUBSCRIBE_NOTIFICATIONS
    }),
    isTokenValid() {
      return this.isUnsubscribeTokenValid && this.token != null;
    },
    page() {
      let state = unsubscribeStateTypes.INVALID;

      if (this.unsubscribedSuccessfully === true) {
        state = unsubscribeStateTypes.CANCELLED;
      } else if (this.isTokenValid) {
        state = unsubscribeStateTypes.CONFIRM;
      }

      return state;
    }
  },
  methods: {
    ...mapActions({
      unsubscribeNotifications: types.UNSUBSCRIBE_NOTIFICATIONS,
      validateUnsubscribeNotifications: types.VALIDATE_UNSUBSCRIBE_NOTIFICATIONS,
    }),
    async onConfirmUnsubscribe() {
      try {
        await this.unsubscribeNotifications({
          token: this.token,
        });
        this.unsubscribedSuccessfully = true;
      } catch {
        this.token = null;
      }
    },
  },
  created() {
    this.token = this.$route.query.token;
    this.validateUnsubscribeNotifications({token: this.token}).finally(() => {
      this.loadingValidation = false;
    });
  }
}
</script>
<style lang="scss" scoped>
.unsubscribe-container {
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    117.36deg,
    var(--color-primary-light) 0%,
    var(--color-primary-dark-guardian) 100%,
    #02487a 100%,
    #192a79 100%,
    var(--color-primary-light) 100%
  );
  color: var(--color-white);
  font-size: 18px;
}

.separator {
  width: 100%;
  flex-basis: 100%;
}

.footer {
  align-self: end;
  margin-bottom: 15px;
  text-align: center;

  a,
  button {
    display: inline-block;
    font-size: 18px;
    color: var(--color-white);
    margin-left: 15px;
    margin-right: 15px;
  }
}

.aula-logo {
  text-align: center;
  font-size: 68px;
}

.go-to-aula {
  text-decoration: underline;
  color: var(--color-white);

  &:hover {
    color: var(--color-white);
  }
}

.card-wrapper {
  --icon-font-size: 40px;
  min-height: Min(230px, 100vh);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
