<template>
  <nav
    ref="toolbar"
    class="messages-toolbar navbar navbar-expand-lg"
    tabindex="-1"
    :class="{ 'mobile-message-toolbar': isMobile }"
  >
    <div class="nav-item">
      <multi-button-mobile
        :items="multiButtonItems"
        @newMessage="
          checkBlockedUser();
          createPost();
        "
      />
      <b-btn
        variant="link"
        class="toolbar-button tool-link"
        :aria-label="'MESSAGE_TOOLBAR_NEW_MESSAGE' | fromTextKey"
        @click="
          checkBlockedUser();
          createPost();
        "
      >
        <i class="icon icon-Aula_plus in-circle" />
        {{ 'MESSAGE_TOOLBAR_NEW_MESSAGE' | fromTextKey }}
      </b-btn>
    </div>
    <ul
      class="navbar-nav"
      :class="{
        'mr-auto': !isMobile,
        'mobile-navbar-nav': isMobile && chosenFolderAndMailOwner.folderName != null,
      }"
    >
      <li
        v-if="hasPermissionToFolders"
        class="nav-item dropdown"
        :class="{ 'mobile-nav-item': isMobile && chosenFolderAndMailOwner.folderName != null }"
      >
        <b-dropdown ref="folderMenu" class="dropdown-select foldermenu w-100" toggle-class="toolbar-button">
          <template slot="button-content">
            <i class="icon mr-2" :class="getFolderIcon(chosenFolderAndMailOwner.folderType)" aria-hidden="true" />
            <div v-if="chosenFolderAndMailOwner.folderName == null">
              {{ 'MESSAGE_TOOLBAR_INBOX' | fromTextKey }}
            </div>
            <div v-else class="folder-text" :title="chosenFolderAndMailOwner.folderName">
              {{ chosenFolderAndMailOwner.folderName }}
            </div>
            <i class="icon icon-Aula_down-arrow" aria-hidden="true" />
            <badge-notification class="message-badge-notification" :amount="amountOfBadgesOnDropdown" />
          </template>
          <div class="scrollable mt-2">
            <b-dropdown-header id="personal-inboxes">
              {{ 'MESSAGE_TOOLBAR_PERSONAL_INBOXES' | fromTextKey }}
            </b-dropdown-header>
            <a
              key="inbox"
              class="dropdown-item"
              :class="{
                active: isActiveFolder(messageFiltersEnum.DEFAULT_FOLDER),
              }"
              @click="onSelectFolderClicked(messageFiltersEnum.DEFAULT_FOLDER)"
              @keydown.enter="onSelectFolderClicked(messageFiltersEnum.DEFAULT_FOLDER)"
            >
              <i class="icon icon-Aula_folder mr-2" aria-hidden="true" />
              <span>{{ 'MESSAGE_TOOLBAR_INBOX' | fromTextKey }}</span>
              <i
                v-if="folders.length > 0"
                class="icon icon-Aula_down-arrow"
                :aria-label="
                  showInboxFolders ? 'ARIA_LABEL_FOLD_SUBFOLDERS' : 'ARIA_LABEL_UNFOLD_SUBFOLDERS' | fromTextKey
                "
                tabindex="0"
                @click.stop="showInboxFolders = !showInboxFolders"
                @keydown.enter.stop="showInboxFolders = !showInboxFolders"
              />
              <badge-notification class="message-badge-notification" :amount="amountOfBadgesOnInbox" />
            </a>
            <folders
              v-if="folders.length > 0 && showInboxFolders"
              :folders="folders"
              @closeFolderMenu="closeFolderMenu"
              @selectFolder="onSelectFolderClicked"
              @updateThreads="updateThreads"
            />
            <div v-if="commonInboxes && commonInboxes.length" class="common-inboxes">
              <b-dropdown-header id="common-inboxes">
                {{ 'MESSAGE_TOOLBAR_COMMON_INBOXES' | fromTextKey }}
              </b-dropdown-header>
              <template v-for="(commonInbox, index) in commonInboxes">
                <a
                  :key="index"
                  class="dropdown-item"
                  role="button"
                  tabindex="0"
                  :class="{
                    active: commonInbox.id === chosenFolderAndMailOwner.mailOwnerId && isActiveFolder(commonInbox.id),
                  }"
                  @click="onSelectCommonInboxClicked(commonInbox)"
                  @keydown.enter="onSelectCommonInboxClicked(commonInbox)"
                >
                  <i class="icon icon-Aula_folder mr-2" aria-hidden="true" />
                  <span>{{ commonInbox.name }}</span>
                  <i
                    v-if="commonInbox.folders.length"
                    aria-hidden="true"
                    class="icon icon-Aula_down-arrow"
                    tabindex="0"
                    :aria-label="
                      commonInbox.showFolders || unfoldCommonInboxIds.includes(commonInbox.id)
                        ? 'ARIA_LABEL_FOLD_SUBFOLDERS'
                        : 'ARIA_LABEL_UNFOLD_SUBFOLDERS' | fromTextKey
                    "
                    @click.stop="unfoldCommonInbox(commonInbox)"
                    @keydown.enter.stop="unfoldCommonInbox(commonInbox)"
                  />
                  <badge-notification
                    class="message-badge-notification"
                    :show-exclamation-icon-instead-of-amount="
                      amountOfBadgesOnCommonInbox(commonInbox.id) > 0 &&
                      amountOfNewMessagesInCommonInbox(commonInbox.id) === 0
                    "
                    :amount="amountOfNewMessagesInCommonInbox(commonInbox.id)"
                  />
                </a>
                <folders
                  v-if="
                    commonInbox.folders.length > 0 &&
                    (commonInbox.showFolders || unfoldCommonInboxIds.includes(commonInbox.id))
                  "
                  :key="'folders' + index"
                  :folders="commonInbox.folders"
                  :common-inbox-folder="true"
                  :common-inbox-id="commonInbox.id"
                  @closeFolderMenu="closeFolderMenu"
                  @selectFolder="onSelectFolderClicked"
                  @updateThreads="updateThreads"
                />
              </template>
            </div>
            <b-dropdown-divider />
            <span class="new-folder">
              <a
                class="dropdown-item"
                role="button"
                tabindex="0"
                @keydown.enter="showFolderCreate = !showFolderCreate"
                @click="showFolderCreate = !showFolderCreate"
                >{{ 'MESSAGE_TOOLBAR_CREATE_NEW_FOLDER' | fromTextKey }}</a
              >
              <b-form-group v-show="showFolderCreate" :label="'MESSAGE_TOOLBAR_CREATE_NEW_FOLDER_INPUT' | fromTextKey">
                <div class="d-flex">
                  <b-form-input
                    ref="inputNewFolder"
                    v-model="newFolderName"
                    size="sm"
                    type="text"
                    maxlength="100"
                    :aria-label="'MESSAGE_TOOLBAR_CREATE_NEW_FOLDER_INPUT' | fromTextKey"
                    :placeholder="'MESSAGE_TOOLBAR_CREATE_NEW_FOLDER_INPUT' | fromTextKey"
                    @keyup.enter="createFolder"
                  />
                  <b-btn
                    variant="link"
                    :aria-label="'MESSAGE_TOOLBAR_UPDATE_MODAL_TITLE' | fromTextKey"
                    class="ml-2 px-2"
                    @click="createFolder"
                  >
                    {{ 'BUTTON_SAVE' | fromTextKey }}
                  </b-btn>
                </div>
              </b-form-group>
            </span>
          </div>
        </b-dropdown>
      </li>
      <li class="d-flex align-center">
        <div class="nav-item dropdown">
          <b-dropdown class="dropdown-select filters-option" toggle-class="toolbar-button">
            <template slot="button-content">
              <span class="filters">
                {{ ('MESSAGE_TOOLBAR_FILTER_LABEL_' + chosenFolderAndMailOwner.filter.toUpperCase()) | fromTextKey }}
              </span>
              <i class="icon icon-Aula_down-arrow" aria-hidden="true" />
            </template>
            <b-dropdown-item
              v-for="(filter, index) in filterOptions"
              :key="index"
              :class="{
                disabled: inSearch && filter.value === messageFiltersEnum.FILTER_DRAFT,
              }"
              :active="chosenFolderAndMailOwner.filter === filter.value"
              @click="onFilterChangedClicked(filter.value)"
            >
              {{ filter.textKey | fromTextKey }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="nav-item dropdown">
          <b-dropdown class="dropdown-select filters-option" toggle-class="toolbar-button">
            <template slot="button-content">
              <span class="sort mr-2" :class="chosenFolderAndMailOwner.order"> </span>
              <span>
                {{ ('MESSAGE_TOOLBAR_SORT_' + chosenFolderAndMailOwner.sort.toUpperCase()) | fromTextKey }}
              </span>
              <i class="icon icon-Aula_down-arrow" aria-hidden="true" />
            </template>
            <b-dropdown-item
              v-for="sort in sortOptions"
              :key="sort"
              :class="chosenFolderAndMailOwner.sort === sort ? chosenFolderAndMailOwner.order : ''"
              aria-describedby="sortorder"
              @click="onSortingChangedClicked(sort)"
            >
              {{ ('MESSAGE_TOOLBAR_SORT_' + sort.toUpperCase()) | fromTextKey }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div v-if="hasPermissionToSetAutoreply && isMobile" class="navbar-nav">
          <b-btn variant="link" class="toolbar-button auto-reply" @click="checkBlockedAutoReply()">
            <i class="icon icon-Aula_settings" aria-hidden="true" />
            <div v-if="!autoReply" class="autoreply-text">
              {{ 'MESSAGE_TOOLBAR_CREATE_AUTO_REPLY' | fromTextKey }}
            </div>
            <div v-else class="autoreply-text">
              {{ 'MESSAGE_TOOLBAR_EDIT_AUTO_REPLY' | fromTextKey }}
            </div>
          </b-btn>
        </div>
      </li>
      <li v-if="canSelectMessageView">
        <b-dropdown class="dropdown-select filters-option" toggle-class="toolbar-button">
          <template slot="button-content">
            {{ 'MESSAGE_TOOLBAR_THREAD_VIEW' | fromTextKey }}
            <i class="icon icon-Aula_down-arrow" aria-hidden="true" />
          </template>
          <b-dropdown-item
            v-for="(option, index) in threadViewOptions"
            :key="index"
            :value="option.value"
            :active="selectedThreadView === option.value"
            @click="setSelectedThreadView(option.value)"
          >
            {{ option.label }}
          </b-dropdown-item>
        </b-dropdown>
      </li>
    </ul>
    <div v-if="hasPermissionToSetAutoreply && !isMobile" class="navbar-nav">
      <b-btn variant="link" class="toolbar-button auto-reply" @click="checkBlockedAutoReply()">
        <i class="icon icon-Aula_settings" aria-hidden="true" />
        <div v-if="!autoReply" class="autoreply-text">
          {{ 'MESSAGE_TOOLBAR_CREATE_AUTO_REPLY' | fromTextKey }}
        </div>
        <div v-else class="autoreply-text">
          {{ 'MESSAGE_TOOLBAR_EDIT_AUTO_REPLY' | fromTextKey }}
        </div>
      </b-btn>
    </div>
    <aula-modal
      ref="modalInfoBoxForBlockedUserCreateNewMessage"
      header-text="MESSAGE_BLOCK_COMMUNICATION_TITLE"
      :show-cancel="false"
      @okClicked="$refs.modalInfoBoxForBlockedUserCreateNewMessage.hide()"
    >
      {{ 'MESSAGE_BLOCK_COMMUNICATION_BODY' | fromTextKey }}
    </aula-modal>
  </nav>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { types } from '../../store/types/types';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import { messageFiltersEnum } from '../../../shared/enums/messageFiltersEnum';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { messageOwnerTypes } from '../../../shared/enums/messageOwnerTypes';
import { notificationAreas } from '../../../shared/enums/notificationAreas';
import { folderTypes } from '../../../shared/enums/folderTypes';
import BadgeNotification from '../../../shared/components/BadgeNotification.vue';
import MultiButtonMobile from '../shared/MultiButtonMobile.vue';
import Folders from './Folders.vue';
import { notificationTypes } from '../../../shared/enums/notificationTypes';
import { notificationEventTypes } from '../../../shared/enums/notificationEventTypes';
import { orderDirections } from '../../../shared/enums/orderDirections';
import { messageProviderKeyEnum } from '../../../shared/enums/messageProviderKeyEnum';
import { messageFilterTypes } from '../../../shared/enums/messageFilterTypes';
import * as dateUtil from '../../../shared/utils/dateUtil';
import { threadViewEnum } from '../../../shared/enums/threadViewEnum';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';

export default {
  components: {
    BadgeNotification,
    Folders,
    MultiButtonMobile,
  },
  inject: {
    updateMessageNewComponentKey: messageProviderKeyEnum.UPDATE_MESSAGE_NEW_COMPONENT_KEY,
    restartPollingService: messageProviderKeyEnum.RESTART_POLLING_SERVICE,
    getCanDiscardEditingMessage: messageProviderKeyEnum.CAN_DISCARD_EDITING_MESSAGE,
    onCancelEditingMessage: messageProviderKeyEnum.ON_CANCEL_EDITING_MESSAGE,
    getSearchModel: messageProviderKeyEnum.SEARCH_MODEL,
    getSelectedThreadView: messageProviderKeyEnum.SELECTED_THREAD_VIEW,
    setSelectedThreadView: messageProviderKeyEnum.SET_SELECTED_THREAD_VIEW,
  },
  data: function () {
    return {
      chosenFolderAndMailOwner: {
        mailOwnerId: null,
        mailOwnerType: messageOwnerTypes.INSTITUTION_PROFILE,
        folderId: messageFiltersEnum.DEFAULT_FOLDER,
        folderName: null,
        commonInboxName: null,
        filter: messageFiltersEnum.FILTER_ALL,
        sort: messageFiltersEnum.SORT_DATE,
        order: orderDirections.DESCENDING,
        folderType: folderTypes.NORMAL,
      },
      folderTypes: folderTypes,
      tempFolderId: '',
      newFolderName: '',
      showFolderCreate: false,
      createNewMessagePath: '/beskeder/opret',
      portalRoles: portalRoles,
      permissionEnum: permissionEnum,
      notificationAreas: notificationAreas,
      messageFiltersEnum: messageFiltersEnum,
      messageOwnerTypes: messageOwnerTypes,
      showInboxFolders: true,
      maxFolderNameLength: 100,
      unfoldCommonInboxIds: [],
      filterOptions: messageFilterTypes,
      sortOptions: [messageFiltersEnum.SORT_DATE, messageFiltersEnum.SORT_SUBJECT],
      threadViewOptions: [
        {
          value: threadViewEnum.STANDARD,
          label: this.$options.filters.fromTextKey('MESSAGE_TOOLBAR_THREAD_VIEW_STANDARD'),
        },
        {
          value: threadViewEnum.COMPACT,
          label: this.$options.filters.fromTextKey('MESSAGE_TOOLBAR_THREAD_VIEW_COMPACT'),
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      isPreviewMode: types.GET_IS_PREVIEW_MODE,
      chosenSubscription: types.MESSAGES_GET_SELECTED_SUBSCRIPTION,
      autoReply: types.MESSAGES_GET_AUTOREPLY,
      commonInboxes: types.MESSAGES_GET_COMMON_INBOXES,
      institutions: types.GET_INSTITUTIONS,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
      folders: types.MESSAGES_GET_FOLDERS,
      profile: types.GET_CURRENT_PROFILE,
      hasPermission: types.HAS_PERMISSION,
      notifications: types.GET_NOTIFICATIONS,
      activeChildren: types.GET_ACTIVE_CHILDREN,
      inSearch: types.MESSAGES_GET_IN_SEARCH,
      isMobile: types.GET_IS_MOBILE,
      chosenFolderAndMailOwnerFromStore: types.MESSAGES_GET_CHOSEN_FOLDER_AND_MAIL_OWNER,
      hasNonBlockingProfiles: types.GET_HAS_NON_BLOCKING_PROFILES,
    }),
    selectedThreadView() {
      return this.getSelectedThreadView();
    },
    searchModel() {
      return this.getSearchModel();
    },
    canDiscardEditingMessage() {
      return this.getCanDiscardEditingMessage();
    },
    canSelectMessageView() {
      return this.profile.role === portalRoles.EMPLOYEE && !this.isMobile;
    },
    amountOfBadgesOnDropdown() {
      if (
        this.chosenFolderAndMailOwner.folderId &&
        this.chosenFolderAndMailOwner.folderId !== messageFiltersEnum.DEFAULT_FOLDER
      ) {
        return this.notifications.filter(
          n =>
            n.notificationType === notificationTypes.BADGE &&
            n.notificationArea === this.notificationAreas.MESSAGES &&
            n.folderId === this.chosenFolderAndMailOwner.folderId
        ).length;
      } else if (this.chosenFolderAndMailOwner.mailOwnerType === messageOwnerTypes.COMMON_INBOX) {
        return this.notifications.filter(
          n =>
            n.notificationType === notificationTypes.BADGE &&
            n.notificationArea === this.notificationAreas.MESSAGES &&
            n.commonInboxId === this.chosenFolderAndMailOwner.mailOwnerId &&
            !n.folderId
        ).length;
      }
      return this.amountOfBadgesOnInbox;
    },
    hasPermissionToSetAutoreply() {
      return (
        this.hasPermission(permissionEnum.INBOX_SET_PERSONAL_AUTOREPLY) &&
        this.chosenFolderAndMailOwner.mailOwnerType !== messageOwnerTypes.COMMON_INBOX
      );
    },
    hasPermissionToFolders() {
      return this.hasPermission(permissionEnum.INBOX_FOLDERS);
    },
    multiButtonItems() {
      const items = [];
      items.push({
        icon: 'icon-Aula_write',
        text: 'MESSAGE_TOOLBAR_CREATE_NEW_MESSAGE',
        action: 'newMessage',
      });
      return items;
    },
    amountOfBadgesOnInbox() {
      return this.notifications.filter(
        n =>
          n.notificationType === notificationTypes.BADGE &&
          n.notificationArea === this.notificationAreas.MESSAGES &&
          !n.commonInboxId &&
          !n.folderId
      ).length;
    },
  },
  watch: {
    'chosenSubscription.id'() {
      if (!this.inSearch) {
        return;
      }

      const currentCommonInbox = this.commonInboxes.find(
        inbox => this.chosenSubscription.mailBoxOwner != null && inbox.id === this.chosenSubscription.mailBoxOwner.id
      );
      if (currentCommonInbox != null) {
        this.selectCommonInbox(currentCommonInbox, false);
      } else {
        this.chosenFolderAndMailOwner.folderName = null;
      }
    },
    chosenFolderAndMailOwnerFromStore: {
      handler() {
        this.chosenFolderAndMailOwner = this.chosenFolderAndMailOwnerFromStore;
      },
      deep: true,
    },
    activeInstitutions: function () {
      this.initCommonInboxes();
      this.filterOrSortResults();
    },
    activeChildren() {
      this.filterOrSortResults();
      this.initCommonInboxes();
    },
    showFolderCreate: function () {
      if (this.showFolderCreate) {
        this.$nextTick(function () {
          this.$refs.inputNewFolder.focus();
        });
      }
    },
    profile() {
      if (this.hasPermission(permissionEnum.INBOX_FOLDERS) && this.profile.role === portalRoles.EMPLOYEE) {
        this.loadInboxFolders();
      } else {
        this.mutateInboxFolders([]);
      }
      this.chosenFolderAndMailOwner.filter = messageFiltersEnum.FILTER_ALL;
      this.chosenFolderAndMailOwner.sort = messageFiltersEnum.SORT_DATE;
      this.chosenFolderAndMailOwner.order = orderDirections.DESCENDING;
      this.initCommonInboxes();
    },
    notifications() {
      this.toggleCommonInboxFolders({ notifications: this.notifications });
    },
  },
  mounted() {
    this.$refs.toolbar.focus();
  },
  async created() {
    if (this.profile && this.profile.profileId) {
      this.initCommonInboxes();
    }
    this.resetChosenFolderAndMailOwner();
    this.chosenFolderAndMailOwner.folderId = this.$route.query.folderId
      ? this.$route.query.folderId
      : messageFiltersEnum.DEFAULT_FOLDER;
    if (this.$route.query.mailBoxOwnerType === this.messageOwnerTypes.COMMON_INBOX && this.$route.query.mailOwnerId) {
      this.chosenFolderAndMailOwner.mailOwnerId = this.$route.query.mailOwnerId;
      this.chosenFolderAndMailOwner.mailOwnerType = messageOwnerTypes.COMMON_INBOX;
    }
    if (this.profile.role === portalRoles.OTP) {
      await this.loadOtpInboxes();
    }
    await this.updateThreads(false);
    this.$emit('threadsLoaded');
  },
  methods: {
    ...mapActions({
      updateThreadViewAction: types.UPDATE_THREADS_VIEW,
      updateSelectedThreadIds: types.ACTION_UPDATE_SELECTED_THREAD_IDS,
      updateSelectedSubscriptionIds: types.ACTION_UPDATE_SELECTED_SUBSCRIPTION_IDS,
      updateSelectedBundleIds: types.ACTION_UPDATE_SELECTED_BUNDLE_IDS,
      updateThreadViewActionDrafts: types.UPDATE_THREADS_VIEW_BY_SUBSCRIPTION,
      createFolderAction: types.ADD_THREADS_FOLDER,
      createCommonInboxFolderAction: types.ADD_THREADS_FOLDER_FOR_COMMON_INBOX,
      loadCommonInboxes: types.LOAD_COMMON_INBOXES,
      deleteNotifications: types.DELETE_NOTIFICATIONS,
      loadInboxFolders: types.INIT_THREADS_FOLDERS,
      searchSubscriptions: types.SEARCH_SUBSCRIPTIONS,
      loadOtpInboxes: types.ACTION_GET_OTP_INBOXES,
      resetThreadBundles: types.RESET_THREAD_BUNDLES,
    }),
    ...mapMutations({
      setChosenFolderAndMailOwner: types.MUTATE_CHOSEN_FOLDER_AND_MAIL_OWNER,
      showErrorText: types.MUTATE_ERROR_TEXT,
      toggleCommonInboxFolders: types.MUTATE_TOGGLE_COMMON_INBOX_FOLDERS,
      mutateInboxFolders: types.MUTATE_INIT_THREADS_FOLDERS,
      setInSearchState: types.MUTATE_SUBSCRIPTIONS_IN_SEARCH,
    }),
    getFolderIcon(folderType) {
      if (!folderType) {
        return iconClassEnum.FOLDER;
      }
      return folderType === folderTypes.NORMAL ? iconClassEnum.FOLDER : iconClassEnum.BIN;
    },
    onFilterChangedClicked(filter) {
      if (this.canDiscardEditingMessage) {
        this.changeFilter(filter);
      } else {
        this.onCancelEditingMessage(this.changeFilter, filter);
      }
      this.searchModel.setFilter(filter);
    },
    changeFilter(filter) {
      this.chosenFolderAndMailOwner.filter = filter;
      this.resetThreadBundles([]);
      this.filterOrSortResults();
    },
    unfoldCommonInbox(commonInbox) {
      if (this.unfoldCommonInboxIds.includes(commonInbox.id)) {
        commonInbox.showFolders = false;
        this.unfoldCommonInboxIds = this.unfoldCommonInboxIds.filter(id => id != commonInbox.id);
      } else {
        this.unfoldCommonInboxIds.push(commonInbox.id);
      }
    },
    amountOfBadgesOnCommonInbox(commonInboxId) {
      return this.notifications.filter(
        n => n.notificationType === notificationTypes.ALERT && n.commonInboxId === commonInboxId && !n.folderId
      ).length;
    },
    amountOfNewMessagesInCommonInbox(commonInboxId) {
      return this.notifications.filter(
        n =>
          n.notificationType === notificationTypes.BADGE &&
          n.commonInboxId === commonInboxId &&
          !n.folderId &&
          (n.notificationEventType === notificationEventTypes.NEW_MESSAGE_COMMON_INBOX ||
            n.notificationEventType === notificationEventTypes.NEW_SENSITIVE_MESSAGE_COMMON_INBOX)
      ).length;
    },
    resetChosenFolderAndMailOwner() {
      this.chosenFolderAndMailOwner = {
        mailOwnerId: null,
        mailOwnerType: messageOwnerTypes.INSTITUTION_PROFILE,
        folderId: messageFiltersEnum.DEFAULT_FOLDER,
        folderName: null,
        commonInboxName: null,
        filter: messageFiltersEnum.FILTER_ALL,
        sort: messageFiltersEnum.SORT_DATE,
        order: orderDirections.DESCENDING,
        folderType: folderTypes.NORMAL,
      };
    },
    createPost() {
      if (!this.isPreviewMode) {
        // Reset temporary draft
        localStorage.setItem('tempDraftThread', '');

        this.$router.push({ path: this.createNewMessagePath });
        this.updateMessageNewComponentKey();
      }
    },
    isActiveFolder(id) {
      const isCommonInbox = this.chosenFolderAndMailOwner.mailOwnerType === messageOwnerTypes.COMMON_INBOX;
      return isCommonInbox
        ? this.chosenFolderAndMailOwner.folderId == null && this.chosenFolderAndMailOwner.mailOwnerId === id
        : this.chosenFolderAndMailOwner.folderId === id;
    },
    onSelectFolderClicked(folder, resetCommonInbox = true) {
      if (this.canDiscardEditingMessage) {
        this.setInSearchState(false);
        this.selectFolder(folder, resetCommonInbox);
      } else {
        this.onCancelEditingMessage(this.selectFolder, folder, resetCommonInbox);
      }
      this.searchModel.resetSearchParams();
    },
    selectFolder(folder, resetCommonInbox = true) {
      this.setInSearchState(false);
      if (resetCommonInbox) {
        this.resetChosenFolderAndMailOwner();
      }
      if (folder === messageFiltersEnum.DEFAULT_FOLDER) {
        this.chosenFolderAndMailOwner.folderId = folder;
        this.chosenFolderAndMailOwner.folderName = null;
        this.chosenFolderAndMailOwner.commonInboxName = null;
      } else {
        this.chosenFolderAndMailOwner.folderId = folder.id;
        if (folder.commonInboxId) {
          this.chosenFolderAndMailOwner.mailOwnerId = folder.commonInboxId;
          this.chosenFolderAndMailOwner.mailOwnerType = messageOwnerTypes.COMMON_INBOX;
        }
        this.chosenFolderAndMailOwner.folderName = folder.name;
        this.chosenFolderAndMailOwner.folderType = folder.type;
        this.chosenFolderAndMailOwner.commonInboxName = folder.commonInboxName;
      }
      this.closeFolderMenu();
      this.updateThreads();
    },
    onSelectCommonInboxClicked(commonInbox, update = true) {
      if (this.canDiscardEditingMessage) {
        this.setInSearchState(false);
        this.selectCommonInbox(commonInbox, update);
      } else {
        this.onCancelEditingMessage(this.selectCommonInbox, commonInbox, update);
      }
      this.searchModel.resetSearchParams();
    },
    selectCommonInbox(commonInbox, update = true) {
      this.chosenFolderAndMailOwner = {
        folderId: null,
        mailOwnerId: commonInbox.id,
        mailOwnerType: messageOwnerTypes.COMMON_INBOX,
        folderName: commonInbox.name != null ? commonInbox.name : null,
        commonInboxName: commonInbox.name != null ? commonInbox.name : null,
        filter: this.chosenFolderAndMailOwner.filter,
        sort: this.chosenFolderAndMailOwner.sort,
        order: this.chosenFolderAndMailOwner.order,
        folderType: folderTypes.NORMAL,
      };
      this.closeFolderMenu();
      if (update) {
        this.updateThreads();
      } else {
        this.setChosenFolderAndMailOwner(this.chosenFolderAndMailOwner);
      }
      this.deleteCommonInboxBadges(commonInbox.id);
    },
    deleteCommonInboxBadges(commonInboxId) {
      const notifications = this.notifications.filter(
        n =>
          n.commonInboxId == commonInboxId &&
          (n.notificationEventType === notificationEventTypes.ADDED_TO_COMMON_INBOX ||
            n.notificationEventType === notificationEventTypes.REMOVED_FROM_COMMON_INBOX)
      );
      if (notifications.length > 0) {
        this.mapNotificationsAndDeleteThem(notifications);
      }
    },
    mapNotificationsAndDeleteThem(notifications) {
      this.deleteNotifications({
        notifications: notifications.map(n => ({
          notificationId: n.notificationId,
          institutionProfileId: n.institutionProfileId,
        })),
      });
    },
    showModalForBockedUserCreateNewMessage() {
      this.$refs.modalInfoBoxForBlockedUserCreateNewMessage.show();
    },
    initCommonInboxes() {
      if (!this.hasPermission(permissionEnum.INBOX_FOLDERS)) {
        return;
      }

      let institutionFilter = () => false;
      if (this.profile.role === portalRoles.GUARDIAN) {
        institutionFilter = institution =>
          this.activeChildren.some(activeChild => institution.children.some(child => activeChild == child.id));
      } else if (this.profile.role === portalRoles.EMPLOYEE) {
        institutionFilter = institution =>
          this.activeInstitutions.some(activeInstitution => activeInstitution == institution.institutionCode);
      }
      const instProfileIds = this.institutions
        .filter(institutionFilter)
        .map(institution => institution.institutionProfileId);

      if (instProfileIds.length === 0) {
        instProfileIds.push(this.profile.id);
      }
      this.loadCommonInboxes({
        instProfileId: instProfileIds,
      });
    },
    closeFolderMenu() {
      this.$refs.folderMenu.visible = false;
    },
    checkBlockedUser() {
      if (!this.hasNonBlockingProfiles) {
        this.createNewMessagePath = '#';
        this.showModalForBockedUserCreateNewMessage();
      }
    },
    checkBlockedAutoReply() {
      if (!this.hasNonBlockingProfiles) {
        this.showModalForBockedUserCreateNewMessage();
      } else {
        this.$router.push({ name: 'messageAutoreply' });
      }
    },
    onSortingChangedClicked(sort) {
      if (this.canDiscardEditingMessage) {
        this.changeSorting(sort);
      } else {
        this.onCancelEditingMessage(this.changeSorting, sort);
      }
    },
    changeSorting(sortBy) {
      if (this.chosenFolderAndMailOwner.sort === sortBy) {
        this.chosenFolderAndMailOwner.order =
          this.chosenFolderAndMailOwner.order === orderDirections.ASCENDING
            ? orderDirections.DESCENDING
            : orderDirections.ASCENDING;
      } else {
        this.chosenFolderAndMailOwner.sort = sortBy;

        switch (sortBy) {
          case messageFiltersEnum.SORT_DATE:
            this.chosenFolderAndMailOwner.order = orderDirections.DESCENDING;
            break;
          case messageFiltersEnum.SORT_SUBJECT:
            this.chosenFolderAndMailOwner.order = orderDirections.ASCENDING;
            break;
          default:
            this.chosenFolderAndMailOwner.order = orderDirections.ASCENDING;
        }
      }
      this.filterOrSortResults();
    },
    createFolder() {
      if (this.showFolderCreate && this.newFolderName && this.newFolderName.length <= this.maxFolderNameLength) {
        if (this.chosenFolderAndMailOwner.mailOwnerType === messageOwnerTypes.COMMON_INBOX) {
          this.createCommonInboxFolderAction({
            commonInboxId: this.chosenFolderAndMailOwner.mailOwnerId,
            folderName: this.newFolderName,
          });
        } else {
          this.createFolderAction(this.newFolderName);
        }
        this.newFolderName = '';
        this.showFolderCreate = false;
      } else {
        this.showErrorText('MESSAGE_TOOLBAR_INVALID_NAME');
      }
    },
    executeSearch() {
      let fromDate = this.searchModel.getFromDate();
      let toDate = this.searchModel.getToDate();

      if (fromDate) {
        fromDate = dateUtil.format(dateUtil.startOf(fromDate, 'day'), 'YYYY-MM-DDTHH:mm:ss');
      }

      if (toDate) {
        toDate = dateUtil.format(dateUtil.endOf(toDate, 'day'), 'YYYY-MM-DDTHH:mm:ss');
      }

      this.searchModel.setOffset(0);

      this.searchSubscriptions({
        text: this.searchModel.getQuery(),
        threadSubject: this.searchModel.getSubject(),
        messageContent: this.searchModel.getContent(),
        fromDate,
        toDate,
        threadCreators: this.searchModel.getThreadCreators(),
        participants: this.searchModel.getParticipants(),
        hasAttachments: this.searchModel.getHasAttachments(),
        offset: this.searchModel.getOffset(),
        activeChildren: this.activeChildren,
        activeInstitutions: this.activeInstitutions,
        profile: this.profile,
      }).then(() => {
        this.setChosenFolderAndMailOwner(this.chosenFolderAndMailOwner);
        this.$router.push({ path: '/beskeder' });
      });
    },
    filterOrSortResults() {
      if (this.inSearch || this.searchModel.getIsUsingAdvancedSearch()) {
        this.executeSearch();
      } else if (this.chosenFolderAndMailOwner.filter === this.messageFiltersEnum.FILTER_DRAFT) {
        let draftIds = [0];
        if (localStorage.draftMessages !== undefined && localStorage.draftMessages !== '{}') {
          draftIds = Object.keys(JSON.parse(localStorage.draftMessages));
        }

        this.updateThreadViewActionDrafts({
          subscriptionIds: draftIds,
          folderId: this.chosenFolderAndMailOwner.folderId,
          filterType: this.chosenFolderAndMailOwner.filter,
          mailOwnerId: this.chosenFolderAndMailOwner.mailOwnerId,
          sort: this.chosenFolderAndMailOwner.sort,
          order: this.chosenFolderAndMailOwner.order,
        }).then(() => {
          this.setChosenFolderAndMailOwner(this.chosenFolderAndMailOwner);
          this.$router.push({ path: '/beskeder' });
        });
      } else {
        this.updateThreadViewAction({
          folderId: this.chosenFolderAndMailOwner.folderId,
          filterType: this.chosenFolderAndMailOwner.filter,
          mailOwnerId: this.chosenFolderAndMailOwner.mailOwnerId,
          sort: this.chosenFolderAndMailOwner.sort,
          order: this.chosenFolderAndMailOwner.order,
        }).then(() => {
          this.setChosenFolderAndMailOwner(this.chosenFolderAndMailOwner);
          this.$router.push({ path: '/beskeder' });
        });
      }
    },
    calculateAmountOfBadges(folderId) {
      let amount = 0;
      for (const notification of this.notifications) {
        if (notification.notificationArea === this.notificationAreas.MESSAGES && notification.folderId === folderId) {
          amount++;
        }
      }
      return amount;
    },
    async updateThreads(closeOpenMessage = true) {
      this.resetThreadBundles();
      this.updateSelectedThreadIds([]);
      this.updateSelectedSubscriptionIds([]);
      this.updateSelectedBundleIds([]);
      if (closeOpenMessage) {
        this.$router.push({ path: '/beskeder' });
      }
      let folder = null;
      if (this.$route.query.folderId) {
        folder = this.$route.query.folderId;
        this.chosenFolderAndMailOwner.folderId = this.$route.query.folderId;
      } else {
        folder = this.chosenFolderAndMailOwner.folderId;
      }
      this.setChosenFolderAndMailOwner(this.chosenFolderAndMailOwner);

      // if search is in progress sorting and filtering must be applied to search query
      if (this.inSearch) {
        this.executeSearch();
      } else {
        await this.updateThreadViewAction({
          folderId: folder,
          filterType: this.chosenFolderAndMailOwner.filter,
          mailOwnerId: this.chosenFolderAndMailOwner.mailOwnerId || null,
          sort: this.chosenFolderAndMailOwner.sort,
          order: this.chosenFolderAndMailOwner.order,
        });
        this.restartPollingService();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.aula-circle-message {
  font-size: 10px;
  margin-top: 18px;
  @include breakpoint-tablet-portrait() {
    font-size: 14px;
    margin-top: 24px;
  }
}

.message-badge-notification {
  --position: relative;
}

.messages-toolbar {
  font-size: 14px;
  background-color: $color-grey-base;
  outline: none;
  height: 50px;
  padding-left: 0;

  .navbar-nav {
    flex-direction: row;
    align-items: center;
  }

  .navbar-nav .btn,
  .nav-item .btn span {
    display: flex;
    align-items: center;
  }
  .foldermenu /deep/ .dropdown-menu {
    max-width: 300px !important;
    width: 300px;
  }
  .filters-option {
    /deep/ .dropdown-menu {
      max-width: 200px !important;
      width: 200px;
    }
  }
  .dropdown-menu {
    .form-group {
      min-width: 190px;
      margin: 0;
      padding: 0 5px;
    }
    input[type='text'] {
      border: solid 1px $color-form-border;
      padding: 8px 9px;
      width: 220px;
    }
  }
  .new-content-icon {
    position: relative;
    font-weight: bold;
    padding: 0 5px;
    height: 16px;
    top: -2px;
    background: $color-alert;
    line-height: 16px;
    display: inline-block;
    font-size: 12px;
    color: $color-grey-base;
    border-radius: 12px;
  }

  /deep/.toolbar-button {
    padding: 8px;
    border-radius: 4px;
    min-height: 38px;
    transition: background-color 150ms ease-in;
    &:hover {
      --dropdown-button-background: var(--color-grey-light);
      background-color: var(--color-grey-light);
    }
  }

  .dropdown-select {
    padding: 0;
  }

  .tool-link,
  .dropdown-select {
    .theme-employee & {
      color: $color-primary-darker-employee;
    }
    .theme-guardian & {
      color: $color-primary-darker-guardian;
    }
    .theme-child & {
      color: $color-primary-darker-child;
    }
    .theme-admin & {
      color: $color-primary-darker-admin;
    }
    &:hover {
      text-decoration: none;
    }
    .icon {
      position: relative;
    }
    .icon-Aula_down-arrow,
    .icon-Aula_up-arrow {
      font-size: 5px;
      position: relative;
      padding: 0.5rem;
      @include breakpoint-tablet-landscape() {
        padding: 0.25rem;
      }
    }
    .icon-Aula_folder {
      font-size: 17px;
    }
    .icon-Aula_write {
      top: 14px;
      font-size: 18px;
      @include breakpoint-tablet-portrait() {
        top: 20px;
      }
    }
    .dropdown-item {
      align-items: center;
      cursor: pointer;
      display: flex;
      padding-right: 10px;

      span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.dropdown-folder {
        padding-left: 2.25rem;
      }
      font-weight: bold;
    }
    .dropdown-header {
      background-color: $color-dropdown-focus;
    }
  }
  .sort {
    &.asc:before,
    &.desc:before {
      margin-left: 3px;
      margin-right: -3px;
    }
  }
  .desc,
  .asc {
    display: flex;
    align-items: center;
  }

  .dropdown-toggle {
    .desc::before {
      content: '\2193';
      margin-right: -3px;
      margin-left: 3px;
      margin-bottom: 4px;
    }
    .asc::before {
      content: '\2191';
      margin-right: -3px;
      margin-left: 3px;
      margin-bottom: 4px;
    }
  }

  .dropdown-menu {
    .desc /deep/a::before {
      content: '\2193';
      margin-right: 3px;
      margin-left: -12px;
      margin-bottom: 4px;
    }
    .asc /deep/::before {
      content: '\2191';
      margin-right: 3px;
      margin-left: -12px;
      margin-bottom: 4px;
    }

    .desc /deep/a::before,
    .asc /deep/a::before {
      .theme-employee & {
        color: $color-primary-base-employee;
      }
      .theme-guardian & {
        color: $color-primary-base-guardian;
      }
      .theme-child & {
        color: $color-primary-base-child;
      }
    }
  }

  .scrollable {
    max-height: 70vh;
    overflow-y: auto;
  }
  .is-not-empty {
    color: red !important;
  }
  .autoreply-text {
    display: none;
    @include breakpoint-xs-up() {
      display: initial;
      position: relative;
      margin-left: 5px;
    }
  }
  .icon-Aula_settings {
    font-size: 19px;
  }
}

.badge-notification-dropdown {
  left: -6px !important;
  top: 7px !important;
  .badge {
    width: 20px;
    height: 20px;
  }
}

.foldermenu,
.sortingmenu,
.filters-option {
  --dropdown-toggle-button-max-width: 150px;
  @include breakpoint-lg() {
    --dropdown-toggle-button-max-width: 300px;
  }

  position: relative;
  top: 0px;

  & /deep/ .dropdown-toggle {
    display: flex;
    align-items: center;
  }
}
.folder-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
  @include breakpoint-lg-down {
    max-width: 100%;
    margin-right: 0;
  }
}
.mobile-message-toolbar {
  justify-content: unset;
  padding-left: 8px;
}
.mobile-nav-item {
  max-width: 45vw;

  /deep/ button.dropdown-toggle {
    width: 100%;
  }
}
</style>
