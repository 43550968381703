<template>
  <div class="contacts">
    <div class="contact-master-data">
      <ContactsFragmentDesktop v-if="!isMobile" />
      <ContactsFragmentMobile v-else />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { types } from '../../store/types/types';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import { contactsProfileTypeFilters } from '../../../shared/enums/contactsProfileTypeFilters';
import { portal } from '../../../shared/assets/plugins/axios/axios';
import requestCancelTokenMixin from '../../../shared/mixins/requestCancelTokenMixin';
import { isProfileEmployee } from '../../../shared/functions/isProfileEmployee';
import { contactViewTypes } from '../../../shared/enums/contactViewTypes';
import { orderDirections } from '../../../shared/enums/orderDirections';
import { contactSortingTypes } from '../../../shared/enums/contactSortingTypes';
import { collapseDirectionEnum } from '../../../shared/libs/table/enums/collapseDirectionEnum';
import ContactsFragmentDesktop from '../../../shared/PageFragments/ContactsFragment/ContactsFragmentDesktop';
import { providerKeyTypes } from '../../../shared/enums/providerKeyTypes';
import ContactsFragmentMobile from '../../../shared/PageFragments/ContactsFragment/ContactsFragmentMobile';
import { isProfileChild } from '../../../shared/functions/isProfileChild';
import { profileUtil } from '../../../shared/utils/profileUtil';
import { ContactViewRouteEnum } from '../../../shared/enums/contactViewRouteEnum';

export default {
  mixins: [requestCancelTokenMixin],
  provide() {
    return {
      [providerKeyTypes.canViewAllContactInformation]: () => this.hasAccessToViewAllContactInformation,
      [providerKeyTypes.onLoadGroups]: query => this.loadGroups(query),
      [providerKeyTypes.groups]: () => this.filteredGroupsOptions,
      [providerKeyTypes.contactGroups]: () => this.contactGroupsDropdown,
      [providerKeyTypes.setContactGroupSearchQuery]: () => {
        this.searchingGroupQuery = null;
      },
      [providerKeyTypes.groupId]: () => this.chosenContactList,
      [providerKeyTypes.setGroupId]: groupId => this.changeGroup(groupId),
      [providerKeyTypes.viewModes]: () => this.viewModes,
      [providerKeyTypes.viewMode]: () => this.selectedView,
      [providerKeyTypes.setViewMode]: viewMode => {
        if (this.isLoading) {
          return;
        }

        this.changeViewMode(viewMode);

        if (this.chosenContactList == null) {
          return;
        }

        this.getAllContacts();
      },
      [providerKeyTypes.profileTypes]: () => this.menuFilterItems,
      [providerKeyTypes.setProfileType]: profileType => {
        if (this.isLoading) {
          return;
        }
        this.changeProfileType(profileType);
        this.getAllContacts();
      },
      [providerKeyTypes.profileType]: () => this.filterProfileType,
      [providerKeyTypes.profile]: () => this.profile,
      [providerKeyTypes.contacts]: () => this.contacts,
      [providerKeyTypes.orderDirection]: () => this.order,
      [providerKeyTypes.sortBy]: () => this.sortBy,
      [providerKeyTypes.onSortBy]: sortBy => this.doSort(sortBy),
      [providerKeyTypes.loading]: () => this.isLoading,
      [providerKeyTypes.groupSearch]: () => this.searchingGroupQuery,
      [providerKeyTypes.showTooManyGroupWarning]: () => this.showFilteringGroupWarning,
      [providerKeyTypes.canViewParentCustody]: () => this.canViewParentCustody,
    };
  },
  data: function () {
    return {
      portalRoles: portalRoles,
      permissionEnum: permissionEnum,
      contactsProfileTypeFilters: contactsProfileTypeFilters,
      chosenContactList: null,
      filterMenus: [
        {
          name: 'ALL_CHILDREN',
          label: 'ARIA_LABEL_ALL_CHILDREN',
          filter: contactsProfileTypeFilters.CHILD,
        },
        {
          name: 'BOYS',
          label: 'ARIA_LABEL_BOYS',
          filter: contactsProfileTypeFilters.BOY,
        },
        {
          name: 'GIRLS',
          label: 'ARIA_LABEL_GIRLS',
          filter: contactsProfileTypeFilters.GIRL,
        },
        {
          name: 'CONTACT_FILTER_GUARDIAN',
          label: 'ARIA_LABEL_PARENTS',
          filter: contactsProfileTypeFilters.GUARDIAN,
        },
        {
          name: 'EMPLOYEES',
          label: 'ARIA_LABEL_EMPLOYEE',
          filter: contactsProfileTypeFilters.EMPLOYEE,
        },
      ],
      filterProfileType: contactsProfileTypeFilters.CHILD,
      isLoading: true,
      sortBy: contactSortingTypes.NAME,
      contacts: [],
      currentPage: 1,
      contactsPerPageLoad: 20,
      order: orderDirections.ASCENDING,
      memberGroups: [],
      searchedGroups: [],
      searchingGroupQuery: null,
      showFilteringGroupWarning: false,
      contactsCancelTokenSource: null,
      selectedView: null,
      contactSortingTypes,
      collapseDirectionEnum,
      showParentCustody: false,
    };
  },
  computed: {
    ...mapGetters({
      isPreviewMode: types.GET_IS_PREVIEW_MODE,
      isMobile: types.GET_IS_MOBILE,
      profile: types.GET_CURRENT_PROFILE,
      institutions: types.GET_INSTITUTIONS,
      groupFilters: types.GET_GROUP_FILTER,
      contactList: types.GET_CONTACT_LIST,
      hasPermission: types.HAS_PERMISSION,
      activeInstitutionCodes: types.GET_ACTIVE_INSTITUTIONS,
      activeChildIds: types.GET_ACTIVE_CHILDREN,
      canLoadMoreContacts: types.GET_CAN_LOAD_MORE_CONTACTS,
    }),
    canViewParentCustody() {
      return this.contacts.length > 0 && this.hasPermission(permissionEnum.VIEW_CUSTODY);
    },
    menuFilterItems() {
      if (isProfileChild(this.profile)) {
        return [];
      }

      if (this.chosenContactList === 'contactParent') {
        return this.filterMenus.filter(filterMenu => filterMenu.filter === contactsProfileTypeFilters.GUARDIAN);
      }

      const childProfileTypes = profileUtil.getChildProfileTypes();
      const isChildProfileFilterSelected = childProfileTypes.includes(this.filterProfileType);
      let allowedFilterMenuTypes = [
        contactsProfileTypeFilters.CHILD,
        contactsProfileTypeFilters.BOY,
        contactsProfileTypeFilters.GIRL,
        contactsProfileTypeFilters.GUARDIAN,
        contactsProfileTypeFilters.EMPLOYEE,
      ];

      if (!isChildProfileFilterSelected) {
        allowedFilterMenuTypes = [
          contactsProfileTypeFilters.CHILD,
          contactsProfileTypeFilters.GUARDIAN,
          contactsProfileTypeFilters.EMPLOYEE,
        ];
      }

      if (
        this.selectedView === contactViewTypes.PHONE_LIST ||
        this.selectedView === contactViewTypes.BIRTHDAY_LIST ||
        this.selectedView === contactViewTypes.CHECKBOX_LIST ||
        this.selectedView === contactViewTypes.PHOTO_LIST
      ) {
        allowedFilterMenuTypes = [
          contactsProfileTypeFilters.CHILD,
          contactsProfileTypeFilters.BOY,
          contactsProfileTypeFilters.GIRL,
        ];
      }

      return this.filterMenus.filter(filterMenu => allowedFilterMenuTypes.includes(filterMenu.filter));
    },
    viewModes() {
      return [
        {
          name: this.$options.filters.fromTextKey('CONTACT_LIST'),
          value: contactViewTypes.CONTACT_LIST,
        },
        {
          name: this.$options.filters.fromTextKey('CONTACT_PHONE_LIST'),
          value: contactViewTypes.PHONE_LIST,
        },
        {
          name: this.$options.filters.fromTextKey('CONTACT_PHOTO_LIST'),
          value: contactViewTypes.PHOTO_LIST,
        },
        {
          name: this.$options.filters.fromTextKey('CONTACT_CHECKBOX_LIST'),
          value: contactViewTypes.CHECKBOX_LIST,
        },
        {
          name: this.$options.filters.fromTextKey('CONTACT_BIRTHDAY_LIST'),
          value: contactViewTypes.BIRTHDAY_LIST,
        },
      ];
    },
    hasAccessToViewAllContactInformation() {
      return this.hasPermission(permissionEnum.VIEW_CONTACT_INFORMATION_ALL);
    },
    getFilteringGroups() {
      let groups = this.groupFilters;
      if (this.hasAccessToViewAllContactInformation && this.searchingGroupQuery) {
        groups = this.searchedGroups;
      }
      return groups;
    },
    contactGroupsDropdown() {
      return this.getFilteringGroups.map(group => ({
        ...group,
        label: group.displayName,
        value: group.profileId,
      }));
    },
    filteredGroupsOptions() {
      const options = [];
      const canSeeContactParentsContactInfo = this.hasPermission(permissionEnum.SEE_CONTACT_PARENTS_CONTACT_INFO);

      if (!this.contactGroupsDropdown) {
        return options;
      }

      for (const group of this.contactGroupsDropdown) {
        if (canSeeContactParentsContactInfo) {
          options.push({
            name: this.$options.filters.fromTextKey('CONTACT_PARENT'),
            id: 'contactParent',
            group: group.value,
          });
        }

        for (const option of group.groups) {
          options.push({
            ...option,
            group: group.value,
          });
        }
      }
      return options;
    },
    groupFilterParams() {
      const params = {};
      if (isProfileEmployee(this.profile)) {
        params.institutionCodes = this.activeInstitutionCodes;
      } else {
        params.childInstitutionProfileIds = this.activeChildIds;
      }
      return params;
    },
  },
  watch: {
    activeInstitutionCodes() {
      this.init();
    },
    activeChildIds() {
      this.init();
    },
    $route(to, from) {
      if (to.query.filter !== from.query.filter) {
        let newFilter = to.query.filter;
        const isFilterValid = Object.values(contactsProfileTypeFilters).includes(newFilter);
        if (!isFilterValid) {
          newFilter = contactsProfileTypeFilters.CHILD;
        }
        if (!to.query.gruppe) {
          this.resetContactList();
          return;
        }
        this.changeProfileType(to.query.filter);
        this.getAllContacts();
      }
    },
  },
  methods: {
    ...mapActions({
      loadGroupFilter: types.LOAD_GROUP_FILTER,
      loadContactList: types.LOAD_CONTACT_LIST,
      loadContactParents: types.LOAD_CONTACT_PARENT,
    }),
    async init() {
      this.isLoading = true;
      const { udsigt, gruppe, filter } = this.$route.query;
      this.filterProfileType = filter || contactsProfileTypeFilters.CHILD;
      this.chosenContactList = gruppe;
      this.selectedView = this.mapRouteQueryToView(udsigt);
      this.populateRoute();
      await this.loadGroupFilter(this.groupFilterParams);
      let defaultGroup;
      let hasChosenContactList = false;

      for (const profile of this.groupFilters) {
        if (profile.groups.some(group => group.id === this.chosenContactList)) {
          hasChosenContactList = true;
        }
        const defaultGroupOfProfile = profile.groups.find(group => group.showAsDefault);
        if (defaultGroupOfProfile) {
          defaultGroup = defaultGroupOfProfile;
        }
      }

      if (this.$route.query.gruppe) {
        this.chosenContactList =
          this.$route.query.gruppe === 'contactParent' ? 'contactParent' : parseInt(this.$route.query.gruppe);
      } else if (defaultGroup && !hasChosenContactList) {
        this.chosenContactList = defaultGroup.id;
      }
      hasChosenContactList = this.chosenContactList != null;
      if (defaultGroup || hasChosenContactList) {
        this.getAllContacts();
      } else {
        this.resetContactList();
      }
    },
    populateRoute() {
      let view = '';
      switch (this.$route.query.udsigt) {
        case ContactViewRouteEnum.CONTACT_LIST:
          view = contactViewTypes.CONTACT_LIST;
          break;
        case ContactViewRouteEnum.PHONE_LIST:
          view = contactViewTypes.PHONE_LIST;
          break;
        case ContactViewRouteEnum.PHOTO_LIST:
          view = contactViewTypes.PHOTO_LIST;
          break;
        case ContactViewRouteEnum.CHECKBOX_LIST:
          view = contactViewTypes.CHECKBOX_LIST;
          break;
        case ContactViewRouteEnum.BIRTHDAY_LIST:
          view = contactViewTypes.BIRTHDAY_LIST;
          break;
      }
      this.selectedView = view;
    },
    resetContactList() {
      this.isLoading = false;
      this.chosenContactList = null;
      this.contacts = [];
    },
    changeGroup(groupId) {
      this.chosenContactList = groupId;
      this.filterProfileType = this.menuFilterItems[0]?.filter || contactsProfileTypeFilters.CHILD;
      this.getAllContacts();
      this.updateRoute();
    },
    changeViewMode(viewMode) {
      this.selectedView = viewMode;
      const profileType = this.menuFilterItems[0]?.filter || contactsProfileTypeFilters.CHILD;
      this.changeProfileType(profileType);
      this.setSortBy(contactSortingTypes.NAME);
      this.setOrder(orderDirections.ASCENDING);
      this.updateRoute();
    },
    updateRoute() {
      this.$router.push({
        name: 'contacts',
        query: {
          udsigt: this.mapViewToRouteQuery(this.selectedView),
          gruppe: this.chosenContactList,
          filter: this.filterProfileType,
        },
      });
    },
    mapViewToRouteQuery(view) {
      switch (view) {
        case contactViewTypes.CONTACT_LIST:
          return ContactViewRouteEnum.CONTACT_LIST;
        case contactViewTypes.PHONE_LIST:
          return ContactViewRouteEnum.PHONE_LIST;
        case contactViewTypes.PHOTO_LIST:
          return ContactViewRouteEnum.PHOTO_LIST;
        case contactViewTypes.CHECKBOX_LIST:
          return ContactViewRouteEnum.CHECKBOX_LIST;
        case contactViewTypes.BIRTHDAY_LIST:
          return ContactViewRouteEnum.BIRTHDAY_LIST;
      }
    },
    changeProfileType(profileType) {
      this.filterProfileType = profileType;
      if (
        this.filterProfileType === contactsProfileTypeFilters.GUARDIAN ||
        this.filterProfileType === contactsProfileTypeFilters.EMPLOYEE
      ) {
        this.sortBy = contactSortingTypes.NAME;
      }
      this.updateRoute();
    },
    doSort(sortBy) {
      let order = orderDirections.ASCENDING;
      if (this.order === orderDirections.ASCENDING) {
        order = orderDirections.DESCENDING;
      }

      if (this.sortBy !== sortBy) {
        order = orderDirections.ASCENDING;
        this.setSortBy(sortBy);
      }

      this.setOrder(order);
      this.getAllContacts();
    },
    setSortBy(sortBy) {
      this.sortBy = sortBy;
    },
    setOrder(order) {
      this.order = order;
    },
    addLetterToContacts() {
      let letter = '';
      for (const contact of this.contacts) {
        const firstLetter = contact.fullName.charAt(0);
        if (firstLetter !== letter) {
          contact.letter = firstLetter;
          letter = firstLetter;
        }
      }
    },
    async getContacts(isLoadMore) {
      if (this.chosenContactList != null) {
        if (!isLoadMore) {
          this.currentPage = 1;
        }
        if (this.contactsCancelTokenSource !== null) {
          this.cancelAxiosRequest(this.contactsCancelTokenSource);
          this.resetContactsCancelToken();
        }
        this.createContactsCancelToken();
        if (this.chosenContactList === 'contactParent') {
          await this.getContactParents(isLoadMore);
        } else {
          await this.getContactList(isLoadMore);
        }
      }
    },
    getContactParents(isLoadMore = false) {
      const httpConfig = {
        params: {
          page: this.currentPage,
          order: this.order,
        },
        isLoadMore,
        cancelToken: this.contactsCancelTokenSource.token,
      };
      return this.loadContactParents(httpConfig).catch(error => Promise.reject(error));
    },
    getContactList(isLoadMore = false) {
      if (this.chosenContactList != null && this.chosenContactList > 0) {
        const httpConfig = {
          params: {
            groupId: this.chosenContactList,
            filter: this.filterProfileType,
            field: this.sortBy,
            page: this.currentPage,
            order: this.order,
          },
          isLoadMore,
          cancelToken: this.contactsCancelTokenSource.token,
        };
        return this.loadContactList(httpConfig).catch(error => Promise.reject(error));
      }
    },
    async loadGroups(query) {
      this.searchingGroupQuery = query;
      const response = await portal.get('?method=search.findGroups', {
        params: {
          text: query,
          institutionCodes: this.activeInstitutionCodes,
          limit: 201,
        },
      });
      const results = response.data.data.results;
      this.showFilteringGroupWarning = results.length > 200;
      const activeInstitutions = this.institutions.filter(i => this.activeInstitutionCodes.includes(i.institutionCode));
      const institutionResults = {};
      for (const institution of activeInstitutions) {
        institutionResults[institution.institutionCode] = {
          name: institution.name,
          groupResults: [],
        };
      }

      for (const item of results) {
        const group = {
          name: item.name,
          id: item.id,
        };
        if (institutionResults[item.institutionCode] != null) {
          institutionResults[item.institutionCode].groupResults.push(group);
        }
      }
      const groupedResults = [];
      for (const institution of activeInstitutions) {
        const institutionCode = institution.institutionCode;
        if (institutionResults[institutionCode].groupResults.length > 0) {
          groupedResults.push({
            displayName: institutionResults[institutionCode].name,
            groups: institutionResults[institutionCode].groupResults,
            profileId: institution.institutionProfileId,
          });
        }
      }
      this.searchedGroups = groupedResults;
    },
    async getAllContacts() {
      this.isLoading = true;
      let loadMore = false;
      let canLoadMore = false;
      this.currentPage = 0;
      this.contacts = [];
      do {
        try {
          this.currentPage++;
          await this.getContacts(loadMore);
          loadMore = true;
          canLoadMore = this.canLoadMoreContacts;
          this.contacts = this.contactList;
        } catch (e) {
          canLoadMore = false;
        }
        this.isLoading = false;
      } while (canLoadMore);
      this.addLetterToContacts();
      this.resetContactsCancelToken();
    },
    createContactsCancelToken() {
      this.contactsCancelTokenSource = this.getAxiosCancelTokenSource();
    },
    resetContactsCancelToken() {
      this.contactsCancelTokenSource = null;
    },
    addPrintParentStyles() {
      document.body.classList.add('remove-parent-container-styles');
    },
    removePrintParentStyles() {
      document.body.classList.remove('remove-parent-container-styles');
    },
    mapRouteQueryToView(query) {
      switch (query) {
        case ContactViewRouteEnum.CONTACT_LIST:
          return contactViewTypes.CONTACT_LIST;
        case contactViewTypes.PHONE_LIST:
          return ContactViewRouteEnum.PHONE_LIST;
        case contactViewTypes.PHOTO_LIST:
          return ContactViewRouteEnum.PHOTO_LIST;
        case contactViewTypes.CHECKBOX_LIST:
          return ContactViewRouteEnum.CHECKBOX_LIST;
        case contactViewTypes.BIRTHDAY_LIST:
          return ContactViewRouteEnum.BIRTHDAY_LIST;
      }
    },
  },
  mounted() {
    window.addEventListener('beforeprint', this.addPrintParentStyles);
    window.addEventListener('afterprint', this.removePrintParentStyles);
    this.init();
    if (!this.isMobile) {
      setTimeout(function () {
        document.querySelector('.contact-master-data .contacts-group-filter').focus();
      }, 500);
    }
  },
  beforeDestroy() {
    window.removeEventListener('beforeprint', this.addPrintParentStyles);
    window.removeEventListener('afterprint', this.removePrintParentStyles);
  },
  components: {
    ContactsFragmentMobile,
    ContactsFragmentDesktop,
  },
};
</script>

<style lang="scss" scoped>
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
.contacts {
  @import '../../../shared/assets/scss/components/contacts/_contacts.scss';
}
</style>
