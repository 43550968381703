<template>
  <div id="aula-login">
    <div id="main">
      <div id="aula-choose-portal-role">
        <b-container>
          <div class="aula-logo" role="heading" aria-level="1">
            <span class="sr-only">{{ 'ARIA_LABEL_AULA_LOGIN' | fromTextKey }}</span>
            <i class="icon-Logo" aria-hidden="true" />
          </div>
          <b-row>
            <b-col cols="12" class="login-method">
              {{ 'LOGIN_PAGE_CHOOSE_LOGIN_METHOD' | fromTextKey }}
            </b-col>
          </b-row>
          <b-row class="justify-content-md-center box-container mt-4">
            <b-col cols="12" lg="4">
              <div
                class="square-box login-option"
                tabindex="0"
                role="button"
                @click="selectPortalRole('guardian')"
                @keydown.enter="selectPortalRole('guardian')"
              >
                <div class="content">
                  {{ 'PARENT' | fromTextKey }}
                </div>
                <i v-if="!isMobile" class="icon-Aula_forward-arrow" />
              </div>
            </b-col>
            <b-col cols="12" lg="4">
              <div
                class="square-box login-option"
                tabindex="0"
                role="button"
                @click="selectPortalRole('otp')"
                @keydown.enter="selectPortalRole('otp')"
              >
                <div class="content">{{ 'OTP' | fromTextKey }}<br /></div>
                <i v-if="!isMobile" class="icon-Aula_forward-arrow" />
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { types } from '../../../src/store/types/types';
import { parentTypes } from '../../enums/parentTypes.ts';
import { portalRoles } from '../../enums/portalRoles';

export default {
  computed: {
    ...mapGetters({
      isProfileLoaded: types.IS_PROFILE_LOADED,
      isMobile: types.GET_IS_MOBILE,
    }),
  },
  methods: {
    selectPortalRole(method) {
      if (method == 'guardian') {
        this.getProfileContext({
          portalrole: portalRoles.GUARDIAN,
          parent: parentTypes.PORTAL,
        }).then(() => {
          this.$router.push({ name: 'overblik' });
        });
      } else {
        this.getProfileContext({
          portalrole: portalRoles.OTP,
          parent: parentTypes.PORTAL,
        }).then(() => {
          this.$router.push({ name: 'overblik' });
        });
      }
    },
    ...mapActions({
      getProfileContext: types.ACTION_GET_PROFILE_CONTEXT,
    }),
    mounted() {
      if (!this.isProfileLoaded) {
        this.$router.push({ name: 'login' });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/scss/core/variables.scss';
@import '../../assets/scss/core/breakpoints.scss';
@import '../../assets/scss/components/login/login.scss';
</style>
