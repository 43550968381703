<template>
  <div class="aula-album-detail-container">
    <gallery-toolbar
      v-if="!isUploadParent"
      :parent="parent"
      :media-number="albumMedia.length"
      @activateDownloadMedia="onDownloadAlbumClicked"
    />
    <div class="album-detail-container">
      <mobile-topbar
        v-if="isMobile && albumInfo != null"
        :text="albumInfo.title != null ? albumInfo.title : albumInfo.name"
        :show-ok="canShowMobileTopbarRightButton"
        :right-text="topbarRightTextKey"
        :text-key="isUploadParent ? 'GALLERY_UPLOAD_PICTURES' : ''"
        :header-text-from-textkey="false"
        @rightTextClicked="onRightTextMobileTopbarClick"
        @leftIconClicked="backToGallery"
      />
      <div class="album-container page-layout-padding">
        <div v-if="albumInfo != null" class="album-header">
          <template>
            <b-row v-if="isMobile" class="metadata">
              <b-col v-if="albumInfo.creator != null" cols="auto">
                <ContactAvatar :profile="albumInfo.creator" />
              </b-col>
              <b-col>
                <h1 v-if="albumInfo.description != null">
                  {{ albumInfo.description }}
                </h1>
                <p v-if="albumInfo.creator != null">
                  {{ albumInfo.creator | displayProfileNameWithMetadata }} -
                  {{ moment(albumInfo.creationDate).format(dateFormat) }}
                </p>
                <p v-if="albumInfo.sharedWithGroups && albumInfo.sharedWithGroups.length > 0">
                  {{ 'GALLERY_SHARED_WITH' | fromTextKey }}:
                </p>
                <GroupMemberPopoverList :groups="albumInfo.sharedWithGroups" />
              </b-col>
            </b-row>
            <b-row v-else>
              <b-col cols="9">
                <button
                  type="button"
                  class="btn-link back-to-gallery mb-3"
                  :aria-label="'ARIA_LABEL_BACK' | fromTextKey"
                  @click="backToGallery()"
                  @keydown.enter="backToGallery()"
                >
                  <i class="icon-Aula_app_arrow_slideback mr-2" />
                  {{ 'ALL_ALBUMS' | fromTextKey }}
                </button>
                <h1>
                  {{ albumInfo.title != null ? albumInfo.title : albumInfo.name }}
                  <i class="icon-Album" />
                </h1>
                <p>
                  {{ albumInfo.description }}
                  <br />
                  <template v-if="albumInfo.sharedWithGroups && albumInfo.sharedWithGroups.length > 0">
                    {{ 'GALLERY_SHARED_WITH' | fromTextKey }}:
                    <GroupMemberPopoverList :groups="albumInfo.sharedWithGroups" />
                  </template>
                </p>
              </b-col>
              <b-col v-if="albumInfo.creator != null" cols="3" class="metadata" align="right">
                <ContactAvatar :profile="albumInfo.creator" />
                <div>
                  {{ albumInfo.creator | displayProfileNameWithMetadata }}
                </div>
                <div>
                  {{ moment(albumInfo.creationDate).format(dateFormat) }}
                </div>
              </b-col>
            </b-row>
            <b-row v-if="!isUploadParent">
              <b-col class="d-flex pl-3 mt-2 mt-lg-0" lg="8" cols="12">
                <AulaSwitchButton
                  v-model="isSelecting"
                  :aria-label="'SELECT_MORE' | fromTextKey"
                  @change="toggleTagMode()"
                >
                  {{ 'SELECT_MORE' | fromTextKey }}
                  <span v-if="isSelecting" class="text-nowrap">
                    ({{
                      'MEDIA_SELECTED'
                        | fromTextKey({
                          numberOfSelected: selectedMedias.length,
                        })
                    }})
                  </span>
                </AulaSwitchButton>
                <template v-if="!isMobile && isSelecting && selectedMedias.length !== 0">
                  <b-dropdown
                    v-if="canEditTags"
                    ref="multiMediaTagDropdown"
                    class="dropdown-select foldermenu position-relative album-detail-tag-dropdown ml-2"
                    :right="false"
                    variant="link"
                  >
                    <template slot="button-content">
                      <i class="icon-tag mr-1" />
                      {{ 'GALLERY_ADD_TAGS' | fromTextKey }}
                      <i class="sort icon-Aula_down-arrow" />
                    </template>
                    <MediaTagHandling
                      :show-add-tag-label="false"
                      :can-edit-tags="canEditTags"
                      :institution-code="albumInstitutionCode"
                      :show-existing-tags="false"
                      :is-multiple-media-tagging="true"
                      class="pl-2 pr-2"
                      @cancelTagging="cancelTagging"
                      @addTagsToMedia="addTagsToSelectedMedias"
                    />
                  </b-dropdown>
                  <AulaButton
                    class="action-button"
                    variant="link"
                    :disabled="isDownloading"
                    @click="onDownloadMediaClicked"
                  >
                    <Icon :name="iconClassEnum.DOWNLOAD" />
                    {{ 'GALLERY_DOWNLOAD_MULTIPLE_MEDIA' | fromTextKey }}
                    <AulaSpinner v-if="isDownloading" class="download-spinner" />
                  </AulaButton>
                  <AulaButton
                    class="action-button"
                    variant="link"
                    :disabled="!canDeleteSelectedMedia"
                    @click="onDeleteSelectedMediaClicked"
                  >
                    <Icon :name="iconClassEnum.BIN" />
                    {{ 'GALLERY_DELETE_MULTIPLE_MEDIA' | fromTextKey }}
                  </AulaButton>
                </template>
              </b-col>
              <b-col v-if="!isMobile" class="d-flex flex-row justify-content-end" cols="4">
                <b-dropdown ref="sorter" class="sorter dropdown-select align-self-end" toggle-class="sorter" right>
                  <template #button-content>
                    {{ filterName | fromTextKey }}
                    <i class="icon icon-Aula_down-arrow" aria-hidden="true" />
                  </template>
                  <template v-for="filter in fitlerConfigOptions">
                    <b-dropdown-item
                      :key="filter.value"
                      :active="
                        filter.name === 'MEDIA_FILTER_ALL'
                          ? filterName === 'GALLERY_FILTER'
                          : filterName === filter.name
                      "
                      @click="doFilter(filter.name, filter.value)"
                    >
                      {{ filter.name | fromTextKey }}
                    </b-dropdown-item>
                  </template>
                </b-dropdown>
                <b-dropdown ref="sorter" class="sorter dropdown-select align-self-end" toggle-class="sorter" right>
                  <template #button-content>
                    <div class="sort ml-2 mb-1" :class="orderDirection" />
                    {{ sortName | fromTextKey }}
                    <i class="icon icon-Aula_down-arrow" aria-hidden="true" />
                  </template>
                  <template v-for="sort in sortConfigOptions">
                    <b-dropdown-item
                      :key="sort.value"
                      :class="sortOn === sort.value ? orderDirection : ''"
                      @click="doSort(sort.value, sort.name)"
                    >
                      {{ 'SORT_MEDIA_BY_UPLOADED_AT' | fromTextKey }}
                    </b-dropdown-item>
                  </template>
                </b-dropdown>
              </b-col>
            </b-row>
          </template>
        </div>
        <hr v-if="!isMobile" />
        <div v-if="isSelecting" class="gallery-select">
          <b-form-checkbox v-model="selectAll" class="accept" @input="selectAllMedia()">
            {{ 'GALLERY_SELECT_ALL' | fromTextKey }}
          </b-form-checkbox>
        </div>
        <Overlay :show="isLoading">
          <ObservingContainer class="album-body" :options="observerOptions">
            <MediaList
              :media-list="albumMedia"
              :can-delete-media="isGroupAdministrator || isAdministrator"
              :select-mode="isSelecting"
              @onMediaSelected="onMediaSelected"
              @onDeleteMediaClick="onDeleteMediaClick"
              @onOpenMedia="openMedia"
            />
            <ObservedTarget v-if="isObservable" @onIntersecting="onBottomReached" />
          </ObservingContainer>
        </Overlay>
      </div>
      <transition v-if="isMobile" name="slide-only">
        <MediaManagementMobile
          v-if="isVisibleMediaActionMobile"
          :topbar-header-text="
            'MEDIA_SELECTED'
              | fromTextKey({
                numberOfSelected: selectedMedias.length,
              })
          "
          :selected-medias="selectedMedias"
          :is-downloading="isDownloading"
          :can-delete="canDeleteSelectedMedia"
          :can-edit-tag="canEditTags"
          @download="onDownloadMediaClicked"
          @delete="onDeleteSelectedMediaClicked"
          @openMediaTagHandling="isVisibleMobileTagDetails = true"
          @goBack="closeMediaActionMobile"
        />
      </transition>
      <transition v-if="isMobile" name="slide-only">
        <div v-if="isVisibleMobileTagDetails" class="media-tag-details-mobile">
          <MediaTagHandling
            :is-mobile="isMobile"
            :show-add-tag-label="false"
            :can-edit-tags="canEditTags"
            :institution-code="albumInstitutionCode"
            :show-existing-tags="false"
            :is-multiple-media-tagging="true"
            @cancelTagging="closeTagDetailsMobile"
            @addTagsToMedia="addTagsToSelectedMedias"
          />
        </div>
      </transition>
    </div>

    <aula-modal
      ref="deleteMediaModal"
      ok-text="BUTTON_YES"
      @cancelClicked="$refs.deleteMediaModal.hide()"
      @okClicked="deleteMedia({ mediaIds: [mediaToDelete.id] }), $refs.deleteMediaModal.hide()"
    >
      {{ 'GALLERY_DELETE_MEDIA_CONFIRM' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="multiMediaDeletionWarningModal"
      ok-text="BUTTON_YES"
      @cancelClicked="$refs.multiMediaDeletionWarningModal.hide()"
      @okClicked="deleteSelectedMedia"
    >
      <span v-html="multipleMediaDeletionWarningText" />
    </aula-modal>
    <aula-modal
      ref="downloadAlbumWarningModal"
      ok-text="BUTTON_YES"
      :is-loading="isDownloading"
      @cancelClicked="$refs.downloadAlbumWarningModal.hide()"
      @okClicked="downloadAlbum"
    >
      <p>{{ 'GALLERY_DOWLOAD_ALBUM_WARNING_1' | fromTextKey }}</p>
      <p>{{ 'GALLERY_DOWLOAD_ALBUM_WARNING_2' | fromTextKey }}</p>
    </aula-modal>
    <media-detail
      v-if="canShowMediaDetail"
      :album="albumMedia"
      :parent="parent == parentTypes.GROUP ? parentTypes.GROUP_GALLERY : parent"
      :sort-on="sortOn"
      :order-direction="orderDirection"
      :filter-by="filterBy"
      :filter-inst-profile-ids="getFilterInstProfileIds"
    />
    <create-album
      v-if="($route.path.includes('galleri/rediger') || $route.path.includes('galleri/tilfoej')) && !activeGroup.id"
      :parent="parent"
      @closed="handleAlbumUpdated"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { types } from '../../store/types/types';
import MediaDetail from './MediaDetail';
import GalleryToolbar from './GalleryToolbar';
import MobileTopbar from '../../../shared/components/MobileTopbar';
import CreateAlbum from './CreateAlbum';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { mediaTypes } from '../../../shared/enums/mediaTypes';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import saveAs from 'jszip/vendor/FileSaver';
import { browserUtil } from '../../../shared/utils/browserUtil';
import { orderDirections } from '../../../shared/enums/orderDirections';
import { mediaSortType } from '../../../shared/enums/mediaSortType';
import { mediaFilterType } from '../../../shared/enums/mediaFilterType';
import moment from 'moment-timezone';
import { Cookie } from '../../../shared/assets/plugins/cookie';
import { performanceUtil } from '../../../shared/utils/performanceUtil';
import { conversionStatusTypes } from '../../../shared/enums/conversionStatusTypes';
import AulaSwitchButton from '../../../shared/components/AulaSwitchButton.vue';
import MediaTagHandling from '../../../shared/PageFragments/Media/MediaTagHandling.vue';
import uniqBy from 'lodash/uniqBy';
import isEmpty from 'lodash/isEmpty';
import { xor } from 'lodash/array';
import MediaList from '../../../shared/PageFragments/Media/MediaList.vue';
import MediaManagementMobile from './MediaManagementMobile.vue';
import AulaButton from '../../../shared/components/AulaButton.vue';
import Icon from '../../../shared/components/Icon.vue';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import ObservingContainer from '../../../shared/libs/intersection-observer/components/Container.vue';
import ObservedTarget from '../../../shared/libs/intersection-observer/components/Target.vue';
import Overlay from '../../../shared/components/Overlay.vue';
import GroupMemberPopoverList from '../groups/GroupMemberPopoverList.vue';
import ContactAvatar from '../contacts/ContactAvatar.vue';

export default {
  components: {
    ContactAvatar,
    GroupMemberPopoverList,
    Overlay,
    ObservedTarget,
    ObservingContainer,
    Icon,
    AulaButton,
    MediaManagementMobile,
    MediaList,
    MediaTagHandling,
    AulaSwitchButton,
    GalleryToolbar,
    MediaDetail,
    MobileTopbar,
    CreateAlbum,
  },
  props: {
    parent: { type: String, default: parentTypes.GALLERY },
    albumId: { type: Number, default: null },
    myMedia: { type: Boolean, default: false },
    institutionProfileIdsFilter: { type: Array, required: false, default: null },
  },
  data: function () {
    return {
      isSelecting: false,
      isVisibleMobileTagDetails: false,
      isVisibleMediaActionMobile: false,
      image: '',
      selectAll: false,
      imgDescription: '',
      imgTags: '',
      mediaToDelete: '',
      currentLimit: 12,
      isLoading: true,
      isDownloading: false,
      dateFormat: 'DD-MM-YYYY',
      mediaTypes,
      moment,
      parentTypes,
      mediaSortType,
      sortName: 'GALLERY_SORT',
      filterName: 'GALLERY_FILTER',
      sortOn: mediaSortType.UPLOADED_AT,
      orderDirection: orderDirections.DESCENDING,
      filterBy: mediaFilterType.ALL,
      fitlerConfigOptions: [
        {
          value: mediaFilterType.ALL,
          name: 'MEDIA_FILTER_ALL',
        },
        {
          value: mediaFilterType.MY_MEDIA,
          name: 'MEDIA_FILTER_MY_MEDIA_ALBUMS',
        },
      ],
      sortConfigOptions: [
        {
          value: mediaSortType.UPLOADED_AT,
          name: 'SORT_MEDIA_BY_UPLOADED_AT',
        },
      ],
      conversionStatus: conversionStatusTypes,
    };
  },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
    ...mapGetters({
      albums: types.GET_ALBUMS,
      moreMediasExist: types.GET_MORE_MEDIAS_EXIST,
      albumMedia: types.GET_ALBUM_DETAILS,
      activeAlbum: types.GET_ACTIVE_ALBUM,
      isMobile: types.GET_IS_MOBILE,
      profile: types.GET_CURRENT_PROFILE,
      children: types.GET_CHILDREN,
      activeChildren: types.GET_ACTIVE_CHILDREN,
      institutions: types.GET_INSTITUTIONS,
      activeGroup: types.GET_ACTIVE_GROUP,
      isProfileLoaded: types.IS_PROFILE_LOADED,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
      hasPermissionGroup: types.HAS_PERMISSION_ON_GROUP,
      hasPermission: types.HAS_PERMISSION,
      mediaCount: types.GET_MEDIA_COUNT,
    }),
    isBlockedCommunication() {
      const activeInstitution = this.institutions.find(
        institution => institution.institutionCode === this.albumInstitutionCode
      );
      return activeInstitution?.communicationBlock;
    },
    canShowMediaDetail() {
      const mediaId = this.$route.params.mediaId;
      if (!this.albumMedia.some(media => media.id === parseInt(mediaId))) {
        return false;
      }
      return this.$route.path.includes('galleri/medie') || this.$route.name == 'group-media-details';
    },
    multipleMediaDeletionWarningText() {
      let warningText1 = this.$options.filters.fromTextKey('GALLERY_DELETE_MULTIPLE_MEDIA_WARNING_PLURAL_1', {
        numberOfMedia: this.selectedMedias.length,
      });
      let warningText2 = this.$options.filters.fromTextKey('GALLERY_DELETE_MULTIPLE_MEDIA_WARNING_PLURAL_2');
      const warningText3 = this.$options.filters.fromTextKey('GALLERY_DELETE_MULTIPLE_MEDIA_WARNING_3');

      if (this.selectedMedias.length === 1) {
        warningText1 = this.$options.filters.fromTextKey('GALLERY_DELETE_MULTIPLE_MEDIA_WARNING_SINGULAR_1', {
          numberOfMedia: this.selectedMedias.length,
        });
        warningText2 = this.$options.filters.fromTextKey('GALLERY_DELETE_MULTIPLE_MEDIA_WARNING_SINGULAR_2');
      }

      return `${warningText1}<br/>${warningText2}<br/>${warningText3}`;
    },
    canDeleteSelectedMedia() {
      return this.selectedMedias.every(media => media.currentUserCanDelete);
    },
    observerOptions() {
      return { rootMargin: '50%' };
    },
    isObservable() {
      return this.albumMedia.length > 0;
    },
    albumInstitutionCode() {
      if (this.activeAlbum && this.activeAlbum.creator) {
        return this.activeAlbum.creator.institutionCode;
      }
      return null;
    },
    canShowMobileTopbarRightButton() {
      return this.isUploadParent || this.selectedMedias.length !== 0;
    },
    topbarRightTextKey() {
      if (this.isSelecting && this.selectedMedias.length !== 0) {
        return 'NEXT_STEP';
      }
      if (this.isUploadParent) {
        return 'OK';
      }
      return '';
    },
    selectedMedias() {
      return this.albumMedia.filter(media => media.selected);
    },
    isUploadParent() {
      return this.parent === parentTypes.UPLOAD;
    },
    isGroupAdministrator() {
      if (this.activeAlbum != null && this.activeAlbum.sharedWithGroups != null) {
        for (const group of this.activeAlbum.sharedWithGroups) {
          if (this.hasPermissionGroup(permissionEnum.DELETE_POSTS_MEDIA_COMMENTS, group.id)) {
            return true;
          }
        }
      }
      return false;
    },
    isAdministrator() {
      return this.hasPermission(permissionEnum.ADMIN_MODULE);
    },
    canEditTags() {
      return this.hasPermission(permissionEnum.HANDLE_MEDIA) && !this.isBlockedCommunication;
    },
    albumInfo: function () {
      if (this.$route.name == 'institutionProfileMedias') {
        if (this.albums.length == 0) {
          const httpConfig = {
            params: {
              index: 0,
              limit: this.children.length,
              filterInstProfileIds: this.getFilterInstProfileIds(),
            },
          };
          // eslint-disable-next-line vue/no-async-in-computed-properties
          this.getAlbums(httpConfig).then(() => this.getActiveAlbumForChildMediasOtp());
        } else {
          return this.getActiveAlbumForChildMediasOtp();
        }
      } else if (this.$route.params.albumId != null && !this.$route.path.includes('minemedier')) {
        if (this.activeAlbum != null && this.activeAlbum.id == this.$route.params.albumId) {
          return this.activeAlbum;
        } else if (this.albums.length > 0) {
          return this.albums.find(album => album.id == this.$route.params.albumId);
        }
      } else if (this.albumId != null) {
        return this.albums.find(album => album.id == this.albumId);
      } else if (this.$route.path.includes('minemedier') || this.myMedia) {
        if (this.albums.length == 0) {
          const httpConfig = {
            params: {
              index: this.albumMedia.length,
              limit: this.currentLimit,
            },
          };
          // eslint-disable-next-line vue/no-async-in-computed-properties
          this.getAlbums(httpConfig).then(() => this.albums.find(album => album.id == null));
        } else {
          return this.albums.find(album => album.id == null);
        }
      }
      return this.activeAlbum;
    },
    getFilterInstProfileIds() {
      if (this.institutionProfileIdsFilter !== null) {
        return this.institutionProfileIdsFilter;
      }

      if (this.profile.role === portalRoles.OTP || this.profile.role === portalRoles.GUARDIAN) {
        return this.activeChildren;
      }

      return this.institutions
        .filter(institution => this.activeInstitutions.includes(institution.institutionCode))
        .map(institution => institution.institutionProfileId);
    },
  },
  watch: {
    activeChildren: function () {
      this.loadMoreMedias(true);
    },
    activeInstitutions: function () {
      this.loadMoreMedias(true);
    },
    isProfileLoaded() {
      if (this.isProfileLoaded) {
        this.loadMoreMedias(true);
      }
    },
  },
  mounted() {
    if (this.isProfileLoaded) {
      this.loadMoreMedias(true);
    }
    this.isSelecting = this.isUploadParent;
    if (this.parent === this.parentTypes.GROUP) {
      this.scrollToTopModal();
    }
    if (this.$route.params.mediaId) {
      this.loadMoreMedias(false, true);
    }
  },
  beforeDestroy() {
    if (this.isSelecting) {
      this.deselectAllMedia();
      if (this.isUploadParent) {
        this.$emit('selectedMediaUpdated', []);
      }
    }
    this.removeExistMedias([]);
  },
  methods: {
    ...mapMutations({
      removeExistMedias: types.MUTATE_REMOVE_EXISTING_MEDIA,
      selectMedia: types.MUTATE_MEDIA_SELECTED,
      showErrorText: types.MUTATE_ERROR_TEXT,
      resetAlbumDetails: types.MUTATE_RESET_ALBUM_DETAILS,
      setSucessText: types.MUTATE_SUCCESS_TEXT,
    }),
    ...mapActions({
      deleteMedia: types.DELETE_MEDIA,
      getAlbumMedia: types.ACTION_GET_ALBUM_DETAILS,
      getAlbumMediaAdmin: types.ACTION_GET_ALBUM_DETAILS_ADMIN,
      downloadZipFile: types.DOWNLOAD_ZIP_FILE,
      getAlbums: types.ACTION_GET_ALBUMS,
      updateMedia: types.UPDATE_ATTACHMENTS,
    }),
    scrollToTopModal() {
      const modalContainer = document.querySelector('.modal-aula.scrollbar');

      if (modalContainer) {
        modalContainer.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    },
    deleteSelectedMedia() {
      const mediaIds = this.selectedMedias.map(media => media.id);
      this.deleteMedia({ mediaIds });
      this.$refs.multiMediaDeletionWarningModal.hide();
      this.closeMediaActionMobile();
    },
    handleAlbumUpdated() {
      this.resetAlbumDetails();
      this.loadMoreMedias(true);
    },
    onBottomReached() {
      if (this.isLoading || !this.moreMediasExist) {
        return;
      }
      this.loadMoreMedias();
    },
    displaySharedGroupName(groupName, index) {
      if (index === 0) {
        return groupName;
      }
      return `, ${groupName}`;
    },
    onDownloadAlbumClicked() {
      this.$refs.downloadAlbumWarningModal.show();
    },
    async downloadAlbum() {
      this.selectAll = true;
      this.isDownloading = true;
      await this.selectAllMedia();
      await this.downloadMedia();
      this.setSucessText('GALLERY_ALBUM_DOWNLOADED');
      this.$refs.downloadAlbumWarningModal.hide();
      this.cancelDownloadMedia();
    },
    addTagsToSelectedMedias(tags) {
      const selectedMedias = this.albumMedia.filter(media => media.selected);
      const mediaTagUpdateRequest = [];
      let updatedMediaDictionary = {};

      for (const media of selectedMedias) {
        const updatedTags = uniqBy(media.tags.concat(tags), tag => tag.id);
        const updatedTagIds = updatedTags.map(updatedTag => updatedTag.id);

        if (
          !isEmpty(
            xor(
              updatedTagIds,
              media.tags.map(tag => tag.id)
            )
          )
        ) {
          updatedMediaDictionary = { ...updatedMediaDictionary, [media.id]: updatedTags };
          mediaTagUpdateRequest.push({
            id: media.id,
            tags: updatedTagIds,
            description: media.description,
            title: media.title,
          });
        }
      }

      if (mediaTagUpdateRequest.length !== 0) {
        this.updateMedia({ media: mediaTagUpdateRequest }).then(() => {
          for (const media of this.albumMedia) {
            if (updatedMediaDictionary[media.id] != null) {
              media.tags = updatedMediaDictionary[media.id];
            }
          }
        });
      }
      this.cancelTagging();
    },
    cancelTagging() {
      if (this.isMobile) {
        this.isVisibleMediaActionMobile = false;
        this.isVisibleMobileTagDetails = false;
      } else {
        this.$refs.multiMediaTagDropdown.hide();
      }
      this.deselectAllMedia();
    },
    closeTagDetailsMobile() {
      this.isVisibleMobileTagDetails = false;
    },
    onRightTextMobileTopbarClick() {
      if (this.isUploadParent) {
        this.$emit('addMediaFromGallery');
      }
      this.isVisibleMediaActionMobile = true;
    },
    closeMediaActionMobile() {
      this.isVisibleMediaActionMobile = false;
    },
    toggleTagMode() {
      if (!this.isSelecting) {
        this.deselectAllMedia();
      }
    },
    deselectAllMedia() {
      this.selectAll = false;
      for (const media of this.albumMedia) {
        if (media.selected) {
          this.selectMedia({ id: media.id, selected: !media.selected });
        }
      }
    },
    getActiveAlbumForChildMediasOtp() {
      const regardingInstitutionProfileId = this.$route.params.institutionProfileId;

      if (regardingInstitutionProfileId != null) {
        return this.albums.find(album => album.regardingInstitutionProfileId == regardingInstitutionProfileId);
      } else {
        return this.albums.find(album => album.id == this.$route.params.albumId);
      }
    },
    backToGallery() {
      if (this.parent === parentTypes.GALLERY) {
        this.$router.push({ name: 'gallery' });
      } else if (this.parent === parentTypes.GROUP) {
        this.$router.push({ name: 'group-gallery' });
      } else {
        this.$emit('hideAlbumDetailForEditor');
      }
    },
    loadMoreMedias: performanceUtil.asyncDebounce(async function (isFirstTime, loadAll = false) {
      let albumId = null;
      let userSpecificAlbum = null;
      const userInstitutionProfileId = this.$route.params.institutionProfileId;
      if (this.$route.path.includes('minemedier') || this.myMedia) {
        userSpecificAlbum = true;
      } else if (this.$route.params.albumId) {
        albumId = this.$route.params.albumId;
      } else {
        albumId = this.albumId;
      }

      if ((albumId != null && albumId != 'opret') || userSpecificAlbum) {
        this.isLoading = true;
        const params = {
          userSpecificAlbum: userSpecificAlbum,
          albumId: albumId,
          index: isFirstTime ? 0 : this.albumMedia.length,
          limit: this.currentLimit,
          sortOn: this.sortOn,
          orderDirection: this.orderDirection,
          filterBy: this.filterBy,
        };

        if (this.$route.name == 'institutionProfileMedias' && userInstitutionProfileId) {
          params.filterInstProfileIds = [userInstitutionProfileId];
        } else if (!this.activeGroup.id) {
          params.filterInstProfileIds = this.getFilterInstProfileIds;
        }

        let media = this.getAlbumMedia;
        if (Cookie.Read('fromAdminGroup')) {
          media = this.getAlbumMediaAdmin;
        }
        await media(params).then(async () => {
          if (loadAll && this.moreMediasExist) {
            await this.loadMoreMedias(false, true);
          } else {
            if (loadAll && !this.moreMediasExist) {
              this.toggleAllMedia();
            }
            this.isLoading = false;
          }
        });
      }
    }),
    async selectAllMedia() {
      if (this.moreMediasExist) {
        await this.loadMoreMedias(false, true);
      } else {
        this.toggleAllMedia();
      }
    },
    toggleAllMedia() {
      for (const media of this.albumMedia) {
        media.selected = this.selectAll;
      }
      if (this.isUploadParent) {
        this.emitSelectedMediaForEditor();
      }
    },
    getBinaryContent(url) {
      return new Promise((resolve, reject) => {
        // Safari does not support sec-fetch-mode cors, so we avoid that by using fetch instead of XMLHttpRequest
        if (browserUtil.isSafari()) {
          fetch(url).then(
            response =>
              response.arrayBuffer().then(buffer => {
                resolve(buffer);
              }),
            error => {
              reject(error);
            }
          );
        } else {
          JSZipUtils.getBinaryContent(url, function (err, data) {
            if (err) {
              reject(err);
            } else {
              resolve(data);
            }
          });
        }
      });
    },
    async onDownloadMediaClicked() {
      if (this.isDownloading) {
        return;
      }
      await this.downloadMedia();
      this.setSucessText('GALLERY_MEDIA_DOWNLOADED');
    },
    async downloadMedia() {
      if (this.selectedMedias.length === 0) {
        return;
      }
      this.isDownloading = true;
      await this.generateAsyncZip();
      this.isDownloading = false;
    },
    cancelDownloadMedia() {
      this.selectAll = false;
      this.deselectAllMedia();
    },
    async generateAsyncZip() {
      const zip = new JSZip();
      for (const media of this.selectedMedias) {
        try {
          const currentFile = await this.getBinaryContent(media.file.url);
          zip.file(media.file.name, currentFile, { binary: true });
        } catch {
          continue;
        }
      }
      const zipFileName = this.activeAlbum?.title || 'download';
      return zip
        .generateAsync({ type: 'blob' })
        .then(blob => {
          saveAs(blob, `${zipFileName}.zip`);
        })
        .catch(() => {
          this.showErrorText('GALLERY_DOWNLOAD_ERROR');
        });
    },
    onDeleteMediaClick(media) {
      this.$refs.deleteMediaModal.show();
      this.mediaToDelete = media;
    },
    onDeleteSelectedMediaClicked() {
      this.$refs.multiMediaDeletionWarningModal.show();
    },
    emitSelectedMediaForEditor() {
      const selected = [];
      for (const media of this.albumMedia) {
        if (media.selected) {
          selected.push(media);
        }
      }
      this.$emit('selectedMediaUpdated', selected);
    },
    doSort(sort, sortOn) {
      this.resetAlbumDetails();
      this.sortName = sortOn;
      if (this.sortOn === sort) {
        this.orderDirection =
          this.orderDirection === orderDirections.ASCENDING ? orderDirections.DESCENDING : orderDirections.ASCENDING;
      } else {
        this.sortOn = sort;
        this.orderDirection = orderDirections.ASCENDING;
      }
      this.loadMoreMedias(true);
    },
    doFilter(filterName, filterBy) {
      filterName === 'MEDIA_FILTER_ALL' ? (this.filterName = 'GALLERY_FILTER') : (this.filterName = filterName);
      this.filterBy = filterBy;
      this.loadMoreMedias(true);
    },
    onMediaSelected(media) {
      this.selectMedia({ id: media.id, selected: !media.selected });
      if (this.isUploadParent) {
        this.emitSelectedMediaForEditor();
      }
    },
    openMedia(media) {
      if (media.conversionStatus === this.conversionStatus.PROCESSING) {
        return;
      }
      if (this.parent === parentTypes.GALLERY) {
        if (this.$route.name == 'myMedias') {
          this.$router.push({
            name: 'myMediaDetails',
            params: { mediaId: media.id },
          });
        } else if (this.$route.name == 'institutionProfileMedias') {
          this.$router.push({
            name: 'myMediaDetails',
            params: { mediaId: media.id },
          });
        } else {
          this.$router.push({
            name: 'mediaDetails',
            params: { mediaId: media.id, albumId: this.$route.params.albumId },
          });
        }
      } else if (this.parent === parentTypes.GROUP) {
        this.$router.push({
          name: 'group-media-details',
          params: {
            groupId: this.activeGroup.id,
            mediaId: media.id,
            albumId: this.$route.params.albumId,
          },
        });
      } else if (this.isUploadParent) {
        this.selectMedia({ id: media.id, selected: !media.selected });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.album-header {
  --group-page-label-margin: 0 10px;
  --groupd-modal-input-background-color: var(--color-grey-light);
  --input-background-base-color: var(--color-grey-light);

  .album-detail-tag-dropdown {
    --dropdown-menu-min-width: 400px;
  }
}
.action-button {
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: inherit;
  padding: 0 5px;
  margin: 0 5px;

  .download-spinner {
    position: absolute;
    right: -40px;
    width: 50px;
    height: 50px;
  }
}
</style>
