import jQuery from 'jquery';

export const highlight = jQuery.extend({
  highlight: function (node, re, nodeName, className) {
    if (node.nodeType === 3) {
      const match = node.data.match(re);
      if (match) {
        const highlight = document.createElement(nodeName || 'span');
        highlight.className = className || 'highlight';
        const wordNode = node.splitText(match.index);
        wordNode.splitText(match[0].length);
        const wordClone = wordNode.cloneNode(true);
        highlight.appendChild(wordClone);
        wordNode.parentNode.replaceChild(highlight, wordNode);
        return 1; // skip added node in parent
      }
    } else if (
      node.nodeType === 1 &&
      node.childNodes && // only element nodes that have children
      !/(script|style)/i.test(node.tagName) && // ignore script and style nodes
      !(node.tagName === nodeName.toUpperCase() && node.className === className)
    ) {
      // skip if already highlighted
      for (let i = 0; i < node.childNodes.length; i++) {
        i += jQuery.highlight(node.childNodes[i], re, nodeName, className);
      }
    }
    return 0;
  },
});

export const unhighlight = (jQuery.fn.unhighlight = function (options) {
  const settings = { className: 'highlight', element: 'span' };
  jQuery.extend(settings, options);

  return this.find(settings.element + '.' + settings.className)
    .each(function () {
      const parent = this.parentNode;
      parent.replaceChild(this.firstChild, this);
      parent.normalize();
    })
    .end();
});

export const highlighter = (jQuery.fn.highlight = function (words, options) {
  const settings = {
    className: 'highlight',
    element: 'span',
    caseSensitive: false,
    wordsOnly: false,
    wordsBoundary: '\\b',
  };
  jQuery.extend(settings, options);

  if (words.constructor === String) {
    words = [words];
  }
  words = jQuery.grep(words, function (word) {
    return word != '';
  });
  words = jQuery.map(words, function (word) {
    return word.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  });
  if (words.length === 0) {
    return this;
  }

  const flag = settings.caseSensitive ? '' : 'i';
  let pattern = '(' + words.join('|') + ')';
  if (settings.wordsOnly) {
    pattern =
      (settings.wordsBoundaryStart != null ? settings.wordsBoundaryStart : settings.wordsBoundary) +
      pattern +
      (settings.wordsBoundaryEnd != null ? settings.wordsBoundaryEnd : settings.wordsBoundary);
  }
  const re = new RegExp(pattern, flag);

  return this.each(function () {
    jQuery.highlight(this, re, settings.element, settings.className);
  });
});
