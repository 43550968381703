<template>
  <div class="opening-hour-and-closed-day-container">
    <template v-if="canAccessPresenceModule">
      <template v-if="isMobile && filteredInstitutions.length > 1">
        <div v-for="(institution, index) in filteredInstitutions" :key="'inst-' + index" class="box">
          <div
            class="box-title px-4"
            tabindex="0"
            role="button"
            @click="selectInstitutionMobileView(institution)"
            @keydown.enter="selectInstitutionMobileView(institution)"
          >
            {{ institution.name }}
            <i class="icon icon-Aula_forward-arrow pull-right" />
          </div>
        </div>
        <aula-modal
          ref="generalOpeningHourModal"
          :header-text="mobileHeaderText"
          :header-text-from-textkey="false"
          :show-ok="false"
          @cancelClicked="$refs.generalOpeningHourModal.hide()"
        >
          <aula-spinner v-if="isLoading" />
          <div v-show="!isLoading">
            <general-opening-hours :selected-institutions="selectedInstitutions" />
            <specific-opening-hours :selected-institutions="selectedInstitutions" />
            <holiday-and-closed-days :selected-institutions="selectedInstitutions" />
          </div>
        </aula-modal>
      </template>
      <template v-else>
        <aula-spinner v-if="isLoading" />
        <div v-show="!isLoading">
          <general-opening-hours :selected-institutions="selectedInstitutions" />
          <specific-opening-hours :selected-institutions="selectedInstitutions" />
          <holiday-and-closed-days :selected-institutions="selectedInstitutions" />
        </div>
      </template>
    </template>
    <div v-else class="px-2 px-lg-0">
      {{ 'PRESENCE_WARNING_NOT_MEMBER_OF_PRESENCE' | fromTextKey }}
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { types } from '../../store/types/types';
import { portalRoles } from '../../../shared/enums/portalRoles';
import GeneralOpeningHours from './GeneralOpeningHours';
import SpecificOpeningHours from './SpecificOpeningHours';
import HolidayAndClosedDays from './HolidayAndClosedDays.vue';

export default {
  data: function () {
    return {
      selectedInstitutionForMobile: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
      children: types.GET_CHILDREN,
      activeChildIds: types.GET_ACTIVE_CHILDREN,
      institutions: types.GET_INSTITUTIONS,
      getPresenceStates: types.GET_PRESENCE_STATES_GUARDIAN,
      presenceFilters: types.GET_PRESENCE_FILTERS,
      profile: types.GET_CURRENT_PROFILE,
      departments: types.GET_DEPARTMENTS,
    }),
    canAccessPresenceModule() {
      if (this.profile.role === portalRoles.EMPLOYEE) {
        return this.departments.length > 0;
      }
      return true;
    },
    filteredInstitutions() {
      if (this.profile.role === portalRoles.EMPLOYEE) {
        const presenceInstitutionCodes = this.presenceFilters.map(item => item.institutionCode);
        return this.institutions.filter(institution => presenceInstitutionCodes.includes(institution.institutionCode));
      }

      const presenceChildren = this.getPresenceStates.filter(child => this.activeChildIds.includes(child.uniStudentId));
      const childInstitutionCodes = presenceChildren.map(child => child.uniStudent.institutionCode);
      return this.institutions.filter(institution => childInstitutionCodes.includes(institution.institutionCode));
    },
    selectedInstitutions() {
      return this.isMobile && this.filteredInstitutions.length > 1
        ? [this.selectedInstitutionForMobile]
        : this.filteredInstitutions;
    },
    mobileHeaderText() {
      return this.selectedInstitutionForMobile?.name || '';
    },
  },
  methods: {
    ...mapActions({
      loadSpecificOpeningHoursForGuardian: types.LOAD_SPECIFIC_OPENING_HOURS_FOR_GUARDIAN,
      loadGeneralOpeningHoursForGuardian: types.LOAD_GENERAL_OPENING_HOURS_FOR_GUARDIAN,
      loadHolidayClosedDays: types.LOAD_HOLIDAY_CLOSED_DAY,
    }),
    selectInstitutionMobileView(institution) {
      this.selectedInstitutionForMobile = institution;
      this.$refs.generalOpeningHourModal.show();
      this.getOpeningHoursAndClosedDays();
    },
    getOpeningHoursAndClosedDays() {
      if (this.selectedInstitutions.length === 0) {
        return;
      }
      this.isLoading = true;
      const institutionCodes = this.selectedInstitutions.map(inst => inst.institutionCode);
      const specificOpeningHours = this.loadSpecificOpeningHoursForGuardian({ institutionCodes });
      const generalOpeningHours = this.loadGeneralOpeningHoursForGuardian({ institutionCodes });
      const holidayClosedDays = this.loadHolidayClosedDays({ institutionCodes });

      Promise.all([specificOpeningHours, generalOpeningHours, holidayClosedDays]).then(() => {
        this.isLoading = false;
      });
    },
  },
  components: {
    SpecificOpeningHours,
    GeneralOpeningHours,
    HolidayAndClosedDays,
  },
  watch: {
    selectedInstitutions() {
      this.getOpeningHoursAndClosedDays();
    },
  },
  mounted() {
    this.getOpeningHoursAndClosedDays();
  },
};
</script>
<style lang="scss" scoped>
@import '../../../shared/assets/scss/core/variables';
@import '../../../shared/assets/scss/core/breakpoints';
@import '../../../shared/assets/scss/components/presence/presence';
@import '../../../shared/assets/scss/components/presence/presence-employee';
</style>
