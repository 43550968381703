





























import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import { mapActions, mapGetters } from 'vuex';
import { types } from '../../store/types/types.js';
import Avatar from '../../../shared/components/Avatar.vue';
import ProfileFilterGroupHome from './ProfileFilterGroupHome.vue';
import button from '../../../shared/directives/button.js';
import { InstitutionProfileModel } from '../../../shared/models/institutionProfile.model';

export default {
  components: { ProfileFilterGroupHome, Avatar },
  directives: {
    button,
  },
  props: {
    canUseGroupHomeFilter: { type: Boolean, default: false },
  },
  data() {
    return {
      selectedProfiles: [],
    };
  },
  computed: {
    ...mapGetters({
      profile: types.GET_CURRENT_PROFILE,
      children: types.GET_CHILDREN,
      activeChildIds: types.GET_ACTIVE_CHILDREN,
      activeInstitutionCodes: types.GET_ACTIVE_INSTITUTIONS,
    }),
    iconClassEnum() {
      return iconClassEnum;
    },
  },
  mounted() {
    this.selectedProfiles = this.activeChildIds;
  },
  methods: {
    ...mapActions({
      setActiveChildren: types.SET_ACTIVE_CHILDREN,
      loadNotifications: types.LOAD_NOTIFICATIONS,
    }),
    handleProfilesSelect(childIds) {
      if (childIds.length < 1) {
        this.selectedProfiles = this.activeChildIds;
        return;
      }
      const activeChildren = this.children.filter(child => childIds.includes(child.id));
      this.setActiveChildren(activeChildren);
      this.loadNotifications({
        activeChildrenIds: this.activeChildIds,
        activeInstitutionCodes: this.activeInstitutionCodes,
      });
    },
    toggleCheckbox(event: Event) {
      const checkbox: HTMLInputElement = (event.currentTarget as HTMLElement).querySelector('input[type="checkbox"]');
      checkbox.click();
    },
    handleGroupHomeChanged() {
      this.selectedProfiles = this.activeChildIds;
    },
    getCheckboxDisabledState(profile) {
      return this.activeChildIds.length === 6 && this.activeChildIds.includes(profile.id) === false;
    },
    getOptionTooltip(profile: InstitutionProfileModel) {
      if (this.getCheckboxDisabledState(profile)) {
        return this.$options.filters.fromTextKey('LIMIT_NUMBER_OF_ACTIVE_INSTITUTION_PROFILES');
      }
      return '';
    },
  },
};
