<template>
  <div class="aula-posts-aside-container">
    <div class="daily-overview-container">
      <daily-overview v-if="$route.name === 'overblik'" />
    </div>
    <div class="mask" />
    <div class="aula-posts-aside-overview">
      <birthdays />
      <widget-placeholder :list-of-widgets="widgets" class="mt-4" placement="narrow" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DailyOverview from '../shared/DailyOverview.vue';
import Birthdays from '../shared/Birthdays.vue';
import { types } from '../../store/types/types';
import WidgetPlaceholder from '../widgets/WidgetPlaceholder';
import { moduleWidgetPlacements } from '../../../shared/enums/moduleWidgetPlacements';
import moment from 'moment-timezone';

export default {
  data: function () {
    return {
      moment: moment,
      moduleWidgetPlacements: moduleWidgetPlacements,
    };
  },
  computed: {
    ...mapGetters({
      notifications: types.GET_NOTIFICATIONS,
      menuItems: types.GET_MENU_ITEMS,
      profile: types.GET_CURRENT_PROFILE,
    }),
    widgets() {
      return this.menuItems != undefined ? this.menuItems[moduleWidgetPlacements.RIGHT_OF_OVERVIEW] : [];
    },
  },
  components: {
    DailyOverview,
    Birthdays,
    WidgetPlaceholder,
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.aula-posts-aside-container {
  position: relative;
  display: none;
  background-color: $color-grey-base;
  min-height: 100%;
  overflow: visible;
  box-shadow: var(--box-shadow-base);

  @include breakpoint-lg() {
    display: flex;
  }

  .daily-overview-container {
    width: 100%;
    flex: 0 0 auto;
  }

  .aula-posts-aside-overview {
    flex: 0 0 auto;
    position: relative;
    min-height: 100%;
    font-size: 18px;
    padding: 28px 25px 0 25px;
    background-color: $color-grey-base;
    width: 100%;
    margin-left: -100%;
    z-index: 10;

    /deep/ .quick-access-item {
      margin-top: 10px;
      height: 70px;
      line-height: 70px;
      padding: 0 25px;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      font-size: 15px;
      background: $color-white;
      &:hover {
        background: $color-grey-light;
      }
    }
  }

  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $color-grey-base;
    z-index: 10;
  }
}
</style>
