var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isSectionVisible)?_c('div',{staticClass:"holiday-closed-days-container"},[(_vm.isMobile)?_c('div',{staticClass:"mobile-box-title"},[_vm._v("\n    "+_vm._s(_vm._f("fromTextKey")('PRESENCE_HOLIDAY_CLOSED_DAYS'))+"\n  ")]):_c('h2',[_vm._v(_vm._s(_vm._f("fromTextKey")('PRESENCE_HOLIDAY_CLOSED_DAYS')))]),_vm._v(" "),_vm._l((_vm.splitHolidayClosedDaysData),function(subList,key){return [_c('div',{key:'table-' + key,staticClass:"table",class:{
        'w-50': subList.length === 1 && _vm.selectedInstitutions.length !== 1,
      }},[_c('div',{staticClass:"table-row header"},[(_vm.selectedInstitutions.length === 1)?[_c('div',{staticClass:"table-cell date-header"},[_vm._v("\n            "+_vm._s(_vm._f("fromTextKey")(_vm.selectedInstitutions.length === 1 ? 'PRESENCE_DATE_LABEL' : ''))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"table-cell institution-header"},[_vm._v("\n            "+_vm._s(_vm._f("fromTextKey")('PRESENCE_HOLIDAY_CLOSED_DAYS_NAME'))+"\n          ")])]:_vm._l((subList),function(institutionHolidayClosedDaysData){return _c('div',{key:institutionHolidayClosedDaysData.institutionCode,staticClass:"table-cell institution-header w-50"},[_vm._v("\n            "+_vm._s(_vm.getInstitutionName(institutionHolidayClosedDaysData.institutionCode))+"\n          ")])})],2),_vm._v(" "),_vm._l((_vm.getNumberOfRowsForSubGroupTable(subList)),function(index){return _c('div',{key:index,staticClass:"table-row body"},[(_vm.selectedInstitutions.length === 1 || _vm.isMobile)?[(subList[0].closedDaysOverview.closedDays[index - 1])?[_c('div',{staticClass:"table-cell date-cell"},[_vm._v("\n              "+_vm._s(_vm.getFormatDatePeriod(
                  subList[0].closedDaysOverview.closedDays[index - 1].startDate,
                  subList[0].closedDaysOverview.closedDays[index - 1].endDate
                ))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"table-cell"},[_vm._v("\n              "+_vm._s(subList[0].closedDaysOverview.closedDays[index - 1].name)+"\n            ")])]:_vm._e()]:_vm._l((subList),function(institutionHolidayClosedDaysData){return _c('div',{key:'cell-' + institutionHolidayClosedDaysData.institutionCode,staticClass:"table-cell"},[(institutionHolidayClosedDaysData.closedDaysOverview.closedDays[index - 1])?[_c('div',{staticClass:"d-flex mb-1"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm._f("fromTextKey")('PRESENCE_DATE_LABEL'))+":")]),_vm._v("\n                "+_vm._s(_vm.getFormatDatePeriod(
                    institutionHolidayClosedDaysData.closedDaysOverview.closedDays[index - 1].startDate,
                    institutionHolidayClosedDaysData.closedDaysOverview.closedDays[index - 1].endDate
                  ))+"\n              ")]),_vm._v(" "),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm._f("fromTextKey")('PRESENCE_HOLIDAY_CLOSED_DAYS_NAME'))+":")]),_vm._v("\n                "+_vm._s(institutionHolidayClosedDaysData.closedDaysOverview.closedDays[index - 1].name)+"\n              ")])]:_vm._e()],2)})],2)})],2)]})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }