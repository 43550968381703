<template>
  <div>
    <label :for="id"><slot name="aula-search" /></label>
    <AulaSearchRecipients
      :id="id"
      v-bind="{ ...$props, ...$attrs }"
      :scope-employees-to-institution="false"
      @emitSelected="$emit('change', $event)"
    />
  </div>
</template>

<script>
import AulaSearchRecipients from '../../components/AulaSearchRecipients.vue';

export default {
  name: 'MessageAdvancedSearchAulaSearch',
  components: {
    AulaSearchRecipients,
  },
  data() {
    return {
      id: null,
    };
  },
  mounted() {
    this.id = String(this._uid);
  },
};
</script>

<style lang="scss" scoped></style>
