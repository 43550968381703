<template>
  <aula-modal
    ref="notificationMobile"
    css-class="color-grey-light p-0"
    header-text="USERS_LABEL_NOTIFICATION_SETTINGS"
    :show-ok="false"
    @cancelClicked="goBack"
  >
    <aula-list class="aula-list">
      <aula-list-item v-button class="aula-list-item color-grey-base" @click="openNotificationAreaClearMobile()">
        <template>
          <span class="font-weight-bold">
            {{ 'NOTIFICATION_CLEAR_ALL_TITLE' | fromTextKey }}
          </span>
        </template>
        <template #end>
          <i class="icon-Aula_forward-arrow" />
        </template>
      </aula-list-item>
      <aula-list-item v-button class="aula-list-item color-grey-base" @click="openNotificationSettingMobileModal()">
        <template>
          <span class="font-weight-bold">
            {{ 'NOTIFICATION_SETTINGS_TITLE' | fromTextKey }}
          </span>
        </template>
        <template #end>
          <i class="icon-Aula_forward-arrow" />
        </template>
      </aula-list-item>
    </aula-list>
    <aula-modal
      ref="notificationAreaClearMobile"
      css-class="color-grey-light p-0"
      header-text="NOTIFICATION_CLEAR_ALL_TITLE"
      ok-text="NOTIFICATION_CLEAR_ALL_MOBILE_BUTTON"
      :show-ok="selectedModules.length !== 0"
      @cancelClicked="backToNotificationMobile"
      @okClicked="$refs.confirmationModal.show()"
    >
      <notification-area-clear-mobile
        :notification-setting-model="notificationSettingModel"
        :available-areas="availableAreas"
        @updateAreaDictionary="updateAreaDictionary"
      />
    </aula-modal>
    <aula-modal
      ref="confirmationModal"
      header-text="WARNING_MODAL"
      ok-text="BUTTON_YES"
      cancel-text="CANCEL"
      @cancelClicked="$refs.confirmationModal.hide()"
      @okClicked="deleteNotificationsFromModule"
    >
      {{ 'NOTIFICATION_CLEAR_ALL_WARNING_1' | fromTextKey }}<br />
      {{ 'NOTIFICATION_CLEAR_ALL_WARNING_2' | fromTextKey }}
    </aula-modal>
    <aula-modal ref="notificationSettingMobile" ok-text="BUTTON_YES" css-class="p-0">
      <notification-setting-mobile
        :is-loading="isLoading"
        :is-view-mode="isViewMode"
        :available-areas="availableAreas"
        :profile="profile"
        :every-day="everyDay"
        :show-no-day-selected="showNoDaySelected"
        :show-select-days="showSelectDays"
        :notification-setting-model="notificationSettingModel"
        @goBackNotification="goBackNotification"
        @updateViewMode="updateViewMode"
        @initEditMode="initEditMode"
        @setAllSelectedDays="setAllSelectedDays"
        @updateEveryDay="updateEveryDay"
        @resetTable="resetTable"
        @saveChanges="saveChanges"
      />
    </aula-modal>
  </aula-modal>
</template>

<script>
import { notificationProviderKeyEnum } from '../../enums/notificationProviderKeyEnum';
import NotificationSettingMobile from './NotificationSettingMobile';
import NotificationAreaClearMobile from './NotificationAreaClearMobile';
import AulaList from '../../components/AulaList';
import AulaListItem from '../../components/AulaListItem';
import button from '../../directives/button';

export default {
  name: 'NotificationMobile',
  directives: {
    button,
  },
  components: {
    AulaListItem,
    AulaList,
    NotificationSettingMobile,
    NotificationAreaClearMobile,
  },
  inject: {
    getNotificationSettingModel: notificationProviderKeyEnum.NOTIFICATION_SETTING_MODEL,
  },
  props: {
    isViewMode: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    availableAreas: { type: Array, default: () => [] },
    profile: { type: Object, default: null },
    everyDay: { type: Boolean, default: null },
    showNoDaySelected: { type: Boolean, default: null },
    showSelectDays: { type: Boolean, default: null },
  },
  data: function () {
    return {
      areaDictionary: {},
      selectedModules: [],
    };
  },
  computed: {
    notificationSettingModel() {
      return this.getNotificationSettingModel();
    },
  },
  mounted() {
    this.$refs.notificationMobile.show();
  },
  methods: {
    goBack() {
      this.$router.push({ name: 'overblik' });
    },
    backToNotificationMobile() {
      this.selectedModules = [];
      this.areaDictionary = {};
      this.$refs.notificationAreaClearMobile.hide();
    },
    updateAreaDictionary(moduleValue, value) {
      this.areaDictionary[moduleValue] = value;
      this.selectedModules = Object.keys(this.areaDictionary).filter(key => this.areaDictionary[key] === true);
    },
    deleteNotificationsFromModule() {
      this.notificationSettingModel.setSelectedAreas(this.selectedModules);
      this.$emit('deleteNotificationsFromModule');
      this.$refs.confirmationModal.hide();
      this.$refs.notificationAreaClearMobile.hide();
      this.selectedModules = [];
      this.areaDictionary = {};
    },
    goBackNotification() {
      this.$refs.notificationSettingMobile.hide();
    },
    openNotificationSettingMobileModal() {
      this.$refs.notificationSettingMobile.show();
    },
    openNotificationAreaClearMobile() {
      this.$refs.notificationAreaClearMobile.show();
    },
    initEditMode() {
      this.$emit('initEditMode');
    },
    saveChanges() {
      this.$emit('saveChanges');
    },
    updateViewMode(value) {
      this.$emit('updateViewMode', value);
    },
    updateEveryDay(value) {
      this.$emit('updateEveryDay', value);
    },
    resetTable() {
      this.$emit('resetTable');
    },
    setAllSelectedDays() {
      this.$emit('setAllSelectedDays');
    },
  },
};
</script>

<style lang="scss" scoped>
.aula-list-item {
  padding: 14px 24px;
  border-bottom-width: 1px;
}

.aula-list {
  padding: 0;
}
</style>
