<template>
  <div class="presence-absence">
    <sickness-reports :is-loading-presence-state="isLoadingPresenceState" />
    <vacation-announcements />
    <vacation-registrations />
  </div>
</template>
<script>
import { types } from '../../store/types/types';
import { mapActions, mapGetters } from 'vuex';
import { presenceStates } from '../../../shared/enums/presenceStates';
import { presenceModules } from '../../../shared/enums/presenceModules';
import SicknessReports from './SicknessReports';
import VacationRegistrations from './VacationRegistrations';
import VacationAnnouncements from './VacationAnnouncements';

export default {
  components: { VacationAnnouncements, VacationRegistrations, SicknessReports },
  data: function () {
    return {
      presenceStates: presenceStates,
      presenceModules: presenceModules,
      isLoadingPresenceState: false,
    };
  },
  computed: {
    ...mapGetters({
      children: types.GET_CHILDREN,
      activeChildren: types.GET_ACTIVE_CHILDREN,
      isMobile: types.GET_IS_MOBILE,
      getPresenceStates: types.GET_PRESENCE_STATES_GUARDIAN,
    }),
  },
  methods: {
    ...mapActions({
      loadPresenceStates: types.LOAD_PRESENCE_STATES,
      loadPresenceConfigurationByChildIds: types.LOAD_PRESENCE_CONFIGURATIONS_CHILD_IDS,
    }),
    loadPresenceStateByChildIds() {
      const activeChildren = this.children.filter(child => this.activeChildren.includes(child.id));
      if (activeChildren.length > 0) {
        const childIds = activeChildren.map(child => child.id);
        this.isLoadingPresenceState = true;
        this.loadPresenceStates({ institutionProfileIds: childIds }).then(() => {
          if (this.getPresenceStates.length > 0) {
            const presenceChildrenIds = this.getPresenceStates.map(s => s.uniStudentId);
            this.loadPresenceConfigurationByChildIds({
              childIds: presenceChildrenIds,
            }).then(() => {
              this.isLoadingPresenceState = false;
            });
          }
        });
      }
    },
  },
  watch: {
    activeChildren: function () {
      this.loadPresenceStateByChildIds();
    },
  },
  mounted() {
    this.loadPresenceStateByChildIds();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/presence/_presence.scss';
.mobile-aula-spinner {
  position: absolute;
  width: 60px;
  height: 60px;
  top: -15px !important;
  left: 5px;
}
.submit-loading {
  position: absolute;
  width: 80px;
  height: 80px;
}
</style>
