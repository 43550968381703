<template>
  <b-card class="search-result-item" tabindex="0" @keypress.enter="goToEventDetail" @click="goToEventDetail">
    <div class="search-result-icon-col">
      <template v-if="isChild">
        <img class="menu-icon" :src="'static/icons/icon-Aula_calendar_child.svg'" />
      </template>
      <i v-else class="icon-Aula_calendar" />
    </div>
    <div class="search-result-text-col">
      <div class="card-header-text">
        <keyword-highlight :queries="searchString">
          {{ eventItem.title }}
        </keyword-highlight>
      </div>
      <ul class="card-text list-unstyled">
        <li v-if="eventItem.description != ''">
          {{ 'SEARCH_DESC_LABEL' | fromTextKey }}:
          <span v-html="$options.filters.highlightKeyword(eventItem.description, searchString)" />
        </li>
        <template v-if="eventItem.type === eventTypes.PRESENCE_HOLIDAY">
          <li>{{ moment(eventItem.startDateTime).utc() | longDate }}</li>
          <li>{{ moment(eventItem.endDateTime).utc() | longDate }}</li>
        </template>
        <template v-else>
          <li>
            {{ eventItem.startDateTime | longDate }}
            {{ eventItem.startDateTime | time }}
          </li>
          <li>
            {{ eventItem.endDateTime | longDate }}
            {{ eventItem.endDateTime | time }}
          </li>
        </template>
        <li v-if="eventItem.location">
          {{ eventItem.location }}
        </li>
        <li v-if="eventItem.type !== eventTypes.PRESENCE_HOLIDAY">
          {{ 'SEARCH_CREATOR_BY_LABEL' | fromTextKey }}:
          <keyword-highlight :queries="searchString">
            {{ eventItem.creatorAulaName }}
          </keyword-highlight>
        </li>
      </ul>
    </div>
  </b-card>
</template>

<script>
import { mapActions } from 'vuex';
import KeywordHighlight from 'vue-text-highlight';
import { types } from '../../store/types/types';
import moment from 'moment-timezone';
import { eventTypeEnum } from '../../../shared/enums/eventTypeEnum';

export default {
  props: {
    eventItem: { type: Object, default: () => {} },
    searchString: { type: String, default: '' },
    parentSource: { type: String, default: null },
    validGroupModules: { type: Array, default: () => [] },
    numberOfMenuItems: { type: Number, default: 4 },
    isChild: { type: Boolean, default: false },
  },
  data: function () {
    return {
      moment: moment,
      eventTypes: eventTypeEnum,
    };
  },
  methods: {
    ...mapActions({
      setEventSelectedFromSearch: types.SET_EVENT_SELECTED_FROM_SEARCH,
      resetActiveGroup: types.RESET_ACTIVE_GROUP,
    }),
    goToEventDetail: function () {
      let groupCalendar = null;
      let i = 0;
      for (const module of this.validGroupModules) {
        if (module.showOnDashboard && i < this.numberOfMenuItems && module.module.type === 'calendar') {
          groupCalendar = module;
          break;
        }
        i++;
      }
      this.setEventSelectedFromSearch(this.eventItem);
      if (groupCalendar != null && this.parentSource === 'GROUP') {
        this.$router.push({ name: 'group-' + groupCalendar.module.type });
      } else {
        this.resetActiveGroup();
        this.$router.push({ name: 'calendar' });
      }
    },
  },
  components: {
    KeywordHighlight,
  },
};
</script>

<style scoped lang="scss">
.card-text {
  text-transform: capitalize;
}
</style>
