<template>
  <div class="media-action-mobile-view">
    <mobile-topbar :text="topbarHeaderText" :header-text-from-textkey="false" @leftIconClicked="goBack()" />
    <AulaButtons class="media-action-navbar">
      <AulaButton v-if="canEditTag" variant="link" class="m-0" @click="openMediaTagHandling">
        <i class="icon-tag mr-1" />
        {{ 'GALLERY_ADD_TAGS' | fromTextKey }}
      </AulaButton>
      <AulaButton variant="link" class="action-button" :disabled="isDownloading" @click="onDownloadMediaClicked">
        <Icon :name="iconClassEnum.DOWNLOAD" />
        {{ 'GALLERY_DOWNLOAD_MULTIPLE_MEDIA' | fromTextKey }}
        <AulaSpinner v-if="isDownloading" class="action-spinner" />
      </AulaButton>
      <AulaButton variant="link" class="action-button" :disabled="!canDelete" @click="onDeleteMediaClicked">
        <Icon :name="iconClassEnum.BIN" />
        {{ 'GALLERY_DELETE_MULTIPLE_MEDIA' | fromTextKey }}
      </AulaButton>
    </AulaButtons>
    <MediaList class="media-list" :media-list="selectedMedias" :show-delete-media="false" />
  </div>
</template>

<script>
import MediaList from '../../../shared/PageFragments/Media/MediaList.vue';
import MobileTopbar from '../../../shared/components/MobileTopbar.vue';
import Icon from '../../../shared/components/Icon.vue';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import Button from '../../../shared/directives/button';
import AulaButtons from '../../../shared/components/AulaButtons.vue';
import AulaButton from '../../../shared/components/AulaButton.vue';

export default {
  name: 'MediaManagementMobile',
  directives: {
    Button,
  },
  components: { AulaButton, AulaButtons, Icon, MobileTopbar, MediaList },
  props: {
    selectedMedias: { type: Array, default: () => [] },
    topbarHeaderText: { type: String, default: '' },
    isDownloading: { type: Boolean, default: false },
    canDelete: { type: Boolean, default: false },
    canEditTag: { type: Boolean, default: false },
  },
  emits: ['goBack', 'openMediaTagHandling', 'download'],
  data() {
    return {
      iconClassEnum,
    };
  },
  methods: {
    goBack() {
      this.$emit('goBack');
    },
    openMediaTagHandling() {
      this.$emit('openMediaTagHandling');
    },
    onDownloadMediaClicked() {
      this.$emit('download');
    },
    onDeleteMediaClicked() {
      this.$emit('delete');
    },
  },
};
</script>

<style scoped lang="scss">
.media-action-mobile-view {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background: var(--color-grey-light);
  z-index: var(--modal-z-index);

  .media-action-navbar {
    padding: 10px 20px;
    border-bottom: 1px solid var(--color-grey-dunkel);
  }
}

.action-button {
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 5px;
  margin: 0 5px;

  .action-spinner {
    position: absolute;
    right: -40px;
    width: 50px;
    height: 50px;
  }
}
</style>
