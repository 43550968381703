<template>
  <div>
    <slot />
    <p v-if="isAllowedToSeeCustody && showParentCustody" class="m-0">
      {{ 'HAS_CUSTODY' | fromTextKey }}: {{ hasCustodyAnswerText }}
    </p>
    <p v-if="!profile.userHasGivenConsentToShowContactInformation" class="font-italic">
      {{ notAllowedToSeeContactMessage }}
    </p>
  </div>
</template>
<script lang="js">
import {textUtil} from "../../utils/textUtil";
import {contactsUtil} from "../../utils/contactsUtil";

export default {
  name: 'ContactsFragmentContactMetadata',
  props: {
    showParentCustody: {
      required: false,
      default: () => false,
      type: Boolean,
    },
    profile: {
      required: true,
      type: Object,
    }
  },
  computed: {
    hasCustodyAnswerText() {
      if (!this.isAllowedToSeeCustody) {
        return;
      }

      let textKey = 'NO';
      if (contactsUtil.getHasCustodyFromRelation(this.profile)) {
        textKey = 'YES';
      }

      return this.$options.filters.fromTextKey(textKey);
    },
    notAllowedToSeeContactMessage() {
      return `${textUtil.getGenitiveName(this.profile.firstName)} ${this.$options.filters.fromTextKey(
        'CONTACT_DOES_NOT_APPEAR_IN_AULA'
      )}`;
    },
    isAllowedToSeeCustody() {
      return contactsUtil.getHasCustodyFromRelation(this.profile) != null;
    }
  }
}
</script>
