<template>
  <b-card
    class="search-result-item margin-bottom-20"
    tabindex="0"
    @keypress.enter="openDocument(result)"
    @click="openDocument(result)"
  >
    <div class="search-result-icon-col">
      <template v-if="isChild">
        <img class="menu-icon" :src="'static/icons/icon-Aula_paper_child.svg'" />
      </template>
      <i v-else class="icon-Aula_paper" />
    </div>
    <div class="search-result-text-col">
      <div class="card-header-text">
        <keyword-highlight :queries="searchString">
          {{ result.title }}
        </keyword-highlight>
      </div>
      <div class="card-text">
        {{ 'SEARCH_CREATED_LABEL' | fromTextKey }}: {{ result.created | shortDate(true) }}<br />
        {{ result.institutionName }}
        <template v-if="result.docType != docTypes.IMPORTANT_FILE">
          {{ 'SEARCH_CREATOR_BY_LABEL' | fromTextKey }}:
          <keyword-highlight :queries="searchString"> {{ result.creatorName }} </keyword-highlight><br />
          <template v-if="isSteppedUp">
            {{ 'SEARCH_CATEGORY_LABEL' | fromTextKey }}:
            <keyword-highlight :queries="searchString"> {{ result.category }} </keyword-highlight><br />
            {{ 'SEARCH_ASSOCIATION_LABEL' | fromTextKey }}:
            <keyword-highlight :queries="searchString"> {{ associations }} </keyword-highlight><br />
          </template>
        </template>
      </div>
    </div>
  </b-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import KeywordHighlight from 'vue-text-highlight';
import { types } from '../../store/types/types';
import moment from 'moment-timezone';
import { docTypes } from '../../../shared/enums/docTypes';

export default {
  props: {
    result: { type: Object, default: () => {} },
    searchString: { type: String, default: null },
    isChild: { type: Boolean, default: false },
  },
  data: function () {
    return {
      moment: moment,
      docTypes: docTypes,
    };
  },
  computed: {
    ...mapGetters({
      isSteppedUp: types.GET_GLOBAL_STEPPED_UP,
    }),
    associations() {
      let associations = '';
      if (this.result.childAssociations?.length > 0) {
        associations = this.result.childAssociations.map(child => child.firstName + ' ' + child.lastName).join(', ');
      }
      if (this.result.groupAssociations?.length > 0) {
        if (associations !== '') {
          associations += ', ';
        }
        associations += this.result.groupAssociations.map(group => group.name).join(', ');
      }
      return associations;
    },
  },
  methods: {
    ...mapMutations({
      setStepUpNotification: types.MUTATE_SET_NOTIFICATION_STEP_UP,
    }),
    openDocument(document) {
      if (document.docType == docTypes.IMPORTANT_FILE) {
        window.location.href = document.url;
      } else {
        if (this.isSteppedUp) {
          if (document.docType == docTypes.INTERNAL_SECURE_FILE) {
            this.$router.push('dokumenter/sikre/internal/' + document.id);
          } else if (document.docType == docTypes.EXTERNAL_SECURE_FILE) {
            this.$router.push('dokumenter/sikre/external/' + document.id);
          }
        } else {
          let url = '';
          if (document.docType == docTypes.INTERNAL_SECURE_FILE) {
            url = this.$options.filters.getSiteUrl('portal') + '/#/dokumenter/sikre/internal/' + document.id;
          } else if (document.docType == docTypes.EXTERNAL_SECURE_FILE) {
            url = this.$options.filters.getSiteUrl('portal') + '/#/dokumenter/sikre/external/' + document.id;
          }
          this.setStepUpNotification({
            showStepUpNotification: true,
            redirectedUrl: url,
          });
        }
      }
    },
  },
  components: {
    KeywordHighlight,
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.card-text {
  text-transform: capitalize;
}
</style>
