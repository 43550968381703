export enum revisionChangeTypeEnum {
  SHARED = 'shared',
  UNSHARED = 'unshared',
  IMPLICIT_UNSHARED = 'implicit_unshared',
  LOCKED = 'locked',
  UNLOCKED = 'unlocked',
  EDITED = 'edited',
  EXPORTED = 'exported',
  CREATED = 'created',
  EDIT_PERMISSION_ADDED = 'permission_added',
  EDIT_PERMISSION_REMOVED = 'permission_removed',
  RESTORED = 'restored',
  DELETED = 'deleted',
}
