<template>
  <article class="message message-deleted">
    <b-row class="row-no-margin">
      <b-col cols="2" />
      <b-col cols="8" class="p-0">
        <div class="message-body system-message">
          {{ 'MESSAGE_IS_DELETED' | fromTextKey }}
          <div class="message-meta text-right">
            <post-time class="message-sent-time" :time="message.deletedAt" variation="long" />
          </div>
        </div>
      </b-col>
    </b-row>
  </article>
</template>
<script>
export default {
  props: {
    message: { type: Object, default: () => {} },
  },
};
</script>
