<template>
  <div class="onboarding-step2" :class="{ 'has-warning': $parent.showWarningAdditional }">
    <div class="step2-content scrollbar">
      <consent-additional
        ref="additionalTable"
        :data="additionalDataForOnboarding"
        type="additional"
        @showWarning="showWarning"
      />
    </div>
    <b-alert variant="danger" class="warning" :show="$parent.showWarningAdditional">
      {{ 'WARNING_ANSWER_ADDITIONAL' | fromTextKey }}
    </b-alert>
  </div>
</template>

<script>
import { types } from '../../store/types/types';
import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';
import ConsentAdditional from '../../../shared/components/consentAdditional/ConsentAdditional';

export default {
  data: function () {
    return {
      isLoading: true,
      stepIndex: null,
    };
  },
  computed: {
    ...mapGetters({
      additionalDataForOnboarding: types.GET_ADDITIONAL_DATA_FOR_ONBOARDING,
    }),
  },
  methods: {
    ...mapActions({
      loadAdditionalData: types.LOAD_ADDITIONAL_DATA,
    }),
    validatedAdditionalAnswer(isSubmitted) {
      return this.$refs.additionalTable.validateAdditionalAnswered(isSubmitted);
    },
    submitAdditionalData() {
      return this.$refs.additionalTable.submitAdditionalTable();
    },
    showWarning(isPassed) {
      if (this.$parent.showWarningAdditional) {
        this.$parent.showWarningAdditional = !isPassed;
      }
    },
  },
  mounted() {
    const em = this;
    this.stepIndex = this.$parent.stepStatus.findIndex(s => s.step === 'additional');
    if (this.stepIndex !== -1 && this.additionalDataForOnboarding == null) {
      this.loadAdditionalData({ isOnboarding: true }).then(() => {
        em.isLoading = false;
      });
    } else {
      em.isLoading = false;
    }
  },
  components: {
    'consent-additional': ConsentAdditional,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/core/animations.scss';
@import '../../../shared/assets/scss/components/login/onboarding.scss';
</style>
