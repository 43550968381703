export default class PollingService {
  #fn;
  #waitingTimeInSecond;
  #cycleCount;
  #isRunning;
  #isMaximumLimitReached;
  #maxWaitingInterval;
  #waitingTimeout;

  constructor(fn, maxWaitingInterval = 300) {
    this.#fn = fn;
    this.#isRunning = false;
    this.#cycleCount = 0;
    this.#waitingTimeInSecond = 2;
    this.#maxWaitingInterval = maxWaitingInterval;
    this.#isMaximumLimitReached = false;
  }

  async start() {
    if (this.#isRunning) {
      return;
    }

    this.#isRunning = true;
    while (this.#isRunning) {
      if (!this.#isMaximumLimitReached) {
        this.#cycleCount++;
        this.#calculateWaitingTime();
      }

      await this.#wait();

      try {
        await this.#fn();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    }
  }

  stop() {
    this.#isRunning = false;
    this.#stopWaiting();
    this.reset();
  }

  reset() {
    this.#cycleCount = 0;
    this.#waitingTimeInSecond = 2;
    this.#isMaximumLimitReached = false;
  }

  async #wait() {
    return new Promise(resolve => {
      this.#waitingTimeout = setTimeout(() => {
        resolve();
      }, this.#waitingTimeInSecond * 1000);
    });
  }

  #stopWaiting() {
    clearTimeout(this.#waitingTimeout);
  }

  #calculateWaitingTime() {
    const interval = 2 ** this.#cycleCount;
    this.#isMaximumLimitReached = interval > this.#maxWaitingInterval;
    this.#waitingTimeInSecond = this.#isMaximumLimitReached ? this.#maxWaitingInterval : interval;
  }
}
