<template>
  <div class="group-members-container page-layout-padding">
    <h3>{{ 'GROUP_ADMINISTRATION_MEMBER' | fromTextKey }}:</h3>
    <h4 class="text-uppercase">{{ 'EMPLOYEES' | fromTextKey }}</h4>
    <aula-table
      v-if="employeeMembers.length > 0"
      :total-rows="employeeMembers.length"
      :show-pagination="employeeMembers.length > 200"
      :items="employeeMembers"
      :striped="false"
      class="group-member"
      :fields="employeeField"
      :parent="parentTypes.PORTAL"
      table-ref="employeeMembersTable"
    />
    <div v-else class="group-empty-text">
      {{ getGroupEmptyText(portalRoles.EMPLOYEE) }}
    </div>
    <h4>{{ 'CHILDREN' | fromTextKey }}</h4>
    <aula-table
      v-if="childMembers.length > 0"
      :total-rows="childMembers.length"
      :show-pagination="childMembers.length > 200"
      :items="childMembers"
      :striped="false"
      class="group-member"
      :fields="childField"
      :parent="parentTypes.PORTAL"
      table-ref="childMembersTable"
    />
    <div v-else class="group-empty-text">
      {{ getGroupEmptyText(portalRoles.CHILD) }}
    </div>
    <h4>{{ 'PARENTS' | fromTextKey }}</h4>
    <aula-table
      v-if="guardianMembers.length > 0"
      :total-rows="guardianMembers.length"
      :show-pagination="guardianMembers.length > 200"
      :items="guardianMembers"
      :striped="false"
      class="group-member"
      :fields="parentField"
      :parent="parentTypes.PORTAL"
      table-ref="parentMembersTable"
    />
    <div v-else class="group-empty-text">
      {{ getGroupEmptyText(portalRoles.GUARDIAN) }}
    </div>
  </div>
</template>

<script>
import { types } from '../../store/types/types';
import { mapActions, mapGetters } from 'vuex';
import AulaTable from '../../../shared/components/Table';
import Vue from 'vue';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { institutionRole } from '../../../shared/enums/institutionRole';
import { groupMembershipRoles } from '../../../shared/enums/groupMembershipRoles';
import uniqueBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';

export default {
  components: {
    AulaTable,
  },
  data: function () {
    return {
      parentTypes,
      institutionRole: institutionRole,
      portalRoles,
      employeeField: {
        profile: {
          label: this.$options.filters.fromTextKey('GROUP_ADMINISTRATION_MEMBER_NAME'),
          sortable: true,
        },
      },
      childField: {
        profile: {
          label: this.$options.filters.fromTextKey('GROUP_ADMINISTRATION_MEMBER_NAME'),
          sortable: true,
        },
      },
      parentField: {
        profile: {
          label: this.$options.filters.fromTextKey('GROUP_ADMINISTRATION_MEMBER_NAME'),
          sortable: true,
        },
      },
      otpField: {
        profile: {
          label: this.$options.filters.fromTextKey('GROUP_ADMINISTRATION_MEMBER_NAME'),
          sortable: true,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      group: types.GET_ACTIVE_GROUP,
      groupMemberships: types.GET_GROUP_MEMBERSHIPS,
      profile: types.GET_CURRENT_PROFILE,
    }),
    employeeMembers() {
      const employeeRoles = [
        institutionRole.TEACHER,
        institutionRole.PRESCHOOL_TEACHER,
        institutionRole.LEADER,
        institutionRole.OTHER,
      ];
      const employeeMembers = this.groupMemberships.filter(member => employeeRoles.includes(member.institutionRole));
      const itemsArray = [];
      for (const member of employeeMembers) {
        if (
          member.groupRole == groupMembershipRoles.MEMBER &&
          itemsArray.filter(m => m.itemId.id == member.institutionProfile.id).length == 0
        ) {
          itemsArray.push({
            profile: Vue.filter('displayProfileNameWithMetadata')(member.institutionProfile),
            itemId: member.institutionProfile,
          });
        }
      }
      return sortBy(itemsArray, ['profile']);
    },
    childMembers() {
      const childRoles = [
        institutionRole.CHILD,
        institutionRole.DAYCARE,
        institutionRole.EARLY_STUDENT,
        institutionRole.MIDDLE_LATE_STUDENT,
      ];
      const childMembers = this.groupMemberships.filter(member => childRoles.includes(member.institutionRole));
      const itemsArray = [];
      for (const member of childMembers) {
        if (
          member.groupRole == groupMembershipRoles.MEMBER &&
          itemsArray.filter(m => m.itemId.id == member.institutionProfile.id).length == 0
        ) {
          itemsArray.push({
            profile: Vue.filter('displayProfileNameWithMetadata')(member.institutionProfile),
            itemId: member.institutionProfile,
          });
        }
      }
      return sortBy(itemsArray, ['profile']);
    },
    guardianMembers: function () {
      const toItem = (itemId, profile) => ({ itemId, profile });

      const parentMembers = this.groupMemberships
        .filter(
          member =>
            member.institutionRole == institutionRole.GUARDIAN && member.groupRole == groupMembershipRoles.MEMBER
        )
        .map(member =>
          toItem(member.institutionProfile, Vue.filter('displayProfileNameWithMetadata')(member.institutionProfile))
        );

      const otpMembers = this.groupMemberships
        .filter(member => member.otpInboxId)
        .map(member => toItem(member.otpInboxId, member.otpInboxMembershipDisplayName));

      const uniqueParents = uniqueBy(parentMembers, 'itemId.id');
      const guardians = [...uniqueParents, ...otpMembers];
      return sortBy(guardians, ['profile']);
    },
  },
  mounted() {
    if (this.group.id != null) {
      this.loadGroupMemberships({ groupId: this.group.id });
    }
  },
  methods: {
    ...mapActions({
      loadGroupMemberships: types.LOAD_GROUP_MEMBERSHIPS,
    }),
    getGroupEmptyText(portalRole) {
      let profileType;
      switch (portalRole) {
        case portalRoles.CHILD:
          profileType = Vue.filter('fromTextKey')('CHILDREN');
          break;
        case portalRoles.GUARDIAN:
          profileType = Vue.filter('fromTextKey')('PARENTS');
          break;
        case portalRoles.EMPLOYEE:
          profileType = Vue.filter('fromTextKey')('EMPLOYEES');
          break;
        case portalRoles.OTP:
          profileType = Vue.filter('fromTextKey')('OTP');
          break;
      }
      return (
        Vue.filter('fromTextKey')('GROUP_EMPTY_1') +
        profileType.toLowerCase() +
        Vue.filter('fromTextKey')('GROUP_EMPTY_2')
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/_breakpoints.scss';

.group-members-container {
  @include breakpoint-lg-down() {
    /deep/ {
      .table .table-row.body .table-cell .item span:first-child {
        display: none;
      }
    }
  }

  .group-member {
    --table-pointer-event: none;

    margin-top: 20px;
  }

  .group-empty-text {
    margin-bottom: 8px;
  }
}
</style>
