<template>
  <div
    class="more-menu-item"
    :title="'GO_TO' | fromTextKey({ goto: menuItem.name })"
    v-on="$listeners"
    @mouseover="setMouseHover(true)"
    @mouseout="setMouseHover(false)"
  >
    <span class="sr-only">{{ 'NAVIGATE_TO' | fromTextKey }}{{ menuItem.name.toLowerCase() }}</span>
    <span aria-hidden="true">
      <badge-notification v-if="badgeCount > 0" :amount="badgeCount" :border="true" />
      <img v-if="isImage" class="menu-icon" :src="menuIconImageSource" :alt="menuItem.name" />
      <i v-else class="menu-icon" :class="menuIconImageSource" />
      <span class="more-menu-text">{{ menuTitle }}</span>
    </span>
  </div>
</template>
<script>
import { types } from '../../../src/store/types/types';
import BadgeNotification from '../../components/BadgeNotification.vue';
import { mapGetters } from 'vuex';
import { institutionRole } from '../../enums/institutionRole';
import { menuUtil } from '../../utils/menuUtil';

export default {
  components: {
    BadgeNotification,
  },
  props: {
    menuItem: {
      required: true,
      type: Object,
    },
    badgeCount: {
      default: () => 0,
      required: false,
      type: Number,
    },
  },
  data() {
    return {
      isMouseHover: false,
    };
  },
  computed: {
    ...mapGetters({
      profile: types.GET_CURRENT_PROFILE,
    }),
    menuTitle() {
      const menuTextKey = menuUtil.getMenuType(this.menuItem.type);
      if (menuTextKey) {
        return this.$options.filters.fromTextKey(menuTextKey);
      }
      return this.menuItem.name;
    },
    isImage() {
      return menuUtil.getIsIconImage(this.menuIconImageSource);
    },
    isChild() {
      return this.profile.institutionRoles.includes(institutionRole.EARLY_STUDENT);
    },
    isWidget() {
      return this.menuItem.widgetId != null;
    },
    menuIconImageSource() {
      if (this.isChild && !this.isWidget) {
        return 'static/icons/' + this.menuItem.icon + '_child.svg';
      }
      if (this.isWidget && !this.menuItem.icon) {
        return 'icon-Aula_widget';
      }
      if (this.isMouseHover && this.menuItem.iconHover?.length > 0) {
        return this.menuItem.iconHover;
      }
      return menuUtil.getMenuIconClass(this.menuItem);
    },
  },
  methods: {
    setMouseHover(hover) {
      this.isMouseHover = hover;
    },
  },
};
</script>
<style scoped lang="scss">
.more-menu-item {
  --aula-badge-box-shadow-color: var(--color-grey-dark);
  &:hover {
    --aula-badge-box-shadow-color: var(--color-primary-darker);
  }
}
</style>
