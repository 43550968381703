import { render, staticRenderFns } from "./SpecificOpeningHours.vue?vue&type=template&id=f466b496&scoped=true&"
import script from "./SpecificOpeningHours.vue?vue&type=script&lang=js&"
export * from "./SpecificOpeningHours.vue?vue&type=script&lang=js&"
import style0 from "./SpecificOpeningHours.vue?vue&type=style&index=0&id=f466b496&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f466b496",
  null
  
)

export default component.exports