<template>
  <span>
    <span :id="popoverButtonId" v-button class="open-button">
      <span>{{ 'MESSAGE_OTHER_RECIPIENTS' | fromTextKey({ recipientsCount: recipientsCount }) }} </span>
      <i class="icon-Aula_down-arrow" :aria-label="'ARIA_LABEL_SHOW_RECIPIENT_LIST_POPOVER' | fromTextKey" />
    </span>
    <span class="popover-container">
      <AulaPopover :target="popoverButtonId">
        <template #title>
          {{ title }}
        </template>
        <template #content>
          <div v-for="(recipient, i) in recipients" :key="'message-recipient-' + i" class="recipients">
            {{ formatRecipient(recipient) }}{{ getSeparator(i) }}
          </div>
        </template>
      </AulaPopover>
    </span>
  </span>
</template>
<script>
import AulaPopover from '../../../shared/components/AulaPopover';
import button from '../../../shared/directives/button';
import { types } from '../../store/types/types';
import { mapGetters } from 'vuex';
import uniqueId from 'lodash/uniqueId';

export default {
  components: { AulaPopover },
  directives: {
    button,
  },
  props: {
    recipientsCount: { type: Number, default: 0 },
    title: { type: String, default: '' },
    recipients: { type: Array, default: () => [] },
  },
  computed: {
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
    }),
    popoverButtonId() {
      return `popover-button-${uniqueId()}`;
    },
  },
  methods: {
    getSeparator(index) {
      return index === this.recipients.length - 1 ? '' : ',';
    },
    formatRecipient(recipient) {
      if (!recipient) {
        return;
      }
      return typeof recipient === 'string'
        ? recipient
        : this.$options.filters.displayProfileNameWithMetadata(recipient);
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.recipients {
  margin-bottom: 6px;
}

.popover-container {
  color: var(--color-darkblue);
}

.open-button {
  color: var(--color-help-text);
  text-decoration: none;
}
</style>
