<template>
  <div id="aula-additional-data" class="page-layout-padding">
    <h1 v-if="!isMobile" class="page-title" tabindex="0">
      {{ 'ADDITIONAL_INFO_LABEL' | fromTextKey }}
    </h1>
    <div
      v-if="filteredAdditionalData() != null && filteredAdditionalData().length > 0"
      class="consent-header-container"
    >
      <div v-if="isMobile" class="consent-header-mobile">
        <mobile-topbar
          :left-icon="isViewMode ? 'icon-Aula_app_arrow_slideback' : null"
          :left-text="isViewMode ? null : 'CANCEL'"
          :text-key="isRevisionHistoryViewMode ? 'ADDITIONAL_DATA_VIEW_REVISION_HITORY' : 'ADDITIONAL_INFO_LABEL'"
          :right-text-icon="isViewMode ? 'icon-Aula_edit_pen' : null"
          :right-text="isViewMode ? 'BUTTON_EDIT_SMALL' : 'BUTTON_SAVE_SMALL'"
          :background-color="null"
          :color="null"
          :show-ok="!isRevisionHistoryViewMode"
          :css-class="isViewMode ? 'consent-view-mode-header' : 'consent-edit-mode-header'"
          :is-loading="isUpdating"
          @leftIconClicked="goToOverview()"
          @leftTextClicked="showCancelModal()"
          @rightTextClicked="isViewMode ? stepUpLogin() : saveAdditionalData()"
        />
        <b-btn
          v-if="isViewMode && !isRevisionHistoryViewMode && profile.role == portalRoles.GUARDIAN"
          variant="link"
          class="mr-3 btn-revision-history pull-right"
          @click="toggleRevisionHistory()"
        >
          {{ 'ADDITIONAL_DATA_VIEW_REVISION_HITORY' | fromTextKey }}
        </b-btn>
      </div>
      <template v-else>
        <template v-if="isViewMode && !isRevisionHistoryViewMode">
          <b-btn variant="primary" class="ml-3 pull-right" @click="stepUpLogin()">
            {{ 'BUTTON_EDIT' | fromTextKey }}
          </b-btn>
          <b-btn
            v-if="profile.role == portalRoles.GUARDIAN"
            variant="link"
            class="ml-3 pull-right"
            @click="toggleRevisionHistory()"
          >
            {{ 'ADDITIONAL_DATA_VIEW_REVISION_HITORY' | fromTextKey }}
          </b-btn>
        </template>
        <template v-else>
          <aula-spinner v-if="isUpdating" class="updating-aula-spinner" />
          <template v-if="isRevisionHistoryViewMode">
            <b-btn variant="primary" class="ml-3 pull-right" @click="toggleRevisionHistory()">
              {{ 'BUTTON_BACK' | fromTextKey }}
            </b-btn>
          </template>
          <template v-else>
            <b-btn variant="primary" class="ml-3 pull-right" :disabled="isUpdating" @click="saveAdditionalData()">
              {{ 'BUTTON_SAVE' | fromTextKey }}
            </b-btn>
            <b-btn variant="link" class="ml-3 pull-right" @click="showCancelModal()">
              {{ 'CANCEL' | fromTextKey }}
            </b-btn>
          </template>
        </template>
      </template>
    </div>
    <aula-spinner v-if="isLoading" />
    <consent-additional
      v-if="!isLoading && filteredAdditionalData() != null && filteredAdditionalData().length > 0"
      ref="additionalTable"
      :data="filteredAdditionalData()"
      :class="{
        'consent-table-mobile': isMobile,
        'view-mode': isViewMode,
        'view-revision-history': isViewMode && !isRevisionHistoryViewMode && profile.role == portalRoles.GUARDIAN,
      }"
      :is-revision-history-view-mode="isRevisionHistoryViewMode"
      :is-disabled="isViewMode"
      type="additional"
    />
    <aula-modal
      ref="cancelModal"
      ok-text="YES"
      @cancelClicked="$refs.cancelModal.hide()"
      @okClicked="cancelModalOkClicked()"
    >
      {{ 'CONSENT_CANCEL_MODAL_TEXT1' | fromTextKey }}<br />
      {{ 'CONSENT_CANCEL_MODAL_TEXT2' | fromTextKey }}
    </aula-modal>
    <aula-modal ref="invalidModal" :show-cancel="false" @okClicked="$refs.invalidModal.hide()">
      {{ 'CONSENT_INVALID_MODAL_TEXT1' | fromTextKey }}<br />
      {{ 'CONSENT_INVALID_MODAL_TEXT2' | fromTextKey }}
    </aula-modal>
    <b-alert variant="danger" class="warning" :show="showWarningAdditional">
      {{ 'WARNING_ANSWER_ADDITIONAL' | fromTextKey }}
    </b-alert>
    <toastr ref="additionalSaved" variant="success" :show-icon="true" icon="icon-Aula_check">
      {{ 'ADDITIONAL_DATA_SAVED_SUCCESS' | fromTextKey }}
    </toastr>
  </div>
</template>

<script>
import { types } from '../../store/types/types';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import ConsentAdditional from '../../../shared/components/consentAdditional/ConsentAdditional';
import { portalRoles } from '../../../shared/enums/portalRoles';
import MobileTopbar from '../../../shared/components/MobileTopbar';

export default {
  data: function () {
    return {
      portalRoles: portalRoles,
      isViewMode: true,
      isLoading: false,
      isUpdating: false,
      isRevisionHistoryViewMode: false,
      showWarningAdditional: false,
    };
  },
  computed: {
    ...mapGetters({
      additionalDataForOwner: types.GET_ADDITIONAL_DATA_FOR_ONBOARDING,
      isMobile: types.GET_IS_MOBILE,
      isSteppedUp: types.GET_GLOBAL_STEPPED_UP,
      institutions: types.GET_INSTITUTIONS,
      profile: types.GET_CURRENT_PROFILE,
      activeGroupHome: types.GET_ACTIVE_GROUP_HOME,
      groupHomes: types.GET_GROUP_HOMES,
    }),
  },
  methods: {
    ...mapActions({
      loadAdditionalDataForOwner: types.LOAD_ADDITIONAL_DATA,
      updateAdditionalData: types.UPDATE_ADDITIONAL_DATA,
    }),
    ...mapMutations({
      setStepUpNotification: types.MUTATE_SET_NOTIFICATION_STEP_UP,
    }),
    saveAdditionalData() {
      const em = this;
      this.showWarningAdditional = false;

      if (!this.$refs.additionalTable.validateAdditionalAnswered(true)) {
        this.showWarningAdditional = true;
        this.$refs.invalidModal.show();
        return;
      }

      this.isUpdating = true;
      const payload = em.$refs.additionalTable.submitAdditionalTable();

      em.updateAdditionalData(payload)
        .then(() => {
          em.$refs.additionalSaved.show();
          em.isViewMode = true;
          em.isUpdating = false;
          em.loadAdditionalDataForOwner({
            isResponded: true,
            institutionRoles: this.institutions.map(inst => inst.institutionRole),
          });
        })
        .catch(() => {
          em.isViewMode = true;
        });
    },
    filteredAdditionalData() {
      if (this.profile.role === this.portalRoles.OTP) {
        return this.getFilteredAdditionalDataForActiveGroupHome();
      } else {
        return this.additionalDataForOwner;
      }
    },
    getFilteredAdditionalDataForActiveGroupHome() {
      const groupHomeChildren = this.activeGroupHome.children.map(child => child.id);
      const additionalDataReleatedToGroupHomeChildren = this.additionalDataForOwner.filter(additionalData =>
        groupHomeChildren.includes(additionalData.institutionProfile.id)
      );
      return additionalDataReleatedToGroupHomeChildren;
    },
    toggleRevisionHistory() {
      this.isRevisionHistoryViewMode = !this.isRevisionHistoryViewMode;
    },
    showCancelModal() {
      if (this.$refs.additionalTable.isTableEdited()) {
        this.$refs.cancelModal.show();
      } else {
        this.isViewMode = true;
      }
    },
    cancelModalOkClicked() {
      this.$refs.cancelModal.hide();
      this.showWarningAdditional = false;
      this.isViewMode = true;
      this.$refs.additionalTable.resetTable();
    },
    stepUpLogin() {
      if (!this.isSteppedUp) {
        this.setStepUpNotification({
          showStepUpNotification: true,
          redirectedUrl: window.location.href,
        });
      } else {
        this.isViewMode = false;
      }
    },
    goToOverview() {
      if (this.isRevisionHistoryViewMode) {
        this.isRevisionHistoryViewMode = false;
      } else {
        this.$router.push({ name: 'overblik' });
      }
    },
    init() {
      this.isLoading = true;
      this.loadAdditionalDataForOwner({
        isResponded: true,
        institutionRoles: this.institutions.map(inst => inst.institutionRole),
      }).then(() => (this.isLoading = false));
    },
  },
  watch: {
    institutions() {
      this.init();
    },
  },
  mounted() {
    if (this.institutions.length > 0) {
      if (this.isSteppedUp) {
        this.init();
      } else {
        this.setStepUpNotification({
          showStepUpNotification: true,
          redirectedUrl: window.location.href,
        });
      }
    }
    if (!this.isMobile) {
      setTimeout(function () {
        document.querySelector('#aula-additional-data .page-title').focus();
      }, 500);
    }
  },
  components: {
    ConsentAdditional,
    MobileTopbar,
  },
};
</script>

<style scoped>
.updating-aula-spinner {
  width: 80px;
  height: 80px;
  position: absolute;
  right: 20px;
  top: -12px;
}
</style>
