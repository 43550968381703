import { render, staticRenderFns } from "./CalendarAside.vue?vue&type=template&id=00a13294&scoped=true&"
import script from "./CalendarAside.vue?vue&type=script&lang=js&"
export * from "./CalendarAside.vue?vue&type=script&lang=js&"
import style0 from "./CalendarAside.vue?vue&type=style&index=0&id=00a13294&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00a13294",
  null
  
)

export default component.exports