var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"search-result-item margin-bottom-20",attrs:{"tabindex":"0"},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.navigateToResult.apply(null, arguments)},"click":_vm.navigateToResult}},[_c('div',{staticClass:"search-result-icon-col"},[(_vm.isChild)?[_c('img',{staticClass:"menu-icon",attrs:{"src":'static/icons/icon-Aula_write_child.svg'}})]:_c('i',{staticClass:"icon-Aula_write"})],2),_vm._v(" "),_c('div',{staticClass:"search-result-text-col"},[_c('router-link',{attrs:{"tag":"span","to":{
        name: 'uniqueThread',
        params: { id: _vm.result.threadId },
        query: {
          fromSearch: true,
          folderId: _vm.result.folderId,
          mailBoxOwnerType:
            _vm.result.mailBoxOwner.mailBoxOwnerType == _vm.messageOwnerTypes.COMMON_INBOX
              ? _vm.result.mailBoxOwner.mailBoxOwnerType
              : null,
          mailOwnerId:
            _vm.result.mailBoxOwner.mailBoxOwnerType == _vm.messageOwnerTypes.COMMON_INBOX ? _vm.result.mailBoxOwner.id : null,
        },
      }}},[_c('div',{staticClass:"card-header-text"},[_c('keyword-highlight',{attrs:{"queries":_vm.searchString}},[_vm._v("\n          "+_vm._s(_vm.result.title)+"\n        ")])],1),_vm._v(" "),_c('div',{staticClass:"card-text"},[_vm._v("\n        "+_vm._s(_vm._f("fromTextKey")('SEARCH_RESULT_MESSAGE_BY'))+"\n        "),_c('keyword-highlight',{attrs:{"queries":_vm.searchString}},[_vm._v(" "+_vm._s(_vm.result.author)+" ")]),_c('br'),_vm._v("\n        "+_vm._s(_vm._f("fromTextKey")('SEARCH_RESULT_MESSAGE_TYPE'))+"\n        "+_vm._s(_vm._f("fromTextKey")(('SEARCH_RESULT_MESSAGE_' + _vm.result.docType.toUpperCase())))+"\n        "),(_vm.result.message != null && _vm.result.message.length > 0)?_c('div',{staticClass:"truncate",domProps:{"innerHTML":_vm._s(_vm.Vue.filter('highlightKeyword')(_vm.result.message, _vm.searchString))}}):_vm._e()],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }