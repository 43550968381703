











































import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import { mapActions, mapGetters } from 'vuex';
import { types } from '../../store/types/types.js';
import { InstitutionModel } from '../../../shared/models/institution.model';
import AulaButton from '../../../shared/components/AulaButton.vue';
import Icon from '../../../shared/components/Icon.vue';
import IconContainer from '../../../shared/components/IconContainer.vue';

export default {
  components: { IconContainer, Icon, AulaButton },
  data() {
    return {
      selectedInstitutions: [],
      isAdding: false,
    };
  },
  computed: {
    ...mapGetters({
      institutions: types.GET_INSTITUTIONS,
      activeInstitutionCodes: types.GET_ACTIVE_INSTITUTIONS,
    }),
    iconClassEnum() {
      return iconClassEnum;
    },
    sortedSelectedInstitutions() {
      return this.institutions.filter((institution: InstitutionModel) =>
        this.activeInstitutionCodes.includes(institution.institutionCode)
      );
    },
    unselectedInstitutions() {
      return this.institutions.filter(
        (institution: InstitutionModel) => !this.activeInstitutionCodes.includes(institution.institutionCode)
      );
    },
    isVisibleAddButton() {
      return !this.isAdding && this.selectedInstitutions.length < 6;
    },
    canRemoveInstitution() {
      return this.selectedInstitutions.length > 1;
    },
  },
  mounted() {
    this.selectedInstitutions = this.institutions.filter((institution: InstitutionModel) =>
      this.activeInstitutionCodes.includes(institution.institutionCode)
    );
  },
  methods: {
    ...mapActions({
      setActiveInstitutions: types.SET_ACTIVE_INSTITUTIONS,
      loadNotifications: types.LOAD_NOTIFICATIONS,
    }),
    showProfileSearch() {
      this.isAdding = true;
      this.$nextTick(() => {
        this.$refs.profileSelect.focus();
      });
    },
    addProfile(profile: InstitutionModel) {
      this.isAdding = false;
      this.selectedInstitutions.push(profile);
      this.setActiveInstitutions(this.selectedInstitutions);
      this.loadNotifications({ activeInstitutionCodes: this.activeInstitutionCodes });
    },
    removeProfile(profile: InstitutionModel) {
      this.selectedInstitutions = this.selectedInstitutions.filter(
        (institution: InstitutionModel) => institution.institutionCode !== profile.institutionCode
      );
      this.setActiveInstitutions(this.selectedInstitutions);
      this.loadNotifications({ activeInstitutionCodes: this.activeInstitutionCodes });
    },
  },
};
