<template>
  <div>
    <div class="contacts-group-filter">
      <ContactsFilterDropdown
        :value="groupId"
        class="contact-filter contact-group-filter"
        :aria-label="'ARIA_LABEL_CHOOSE_GROUP_TO_VIEW_CONTACTS' | fromTextKey"
        filterable
        :remote="canViewAllContactInformation"
        :remote-method="onLoadGroups"
        :clearable="canViewAllContactInformation"
        :groups="contactGroups"
        :options="groups"
        @clear="onClearSearchGroupQuery"
        @change="onChangeGroup"
      >
        <template #option="option">
          <aula-option :label="option.name" :value="option.id" />
        </template>
        <ContactsFragmentFilteringGroupWarning v-if="groupSearch != null && showTooManyGroupsWarning" />
      </ContactsFilterDropdown>
      <ContactsFilterDropdown
        :value="viewMode"
        class="contact-filter contact-type-filter"
        :options="viewModes"
        :placeholder="'CONTACT_LIST_TYPE_PLACEHOLDER' | fromTextKey"
        @change="onChangeViewMode"
      >
        <template #option="option">
          <aula-option :label="option.name" :value="option.value" />
        </template>
      </ContactsFilterDropdown>
    </div>
    <div class="contacts-container page-layout-padding">
      <p v-if="!viewMode">
        {{ 'CHOOSE_LIST_TYPE_TO_VIEW_CONTACTS' | fromTextKey }}
      </p>
      <div v-if="viewMode" class="contact-header">
        <h1>
          {{ pageTitle }}
        </h1>
      </div>
      <div class="d-none d-print-block">
        <b-row class="filters-row">
          <b-col v-if="selectedGroup" cols="3">
            <div class="filter-group">
              <span class="font-weight-bold"> {{ 'CONTACT_INSTITUTION' | fromTextKey }}: </span>
              <span>
                {{ institutionName }}
              </span>
            </div>
          </b-col>
          <b-col v-if="selectedGroup" cols="3">
            <div class="filter-group">
              <span class="font-weight-bold"> {{ 'CONTACT_GROUP' | fromTextKey }}: </span>
              <span>
                {{ selectedGroup.name }}
              </span>
            </div>
          </b-col>
          <b-col v-if="selectedProfileType" cols="3">
            <div class="filter-group">
              <span class="font-weight-bold"> {{ 'CONTACT_FILTER' | fromTextKey }}: </span>
              <span>
                {{ selectedProfileType.label | fromTextKey }}
              </span>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="groupId && viewMode" class="config-sections pt-3">
        <AulaNavigationToolbar class="tabs-nav d-print-none">
          <AulaButtons class="h-100 tabs-buttons">
            <template v-for="menu in profileTypes">
              <AulaButton
                class="tab-item"
                fill="clear"
                :class="{ 'active-tab': menu.filter === profileType }"
                :aria-selected="menu.filter === profileType"
                @click="onChangeProfileType(menu.filter)"
              >
                <span :aria-label="menu.label | fromTextKey" class="sr-only" />
                <span aria-hidden="true">{{ menu.name | fromTextKey }}</span>
              </AulaButton>
            </template>
          </AulaButtons>
          <template #end>
            <PrintButton class="p-2 print-button" />
          </template>
        </AulaNavigationToolbar>
      </div>
      <aula-spinner v-if="loading" />
      <template v-if="viewMode === contactViewTypes.CONTACT_LIST">
        <ContactsFragmentDesktopContactListParents
          v-if="profileType === contactsProfileTypeFilters.GUARDIAN"
          v-show="!loading"
        />
        <ContactsFragmentDesktopContactListEmployees
          v-else-if="profileType === contactsProfileTypeFilters.EMPLOYEE"
          v-show="!loading"
        />
        <ContactsFragmentDesktopContactList v-else v-show="!loading" />
      </template>
      <ContactsFragmentDesktopPhoneList v-else-if="viewMode === contactViewTypes.PHONE_LIST" v-show="!loading" />
      <ContactsFragmentDesktopBirthdayList v-else-if="viewMode === contactViewTypes.BIRTHDAY_LIST" v-show="!loading" />
      <ContactsFragmentDesktopChecklistList v-else-if="viewMode === contactViewTypes.CHECKBOX_LIST" v-show="!loading" />
      <ContactsFragmentDesktopPhotoList v-else-if="viewMode === contactViewTypes.PHOTO_LIST" v-show="!loading" />
    </div>
  </div>
</template>
<script lang="js">
import ContactsFilterDropdown from "./ContactsFragmentFilterDropdown";
import {contactSortingTypes} from '../../enums/contactSortingTypes';
import {providerKeyTypes} from "../../enums/providerKeyTypes";
import AulaNavigationToolbar from "../../components/navigation/AulaNavigationToolbar";
import AulaButtons from "../../components/AulaButtons";
import AulaButton from "../../components/AulaButton";
import PrintButton from "../../components/PrintButton";
import {contactsProfileTypeFilters} from "../../enums/contactsProfileTypeFilters";
import {portalRoles} from "../../enums/portalRoles";
import ContactsFragmentDesktopContactList from "./ContactsFragmentDesktopContactList";
import {contactViewTypes} from "../../enums/contactViewTypes";
import ContactsFragmentDesktopPhoneList from "./ContactsFragmentDesktopPhoneList";
import ContactsFragmentFilteringGroupWarning from "./ContactsFragmentFilteringGroupWarning";
import ContactsFragmentDesktopBirthdayList from "./ContactsFragmentDesktopBirthdayList";
import ContactsFragmentDesktopChecklistList from "./ContactsFragmentDesktopChecklistList";
import ContactsFragmentDesktopContactListEmployees from "./ContactsFragmentDesktopContactListEmployees";
import ContactsFragmentDesktopPhotoList from "./ContactsFragmentDesktopPhotoList";
import ContactsFragmentDesktopContactListParents from "./ContactsFragmentDesktopContactListParents";
import {mapGetters} from "vuex";
import {types} from "../../../src/store/types/types";

export default {
  name: 'ContactsFragmentDesktop',
  components: {
    ContactsFragmentDesktopContactListParents,
    ContactsFragmentDesktopPhotoList,
    ContactsFragmentDesktopContactListEmployees,
    ContactsFragmentDesktopBirthdayList,
    ContactsFragmentDesktopChecklistList,
    ContactsFragmentDesktopPhoneList,
    ContactsFragmentFilteringGroupWarning,
    ContactsFragmentDesktopContactList,
    PrintButton,
    AulaButton,
    AulaButtons,
    AulaNavigationToolbar,
    ContactsFilterDropdown,
  },
  inject: {
    getCanViewAllContactInformation: providerKeyTypes.canViewAllContactInformation,
    loadGroups: providerKeyTypes.onLoadGroups,
    getGroups: providerKeyTypes.groups,
    getContactGroups: providerKeyTypes.contactGroups,
    setContactGroupSearchQuery: providerKeyTypes.setContactGroupSearchQuery,
    getGroupId: providerKeyTypes.groupId,
    setGroupId: providerKeyTypes.setGroupId,
    getViewModes: providerKeyTypes.viewModes,
    getViewMode: providerKeyTypes.viewMode,
    setViewMode: providerKeyTypes.setViewMode,
    getProfileTypes: providerKeyTypes.profileTypes,
    setProfileType: providerKeyTypes.setProfileType,
    getProfileType: providerKeyTypes.profileType,
    getProfile: providerKeyTypes.profile,
    getLoading: providerKeyTypes.loading,
    getGroupSearch: providerKeyTypes.groupSearch,
    getShowTooManyGroupsWarning: providerKeyTypes.showTooManyGroupWarning,
    getContacts: providerKeyTypes.contacts,
  },
  data() {
    return {
      contactSortingTypes,
      contactsProfileTypeFilters,
      portalRoles,
      showParentCustody: false,
      contactViewTypes,
    };
  },
  computed: {
    ...mapGetters({
      institutions: types.GET_INSTITUTIONS,
    }),
    contacts() {
      return this.getContacts();
    },
    showTooManyGroupsWarning() {
      return this.getShowTooManyGroupsWarning();
    },
    groupSearch() {
      return this.getGroupSearch();
    },
    profile() {
      return this.getProfile();
    },
    canViewAllContactInformation() {
      return this.getCanViewAllContactInformation();
    },
    groups() {
      return this.getGroups();
    },
    contactGroups() {
      return this.getContactGroups();
    },
    groupId() {
      return this.getGroupId();
    },
    viewModes() {
      return this.getViewModes();
    },
    viewMode() {
      return this.getViewMode();
    },
    profileType() {
      return this.getProfileType();
    },
    profileTypes() {
      return this.getProfileTypes();
    },
    loading() {
      return this.getLoading();
    },
    selectedProfileType() {
      return this.profileTypes.find(profileType => profileType.filter === this.profileType);
    },
    selectedGroup() {
      return this.groups.find(group => group.id === this.groupId);
    },
    institutionName() {
      const selectedGroup = this.selectedGroup;
      if (selectedGroup) {
        const activeInstitution = this.institutions.find(institution => institution.institutionCode === selectedGroup?.institutionCode);
        return activeInstitution ? activeInstitution.name : this.$options.filters.fromTextKey('CONTACT_LIST_CROSS_INSTITUTIONAL_GROUP');
      }
      return '';
    },
    pageTitle() {
      let text = '';
      switch (this.viewMode) {
        case contactViewTypes.CONTACT_LIST:
          text = 'CONTACT_LIST';
          break;
        case contactViewTypes.PHONE_LIST:
          text = 'CONTACT_PHONE_LIST';
          break;
        case contactViewTypes.PHOTO_LIST:
          text = 'CONTACT_PHOTO_LIST';
          break;
        case contactViewTypes.CHECKBOX_LIST:
          text = 'CONTACT_CHECKBOX_LIST';
          break;
        case contactViewTypes.BIRTHDAY_LIST:
          text = 'CONTACT_BIRTHDAY_LIST';
          break;
      }
      return this.$options.filters.fromTextKey(text);
    }
  },
  methods: {
    onLoadGroups(query) {
      return this.loadGroups(query);
    },
    onClearSearchGroupQuery() {
      this.setContactGroupSearchQuery(null);
    },
    onChangeGroup(groupId) {
      this.setGroupId(groupId);
    },
    onChangeViewMode(viewMode) {
      this.setViewMode(viewMode);
    },
    onChangeProfileType(profileType) {
      this.setProfileType(profileType);
    },
  }
};
</script>
<style lang="scss" scoped>
.tabs-buttons {
  --icon-size: 8px;
}

.print-button {
  --font-size: 16px;
}

.tab-item {
  text-transform: uppercase;
}
</style>
