<template>
  <div class="notification-settings">
    <div class="notification-settings-header-container">
      <div class="notification-settings-header-mobile">
        <mobile-topbar
          :left-icon="isViewMode ? 'icon-Aula_app_arrow_slideback' : null"
          :left-text="isViewMode ? null : 'CANCEL'"
          :text-key="isViewMode ? 'MOBILE_NOTIFICATION_SETTINGS_TITLE_SHORT' : 'MOBILE_NOTIFICATION_SETTINGS_EDIT'"
          :right-text-icon="isViewMode ? 'icon-Aula_edit_pen' : null"
          :right-text="isViewMode ? 'BUTTON_EDIT_SMALL' : 'BUTTON_SAVE_SMALL'"
          :background-color="null"
          :color="null"
          :css-class="isViewMode ? 'notification-settings-view-mode-header' : 'notification-settings-edit-mode-header'"
          @leftIconClicked="goBackNotification()"
          @leftTextClicked="showCancelModal()"
          @rightTextClicked="isViewMode ? initEditMode() : saveChanges()"
        />
      </div>
      <div class="notification-settings-body-mobile" :class="isViewMode ? 'view-mode' : 'edit-mode'">
        <template v-if="notificationSettingModel.getEmailAvailable() || notificationSettingModel.getAppAvailable()">
          <div class="strong-label">
            {{ 'NOTIFICATION_SETTINGS_FROM' | fromTextKey }}
          </div>
          <div class="item upper-case mt-1">
            <i class="icon-Aula_write icon-border" />
            {{ 'MESSAGE_HEADER_TITLE' | fromTextKey }}
          </div>
          <label class="item">
            <span class="item-label font-weight-normal">
              {{ 'NOTIFICATION_SETTINGS_EMPLOYEES' | fromTextKey }}
            </span>
            <span v-if="isViewMode" class="view-mode-mobile pull-right">
              {{
                notificationSettingModel.getMessagesFromEmployees()
                  ? 'NOTIFICATION_SETTINGS_SHORT_YES'
                  : 'NOTIFICATION_SETTINGS_SHORT_NO' | fromTextKey
              }}
            </span>
            <el-switch
              v-else
              :value="notificationSettingModel.getMessagesFromEmployees()"
              :width="(width = 48)"
              class="pull-right"
              @change="updateMessagesFromEmployees"
            />
          </label>
          <label class="item">
            <span class="item-label font-weight-normal">
              {{ 'NOTIFICATION_SETTINGS_GUARDIANS' | fromTextKey }}
            </span>
            <span v-if="isViewMode" class="view-mode-mobile pull-right">
              {{
                notificationSettingModel.getMessagesFromGuardians()
                  ? 'NOTIFICATION_SETTINGS_SHORT_YES'
                  : 'NOTIFICATION_SETTINGS_SHORT_NO' | fromTextKey
              }}
            </span>
            <el-switch
              v-else
              :value="notificationSettingModel.getMessagesFromGuardians()"
              :width="(width = 48)"
              class="pull-right"
              @change="updateMessagesFromGuardians"
            />
          </label>
          <label class="item">
            <span class="item-label font-weight-normal">
              {{ 'NOTIFICATION_SETTINGS_CHILDREN' | fromTextKey }}
            </span>
            <span v-if="isViewMode" class="view-mode-mobile pull-right">
              {{
                notificationSettingModel.getMessagesFromChildren()
                  ? 'NOTIFICATION_SETTINGS_SHORT_YES'
                  : 'NOTIFICATION_SETTINGS_SHORT_NO' | fromTextKey
              }}
            </span>
            <el-switch
              v-else
              :value="notificationSettingModel.getMessagesFromChildren()"
              :width="(width = 48)"
              class="pull-right"
              @change="updateMessagesFromChildren"
            />
          </label>
          <div class="item upper-case mt-3">
            <i class="icon-Aula_calendar icon-border" />
            {{ 'CALENDAR_TAB_TITLE' | fromTextKey }}
          </div>

          <label class="item">
            <span class="item-label font-weight-normal">
              {{ 'NOTIFICATION_SETTINGS_ALL_KALENDAR_EVENTS' | fromTextKey }}
            </span>
            <span v-if="isViewMode" class="view-mode-mobile pull-right">
              {{
                notificationSettingModel.getCalendar()
                  ? 'NOTIFICATION_SETTINGS_SHORT_YES'
                  : 'NOTIFICATION_SETTINGS_SHORT_NO' | fromTextKey
              }}
            </span>
            <el-switch
              v-else
              :value="notificationSettingModel.getCalendar()"
              :width="(width = 48)"
              :inactive-value="false"
              class="pull-right"
              @change="updateCalendar"
            />
          </label>
          <label v-if="profile.role === portalRoles.EMPLOYEE" class="item">
            <span class="item-label font-weight-normal">
              {{ 'NOTIFICATION_SETTINGS_SUBSTITUTE_TEACHER' | fromTextKey }}
            </span>
            <span v-if="isViewMode" class="view-mode-mobile pull-right">
              {{
                notificationSettingModel.getNotifyAssignedAsSubstituteTeacher()
                  ? 'NOTIFICATION_SETTINGS_SHORT_YES'
                  : 'NOTIFICATION_SETTINGS_SHORT_NO' | fromTextKey
              }}
            </span>
            <el-switch
              v-else
              :value="notificationSettingModel.getNotifyAssignedAsSubstituteTeacher()"
              :width="(width = 48)"
              :active-value="true"
              :inactive-value="false"
              class="pull-right"
              @change="updateNotifyAssignedAsSubstituteTeacher"
            />
          </label>
          <label v-if="profile.role === portalRoles.EMPLOYEE" class="item">
            <span class="item-label font-weight-normal">
              {{ 'NOTIFICATION_SETTINGS_LESSON_NOTE_CHANGED' | fromTextKey }}
            </span>
            <span v-if="isViewMode" class="view-mode-mobile pull-right">
              {{
                notificationSettingModel.getNotifyLessonNoteChanged()
                  ? 'NOTIFICATION_SETTINGS_SHORT_YES'
                  : 'NOTIFICATION_SETTINGS_SHORT_NO' | fromTextKey
              }}
            </span>
            <el-switch
              v-else
              :value="notificationSettingModel.getNotifyLessonNoteChanged()"
              :width="(width = 48)"
              :active-value="true"
              :inactive-value="false"
              class="pull-right"
              @change="updateNotifyLessonNoteChanged"
            />
          </label>
          <div class="item upper-case mt-3">
            <i class="icon-Aula_picture icon-border" />
            {{ 'GALLERY_TITLE' | fromTextKey }}
          </div>
          <label class="item">
            <span class="item-label font-weight-normal">
              {{ 'NOTIFICATION_SETTINGS_ALL_MEDIA' | fromTextKey }}
            </span>
            <span v-if="isViewMode" class="view-mode-mobile pull-right">
              {{
                notificationSettingModel.getGallery()
                  ? 'NOTIFICATION_SETTINGS_SHORT_YES'
                  : 'NOTIFICATION_SETTINGS_SHORT_NO' | fromTextKey
              }}
            </span>
            <el-switch
              v-else
              :value="notificationSettingModel.getGallery()"
              :width="(width = 48)"
              :active-value="true"
              :inactive-value="false"
              class="pull-right"
              @change="updateGallery"
            />
          </label>
          <div class="item upper-case mt-3">
            <i class="icon-Aula_home icon-border" />
            {{ 'POSTS_PAGETITLE' | fromTextKey }}
          </div>
          <label class="item">
            <span class="item-label font-weight-normal">
              {{ 'NOTIFICATION_SETTINGS_ALL_POSTS' | fromTextKey }}
            </span>
            <span v-if="isViewMode" class="view-mode-mobile pull-right">
              {{
                notificationSettingModel.getPost()
                  ? 'NOTIFICATION_SETTINGS_SHORT_YES'
                  : 'NOTIFICATION_SETTINGS_SHORT_NO' | fromTextKey
              }}
            </span>
            <el-switch
              v-else
              :value="notificationSettingModel.getPost()"
              :width="(width = 48)"
              :active-value="true"
              :inactive-value="false"
              class="pull-right"
              @change="updatePost"
            />
          </label>
          <template v-if="profile.role === portalRoles.GUARDIAN || profile.role === portalRoles.OTP">
            <div class="item upper-case mt-3">
              <i class="icon-Kommegaa_ikon icon-border" />
              {{ 'PRESENCE_PAGETITLE' | fromTextKey }}
            </div>
            <label class="item">
              <span class="item-label font-weight-normal">
                {{ 'NOTIFICATION_SETTINGS_PICKUP_ACTIVITIES' | fromTextKey }}
              </span>
              <span v-if="isViewMode" class="view-mode-mobile pull-right">
                {{
                  notificationSettingModel.getPickUpActivity()
                    ? 'NOTIFICATION_SETTINGS_SHORT_YES'
                    : 'NOTIFICATION_SETTINGS_SHORT_NO' | fromTextKey
                }}
              </span>
              <el-switch
                v-else
                :value="notificationSettingModel.getPickUpActivity()"
                :width="(width = 48)"
                :active-value="true"
                :inactive-value="false"
                class="pull-right"
                @change="updatePickUpActivity"
              />
            </label>
            <label class="item">
              <span class="item-label font-weight-normal">
                {{ 'NOTIFICATION_SETTINGS_VACATION_REQUESTS' | fromTextKey }}
              </span>
              <span v-if="isViewMode" class="view-mode-mobile pull-right">
                {{
                  notificationSettingModel.getVacationRequest()
                    ? 'NOTIFICATION_SETTINGS_SHORT_YES'
                    : 'NOTIFICATION_SETTINGS_SHORT_NO' | fromTextKey
                }}
              </span>
              <el-switch
                v-else
                :value="notificationSettingModel.getVacationRequest()"
                :width="(width = 48)"
                :active-value="true"
                :inactive-value="false"
                class="pull-right"
                @change="updateVacationRequest"
              />
            </label>
          </template>
          <div class="widget-settings">
            <div
              v-for="widgetSetting in notificationSettingModel.getWidgetSettings()"
              :key="widgetSetting.widgetId"
              class="widget-setting"
            >
              <div class="item upper-case mt-3">
                <i class="icon-Aula_widget icon-border" />
                {{ widgetSetting.title }}
              </div>
              <label class="item">
                <span class="item-label font-weight-normal">
                  {{ 'NOTIFICATION_SETTINGS_WIDGET_ACTIVATED' | fromTextKey }}
                </span>
                <span v-if="isViewMode" class="view-mode-mobile pull-right">
                  {{
                    widgetSetting.isActive
                      ? 'NOTIFICATION_SETTINGS_SHORT_YES'
                      : 'NOTIFICATION_SETTINGS_SHORT_NO' | fromTextKey
                  }}
                </span>
                <el-switch
                  v-else
                  :value="widgetSetting.isActive"
                  :width="(width = 48)"
                  :active-value="true"
                  :inactive-value="false"
                  class="pull-right"
                  @change="updateWidget(widgetSetting.widgetId, $event)"
                />
              </label>
            </div>
          </div>
        </template>
        <div class="strong-label">
          {{ 'MOBILE_NOTIFICATION_SETTINGS_PLATFORM_MENU' | fromTextKey }}
        </div>
        <div class="item upper-case">
          <i class="icon-Aula_mobile icon-border" />
          {{ 'MOBILE_NOTIFICATION_SETTINGS_MOBILE_MENU' | fromTextKey }}
        </div>
        <div v-if="!notificationSettingModel.getAppAvailable()" class="item">
          <div class="settings-label">
            {{ 'NOTIFICATION_SETTINGS_NO_APP' | fromTextKey }}
          </div>
          <br />
          <div class="settings-label mb-2">
            {{ 'NOTIFICATION_SETTINGS_DOWNLOAD' | fromTextKey }}
          </div>
          <a href="https://itunes.apple.com/" :aria-label="'ARIA_LABEL_APP_STORE' | fromTextKey">
            <img class="image-link" src="../../../static/appstores/app_store.svg" alt="" />
          </a>
          <a href="https://play.google.com/store/apps/" :aria-label="'ARIA_LABEL_GOOGLE_PLAY' | fromTextKey">
            <img class="image-link" src="../../../static/appstores/app_google_play.svg" alt="" />
          </a>
          <br />
        </div>
        <label v-else class="item">
          <span class="item-label font-weight-normal">
            {{ 'MOBILE_NOTIFICATION_SETTINGS_MOBILE_ALLOW_LABEL' | fromTextKey }}
          </span>
          <span v-if="isViewMode" class="view-mode-mobile pull-right">
            {{
              notificationSettingModel.getAppDisabled()
                ? 'NOTIFICATION_SETTINGS_SHORT_YES'
                : 'NOTIFICATION_SETTINGS_SHORT_NO' | fromTextKey
            }}
          </span>
          <el-switch
            v-else
            :value="notificationSettingModel.getAppDisabled()"
            :width="(width = 48)"
            :active-value="true"
            :inactive-value="false"
            class="pull-right"
            @change="updateAppDisabled"
          />
        </label>
        <div class="item upper-case">
          <i class="icon-Aula_email icon-border" />
          {{ 'MOBILE_NOTIFICATION_SETTINGS_EMAIL_MENU' | fromTextKey }}
        </div>
        <div v-if="!notificationSettingModel.getEmailAvailable()" class="item">
          <div class="settings-label">
            {{ 'NOTIFICATION_SETTINGS_NO_EMAIL' | fromTextKey }}
          </div>
          <br />
          <div class="settings-label">
            {{ 'NOTIFICATION_SETTINGS_ENTER_EMAIL' | fromTextKey }}
          </div>
        </div>
        <div v-else class="item">
          <label class="item-child d-block">
            <span class="item-label font-weight-normal">
              {{ 'MOBILE_NOTIFICATION_SETTINGS_EMAIL_ALLOW_LABEL' | fromTextKey }}
            </span>
            <span v-if="isViewMode" class="view-mode-mobile pull-right">
              {{
                notificationSettingModel.getEmailDisabled()
                  ? 'NOTIFICATION_SETTINGS_SHORT_YES'
                  : 'NOTIFICATION_SETTINGS_SHORT_NO' | fromTextKey
              }}
            </span>
            <el-switch
              v-else
              :value="notificationSettingModel.getEmailDisabled()"
              :width="(width = 48)"
              :active-value="true"
              :inactive-value="false"
              class="pull-right"
              @change="updateEmailDisabled"
            />
          </label>
          <template v-if="notificationSettingModel.getEmailDisabled()">
            <label class="item-child d-block">
              <span class="item-label font-weight-normal">
                {{ 'MOBILE_NOTIFICATION_SETTINGS_EMAIL_EVERY_TIME' | fromTextKey }}
              </span>
              <span v-if="isViewMode" class="view-mode-mobile pull-right">
                {{
                  (notificationSettingModel.getInstant()
                    ? 'NOTIFICATION_SETTINGS_SHORT_YES'
                    : 'NOTIFICATION_SETTINGS_SHORT_NO') | fromTextKey
                }}
              </span>
              <el-switch
                v-else
                :value="notificationSettingModel.getInstant()"
                :width="(width = 48)"
                :active-value="true"
                :inactive-value="false"
                class="pull-right"
                @change="updateInstant"
              />
            </label>
            <label class="item-child d-block">
              <span class="item-label font-weight-normal">
                {{ 'MOBILE_NOTIFICATION_SETTINGS_EMAIL_AFTER_TIME_INTERVAL' | fromTextKey }}
              </span>
              <span v-if="isViewMode" class="view-mode-mobile pull-right">
                {{
                  (!notificationSettingModel.getInstant()
                    ? 'NOTIFICATION_SETTINGS_SHORT_YES'
                    : 'NOTIFICATION_SETTINGS_SHORT_NO') | fromTextKey
                }}
              </span>
              <el-switch
                v-else
                :value="notificationSettingModel.getInstant()"
                :width="(width = 48)"
                :active-value="false"
                :inactive-value="true"
                class="pull-right"
                @change="updateInstant"
              />
            </label>
          </template>
        </div>
        <div v-if="notificationSettingModel.getEmailDisabled() && !notificationSettingModel.getInstant()" class="item">
          <div class="item-child item-time-picker">
            <div class="item-label">
              {{ 'MOBILE_NOTIFICATION_SETTINGS_TIME_LABEL' | fromTextKey }}
            </div>
            <div :class="showNoDaySelected ? 'red-border' : ''">
              <AulaTimepicker
                :value="notificationSettingModel.getScheduledTime()"
                format="HH:mm"
                hour-label="Time"
                minute-label="Minut"
                :placeholder="'MESSAGE_PLACEHOLDER_SELECT_TIME' | fromTextKey"
                :aria-label="'ARIA_LABEL_CALENDAR_START_TIME' | fromTextKey"
                hide-clear-button
                :minute-interval="timepickerSettings.HALF_HOUR_INTERVAL"
                advanced-keyboard
                lazy
                @input="updateScheduledTime"
              />
            </div>
          </div>
          <label class="item-child d-block">
            <span class="item-label font-weight-normal">
              {{ 'MOBILE_NOTIFICATION_SETTINGS_EVERY_DAY' | fromTextKey }}
            </span>
            <span v-if="isViewMode" class="view-mode-mobile pull-right">
              {{ everyDay ? 'NOTIFICATION_SETTINGS_SHORT_YES' : 'NOTIFICATION_SETTINGS_SHORT_NO' | fromTextKey }}
            </span>
            <el-switch v-else :value="everyDay" :width="(width = 48)" class="pull-right" @change="setAllSelectedDays" />
          </label>
          <div class="item-child">
            <div class="item-label">
              {{ 'NOTIFICATION_SETTINGS_SPECIFIC_DAYS' | fromTextKey }}
            </div>
            <span class="pull-right">
              {{ 'NOTIFICATION_SETTINGS_SELECT_DAYS' | fromTextKey }}
              <i
                class="icon small-icon"
                :class="showSelectDays || !isViewMode ? 'icon-Aula_up-arrow' : 'icon-Aula_down-arrow'"
              />
            </span>
            <div
              v-if="showSelectDays || !isViewMode"
              class="select-days"
              :class="showNoDaySelected ? 'red-border' : ''"
            >
              <label class="item-child d-block">
                <span class="item-label font-weight-normal">
                  {{ 'NOTIFICATION_SETTINGS_MONDAY' | fromTextKey }}
                </span>
                <span v-if="isViewMode" class="view-mode-mobile pull-right">
                  {{
                    notificationSettingModel.getMonday()
                      ? 'NOTIFICATION_SETTINGS_SHORT_YES'
                      : 'NOTIFICATION_SETTINGS_SHORT_NO' | fromTextKey
                  }}
                </span>
                <el-switch
                  v-else
                  :value="notificationSettingModel.getMonday()"
                  :width="(width = 48)"
                  class="pull-right"
                  @change="updateMonday"
                />
              </label>
              <label class="item-child d-block">
                <span class="item-label font-weight-normal">
                  {{ 'NOTIFICATION_SETTINGS_TUESDAY' | fromTextKey }}
                </span>
                <span v-if="isViewMode" class="view-mode-mobile pull-right">
                  {{
                    notificationSettingModel.getTuesday()
                      ? 'NOTIFICATION_SETTINGS_SHORT_YES'
                      : 'NOTIFICATION_SETTINGS_SHORT_NO' | fromTextKey
                  }}
                </span>
                <el-switch
                  v-else
                  :value="notificationSettingModel.getTuesday()"
                  :width="(width = 48)"
                  class="pull-right"
                  @change="updateTuesday"
                />
              </label>
              <label class="item-child d-block">
                <span class="item-label font-weight-normal">
                  {{ 'NOTIFICATION_SETTINGS_WEDNESDAY' | fromTextKey }}
                </span>
                <span v-if="isViewMode" class="view-mode-mobile pull-right">
                  {{
                    notificationSettingModel.getWednesday()
                      ? 'NOTIFICATION_SETTINGS_SHORT_YES'
                      : 'NOTIFICATION_SETTINGS_SHORT_NO' | fromTextKey
                  }}
                </span>
                <el-switch
                  v-else
                  :value="notificationSettingModel.getWednesday()"
                  :width="(width = 48)"
                  class="pull-right"
                  @change="updateWednesday"
                />
              </label>
              <label class="item-child d-block">
                <span class="item-label font-weight-normal">
                  {{ 'NOTIFICATION_SETTINGS_THURSDAY' | fromTextKey }}
                </span>
                <span v-if="isViewMode" class="view-mode-mobile pull-right">
                  {{
                    notificationSettingModel.getThursday()
                      ? 'NOTIFICATION_SETTINGS_SHORT_YES'
                      : 'NOTIFICATION_SETTINGS_SHORT_NO' | fromTextKey
                  }}
                </span>
                <el-switch
                  v-else
                  :value="notificationSettingModel.getThursday()"
                  :width="(width = 48)"
                  class="pull-right"
                  @change="updateThursday"
                />
              </label>
              <label class="item-child d-block">
                <span class="item-label font-weight-normal">
                  {{ 'NOTIFICATION_SETTINGS_FRIDAY' | fromTextKey }}
                </span>
                <span v-if="isViewMode" class="view-mode-mobile pull-right">
                  {{
                    notificationSettingModel.getFriday()
                      ? 'NOTIFICATION_SETTINGS_SHORT_YES'
                      : 'NOTIFICATION_SETTINGS_SHORT_NO' | fromTextKey
                  }}
                </span>
                <el-switch
                  v-else
                  :value="notificationSettingModel.getFriday()"
                  :width="(width = 48)"
                  class="pull-right"
                  @change="updateFriday"
                />
              </label>
              <label class="item-child d-block">
                <span class="item-label font-weight-normal">
                  {{ 'NOTIFICATION_SETTINGS_SATURDAY' | fromTextKey }}
                </span>
                <span v-if="isViewMode" class="view-mode-mobile pull-right">
                  {{
                    notificationSettingModel.getSaturday()
                      ? 'NOTIFICATION_SETTINGS_SHORT_YES'
                      : 'NOTIFICATION_SETTINGS_SHORT_NO' | fromTextKey
                  }}
                </span>
                <el-switch
                  v-else
                  :value="notificationSettingModel.getSaturday()"
                  :width="(width = 48)"
                  class="pull-right"
                  @change="updateSaturday"
                />
              </label>
              <label class="item-child d-block">
                <span class="item-label font-weight-normal">
                  {{ 'NOTIFICATION_SETTINGS_SUNDAY' | fromTextKey }}
                </span>
                <span v-if="isViewMode" class="view-mode-mobile pull-right">
                  {{
                    notificationSettingModel.getSunday()
                      ? 'NOTIFICATION_SETTINGS_SHORT_YES'
                      : 'NOTIFICATION_SETTINGS_SHORT_NO' | fromTextKey
                  }}
                </span>
                <el-switch
                  v-else
                  :value="notificationSettingModel.getSunday()"
                  :width="(width = 48)"
                  class="pull-right"
                  @change="updateSunday"
                />
              </label>
            </div>
            <b-alert variant="danger" :show="showNoDaySelected">
              {{ 'NOTIFICATION_SETTINGS_SAVE_FAILED' | fromTextKey }}
            </b-alert>
          </div>
        </div>
      </div>
    </div>
    <aula-modal
      ref="cancelModal"
      ok-text="BUTTON_YES"
      @cancelClicked="$refs.cancelModal.hide()"
      @okClicked="cancelModalOkClicked()"
    >
      {{ 'CONSENT_CANCEL_MODAL_TEXT1' | fromTextKey }}<br />
      {{ 'CONSENT_CANCEL_MODAL_TEXT2' | fromTextKey }}
    </aula-modal>
  </div>
</template>

<script>
import { timepickerSettings } from '../../enums/timepickerSettings';
import AulaTimepicker from '../../components/Timepicker';
import { portalRoles } from '../../enums/portalRoles';
import MobileTopbar from '../../components/MobileTopbar';
import { NotificationSetting } from '../../../src/business/notificationSetting';

export default {
  name: 'NotificationSetttingMobile',
  components: {
    AulaTimepicker,
    MobileTopbar,
  },
  props: {
    isLoading: { type: Boolean, default: false },
    isViewMode: { type: Boolean, default: false },
    availableAreas: { type: Array, default: () => [] },
    profile: { type: Object, default: null },
    everyDay: { type: Boolean, default: null },
    showNoDaySelected: { type: Boolean, default: null },
    showSelectDays: { type: Boolean, default: null },
    notificationSettingModel: { type: NotificationSetting, default: null },
  },
  data: function () {
    return {
      portalRoles,
      timepickerSettings,
    };
  },
  methods: {
    setAllSelectedDays(value) {
      this.updateEveryDay(value);
      this.$emit('setAllSelectedDays');
    },
    cancelModalOkClicked() {
      this.updateViewMode(true);
      this.$refs.cancelModal.hide();
      this.$emit('resetTable');
    },
    updateViewMode(value) {
      this.$emit('updateViewMode', value);
    },
    goBackNotification() {
      this.$emit('goBackNotification');
    },
    initEditMode() {
      this.$emit('initEditMode');
    },
    saveChanges() {
      this.$emit('saveChanges');
    },
    updateEveryDay(value) {
      this.$emit('updateEveryDay', value);
    },
    showCancelModal() {
      this.$refs.cancelModal.show();
    },
    updateAppDisabled(value) {
      this.notificationSettingModel.setAppDisabled(value);
    },
    updateCalendar(value) {
      this.notificationSettingModel.setCalendar(value);
    },
    updateEmailDisabled(value) {
      this.notificationSettingModel.setEmailDisabled(value);
    },
    updateGallery(value) {
      this.notificationSettingModel.setGallery(value);
    },
    updateInstant(value) {
      this.notificationSettingModel.setInstant(value);
    },
    updateMessagesFromChildren(value) {
      this.notificationSettingModel.setMessagesFromChildren(value);
    },
    updateMessagesFromEmployees(value) {
      this.notificationSettingModel.setMessagesFromEmployees(value);
    },
    updateMessagesFromGuardians(value) {
      this.notificationSettingModel.setMessagesFromGuardians(value);
    },
    updateNotifyAssignedAsSubstituteTeacher(value) {
      this.notificationSettingModel.setNotifyAssignedAsSubstituteTeacher(value);
    },
    updateNotifyLessonNoteChanged(value) {
      this.notificationSettingModel.setNotifyLessonNoteChanged(value);
    },
    updatePickUpActivity(value) {
      this.notificationSettingModel.setPickUpActivity(value);
    },
    updatePost(value) {
      this.notificationSettingModel.setPost(value);
    },
    updateWidget(widgetId, value) {
      this.notificationSettingModel.setWidgetSettingById(widgetId, value);
    },
    updateScheduledTime(value) {
      this.notificationSettingModel.setScheduledTime(value);
    },
    updateSelectedAreas(value) {
      this.notificationSettingModel.setSelectedAreas(value);
    },
    updateVacationRequest(value) {
      this.notificationSettingModel.setVacationRequest(value);
    },
    updateMonday(value) {
      this.notificationSettingModel.setMonday(value);
    },
    updateTuesday(value) {
      this.notificationSettingModel.setTuesday(value);
    },
    updateWednesday(value) {
      this.notificationSettingModel.setWednesday(value);
    },
    updateThursday(value) {
      this.notificationSettingModel.setThursday(value);
    },
    updateFriday(value) {
      this.notificationSettingModel.setFriday(value);
    },
    updateSaturday(value) {
      this.notificationSettingModel.setSaturday(value);
    },
    updateSunday(value) {
      this.notificationSettingModel.setSunday(value);
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/scss/core/variables';
@import '../../assets/scss/core/breakpoints';
@import '../../assets/scss/elements/notificationSetting';

footer {
  @include breakpoint-lg-down() {
    position: fixed !important;
    bottom: 80px;
    top: unset;
  }
}

.settings-label {
  display: block;
  &.large {
    margin-bottom: 15px;
  }
}

.red-border {
  .notification-settings-body-mobile & {
    display: block;
  }
}
</style>
