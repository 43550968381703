<template>
  <div class="aula-documentsFolderPicker-container">
    <div v-for="(folder, index) in folders" :key="index" :class="['folder-' + folder.id]">
      <div
        class="folderItem"
        role="button"
        tabindex="0"
        @click="chooseFolder(folder.id)"
        @keydown.enter="chooseFolder(folder.id)"
      >
        <i class="icon-Aula_folder" /> {{ folder.name }}
      </div>
      <div v-if="folder.children" class="parent">
        <span class="folderFold icon icon-Aula_forward-arrow" @click.stop="showSub($event)" />
        <div class="sub-folders">
          <documents-folder-picker :folders="folder.children" :not-root="true" :include-documents="includeDocuments" />
        </div>
      </div>
      <div v-if="includeDocuments == true">- documents here -</div>
    </div>
  </div>
</template>

<script>
import { types } from '../../store/types/types';
import { mapGetters, mapActions } from 'vuex';
import $ from 'jquery';
export default {
  name: 'DocumentsFolderPicker',
  props: ['folders', 'includeDocuments', 'notRoot', 'value'],
  data: function () {
    return {};
  },
  computed: {
    ...mapGetters({
      profile: types.GET_CURRENT_PROFILE,
      selectedFolder: types.GET_CHOOSENFOLDER,
    }),
  },
  methods: {
    ...mapActions({
      setSelectedFolder: types.ACTIONS_DOCUMENTS_SET_SELECTED_FOLDER,
    }),
    chooseFolder(folderId) {
      this.setSelectedFolder(folderId);
      [].forEach.call(
        document.getElementsByClassName('aula-documentsFolderPicker-container')[0].getElementsByClassName('active'),
        function (e) {
          e.classList.remove('active');
        }
      );
      this.$el.getElementsByClassName('folder-' + folderId)[0].classList.add('active');
    },
    showSub(event) {
      const siblings = event.currentTarget.parentElement.parentElement.parentElement;
      let closeCurrent = false;
      if (event.currentTarget.parentElement.classList.contains('showSub')) {
        closeCurrent = true;
      }
      if (siblings.querySelector('.showSub')) {
        $('.showSub', siblings).removeClass('showSub');
      }
      if (!closeCurrent) {
        event.currentTarget.parentElement.classList.toggle('showSub');
      }
    },
  },
  created() {
    $('.showSub').removeClass('showSub');
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
.aula-documentsFolderPicker-container {
  .parent {
    position: relative;
    .folderFold {
      position: absolute;
      top: -24px;
      right: 15px;
      cursor: pointer;
      transition: transform 0.25s;
    }
  }
  .sub-folders {
    display: none;
    padding-left: 10px;
  }
  .showSub {
    & > .sub-folders {
      display: block;
    }
    & > .folderFold {
      transform: rotate(90deg);
    }
  }
  .folderItem {
    padding: 5px 10px;
    margin-bottom: 1px;
    &:hover {
      background-color: $color-grey-light;
    }
  }
  .active {
    > .folderItem,
    > .parent > .folderFold {
      background-color: $color-darkblue;
      color: $color-white;
    }
  }
}
</style>
