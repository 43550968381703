<template>
  <div class="background">
    <div class="aula-onboarding">
      <b-container>
        <div class="header">
          <i
            v-if="isMobile && !stepStatus.findIndex(s => s.step == $route.params.step) == 0"
            class="go-back icon-Aula_arrow_new_item"
            :aria-label="'ARIA_LABEL_BACK_PREVIOUS_STEP' | fromTextKey"
            @click="goBackStep"
          />
          <div class="aula-logo">
            <div class="logo">
              <i class="icon-Logo" aria-hidden="true" />
            </div>
          </div>
          <b-btn
            v-if="isMobile"
            variant="link"
            class="next-link"
            :disabled="disabledNextButton || isLoading"
            @click="stepSubmit()"
          >
            {{ 'NEXT_STEP' | fromTextKey }}
            <aula-spinner v-if="isLoading" class="aula-spinner" />
          </b-btn>
          <div class="onboarding-steps-mobile">
            <ul v-if="isMobile" class="steps">
              <li
                v-for="(step, index) in stepStatus"
                :key="index"
                tabindex="1"
                :class="$route.params.step == step.step ? 'active' : ''"
                :aria-current="$route.params.step == step.step ? 'step' : null"
                :aria-label="
                  'ARIA_LABEL_GO_TO_STEP'
                    | fromTextKey({
                      stepNumber: index + 1,
                      stepName: getStepName(step.step),
                    })
                "
                @click="goToStep(step.step)"
              >
                {{ index + 1 }}
              </li>
            </ul>
          </div>
        </div>
        <b-row class="onboarding">
          <b-col cols="12" lg="9" class="p-0 onboarding-wrapper">
            <aula-spinner v-if="initLoading" />
            <div v-else class="onboard-content">
              <div v-if="isMobile" class="intro-step margin-bottom-40">
                <h1 class="step-title">
                  {{ getStepText(true) }}
                </h1>
                <div class="step-desc scrollbar" v-html="getStepText()" />
              </div>
              <onboarding-policy
                v-if="$route.params.step == 'policy' && stepStatus.findIndex(s => s.step == 'policy') > -1"
              />
              <onboarding-update-contact-info
                v-if="
                  $route.params.step == 'update' && stepStatus.findIndex(s => s.step == 'update') > -1 && !isLoading
                "
                ref="onboardingUpdate"
                :profile-master-data="profileMasterData"
                :related-profiles="custodialChildren"
              />
              <onboarding-consent
                v-show="$route.params.step == 'consent' && stepStatus.findIndex(s => s.step == 'consent') > -1"
                ref="onboardingConsent"
              />
              <onboarding-additional-data
                v-show="$route.params.step == 'additional' && stepStatus.findIndex(s => s.step == 'additional') > -1"
                ref="onboardingAdditionalData"
              />
            </div>
          </b-col>
          <b-col v-if="!isMobile" lg="3" class="onboarding-steps">
            <ul class="steps">
              <li
                v-for="(step, index) in stepStatus"
                :key="index"
                tabindex="1"
                :class="$route.params.step == step.step ? 'active' : ''"
                :aria-selected="$route.params.step == step.step ? true : false"
                :aria-label="
                  'ARIA_LABEL_GO_TO_STEP'
                    | fromTextKey({
                      stepNumber: index + 1,
                      stepName: getStepName(step.step),
                    })
                "
                @click="goToStep(step.step)"
              >
                {{ index + 1 }}
              </li>
            </ul>
            <h2 class="step-title scrollbar">
              {{ getStepText(true) }}
            </h2>
            <div class="step-desc scrollbar" v-html="getStepText()" />
            <div class="step-button">
              <b-btn
                variant="primary"
                class="send-btn"
                :disabled="disabledNextButton || isLoading"
                @click="stepSubmit()"
              >
                {{ getNextButtonText() }}
                <aula-spinner v-if="isLoading" class="aula-spinner" />
              </b-btn>
              <b-btn
                v-if="!stepStatus.findIndex(s => s.step == $route.params.step) == 0"
                variant="link"
                class="previous-link"
                @click="goBackStep"
              >
                {{ 'PREVIOUS_STEP' | fromTextKey }}
              </b-btn>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <aula-modal
        ref="declinedSpecialConsentWarning"
        @cancelClicked="$refs.declinedSpecialConsentWarning.hide(), (isLoading = false)"
        @okClicked="submitConsent()"
      >
        <template v-for="(consent, i) in declinedSpecialConsents">
          {{ getDeclinedConsentWarningMessage(consent) }}<br :key="i" />
        </template>
        {{ 'CONSENT_WARNING_DECLINED_2' | fromTextKey }}
      </aula-modal>
      <aula-modal ref="onboardingError" :show-cancel="false" @okClicked="onboardingRefresh()">
        {{ 'ONBOARDING_WARNING_ERROR' | fromTextKey }}
      </aula-modal>
      <step-up-notification />
      <portal-target v-for="modalId in openModals" :key="modalId" :name="'modal-' + modalId" />
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { mapActions, mapMutations } from 'vuex';
import { types } from '../../store/types/types';
import { Cookie } from '../../../shared/assets/plugins/cookie';
import OnboardingPolicy from './OnboardingPolicy.vue';
import OnboardingUpdateContactInfo from './OnboardingUpdateContactInfo.vue';
import OnboardingConsent from './OnboardingConsent.vue';
import OnboardingAdditionalData from './OnboardingAdditionalData.vue';
import AulaModal from '../../../shared/components/AulaModal';
import { consentNumber } from '../../../shared/enums/consentNumber';
import StepUpNotification from '../../components/shared/StepUpNotification';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import $ from 'jquery';
import { onboardingStatusEnum } from '../../../shared/enums/onboardingStatusEnum';
import { profileService } from '../../../shared/services/api/profile.service';

export default {
  components: {
    'onboarding-policy': OnboardingPolicy,
    'onboarding-update-contact-info': OnboardingUpdateContactInfo,
    'onboarding-consent': OnboardingConsent,
    'onboarding-additional-data': OnboardingAdditionalData,
    'step-up-notification': StepUpNotification,
    AulaModal,
  },
  data: function () {
    return {
      Vue: Vue,
      consentNumber: consentNumber,
      parentTypes: parentTypes,
      isLoading: false,
      initLoading: true,
      stepStatus: [],
      isPolicyAccepted: false,
      isChangedPrimaryInstProfile: false,
      showWarningAcceptPolicy: false,
      showWarningAdditional: false,
      showWarningConsent: false,
      policyUrl: '',
      declinedSpecialConsents: [],
      profileMasterData: null,
    };
  },
  computed: {
    ...mapGetters({
      onboardingStatus: types.IS_ONBOARDING_NEEDED,
      onboardingSteps: types.GET_ONBOARDING_STEPS,
      getProfilesByLogin: types.GET_PROFILES_BY_LOGIN,
      profile: types.GET_CURRENT_PROFILE,
      institutions: types.GET_INSTITUTIONS,
      getPersonalDataPolicies: types.GET_PERSONAL_DATA_POLICIES,
      isOnboardingError: types.IS_ONBOARDING_ERROR,
      openModals: types.GET_OPEN_MODALS,
      isMobile: types.GET_IS_MOBILE,
    }),
    custodialChildren() {
      const relations = this.profileMasterData.institutionProfiles.flatMap(profile => profile.relations);
      return relations.filter(relation => relation.hasCustody === true);
    },
    disabledNextButton() {
      if (this.stepStatus.length > 0) {
        const step = this.$route.params.step;
        if (step != undefined) {
          const stepIndex = this.stepStatus.findIndex(s => s.step == step);
          if (stepIndex != -1) {
            return this.stepStatus[stepIndex].disabledSubmit;
          }
        }
      }
      return true;
    },
    isPolicyStepValid() {
      return this.isPolicyAccepted;
    },
    isUpdateStepValid() {
      const stepName = this.$route.params.step;
      const stepIndex = this.stepStatus.findIndex(s => s.step == stepName);
      return this.stepStatus[stepIndex].disabledSubmit === false;
    },
    isConsentStepValid() {
      return this.$refs?.onboardingConsent?.validatedConsentAnswer();
    },
  },
  watch: {
    $route(to) {
      this.stepStatus = this.onboardingSteps;
      if (this.stepStatus.length > 0) {
        if (to.path.includes('onboarding')) {
          const step = to.params.step;
          if (this.stepStatus.findIndex(s => s.step == step) == -1) {
            const redirectToStep =
              Cookie.Read('isOnboardingStep') != null ? Cookie.Read('isOnboardingStep') : this.stepStatus[0].step;
            this.$router.push({
              name: 'stepOnboarding',
              params: { step: redirectToStep },
            });
          }
        }
      } else {
        window.location.href = '/';
      }
    },
    isOnboardingError() {
      if (this.isOnboardingError) {
        this.$refs.onboardingError.show();
      }
    },
  },
  mounted() {
    const em = this;
    if (this.isOnboardingError) {
      this.$refs.onboardingError.show();
    }
    if (this.getPersonalDataPolicies == null) {
      this.loadPersonalDataPolicies().then(() => {
        if (em.getPersonalDataPolicies != null && em.getPersonalDataPolicies.length > 0) {
          for (const policy of em.getPersonalDataPolicies) {
            em.policyUrl +=
              '<a href="' +
              policy.commonFile.file.url +
              '" class="policy-link" target="_blank">' +
              policy.commonFile.title +
              ' (' +
              policy.institution.institutionName +
              ')</a>, ';
            $('.policyURLs').html(em.policyUrl.slice(0, -2));
          }
        }
      });
    }
    if (this.onboardingStatus == null) {
      const em = this;
      this.checkIsOnboardingNeeded().then(() => {
        if (em.onboardingStatus === onboardingStatusEnum.NEEDS_STEP_UP) {
          em.setStepUpNotification({
            showStepUpNotification: true,
            showStepUpForOnboarding: true,
            redirectedUrl: window.location.href,
          });
        } else if (this.onboardingStatus === onboardingStatusEnum.REQUIRED) {
          em.initLoading = false;
          em.stepStatus = em.onboardingSteps;
          em.$router.push({
            name: 'stepOnboarding',
            params: { step: em.stepStatus[0].step },
          });
        } else {
          em.$router.push({ name: 'overblik' });
        }
      });
    } else if (this.onboardingStatus === onboardingStatusEnum.REQUIRED) {
      this.initLoading = false;
      this.stepStatus = this.onboardingSteps;
      this.$router.push({
        name: 'stepOnboarding',
        params: { step: this.stepStatus[0].step },
      });
    } else if (this.onboardingStatus == onboardingStatusEnum.NEEDS_STEP_UP) {
      this.setStepUpNotification({
        showStepUpNotification: true,
        redirectedUrl: window.location.href,
      });
    } else {
      this.$router.push({ name: 'overblik' });
    }
    this.getProfileMasterData();
  },
  methods: {
    onboardingRefresh() {
      Cookie.Erase('isOnboardingStep');
      this.$refs.onboardingError.hide();
      window.location.href = '/';
    },
    getIsAdditionalStepValid() {
      return this.$refs?.onboardingAdditionalData?.validatedAdditionalAnswer(true);
    },
    getNextButtonText() {
      const stepName = this.$route.params.step;
      if (stepName != undefined && this.stepStatus.length > 0) {
        if (this.stepStatus[this.stepStatus.length - 1].step == stepName) {
          return Vue.filter('fromTextKey')('SUBMIT');
        }
      }
      return Vue.filter('fromTextKey')('NEXT_STEP');
    },
    resetStep(step) {
      switch (step) {
        case 'update':
          this.showWarningAcceptPolicy = false;
          break;
        case 'consent':
          this.showWarningConsent = false;
          break;
        case 'additional':
          this.showWarningAdditional = false;
          break;
      }
    },
    goToStep(step = null) {
      let stepIndex = 0;
      if (step == null) {
        const stepName = this.$route.params.step;
        stepIndex = this.stepStatus.findIndex(s => s.step == stepName);
        if (stepIndex > -1 && stepIndex < this.stepStatus.length - 1) {
          stepIndex++;
        }
      } else {
        stepIndex = this.stepStatus.findIndex(s => s.step == step);
      }
      if (!this.stepStatus[stepIndex].isLocked) {
        this.stepSubmit(stepIndex);
      } else {
        this.stepSubmit();
      }
    },
    goBackStep() {
      const step = this.$route.params.step;
      const stepIndex = this.stepStatus.findIndex(s => s.step == step);
      if (stepIndex > 0 && !this.stepStatus[stepIndex - 1].isLocked) {
        this.$router.push({
          name: 'stepOnboarding',
          params: { step: this.stepStatus[stepIndex - 1].step },
        });
      }
    },
    getStepName(step) {
      return Vue.filter('fromTextKey')('ONBOARDING_STEP_' + step.toUpperCase());
    },
    getStepText(isTitle = false) {
      if (
        this.$route.params.step != undefined &&
        this.stepStatus.findIndex(s => s.step == this.$route.params.step) > -1
      ) {
        const key = isTitle ? 'STEP_TITLE_' : 'STEP_DESCRIPTION_';
        const stepName = this.$route.params.step;
        let text = '';
        if (isTitle) {
          text = Vue.filter('fromTextKey')(key + stepName.toUpperCase());
        } else {
          if (stepName == 'update') {
            text = '<p>' + Vue.filter('fromTextKey')(key + stepName.toUpperCase() + '_1') + '</p>';
            if (this.profile.role === portalRoles.EMPLOYEE) {
              text += '<p>' + Vue.filter('fromTextKey')(key + stepName.toUpperCase() + '_2') + '</p>';
            }
          } else {
            text =
              '<p>' +
              Vue.filter('fromTextKey')(key + stepName.toUpperCase() + '_1') +
              '</p>' +
              '<p>' +
              Vue.filter('fromTextKey')(key + stepName.toUpperCase() + '_2') +
              '</p>';
          }
        }
        return text;
      }
    },
    markOnboarded() {
      const em = this;
      this.markOnboardingCompleted({ onlyActiveProfile: true }).then(async () => {
        if (!this.isOnboardingError) {
          Cookie.Erase('isOnboardingStep');
          if (em.isChangedPrimaryInstProfile) {
            await em.getProfileContext({ parent: parentTypes.PORTAL });
          }
          em.$router.push({ name: 'overblik' });
        }
      });
    },
    async getProfileMasterData() {
      this.isLoading = true;
      const profiles = this.getProfilesByLogin.filter(profile => profile.portalRole === this.profile.role);
      const institutionProfileIds = profiles[0].institutionProfiles.map(p => p.id);
      this.profileMasterData = await profileService.getProfileMasterData(institutionProfileIds);
      this.isLoading = false;
    },
    submitToNextStep(stepIndex, nextStepIndex) {
      if (this.stepStatus[nextStepIndex] != null) {
        this.stepStatus[stepIndex].isLocked = false;
        this.stepStatus[nextStepIndex].isLocked = false;
        this.resetStep(this.stepStatus[nextStepIndex].step);
        Cookie.Create('isOnboardingStep', this.stepStatus[nextStepIndex].step);
        this.$router.push({
          name: 'stepOnboarding',
          params: { step: this.stepStatus[nextStepIndex].step },
        });
      } else {
        this.markOnboarded();
      }
    },
    getDeclinedConsentWarningMessage(consent) {
      let message;
      if (consent.consentId == this.consentNumber.NUMBER_36) {
        message = this.$options.filters.fromTextKey('CONSENT_WARNING_ONBOARDING_DECLINED_CONSENT_NUMBER_36');
      } else {
        message = this.$options.filters.fromTextKey('CONSENT_WARNING_DECLINED_CONSENT_NUMBER_' + consent.consentId);
      }
      return message;
    },
    submitConsent() {
      this.$refs.declinedSpecialConsentWarning.hide();
      const payload = this.$refs.onboardingConsent.submitConsent();
      payload.isOnboarding = true;
      this.updateConsentResponses(payload).then(() => {
        this.isLoading = false;
        this.loadConsentResponses();
        const stepIndex = this.stepStatus.findIndex(s => s.step == this.$route.params.step);
        const nextStepIndex = stepIndex + 1;
        this.submitToNextStep(stepIndex, nextStepIndex);
      });
    },
    stepSubmit(step = null) {
      const em = this;
      const stepName = this.$route.params.step;
      const stepIndex = this.stepStatus.findIndex(s => s.step == stepName);
      if (step != null && step < stepIndex && stepIndex > 0) {
        // step back
        this.$router.push({
          name: 'stepOnboarding',
          params: { step: this.stepStatus[step].step },
        });
        return;
      }
      let nextStepIndex = stepIndex + 1;
      if (step != null) {
        nextStepIndex = step;
      }
      let payload = null;
      switch (stepName) {
        case 'policy':
          if (!this.isPolicyStepValid) {
            this.showWarningAcceptPolicy = true;
          } else {
            this.showWarningAcceptPolicy = false;
            this.submitToNextStep(stepIndex, nextStepIndex);
          }
          break;
        case 'update':
          if (this.isUpdateStepValid) {
            this.isLoading = true;
            payload = this.$refs.onboardingUpdate.updateContactInfo();
            this.isChangedPrimaryInstProfile = payload.isChangedPrimaryInstProfile;
            this.updateProfileMasterData(payload).then(() => {
              em.isLoading = false;
              em.submitToNextStep(stepIndex, nextStepIndex);
            });
          }
          break;
        case 'consent':
          if (!this.isConsentStepValid) {
            this.showWarningConsent = true;
          } else {
            this.isLoading = true;
            this.declinedSpecialConsents = this.$refs.onboardingConsent.declinedSpecialQuestionEdited();
            if (this.declinedSpecialConsents.length > 0) {
              this.isLoading = false;
              this.$refs.declinedSpecialConsentWarning.show();
            } else {
              return this.submitConsent();
            }
          }
          break;
        case 'additional':
          if (!this.getIsAdditionalStepValid()) {
            this.showWarningAdditional = true;
          } else {
            this.showWarningAdditional = false;
            this.isLoading = true;
            payload = this.$refs.onboardingAdditionalData.submitAdditionalData();
            this.updateAdditionalData(payload).then(() => {
              em.isLoading = false;
              em.markOnboarded();
            });
          }
          break;
      }
      setTimeout(() => {
        em.isLoading = false;
      }, 10000);
    },
    checkExistConsent(consentResponse) {
      let consents = [];
      for (const consent of consentResponse) {
        consents = consents.concat(consent.consentResponses);
      }
      return consents.length > 0;
    },
    ...mapActions({
      checkIsOnboardingNeeded: types.CHECK_IS_ONBOARDING_NEEDED,
      getProfileContext: types.ACTION_GET_PROFILE_CONTEXT,
      loadConsentResponses: types.ACTION_GET_CONSENTS_RESPONSE,
      markOnboardingCompleted: types.MARK_ONBOARDING_COMPLETED,
      updateProfileMasterData: types.UPDATE_PROFILE_MASTER_DATA,
      updateConsentResponses: types.ACTION_UPDATE_CONSENT_RESPONSES,
      updateAdditionalData: types.UPDATE_ADDITIONAL_DATA,
      loadPersonalDataPolicies: types.LOAD_PERSONAL_DATA_POLICIES,
    }),
    ...mapMutations({
      resetConsent: types.MUTATE_RESET_MYPROFILE_PENDING_CONSENT,
      setStepUpNotification: types.MUTATE_SET_NOTIFICATION_STEP_UP,
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/login/onboarding.scss';
.aula-onboarding /deep/ a {
  font-weight: bold;
  color: $color-picker-button-blue;
  &:hover {
    text-decoration: underline;
  }
}
</style>
