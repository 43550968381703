<template>
  <div class="d-flex flex-column h-100 p-lg-1 p-0">
    <div class="searchArea">
      <typeahead-search
        ref="searchField"
        enable-keyboard-selection
        :value="searchModel.getQuery()"
        :placeholder="'MESSAGE_LIST_SEARCH_MESSAGES' | fromTextKey"
        @selected="runTypeaheadSearch"
        @resetSearch="clearAllInput"
        @inputChanged="getAllSubscriptionsWhenBlank"
        @updateValueInput="setTypeaheadSearchValue"
        @advanceSearchClick="showAdvanceSearchModal"
      />
    </div>
    <ObservingContainer class="threads scrollbar mt-1 mt-lg-0 pb-lg-2" :options="observerOptions">
      <ThreadCompactHeader v-if="!isMobile && selectedThreadView === threadViewEnum.COMPACT" class="color-grey-dark" />
      <transition-group name="list">
        <subscription-container
          v-for="subscription in sortedThreads"
          :key="`s-${subscription.id}`"
          :subscription-data="subscription"
        />
      </transition-group>
      <b-spinner v-if="isLoadingThreads" class="loading-spinner" />
      <div v-if="!moreSubscriptionsExist && !isLoadingThreads" class="p-3 text-center">
        {{ 'MESSAGE_NO_MORE_THREADS' | fromTextKey }}
      </div>
      <ObservedTarget v-if="isObservable" @onIntersecting="onBottomReached" />
    </ObservingContainer>
    <aula-modal
      ref="advanceSearchModal"
      header-icon="icon-Aula_search"
      header-text="MESSAGE_ADVANCED_SEARCH"
      ok-text="ARIA_LABEL_SEARCH"
      :css-class="advanceSearchClass"
      @cancelClicked="hideAdvancedSearchModal"
      @okClicked="runSearch(advancedSearchQuery)"
    >
      <MessageAdvancedSearch
        class="mx-lg-3"
        :query="advancedSearchQuery"
        :subject="advancedSearchSubject"
        :message-content="advancedSearchMessageContent"
        :filter="advancedSearchFilter"
        :from-date="advancedSearchFromDate"
        :to-date="advancedSearchToDate"
        :thread-creators="advancedSearchThreadCreators"
        :participants="advancedSearchParticipants"
        :with-attachments="advancedSearchWithAttachments"
        :folders="chosenFolderAndMailOwner"
        @updateSearchQuery="setAdvancedSearchQuery"
        @clearAllInput="clearAllInput"
        @updateSearchSubject="setAdvancedSearchSubject"
        @updateSearchMessageContent="setAdvancedSearchMessageContent"
        @updateSearchSelectedFilter="setAdvancedSearchFilter"
        @updateSearchFromDate="setAdvancedSearchFromDate"
        @updateSearchToDate="setAdvancedSearchToDate"
        @updateSearchThreadCreators="setAdvancedSearchThreadCreators"
        @updateSearchParticipants="setAdvancedSearchParticipants"
        @updateSearchWithAttachments="setAdvancedSearchWithAttachments"
      />
    </aula-modal>
  </div>
</template>
<script>
import { types } from '../../store/types/types';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import TypeaheadSearch from '../shared/TypeaheadSearch';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import { messageOwnerTypes } from '../../../shared/enums/messageOwnerTypes';
import { messageFiltersEnum } from '../../../shared/enums/messageFiltersEnum';
import $ from 'jquery';
import requestCancelTokenMixin from '../../../shared/mixins/requestCancelTokenMixin';
import SubscriptionContainer from './SubscriptionContainer';
import { messageProviderKeyEnum } from '../../../shared/enums/messageProviderKeyEnum';
import * as dateUtil from '../../../shared/utils/dateUtil';
import MessageAdvancedSearch from '../../../shared/PageFragments/MessageAdvancedSearch/MessageAdvancedSearch';
import ObservingContainer from '../../../shared/libs/intersection-observer/components/Container';
import ObservedTarget from '../../../shared/libs/intersection-observer/components/Target';
import ThreadCompactHeader from '../../../shared/PageFragments/ThreadViews/ThreadCompactHeader';
import { threadViewEnum } from '../../../shared/enums/threadViewEnum';

export default {
  components: {
    ThreadCompactHeader,
    ObservedTarget,
    ObservingContainer,
    SubscriptionContainer,
    TypeaheadSearch,
    MessageAdvancedSearch,
  },
  mixins: [requestCancelTokenMixin],
  inject: {
    setConversationLoadingState: messageProviderKeyEnum.SET_CONVERSATION_LOADING_STATE,
    restartPollingService: messageProviderKeyEnum.RESTART_POLLING_SERVICE,
    getIsDraftSelected: messageProviderKeyEnum.IS_DRAFT_SELECTED,
    getSearchModel: messageProviderKeyEnum.SEARCH_MODEL,
    getSelectedThreadView: messageProviderKeyEnum.SELECTED_THREAD_VIEW,
  },
  data: function () {
    return {
      title: 'Beskeder',
      openThreadCancelTokenSource: null,
      advancedSearchQuery: '',
      advancedSearchSubject: '',
      advancedSearchMessageContent: '',
      advancedSearchFilter: messageFiltersEnum.FILTER_ALL,
      advancedSearchFromDate: undefined,
      advancedSearchToDate: undefined,
      advancedSearchThreadCreators: [],
      advancedSearchParticipants: [],
      advancedSearchWithAttachments: false,
      threadViewEnum,
    };
  },
  computed: {
    ...mapGetters({
      folders: types.MESSAGES_GET_FOLDERS,
      messages: types.MESSAGES_GET,
      isLoadingThreads: types.IS_LOADING_THREADS,
      subscriptions: types.MESSAGES_GET_SUBSCRIPTIONS,
      subscriptionsIndex: types.MESSAGES_GET_SUBSCRIPTIONS_INDEX,
      draftThreads: types.MESSAGES_GET_DRAFT_THREADS,
      activeChildren: types.GET_ACTIVE_CHILDREN,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
      profile: types.GET_CURRENT_PROFILE,
      selectedThreadIds: types.MESSAGES_GET_CHOSEN_THREAD_IDS,
      selectedBundleIds: types.MESSAGES_GET_CHOSEN_BUNDLE_IDS,
      isMobile: types.GET_IS_MOBILE,
      subscriptionPressed: types.MESSAGES_GET_SUBSCRIPTIONS_ONPRESS_MOBILEVIEW,
      notifications: types.GET_NOTIFICATIONS,
      moreSubscriptionsExist: types.MESSAGES_GET_MORE_SUBSCRIPTIONS_EXIST,
      isSteppedUp: types.GET_GLOBAL_STEPPED_UP,
      chosenFolderAndMailOwner: types.MESSAGES_GET_CHOSEN_FOLDER_AND_MAIL_OWNER,
      hasPermission: types.HAS_PERMISSION,
      inSearch: types.MESSAGES_GET_IN_SEARCH,
      messageSearchQuery: types.MESSAGES_GET_ACTIVE_SEARCH_QUERY,
      commonInboxes: types.MESSAGES_GET_COMMON_INBOXES,
      selectedDraft: types.MESSAGES_GET_SELECTED_DRAFT,
      subscriptionsAndBundleThreads: types.MESSAGES_GET_SUBSCRIPTIONS_AND_BUNDLE_THREADS,
    }),
    advanceSearchClass() {
      let advanceSearchClass = 'aula-modal-lg';
      if (this.isMobile) {
        advanceSearchClass += ' color-grey-light';
      }
      return advanceSearchClass;
    },
    observerOptions() {
      return { rootMargin: '50%' };
    },
    isObservable() {
      return this.sortedThreads.length > 0;
    },
    selectedThreadView() {
      return this.getSelectedThreadView();
    },
    searchModel() {
      return this.getSearchModel();
    },
    isDraftSelected() {
      return this.getIsDraftSelected();
    },
    sortedThreads() {
      const shownSubscriptions = this.subscriptions.filter(subscription => !subscription.deleted);
      let draftThreads = [];
      if (
        (this.chosenFolderAndMailOwner.filter === messageFiltersEnum.FILTER_ALL ||
          this.chosenFolderAndMailOwner.filter === messageFiltersEnum.FILTER_DRAFT) &&
        this.searchModel.query.length === 0 &&
        !this.searchModel.getIsUsingAdvancedSearch()
      ) {
        draftThreads = Object.values(this.draftThreads);
      }
      return [...draftThreads.reverse(), ...shownSubscriptions];
    },
    currentSubscriptionId() {
      return this.$route.params?.id;
    },
  },
  watch: {
    inSearch() {
      this.restartPollingService();
    },
    currentSubscriptionId() {
      this.checkIfOpenThread();
    },
  },
  mounted() {
    this.checkIfOpenThread();
    window.addEventListener('keyup', this.scrollIntoViewOnFocused);
  },
  destroyed() {
    window.removeEventListener('keyup', this.scrollIntoViewOnFocused);
  },
  methods: {
    ...mapActions({
      updateThreadViewAction: types.UPDATE_THREADS_VIEW,
      openThread: types.SELECT_SUBSCRIPTION,
      searchSubscriptions: types.SEARCH_SUBSCRIPTIONS,
      deleteNotifications: types.DELETE_NOTIFICATIONS,
      loadMoreSubscriptions: types.LOAD_MORE_SUBSCRIPTIONS,
    }),
    ...mapMutations({
      setStepUpNotification: types.MUTATE_SET_NOTIFICATION_STEP_UP,
      setIsLoadingThreads: types.MUTATE_IS_LOADING_THREADS,
      setMessageSearchQuery: types.MUTATE_SUBSCRIPTIONS_SEARCH_QUERY,
      setChosenFilter: types.MUTATE_CHOSEN_FILTER,
      setSelectedThreadIds: types.MUTATE_CHOSEN_THREAD_IDS,
      setSelectedSubscriptionsIds: types.MUTATE_CHOSEN_SUBSCRIPTION_IDS,
      setSelectedBundleIds: types.MUTATE_CHOSEN_BUNDLE_IDS,
    }),
    onBottomReached() {
      if (this.moreSubscriptionsExist && !this.isLoadingThreads) {
        this.loadMoreThreads();
      }
    },
    loadMoreThreads() {
      let loadMoreAction = this.loadMoreSubscriptions;
      if (this.inSearch) {
        loadMoreAction = this.runAdvancedSearch;
        this.setMessageSearchQuery(this.searchModel.getQuery());
        this.searchModel.setOffset(this.subscriptions.length);
      }

      this.setIsLoadingThreads(true);
      return loadMoreAction().then(() => {
        this.setIsLoadingThreads(false);
      });
    },
    setTypeaheadSearchValue(value) {
      this.searchModel.setQuery(value);
    },
    setAdvancedSearchQuery(query) {
      this.advancedSearchQuery = query;
    },
    clearAllInput() {
      this.advancedSearchQuery = '';
      this.advancedSearchSubject = '';
      this.advancedSearchMessageContent = '';
      this.advancedSearchFilter = messageFiltersEnum.FILTER_ALL;
      this.advancedSearchFromDate = null;
      this.advancedSearchToDate = null;
      this.advancedSearchThreadCreators = [];
      this.advancedSearchParticipants = [];
      this.advancedSearchWithAttachments = false;

      this.searchModel.setQuery('');
      this.searchModel.resetSearchParams();

      this.setChosenFilter(this.advancedSearchFilter);

      this.getAllSubscriptionsWhenBlank('');
    },
    setAdvancedSearchSubject(subject) {
      this.advancedSearchSubject = subject;
    },
    setAdvancedSearchMessageContent(messageContent) {
      this.advancedSearchMessageContent = messageContent;
    },
    setAdvancedSearchFilter(filter) {
      this.advancedSearchFilter = filter;
    },
    setAdvancedSearchFromDate(fromDate) {
      this.advancedSearchFromDate = fromDate;
    },
    setAdvancedSearchToDate(toDate) {
      this.advancedSearchToDate = toDate;
    },
    setAdvancedSearchThreadCreators(threadCreators) {
      this.advancedSearchThreadCreators = threadCreators;
    },
    setAdvancedSearchParticipants(participants) {
      this.advancedSearchParticipants = participants;
    },
    setAdvancedSearchWithAttachments(withAttachments) {
      this.advancedSearchWithAttachments = withAttachments;
    },
    clearSearch() {
      this.$refs.searchField.resetSearchWithoutUpdate();
    },
    setLatestMessage(subscription, message) {
      this.subscriptions[subscription].latestMessage = message;
      this.subscriptions[subscription].lastReadMessageId = message.id;
    },
    openDraftThread() {
      this.openThread({ subscriptionId: this.currentSubscriptionId });
    },
    checkIfOpenThread() {
      if (this.isDraftSelected) {
        this.openDraftThread();
      }

      const subscription = this.subscriptionsAndBundleThreads.find(
        subscription => subscription.id === this.currentSubscriptionId
      );
      const isSensitive = subscription?.sensitive || this.selectedDraft.sensitive;

      if (isSensitive && !this.isSteppedUp) {
        this.setStepUpNotification({
          showStepUpNotification: true,
          redirectedUrl: window.location.href,
          parent: parentTypes.MESSAGES,
        });
        this.$router.push({ name: 'messages' });
        return;
      }

      if (subscription && !this.isDraftSelected) {
        this.openThreadOnDevice(subscription);
      }
    },
    openThreadOnDevice(subscription) {
      if (this.isMobile && this.subscriptionPressed) {
        return;
      }

      this.cancelPreviousOpenThreadRequest();
      this.generateOpenThreadCancelTokenSource();
      this.deleteNotificationsForThread(subscription.id);
      this.setConversationLoadingState(true);
      this.openThread({
        subscriptionId: subscription.id,
        subscriptionLeft: subscription.leaveTime != null,
        mailOwnerId: this.getMailOwnerId(subscription),
        otpInboxId: this.getOtpInboxId(subscription),
        cancelToken: this.openThreadCancelTokenSource.token,
      }).then(
        () => {
          this.resetOpenThreadCancelTokenSource();
          this.highlightAndScrollToWord();
          this.setConversationLoadingState(false);
          this.restartPollingService();
        },
        () => null
      );
    },
    getMailOwnerId(subscription) {
      let mailOwnerId = null;
      if (
        this.inSearch &&
        subscription.mailBoxOwnerType == messageOwnerTypes.COMMON_INBOX &&
        this.commonInboxes.find(c => c.id === subscription.mailBoxOwnerId)
      ) {
        mailOwnerId = subscription.mailBoxOwnerId;
      } else if (!this.inSearch && this.chosenFolderAndMailOwner.mailOwnerType == messageOwnerTypes.COMMON_INBOX) {
        mailOwnerId = this.chosenFolderAndMailOwner.mailOwnerId;
      }
      return mailOwnerId;
    },
    getOtpInboxId(subscription) {
      let mailOwnerId = null;
      if (
        this.profile.role === portalRoles.OTP &&
        subscription.mailBoxOwner &&
        subscription.mailBoxOwner.mailBoxOwnerType === messageOwnerTypes.OTP_INBOX
      ) {
        mailOwnerId = subscription.mailBoxOwner.id;
        this.chosenFolderAndMailOwner.mailOwnerType = messageOwnerTypes.OTP_INBOX;
        this.chosenFolderAndMailOwner.mailOwnerId = mailOwnerId;
      }
      return mailOwnerId;
    },
    showAdvanceSearchModal() {
      this.advancedSearchQuery = this.searchModel.getQuery();
      this.advancedSearchFilter = this.chosenFolderAndMailOwner.filter;
      this.$refs.advanceSearchModal.show();
    },
    hideAdvancedSearchModal() {
      this.$refs.advanceSearchModal.hide();
    },
    getAllSubscriptionsWhenBlank(val) {
      if (val === '' && !this.searchModel.getIsUsingAdvancedSearch()) {
        this.setIsLoadingThreads(true);
        if (this.chosenFolderAndMailOwner) {
          this.updateThreadViewAction({
            folderId: this.chosenFolderAndMailOwner.folderId,
            filterType: this.chosenFolderAndMailOwner.filter,
            mailOwnerId: this.chosenFolderAndMailOwner.mailOwnerId,
            sort: this.chosenFolderAndMailOwner.sort,
            order: this.chosenFolderAndMailOwner.order,
          }).then(() => {
            this.setIsLoadingThreads(false);
          });
        } else {
          this.$store.dispatch(types.INIT_SUBSCRIPTIONS).then(() => {
            this.setIsLoadingThreads(false);
          });
        }
        if (
          this.profile.role == portalRoles.EMPLOYEE &&
          this.hasPermission(permissionEnum.INBOX_SET_PERSONAL_AUTOREPLY)
        ) {
          this.$store.dispatch('messages/INIT_AUTOREPLY');
        }
      }
    },
    highlightAndScrollToWord() {
      $('.message-textarea .prefixIcon').focus();
      const query = this.searchModel.getQuery();
      if (query != '') {
        // TODO: Refactor this to use the highlightSubscriptionKeyword filter.
        if ($('.message-body:contains(' + query + '):eq(0)').length > 0) {
          $('.threads-container').animate({
            scrollTop:
              $('.messages-overview').offset().top + $('.message-body:contains(' + query + '):eq(0)').position().top,
          });
        }
        $('.message-body').unhighlight();
        $('.thread-metadata-creator').unhighlight();
        $('.subject-sent-to .subscriber').unhighlight();
        const queryWords = query.split(' ');
        queryWords.sort((a, b) => b.length - a.length);
        for (const word of queryWords) {
          $('.message-body').highlight(word, {
            wordsOnly: true,
            wordsBoundaryEnd: '',
          });
          $('.thread-metadata-creator').highlight(word, {
            wordsOnly: true,
            wordsBoundaryEnd: '',
          });
          $('.subject-sent-to .subscriber').highlight(word, {
            wordsOnly: true,
            wordsBoundaryEnd: '',
          });
        }
      }
    },
    async loadSearch(query) {
      this.searchModel.setQuery(query);
      this.searchModel.setOffset(0);
      this.setIsLoadingThreads(true);
      this.setMessageSearchQuery(query);

      if (query.length > 0) {
        await this.runAdvancedSearch();
      } else {
        this.getAllSubscriptionsWhenBlank();
      }
      this.setIsLoadingThreads(false);
    },
    runTypeaheadSearch(query) {
      this.searchModel.setQuery(query);
      this.loadSearchThread();
      this.resetMultipleSelections();
    },
    runSearch(query) {
      this.searchModel.setFilter(this.advancedSearchFilter);
      this.searchModel.setQuery(query);
      this.searchModel.setContent(this.advancedSearchMessageContent);
      this.searchModel.setFromDate(this.advancedSearchFromDate);
      this.searchModel.setToDate(this.advancedSearchToDate);
      this.searchModel.setHasAttachments(this.advancedSearchWithAttachments);
      this.searchModel.setParticipants(this.advancedSearchParticipants);
      this.searchModel.setSubject(this.advancedSearchSubject);
      this.searchModel.setThreadCreators(this.advancedSearchThreadCreators);

      this.setChosenFilter(this.advancedSearchFilter);
      this.loadSearchThread();

      this.resetMultipleSelections();
      this.$refs.advanceSearchModal.hide();
    },
    resetMultipleSelections() {
      this.setSelectedThreadIds([]);
      this.setSelectedSubscriptionsIds([]);
      this.setSelectedBundleIds([]);
    },
    loadSearchThread() {
      const query = this.searchModel.getQuery();
      if (this.searchModel.getIsUsingAdvancedSearch()) {
        this.loadAdvancedSearch();
      } else {
        if (query) {
          this.loadSearch(query);
        } else {
          this.getAllSubscriptionsWhenBlank(query);
        }
      }
    },
    async loadAdvancedSearch() {
      this.searchModel.setOffset(0);
      this.setIsLoadingThreads(true);
      this.runAdvancedSearch().then(() => {
        this.setIsLoadingThreads(false);
      });
      this.$refs.advanceSearchModal.hide();
    },
    deleteNotificationsForThread(threadId) {
      const notifications = this.notifications
        .filter(n => n.threadId == threadId)
        .map(n => ({
          notificationId: n.notificationId,
          institutionProfileId: n.institutionProfileId,
        }));
      if (notifications.length > 0) {
        this.deleteNotifications({ notifications: notifications });
      }
    },
    cancelPreviousOpenThreadRequest() {
      this.cancelAxiosRequest(this.openThreadCancelTokenSource);
    },
    generateOpenThreadCancelTokenSource() {
      this.openThreadCancelTokenSource = this.getAxiosCancelTokenSource();
    },
    resetOpenThreadCancelTokenSource() {
      this.openThreadCancelTokenSource = null;
    },
    scrollIntoViewOnFocused(e) {
      if (e.code === 'Tab') {
        document.activeElement.scrollIntoView({ block: 'center', inline: 'start' });
      }
    },
    runAdvancedSearch() {
      let fromDate = this.searchModel.getFromDate();
      let toDate = this.searchModel.getToDate();

      if (fromDate) {
        fromDate = dateUtil.format(dateUtil.startOf(fromDate, 'day'), 'YYYY-MM-DDTHH:mm:ss');
      }

      if (toDate) {
        toDate = dateUtil.format(dateUtil.endOf(toDate, 'day'), 'YYYY-MM-DDTHH:mm:ss');
      }

      return this.searchSubscriptions({
        text: this.searchModel.getQuery(),
        threadSubject: this.searchModel.getSubject(),
        messageContent: this.searchModel.getContent(),
        fromDate,
        toDate,
        threadCreators: this.searchModel.getThreadCreators(),
        participants: this.searchModel.getParticipants(),
        hasAttachments: this.searchModel.getHasAttachments(),
        offset: this.searchModel.getOffset(),
        activeChildren: this.activeChildren,
        activeInstitutions: this.activeInstitutions,
        profile: this.profile,
      });
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/core/scrollbar.scss';
@import '../../../shared/assets/scss/core/animations.scss';

.threads {
  overflow-x: hidden;
  flex-grow: 1;
  flex-basis: 0;

  @include breakpoint-lg {
    position: relative;
    overflow-y: scroll;
    //-webkit-overflow-scrolling: touch;
    overflow-x: hidden;
  }
  @include breakpoint-lg-down() {
    padding-bottom: 80px;
  }
  .searchField {
    position: relative;
    width: 100%;
    margin-bottom: 4px;
    height: 50px;
    font-size: 14px;
    border-radius: 0px;
  }
  .firefox & {
    padding-right: 0 !important;
  }
  .loading-spinner {
    margin: 24px auto;
    display: flex;
  }
}

.point-left {
  transform: rotate(-90deg);
  display: block;
  width: 30px;
  font-size: 25px;
}

.is-from-search {
  max-height: none;
}

.backToInbox {
  padding: 14px 20px 14px 0px;
  background-color: $color-grey-darker;

  .arrow {
    min-width: 64px;
  }

  .text {
    font-size: 12px;
  }

  i {
    margin-left: 10px;
    cursor: pointer;
    margin-top: -15px;
  }
}

.scrollbar {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  .firefox & {
    padding-right: 17px;
  }

  &:hover {
    padding-right: 0;
  }
}
.searchArea {
  /deep/ .input-group.search-input-group {
    .input-group-prepend {
      right: 25px;
      top: 15px;
    }
  }
  @include breakpoint-lg {
    height: 54px;
    width: 100%;
    border-bottom: solid 2px $color-grey-light;
  }
}
</style>
