<template>
  <aula-modal
    ref="groupPage"
    css-class="group-modal"
    :disable-close="true"
    :always-visible="group && Object.keys(group).length > 0"
    :hide-header="true"
    :hide-footer="true"
  >
    <div class="group-content paddingContent">
      <b-row class="margin-bottom group-header">
        <b-col v-if="!searchOnMobile" cols="10" class="padding-left">
          <div class="hide-desktop-up">
            <i
              tabindex="0"
              role="button"
              class="icon-Aula_app_arrow_slideback icon-header"
              :aria-label="'ARIA_LABEL_CLOSE' | fromTextKey"
              @click="closeGroupPage()"
              @keydown.enter="closeGroupPage()"
            />
          </div>
          <h1 class="group-title truncate" :aria-label="groupHeaderAriaLabel">
            <i class="icon-Aula_group" aria-hidden="true" />&nbsp;&nbsp;{{ group.name }}
          </h1>
        </b-col>
        <b-col v-if="searchOnMobile" cols="10" class="group-search-mobile">
          <AulaSearchInput
            v-model="searchString"
            :aria-label="'ARIA_LABEL_ENTER_KEY_SEARCH' | fromTextKey"
            :placeholder="'GROUP_SEARCH_ON_GROUP' | fromTextKey"
            @onSearchClicked="searchGroup"
            @onClearClicked="clearSearchInput"
          />
        </b-col>
        <b-col cols="2" class="text-right hide-desktop-down">
          <i
            tabindex="0"
            role="button"
            class="icon-Aula_close icon-header"
            :aria-label="'ARIA_LABEL_CLOSE' | fromTextKey"
            @click="closeGroupPage()"
            @keydown.enter="closeGroupPage()"
          />
        </b-col>
        <b-col v-if="!searchOnMobile" cols="2" class="hide-desktop-up icon-header-search-container">
          <i
            role="button"
            tabindex="0"
            class="icon-Aula_search icon-header-search"
            :aria-label="'ARIA_LABEL_SEARCH' | fromTextKey"
            @click="changeSearchOnMobile()"
          />
        </b-col>
        <b-col v-if="searchOnMobile" cols="2" class="hide-desktop-up icon-header-close-container">
          <i
            role="button"
            tabindex="0"
            class="icon-Aula_close icon-header-close"
            :aria-label="'ARIA_LABEL_CLOSE' | fromTextKey"
            @click="changeSearchOnMobile()"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-navbar
            v-if="typeof group != 'undefined' && Object.keys(group).length > 0 && showNavbar"
            class="group-page-navbar"
            :toggleable="false"
          >
            <b-navbar-nav>
              <template v-for="(module, i) in visibleModules">
                <b-nav-item
                  v-if="
                    module.showOnDashboard &&
                    i + 1 < numberOfMenuItems &&
                    ![moduleTypes.MESSAGES, moduleTypes.DOCUMENTS, moduleTypes.PRESENCE].includes(module.module.type)
                  "
                  :key="'vm' + i"
                  :class="groupPageComponent == module.module.type ? 'active' : ''"
                  :aria-current="groupPageComponent === module.module.type ? 'page' : false"
                  :title="module.module.name.toUpperCase()"
                  @click="onModuleNavItemClicked(module)"
                >
                  {{ module.module.name.toUpperCase() }}
                </b-nav-item>
              </template>
              <template v-for="(widget, j) in visibleWidgets">
                <b-nav-item
                  v-if="widget.showOnDashboard && visibleModulesLength + j + 1 < numberOfMenuItems"
                  :key="'vw' + j"
                  :class="groupPageComponent == widget.widget.url ? 'active' : ''"
                  :aria-current="groupPageComponent === widget.widget.url ? 'page' : false"
                  :title="widget.widget.name.toUpperCase()"
                  @click="onWidgetNavItemClicked(widget)"
                >
                  {{ widget.widget.name.toUpperCase() }}
                </b-nav-item>
              </template>
              <b-nav-item
                v-if="isAdmin === 1 && visibleModulesLength + visibleWidgetsLength < numberOfMenuItems && !isMobile"
                :class="{
                  active: groupPageComponent == moduleTypes.GROUP_ADMINISTRATION,
                  'has-badge': amountOfAppliedMembershipsAndReportNotifications > 0,
                }"
                :aria-current="groupPageComponent === moduleTypes.GROUP_ADMINISTRATION ? 'page' : false"
                :title="'GROUP_ADMINISTRATION_TITLE' | fromTextKey"
                @click="
                  $router.push({
                    name: 'group-administration',
                    params: { groupId: $route.params.groupId },
                  });
                  groupPageComponent = moduleTypes.GROUP_ADMINISTRATION;
                "
              >
                {{ 'GROUP_ADMINISTRATION_TITLE' | fromTextKey }}
                <badge-notification
                  v-if="amountOfAppliedMembershipsAndReportNotifications > 0"
                  :amount="amountOfAppliedMembershipsAndReportNotifications"
                  :border="true"
                />
              </b-nav-item>
              <b-nav-item
                v-if="
                  isMember === 1 &&
                  group.allowMembersToBeShown &&
                  visibleModulesLength + visibleWidgetsLength + isAdmin < numberOfMenuItems
                "
                :class="groupPageComponent == moduleTypes.GROUP_MEMBERS ? 'active' : ''"
                :aria-current="groupPageComponent === moduleTypes.GROUP_MEMBERS ? 'page' : false"
                :title="'GROUP_MEMBERS_TITLE' | fromTextKey"
                @click="
                  $router.push({
                    name: 'group-members',
                    params: { groupId: $route.params.groupId },
                  });
                  groupPageComponent = moduleTypes.GROUP_MEMBERS;
                "
              >
                {{ 'GROUP_MEMBERS_TITLE' | fromTextKey }}
              </b-nav-item>
              <b-nav-item
                v-if="
                  showLeaveGroup === 1 &&
                  visibleModulesLength + visibleWidgetsLength + isAdmin + isMember < numberOfMenuItems
                "
                :title="'GROUP_LEAVE_GROUP' | fromTextKey"
                @click="$refs.leaveModal.show()"
              >
                {{ 'GROUP_LEAVE_GROUP' | fromTextKey }}
              </b-nav-item>
            </b-navbar-nav>
            <b-navbar-nav class="hide-desktop-down">
              <b-nav-item-dropdown
                v-if="
                  visibleModulesLength + visibleWidgetsLength + isAdmin + showLeaveGroup + isMember > numberOfMenuItems
                "
                no-caret
                right
              >
                <template slot="button-content">
                  {{ 'GROUP_OTHERS' | fromTextKey }}
                  <i
                    class="icon-Aula_down-arrow"
                    style="font-size: 0.3em; position: absolute; left: 102px; top: 28px"
                  />
                </template>
                <template v-for="(module, k) in visibleModules">
                  <b-dropdown-item
                    v-if="module.showOnDashboard && k + 1 >= numberOfMenuItems"
                    :key="'vm2' + k"
                    :class="groupPageComponent == module.module.type ? 'active' : ''"
                    :aria-current="groupPageComponent === module.module.type ? 'page' : false"
                    :title="module.module.name.toUpperCase()"
                    @click="
                      $router.push({
                        name: 'group-' + module.module.type,
                        params: { groupId: $route.params.groupId },
                      });
                      groupPageComponent = module.module.type;
                    "
                  >
                    {{ module.module.name.toUpperCase() }}
                  </b-dropdown-item>
                </template>
                <template v-for="(widget, l) in visibleWidgets">
                  <b-dropdown-item
                    v-if="widget.showOnDashboard && visibleModulesLength + l + 1 >= numberOfMenuItems"
                    :key="'vw2' + l"
                    :class="groupPageComponent == widget.widget.type ? 'active' : ''"
                    :aria-current="groupPageComponent === widget.widget.type ? 'page' : false"
                    :title="widget.widget.name.toUpperCase()"
                    @click="
                      $router.push({
                        name: 'groupWidget',
                        params: {
                          groupId: $route.params.groupId,
                          id: 'W' + widget.widget.widgetId + 'V' + widget.widget.widgetVersion,
                        },
                      });
                      groupPageComponent = widget.widget.type;
                    "
                  >
                    {{ widget.widget.name.toUpperCase() }}
                  </b-dropdown-item>
                </template>
                <b-dropdown-item
                  v-if="isAdmin === 1 && visibleModulesLength + visibleWidgetsLength >= numberOfMenuItems"
                  :class="groupPageComponent == 'group-administration' ? 'active' : ''"
                  :aria-current="groupPageComponent === 'group-administration' ? 'page' : false"
                  :title="'GROUP_ADMINISTRATION_TITLE' | fromTextKey"
                  @click="
                    $router.push({
                      name: 'group-administration',
                      params: { groupId: $route.params.groupId },
                    });
                    groupPageComponent = 'group-administration';
                  "
                >
                  {{ 'GROUP_ADMINISTRATION_TITLE' | fromTextKey }}
                  <badge-notification
                    v-if="amountOfAppliedMembershipsAndReportNotifications > 0"
                    :amount="amountOfAppliedMembershipsAndReportNotifications"
                    :border="true"
                  />
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    isMember === 1 &&
                    group.allowMembersToBeShown &&
                    visibleModulesLength + visibleWidgetsLength + isAdmin >= numberOfMenuItems
                  "
                  :class="groupPageComponent == 'group-members' ? 'active' : ''"
                  :aria-current="groupPageComponent === 'group-members' ? 'page' : false"
                  :title="'GROUP_MEMBERS_TITLE' | fromTextKey"
                  @click="
                    $router.push({
                      name: 'group-members',
                      params: { groupId: $route.params.groupId },
                    });
                    groupPageComponent = 'group-members';
                  "
                >
                  {{ 'GROUP_MEMBERS_TITLE' | fromTextKey }}
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    showLeaveGroup === 1 &&
                    visibleModulesLength + visibleWidgetsLength + isAdmin + isMember >= numberOfMenuItems
                  "
                  :title="'GROUP_LEAVE_GROUP' | fromTextKey"
                  @click="$refs.leaveModal.show()"
                >
                  {{ 'GROUP_LEAVE_GROUP' | fromTextKey }}
                </b-dropdown-item>
              </b-nav-item-dropdown>
            </b-navbar-nav>
            <b-navbar-nav class="ml-auto hide-desktop-down px-1">
              <b-nav-form class="group-search search-module" @submit.prevent>
                <AulaSearchInput
                  v-model="searchString"
                  :aria-label="'ARIA_LABEL_ENTER_KEY_SEARCH' | fromTextKey"
                  :placeholder="'GROUP_SEARCH_ON_GROUP' | fromTextKey"
                  @onSearchClicked="searchGroup"
                  @onClearClicked="clearSearchInput"
                />
              </b-nav-form>
            </b-navbar-nav>
          </b-navbar>
          <b-card class="group-page-component">
            <router-view
              name="group"
              parent="group"
              :number-of-menu-items="numberOfMenuItems"
              calendar-id="group-calendar"
            />
          </b-card>
        </b-col>
      </b-row>
    </div>
    <aula-modal
      ref="leaveModal"
      header-text="GROUP_CONFIRM_LEAVE"
      ok-text="BUTTON_YES"
      @cancelClicked="$refs.leaveModal.hide()"
      @okClicked="leaveGroup()"
    >
      {{ 'GROUP_LEAVE_INFO' | fromTextKey }}
    </aula-modal>
  </aula-modal>
</template>

<script>
import { types } from '../../store/types/types';
import { mapActions, mapGetters } from 'vuex';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import { groupMembershipRoles } from '../../../shared/enums/groupMembershipRoles';
import { groupAccessTypes } from '../../../shared/enums/groupAccessTypes';
import { moduleTypes } from '../../../shared/enums/moduleTypes';
import { groupTypes } from '../../../shared/enums/groupTypes';
import { Cookie } from '../../../shared/assets/plugins/cookie';
import BadgeNotification from '../../../shared/components/BadgeNotification.vue';
import { notificationTypes } from '../../../shared/enums/notificationTypes';
import { notificationEventTypes } from '../../../shared/enums/notificationEventTypes';
import Vue from 'vue';
import $ from 'jquery';
import { groupMembershipActions } from '../../../shared/enums/groupMembershipActions';
import AulaSearchInput from '../../../shared/components/AulaSearchInput';

export default {
  components: {
    AulaSearchInput,
    BadgeNotification,
  },
  data: function () {
    return {
      searchString: '',
      groupPageComponent: '',
      searchOnMobile: false,
      showNavbar: true,
      numberOfMenuItems: 4,
      moduleTypes: moduleTypes,
      groupAccessTypes: groupAccessTypes,
      groupTypes: groupTypes,
    };
  },
  computed: {
    ...mapGetters({
      group: types.GET_ACTIVE_GROUP,
      institutions: types.GET_INSTITUTIONS,
      userGroups: types.GET_USER_GROUPS,
      groupMemberships: types.GET_GROUP_MEMBERSHIPS,
      activeInstitutionCodes: types.GET_ACTIVE_INSTITUTIONS,
      activeChildrenIds: types.GET_ACTIVE_CHILDREN,
      profile: types.GET_CURRENT_PROFILE,
      isMobile: types.GET_IS_MOBILE,
      hasPermissionGroup: types.HAS_PERMISSION_ON_GROUP,
      hasPermission: types.HAS_PERMISSION,
      notifications: types.GET_NOTIFICATIONS,
    }),
    appliedInstitutions() {
      if (this.group.type === groupTypes.MUNICIPAL) {
        return this.institutions.filter(inst => this.group.membershipInstitutions.includes(inst.institutionCode));
      }
      if (this.group.type === groupTypes.CROSS_INSTITUTIONAL) {
        return this.institutions.filter(inst =>
          inst.administrativeAuthority?.institutionCodes.includes(this.group.institutionCode)
        );
      }
      return this.institutions.filter(inst => this.group.institutionCode === inst.institutionCode);
    },
    groupHeaderAriaLabel() {
      return Vue.filter('fromTextKey')('ARIA_LABEL_GROUP_PAGE') + ' ' + this.group.name;
    },
    amountOfAppliedMembershipsAndReportNotifications() {
      const appliedMemberships = this.groupMemberships.filter(m => m.groupRole === groupMembershipRoles.APPLIED).length;
      const reportNotifications = this.notifications.filter(
        n =>
          n.sharedGroupIds &&
          n.sharedGroupIds.includes(this.group.id) &&
          n.notificationType === notificationTypes.BADGE &&
          (n.notificationEventType === notificationEventTypes.POST_WAS_REPORTED ||
            n.notificationEventType === notificationEventTypes.MEDIA_WAS_REPORTED ||
            n.notificationEventType === notificationEventTypes.COMMENT_WAS_REPORTED)
      ).length;
      return appliedMemberships + reportNotifications;
    },
    isMember() {
      const groupId = this.group.id != null ? this.group.id : this.$route.params.groupId;
      return this.userGroups.find(group => group.id == groupId) != null ? 1 : 0;
    },
    visibleModules() {
      let visibleModules = this.group.validGroupModules.filter(
        module => module.showOnDashboard && module.module.type !== moduleTypes.DOCUMENTS
      );
      visibleModules = visibleModules.sort((module1, module2) => (module1.module.id > module2.module.id ? 1 : -1));
      if (this.hasPermissionToAccessComments && Cookie.Read('fromAdminGroup')) {
        if (visibleModules.filter(m => m.module != null && m.module.type == moduleTypes.OVERVIEW).length == 0) {
          visibleModules.push({
            module: {
              name: 'OVERBLIK',
              url: '/overblik',
              type: moduleTypes.OVERVIEW,
            },
            showOnDashboard: true,
          });
        }
        if (
          this.group.type != groupTypes.MUNICIPAL &&
          visibleModules.filter(m => m.module != null && m.module.type == moduleTypes.GALLERY).length == 0
        ) {
          visibleModules.push({
            module: {
              name: 'GALLERI',
              url: '/galleri',
              type: moduleTypes.GALLERY,
            },
            showOnDashboard: true,
          });
        }
      }
      return visibleModules;
    },
    visibleWidgets() {
      return this.group.validGroupWidgets.filter(widget => widget.showOnDashboard);
    },
    visibleModulesLength() {
      return this.group.validGroupModules.filter(module => module.showOnDashboard).length;
    },
    visibleWidgetsLength() {
      return this.group.validGroupWidgets.filter(widget => widget.showOnDashboard).length;
    },
    hasPermissionToAdministrateGroup() {
      return this.hasPermissionGroup(permissionEnum.HANDLE_GROUP_APPLICATION, this.group.id);
    },
    hasPermissionToAccessComments() {
      return (
        this.hasPermission(permissionEnum.HANDLE_USER_ROLES) || this.hasPermission(permissionEnum.SEARCH_ACCESS_GROUPS)
      );
    },
    isAdmin() {
      return this.isMember === 1 && this.hasPermissionToAdministrateGroup ? 1 : 0;
    },
    showLeaveGroup() {
      const groupId = this.group.id != null ? this.group.id : this.$route.params.groupId;
      const activeGroup = this.userGroups.find(group => group.id == groupId);
      return this.isMember === 1 &&
        activeGroup != null &&
        activeGroup.membershipType == groupMembershipRoles.DIRECT &&
        !this.hasPermissionToAdministrateGroup &&
        this.group != null &&
        this.group.access != groupAccessTypes.CLOSED &&
        this.group.type != groupTypes.UNI
        ? 1
        : 0;
    },
  },
  watch: {
    userGroups() {
      if (this.userGroups.find(group => group.id === this.group.id) == null) {
        this.closeGroupPage();
      }
    },
  },
  beforeDestroy() {
    if (Cookie.Read('fromAdminGroup') == null) {
      this.$refs.groupPage.hide();
      this.resetActiveGroup();
    }
  },
  mounted() {
    this.getNumberOfMenuItems();
    setTimeout(function () {
      // Safari redraw fix
      const sel = document.querySelector('.group-content');
      if (sel != null && sel.style != null) {
        sel.style.display = 'none';
        sel.offsetHeight;
        sel.style.display = '';
      }
    }, 100);
    window.addEventListener('resize', this.getNumberOfMenuItems);
    this.init();
  },
  methods: {
    ...mapActions({
      loadGroupMemberships: types.LOAD_GROUP_MEMBERSHIPS,
      joinOrLeaveGroup: types.SET_JOIN_OR_LEAVE_GROUP,
      setSearchString: types.SET_SEARCH_STRING_GROUP,
      loadSearchResults: types.LOAD_GROUP_SEARCH_RESULTS,
      resetSelectedUser: types.RESET_SELECTED_USER,
      loadGroup: types.SET_ACTIVE_GROUP,
      resetActiveGroup: types.RESET_ACTIVE_GROUP,
      loadNotifications: types.LOAD_NOTIFICATIONS,
    }),
    clearSearchInput() {
      this.searchString = '';
    },
    getNumberOfMenuItems() {
      if ($(window).width() < 1400) {
        this.numberOfMenuItems = 9999;
      } else if ($(window).width() < 1540) {
        this.numberOfMenuItems = 5;
      } else if ($(window).width() < 1680) {
        this.numberOfMenuItems = 6;
      } else {
        this.numberOfMenuItems = 7;
      }
    },
    searchGroup() {
      if (this.searchString != '') {
        this.groupPageComponent = 'search-container';
        this.$router.push({
          name: 'group-search-container',
          params: { groupId: this.$route.params.groupId },
        });
        this.setSearchString(this.searchString);
        this.loadSearchResults({
          query: this.searchString,
          institutionCode: this.group.institutionCode,
          groupId: this.group.id,
          docType: 'Post',
        });
        if (this.isMobile) {
          this.showNavbar = false;
        }
        this.resetSelectedUser();
      }
    },
    leaveGroup() {
      this.$refs.leaveModal.hide();
      const institutionProfileIds = this.appliedInstitutions.map(inst => inst.institutionProfileId);
      this.joinOrLeaveGroup({
        groupId: this.group.id,
        institutionProfileIds,
        action: groupMembershipActions.LEAVE,
      }).then(() => {
        this.closeGroupPage();
      });
    },
    closeGroupPage() {
      Cookie.Erase('fromAdminGroup');
      this.$refs.groupPage.hide();
      this.resetActiveGroup();
      this.$router.push({ name: 'overblik' });
      this.reloadNotifications();
    },
    changeSearchOnMobile() {
      if (!this.searchOnMobile) {
        this.searchOnMobile = true;
        this.showNavbar = false;
      } else {
        this.showNavbar = true;
        this.searchOnMobile = false;
        this.searchString = '';
        this.initFirstModuleOrWidget();
      }
    },
    onModuleNavItemClicked(module) {
      this.$router.push({
        name: 'group-' + module.module.type,
        params: { groupId: this.$route.params.groupId },
      });
      this.groupPageComponent = module.module.type;
      this.reloadNotifications();
    },
    onWidgetNavItemClicked(widget) {
      this.$router.push({
        name: 'groupWidget',
        params: {
          groupId: this.$route.params.groupId,
          id: 'W' + widget.widget.widgetId + 'V' + widget.widget.widgetVersion,
        },
      });
      this.groupPageComponent = widget.widget.url;
      this.reloadNotifications();
    },
    reloadNotifications() {
      this.loadNotifications({
        activeInstitutionCodes: this.activeInstitutionCodes,
        activeChildrenIds: this.activeChildrenIds,
      });
    },
    init() {
      if (this.$route.path.includes('gruppe')) {
        if (this.$route.name === 'fromAdminGroup') {
          Cookie.Create('fromAdminGroup', true, 1);
        }
        this.$refs.groupPage.show();
        if (this.$route.params.groupId && !Object.keys(this.group).length > 0) {
          this.loadGroup({ groupId: this.$route.params.groupId }).then(() => {
            this.initFirstModuleOrWidget();
            if (this.isMember === 1 && (this.group.allowMembersToBeShown || this.isAdmin === 1)) {
              const groupId = this.group.id != null ? this.group.id : this.$route.params.groupId;
              if (groupId != null) {
                this.loadGroupMemberships({ groupId: groupId });
              }
            }
          });
        } else {
          this.initFirstModuleOrWidget();
          if (this.isMember === 1 && (this.group.allowMembersToBeShown || this.isAdmin === 1)) {
            const groupId = this.group.id != null ? this.group.id : this.$route.params.groupId;
            if (groupId != null) {
              this.loadGroupMemberships({ groupId: groupId });
            }
          }
        }
      }
    },
    initFirstModuleOrWidget() {
      if (this.$route.name === 'group-dashboard' || Cookie.Read('fromAdminGroup')) {
        for (const module of this.visibleModules) {
          if (module.showOnDashboard) {
            this.groupPageComponent = module.module.type;
            let params = { groupId: this.$route.params.groupId };
            if (Cookie.Read('fromAdminGroup')) {
              params = { groupId: this.$route.params.groupId, fromAdmin: true };
            }
            this.$router.push({
              name: 'group-' + module.module.type,
              params: params,
            });
            return;
          }
        }
        for (const widget of this.visibleWidgets) {
          if (widget.showOnDashboard) {
            this.groupPageComponent = widget.widget.url;
            this.$router.push({
              name: 'groupWidget',
              params: {
                groupId: this.$route.params.groupId,
                id: 'W' + widget.widget.widgetId + 'V' + widget.widget.widgetVersion,
              },
            });
            return;
          }
        }
        if (this.isAdmin) {
          this.groupPageComponent = 'group-administration';
          this.$router.push({
            name: 'group-administration',
            params: { groupId: this.$route.params.groupId },
          });
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/components/groups/group-page.scss';
</style>
