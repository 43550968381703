<template>
  <div v-click-outside="hideDropdown" class="aula-groups" :title="'GROUPS_LABEL_CHILD' | fromTextKey">
    <div
      id="dropdown-groups"
      class="header-dropdown"
      role="button"
      tabindex="0"
      :aria-label="'ARIA_LABEL_MY_GROUPS' | fromTextKey"
      :class="{ active: showGroupsDropdown }"
      @click="
        !isPreviewMode
          ? toggleHeaderDropdown({
              dropdown: 'groups',
              visible: showGroupsDropdown,
            })
          : ''
      "
      @keyup.enter="
        !isPreviewMode
          ? toggleHeaderDropdown({
              dropdown: 'groups',
              visible: showGroupsDropdown,
            })
          : ''
      "
    >
      <i v-if="!isChild" class="icon-Aula_group" />
      <img v-else class="menu-icon" src="static/icons/icon-Aula_group_child.svg" />
      <badge-notification v-if="badgeAmount > 0" :amount="badgeAmount" :border="true" />
    </div>
    <transition
      name="slide-fade"
      :leave-active-class="isClickedCurrentTab ? 'slide-fade-leave-active' : 'slide-fade-leave-active-hide'"
      :leave-to-class="isClickedCurrentTab ? 'slide-fade-leave-to' : 'leave-to'"
    >
      <div v-show="showGroupsDropdown" class="header-dropdown-menu" role="dialog">
        <groups-list />
      </div>
    </transition>
  </div>
</template>

<script>
import { types } from '../../store/types/types';
import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';
import GroupsList from './GroupsList.vue';
import BadgeNotification from '../../../shared/components/BadgeNotification.vue';
import { institutionRole } from '../../../shared/enums/institutionRole';
import { notificationTypes } from '../../../shared/enums/notificationTypes';
import { notificationEventTypes } from '../../../shared/enums/notificationEventTypes';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import $ from 'jquery';

export default {
  data: function () {
    return {
      institutionRole: institutionRole,
    };
  },
  computed: {
    ...mapGetters({
      profile: types.GET_CURRENT_PROFILE,
      isPreviewMode: types.GET_IS_PREVIEW_MODE,
      isTablet: types.GET_IS_TABLET,
      isMobile: types.GET_IS_MOBILE,
      showGroupsDropdown: types.GET_SHOW_GROUPS_DROPDOWN,
      showSearchDropdown: types.GET_SHOW_SEARCH_DROPDOWN,
      showProfileDropdown: types.GET_SHOW_PROFILE_DROPDOWN,
      notifications: types.GET_NOTIFICATIONS,
      userGroups: types.GET_USER_GROUPS,
      hasPermissionGroup: types.HAS_PERMISSION_ON_GROUP,
    }),
    isClickedCurrentTab: function () {
      return this.showGroupsDropdown || (!this.showSearchDropdown && !this.showProfileDropdown);
    },
    isChild() {
      return this.profile.institutionRoles.includes(this.institutionRole.EARLY_STUDENT);
    },
    badgeAmount() {
      let badgeAmount = 0;
      const relevantNotifications = this.notifications.filter(
        n =>
          n.sharedGroupIds &&
          n.sharedGroupIds.length > 0 &&
          n.sharedGroupIds.filter(g => this.userGroups.find(ug => ug.id == g) != null).length > 0 &&
          n.notificationType === notificationTypes.BADGE &&
          (n.notificationEventType === notificationEventTypes.POST_WAS_REPORTED ||
            n.notificationEventType === notificationEventTypes.MEDIA_WAS_REPORTED ||
            n.notificationEventType === notificationEventTypes.COMMENT_WAS_REPORTED)
      );
      for (const notification of relevantNotifications) {
        for (const groupId of notification.sharedGroupIds) {
          if (this.hasPermissionGroup(permissionEnum.HANDLE_GROUP_APPLICATION, groupId)) {
            badgeAmount++;
          }
        }
      }
      return badgeAmount;
    },
  },
  methods: {
    ...mapActions({
      toggleHeaderDropdown: types.TOGGLE_HEADER_DROPDOWN,
    }),
    hideDropdown() {
      this.$store.commit(types.MUTATE_HIDE_GROUPS_DROPDOWN);
    },
    updateScrollOnResize() {
      if (!this.showGroupsDropdown) {
        return;
      }
      if (!this.isMobile && !this.isTablet) {
        // Remove noscroll in case you've moved from small desktop or below to large desktop
        $('html').removeClass('noscroll');
      } else {
        $('html').addClass('noscroll');
      }
    },
  },
  watch: {
    showGroupsDropdown(to) {
      if (!to) {
        // Always remove noscroll when hiding the groups dropdown
        $('html').removeClass('noscroll');
      }
      if (to && (this.isMobile || this.isTablet)) {
        $('html').addClass('noscroll');
      }
    },
  },
  created() {
    window.addEventListener('resize', this.updateScrollOnResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.updateScrollOnResize);
  },
  mounted() {
    const htmlSelector = 'html';
    const groupListSelector = '.groups-list';
    $(groupListSelector).mouseenter(() => {
      const htmlElement = document.getElementsByTagName(htmlSelector)[0];
      if (htmlElement) {
        const hasVerticalScrollbar = htmlElement.scrollHeight > htmlElement.clientHeight;
        if (!this.isMobile && !this.isTablet && this.showGroupsDropdown && hasVerticalScrollbar) {
          $(htmlSelector).addClass('noscroll');
        }
      }
    });
    $(groupListSelector).mouseleave(() => {
      if (!this.isMobile && !this.isTablet && this.showGroupsDropdown) {
        $(htmlSelector).removeClass('noscroll');
      }
    });
  },
  components: {
    GroupsList,
    BadgeNotification,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/core/animations.scss';
.aula-groups {
  border-right: 1px solid $color-white;
  border-left: 1px solid $color-white;
  .header-dropdown {
    --aula-badge-box-shadow-color: var(--color-primary-dark);
    &.active,
    &:hover {
      --aula-badge-box-shadow-color: var(--color-primary-darker);
    }
  }
}
img.menu-icon {
  width: var(--default-menu-icon-size);
}

.header-dropdown-menu {
  bottom: 0;
  height: auto;
  position: fixed;
}

.small-text {
  text-transform: uppercase;

  .early-student & {
    color: #fff !important;
    text-transform: capitalize;
    font-family: Baloo;
    font-weight: normal;
    font-size: 15px !important;
  }
}
</style>
