<template>
  <b-card
    class="search-result-item margin-bottom-20"
    tabindex="0"
    @keypress.enter="navigateToResult"
    @click="navigateToResult"
  >
    <div class="search-result-icon-col">
      <template v-if="isChild">
        <img class="menu-icon" :src="'static/icons/icon-Aula_write_child.svg'" />
      </template>
      <i v-else class="icon-Aula_write" />
    </div>
    <div class="search-result-text-col">
      <router-link
        tag="span"
        :to="{
          name: 'uniqueThread',
          params: { id: result.threadId },
          query: {
            fromSearch: true,
            folderId: result.folderId,
            mailBoxOwnerType:
              result.mailBoxOwner.mailBoxOwnerType == messageOwnerTypes.COMMON_INBOX
                ? result.mailBoxOwner.mailBoxOwnerType
                : null,
            mailOwnerId:
              result.mailBoxOwner.mailBoxOwnerType == messageOwnerTypes.COMMON_INBOX ? result.mailBoxOwner.id : null,
          },
        }"
      >
        <div class="card-header-text">
          <keyword-highlight :queries="searchString">
            {{ result.title }}
          </keyword-highlight>
        </div>
        <div class="card-text">
          {{ 'SEARCH_RESULT_MESSAGE_BY' | fromTextKey }}
          <keyword-highlight :queries="searchString"> {{ result.author }} </keyword-highlight><br />
          {{ 'SEARCH_RESULT_MESSAGE_TYPE' | fromTextKey }}
          {{ ('SEARCH_RESULT_MESSAGE_' + result.docType.toUpperCase()) | fromTextKey }}
          <div
            v-if="result.message != null && result.message.length > 0"
            class="truncate"
            v-html="Vue.filter('highlightKeyword')(result.message, searchString)"
          />
        </div>
      </router-link>
    </div>
  </b-card>
</template>

<script>
import KeywordHighlight from 'vue-text-highlight';
import { messageOwnerTypes } from '../../../shared/enums/messageOwnerTypes';
import Vue from 'vue';

export default {
  components: {
    KeywordHighlight,
  },
  props: {
    result: { type: Object, default: () => {} },
    parent: { type: String, default: 'profile' },
    searchString: { type: String, default: '' },
    isChild: { default: false },
  },
  data: function () {
    return {
      Vue: Vue,
      messageOwnerTypes: messageOwnerTypes,
    };
  },
  methods: {
    navigateToResult() {
      this.$router.push({
        name: 'uniqueThread',
        params: { id: this.result.threadId },
        query: {
          fromSearch: true,
          folderId: this.result.folderId,
          mailBoxOwnerType:
            this.result.mailBoxOwner.mailBoxOwnerType == messageOwnerTypes.COMMON_INBOX
              ? this.result.mailBoxOwner.mailBoxOwnerType
              : null,
          mailOwnerId:
            this.result.mailBoxOwner.mailBoxOwnerType == messageOwnerTypes.COMMON_INBOX
              ? this.result.mailBoxOwner.id
              : null,
        },
      });
    },
  },
};
</script>
