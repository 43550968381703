<template>
  <div class="background">
    <div class="aula-onboarding">
      <b-container>
        <div class="header privacy">
          <div class="aula-logo">
            <b-btn variant="link" class="logo" @click="$router.push({ name: 'login' })">
              <i class="icon-Logo" aria-hidden="true"></i>
            </b-btn>
          </div>
        </div>
        <b-row class="onboarding privacy">
          <div cols="12" class="privacy-policy-container">
            <div v-if="dataPolicy.content && dataPolicy.content.html" v-html="dataPolicy.content.html" />
          </div>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import { types } from '../../store/types/types';
import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';

export default {
  props: {},
  data: function () {
    return {};
  },
  computed: {
    ...mapGetters({
      dataPolicy: types.GET_OPEN_DATA_POLICY,
    }),
  },
  methods: {
    ...mapActions({
      loadDataPolicy: types.LOAD_OPEN_DATA_POLICY,
    }),
  },
  mounted() {
    this.loadDataPolicy();
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/login/onboarding.scss';
.privacy-policy-container {
  padding: 50px;
  @include breakpoint-sm-down() {
    padding: 10px;
  }
}
.aula-onboarding /deep/ a {
  font-weight: bold;
  color: $color-picker-button-blue;
  &:hover {
    text-decoration: underline;
  }
}
</style>
