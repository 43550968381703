<template>
  <drawer :show="isShown" :focus-target="focusTarget" @change-show="changeDrawerShow">
    <template #drawer-title>
      <div class="presence-drawer-header">
        <Icon :name="iconClassEnum.EXPORT" class="presence-drawer-icon hide-tablet-down mr-3" aria-hidden="true" />
        <span>{{ drawerTitle }}</span>
      </div>
    </template>
    <template #header-right>
      <div class="d-flex">
        <AulaButton
          v-if="isReadonly && isEditable"
          variant="link"
          class="drawer-edit-button mt-0"
          :aria-label="'ARIA_LABEL_EDIT' | fromTextKey"
          @click="isReadonly = false"
        >
          <Icon :name="iconClassEnum.EDIT_PEN" class="icon" />
          <span v-if="!isMobile">{{ 'BUTTON_EDIT' | fromTextKey }}</span>
        </AulaButton>
      </div>
    </template>
    <template #drawer-content>
      <aula-spinner v-if="isLoadingVacation" />
      <div v-else-if="currentEvent" class="register-vacation-container">
        <h2>{{ 'PRESENCE_UPDATE_VACATION' | fromTextKey }}</h2>
        <b-row class="mt-3 mb-4">
          <b-col>
            {{ 'PRESENCE_VACATION_ANNOUNCEMENT_DESCRIPTION_1' | fromTextKey }}<br />
            {{ 'PRESENCE_VACATION_ANNOUNCEMENT_DESCRIPTION_2' | fromTextKey }}
          </b-col>
        </b-row>
        <div class="separating-line">
          <template v-if="isMobile">
            <b-row v-for="(child, index) in reportedChildren" :key="index" class="mobile mt-4 mb-2">
              <b-col cols="9">
                {{ child.name }}
                <template v-if="child.isMultipleInstitution"> ({{ child.institutionName }}) </template>
              </b-col>
              <b-col cols="3">
                <el-switch
                  v-model="child.isSelected"
                  class="mobile-switch"
                  :width="48"
                  :disabled="!isCreating"
                  @input="validateChildren"
                />
              </b-col>
            </b-row>
          </template>
          <template v-for="(child, index) in reportedChildren" v-else>
            <b-form-checkbox
              :key="index"
              v-model="child.isSelected"
              :value="true"
              :unchecked-value="false"
              :disabled="!isCreating"
              class="mr-28"
              @input="validateChildren"
            >
              {{ child.name }}
              <span v-if="child.isMultipleInstitution"> ({{ child.institutionName }}) </span>
            </b-form-checkbox>
          </template>
          <b-alert variant="danger" :show="isMissingChildren">
            {{ 'PRESENCE_ALERT_ABSENCE_CHOOSE_CHILD' | fromTextKey }}
          </b-alert>
        </div>
        <b-row>
          <b-col md="6" class="mt-2 mt-lg-0">
            <label>{{ 'PRESENCE_VACATION_FR0M_DATE' | fromTextKey }} <span class="mandatory">*</span></label>
            <br />
            <el-date-picker
              v-model="startDate"
              class="w-100"
              :picker-options="defaultDateOptions"
              :format="defaultDateFormat"
              :value-format="defaultDateValueFormat"
              :disabled="!isCreating && (isReadonly || !currentEvent.canEditStartDate)"
              placeholder="dd/mm/yyyy"
              type="date"
              :class="{
                'red-border': isMissingStartDate,
                elPickerActive: isStartDateClicked,
              }"
              @focus="
                datePickerModifier();
                isStartDateClicked = true;
              "
              @blur="isStartDateClicked = false"
              @input="validateDate"
            />
            <b-alert variant="danger" :show="isMissingStartDate">
              {{ 'PRESENCE_ALERT_START_DATE' | fromTextKey }}
            </b-alert>
          </b-col>
          <b-col md="6" class="mt-2 mt-lg-0">
            <label>{{ 'PRESENCE_VACATION_TO_DATE' | fromTextKey }} <span class="mandatory">*</span></label>
            <br />
            <el-date-picker
              v-model="endDate"
              class="w-100"
              :format="defaultDateFormat"
              :value-format="defaultDateValueFormat"
              :picker-options="endDateOptions"
              :disabled="isReadonly"
              placeholder="dd/mm/yyyy"
              type="date"
              :class="{
                'red-border': isMissingEndDate,
                elPickerActive: isEndDateClicked,
              }"
              @focus="
                datePickerModifier();
                isEndDateClicked = true;
              "
              @blur="isEndDateClicked = false"
              @input="validateDate"
            />
            <b-alert variant="danger" :show="isMissingEndDate">
              {{ 'PRESENCE_ALERT_END_DATE' | fromTextKey }}
            </b-alert>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12" class="mt-2 mt-lg-0">
            <label>{{ 'PRESENCE_VACATION_NOTE' | fromTextKey }}</label>
            <b-textarea
              v-model="comment"
              max-rows="1"
              rows="3"
              maxlength="250"
              class="w-100"
              :disabled="isReadonly"
              :placeholder="'PRESENCE_VACATION_NOTE_PLACEHOLDER' | fromTextKey"
              @change="hasDataChanged = true"
            />
          </b-col>
        </b-row>
        <aula-form-footer
          :is-loading="isSavingVacation"
          :show-delete="!isCreating"
          :show-delete-mobile="!isCreating"
          :show-delete-icon="!isCreating"
          :submit-text="isReadonly ? 'BUTTON_CLOSE' : 'BUTTON_SAVE'"
          :show-cancel="!isReadonly"
          @submitClicked="isReadonly ? handleDiscard() : onSaveClicked()"
          @deleteClicked="$refs.deletionWarningModal.show()"
          @cancelClicked="handleDiscard"
        />
      </div>
      <aula-modal
        ref="deletionWarningModal"
        ok-text="YES"
        :is-loading="isDeletingVacation"
        @cancelClicked="$refs.deletionWarningModal.hide()"
        @okClicked="deleteVacation"
      >
        {{ 'CALENDAR_DELETE_HOLIDAY_TEXT' | fromTextKey }}<br />
        {{ 'CALENDAR_DELETE_HOLIDAY_TEXT_2' | fromTextKey }}
      </aula-modal>
      <aula-modal
        ref="discardEditingModal"
        @cancelClicked="$refs.discardEditingModal.hide()"
        @okClicked="discardEditing()"
      >
        {{ 'CALENDAR_CANCEL_INFO' | fromTextKey }}<br />{{ 'CALENDAR_CANCEL_INFO_2' | fromTextKey }}
      </aula-modal>
    </template>
  </drawer>
</template>
<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { types } from '../../store/types/types';
import { presenceModules } from '../../../shared/enums/presenceModules';
import moment from 'moment-timezone';
import { eventTypeEnum } from '../../../shared/enums/eventTypeEnum';
import Icon from '../../../shared/components/Icon.vue';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import AulaButton from '../../../shared/components/AulaButton.vue';

export default {
  components: { AulaButton, Icon },
  data: function () {
    return {
      isShown: false,
      isSavingVacation: false,
      isLoadingVacation: false,
      isDeletingVacation: false,
      reportedChildren: [],
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      isMissingStartDate: false,
      isMissingEndDate: false,
      isMissingChildren: false,
      isStartDateClicked: false,
      isEndDateClicked: false,
      hasDataChanged: false,
      endDateOptions: null,
      isReadonly: false,
      isCreating: false,
      isEditable: false,
      focusTarget: null,
      comment: '',
    };
  },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
      children: types.GET_CHILDREN,
      defaultDateOptions: types.GET_DEFAULT_DATE_OPTIONS,
      defaultDateFormat: types.GET_DEFAULT_DATE_FORMAT,
      defaultDateValueFormat: types.GET_DEFAULT_DATE_VALUE_FORMAT,
      getPresenceActiveModules: types.GET_PRESENCE_ACTIVE_MODULES,
      currentEvent: types.GET_CURRENT_EVENT,
      hasCustodyOrExtendedAccess: types.GET_HAS_CUSTODY_OR_EXTENDED_ACCESS,
    }),
    drawerTitle() {
      let titleFromTextKey = '';
      if (this.isCreating) {
        titleFromTextKey = 'PRESENCE_REGISTER_VACATION';
      }
      if (this.isReadonly) {
        titleFromTextKey = 'PRESENCE_REPORT_VACATION_ANNOUNCEMENT';
      }
      if (!this.isReadonly && !this.isCreating) {
        titleFromTextKey = 'PRESENCE_UPDATE_VACATION';
      }
      return Vue.filter('fromTextKey')(titleFromTextKey);
    },
  },
  methods: {
    ...mapActions({
      addAbsence: types.ADD_ABSENCE,
      getEventById: types.GET_EVENT_BY_ID,
      updateSimpleEvent: types.UPDATE_SIMPLE_EVENT,
      deleteEvent: types.DELETE_EVENT,
    }),
    async show(vacation = null, isReadonly = false) {
      this.isShown = true;
      this.focusTarget = this.isMobile ? '.icon-Aula_arrow_new_item.aula-close-arrow' : '.icon-Aula_close.aula-close';
      this.isReadonly = isReadonly;
      this.isCreating = !isReadonly && !vacation;
      this.isEditable = vacation && vacation.isEditable;
      this.hasDataChanged = false;

      if (this.isCreating) {
        this.resetVacationForm();
      } else if (vacation) {
        await this.getVacationDetail(vacation.vacationId);
      }
      this.getReportedChildren();
    },
    hide() {
      this.isShown = false;
      this.$emit('onClosed');
    },
    changeDrawerShow(value) {
      if (!value) {
        this.handleDiscard();
      }
    },
    resetVacationForm() {
      this.isMissingStartDate = false;
      this.isMissingEndDate = false;
      this.isMissingChildren = false;
      this.startDate = moment().format('YYYY-MM-DD');
      this.endDate = moment().format('YYYY-MM-DD');
      this.comment = '';
    },
    discardEditing() {
      this.$refs.discardEditingModal.hide();
      this.hide();
    },
    handleDiscard() {
      if (this.hasDataChanged) {
        this.$refs.discardEditingModal.show();
      } else {
        this.hide();
      }
    },
    getVacationDetail(vacationId) {
      this.isLoadingVacation = true;
      return this.getEventById({ eventId: vacationId }).then(() => {
        this.startDate = moment(this.currentEvent.startDateTime).utc().format('YYYY-MM-DD');
        this.endDate = moment(this.currentEvent.endDateTime).utc().format('YYYY-MM-DD');
        this.comment = this.currentEvent.description ? this.currentEvent.description.html : '';
        this.isLoadingVacation = false;
      });
    },
    getReportedChildren() {
      let children = [];
      if (
        this.getPresenceActiveModules[presenceModules.VACATION] != null &&
        this.getPresenceActiveModules[presenceModules.VACATION].length > 0
      ) {
        children = this.children
          .filter(
            child =>
              this.getPresenceActiveModules[presenceModules.VACATION].includes(child.id) &&
              this.hasCustodyOrExtendedAccess(child.id)
          )
          .map(child => ({
            ...child,
            isSelected: this.isCreating || (this.currentEvent && child.id === this.currentEvent.creator.id),
          }));
      }
      this.reportedChildren = children;
    },
    validateDate() {
      this.hasDataChanged = true;
      if (this.startDate) {
        this.isMissingStartDate = false;
        if (moment(this.startDate).isAfter(moment(this.endDate))) {
          this.endDate = this.startDate;
          this.isMissingEndDate = false;
        }
      } else {
        this.isMissingStartDate = true;
      }
      if (this.endDate) {
        this.isMissingEndDate = false;
        if (moment(this.endDate).isBefore(moment(this.startDate))) {
          this.startDate = this.endDate;
          this.isMissingStartDate = false;
        }
      } else {
        this.isMissingEndDate = true;
      }

      const startDate = this.startDate;
      this.endDateOptions = {
        disabledDate(date) {
          return moment(date).isBefore(moment(startDate));
        },
        firstDayOfWeek: 1,
      };
    },
    validateChildren() {
      this.hasDataChanged = true;
      const selectedChildIds = this.reportedChildren.filter(child => child.isSelected).map(child => child.id);
      this.isMissingChildren = selectedChildIds.length === 0;
    },
    onSaveClicked() {
      const isValid = !this.isMissingChildren && !this.isMissingStartDate && !this.isMissingEndDate;

      if (this.isReadonly) {
        this.hide();
      } else if (isValid) {
        if (this.isCreating) {
          this.createVacation();
        } else {
          this.updateVacation();
        }
      }
    },
    createVacation() {
      this.isSavingVacation = true;
      const selectedChildIds = this.reportedChildren.filter(child => child.isSelected).map(child => child.id);
      if (selectedChildIds.length > 0) {
        const params = {
          childIds: selectedChildIds,
          intervals: [
            {
              startDate: this.startDate,
              endDate: this.endDate,
            },
          ],
          comment: this.comment,
        };
        this.addAbsence(params)
          .then(() => {
            this.hide();
          })
          .finally(() => {
            this.isSavingVacation = false;
          });
      }
    },
    updateVacation() {
      this.isSavingVacation = true;
      const params = {
        eventId: this.currentEvent.id,
        startDateTime: this.startDate,
        endDateTime: this.endDate,
        type: eventTypeEnum.PRESENCE_HOLIDAY,
        description: this.comment,
        title: this.currentEvent.title,
        allDay: true,
        private: false,
        responseRequired: this.currentEvent.responseRequired,
        addedToInstitutionCalendar: this.currentEvent.addedToInstitutionCalendar,
        hideInOwnCalendar: this.currentEvent.hideInOwnCalendar,
        isEditEvent: true,
      };
      this.updateSimpleEvent(params)
        .then(() => {
          this.hide();
        })
        .finally(() => {
          this.isSavingVacation = false;
        });
    },
    deleteVacation() {
      this.isDeletingVacation = true;
      const params = {
        eventForm: this.currentEvent,
        type: eventTypeEnum.PRESENCE_HOLIDAY,
      };
      this.deleteEvent(params)
        .then(() => {
          this.$refs.deletionWarningModal.hide();
          this.hide();
        })
        .finally(() => {
          this.isDeletingVacation = false;
        });
    },
  },
  mounted() {
    this.endDateOptions = this.defaultDateOptions;
  },
};
</script>
<style lang="scss" scoped>
@import '../../../shared/assets/scss/core/variables';
@import '../../../shared/assets/scss/core/breakpoints';
@import '../../../shared/assets/scss/components/presence/_presence.scss';
</style>
