import { messageFiltersEnum } from './messageFiltersEnum';

export const messageFilterTypes = [
  {
    value: messageFiltersEnum.FILTER_ALL,
    textKey: 'MESSAGE_TOOLBAR_FILTER_ALL',
  },
  {
    value: messageFiltersEnum.FILTER_UNREAD,
    textKey: 'MESSAGE_TOOLBAR_FILTER_UNREAD',
  },
  {
    value: messageFiltersEnum.FILTER_MARKED,
    textKey: 'MESSAGE_TOOLBAR_FILTER_MARKED',
  },
];
