<template>
  <div class="general-opening-hour-container">
    <div v-if="isMobile" class="mobile-box-title">
      {{ 'PRESENCE_GENERAL_OPENING_HOURS' | fromTextKey }}
    </div>
    <h2 v-else>{{ 'PRESENCE_GENERAL_OPENING_HOURS' | fromTextKey }}</h2>
    <template v-for="(subList, key) in splitGeneralOpeningHourData">
      <div
        :key="'table-' + key"
        :class="{
          'for-one-institution': subList.length === 1 && generalOpeningHoursForGuardian.length !== 1,
        }"
        class="table"
      >
        <div class="table-row header">
          <div class="table-cell weekday-header">
            {{ 'PRESENCE_WEEKDAY_LABEL' | fromTextKey }}
          </div>
          <template v-if="generalOpeningHoursForGuardian.length === 1">
            <div class="table-cell institution-header">
              {{ 'PRESENCE_OPENING_HOURS' | fromTextKey }}
            </div>
          </template>
          <template v-else>
            <div
              v-for="institutionOpeningHourData in subList"
              :key="institutionOpeningHourData.institutionCode"
              class="table-cell institution-header"
            >
              {{ getInstitutionName(institutionOpeningHourData.institutionCode) }}
            </div>
          </template>
        </div>
        <div v-for="index in 5" :key="index" class="table-row body">
          <div class="table-cell weekday-cell">
            {{ index | isoDateNumberToName }}
          </div>
          <div
            v-for="institutionOpeningHourData in subList"
            :key="'cell-' + institutionOpeningHourData.institutionCode"
            class="table-cell"
          >
            <template
              v-if="
                institutionOpeningHourData.openingHours.length > 0 &&
                institutionOpeningHourData.openingHours[index - 1].openTime !== ''
              "
            >
              {{ institutionOpeningHourData.openingHours[index - 1].openTime }}
              -
              {{ institutionOpeningHourData.openingHours[index - 1].closeTime }}
            </template>
            <template v-else>
              {{ 'PRESENCE_OPENING_HOUR_NOT_GIVEN' | fromTextKey }}
            </template>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { types } from '../../store/types/types';
import dataFormat from '../../../shared/mixins/dataFormatMixin';

export default {
  mixins: [dataFormat],
  props: {
    selectedInstitutions: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
      generalOpeningHoursForGuardian: types.GET_GENERAL_OPENING_HOURS_FOR_GUARDIAN,
    }),
    splitGeneralOpeningHourData() {
      const chunk = this.isMobile ? 1 : 2;
      return this.splitDataToChunks(this.generalOpeningHoursForGuardian, chunk);
    },
  },
  methods: {
    getInstitutionName(institutionCode) {
      const inst = this.selectedInstitutions.find(institution => institution.institutionCode === institutionCode);
      if (inst) {
        return inst.name;
      }
      return '';
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../../shared/assets/scss/core/variables';
@import '../../../shared/assets/scss/core/breakpoints';
@import '../../../shared/assets/scss/components/presence/presence';
</style>
