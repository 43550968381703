<template>
  <div class="background">
    <div class="aula-onboarding">
      <b-container>
        <b-row class="header stepupfailure">
          <b-col cols="12" class="aula-logo">
            <div class="logo">
              <i class="icon-Logo" aria-hidden="true" />
            </div>
          </b-col>
        </b-row>
        <b-row class="onboarding stepupfailure">
          <b-col cols="12" class="p-0 onboarding-wrapper">
            <div class="onboard-content">
              <h1>{{ stepUpFailureTitle }}</h1>
              <h5>{{ stepUpFailureText }}</h5>
              <b-btn variant="link" @click="navigateToAula()">
                {{ stepUpFailureLink }}
              </b-btn>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  data() {
    return {
      stepUpFailureTitle: null,
      stepUpFailureText: null,
      stepUpFailureLink: null,
    };
  },
  mounted() {
    const type = this.$route.query.type;
    switch (type) {
      case 'idpassurancelevelerror':
        document.title = Vue.filter('fromTextKey')('IDP_ASSURANCE_LEVEL_ERROR_PAGE_TITLE');
        this.stepUpFailureTitle = Vue.filter('fromTextKey')('IDP_ASSURANCE_LEVEL_ERROR_TITLE');
        this.stepUpFailureText = Vue.filter('fromTextKey')('IDP_ASSURANCE_LEVEL_ERROR_TEXT');
        this.stepUpFailureLink = Vue.filter('fromTextKey')('ARIA_LABEL_BACK');
        break;
      case 'idpconfigurationerror':
        document.title = Vue.filter('fromTextKey')('IDP_CONFIGURATION_ERROR_PAGE_TITLE');
        this.stepUpFailureTitle = Vue.filter('fromTextKey')('IDP_CONFIGURATION_ERROR_TITLE');
        this.stepUpFailureText = Vue.filter('fromTextKey')('IDP_CONFIGURATION_ERROR_TEXT');
        this.stepUpFailureLink = Vue.filter('fromTextKey')('ARIA_LABEL_BACK');
        break;
      default:
        document.title = Vue.filter('fromTextKey')('IDP_CONFIGURATION_ERROR_PAGE_TITLE');
        this.stepUpFailureTitle = Vue.filter('fromTextKey')('IDP_CONFIGURATION_ERROR_TITLE');
        this.stepUpFailureText = Vue.filter('fromTextKey')('IDP_CONFIGURATION_ERROR_TEXT');
        this.stepUpFailureLink = Vue.filter('fromTextKey')('ARIA_LABEL_BACK');
        break;
    }
  },
  methods: {
    navigateToAula() {
      window.location.href = localStorage.getItem('redirectedurlbeforestepup');
      setTimeout(() => {
        window.location.reload();
      }, 500);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../assets/scss/core/variables.scss';
@import '../../assets/scss/core/breakpoints.scss';
@import '../../assets/scss/components/login/onboarding.scss';
</style>
