<template>
  <article class="recipient-added container">
    <b-row>
      <b-col cols="8" offset="2">
        <div class="system-message">
          <i class="icon-Aula_user-add system-message-icon" />
          <div class="system-message-text text-wrap">
            {{ firstRecipient }}
            <span v-if="numberOfOtherAddedRecipients > 0">
              {{ 'MESSAGE_USER_SEPARATOR_TEXT' | fromTextKey }}
            </span>
            <MessageRecipientPopover
              v-if="numberOfOtherAddedRecipients > 0"
              :title="$options.filters.fromTextKey('MESSAGE_OTHER_RECIPIENTS_ADDED_TITLE')"
              :recipients="recipientList"
              :recipients-count="numberOfOtherAddedRecipients"
              class="other-recipients"
            />
            <span v-if="senderIsSelf"> {{ 'MESSAGE_NEW_RECIPIENT_ADDED_BY_YOU' | fromTextKey }}. </span>
            <span v-else>
              {{ 'MESSAGE_NEW_RECIPIENT_ADDED' | fromTextKey }}
              {{ message.inviterName }}.
            </span>
            <post-time :time="message.sendDateTime" :is-capitalized="true" variation="long" />
          </div>
        </div>
      </b-col>
    </b-row>
  </article>
</template>

<script>
import { types } from '../../store/types/types';
import { mapGetters } from 'vuex';
import button from '../../../shared/directives/button';
import MessageRecipientPopover from './MessageRecipientPopover';

export default {
  components: { MessageRecipientPopover },
  directives: {
    button,
  },
  props: {
    message: Object,
    shownSubscription: Object,
  },
  computed: {
    ...mapGetters({
      profile: types.GET_CURRENT_PROFILE,
      isMobile: types.GET_IS_MOBILE,
      profilesByLogin: types.GET_PROFILES_BY_LOGIN,
    }),
    senderIsSelf() {
      if (this.shownSubscription == null) {
        return false;
      }
      const inviterNameWithoutMetadata = this.message.inviterName.split(' (')[0];
      return inviterNameWithoutMetadata === this.profile.fullName;
    },
    recipientList() {
      if (!this.message.newRecipients) {
        return [];
      }
      return this.message.newRecipients;
    },
    messageProfile() {
      const currentProfile = this.profilesByLogin.find(profile => profile.profileId === this.profile.profileId);
      return currentProfile.institutionProfiles.find(
        profile => profile.institutionCode === this.shownSubscription.institutionCode
      );
    },
    firstRecipient() {
      const isCurrentUserAdded = this.recipientList[0].mailBoxOwner.id === this.messageProfile.id;
      return isCurrentUserAdded
        ? this.$options.filters.fromTextKey('MESSAGE_USER_SELF')
        : this.$options.filters.displayProfileNameWithMetadata(this.recipientList[0]);
    },
    otherRecipients() {
      return this.recipientList.slice(1);
    },
    numberOfOtherAddedRecipients() {
      return this.otherRecipients.length || 0;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.text-container {
  width: calc(100% - 45px);
  /deep/ .user {
    position: absolute;
    right: 0px;
    top: 22px;
    .shortname {
      margin-top: 8px;
    }
  }
}

.text-container-self {
  /deep/ .user {
    position: absolute;
    left: 0px;
    top: 22px;
    .shortname {
      margin-top: 8px;
    }
  }
}
</style>
