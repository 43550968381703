<template>
  <div class="contact" :class="{ 'list-view': !isMobile }">
    <div
      class="main-contact"
      :class="{
        full:
          filterBy == contactsProfileTypeFilters.EMPLOYEE || contact.relations == null || contact.relations.length == 0,
      }"
    >
      <div class="contact-profile">
        <b-form-checkbox v-if="showCheckList" v-model="checkUser" />
        <div class="contact-img">
          <ContactAvatar :profile="contact" class="contact-avatar" />
        </div>
        <div class="contact-info">
          <div
            class="contact-name"
            :class="{ mobile: isMobile && expandContactId == contact.id }"
            @click="expandContact"
          >
            {{ contact | displayProfileNameWithMetadata }}
          </div>
          <transition name="slide-mobile">
            <div v-if="!isMobile || (isMobile && expandContactId == contact.id)" class="contact-data">
              <template v-if="contact.currentUserCanViewContactInformation">
                <template v-if="contact.userHasGivenConsentToShowContactInformation">
                  <div v-if="contact.birthday != null" class="contact-text">
                    {{ 'CONTACT_BIRTHDAY' | fromTextKey }} {{ getBirthday() }}
                  </div>
                  <div v-if="contact.address != null && contact.role != portalRoles.CHILD" class="contact-text">
                    {{ contact.address | generateAdress }}
                  </div>
                  <div v-if="contact.homePhoneNumber" class="contact-item">
                    <div v-if="isMobile" class="contact-info-icon phone">
                      <i class="icon icon-Aula_phone" />
                    </div>
                    <span class="meta-name">{{ 'PROFILE_HOME_PHONE_LABEL' | fromTextKey }}: </span>
                    <span class="text">{{ contact.homePhoneNumber }}</span>
                  </div>
                  <div v-if="contact.mobilePhoneNumber" class="contact-item">
                    <div v-if="isMobile" class="contact-info-icon phone">
                      <i class="icon icon-Aula_phone" />
                    </div>
                    <span class="meta-name">{{ 'PROFILE_MOBILE_PHONE_LABEL' | fromTextKey }}: </span>
                    <span class="text">{{ contact.mobilePhoneNumber }}</span>
                  </div>
                  <div v-if="contact.workPhoneNumber" class="contact-item">
                    <div v-if="isMobile" class="contact-info-icon phone">
                      <i class="icon icon-Aula_phone" />
                    </div>
                    <span class="meta-name">{{ 'PROFILE_WORK_PHONE_LABEL' | fromTextKey }}: </span>
                    <span class="text">{{ contact.workPhoneNumber }}</span>
                  </div>
                  <div v-if="contact.email" class="contact-item">
                    <div v-if="isMobile" class="contact-info-icon email">
                      <i class="icon icon-Aula_email" />
                    </div>
                    <span class="meta-name">{{ 'PROFILE_EMAIL_LABEL' | fromTextKey }}: </span>
                    <span class="text">{{ contact.email }}</span>
                  </div>
                </template>
                <template v-else>
                  {{ getGenitiveName(contact.firstName) }}
                  {{ 'CONTACT_DOES_NOT_APPEAR_IN_AULA' | fromTextKey }}
                </template>
              </template>
              <template v-else>
                {{ getNotAllowToSeeContactMessage(contact.firstName) }}
              </template>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <transition name="slide-mobile">
      <b-row
        v-if="
          filterBy != 'employee' &&
          ((!isMobile && contact.relations != null && contact.relations.length > 0) ||
            (isMobile && expandContactId == contact.id))
        "
        class="relations"
      >
        <b-col
          v-for="(relation, index) in contact.relations"
          :key="index"
          cols="12"
          sm="6"
          class="relation"
          :class="{ first: index % 2 == 0 }"
        >
          <div class="mb-1">
            <template v-if="filterBy == contactsProfileTypeFilters.GUARDIAN">
              {{ 'CHILD' | fromTextKey }}
            </template>
            <template v-else>
              {{ relation.relation }}
            </template>
          </div>
          <div class="contact-profile">
            <div v-if="isMobile" class="contact-img">
              <ContactAvatar :profile="relation" />
            </div>
            <div class="contact-info">
              <b-row>
                <b-col
                  cols="12"
                  :xl="relation.userHasGivenConsentToShowContactInformation ? '5' : '12'"
                  class="profile-info"
                >
                  <div v-if="isMobile" class="contact-name">
                    {{ relation | displayProfileNameWithMetadata }}
                  </div>
                  <div class="contact-text">
                    <span v-if="!isMobile">{{ relation | displayProfileNameWithMetadata }}<br /></span>
                    <template v-if="relation.userHasGivenConsentToShowContactInformation">
                      <span v-if="relation.address != null && relation.role != portalRoles.CHILD">{{
                        relation.address | generateAdress
                      }}</span>
                    </template>
                    <template v-else>
                      <i
                        >{{ getGenitiveName(relation.firstName) }}
                        {{ 'CONTACT_DOES_NOT_APPEAR_IN_AULA' | fromTextKey }}</i
                      >
                    </template>
                    <span v-if="relation.hasCustody != null">
                      <br />{{ 'HAS_CUSTODY' | fromTextKey }}:
                      {{ relation.hasCustody ? 'YES' : 'NO' | fromTextKey }}
                    </span>
                  </div>
                </b-col>
                <b-col
                  v-if="relation.userHasGivenConsentToShowContactInformation"
                  cols="12"
                  xl="7"
                  class="contact-data"
                >
                  <div
                    v-if="relation.homePhoneNumber && relation.contactType !== contactTypeTypes.GROUP_HOME"
                    class="contact-item"
                  >
                    <div v-if="isMobile" class="contact-info-icon phone">
                      <i class="icon icon-Aula_phone" />
                    </div>
                    <span class="meta-name">{{ 'PROFILE_HOME_PHONE_LABEL' | fromTextKey }}: </span>
                    <span class="text">{{ relation.homePhoneNumber }}</span>
                  </div>
                  <div v-if="relation.mobilePhoneNumber" class="contact-item">
                    <div v-if="isMobile" class="contact-info-icon phone">
                      <i class="icon icon-Aula_phone" />
                    </div>
                    <span v-if="relation.contactType === contactTypeTypes.GROUP_HOME" class="meta-name"
                      >{{ 'GROUP_HOME_PHONE_PRIMARY_LABEL' | fromTextKey }}:
                    </span>
                    <span v-else class="meta-name">{{ 'PROFILE_MOBILE_PHONE_LABEL' | fromTextKey }}: </span>
                    <span class="text">{{ relation.mobilePhoneNumber }}</span>
                  </div>
                  <div v-if="relation.workPhoneNumber" class="contact-item">
                    <div v-if="isMobile" class="contact-info-icon phone">
                      <i class="icon icon-Aula_phone" />
                    </div>
                    <span v-if="relation.contactType === contactTypeTypes.GROUP_HOME" class="meta-name"
                      >{{ 'GROUP_HOME_PHONE_SECONDARY_LABEL' | fromTextKey }}:
                    </span>
                    <span v-else class="meta-name">{{ 'PROFILE_WORK_PHONE_LABEL' | fromTextKey }}: </span>
                    <span class="text">{{ relation.workPhoneNumber }}</span>
                  </div>
                  <div v-if="relation.email" class="contact-item">
                    <div v-if="isMobile" class="contact-info-icon email">
                      <i class="icon icon-Aula_email" />
                    </div>
                    <span class="meta-name">{{ 'PROFILE_EMAIL_LABEL' | fromTextKey }}: </span>
                    <span class="text">{{ relation.email }}</span>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { types } from '../../store/types/types';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { contactsProfileTypeFilters } from '../../../shared/enums/contactsProfileTypeFilters';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import { contactTypeTypes } from '../../../shared/enums/contactTypeTypes';
import ContactAvatar from './ContactAvatar.vue';

export default {
  components: { ContactAvatar },
  props: {
    contact: { type: Object },
    expandContactId: { type: Number, default: null },
    showCheckList: { type: Boolean, default: false },
    filterBy: { type: String, default: 'child' },
  },
  data: function () {
    return {
      portalRoles: portalRoles,
      contactsProfileTypeFilters: contactsProfileTypeFilters,
      parent: parentTypes.CONTACT,
      checkUser: null,
      contactTypeTypes,
    };
  },
  computed: {
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
      institutions: types.GET_INSTITUTIONS,
    }),
    contactName() {
      if (this.contact.firstName != null && this.contact.firstName.trim().length > 0) {
        return this.contact.firstName + ' ' + this.contact.lastName;
      } else {
        return this.contact.fullName;
      }
    },
  },
  methods: {
    getGenitiveName(firstName) {
      if (firstName != null && firstName.length > 1) {
        return firstName.substr(-1).toLowerCase() == 's' ? firstName : firstName + 's';
      }
      return '';
    },
    getNotAllowToSeeContactMessage(firstName) {
      return `${this.getGenitiveName(firstName)} ${this.$options.filters.fromTextKey(
        'CONTACT_DOES_NOT_APPEAR_IN_AULA'
      )}`;
    },
    getBirthday() {
      if (this.contact.birthday != null) {
        return this.$options.filters.shortDate(
          this.contact.birthday,
          this.contact.role == this.portalRoles.EMPLOYEE ? false : true
        );
      }
      return this.$options.filters.fromTextKey('UNKNOW');
    },
    expandContact() {
      this.$emit('toggleContact', this.contact.id);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/contacts/_contacts.scss';

.contact-avatar {
  --width: 30px;
  --height: 30px;
  --font-size: 10px;
}
</style>
