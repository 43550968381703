<template>
  <button class="btn btn-link sort-container" type="button" @click="onSort">
    <slot />
    <i :class="orderClasses" />
  </button>
</template>
<script lang="js">
import {orderDirections} from "../enums/orderDirections";

export default {
  name: 'Sorting',
  props: {
    direction: {
      required: false,
      type: String,
      default: () => null,
      validator(direction) {
        if (direction == null) {
          return true;
        }
        return [orderDirections.ASCENDING, orderDirections.DESCENDING].includes(direction);
      }
    }
  },
  computed: {
    orderClasses() {
      const classes = ['sort-icon'];
      if (this.direction === orderDirections.ASCENDING) {
        classes.push('icon-Aula_down-arrow-2', 'ascending');
      } else if (this.direction === orderDirections.DESCENDING) {
        classes.push('icon-Aula_up-arrow-2', 'descending');
      } else {
        classes.push('icon-Aula_up-down-arrow-2 asc-desc');
      }
      return classes;
    }
  },
  methods: {
    onSort() {
      this.$emit('sort');
    }
  }
}
</script>
<style lang="scss" scoped>
.sort-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  text-transform: inherit;
}

.visibility-hidden {
  visibility: hidden;
}

.sort-icon {
  margin-left: 5px;
  margin-right: 5px;
}

.asc-desc {
  opacity: 0.5;
}

@media print {
  .sort-icon {
    display: none;
  }
}
</style>
