<template>
  <div class="styleguide-icons-container">
    <h2>Icons</h2>

    <div class="row">
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Bell_2" />Bell_2</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Bell" />Bell</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_arrow_new_item" />icon-Aula_arrow_new_item</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_check" />icon-Aula_check</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_home" />icon-Aula_home</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_phone" />icon-Aula_phone</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_mute" />icon-Aula_mute</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_star_outline" />icon-Aula_star_outline</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_star_solid" />icon-Aula_star_solid</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_user" />icon-Aula_user</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_user_admin" />icon-Aula_suser_admin</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_group" />icon-Aula_group</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_folder" />icon-Aula_folder</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_plus" />icon-Aula_plus</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_attach_doc" />icon-Aula_attach_doc</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_bin" />icon-Aula_bin</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_redirect" />icon-Aula_redirect</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_settings" />icon-Aula_settings</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_star" />icon-Aula_star</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_back-arrow" />icon-Aula_back-arrow</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_forward-arrow" />icon-Aula_forward_arrow</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_up-arrow" />icon-Aula_up_arrow</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_down-arrow" />icon-Aula_down_arrow</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_back-triangle" />icon-Aula_back-triangle</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_forward-triangle" />icon-Aula_forward-triangle</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_calendar" />icon-Aula_calendar</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_check-box" />icon-Aula_check-box</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_check-circle" />icon-Aula_check-circle</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_clock" />icon-Aula_clock</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_elipses" />icon-Aula_elipses</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_export" />icon-Aula_export</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_hamburger" />icon-Aula_hamburger</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_link" />icon-Aula_link</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_note" />icon-Aula_note</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_paper" />icon-Aula_paper</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_picture" />icon-Aula_picture</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_profile" />icon-Aula_profile</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_search" />icon-Aula_search</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_write" />icon-Aula_write</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_black_circle" />icon-Aula_black_circle</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Aula_exclamation_sign" />icon-Aula_exclamation_sign</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-Logo" />icon-Aula_logo</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-file-picture" />icon-file-picture</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-file-music" />icon-file-music</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-file-play" />icon-file-play</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-file-video" />icon-file-video</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-file-video" />icon-file-video</div>
      <div class="col-xl-3 col-md-4 col-sm-6"><i class="icon-file-video" />icon-file-video</div>
    </div>

    <pre class="prettyprint"><code>&lt;i class="icon-Aula_folder"&gt;&lt;/i&gt;</code></pre>
  </div>
</template>

<script>
import { types } from '../../store/types/types';
import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';
export default {
  data: function () {
    return {
      title: 'Beskeder',
    };
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.styleguide-icons-container {
  .row > * {
    text-align: center;
    padding-bottom: 30px;
  }
  [class^='icon-'] {
    font-size: 40px;
    color: $color-primary-dark;
    display: block;
    .theme-employee & {
      color: $color-primary-light-employee;
    }
    .theme-child & {
      color: $color-primary-light-child;
    }
    .theme-admin & {
      color: $color-primary-light-admin;
    }
    .theme-guardian & {
      color: $color-primary-light-guardian;
    }
  }
}
</style>
