<template>
  <div>
    <div class="contacts-container">
      <div class="letters-filter">
        <AulaButton
          v-for="(letter, i) in letters"
          :key="i"
          variant="link"
          class="letter"
          :class="{ active: currentLetter === letter }"
          @click="scrollToLetter(letter, i)"
        >
          {{ letter }}
        </AulaButton>
      </div>
      <div class="contacts-body">
        <b-row id="contactList">
          <div class="contact-header-mobile">
            <h1>{{ 'CONTACTS_TITLE' | fromTextKey }}</h1>
          </div>
          <div class="contacts-group-filter mb-5">
            <ContactsFilterDropdown
              :value="groupId"
              class="group-filter"
              :aria-label="'ARIA_LABEL_CHOOSE_GROUP_TO_VIEW_CONTACTS' | fromTextKey"
              filterable
              :remote="canViewAllContactInformation"
              :remote-method="onLoadGroups"
              :clearable="canViewAllContactInformation"
              :groups="contactGroups"
              :options="groups"
              @clear="onClearSearchGroupQuery"
              @change="onChangeGroup"
            >
              <template #option="option">
                <aula-option :label="option.name" :value="option.id" />
              </template>
              <ContactsFragmentFilteringGroupWarning v-if="groupSearch != null && showTooManyGroupsWarning" />
            </ContactsFilterDropdown>
            <b-dropdown ref="sorter" class="dropdown-select filter-sorter ml-1 mt-1">
              <template slot="button-content">
                {{ 'CONTACT_FILTER_AND_SORT' | fromTextKey }}
                <i class="icon icon-Aula_down-arrow" aria-hidden="true" />
              </template>
              <template v-for="(menu, i) in profileTypes">
                <b-dropdown-item
                  :key="i"
                  :active="menu.filter === profileType"
                  @click="onChangeProfileType(menu.filter)"
                >
                  {{ menu.name | fromTextKey }}
                </b-dropdown-item>
              </template>
              <b-dropdown-divider />
              <b-dropdown-item
                :class="sortBy === contactSortingTypes.NAME ? orderDirection : ''"
                @click="onSortBy(contactSortingTypes.NAME)"
              >
                {{ 'SORT_BY_ALPHABET' | fromTextKey }}
              </b-dropdown-item>
              <b-dropdown-item
                v-if="childFilter.includes(profileType)"
                :class="sortBy === contactSortingTypes.BIRTHDAY ? orderDirection : ''"
                @click="onSortBy(contactSortingTypes.BIRTHDAY)"
              >
                {{ 'SORT_BY_BIRTHDAY' | fromTextKey }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <aula-spinner v-if="loading" />
          <ContactsFragmentNoResult v-else-if="contacts.length === 0" />
          <template v-else>
            <b-col cols="12">
              <profile-contact
                v-for="(contact, index) in contacts"
                :id="contact.letter"
                :key="index"
                :filter-by="profileType"
                :expand-contact-id="expandedContactId"
                :contact="contact"
                @toggleContact="expandContact"
              />
            </b-col>
          </template>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script lang="js">
import ContactsFilterDropdown from "./ContactsFragmentFilterDropdown";
import AulaButton from "../../components/AulaButton";
import {providerKeyTypes} from "../../enums/providerKeyTypes";
import {profileUtil} from "../../utils/profileUtil";
import {contactsProfileTypeFilters} from "../../enums/contactsProfileTypeFilters";
import {contactSortingTypes} from "../../enums/contactSortingTypes";
import ContactsFragmentNoResult from "./ContactsFragmentNoResult";
import ProfileContact from "../../../src/components/contacts/ProfileContact.vue";
import ContactsFragmentFilteringGroupWarning from "./ContactsFragmentFilteringGroupWarning";

export default {
  name: 'ContactsFragmentMobile',
  components: {
    ContactsFragmentFilteringGroupWarning,
    ContactsFragmentNoResult, AulaButton, ContactsFilterDropdown, ProfileContact},
  inject: {
    getCanViewAllContactInformation: providerKeyTypes.canViewAllContactInformation,
    loadGroups: providerKeyTypes.onLoadGroups,
    getGroups: providerKeyTypes.groups,
    getContactGroups: providerKeyTypes.contactGroups,
    setContactGroupSearchQuery: providerKeyTypes.setContactGroupSearchQuery,
    getGroupId: providerKeyTypes.groupId,
    setGroupId: providerKeyTypes.setGroupId,
    getGroupSearch: providerKeyTypes.groupSearch,
    getProfileTypes: providerKeyTypes.profileTypes,
    setProfileType: providerKeyTypes.setProfileType,
    getProfileType: providerKeyTypes.profileType,
    getSortBy: providerKeyTypes.sortBy,
    getOrderDirection: providerKeyTypes.orderDirection,
    changeSortBy: providerKeyTypes.onSortBy,
    getLoading: providerKeyTypes.loading,
    getContacts: providerKeyTypes.contacts,
  },
  data() {
    return {
      currentLetter: 'A',
      letters: [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
        'Æ',
        'Ø',
        'Å',
      ],
      childFilter: profileUtil.getChildProfileTypes(),
      contactsProfileTypeFilters,
      contactSortingTypes,
      expandedContactId: null,
    }
  },
  computed: {
    contacts() {
      return this.getContacts();
    },
    groups() {
      return this.getGroups();
    },
    contactGroups() {
      return this.getContactGroups();
    },
    groupId() {
      return this.getGroupId();
    },
    canViewAllContactInformation() {
      return this.getCanViewAllContactInformation();
    },
    groupSearch() {
      return this.getGroupSearch();
    },
    profileType() {
      return this.getProfileType();
    },
    profileTypes() {
      return this.getProfileTypes();
    },
    sortBy() {
      return this.getSortBy();
    },
    orderDirection() {
      return this.getOrderDirection();
    },
    loading() {
      return this.getLoading();
    },
  },
  methods: {
    expandContact(id) {
      if (this.expandedContactId === id) {
        this.expandedContactId = null;
      } else {
        this.expandedContactId = id;
      }
    },
    onClearSearchGroupQuery() {
      this.setContactGroupSearchQuery(null);
    },
    onChangeGroup(groupId) {
      this.setGroupId(groupId);
    },
    onLoadGroups(query) {
      return this.loadGroups(query);
    },
    scrollToLetter(letter, letterIndex) {
      const nearestElement = this.findNearestLetterElement(letterIndex);
      this.currentLetter = letter;

      if (nearestElement != null) {
        nearestElement.scrollIntoView(true);
      }
    },
    onSortBy(sortBy) {
      this.changeSortBy(sortBy);
    },
    onChangeProfileType(profileType) {
      this.setProfileType(profileType);
    },
    findNearestLetterElement(letterIndex) {
      const visitedIndices = new Set();
      const searchArray = [letterIndex];
      while (searchArray.length > 0) {
        const searchLetterIndex = searchArray.shift();
        const letter = this.letters[searchLetterIndex];
        const letterElement = this.$el.querySelector(`#${letter}`);
        if (letterElement == null) {
          const stepUpLetterIndex = searchLetterIndex - 1;
          const stepDownLetterIndex = stepDownLetterIndex + 1;
          visitedIndices.add(searchLetterIndex);

          if (stepUpLetterIndex > -1 && visitedIndices.has(stepUpLetterIndex) === false) {
            searchArray.push(stepUpLetterIndex);
          }

          if (stepDownLetterIndex < this.letters.length && visitedIndices.has(stepDownLetterIndex) === false) {
            searchArray.push(stepDownLetterIndex);
          }
        } else {
          return letterElement;
        }
      }

      return null;
    },
  }
}
</script>
