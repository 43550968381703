<template>
  <div class="invalid-link">
    <i aria-hidden="true" class="icon-Aula_close icon" />
    <p class="text">
      {{ 'AULA_THIS_LINK_IS_INVALID' | fromTextKey }}
    </p>
  </div>
</template>
<script lang="js">
export default {
  name: 'UnsubscribeFragmentInvalidLink'
}
</script>
<style lang="scss" scoped>
.invalid-link {
  text-align: center;
}

.icon {
  font-size: var(--icon-font-size);
  margin-bottom: 15px;
  display: inline-block;
}

.text {
  margin: 0;
}
</style>
