<template>
  <AulaButtons class="thread-actions-standard">
    <AulaButton
      class="thread-action"
      :title="$t('messages.delete')"
      variant="link"
      tabindex="-1"
      @click.stop="emitDelete"
    >
      <Icon :name="iconClassEnum.BIN" />
    </AulaButton>
    <AulaButton
      variant="link"
      :title="read ? $t('messages.unread') : $t('messages.read')"
      class="thread-action draft-hidden"
      tabindex="-1"
      @click.stop="emitToggleReadStatus"
    >
      <Icon v-if="read" :name="iconClassEnum.ENVELOPE_CLOSED" />
      <Icon v-else :name="iconClassEnum.ENVELOPE_OPEN" />
    </AulaButton>
    <AulaButton
      variant="link"
      :title="$t('messages.move')"
      class="thread-action draft-hidden"
      tabindex="-1"
      @click.stop="emitMoveToFolder"
    >
      <Icon :name="iconClassEnum.FOLDER" />
    </AulaButton>
    <AulaButton
      variant="link"
      :title="$t('messages.mark_with_star')"
      class="thread-action draft-hidden"
      tabindex="-1"
      @click.stop="emitToggleImportantStatus"
    >
      <Icon v-if="marked" class="visible" :name="iconClassEnum.STAR_SOLID" />
      <Icon v-else :name="iconClassEnum.STAR_OUTLINE" />
    </AulaButton>
    <AulaButton
      v-if="showMute"
      variant="link"
      :title="muted ? $t('messages.un_mute') : $t('messages.mute')"
      :disabled="disabledMute"
      class="thread-action draft-hidden"
      tabindex="-1"
      @click.stop="emitToggleMuteStatus"
    >
      <Icon v-if="muted" class="visible" :name="iconClassEnum.BELL_2" />
      <Icon v-else :name="iconClassEnum.BELL" />
    </AulaButton>
  </AulaButtons>
</template>
<script>
import AulaButtons from '../../components/AulaButtons';
import AulaButton from '../../components/AulaButton';
import Icon from '../../components/Icon.vue';
import { iconClassEnum } from '../../enums/iconClassEnum';
export default {
  components: { Icon, AulaButton, AulaButtons },
  props: {
    marked: { type: Boolean, default: false },
    read: { type: Boolean, default: false },
    muted: { type: Boolean, default: false },
    disabledMute: { type: Boolean, default: false },
    showMute: { type: Boolean, default: true },
  },
  emits: ['toggleMuteStatus', 'toggleImportant', 'toggleReadStatus', 'moveToFolder', 'delete'],
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
  },
  methods: {
    emitToggleMuteStatus() {
      this.$emit('toggleMuteStatus');
    },
    emitToggleImportantStatus() {
      this.$emit('toggleImportant');
    },
    emitToggleReadStatus() {
      this.$emit('toggleReadStatus');
    },
    emitMoveToFolder() {
      this.$emit('moveToFolder');
    },
    emitDelete() {
      this.$emit('delete');
    },
  },
};
</script>
<style scoped lang="scss">
.thread-actions-standard {
  gap: 2px;
}
.draft {
  .draft-hidden {
    visibility: hidden;
  }
}

button.thread-action {
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-primary-base);
  transition: background-color 100ms ease-in, transform 100ms ease-in;

  &:hover {
    background-color: var(--color-grey-light);
    transform: scale(120%);
  }

  .aula-icon {
    font-size: 12px;
    vertical-align: middle;
    &.icon-Aula_star_solid {
      color: var(--color-alert);
    }
  }
}
</style>
