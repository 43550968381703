<template>
  <AulaSelect v-bind="{ ...$props, ...$attrs }" v-on="$listeners">
    <OptionGroup v-for="(group, index) in groups" v-bind="group" :key="index">
      <slot v-bind="{ group }" name="group" />
      <template v-for="option in optionsDictionary[group.value]">
        <slot v-bind="{ ...option }" name="option" />
      </template>
    </OptionGroup>
    <template v-for="option in optionsWithoutGroup">
      <slot v-bind="{ ...option }" name="option" />
    </template>
    <slot />
  </AulaSelect>
</template>
<script lang="js">
import AulaSelect from "../../components/select/Select";
import {selectOptionTypes} from "../../enums/selectOptionTypes";
import OptionGroup from 'element-ui/lib/option-group';

export default {
  name: 'ContactsFilterDropdown',
  components: {
    AulaSelect,
    OptionGroup,
  },
  props: {
    options: {
      required: true,
      type: Array,
    },
    groups: {
      required: false,
      default: () => [],
      type: Array,
    },
  },
  computed: {
    optionsDictionary() {
      const optionsDictionary = {};
      for(const option of this.options) {
        let group = selectOptionTypes.noGroup;
        if (option.group) {
          group = option.group;
        }
        let options = optionsDictionary[group];
        if (!Array.isArray(options)) {
          options = [];
        }
        options.push(option);
        optionsDictionary[group] = options;
      }

      return optionsDictionary;
    },
    optionsWithoutGroup() {
      return this.optionsDictionary[selectOptionTypes.noGroup] || [];
    },
  }
}
</script>
