<template>
  <div class="groups-list">
    <div class="header-dropdown-title">
      {{ 'MY_GROUPS' | fromTextKey }}
      <i
        class="icon-Aula_app_arrow_slideback group-back"
        role="button"
        tabindex="0"
        :aria-label="'ARIA_LABEL_CLOSE' | fromTextKey"
        @click="$emit('groupClick')"
        @keydown.enter="$emit('groupClick')"
      />
      <div class="header-dropdown-close">
        <i
          tabindex="0"
          role="button"
          class="icon-Aula_close"
          :aria-label="'ARIA_LABEL_CLOSE' | fromTextKey"
          @click="
            toggleHeaderDropdown({
              dropdown: 'groups',
              visible: showGroupsDropdown,
            })
          "
          @keydown.enter="
            toggleHeaderDropdown({
              dropdown: 'groups',
              visible: showGroupsDropdown,
            })
          "
        />
      </div>
    </div>
    <template v-for="(group, index) in userGroups">
      <div
        v-if="showGroup(group)"
        :key="group.id + '-' + index"
        class="group-item"
        tabindex="0"
        role="button"
        @click="handleGroupClicked(group)"
        @keydown.enter="handleGroupClicked(group)"
      >
        <div class="d-flex align-items-center text-bold">
          <div class="group-item-name">
            <div class="truncate">
              <i class="icon-Aula_group mobile" />&nbsp;&nbsp;
              <span v-if="group.name.length < 35">{{ group.name }}</span>
              <span
                v-else
                v-b-tooltip.hover="{
                  title: group.name,
                  container: '.groups-list',
                  delay: { show: 10, hide: 100 },
                }"
              >
                {{ group.name }}
              </span>
            </div>
          </div>
          <badge-notification class="group-item-badge" :amount="badgeAmount(group.id)" :border="true" />
          <div class="ml-2">
            <i class="icon-Aula_forward-arrow" />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { types } from '../../store/types/types';
import { mapGetters, mapActions } from 'vuex';
import { groupMembershipRoles } from '../../../shared/enums/groupMembershipRoles.js';
import BadgeNotification from '../../../shared/components/BadgeNotification.vue';
import { notificationTypes } from '../../../shared/enums/notificationTypes';
import { notificationEventTypes } from '../../../shared/enums/notificationEventTypes';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';

export default {
  data: function () {
    return {
      showGroups: false,
      groupMembershipRoles: groupMembershipRoles,
      tooltipInterval: null,
    };
  },
  computed: {
    ...mapGetters({
      userGroups: types.GET_USER_GROUPS,
      profile: types.GET_CURRENT_PROFILE,
      showGroupsDropdown: types.GET_SHOW_GROUPS_DROPDOWN,
      delegatedContext: types.GET_DELEGATED_CONTEXT,
      notifications: types.GET_NOTIFICATIONS,
      hasPermissionGroup: types.HAS_PERMISSION_ON_GROUP,
    }),
  },
  methods: {
    badgeAmount(groupId) {
      if (this.hasPermissionGroup(permissionEnum.HANDLE_GROUP_APPLICATION, groupId)) {
        return this.notifications.filter(
          n =>
            n.sharedGroupIds &&
            n.sharedGroupIds.includes(groupId) &&
            n.notificationType === notificationTypes.BADGE &&
            (n.notificationEventType === notificationEventTypes.POST_WAS_REPORTED ||
              n.notificationEventType === notificationEventTypes.MEDIA_WAS_REPORTED ||
              n.notificationEventType === notificationEventTypes.COMMENT_WAS_REPORTED)
        ).length;
      }
      return 0;
    },
    async handleGroupClicked(group) {
      if (this.delegatedContext.institutionProfileId != null) {
        await this.setDelegatedContext();
      }
      this.toggleGroupOverlay(group);
      this.$emit('groupOpen');
    },
    toggleGroupOverlay(group) {
      this.$router.push({
        name: 'group-dashboard',
        params: { groupId: group.id },
      });
    },
    showGroup(group) {
      return (
        (group.role === groupMembershipRoles.INDIRECT || group.role === groupMembershipRoles.MEMBER) &&
        (group.dashboardEnabled || this.hasPermissionGroup(permissionEnum.HANDLE_GROUP_APPLICATION, group.id))
      );
    },
    ...mapActions({
      toggleHeaderDropdown: types.TOGGLE_HEADER_DROPDOWN,
      setDelegatedContext: types.SET_DELEGATED_CONTEXT,
    }),
  },
  components: {
    BadgeNotification,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.groups-list {
  @include breakpoint-lg-down() {
    overflow-x: auto;
    width: 100vw;
    height: 100vh;
    background-color: $color-primary-base-employee;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1055;
  }
  .group-item {
    position: relative;
    background-color: var(--color-grey-dark);
    padding: 1rem 0.75rem;
    margin-bottom: 2px;
    font-weight: bold;
    .group-item-name {
      margin: 0 5px;
      min-width: 0;
      flex-grow: 1;
    }
    .group-item-badge {
      --position: static;
    }
  }
  .header-dropdown-title {
    @include breakpoint-lg-down() {
      display: block;
      text-align: center;
      height: 65px;
      width: 100%;
      font-size: 18px !important;
    }
    .header-dropdown-close {
      @include breakpoint-lg-down() {
        display: none;
      }
    }
    .group-back {
      position: absolute;
      top: 20px;
      left: 20px;
      display: block;
      @include breakpoint-lg() {
        display: none;
      }
    }
  }
}

/deep/ .groups-list {
  .tooltip {
    .arrow:before {
      border-top-color: $group-tooltip-background;
    }

    .tooltip-inner {
      background-color: $group-tooltip-background !important;

      .theme-employee & {
        color: $color-primary-darker-employee;
      }

      .theme-guardian & {
        color: $color-primary-darker-guardian;
      }

      .theme-child & {
        color: $color-primary-darker-child;
      }

      .theme-admin & {
        color: $color-primary-darker-admin;
      }
    }
  }
}

//Theme styling
.theme-admin {
  .groups-list {
    @include breakpoint-lg-down() {
      background-color: $color-primary-dark-admin;
    }
  }
}
.theme-guardian {
  .groups-list {
    @include breakpoint-lg-down() {
      background-color: $color-primary-base-guardian;
    }
  }
}
.theme-employee {
  .groups-list {
    @include breakpoint-lg-down() {
      background-color: $color-primary-base-employee;
    }
  }
}
.theme-child {
  .groups-list {
    @include breakpoint-lg-down() {
      background-color: $color-primary-base-child;
    }
  }
}
</style>
