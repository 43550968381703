import { render, staticRenderFns } from "./GroupAdministration.vue?vue&type=template&id=48083f96&scoped=true&"
import script from "./GroupAdministration.vue?vue&type=script&lang=js&"
export * from "./GroupAdministration.vue?vue&type=script&lang=js&"
import style0 from "./GroupAdministration.vue?vue&type=style&index=0&id=48083f96&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48083f96",
  null
  
)

export default component.exports