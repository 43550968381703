<template>
  <div class="filtering-group-warning">
    {{ 'CONTACT_TOO_MUCH_FILTERING_GROUP_RESULTS' | fromTextKey }}
  </div>
</template>
<script lang="js">
export default {
  name: 'ContactsFragmentFilteringGroupWarning',
}
</script>
<style lang="scss" scoped>
.filtering-group-warning {
  font-weight: 900;
  padding: 20px;
}
</style>
