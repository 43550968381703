<template>
  <div class="bundle-container">
    <div v-if="isLoading" class="bundle-spinner">
      <b-spinner />
    </div>
    <template v-for="(thread, index) in threadsInBundle" v-else>
      <subscription
        :key="index"
        class="thread"
        :subscription="thread"
        @click.native="onSubscriptionClicked(thread)"
        @keyup.native.enter="onSubscriptionClicked(thread)"
      />
    </template>
  </div>
</template>

<script>
import Subscription from './Subscription';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { types } from '../../store/types/types';

export default {
  components: { Subscription },
  props: {
    subscriptionData: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      threadBundles: types.MESSAGES_GET_THREAD_BUNDLES,
      chosenThreads: types.MESSAGES_GET_CHOSEN_THREAD_IDS,
      chosenSubscriptions: types.MESSAGES_GET_CHOSEN_SUBSCRIPTION_IDS,
    }),
    threadsInBundle() {
      const bundle = this.threadBundles.find(bundle => bundle.bundleId === this.subscriptionData.subscriptionId);
      if (bundle) {
        return bundle.threads.filter(thread => !thread.deleted);
      }
      return [];
    },
  },
  methods: {
    ...mapMutations({
      setSelectedBundleId: types.MUTATE_SELECTED_BUNDLE_ID,
    }),
    ...mapActions({
      loadThreadsInBundleById: types.LOAD_THREADS_IN_BUNDLE_BY_ID,
      updateChosenThreads: types.ACTION_UPDATE_SELECTED_THREAD_IDS,
      updateChosenSubscriptions: types.ACTION_UPDATE_SELECTED_SUBSCRIPTION_IDS,
    }),
    onSubscriptionClicked() {
      this.setSelectedBundleId(this.subscriptionData.subscriptionId);
    },
    async getThreadsInBundle() {
      const bundleId = this.subscriptionData.subscriptionId;
      const bundleExists = this.threadBundles.some(bundle => bundle.bundleId === bundleId);
      this.isLoading = false;

      if (bundleExists) {
        return;
      }

      this.isLoading = true;
      await this.loadThreadsInBundleById({ bundleId });
      this.isLoading = false;

      if (!this.subscriptionData.checked) {
        return;
      }

      const chosenThreads = this.chosenThreads;
      const chosenSubscriptions = this.chosenSubscriptions;
      this.threadsInBundle.forEach(thread => {
        this.$set(thread, 'checked', true);
        if (chosenThreads.includes(thread.id) === false) {
          chosenThreads.push(thread.id);
        }
        if (chosenSubscriptions.includes(thread.subscriptionId) === false) {
          chosenSubscriptions.push(thread.subscriptionId);
        }
      });
      this.updateChosenThreads(chosenThreads);
      this.updateChosenSubscriptions(chosenSubscriptions);
    },
    reloadThreadsInBundle() {
      const bundleId = this.subscriptionData.subscriptionId;
      this.loadThreadsInBundleById({ bundleId });
    },
  },
  watch: {
    threadsInBundle(newValues, oldValues) {
      if (newValues.length < oldValues.length) {
        this.reloadThreadsInBundle();
      }
    },
  },
  mounted() {
    this.getThreadsInBundle();
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/core/animations.scss';
@import '../../../shared/assets/scss/components/messages/_subscriptions.scss';
</style>
