




























import Thumbnail from '../../../shared/components/Thumbnail.vue';
import AulaCard from '../../../shared/components/AulaCard.vue';
import * as dateUtil from '../../../shared/utils/dateUtil.js';
import { PropType } from 'vue';
import { AlbumModel } from '../../../shared/models/album.model';
import Icon from '../../../shared/components/Icon.vue';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';

export default {
  components: { Icon, AulaCard, Thumbnail },
  props: {
    album: { type: Object as PropType<AlbumModel>, required: true },
  },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
    createdAt() {
      return dateUtil.format(this.album.creationDate, 'DD. MMMM YYYY');
    },
  },
};
