var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mobile-groups-profile"},[_c('div',{staticClass:"header-dropdown",class:{ active: _vm.showGroupsDropdown },attrs:{"id":"dropdown-groups","tabindex":"0"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }!_vm.isPreviewMode
          ? _vm.toggleHeaderDropdown({
              dropdown: 'groups',
              visible: _vm.showGroupsDropdown,
            })
          : '';
        _vm.scrollToTopPage();},"click":function($event){!_vm.isPreviewMode
          ? _vm.toggleHeaderDropdown({
              dropdown: 'groups',
              visible: _vm.showGroupsDropdown,
            })
          : '';
        _vm.scrollToTopPage();}}},[_c('i',{staticClass:"icon-Aula_hamburger"})])]),_vm._v(" "),_c('transition',{attrs:{"name":"slide-up"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showGroupsDropdown),expression:"showGroupsDropdown"}],staticClass:"header-dropdown-menu mobile"},[_c('transition',{attrs:{"name":"slide-mobile"}},[(_vm.showGroupList)?_c('groups-list',{on:{"groupClick":_vm.groupClick,"groupOpen":_vm.handleGroupOpen}}):_vm._e()],1),_vm._v(" "),_c('transition',{attrs:{"name":"slide-up"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMobileProfileDropdown),expression:"showMobileProfileDropdown"}],staticClass:"header-dropdown-menu",attrs:{"role":"dialog"}},[(!_vm.isLoadingProfileContext)?_c('UserProfile'):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"group-profile-icons",class:{ active: _vm.showGroupsDropdown }},[_c('i',{staticClass:"icon-Aula_close",attrs:{"aria-label":_vm._f("fromTextKey")('ARIA_LABEL_CLOSE')},on:{"click":function($event){!_vm.isPreviewMode
              ? _vm.toggleHeaderDropdown({
                  dropdown: 'groups',
                  visible: _vm.showGroupsDropdown,
                })
              : '';
            _vm.scrollToTopPage();}}}),_vm._v(" "),_c('div',{staticClass:"item"},[_c('div',{staticClass:"group-profile",attrs:{"tabindex":"0","role":"button"},on:{"click":_vm.groupClick,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.groupClick.apply(null, arguments)}}},[_c('div',{staticClass:"group-icon"},[(!_vm.isChild)?_c('i',{staticClass:"icon-Aula_group"}):_c('img',{staticClass:"menu-icon",attrs:{"src":'static/icons/icon-Aula_group_child.svg'}})]),_vm._v(" "),_c('div',{staticClass:"item-title"},[_vm._v("\n              "+_vm._s(_vm._f("fromTextKey")('MY_GROUPS'))+"\n            ")])])]),_vm._v(" "),_c('div',{staticClass:"item profile"},[_c('div',{staticClass:"group-profile",attrs:{"tabindex":"0"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.toggleHeaderDropdown({
                dropdown: 'mobileProfile',
                visible: _vm.showMobileProfileDropdown,
              })},"click":function($event){return _vm.toggleHeaderDropdown({
                dropdown: 'mobileProfile',
                visible: _vm.showMobileProfileDropdown,
              })}}},[_c('user-icon',{attrs:{"id":_vm.profile.id,"short-name":_vm.profile.shortName,"picture-object":_vm.profile.profilePicture}}),_vm._v(" "),_c('div',{staticClass:"item-title"},[_vm._v("\n              "+_vm._s(_vm._f("fromTextKey")('PROFILE_LABEL'))+"\n            ")])],1)])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }