<template>
  <header v-show="showHeader" id="aula-header">
    <div id="top-bar">
      <div class="logo-outer">
        <b-btn
          variant="link"
          class="logo"
          @click="$router.push({ path: '/' })"
          @keypress.enter="$route.push({ path: '/' })"
        >
          <span class="sr-only">{{ 'NAVIGATE_TO_FRONTPAGE' | fromTextKey }}</span>
          <i class="icon-Logo" aria-hidden="true" />
        </b-btn>
      </div>
      <div v-if="$route.path != '/login'" class="menu-row">
        <global-filter />
        <groups v-if="!isMobile" />
        <search />
        <mobile-groups-profile v-if="isMobile" />
        <settings v-else />
      </div>
    </div>
  </header>
</template>

<script>
import Search from '../searches/Search.vue';
import Settings from '../users/UserSettings.vue';
import Groups from '../groups/Groups.vue';
import GlobalFilter from './GlobalFilter.vue';
import MobileGroupsProfile from './MobileGroupsProfile.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { types } from '../../store/types/types';

export default {
  data: function () {
    return {};
  },
  computed: {
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
      profile: types.GET_CURRENT_PROFILE,
      chosenSubscriptionId: types.MESSAGES_GET_SELECTED_SUBSCRIPTION_ID,
      showHeader: types.GET_SHOW_HEADER,
    }),
  },
  methods: {
    ...mapActions({
      resetSubscription: types.RESET_SELECT_SUBSCRIPTION,
    }),
    ...mapMutations({
      setIsMobile: types.MUTATE_IS_MOBILE,
      setIsTablet: types.MUTATE_IS_TABLET,
    }),
    detectIsMobile() {
      this.setIsMobile();
      this.setIsTablet();
    },
  },
  created() {
    window.addEventListener('orientationchange', this.detectIsMobile);
  },
  beforeDestroy() {
    window.removeEventListener('orientationchange', this.detectIsMobile, false);
  },
  components: {
    search: Search,
    settings: Settings,
    groups: Groups,
    'global-filter': GlobalFilter,
    'mobile-groups-profile': MobileGroupsProfile,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

#top-bar {
  color: $color-white;
  .back.btn {
    display: none;
  }
}

#aula-header /deep/ {
  .menu-icon {
    transition: transform 0.1s ease-in-out;
  }
  .header-dropdown:hover {
    .menu-icon {
      .early-student & {
        transform: scale(1.1);
      }
    }
  }
}
</style>
