var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"groups-list"},[_c('div',{staticClass:"header-dropdown-title"},[_vm._v("\n    "+_vm._s(_vm._f("fromTextKey")('MY_GROUPS'))+"\n    "),_c('i',{staticClass:"icon-Aula_app_arrow_slideback group-back",attrs:{"role":"button","tabindex":"0","aria-label":_vm._f("fromTextKey")('ARIA_LABEL_CLOSE')},on:{"click":function($event){return _vm.$emit('groupClick')},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('groupClick')}}}),_vm._v(" "),_c('div',{staticClass:"header-dropdown-close"},[_c('i',{staticClass:"icon-Aula_close",attrs:{"tabindex":"0","role":"button","aria-label":_vm._f("fromTextKey")('ARIA_LABEL_CLOSE')},on:{"click":function($event){return _vm.toggleHeaderDropdown({
            dropdown: 'groups',
            visible: _vm.showGroupsDropdown,
          })},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.toggleHeaderDropdown({
            dropdown: 'groups',
            visible: _vm.showGroupsDropdown,
          })}}})])]),_vm._v(" "),_vm._l((_vm.userGroups),function(group,index){return [(_vm.showGroup(group))?_c('div',{key:group.id + '-' + index,staticClass:"group-item",attrs:{"tabindex":"0","role":"button"},on:{"click":function($event){return _vm.handleGroupClicked(group)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleGroupClicked(group)}}},[_c('div',{staticClass:"d-flex align-items-center text-bold"},[_c('div',{staticClass:"group-item-name"},[_c('div',{staticClass:"truncate"},[_c('i',{staticClass:"icon-Aula_group mobile"}),_vm._v("  \n            "),(group.name.length < 35)?_c('span',[_vm._v(_vm._s(group.name))]):_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
                title: group.name,
                container: '.groups-list',
                delay: { show: 10, hide: 100 },
              }),expression:"{\n                title: group.name,\n                container: '.groups-list',\n                delay: { show: 10, hide: 100 },\n              }",modifiers:{"hover":true}}]},[_vm._v("\n              "+_vm._s(group.name)+"\n            ")])])]),_vm._v(" "),_c('badge-notification',{staticClass:"group-item-badge",attrs:{"amount":_vm.badgeAmount(group.id),"border":true}}),_vm._v(" "),_vm._m(0,true)],1)]):_vm._e()]})],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ml-2"},[_c('i',{staticClass:"icon-Aula_forward-arrow"})])}]

export { render, staticRenderFns }