var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isSectionVisible)?_c('div',{staticClass:"specific-opening-hour-container"},[(_vm.isMobile)?_c('div',{staticClass:"mobile-box-title"},[_vm._v("\n    "+_vm._s(_vm._f("fromTextKey")('PRESENCE_SPECIFIC_OPENING_HOURS'))+"\n  ")]):_c('h2',[_vm._v(_vm._s(_vm._f("fromTextKey")('PRESENCE_SPECIFIC_OPENING_HOURS')))]),_vm._v(" "),_vm._l((_vm.splitSpecificOpeningHourData),function(subList,key){return [_c('div',{key:'table-' + key,staticClass:"table",class:{
        'w-50': subList.length === 1 && _vm.selectedInstitutions.length !== 1,
      }},[_c('div',{staticClass:"table-row header"},[(_vm.selectedInstitutions.length === 1)?[_c('div',{staticClass:"table-cell date-header"},[_vm._v("\n            "+_vm._s(_vm._f("fromTextKey")(_vm.selectedInstitutions.length === 1 ? 'PRESENCE_DATE_LABEL' : ''))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"table-cell institution-header"},[_vm._v("\n            "+_vm._s(_vm._f("fromTextKey")('PRESENCE_OPENING_HOURS'))+"\n          ")])]:_vm._l((subList),function(institutionOpeningHourData){return _c('div',{key:institutionOpeningHourData.institutionCode,staticClass:"table-cell institution-header w-50"},[_vm._v("\n            "+_vm._s(_vm.getInstitutionName(institutionOpeningHourData.institutionCode))+"\n          ")])})],2),_vm._v(" "),_vm._l((_vm.getNumberOfRowsForSubGroupTable(subList)),function(index){return _c('div',{key:index,staticClass:"table-row body"},[(_vm.selectedInstitutions.length === 1 || _vm.isMobile)?[_c('div',{staticClass:"table-cell date-cell"},[_vm._v("\n            "+_vm._s(_vm.getFormatDatePeriod(
                subList[0].specificOpeningHours[index - 1].startDate,
                subList[0].specificOpeningHours[index - 1].endDate
              ))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"table-cell"},[_vm._v("\n            "+_vm._s(subList[0].specificOpeningHours[index - 1].openTime)+" -\n            "+_vm._s(subList[0].specificOpeningHours[index - 1].closeTime)+"\n          ")])]:_vm._l((subList),function(institutionOpeningHourData){return _c('div',{key:'cell-' + institutionOpeningHourData.institutionCode,staticClass:"table-cell"},[(institutionOpeningHourData.specificOpeningHours[index - 1])?[_c('div',{staticClass:"d-flex mb-1"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm._f("fromTextKey")('PRESENCE_DATE_LABEL'))+":")]),_vm._v("\n                "+_vm._s(_vm.getFormatDatePeriod(
                    institutionOpeningHourData.specificOpeningHours[index - 1].startDate,
                    institutionOpeningHourData.specificOpeningHours[index - 1].endDate
                  ))+"\n              ")]),_vm._v(" "),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm._f("fromTextKey")('PRESENCE_OPENING_HOURS'))+":")]),_vm._v("\n                "+_vm._s(institutionOpeningHourData.specificOpeningHours[index - 1].openTime)+"\n                -\n                "+_vm._s(institutionOpeningHourData.specificOpeningHours[index - 1].closeTime)+"\n              ")])]:_vm._e()],2)})],2)})],2)]})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }