<template>
  <div>
    <ContactsFragmentNoResult v-if="contacts.length === 0" />
    <TableContainer v-else>
      <TableHead>
        <TableRow>
          <TableHeading class="color-grey-base" :collapse-directions="[collapseDirectionEnum.RIGHT]" />
          <TableHeading class="color-grey-base" :collapse-directions="[collapseDirectionEnum.LEFT]">
            <ContactsFragmentSorting
              :direction="orderDirection"
              :sort-by="contactSortingTypes.NAME"
              :sorting-by="sortBy"
              @sort="onSortBy"
            >
              {{ 'CONTACT_NAME' | fromTextKey }}
            </ContactsFragmentSorting>
          </TableHeading>
          <TableHeading class="color-grey-base address-cell">
            {{ 'CONTACT_ADDRESS' | fromTextKey }}
          </TableHeading>
          <TableHeading class="color-grey-base">
            {{ 'CONTACT_PHONE' | fromTextKey }}
          </TableHeading>
          <TableHeading class="color-grey-base">
            {{ 'CONTACT_EMAIL' | fromTextKey }}
          </TableHeading>
        </TableRow>
      </TableHead>
      <TableBody>
        <template v-for="contact in contacts">
          <TableRow :key="contact.id + '_' + contact.fullName">
            <TableCell class="color-grey-base profile-avatar-cell">
              <ContactAvatar
                :disabled="contact.contactType !== contactTypeTypes.PROFILE"
                :profile="contact"
                class="user-avatar"
              />
            </TableCell>
            <TableCell>
              <ContactsFragmentContactMetadata :profile="contact">
                <p class="m-0">
                  {{ contact | displayProfileNameWithMetadata }}
                </p>
              </ContactsFragmentContactMetadata>
            </TableCell>
            <TableCell>
              <address v-if="contact.userHasGivenConsentToShowContactInformation && contact.address != null">
                {{ contact.address.street }},
                <br />
                {{ contact.address.postalCode }}
                {{ contact.address.postalDistrict }}
              </address>
            </TableCell>
            <TableCell>
              <ContactsFragmentDisplayProfilePhoneNumbers
                v-if="contact.userHasGivenConsentToShowContactInformation"
                :profile="contact"
              />
            </TableCell>
            <TableCell>
              <a
                v-if="contact.userHasGivenConsentToShowContactInformation && contact.email"
                class="color-primary-darker"
                :href="'mailto:' + contact.email"
              >
                {{ contact.email }}
              </a>
            </TableCell>
          </TableRow>
        </template>
      </TableBody>
    </TableContainer>
  </div>
</template>
<script lang="js">

import TableCell from "../../libs/table/components/TableCell";
import TableBody from "../../libs/table/components/TableBody";
import ContactsFragmentSorting from "./ContactsFragmentSorting";
import TableHeading from "../../libs/table/components/TableHeading";
import TableRow from "../../libs/table/components/TableRow";
import TableHead from "../../libs/table/components/TableHead";
import TableContainer from "../../libs/table/components/TableContainer";
import {providerKeyTypes} from "../../enums/providerKeyTypes";
import {collapseDirectionEnum} from "../../libs/table/enums/collapseDirectionEnum";
import {contactSortingTypes} from "../../enums/contactSortingTypes";
import {contactTypeTypes} from "../../enums/contactTypeTypes";
import ContactsFragmentNoResult from "./ContactsFragmentNoResult";
import ContactsFragmentDisplayProfilePhoneNumbers from "./ContactsFragmentDisplayProfilePhoneNumbers";
import ContactsFragmentContactMetadata from "./ContactsFragmentContactMetadata";
import ContactAvatar from "../../../src/components/contacts/ContactAvatar.vue";

export default {
  name: 'ContactsFragmentDesktopContactListParents',
  components: {
    ContactAvatar,
    ContactsFragmentContactMetadata,
    ContactsFragmentDisplayProfilePhoneNumbers,
    ContactsFragmentNoResult,
    TableCell,
    TableBody,
    ContactsFragmentSorting,
    TableHeading,
    TableRow,
    TableHead,
    TableContainer,
  },
  inject: {
    getContacts: providerKeyTypes.contacts,
    getOrderDirection: providerKeyTypes.orderDirection,
    getSortBy: providerKeyTypes.sortBy,
    changeSortBy: providerKeyTypes.onSortBy,
    getProfile: providerKeyTypes.profile,
  },
  data() {
    return {
      collapseDirectionEnum,
      contactSortingTypes,
    }
  },
  computed: {
    contactTypeTypes() {
      return contactTypeTypes
    },
    contacts() {
      return this.getContacts();
    },
    orderDirection() {
      return this.getOrderDirection();
    },
    sortBy() {
      return this.getSortBy();
    },
    profile() {
      return this.getProfile();
    },
  },
  methods: {
    onSortBy(sortBy) {
      this.changeSortBy(sortBy);
    },
    getShortName(institutionProfile) {
      return this.$options.filters.displayProfileInitials(institutionProfile);
    },
  }
};
</script>
<style lang="scss" scoped>
.address-cell {
  width: 200px;
  max-width: 200px;
  min-width: 200px;
}
</style>
