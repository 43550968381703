<template>
  <b-card
    class="search-result-item media mb-4"
    tabindex="0"
    @keypress.enter.stop="openMedia(media)"
    @click.stop="openMedia(media)"
  >
    <div class="search-result-icon-col media">
      <div class="media-thumbnails">
        <ImageThumbnail v-if="media.mediaType === mediaTypes.IMAGE" :media="media" />
        <VideoThumbnail v-if="media.mediaType === mediaTypes.VIDEO" :media="media" />
        <SoundThumbnail v-if="media.mediaType === mediaTypes.SOUND" />
      </div>
    </div>
    <div class="search-result-text-col">
      <div class="card-header-text">
        <keyword-highlight :queries="searchString">
          {{ media.title }}
        </keyword-highlight>
      </div>
      <div class="card-text">
        <keyword-highlight :queries="searchString">
          {{ media.description }}
        </keyword-highlight>
      </div>
      <div v-if="media.tags.length > 0" class="card-text">
        {{ 'MEDIA_ALBUM_TAGS' | fromTextKey }} :
        <keyword-highlight :queries="searchString">
          {{ getTaggedName() }}
        </keyword-highlight>
      </div>
      <div class="card-text">
        {{ 'MEDIA_CREATED_BY' | fromTextKey }} :
        <keyword-highlight :queries="searchString">
          {{ media.creator.name }}
        </keyword-highlight>
        <span v-if="media.file.created">({{ media.file.created | longDate(true) }})</span>
      </div>
      <div v-if="media.createdDate" class="card-text">
        {{ 'MEDIA_CREATED_DATE' | fromTextKey }} :{{ media.createdDate }}
      </div>
      <div v-if="media.albumTitle" class="card-text">
        {{ 'MEDIA_IN_ALBUM_TITLE' | fromTextKey }} :
        <keyword-highlight :queries="searchString">
          {{ media.albumTitle }}
        </keyword-highlight>
      </div>
      <div v-if="media.albumDescription" class="card-text">
        {{ 'MEDIA_IN_ALBUM_DESCRIPTION' | fromTextKey }} :
        <keyword-highlight :queries="searchString">
          {{ media.albumDescription }}
        </keyword-highlight>
      </div>
    </div>
  </b-card>
</template>

<script>
import KeywordHighlight from 'vue-text-highlight';
import { mediaTypes } from '../../../shared/enums/mediaTypes';
import { conversionStatusTypes } from '../../../shared/enums/conversionStatusTypes';
import VideoThumbnail from '../../../shared/PageFragments/Media/VideoThumbnail.vue';
import SoundThumbnail from '../../../shared/PageFragments/Media/SoundThumbnail.vue';
import ImageThumbnail from '../../../shared/PageFragments/Media/ImageThumbnail.vue';

export default {
  components: {
    ImageThumbnail,
    SoundThumbnail,
    VideoThumbnail,
    KeywordHighlight,
  },
  props: {
    media: { type: Object, default: () => {} },
    parent: { type: String, default: 'profile' },
    searchString: { type: String, default: '' },
  },
  data: function () {
    return {
      mediaTypes: mediaTypes,
      conversionStatus: conversionStatusTypes,
    };
  },
  methods: {
    getTaggedName() {
      if (this.media.tags.length > 0) {
        const names = this.media.tags.map(tagged => tagged.name);
        return names.join(', ');
      }
      return '';
    },
    openMedia(media) {
      if (media.conversionStatus === this.conversionStatus.PROCESSING) {
        return;
      }
      this.$emit('openMedia', media);
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/_breakpoints.scss';
.card-text {
  text-transform: capitalize;
}
.media-thumbnails {
  font-size: 1rem;
  background-color: var(--color-grey-base);
  color: var(--color-primary-darker);
}

.search-result-item .card-body {
  @include breakpoint-sm-down() {
    --card-body-flex-direction: column;
  }
}
</style>
