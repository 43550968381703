<template>
  <div
    v-if="
      getPresenceActiveModules[presenceModules.VACATION] &&
      getPresenceActiveModules[presenceModules.VACATION].length > 0
    "
    class="guardian-vacation-announcement-container"
  >
    <h2 v-if="!isMobile">
      {{ 'PRESENCE_REPORT_VACATION_ANNOUNCEMENT' | fromTextKey }}
    </h2>
    <div v-else class="mobile-box-title">
      {{ 'PRESENCE_REPORT_VACATION_ANNOUNCEMENT' | fromTextKey }}
      <help-box
        id="vacation-announcement-help-box"
        :title="$options.filters.fromTextKey('PRESENCE_REPORT_VACATION_ANNOUNCEMENT')"
        :body="`${$options.filters.fromTextKey(
          'PRESENCE_VACATION_ANNOUNCEMENT_DESCRIPTION_1'
        )} ${$options.filters.fromTextKey('PRESENCE_VACATION_ANNOUNCEMENT_DESCRIPTION_2')}`"
      />
    </div>
    <div class="mb-3 d-flex justify-content-between">
      <div v-if="!isMobile">
        {{ 'PRESENCE_VACATION_ANNOUNCEMENT_DESCRIPTION_1' | fromTextKey }}
        {{ 'PRESENCE_VACATION_ANNOUNCEMENT_DESCRIPTION_2' | fromTextKey }}
      </div>
      <b-btn
        v-if="hasCustodyOverActiveChildren"
        variant="primary"
        class="ml-lg-4 my-lg-0 mt-3 register-vacation-button"
        @click="onRegisterVacationClicked"
      >
        {{ 'BUTTON_CREATE' | fromTextKey }}
      </b-btn>
    </div>
    <aula-spinner v-if="isLoading" />
    <template v-else-if="splitVacationAnnouncementData.length > 0">
      <div
        v-for="(subVacationAnnouncements, i) in splitVacationAnnouncementData"
        :key="i"
        class="table"
        :class="{ 'w-50': subVacationAnnouncements.length === 1 && !isMobile }"
      >
        <div class="table-row header">
          <div v-for="(childData, j) in subVacationAnnouncements" :key="j" class="table-cell">
            <div v-if="childData.child" class="d-flex align-items-center">
              <div>
                <user-icon
                  :id="childData.child.id"
                  :name="childData.child.name"
                  :short-name="childData.child.shortName"
                  :picture-object="childData.child.profilePicture"
                  user-icon-class="small"
                  :show-inline="true"
                />
              </div>
              <div class="ml-3 flex-grow-1">
                {{ childData.child.name }}
                <span class="font-weight-normal">({{ childData.child.metadata }})</span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-for="(index, j) in getNumberOfRowsForSubGroupTable(subVacationAnnouncements)"
          :key="j"
          class="table-row body"
        >
          <div
            v-for="(childData, k) in subVacationAnnouncements"
            :key="k"
            class="table-cell"
            :tabindex="childData.vacationAnnouncements[index - 1] ? 0 : -1"
            :class="{
              'pointer-cursor': childData.vacationAnnouncements[index - 1],
            }"
            @keyup.enter="
              hasCustodyOrExtendedAccess(childData.child.id)
                ? showVacationAnnouncement(childData.vacationAnnouncements[index - 1], true)
                : undefined
            "
            @click="
              hasCustodyOrExtendedAccess(childData.child.id)
                ? showVacationAnnouncement(childData.vacationAnnouncements[index - 1], true)
                : undefined
            "
          >
            <div
              v-if="childData.vacationAnnouncements[index - 1]"
              class="cell-information d-flex justify-content-between"
            >
              <div>
                <div class="d-flex">
                  <div class="absence-label">{{ 'PRESENCE_REPORT_PERIOD' | fromTextKey }}:</div>
                  {{
                    `${convertDateFormat(childData.vacationAnnouncements[index - 1].startDate)} - ${convertDateFormat(
                      childData.vacationAnnouncements[index - 1].endDate
                    )}`
                  }}
                </div>
                <div v-if="childData.vacationAnnouncements[index - 1].description" class="word-wrap d-flex">
                  <div class="absence-label">{{ 'PRESENCE_VACATION_NOTE_SHORT' | fromTextKey }}:</div>
                  {{ childData.vacationAnnouncements[index - 1].description }}
                </div>
              </div>
              <i v-if="isMobile" class="icon icon-Aula_forward-arrow" />
              <template
                v-else-if="
                  childData.vacationAnnouncements[index - 1].isEditable &&
                  hasCustodyOrExtendedAccess(childData.child.id)
                "
              >
                <b-btn
                  variant="link"
                  class="edit-button"
                  :aria-label="'ARIA_LABEL_EDIT' | fromTextKey"
                  @keyup.enter.stop="showVacationAnnouncement(childData.vacationAnnouncements[index - 1], false)"
                  @click.stop="showVacationAnnouncement(childData.vacationAnnouncements[index - 1], false)"
                >
                  <i class="icon icon-Aula_edit_pen" />
                </b-btn>
              </template>
            </div>
            <hr v-if="isMobile" />
          </div>
        </div>
      </div>
    </template>
    <div v-else class="d-flex font-weight-bold">
      {{ 'PRESENCE_WARNING_VACATION_ANNOUNCEMENTS_NO_RESULTS' | fromTextKey }}
    </div>
    <register-vacation-drawer ref="registerVacationDrawer" @onClosed="loadData" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { types } from '../../store/types/types';
import { presenceModules } from '../../../shared/enums/presenceModules';
import RegisterVacationDrawer from './RegisterVacationDrawer';
import dateTimeFormatMixin from '../../../shared/mixins/dateTimeFormatMixin';
import dataFormat from '../../../shared/mixins/dataFormatMixin';

export default {
  components: { RegisterVacationDrawer },
  mixins: [dateTimeFormatMixin, dataFormat],
  data: function () {
    return {
      isLoading: true,
      presenceModules,
    };
  },
  computed: {
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
      activeChildren: types.GET_ACTIVE_CHILDREN,
      vacationAnnouncementList: types.GET_VACATION_ANNOUNCEMENTS_BY_CHILDREN,
      getPresenceActiveModules: types.GET_PRESENCE_ACTIVE_MODULES,
      hasCustodyOrExtendedAccess: types.GET_HAS_CUSTODY_OR_EXTENDED_ACCESS,
    }),
    splitVacationAnnouncementData() {
      const chunk = this.isMobile ? 1 : 2;
      return this.splitDataToChunks(this.vacationAnnouncementList, chunk);
    },
    hasCustodyOverActiveChildren() {
      return this.activeChildren.filter(childId => this.hasCustodyOrExtendedAccess(childId)).length > 0;
    },
  },
  methods: {
    ...mapActions({
      loadVacationAnnouncements: types.LOAD_VACATION_ANNOUCEMENTS_BY_CHILDREN,
    }),
    getNumberOfRowsForSubGroupTable(subGroups) {
      const groupLengthArr = subGroups.map(item => item.vacationAnnouncements.length);
      return Math.max(...groupLengthArr);
    },
    onRegisterVacationClicked() {
      this.$refs.registerVacationDrawer.show();
    },
    loadData() {
      this.loadVacationAnnouncements({ childIds: this.activeChildren }).then(() => {
        this.isLoading = false;
      });
    },
    showVacationAnnouncement(vacation, isReadonly = false) {
      if (vacation) {
        this.$refs.registerVacationDrawer.show(vacation, isReadonly);
      }
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/presence/_presence.scss';
</style>
