import { ObjectDirective, VNode } from 'vue';

const heightTrackMap: Map<HTMLElement, VNode> = new Map();
import throttle from 'lodash/throttle';
import { MatchMediaService } from '../services/MatchMediaService';

const matchMediaService = new MatchMediaService('(orientation: portrait)');
const customEmit = (vnode: VNode, name: string, data: number) => {
  const handlers = vnode.data?.on || vnode.componentOptions?.listeners;

  if (handlers != null && handlers[name] != null) {
    handlers[name].fns(data);
  }
};
const updateHeight = (element: HTMLElement, vnode: VNode) => {
  const height = element.offsetHeight;
  customEmit(vnode, 'heightUpdate', height);
};
const resizeEventListener = throttle(() => {
  for (const [element, vnode] of heightTrackMap) {
    updateHeight(element, vnode);
  }
}, 10);

window.addEventListener('resize', resizeEventListener, { passive: true });
matchMediaService.addEventListener(resizeEventListener);
export default {
  inserted(element: HTMLElement, binding, vnode) {
    updateHeight(element, vnode);
    heightTrackMap.set(element, vnode);
  },
  unbind(element) {
    heightTrackMap.delete(element);
  },
} as ObjectDirective;
