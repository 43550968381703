<template>
  <article class="recipient-removed container">
    <b-row>
      <b-col cols="8" offset="2">
        <div class="system-message">
          <i class="icon-Aula_user-leave system-message-icon" />
          <div class="system-message-text text-wrap">
            {{ firstLeaver }}
            <span v-if="numberOfOtherLeavers > 0">
              {{ 'MESSAGE_USER_SEPARATOR_TEXT' | fromTextKey }}
            </span>
            <MessageRecipientPopover
              v-if="numberOfOtherLeavers > 0"
              :title="$options.filters.fromTextKey('MESSAGE_OTHER_RECIPIENTS_LEFT_TITLE')"
              :recipients="leaverList"
              :recipients-count="numberOfOtherLeavers"
              class="other-recipients"
            />
            <span>
              {{ 'MESSAGE_USER_LEFT_THE_CONVERSATION' | fromTextKey }}
            </span>
            <post-time :time="message.sendDateTime" :is-capitalized="true" variation="long" />
          </div>
        </div>
      </b-col>
    </b-row>
  </article>
</template>

<script>
import { types } from '../../store/types/types';
import { mapGetters } from 'vuex';
import button from '../../../shared/directives/button';
import MessageRecipientPopover from './MessageRecipientPopover';

export default {
  components: { MessageRecipientPopover },
  directives: {
    button,
  },
  props: {
    message: Object,
    shownSubscription: Object,
  },
  computed: {
    ...mapGetters({
      profile: types.GET_CURRENT_PROFILE,
      isMobile: types.GET_IS_MOBILE,
      profilesByLogin: types.GET_PROFILES_BY_LOGIN,
    }),
    leaverList() {
      if (!this.message.leaverNames) {
        return [];
      }
      return this.message.leaverNames;
    },
    messageProfile() {
      const currentProfile = this.profilesByLogin.find(profile => profile.profileId === this.profile.profileId);
      return currentProfile.institutionProfiles.find(
        profile => profile.institutionCode === this.shownSubscription.institutionCode
      );
    },
    firstLeaver() {
      const messageProfileName = this.$options.filters.displayProfileNameWithMetadata(this.messageProfile);
      const isCurrentUserLeaver = this.leaverList[0] === messageProfileName;
      return isCurrentUserLeaver ? this.$options.filters.fromTextKey('MESSAGE_USER_SELF') : this.leaverList[0];
    },
    otherLeavers() {
      return this.leaverList.slice(1);
    },
    numberOfOtherLeavers() {
      return this.otherLeavers.length || 0;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss"></style>
