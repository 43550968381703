import { portalRoles } from '../enums/portalRoles';
import moment from 'moment-timezone';
import { contactsProfileTypeFilters } from '../enums/contactsProfileTypeFilters';

class ProfileUtil {
  getCanUseSecureLoginServices({ profile }) {
    if (profile.role !== portalRoles.CHILD || profile.birthday == null) {
      return true;
    }

    return moment().diff(moment(profile.birthday), 'years') >= 15;
  }

  getChildProfileTypes() {
    return [contactsProfileTypeFilters.CHILD, contactsProfileTypeFilters.BOY, contactsProfileTypeFilters.GIRL];
  }
}

export const profileUtil = new ProfileUtil();
