<template>
  <div>
    <label :for="id"><slot name="date-picker" /></label> <br />
    <el-date-picker
      :id="id"
      type="date"
      class="el-date-picker"
      format="dd/MM/yyyy"
      :placeholder="'MESSAGE_PLACEHOLDER_SELECT_DATE' | fromTextKey"
      v-bind="{ ...$props, ...$attrs }"
      v-on="$listeners"
    />
  </div>
</template>

<script>
export default {
  name: 'MessageAdvancedSearchDatePicker',
  data() {
    return {
      id: null,
    };
  },
  mounted() {
    this.id = String(this._uid);
  },
};
</script>

<style lang="scss" scoped>
.el-date-picker.el-date-editor.el-input {
  width: 100%;
}
</style>
