<template>
  <div class="aula-documents-container page-layout-padding">
    <b-row class="row justify-content-md-center">
      <b-col>
        <h1 class="document-title" tabindex="-1">
          {{ 'DOCUMENTS_TYPES_PAGE_TITLE' | fromTextKey }}
        </h1>
      </b-col>
    </b-row>
    <b-row class="no-gutters pb-4">
      <b-col
        v-if="hasPermission(permissionEnum.ACCESS_SECURE_FILESHARING)"
        cols="12"
        sm="12"
        lg="3"
        class="text-center"
      >
        <div
          class="linkBox"
          tabindex="0"
          role="button"
          @click="
            isSteppedUp
              ? $router.push({ name: 'documentsSecureList' })
              : setStepUpNotification({
                  showStepUpNotification: true,
                  redirectedUrl: redirectUrlSikreFiler,
                })
          "
          @keydown.enter="
            isSteppedUp
              ? $router.push({ name: 'documentsSecureList' })
              : setStepUpNotification({
                  showStepUpNotification: true,
                  redirectedUrl: redirectUrlSikreFiler,
                })
          "
          @keydown.space="
            isSteppedUp
              ? $router.push({ name: 'documentsSecureList' })
              : setStepUpNotification({
                  showStepUpNotification: true,
                  redirectedUrl: redirectUrlSikreFiler,
                })
          "
        >
          <div class="icons">
            <span class="icon-Aula_folder" />
            <span class="icon-Aula_lock" />
            <badge-notification :amount="calculateBadges(notificationEventTypes.NEW_OR_UPDATED_SECURE_DOCUMENT)" />
          </div>

          <h2>
            {{ 'DOCUMENTS_TYPES_SECURE_FILES_TITLE' | fromTextKey }}
          </h2>
          <div class="text text-center">
            {{
              profile.role === portalRoles.EMPLOYEE
                ? 'DOCUMENTS_TYPES_SECURE_FILES_TEXT_EMPLOYEE'
                : 'DOCUMENTS_TYPES_SECURE_FILES_TEXT_GUARDIAN' | fromTextKey
            }}
          </div>
          <div class="link">
            {{ 'DOCUMENTS_TYPES_SECURE_FILES_LINK' | fromTextKey }}
            <span class="icon-Aula_forward-arrow pull-right" />
          </div>
        </div>
      </b-col>
      <b-col cols="12" sm="12" lg="3" class="text-center">
        <div
          tabindex="0"
          class="linkBox"
          role="button"
          @click="$router.push({ name: 'documentsCommonList' })"
          @keydown.enter="$router.push({ name: 'documentsCommonList' })"
          @keydown.space="$router.push({ name: 'documentsCommonList' })"
        >
          <div class="icons">
            <span class="icon-Aula_folder" />
            <span class="icon-Aula_star_solid" />
            <badge-notification
              :amount="
                calculateBadges(notificationEventTypes.NEW_COMMON_FILE) +
                calculateBadges(notificationEventTypes.UPDATED_SHARING_COMMON_FILE) +
                calculateBadges(notificationEventTypes.NEW_OR_UPDATED_COMMON_FILE)
              "
            />
          </div>

          <h2>
            {{ 'DOCUMENTS_TYPES_COMMON_FILES_TITLE' | fromTextKey }}
          </h2>
          <div class="text">
            {{ 'DOCUMENTS_TYPES_COMMON_FILES_TEXT' | fromTextKey }}
          </div>
          <div class="link">
            {{ 'DOCUMENTS_TYPES_COMMON_FILES_LINK' | fromTextKey }}
            <span class="icon-Aula_forward-arrow pull-right" />
          </div>
        </div>
      </b-col>
      <b-col
        v-if="canSeeGoogleDrive"
        cols="12"
        sm="12"
        lg="3"
        class="text-center"
        tabindex="0"
        role="button"
        @click="onClickExternalService(googleDriveUrl)"
        @keydown.enter="onClickExternalService(googleDriveUrl)"
        @keydown.space="onClickExternalService(googleDriveUrl)"
      >
        <div class="linkBox">
          <div class="icons">
            <span class="icon-Aula_folder" />
            <span class="icon-googledrive" />
          </div>
          <h2>{{ 'DOCUMENTS_TYPES_GOOGLE_FILES_TITLE' | fromTextKey }}</h2>
          <div class="text">
            {{ 'DOCUMENTS_TYPES_GOOGLE_FILES_TEXT' | fromTextKey }}
          </div>
          <div class="link">
            {{ 'DOCUMENTS_TYPES_GOOGLE_FILES_LINK' | fromTextKey }}
            <span class="icon-Aula_forward-arrow pull-right" />
          </div>
        </div>
      </b-col>
      <b-col
        v-if="canSeeOneDrive"
        cols="12"
        sm="12"
        lg="3"
        class="text-center"
        tabindex="0"
        role="button"
        @click="onClickExternalService(oneDriveUrl)"
        @keydown.enter="onClickExternalService(oneDriveUrl)"
        @keydown.space="onClickExternalService(oneDriveUrl)"
      >
        <div class="linkBox">
          <div class="icons">
            <span class="icon-Aula_folder" />
            <span class="icon-onedrive" />
          </div>
          <h2>{{ 'DOCUMENTS_TYPES_ONEDRIVE_FILES_TITLE' | fromTextKey }}</h2>
          <div class="text">
            {{ 'DOCUMENTS_TYPES_ONEDRIVE_FILES_TEXT' | fromTextKey }}
          </div>
          <div class="link">
            {{ 'DOCUMENTS_TYPES_ONEDRIVE_FILES_LINK' | fromTextKey }}
            <span class="icon-Aula_forward-arrow pull-right" />
          </div>
        </div>
      </b-col>
    </b-row>
    <aula-modal
      ref="externalLinkWarningModal"
      @cancelClicked="$refs.externalLinkWarningModal.hide()"
      @okClicked="
        openExternalLink();
        $refs.externalLinkWarningModal.hide();
      "
    >
      {{ 'WARNING_OPEN_EXTERNAL_LINK_1' | fromTextKey }}<br /><br />
      {{ 'WARNING_OPEN_EXTERNAL_LINK_2' | fromTextKey }}
    </aula-modal>
  </div>
</template>

<script>
import { types } from '../../store/types/types';
import { mapGetters, mapMutations } from 'vuex';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import BadgeNotification from '../../../shared/components/BadgeNotification.vue';
import { notificationEventTypes } from '../../../shared/enums/notificationEventTypes';
import { notificationAreas } from '../../../shared/enums/notificationAreas';
import { notificationTypes } from '../../../shared/enums/notificationTypes';
import { portalRoles } from '../../../shared/enums/portalRoles';

export default {
  data: function () {
    return {
      permissionEnum: permissionEnum,
      notificationEventTypes: notificationEventTypes,
      notificationAreas: notificationAreas,
      notificationTypes: notificationTypes,
      redirectUrlSikreFiler: window.location.href + 'sikre',
      portalRoles,
      externalServiceUrl: '',
      googleDriveUrl: 'https://drive.google.com/drive/my-drive',
      oneDriveUrl: 'https://onedrive.live.com/about/da-dk/signin/',
    };
  },
  computed: {
    ...mapGetters({
      profile: types.GET_CURRENT_PROFILE,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
      hasPermission: types.HAS_PERMISSION,
      hasPermissionInstitution: types.HAS_PERMISSION_ON_INSTITUTION,
      isSteppedUp: types.GET_GLOBAL_STEPPED_UP,
      document: types.GET_DOCUMENTS_LIVE,
      notifications: types.GET_NOTIFICATIONS,
    }),
    canSeeGoogleDrive() {
      for (const instCode of this.activeInstitutions) {
        if (this.hasPermissionInstitution(permissionEnum.IMPORT_MEDIA_FROM_GOOGLE_DRIVE, instCode)) {
          return true;
        }
      }
      return false;
    },
    canSeeOneDrive() {
      for (const instCode of this.activeInstitutions) {
        if (this.hasPermissionInstitution(permissionEnum.IMPORT_MEDIA_FROM_ONEDRIVE, instCode)) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    ...mapMutations({
      setStepUpNotification: types.MUTATE_SET_NOTIFICATION_STEP_UP,
    }),
    calculateBadges(notificationEventType) {
      let amount = 0;
      for (const notification of this.notifications) {
        if (
          notification.notificationEventType === notificationEventType &&
          notification.notificationArea === this.notificationAreas.DOCUMENTS &&
          notification.notificationType === this.notificationTypes.BADGE
        ) {
          amount++;
        }
      }
      return amount;
    },
    goToGoogleDrive() {
      window.open('https://drive.google.com/drive/my-drive');
    },
    goToOneDrive() {
      window.open('https://onedrive.live.com/about/da-dk/signin/');
    },
    onClickExternalService(url) {
      this.$refs.externalLinkWarningModal.show();
      this.externalServiceUrl = url;
    },
    openExternalLink() {
      window.open(this.externalServiceUrl);
    },
  },
  mounted() {
    if (
      !this.hasPermission(permissionEnum.ACCESS_SECURE_FILESHARING) &&
      !this.canSeeOneDrive &&
      !this.canSeeGoogleDrive &&
      this.profile.role !== portalRoles.GUARDIAN
    ) {
      this.$router.push({ name: 'documentsCommonList' });
    }
    setTimeout(function () {
      document.querySelector('.aula-documents-container .document-title').focus();
    }, 500);
  },
  components: {
    'badge-notification': BadgeNotification,
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
.aula-documents-container {
  .document-title {
    outline: none;
  }
  .linkBox {
    background-color: $color-grey-base;
    margin: 4px 2px;
    padding-top: 20px;
    @media only screen and (max-width: 780px) {
      margin: 10px auto;
      max-width: 330px;
    }
    cursor: pointer;
    .icons {
      width: 100px;
      margin: 0 auto;
      position: relative;
      .icon-Aula_folder {
        color: $color-grey-darker;
        font-size: 70px;
      }
      .icon-Aula_star_solid,
      .icon-Aula_lock,
      .icon-Aula_cloud,
      .icon-googledrive,
      .icon-onedrive {
        color: #fff;
        width: 46px;
        text-align: center;
        height: 46px;
        line-height: 36px;
        display: inline-block;
        border-radius: 50%;
        border: solid 4px $color-grey-base;
        position: absolute;
        right: 5px;
        bottom: -5px;
        font-size: 18px;
      }
    }
    h2 {
      padding-top: 20px;
      font-size: 16px;
      font-weight: 900;
      display: block;
    }
    .text {
      margin: 0 auto;
      padding: 0 5px;
      min-height: 66px;
      text-align: left;
    }
    .link {
      margin-top: 20px;
      color: #fff;
      padding: 0 30px;
      height: 62px;
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .icon-Aula_forward-arrow {
      margin-top: 3px;
    }
  }
}

.aula-documents-container {
  .linkBox {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .icons {
      .icon-Aula_lock,
      .icon-Aula_star_solid,
      .icon-Aula_cloud,
      .icon-googledrive,
      .icon-onedrive {
        .theme-guardian & {
          background-color: $color-primary-dark-guardian;
        }
        .theme-employee & {
          background-color: $color-primary-dark-employee;
        }
        .theme-child & {
          background-color: $color-primary-dark-child;
        }
      }
    }
    .link {
      .theme-guardian & {
        background-color: $color-primary-dark-guardian;
      }
      .theme-employee & {
        background-color: $color-primary-dark-employee;
      }
      .theme-child & {
        background-color: $color-primary-dark-child;
      }
    }
    &:hover {
      .link {
        .theme-guardian & {
          background-color: $color-primary-base-guardian;
        }
        .theme-employee & {
          background-color: $color-primary-base-employee;
        }
        .theme-child & {
          background-color: $color-primary-base-child;
        }
      }
      .icons {
        .icon-Aula_lock,
        .icon-Aula_star_solid,
        .icon-Aula_cloud {
          .theme-guardian & {
            background-color: $color-primary-base-guardian;
          }
          .theme-employee & {
            background-color: $color-primary-base-employee;
          }
          .theme-child & {
            background-color: $color-primary-base-child;
          }
        }
      }
    }
    /deep/ .badge-notification {
      left: 0px;
      top: 50px;
    }
  }
}
</style>
