export const GROUP_ADMINISTRATION_APPLICATIONS = 'Ansøgninger';
export const GROUP_ADMINISTRATION_APPROVE_APPLICATION = 'Godkend';
export const GROUP_ADMINISTRATION_REJECT_APPLICATION = 'Afvis';
export const GROUP_ADMINISTRATION_CHOSEN_APPLICATION = 'valgte';
export const GROUP_ADMINISTRATION_REMOVE_MEMBER = 'Fjern';
export const GROUP_ADMINISTRATION_SELECT_ALL = 'Vælg alle';
export const GROUP_ADMINISTRATION_DESELECT_ALL = 'Fravælg alle';
export const GROUP_ADMINISTRATION_NOT_ADDDED =
  'Brugeren/én af brugerne har fjernet sin ansøgning til gruppen og er derfor ikke blevet tilføjet';
export const GROUP_ADMINISTRATION_MEMBER = 'Medlemmer';
export const GROUP_ADMINISTRATION_MEMBER_NAME = 'Navn';
export const GROUP_ADMINISTRATION_MEMBER_ROLE = 'Rolle';
export const GROUP_ADMINISTRATION_MEMBER_ACTION = 'Handling';
export const GROUP_ADMINISTRATION_DESCRIPTION =
  'Her kan du se en oversigt over anmeldelser, ansøgninger og medlemmer af gruppen.';
export const GROUP_ADMINISTRATION_DESCRIPTION_2 =
  'Vær opmærksom på, at medlemmerne ikke automatisk har nogle rettigheder i gruppen. Du skal derfor kontakte institutionens administrator, der kan tildele de rettigheder, medlemmerne af gruppen skal have.';
export const GROUPS_LABEL = 'GRUPPER';
export const GROUPS_LABEL_CHILD = 'Grupper';
export const GROUP_CONFIRM_LEAVE = 'Forlad gruppen?';
export const GROUP_CONFIRM_CANCEL = 'Fortryd';
export const GROUP_LEAVE_INFO = 'Er du sikker på, at du vil forlade gruppen?';
export const GROUP_ADMINISTRATION_TITLE = 'ADMINISTRATION';
export const GROUP_MEMBERS_TITLE = 'MEDLEMMER';
export const GROUP_LEAVE_GROUP = 'Forlad gruppe';
export const GROUP_OTHERS = 'ØVRIGE';
export const GROUP_RETRIEVING_GROUP = 'Henter gruppe';
export const GROUP_SEARCH_ON_GROUP = 'Søg i denne gruppe';
export const ARIA_LABEL_GROUP_MEMBERS_OVERLAY = 'Medlemmer af {groupName}';
export const GROUP_EMPTY_1 = 'Der er ingen ';
export const GROUP_EMPTY_2 = ' i denne gruppe';
