<template>
  <ContactsFragmentNoResult v-if="contacts.length === 0" />
  <TableContainer v-else>
    <TableHead>
      <TableRow>
        <TableHeading class="color-grey-base" :collapse-directions="[collapseDirectionEnum.RIGHT]" />
        <TableHeading class="color-grey-base" :collapse-directions="[collapseDirectionEnum.LEFT]">
          <ContactsFragmentSorting
            :direction="orderDirection"
            :sort-by="contactSortingTypes.NAME"
            :sorting-by="sortBy"
            @sort="onSortBy"
          >
            {{ 'CONTACT_NAME' | fromTextKey }}
          </ContactsFragmentSorting>
        </TableHeading>
        <TableHeading class="color-grey-base">
          {{ 'CONTACT_WORK' | fromTextKey }}
        </TableHeading>
      </TableRow>
    </TableHead>
    <TableBody>
      <template v-for="contact in contacts">
        <TableRow :key="contact.id + '_' + contact.fullName">
          <TableCell class="color-grey-base profile-avatar-cell">
            <ContactAvatar :profile="contact" class="user-avatar" />
          </TableCell>
          <TableCell>
            {{ contact | displayProfileNameWithMetadata }}
          </TableCell>
          <TableCell>
            <ContactsFragmentDisplayProfilePhoneNumbers
              v-if="contact.userHasGivenConsentToShowContactInformation"
              :profile="contact"
            />
            <p v-else class="m-0 font-italic">
              {{ getNotAllowedToSeeContactMessage(contact) }}
            </p>
          </TableCell>
        </TableRow>
      </template>
    </TableBody>
  </TableContainer>
</template>
<script lang="js">
import TableCell from "../../libs/table/components/TableCell";
import TableBody from "../../libs/table/components/TableBody";
import ContactsFragmentSorting from "./ContactsFragmentSorting";
import TableHeading from "../../libs/table/components/TableHeading";
import TableRow from "../../libs/table/components/TableRow";
import TableHead from "../../libs/table/components/TableHead";
import TableContainer from "../../libs/table/components/TableContainer";
import {providerKeyTypes} from "../../enums/providerKeyTypes";
import {collapseDirectionEnum} from "../../libs/table/enums/collapseDirectionEnum";
import {contactSortingTypes} from "../../enums/contactSortingTypes";
import ContactsFragmentNoResult from "./ContactsFragmentNoResult";
import ContactsFragmentDisplayProfilePhoneNumbers from "./ContactsFragmentDisplayProfilePhoneNumbers";
import {textUtil} from "../../utils/textUtil";
import ContactAvatar from "../../../src/components/contacts/ContactAvatar.vue";

export default {
  name: 'ContactsFragmentDesktopContactListEmployees',
  components: {
    ContactAvatar,
    ContactsFragmentDisplayProfilePhoneNumbers,
    ContactsFragmentNoResult,
    TableCell,
    TableBody,
    ContactsFragmentSorting,
    TableHeading,
    TableRow,
    TableHead,
    TableContainer,
  },
  inject: {
    getContacts: providerKeyTypes.contacts,
    getOrderDirection: providerKeyTypes.orderDirection,
    getSortBy: providerKeyTypes.sortBy,
    changeSortBy: providerKeyTypes.onSortBy,
  },
  data() {
    return {
      collapseDirectionEnum,
      contactSortingTypes,
    }
  },
  computed: {
    contacts() {
      return this.getContacts();
    },
    orderDirection() {
      return this.getOrderDirection();
    },
    sortBy() {
      return this.getSortBy();
    },
  },
  methods: {
    onSortBy(sortBy) {
      this.changeSortBy(sortBy);
    },
    getShortName(institutionProfile) {
      return this.$options.filters.displayProfileInitials(institutionProfile);
    },
    getNotAllowedToSeeContactMessage(profile) {
      return `${textUtil.getGenitiveName(profile.firstName)} ${this.$options.filters.fromTextKey(
        'CONTACT_DOES_NOT_APPEAR_IN_AULA'
      )}`;
    },
  }
};
</script>
