import { render, staticRenderFns } from "./SicknessReports.vue?vue&type=template&id=86b550fc&scoped=true&"
import script from "./SicknessReports.vue?vue&type=script&lang=js&"
export * from "./SicknessReports.vue?vue&type=script&lang=js&"
import style0 from "./SicknessReports.vue?vue&type=style&index=0&id=86b550fc&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86b550fc",
  null
  
)

export default component.exports