<template>
  <div>
    <label :for="id"><slot name="select" /></label>
    <aula-select
      :id="id"
      class="border-0"
      v-bind="{ ...$props, ...$attrs }"
      @change="$emit('change', $event)"
      @input="$emit('input', $event)"
    >
      <template v-for="option in options"><slot v-bind="{ ...option }" name="option"></slot></template>
    </aula-select>
  </div>
</template>

<script>
export default {
  name: 'MessageAdvancedSearchSelect',
  props: {
    options: { type: Array, default: () => [] },
  },
  data() {
    return {
      id: null,
    };
  },
  mounted() {
    this.id = String(this._uid);
  },
};
</script>

<style lang="scss" scoped></style>
