<template>
  <div class="group-administration-container">
    <p class="page-layout-padding mb-0" data-layout="group-administration">
      {{ 'GROUP_ADMINISTRATION_DESCRIPTION' | fromTextKey }}<br />
      {{ 'GROUP_ADMINISTRATION_DESCRIPTION_2' | fromTextKey }}
    </p>
    <reported-content v-if="group.id == $route.params.groupId" :group="group" />
    <div class="group-applications">
      <template v-if="applicationItems.length > 0">
        <div v-if="!isMobile" class="application-actions">
          <b-btn size="sm" variant="link" @click="updateMultipleMemberships(groupMembershipRoles.MEMBER)">
            {{ 'GROUP_ADMINISTRATION_APPROVE_APPLICATION' | fromTextKey }}
            {{ 'GROUP_ADMINISTRATION_CHOSEN_APPLICATION' | fromTextKey }}
          </b-btn>
          <b-btn size="sm" variant="link" @click="updateMultipleMemberships(groupMembershipRoles.APPLICATIONREMOVED)">
            {{ 'GROUP_ADMINISTRATION_REJECT_APPLICATION' | fromTextKey }}
            {{ 'GROUP_ADMINISTRATION_CHOSEN_APPLICATION' | fromTextKey }}
          </b-btn>
        </div>
        <h3>{{ 'GROUP_ADMINISTRATION_APPLICATIONS' | fromTextKey }}:</h3>
        <aula-table
          :items="applicationItems"
          :fields="fields"
          table-ref="groupApplicationsTable"
          :responsive="isMobile"
          :parent="parentTypes.PORTAL"
          @updateMembership="updateMembership"
          @applicationCheckboxClicked="applicationCheckboxClicked"
          @toggleAllCheckboxes="toggleAllCheckboxes"
        />
        <b-alert
          variant="warning"
          :show="dismissCountDown"
          dismissible
          @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged"
        >
          {{ 'GROUP_ADMINISTRATION_NOT_ADDDED' | fromTextKey }}
        </b-alert>
        <div class="clearfix" />
      </template>
      <template v-if="groupMembers.length > 0">
        <h3>{{ 'GROUP_ADMINISTRATION_MEMBER' | fromTextKey }}:</h3>
        <aula-table
          :items="groupMembers"
          :striped="false"
          :total-rows="groupMembers.length"
          :per-page="20"
          :fields="memberFields"
          :parent="parentTypes.PORTAL"
          :responsive="isMobile"
          table-ref="groupMembersTable"
          :show-pagination="true"
          @updateMembership="updateMembership"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { types } from '../../store/types/types';
import { mapActions, mapGetters } from 'vuex';
import ReportedContent from '../../../shared/components/ReportedContent';
import AulaTable from '../../../shared/components/Table';
import Vue from 'vue';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import { groupTypes } from '../../../shared/enums/groupTypes';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import { groupMembershipRoles } from '../../../shared/enums/groupMembershipRoles';
import { groupAccessTypes } from '../../../shared/enums/groupAccessTypes';

export default {
  data: function () {
    return {
      parentTypes: parentTypes,
      permissionEnum: permissionEnum,
      groupMembershipRoles: groupMembershipRoles,
      selectedApplications: [],
      dismissCountDown: 0,
    };
  },
  computed: {
    ...mapGetters({
      group: types.GET_ACTIVE_GROUP,
      institutions: types.GET_INSTITUTIONS,
      groupMemberships: types.GET_GROUP_MEMBERSHIPS,
      profile: types.GET_CURRENT_PROFILE,
      applicationWasRemoved: types.GET_APPLICATION_WAS_REMOVED,
      hasPermission: types.HAS_PERMISSION,
      hasPermissionOnGroup: types.HAS_PERMISSION_ON_GROUP,
      isMobile: types.GET_IS_MOBILE,
    }),
    fields() {
      const fields = {
        profile: { label: 'Bruger', sortable: true },
        actions: { label: 'Handling', type: 'action' },
        checkboxes: {
          label: Vue.filter('fromTextKey')('TABLE_SELECT_ALL_FILTERS'),
          type: 'checkbox',
          showSelectAllInHeader: true,
          allSelected: false,
          selectedArrays: {
            applications: {
              selected: [],
            },
          },
        },
      };
      if (!this.hasPermissionOnGroup(permissionEnum.HANDLE_GROUP_APPLICATION, this.group.id)) {
        delete fields.actions;
        delete fields.checkboxes;
      }
      return fields;
    },
    memberFields() {
      const memberFields = {
        profile: {
          label: Vue.filter('fromTextKey')('GROUP_ADMINISTRATION_MEMBER_NAME'),
          sortable: true,
        },
        institutionRole: {
          label: Vue.filter('fromTextKey')('GROUP_ADMINISTRATION_MEMBER_ROLE'),
          sortable: true,
        },
        actions: {
          label: Vue.filter('fromTextKey')('GROUP_ADMINISTRATION_MEMBER_ACTION'),
          type: 'action',
        },
      };
      if (
        !this.hasPermissionOnGroup(permissionEnum.HANDLE_GROUP_APPLICATION, this.group.id) ||
        this.group.access.toLowerCase() != groupAccessTypes.APPLICATION ||
        (this.group.type.toLowerCase() != groupTypes.INSTITUTIONAL &&
          this.group.type.toLowerCase() != groupTypes.MUNICIPAL)
      ) {
        delete memberFields.actions;
      }
      return memberFields;
    },
    applicationItems() {
      const itemsArray = [];
      for (const obj of this.groupMemberships) {
        if (obj.groupRole == groupMembershipRoles.APPLIED) {
          const item = {
            profile: Vue.filter('displayProfileNameWithMetadata')(obj.institutionProfile),
            actions: [
              {
                variant: 'link',
                method: 'updateMembership',
                textkey: 'GROUP_ADMINISTRATION_APPROVE_APPLICATION',
                parameters: { groupRole: groupMembershipRoles.MEMBER },
              },
              {
                variant: 'link',
                method: 'updateMembership',
                textkey: 'GROUP_ADMINISTRATION_REJECT_APPLICATION',
                parameters: {
                  groupRole: groupMembershipRoles.APPLICATIONREMOVED,
                },
              },
            ],
            checkboxes: {
              method: 'applicationCheckboxClicked',
              checkboxType: 'applications',
            },
            itemId: obj,
          };
          itemsArray.push(item);
        }
      }
      return itemsArray;
    },
    groupMembers() {
      const itemsArray = [];
      for (const member of this.groupMemberships) {
        if (
          member.groupRole !== groupMembershipRoles.MEMBER ||
          this.institutions.some(inst => inst.institutionProfileId == member.institutionProfile?.id)
        ) {
          continue;
        }
        const isOtp = member.otpInboxId != null;
        const memberProfile = isOtp
          ? member.otpInboxMembershipDisplayName
          : Vue.filter('displayProfileNameWithMetadata')(member.institutionProfile);
        const memberInstitutionRole = isOtp
          ? Vue.filter('fromTextKey')('INSTITUTION_ROLE_OTP')
          : Vue.filter('fromTextKey')('INSTITUTION_ROLE_' + member.institutionRole.split('-').join('').toUpperCase());

        itemsArray.push({
          profile: memberProfile,
          institutionRole: memberInstitutionRole,
          actions: isOtp
            ? []
            : [
                {
                  variant: 'link',
                  method: 'updateMembership',
                  textkey: 'GROUP_ADMINISTRATION_REMOVE_MEMBER',
                  parameters: { groupRole: groupMembershipRoles.INACTIVE },
                },
              ],
          itemId: member,
        });
      }
      return itemsArray;
    },
  },
  methods: {
    ...mapActions({
      setMembership: types.SET_MEMBERSHIP,
      setApplicationWasRemoved: types.SET_APPLICATION_WAS_REMOVED,
    }),
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    toggleAllCheckboxes(allSelected) {
      if (allSelected) {
        this.selectedApplications = this.applicationItems.map(item => item.itemId);
        this.fields.checkboxes.allSelected = true;
        this.fields.checkboxes.selectedArrays.applications.selected = this.applicationItems.map(item => item.itemId);
      } else {
        this.selectedApplications = [];
        this.fields.checkboxes.allSelected = false;
        this.fields.checkboxes.selectedArrays.applications.selected = [];
      }
    },
    updateMembership(response) {
      const em = this;
      const memberId =
        response.item.itemId.institutionProfile != null ? response.item.itemId.institutionProfile.id : null;
      this.setMembership({
        groupRole: response.parameters.groupRole,
        groupId: em.group.id,
        memberId: memberId,
      })
        .then(function () {
          em.updateApplicationsTable(memberId, response.parameters.groupRole);
        })
        .catch(function (error) {
          if (error.response.data.errorMessage.includes('Application rem')) {
            em.dismissCountDown = 5;
            em.updateApplicationsTable(response.item.itemId, groupMembershipRoles.APPLICATIONREMOVED);
          }
        });
    },
    updateMultipleMemberships(groupRole) {
      for (const member of this.selectedApplications) {
        const memberId = member.institutionProfile != null ? member.institutionProfile.id : member.memberGroup.id;
        this.setMembership({
          groupRole: groupRole,
          groupId: this.group.id,
          memberId: memberId,
          id: member.id,
        })
          .then(() => {
            this.updateApplicationsTable(memberId, groupRole);
          })
          .catch(error => {
            if (error.response.data.errorMessage.includes('Application rem')) {
              this.dismissCountDown = 5;
              this.updateApplicationsTable(memberId, groupMembershipRoles.APPLICATIONREMOVED);
            }
          });
      }
      this.selectedApplications = [];
      this.fields.checkboxes.selectedArrays.applications.selected = [];
    },
    updateApplicationsTable(memberId, groupRole) {
      this.groupMemberships.forEach(function (membership) {
        if (membership.institutionProfile != null && membership.institutionProfile.id === memberId) {
          membership.groupRole = groupRole;
        }
      });
    },
    applicationCheckboxClicked(fields) {
      Vue.nextTick(() => {
        this.selectedApplications = fields.checkboxes.selectedArrays.applications.selected;
        this.fields.checkboxes.allSelected = this.selectedApplications.length === this.applicationItems.length;
      });
    },
  },
  components: {
    AulaTable,
    ReportedContent,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.group-search {
  position: absolute;
  top: 0px;
  right: 0px;
}

.application-actions {
  float: right;
  position: relative;

  .btn-link {
    text-decoration: underline;
  }
}
</style>
