<template>
  <div class="contact-master-data">
    <template v-if="isMobile">
      <h1 class="prd-title">
        {{ 'MASTER_DATA_INFORMATION_TITLE' | fromTextKey }}
      </h1>
      <div v-if="chosenQuestionInMobile != null" class="prd-header-mobile">
        <mobile-topbar
          left-icon="icon-Aula_app_arrow_slideback"
          :left-text="null"
          css-class="prd-view-mode-header"
          :text="mobileTitle"
          :header-text-from-textkey="false"
          :right-text-icon="null"
          :background-color="null"
          :color="null"
          @leftIconClicked="goBack()"
        />
        <div class="prd-sorter">
          <b-dropdown ref="sorter" class="dropdown-select filter-sorter mr-1">
            <template slot="button-content">
              {{ 'CONTACT_FILTER_AND_SORT' | fromTextKey }}
              <i class="icon icon-Aula_down-arrow" aria-hidden="true" />
            </template>
            <template v-for="(menu, i) in filterMenus">
              <b-dropdown-item
                v-if="showFilter(menu)"
                :key="i"
                :active="menu.filter === filterProfileType"
                :aria-selected="menu.filter === filterProfileType"
                @click="changeProfileType(menu.filter)"
              >
                {{ menu.name | fromTextKey }}
              </b-dropdown-item>
              <template v-for="(submenu, j) in subFilterMenus">
                <b-dropdown-item
                  v-if="menu.filter === contactsProfileTypeFilters.CHILD && childFilter.includes(filterProfileType)"
                  :key="'child' + j"
                  :active="submenu.filter === filterProfileType"
                  :aria-selected="submenu.filter === filterProfileType"
                  @click="changeProfileType(submenu.filter)"
                >
                  {{ submenu.name | fromTextKey }}
                </b-dropdown-item>
              </template>
            </template>
            <b-dropdown-divider />
            <b-dropdown-item
              :active="sortBy === 'displayname'"
              :aria-selected="sortBy === 'displayname'"
              @click="doSort('displayname')"
            >
              {{ 'SORT_BY_ALPHABET' | fromTextKey }}
            </b-dropdown-item>
            <b-dropdown-item
              :active="sortBy === 'answers'"
              :aria-selected="sortBy === 'answers'"
              @click="doSort('answers')"
            >
              {{ 'SORT_BY_ANSWER' | fromTextKey }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </template>
    <div
      :class="{
        'contacts-group-filter': !isMobile,
        'prd-filter-mobile': isMobile,
      }"
    >
      <b-row class="ml-0">
        <b-col v-if="filteredInstitutions.length > 1" cols="12" md="3" lg="2" :class="{ 'pl-0': !isMobile }">
          <label v-if="isMobile" class="mt-4">{{ 'PERSONAL_REFERENCE_DATA_CHOOSE_INSTITUTION' | fromTextKey }}</label>
          <aula-select v-model="institutionCode" class="institution-selector" @change="changeInstitution">
            <aula-option
              v-for="(institution, i) in filteredInstitutions"
              :key="i"
              :value="institution.institutionCode"
              :label="institution.name"
            />
          </aula-select>
        </b-col>
        <b-col cols="12" md="4" lg="4" :class="{ 'pr-0 pl-0': !isMobile }">
          <label v-if="isMobile" class="mt-4">{{ 'PERSONAL_REFERENCE_DATA_CHOOSE_USER_GROUP' | fromTextKey }}</label>
          <aula-search-recipients
            ref="personalReferenceDataUserOrGroup"
            :multiple="true"
            :multiple-limit="1"
            :split-groups-participants="false"
            url="findRecipientsPersonalReferenceData"
            :scope-employees-to-institution="true"
            placeholder-textkey="ARIA_LABEL_USER_GROUP"
            :members-only="true"
            :reset-search-input="resetSearch"
            :alternative-group-sort="true"
            from-module="personalReferenceData"
            :institution-code="institutionCode"
            :close-search-result="closeSearchResult"
            :split-my-groups-and-other-groups="
              hasPermission(permissionEnum.VIEW_PERSONAL_REFERENCE_DATA_FOR_ALL_CHILDREN_AND_GUARDIAN)
            "
            :select-all="false"
            :existing-participants="selectedUsersAndGroups"
            class="search-user-group"
            @emitSelected="chooseUserGroup"
          />
        </b-col>
        <b-col cols="12" md="5" lg="4">
          <label v-if="isMobile" class="mt-4">{{ 'PERSONAL_REFERENCE_DATA_CHOOSE_QUESTION' | fromTextKey }}</label>
          <aula-spinner v-if="isQuestionLoading && questions != null" class="loading-master-data" />
          <aula-select
            v-else
            v-model="viewQuestion"
            filterable
            :disabled="!institutionProfileId && !groupId"
            :no-data-text="Vue.filter('fromTextKey')('NO_CONSENT_TO_SHOW')"
            class="master-data-selection"
            :aria-label="'ARIA_LABEL_PERSONAL_REFERENCE_DATA_CHOOSE_QUESTION' | fromTextKey"
            @input="selectQuestion"
          >
            <el-option-group
              v-if="questions != null && questions.permissions != null && questions.permissions.length > 0"
              :label="'MASTER_DATA_PERMISSION' | fromTextKey"
            >
              <aula-option
                v-for="(permission, i) in questions.permissions"
                :key="'permission-' + i"
                :label="permission.question"
                :value="'permission-' + permission.id"
              />
            </el-option-group>
            <el-option-group
              v-if="questions != null && questions.additionalData != undefined && questions.additionalData.length > 0"
              :label="'MASTER_DATA_ADDITIONAL_DATA' | fromTextKey"
            >
              <aula-option
                v-for="(additional, i) in questions.additionalData"
                :key="'additional-' + i"
                :label="additional.question"
                :value="'additional-' + additional.id"
              />
            </el-option-group>
            <el-option-group
              v-if="questions != null && questions.consents != undefined && questions.consents.length > 0"
              :label="'MASTER_DATA_CONSENTS' | fromTextKey"
            >
              <aula-option
                v-for="(consent, i) in questions.consents"
                :key="'consent-' + i"
                :label="consent.title"
                :value="'consent-' + consent.id"
              />
            </el-option-group>
          </aula-select>
        </b-col>
      </b-row>
    </div>
    <div id="answerList" :class="{ 'contacts-container page-layout-padding': !isMobile, 'prd-body-mobile': isMobile }">
      <template v-if="!isMobile">
        <div class="contact-header">
          <h1>{{ 'MASTER_DATA_INFORMATION_TITLE' | fromTextKey }}</h1>
          <h2 class="mt-5 mb-4">
            {{ getChosenQuestion() }}
          </h2>
        </div>
        <div class="config-sections">
          <ul class="nav mt-3">
            <template v-if="profile.role !== portalRoles.CHILD">
              <template v-for="(menu, i) in filterMenus">
                <li v-if="showFilter(menu)" :key="i" class="nav-item">
                  <div
                    class="nav-link text-uppercase"
                    tabindex="0"
                    role="button"
                    :class="{ active: menu.filter == filterProfileType }"
                    :aria-selected="menu.filter == filterProfileType"
                    @click="changeProfileType(menu.filter)"
                    @keydown.enter="changeProfileType(menu.filter)"
                  >
                    <span :aria-label="menu.label | fromTextKey" class="sr-only" />
                    <span aria-hidden="true">{{ menu.name | fromTextKey }}</span>
                  </div>
                </li>
                <template v-for="(submenu, j) in subFilterMenus">
                  <li
                    v-if="
                      isGroupChosen &&
                      menu.filter == contactsProfileTypeFilters.CHILD &&
                      childFilter.includes(filterProfileType)
                    "
                    :key="'sub' + j"
                    class="nav-item"
                  >
                    <div
                      class="nav-link text-uppercase"
                      tabindex="0"
                      role="button"
                      :class="{ active: submenu.filter == filterProfileType }"
                      :aria-selected="submenu.filter == filterProfileType"
                      @click="changeProfileType(submenu.filter)"
                      @keydown.enter="changeProfileType(submenu.filter)"
                    >
                      <span :aria-label="submenu.label | fromTextKey" class="sr-only" />
                      <span aria-hidden="true">{{ submenu.name | fromTextKey }}</span>
                    </div>
                  </li>
                </template>
              </template>
            </template>
            <li class="right" />
            <li class="dropdown">
              <b-dropdown ref="sorter" class="dropdown-select sorter ml-5">
                <template slot="button-content">
                  {{ 'SORT_BY' | fromTextKey }}
                  <i class="icon icon-Aula_down-arrow" aria-hidden="true" />
                </template>
                <b-dropdown-item :active="sortBy == 'answers'" @click="doSort('answers')">
                  {{ 'SORT_BY_ANSWER' | fromTextKey }}
                </b-dropdown-item>
                <b-dropdown-item :active="sortBy == 'displayname'" @click="doSort('displayname')">
                  {{ 'SORT_BY_ALPHABET' | fromTextKey }}
                </b-dropdown-item>
              </b-dropdown>
            </li>
          </ul>
        </div>
        <div class="contacts-body">
          <aula-spinner v-if="isAnswerLoading" />
          <template v-else>
            <answer
              v-if="getPersonalReferenceDataAnswer.length > 0"
              :question="chosenReferenceData"
              :profile-type="filterProfileType"
              :is-loading-more="isLoadingMore"
            />
            <div v-else>
              {{ getNoResultMessage() }}
            </div>
          </template>
        </div>
      </template>
      <div v-else-if="chosenQuestionInMobile != null" class="prd-answer-mobile">
        <aula-spinner v-if="isAnswerLoading" />
        <template v-else>
          <answer
            v-if="getPersonalReferenceDataAnswer.length > 0"
            :question="chosenReferenceData"
            :profile-type="filterProfileType"
            :is-loading-more="isLoadingMore"
          />
          <div v-else class="ml-2 mt-4">
            {{ getNoResultMessage() }}
          </div>
        </template>
      </div>
    </div>
    <aula-modal
      ref="noPermission"
      :show-cancel="false"
      ok-text="OK"
      @okClicked="$refs.noPermission.hide(), $router.push({ path: '/overblik' })"
    >
      {{ 'NO_PERMISSION_TO_VIEW_ADDITIONAL_OR_CONSENT' | fromTextKey }}
    </aula-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { types } from '../../store/types/types';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import { contactsProfileTypeFilters } from '../../../shared/enums/contactsProfileTypeFilters';
import { docTypes } from '../../../shared/enums/docTypes';
import MobileTopbar from '../../../shared/components/MobileTopbar';
import AulaSearchRecipients from '../../../shared/components/AulaSearchRecipients';
import Answer from './Answer';
import Vue from 'vue';
import $ from 'jquery';

export default {
  data: function () {
    return {
      Vue: Vue,
      contactsProfileTypeFilters: contactsProfileTypeFilters,
      docTypes: docTypes,
      institutionCode: null,
      isLeader: null,
      institutionProfileId: null,
      resetInput: false,
      resetSearch: false,
      groupId: null,
      isGroupChosen: false,
      questions: null,
      userGroup: null,
      isQuestionLoading: false,
      isAnswerLoading: false,
      portalRoles: portalRoles,
      permissionEnum: permissionEnum,
      filterProfileType: contactsProfileTypeFilters.CHILD,
      childFilter: [contactsProfileTypeFilters.CHILD, contactsProfileTypeFilters.BOY, contactsProfileTypeFilters.GIRL],
      filterMenus: [
        {
          name: 'ALL_CHILDREN',
          label: 'ARIA_LABEL_ALL_CHILDREN',
          filter: contactsProfileTypeFilters.CHILD,
        },
        {
          name: 'CONTACT_FILTER_GUARDIAN',
          label: 'ARIA_LABEL_PARENTS',
          filter: contactsProfileTypeFilters.GUARDIAN,
        },
        {
          name: 'EMPLOYEE',
          label: 'ARIA_LABEL_EMPLOYEE',
          filter: contactsProfileTypeFilters.EMPLOYEE,
        },
      ],
      subFilterMenus: [
        {
          name: 'BOYS',
          label: 'ARIA_LABEL_BOYS',
          filter: contactsProfileTypeFilters.BOY,
        },
        {
          name: 'GIRLS',
          label: 'ARIA_LABEL_GIRLS',
          filter: contactsProfileTypeFilters.GIRL,
        },
      ],
      viewQuestion: null,
      chosenQuestionInMobile: null,
      chosenReferenceData: {},
      isLoading: true,
      sortBy: 'answers',
      order: 'asc',
      additionalDataId: null,
      consentId: null,
      currentPage: 1,
      mobileTitle: null,
      isLoadingMore: false,
      closeSearchResult: false,
      originalProfilePortalRole: null,
      selectedUsersAndGroups: [],
    };
  },
  computed: {
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
      profile: types.GET_CURRENT_PROFILE,
      hasMoreAnswer: types.GET_HAS_MORE_ANSWER,
      institutions: types.GET_INSTITUTIONS,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
      isPreviewMode: types.GET_IS_PREVIEW_MODE,
      isSteppedUp: types.GET_GLOBAL_STEPPED_UP,
      getPersonalReferenceDataQuestion: types.GET_PERSONAL_REFERENCE_DATA_QUESTION,
      getPersonalReferenceDataAnswer: types.GET_PERSONAL_REFERENCE_DATA_ANSWER,
      hasPermissionInstitution: types.HAS_PERMISSION_ON_INSTITUTION,
      hasPermission: types.HAS_PERMISSION,
    }),
    isProfileSwitched() {
      return this.originalProfilePortalRole != this.profile.role;
    },
    filteredInstitutions() {
      return this.institutions.filter(i => this.activeInstitutions.includes(i.institutionCode));
    },
  },
  methods: {
    ...mapActions({
      loadPersonalReferenceDataQuestion: types.LOAD_PERSONAL_REFERENCE_DATA_QUESTION,
      loadPersonalReferenceDataAnswer: types.LOAD_PERSONAL_REFERENCE_DATA_ANSWER,
    }),
    ...mapMutations({
      resetPersonalReferenceDataAnswer: types.MUTATE_PERSONAL_REFERENCE_DATA_ANSWER,
      setStepUpNotification: types.MUTATE_SET_NOTIFICATION_STEP_UP,
    }),
    getChosenQuestion() {
      let question = '';
      if (this.chosenReferenceData.type != null) {
        switch (this.chosenReferenceData.type) {
          case 'consent':
            question =
              this.$options.filters.fromTextKey('MASTER_DATA_CONSENTS') + ': ' + this.chosenReferenceData.title;
            break;
          case 'permission':
            question =
              this.$options.filters.fromTextKey('MASTER_DATA_PERMISSION') + ': ' + this.chosenReferenceData.question;
            break;
          case 'additional':
            question =
              this.$options.filters.fromTextKey('MASTER_DATA_ADDITIONAL_DATA') +
              ': ' +
              this.chosenReferenceData.question;
            break;
        }
      }
      return question;
    },
    showFilter(menu) {
      if (this.isGroupChosen) {
        return (
          menu.filter != this.contactsProfileTypeFilters.EMPLOYEE ||
          (menu.filter == this.contactsProfileTypeFilters.EMPLOYEE && this.isLeader)
        );
      } else {
        return menu.filter == this.filterProfileType;
      }
    },
    resetQuestion() {
      this.resetPersonalReferenceDataAnswer({ data: [] });
      this.questions = null;
      this.viewQuestion = null;
      this.additionalDataId = null;
      this.chosenReferenceData = {};
    },
    resetSelectedUsersAndGroups() {
      this.filterProfileType = contactsProfileTypeFilters.CHILD;
      this.selectedUsersAndGroups = [];
    },
    getNoResultMessage() {
      const profileType = Vue.filter('fromTextKey')('CONTACT_' + this.filterProfileType.toUpperCase());
      return Vue.filter('fromTextKey')('MASTER_DATA_NO_RESULTS', {
        profileType: profileType,
      });
    },
    goBack() {
      this.chosenQuestionInMobile = null;
    },
    chooseUserGroup(userGroup) {
      this.isGroupChosen = false;
      if (userGroup != null && userGroup.length == 1 && userGroup[0].type != null) {
        if (userGroup[0].type == this.docTypes.PROFILE.toLowerCase()) {
          this.institutionProfileId = parseInt(userGroup[0].id);
          this.groupId = null;
          this.filterProfileType = userGroup[0].portalRole;
        } else {
          this.institutionProfileId = null;
          this.groupId = parseInt(userGroup[0].id);
          this.filterProfileType = this.contactsProfileTypeFilters.CHILD;
          this.isGroupChosen = true;
        }
        const params = {
          institutionProfileId: this.institutionProfileId,
          groupId: this.groupId,
        };
        this.isQuestionLoading = true;
        this.resetQuestion();
        if (
          this.hasPermission(permissionEnum.VIEW_USERS_ADDITIONAL_DATA) ||
          this.hasPermission(permissionEnum.VIEW_USERS_CONSENTS)
        ) {
          this.loadPersonalReferenceDataQuestion(params).then(() => {
            this.isQuestionLoading = false;
            this.splitQuestionByType();
          });
        } else {
          this.$refs.noPermission.show();
        }
      } else {
        this.resetQuestion();
        this.institutionProfileId = null;
        this.groupId = null;
      }
    },
    selectQuestion() {
      const chosenQuestionId = this.viewQuestion.split('-');
      if (chosenQuestionId.length == 2) {
        this.chosenQuestionInMobile = this.viewQuestion;
        chosenQuestionId[1] = parseInt(chosenQuestionId[1]);
        let chosenQuestions = [];
        if (chosenQuestionId[0] == 'consent') {
          this.mobileTitle = this.$options.filters.fromTextKey('MASTER_DATA_CONSENTS');
          this.consentId = chosenQuestionId[1];
          this.additionalDataId = null;
          chosenQuestions = this.questions.consents.filter(c => c.id == chosenQuestionId[1]);
        } else if (chosenQuestionId[0] == 'additional') {
          this.consentId = null;
          this.additionalDataId = chosenQuestionId[1];
          chosenQuestions = this.questions.additionalData.filter(a => a.id == chosenQuestionId[1]);
          this.mobileTitle = this.$options.filters.fromTextKey('MASTER_DATA_ADDITIONAL_DATA');
        } else if (chosenQuestionId[0] == 'permission') {
          this.consentId = null;
          this.additionalDataId = chosenQuestionId[1];
          chosenQuestions = this.questions.permissions.filter(a => a.id == chosenQuestionId[1]);
          this.mobileTitle = this.$options.filters.fromTextKey('MASTER_DATA_PERMISSION');
        }
        if (chosenQuestions.length > 0) {
          this.chosenReferenceData = chosenQuestions[0];
          this.chosenReferenceData.type = chosenQuestionId[0];
          this.loadAnswer();
        }
      }
    },
    changeProfileType(profileType) {
      this.filterProfileType = profileType;
      this.loadAnswer();
    },
    loadAnswer(isLoadMore = false) {
      if (
        (this.institutionProfileId != null || this.groupId != null) &&
        (this.additionalDataId != null || this.consentId) &&
        this.filterProfileType != null
      ) {
        if (isLoadMore) {
          this.currentPage++;
          this.isLoadingMore = true;
        } else {
          this.currentPage = 1;
          this.isAnswerLoading = true;
        }
        const params = {
          institutionProfileId: this.institutionProfileId,
          groupId: this.groupId,
          additionalDataId: this.additionalDataId,
          consentId: this.consentId,
          filter: this.filterProfileType,
          page: this.currentPage,
          sorting: this.sortBy,
          isLoadMore: isLoadMore,
        };

        this.loadPersonalReferenceDataAnswer(params).then(() => {
          this.isAnswerLoading = false;
          this.isLoadingMore = false;
        });
      }
    },
    changeInstitution() {
      this.isLeader = false;
      for (const institution of this.institutions) {
        if (
          this.institutionCode == institution.institutionCode &&
          this.hasPermissionInstitution(permissionEnum.VIEW_EMPLOYEES_ADDITIONAL_DATA, institution.institutionCode)
        ) {
          this.isLeader = true;
        }
      }
      this.resetInput = !this.resetInput;
      this.resetQuestion();
      this.resetSelectedUsersAndGroups();
    },
    reset() {
      this.institutionProfileId = null;
      this.groupId = null;
      this.currentPage = 1;
      this.viewQuestion = null;
      this.closeSearchResult = !this.closeSearchResult;
      this.resetSearch = !this.resetSearch;
      this.resetSelectedUsersAndGroups();
    },
    splitQuestionByType() {
      let permissions = [];
      let additionalData = [];
      const consents =
        this.getPersonalReferenceDataQuestion.consentList != null &&
        this.getPersonalReferenceDataQuestion.consentList.length > 0
          ? this.getPersonalReferenceDataQuestion.consentList
          : [];
      if (
        this.getPersonalReferenceDataQuestion.combinedAdditionalDataList != null &&
        this.getPersonalReferenceDataQuestion.combinedAdditionalDataList.length > 0
      ) {
        permissions = this.getPersonalReferenceDataQuestion.combinedAdditionalDataList.filter(
          a => a.permission == true
        );
        additionalData = this.getPersonalReferenceDataQuestion.combinedAdditionalDataList.filter(
          a => a.permission == false
        );
      }
      permissions.sort((p1, p2) => (p1.question > p2.question ? 1 : -1));
      additionalData.sort((p1, p2) => (p1.question > p2.question ? 1 : -1));
      consents.sort((c1, c2) => (c1.title > c2.title ? 1 : -1));
      this.questions = {
        permissions: permissions,
        additionalData: additionalData,
        consents: consents,
      };
    },
    doSort(sort) {
      if (this.sortBy == sort) {
        this.order = this.order == 'asc' ? 'desc' : 'asc';
      } else {
        this.sortBy = sort;
        this.order = 'asc';
      }
      this.loadAnswer();
    },
    setDefaultInstitutionCode() {
      if (this.activeInstitutions.includes(this.institutionCode) === false) {
        this.institutionCode = this.filteredInstitutions[0].institutionCode;
      }
    },
    init() {
      this.resetPersonalReferenceDataAnswer({ data: [] });
      if (this.filteredInstitutions.length > 0) {
        if (!this.isSteppedUp) {
          this.setStepUpNotification({
            showStepUpNotification: true,
            redirectedUrl: window.location.href,
            cancelUrl: '/overblik',
          });
        } else {
          if (this.filteredInstitutions.length > 0) {
            this.isLeader = false;
            let hasPermissionToAccess = false;
            this.setDefaultInstitutionCode();
            for (const institution of this.filteredInstitutions) {
              if (
                this.hasPermissionInstitution(permissionEnum.VIEW_USERS_ADDITIONAL_DATA, institution.institutionCode) ||
                this.hasPermissionInstitution(permissionEnum.VIEW_USERS_CONSENTS, institution.institutionCode)
              ) {
                hasPermissionToAccess = true;
              }
              if (
                this.hasPermissionInstitution(
                  permissionEnum.VIEW_EMPLOYEES_ADDITIONAL_DATA,
                  institution.institutionCode
                )
              ) {
                if (!this.isLeader) {
                  this.institutionCode = institution.institutionCode;
                }
                this.isLeader = true;
              }
            }
            if (!hasPermissionToAccess) {
              this.$refs.noPermission.show();
            }
          }
        }
      }
    },
    handleScroll() {
      if (this.hasMoreAnswer && !this.isLoadingMore) {
        const scrollDiv = $('#answerList');
        if (!this.isMobile) {
          const scrollHeight = $(window).scrollTop() + $(window).height();
          const contentHeight = scrollDiv.offset().top + scrollDiv.height();
          if (scrollHeight >= contentHeight) {
            this.loadAnswer(true);
          }
        } else if (this.chosenQuestionInMobile != null) {
          const scrollHeight = $(window).scrollTop() + $(window).height();
          if (scrollHeight >= scrollDiv.height() && scrollDiv.height() + 60 > $(window).height()) {
            this.loadAnswer(true);
          }
        }
      }
    },
  },
  watch: {
    institutions() {
      if (!this.isProfileSwitched) {
        this.init();
      }
    },
    activeInstitutions() {
      if (!this.isProfileSwitched) {
        this.reset();
        this.init();
      }
    },
  },
  created() {
    this.originalProfilePortalRole = this.profile.role;
  },
  mounted() {
    this.init();
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  components: {
    Answer,
    MobileTopbar,
    AulaSearchRecipients,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables';
@import '../../../shared/assets/scss/core/breakpoints';
@import '../../../shared/assets/scss/components/personalreferencedata/personalReferenceData';
</style>
