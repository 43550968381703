var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.canViewParentCustody)?_c('AulaSwitchButton',{staticClass:"my-4 d-print-none",model:{value:(_vm.showParentCustody),callback:function ($$v) {_vm.showParentCustody=$$v},expression:"showParentCustody"}},[_vm._v("\n    "+_vm._s(_vm._f("fromTextKey")('CONTACT_SHOW_PARENT_CUSTODY'))+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.contacts.length === 0)?_c('ContactsFragmentNoResult'):_c('TableContainer',[_c('TableHead',[_c('TableRow',[_c('TableHeading',{staticClass:"color-grey-base",attrs:{"collapse-directions":[_vm.collapseDirectionEnum.RIGHT]}}),_vm._v(" "),_c('TableHeading',{staticClass:"color-grey-base",attrs:{"collapse-directions":[_vm.collapseDirectionEnum.LEFT]}},[_c('ContactsFragmentSorting',{attrs:{"direction":_vm.orderDirection,"sort-by":_vm.contactSortingTypes.NAME,"sorting-by":_vm.sortBy},on:{"sort":_vm.onSortBy}},[_vm._v("\n            "+_vm._s(_vm._f("fromTextKey")('CONTACT_NAME'))+"\n          ")])],1),_vm._v(" "),_c('TableHeading',{staticClass:"color-grey-base"},[_vm._v("\n          "+_vm._s(_vm._f("fromTextKey")('CONTACT_CONTACT'))+"\n        ")]),_vm._v(" "),_c('TableHeading',{staticClass:"color-grey-base address-cell"},[_vm._v("\n          "+_vm._s(_vm._f("fromTextKey")('CONTACT_ADDRESS'))+"\n        ")]),_vm._v(" "),_c('TableHeading',{staticClass:"color-grey-base"},[_vm._v("\n          "+_vm._s(_vm._f("fromTextKey")('CONTACT_PHONE'))+"\n        ")]),_vm._v(" "),_c('TableHeading',{staticClass:"color-grey-base"},[_vm._v("\n          "+_vm._s(_vm._f("fromTextKey")('CONTACT_EMAIL'))+"\n        ")])],1)],1),_vm._v(" "),_c('TableBody',[_vm._l((_vm.contacts),function(contact,contactIndex){return [_vm._l((_vm.contactRelations[contactIndex]),function(relation,relationIndex){return [_c('TableRow',{key:contact.id +
              '_' +
              contact.fullName +
              '_' +
              relation.role +
              '_' +
              relation.fullName +
              '_' +
              relationIndex,attrs:{"collapse-directions":_vm.getBorderCollapseDirections(relationIndex, contact),"e2e":contact.fullName}},[(relationIndex === 0)?_c('TableCell',{staticClass:"color-grey-base profile-avatar-cell",attrs:{"collapse-directions":[],"rowspan":_vm.getRowSpan(contact)}},[_c('ContactAvatar',{staticClass:"user-avatar",attrs:{"profile":contact}})],1):_vm._e(),_vm._v(" "),(relationIndex === 0)?_c('TableCell',{attrs:{"collapse-directions":[],"rowspan":_vm.getRowSpan(contact)}},[_vm._v("\n              "+_vm._s(_vm._f("displayProfileNameWithMetadata")(contact))+"\n            ")]):_vm._e(),_vm._v(" "),_c('TableCell',[_c('ContactsFragmentDisplayProfileRelationName',{attrs:{"profile":relation,"show-parent-custody":_vm.showParentCustody}})],1),_vm._v(" "),_c('TableCell',[(relation.userHasGivenConsentToShowContactInformation && relation.address != null)?_c('address',[_vm._v("\n                "+_vm._s(relation.address.street)+",\n                "),_c('br'),_vm._v("\n                "+_vm._s(relation.address.postalCode)+"\n                "+_vm._s(relation.address.postalDistrict)+"\n              ")]):_vm._e()]),_vm._v(" "),_c('TableCell',[(relation.userHasGivenConsentToShowContactInformation)?_c('ContactsFragmentDisplayProfilePhoneNumbers',{attrs:{"profile":relation}}):_vm._e()],1),_vm._v(" "),_c('TableCell',[(relation.userHasGivenConsentToShowContactInformation && relation.email)?_c('a',{staticClass:"color-primary-darker",attrs:{"href":'mailto:' + relation.email}},[_vm._v("\n                "+_vm._s(relation.email)+"\n              ")]):_vm._e()])],1)]})]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }