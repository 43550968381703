

























































import { mediaTypes } from '../../enums/mediaTypes';
import { conversionStatusTypes } from '../../enums/conversionStatusTypes';
import { parentTypes } from '../../enums/parentTypes';
import AulaCard from '../../components/AulaCard.vue';
import { PropType } from 'vue';
import { MediaModel } from '../../models/media.model';
import Icon from '../../components/Icon.vue';
import { iconClassEnum } from '../../enums/iconClassEnum';
import VideoThumbnail from './VideoThumbnail.vue';
import AulaButton from '../../components/AulaButton.vue';
import SoundThumbnail from './SoundThumbnail.vue';
import ImageThumbnail from './ImageThumbnail.vue';

export default {
  name: 'MediaCard',
  components: { ImageThumbnail, SoundThumbnail, AulaButton, VideoThumbnail, Icon, AulaCard },
  props: {
    media: { type: Object as PropType<MediaModel>, default: null },
    showSelectBox: { type: Boolean, default: false },
    canDeleteMedia: { type: Boolean, default: false },
    showDeleteMedia: { type: Boolean, default: true },
  },
  data: function () {
    return {
      conversionStatusTypes,
      mediaTypes,
      parentTypes,
    };
  },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
    isChecked() {
      return this.media.selected;
    },
  },
  methods: {
    onDeleteMediaClick() {
      this.$emit('onDeleteMediaClick');
    },
  },
};
