<template>
  <div class="avatar">
    <AulaImg v-if="imgUrl" class="profile-image" :img-url="imgUrl" />
    <div v-else class="profile-initials">
      {{ initials }}
    </div>
  </div>
</template>
<script>
import AulaImg from './AulaImg';
export default {
  name: 'AulaAvatar',
  components: { AulaImg },
  props: {
    imgUrl: {
      type: String,
      default: () => '',
      validator(url) {
        const isValid = typeof url === 'string' && url.trim().length > 0;
        if (!isValid) {
          // eslint-disable-next-line no-console
          console.trace(`imgUrl is not valid in AulaAvatar component`);
        }
        return isValid;
      },
    },
    initials: {
      type: String,
      default: () => '',
      validator(initials) {
        const isValid = typeof initials === 'string';
        if (!isValid) {
          // eslint-disable-next-line no-console
          console.trace(`initials is not valid in AulaAvatar component`);
        }

        return isValid;
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.avatar {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-grey-base);
}

.profile-initials {
  text-transform: uppercase;
}

.profile-image {
  object-fit: contain;
  height: 100%;
  width: 100%;
}
</style>
