<template>
  <div class="app-settings page-layout-padding">
    <h1 class="page-title margin-bottom-20" tabindex="0">
      {{ 'APP_SETTINGS_LABEL' | fromTextKey }}
    </h1>
    {{ 'APP_SETTINGS_SUBLABEL' | fromTextKey }}
    <h2 v-if="multipleDevices">
      {{ 'APP_EMPLOYEE' | fromTextKey }}
    </h2>
    <template v-for="(device, index) in devicesEmployee">
      <div :key="index">
        <hr />
        <b-row>
          <b-col cols="9">
            <div v-if="device.description != null" class="description">
              {{ device.description }}
            </div>
            <div v-else class="description">
              {{ 'APP_UNKNOWN_DEVICE' | fromTextKey }}
            </div>
            {{ 'APP_LAST_LOGIN' | fromTextKey }}
            {{ device.lastActivity | longDate(true, true) }}
          </b-col>
        </b-row>
      </div>
    </template>
    <hr v-if="multipleDevices" />
    <h2 v-if="multipleDevices">
      {{ 'APP_PRIVATE' | fromTextKey }}
    </h2>
    <template v-for="(device, index) in devicesPrivate">
      <div :key="index">
        <hr />
        <b-row>
          <b-col cols="9">
            <div v-if="device.description != null" class="description">
              {{ device.description }}
            </div>
            <div v-else class="description">
              {{ 'APP_UNKNOWN_DEVICE' | fromTextKey }}
            </div>
            {{ 'APP_LAST_LOGIN' | fromTextKey }}
            {{ device.lastActivity | longDate(true, true) }}
          </b-col>
        </b-row>
      </div>
    </template>
    <div v-if="devicesEmployee.length + devicesPrivate.length > 0">
      <hr />
      <br />
      <b-btn @click="$refs.cancelModal.show()">
        {{ 'APP_REMOVE_ALL' | fromTextKey }}
      </b-btn>
    </div>
    <div v-if="devicesEmployee.length + devicesPrivate.length == 0">
      {{ 'APP_NO_DEVICES' | fromTextKey }}
    </div>
    <aula-modal
      ref="cancelModal"
      ok-text="BUTTON_YES"
      @cancelClicked="$refs.cancelModal.hide()"
      @okClicked="unregisterAll()"
    >
      {{ 'APP_MODAL_CONFIRM_1' | fromTextKey }}<br />
      {{ 'APP_MODAL_CONFIRM_2' | fromTextKey }}
    </aula-modal>
  </div>
</template>

<script>
import { types } from '../../store/types/types';
import { mapGetters, mapActions } from 'vuex';

export default {
  data: function () {
    return {
      devicesEmployee: {},
      devicesPrivate: {},
    };
  },
  computed: {
    ...mapGetters({
      notificationSettings: types.GET_NOTIFICATION_SETTINGS,
      isMobile: types.GET_IS_MOBILE,
    }),
    multipleDevices() {
      return this.devicesPrivate.length > 0 && this.devicesEmployee.length > 0;
    },
  },
  methods: {
    ...mapActions({
      getNotificationSetting: types.SET_NOTIFICATION_SETTINGS,
      unregisterAllDevices: types.UNREGISTER_ALL,
    }),
    initAppSetting() {
      this.getNotificationSetting().then(() => {
        this.devicesPrivate = this.notificationSettings.deviceList.filter(item => item.appType == 'Private');
        this.devicesPrivate.sort(function (device1, device2) {
          const deviceName1 = device1.description === null ? '' : device1.description.toLowerCase();
          const deviceName2 = device2.description === null ? '' : device2.description.toLowerCase();
          return deviceName1 > deviceName2 ? 1 : -1;
        });
        this.devicesEmployee = this.notificationSettings.deviceList.filter(item => item.appType == 'Staff');
        this.devicesEmployee.sort(function (device1, device2) {
          const deviceName1 = device1.description === null ? '' : device1.description.toLowerCase();
          const deviceName2 = device2.description === null ? '' : device2.description.toLowerCase();
          return deviceName1 > deviceName2 ? 1 : -1;
        });
      });
    },
    unregisterAll() {
      this.$refs.cancelModal.hide();
      this.unregisterAllDevices().then(() => {
        this.initAppSetting();
      });
    },
  },
  mounted() {
    setTimeout(function () {
      document.querySelector('.app-settings .page-title').focus();
    }, 500);
  },
  created() {
    this.initAppSetting();
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.app-settings {
  max-width: 1024px;
  .description {
    font-size: 18px;
  }
  h2 {
    margin-top: 40px;
  }
  .remove {
    position: relative;
    top: 18px;
  }
}
</style>
