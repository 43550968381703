<template>
  <AulaButton class="print-button d-flex align-items-center" variant="link" fill="clear" @click="onClick">
    <i class="icon-printer print-icon" />
    <slot>
      {{ 'PRINT' | fromTextKey }}
    </slot>
  </AulaButton>
</template>
<script>
import AulaButton from './AulaButton';
import { providerKeyTypes } from '../enums/providerKeyTypes';
export default {
  name: 'PrintButton',
  components: { AulaButton },
  inject: {
    onPrint: {
      from: providerKeyTypes.onClickPrint,
      default: () => () => window.print(),
    },
  },
  methods: {
    onClick() {
      this.onPrint();
    },
  },
};
</script>
<style lang="scss" scoped>
.print-button {
  font-size: var(--font-size, 16px);
}

.print-icon {
  font-size: calc(8px + var(--font-size, 16px));
  margin-right: 5px;
}
</style>
