<template>
  <div v-if="(getFilters && getFilters.length > 1) || profile.role === portalRole.OTP" class="filter-list filter-row">
    <div v-show="disableClick" class="working" />
    <!-- main filter with listed filters -->
    <template
      v-if="getFilters.length <= displayedLength && (profile.role !== portalRole.OTP || groupHomes.length === 1)"
    >
      <div
        v-for="filter in getFilters"
        :key="filter.id"
        v-prevent-double-click
        class="global-filter large-filter no-mark"
        :class="selectedFilterIds.includes(filter.id) ? 'active-filter' : 'inactive-filter'"
        role="button"
        tabindex="0"
        :title="filter.name"
        :aria-label="getAriaLabel(filter)"
        @click="toggleActive(filter)"
        @keyup.enter="toggleActive(filter)"
      >
        <div class="filter-content" aria-hidden="true" @click.prevent>
          <b-form-checkbox
            v-if="selectedFilterIds.includes(filter.id) && getFilters.length > 1"
            class="filter-checkbox smallest"
            tabindex="-1"
            :checked="true"
          />
          <Avatar :profile="filter" />
        </div>
      </div>
    </template>

    <!-- main filter collapsed -->
    <template>
      <div
        v-if="getFilters.length > displayedLength || (profile.role === portalRole.OTP && groupHomes.length > 1)"
        v-click-outside="hideDropdown"
        class="filter-dropdown"
      >
        <!-- OTP OR filter collapsed collapsed -->
        <template>
          <div
            v-if="getFilters.length > displayedLength || profile.role === portalRole.OTP"
            class="global-filter large-filter"
            :class="{
              'active-filter': isFilterExpanded,
              'inactive-filter': !isFilterExpanded,
            }"
            :aria-label="'ARIA_LABEL_PROFILE_FILTER' | fromTextKey({ number: selectedFilterIds.length })"
            tabindex="0"
            role="button"
            @click="toggleFilterDropdown()"
            @keydown.enter="toggleFilterDropdown()"
          >
            <AulaBadge class="badge-notification">{{ selectedFilterIds.length }}</AulaBadge>
            <i class="icon-Aula_profile" />
            <badge-notification
              v-if="profile.role === portalRole.OTP && isChildrenHaveNewPostOrMessage"
              :border="true"
              :show-exclamation-icon-instead-of-amount="true"
              css="group-home-badge"
            />
          </div>
        </template>
        <transition name="slide-fade" leave-active-class="slide-fade-leave-active" leave-to-class="slide-fade-leave-to">
          <div v-show="isFilterExpanded" class="header-dropdown-menu" role="dialog">
            <ProfileFilter @close="hideDropdown" />
          </div>
        </transition>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapMutations, mapGetters } from 'vuex';
import { types } from '../../store/types/types';
import { portalRoles } from '../../../shared/enums/portalRoles';
import BadgeNotification from '../../../shared/components/BadgeNotification.vue';
import isEqual from 'lodash/isEqual';
import { notificationAreas } from '../../../shared/enums/notificationAreas';
import preventDoubleClick from '../../../shared/directives/PreventDoubleClick/PreventDoubleClick';
import $ from 'jquery';
import Avatar from '../../../shared/components/Avatar.vue';
import ProfileFilter from './ProfileFilter.vue';
import AulaBadge from '../../../shared/components/AulaBadge.vue';

export default {
  directives: {
    preventDoubleClick,
  },
  data: function () {
    return {
      portalRole: portalRoles,
      showFilterDropdown: false,
      activeFilter: [],
      disableClick: false,
      activeFilterLimit: 6,
      isFilterExpanded: false,
    };
  },
  computed: {
    ...mapGetters({
      groupHomes: types.GET_GROUP_HOMES,
      children: types.GET_CHILDREN,
      institutions: types.GET_INSTITUTIONS,
      activeChildren: types.GET_ACTIVE_CHILDREN,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
      isMobile: types.GET_IS_MOBILE,
      profile: types.GET_CURRENT_PROFILE,
      notifications: types.GET_NOTIFICATIONS,
      isLoadingThreads: types.IS_LOADING_THREADS,
      isLoadingEvents: types.IS_LOADING_EVENTS,
    }),
    getFilters() {
      const filtering = [];
      if (this.profile.role == this.portalRole.GUARDIAN || this.profile.role == this.portalRole.OTP) {
        for (const child of this.children) {
          if (filtering.filter(c => c.profileId == child.profileId).length == 0) {
            filtering.push(child);
          }
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.activeFilter = this.activeChildren;
      } else if (this.profile.role == this.portalRole.EMPLOYEE) {
        for (const institute of this.institutions) {
          institute.id = institute.institutionCode;

          filtering.push(institute);
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.activeFilter = this.activeInstitutions;
      }
      return filtering;
    },
    selectedFilterIds() {
      const uniqueFilterIds = new Set(this.getFilters.map(filter => filter.id));
      return this.activeFilter.filter(filterId => uniqueFilterIds.has(filterId));
    },
    displayedLength() {
      return this.isMobile ? 2 : 6;
    },
    isChildrenHaveNewPostOrMessage() {
      const childrenIds = this.children.map(c => c.id);
      return (
        this.notifications.find(
          notification =>
            childrenIds.indexOf(notification.institutionProfileId) !== -1 &&
            (notification.notificationArea === notificationAreas.MESSAGES ||
              notification.notificationArea === notificationAreas.POSTS)
        ) != null
      );
    },
  },
  methods: {
    ...mapActions({
      setActiveChildren: types.SET_ACTIVE_CHILDREN,
      setActiveInstitutions: types.SET_ACTIVE_INSTITUTIONS,
      setActiveFilters: types.SET_ACTIVE_FILTERS_DIRECT,
      loadNotifications: types.LOAD_NOTIFICATIONS,
      toggleHeaderDropdown: types.TOGGLE_HEADER_DROPDOWN,
    }),
    ...mapMutations({}),
    getAriaLabel(filter) {
      if (this.selectedFilterIds.includes(filter.id)) {
        return Vue.filter('fromTextKey')('ARIA_LABEL_HIDE_CONTENT_FROM') + filter.name;
      } else {
        return Vue.filter('fromTextKey')('ARIA_LABEL_SEE_CONTENT_FROM') + filter.name;
      }
    },
    toggleActive(item) {
      this.disableClick = this.isLoadingThreads || this.isLoadingEvents;

      if (
        !this.disableClick &&
        (this.selectedFilterIds.length < this.activeFilterLimit || this.activeFilter.includes(item.id))
      ) {
        this.disableClick = true;

        if ([this.portalRole.GUARDIAN, this.portalRole.OTP].indexOf(this.profile.role) > -1) {
          const child = this.children.find(child => child.id == item.id);
          const allChildrenWithProfileId = this.children.filter(c => c.profileId == child.profileId);
          const isChecked = this.selectedFilterIds.includes(item.id);
          let activeChildIds = this.activeChildren;

          if (isChecked) {
            activeChildIds = this.activeChildren.filter(childId => childId !== child.id);
          } else {
            activeChildIds.push(child.id);
          }

          if (
            isEqual(
              allChildrenWithProfileId.map(ac => ac.id),
              this.activeChildren
            )
          ) {
            this.disableClick = false;
            return;
          }

          const activeChildren = this.children.filter(child => activeChildIds.includes(child.id));
          this.setActiveChildren(activeChildren);
          this.setActiveInstitutionsByActiveChildren();
          this.loadNotifications({
            activeChildrenIds: activeChildIds,
            activeInstitutionCodes: this.activeInstitutions,
          });
          Vue.nextTick(() => {
            this.disableClick = false;
          });
        } else {
          if (this.activeInstitutions.length == 1 && this.activeInstitutions.includes(item.institutionCode)) {
            this.disableClick = false;
            return;
          }

          const isChecked = this.activeInstitutions.includes(item.institutionCode);
          let activeInstitutionCodes = this.activeInstitutions;

          if (isChecked) {
            activeInstitutionCodes = this.activeInstitutions.filter(code => code !== item.institutionCode);
          } else {
            activeInstitutionCodes.push(item.institutionCode);
          }

          const activeInstitutions = this.institutions.filter(institution =>
            activeInstitutionCodes.includes(institution.institutionCode)
          );
          this.setActiveInstitutions(activeInstitutions).then(() => {
            this.loadNotifications({
              activeInstitutionCodes: this.activeInstitutions,
            });
            Vue.nextTick(() => {
              this.disableClick = false;
            });
          });
        }
      }
      $('.global-filter').blur();
    },
    setActiveInstitutionsByActiveChildren() {
      const filteredChildren = this.children.filter(child => this.activeChildren.includes(child.id));
      const filteredInstitutionCodes = [...new Set(filteredChildren.map(child => child.institutionCode))];
      this.setActiveFilters({
        institutionCodes: filteredInstitutionCodes,
      });
    },
    toggleFilterDropdown() {
      this.isFilterExpanded = !this.isFilterExpanded;
      this.showFilterDropdown = this.isFilterExpanded;
      this.toggleHeaderDropdown({
        dropdown: 'search',
        visible: true,
      });
    },
    hideDropdown() {
      this.isFilterExpanded = false;
      this.showFilterDropdown = false;
    },
  },
  components: {
    AulaBadge,
    ProfileFilter,
    Avatar,
    BadgeNotification,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/core/animations.scss';

.global-filter {
  --aula-badge-box-shadow-color: var(--color-primary-dark);
  &.active-filter {
    --aula-badge-box-shadow-color: var(--color-primary-darker);
  }
}

.filter-list {
  border-right: 1px solid $color-white;
  .badge-notification {
    position: absolute;
    top: 5px;
    left: 6px;
    background-color: var(--color-white);
    color: var(--color-primary-darker);
  }
  .profile-filter /deep/.custom-control-label::before {
    border: 1px solid var(--color-primary-base) !important;
    background-color: transparent;
  }
  /deep/.custom-control-label::before {
    border: 1px solid var(--color-white) !important;
  }
  /deep/.custom-control-input:checked ~ .custom-control-label::before {
    background-color: var(--color-primary-dark);
  }

  :not(.slider) > .global-filter /deep/ .filter-checkbox {
    .custom-control-label:before {
      width: 21px;
      height: 21px;
      border: 1px solid $color-white !important;
    }
    .custom-control-label:after {
      font-size: 21px;
      left: -28px;
      top: 0px;
    }
  }

  .slider > .global-filter /deep/ .filter-checkbox {
    input[type='checkbox'].custom-control-input {
      display: none;
    }
  }

  .filter-checkbox {
    box-shadow: none !important;
  }

  .filter-text {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: var(--color-primary-darker);
    width: 100%;

    &.top-header-button {
      top: 87px !important;
    }

    &.small-text {
      color: $color-darkblue;
    }

    &:not(.small-text) {
      top: inherit;
      bottom: 15px;
      @include breakpoint-lg-down() {
        bottom: 2px;
      }
    }
  }

  .filter-dropdown {
    > .global-filter {
      float: right;
    }
  }
}

.close {
  position: absolute;
  text-transform: uppercase;
  font-size: 12px;
  right: 0;
  height: 100%;

  div {
    position: relative;
    top: 20px;
    right: 25px;
  }

  i {
    font-size: 46px;
  }
}

@include breakpoint-lg() {
  .slider {
    float: right;
    margin-right: 80px;
  }
}
</style>
