<template>
  <drawer :show="isShown" :focus-target="focusTarget" @change-show="changeDrawerShow">
    <template slot="drawer-title">
      <div class="title">
        <i class="icon-Aula_export presence-drawer-icon hide-tablet-down mr-3" aria-hidden="true" />
        {{ 'PRESENCE_DASHBOARD_UPDATE_TEMPLATE' | fromTextKey }}
      </div>
    </template>
    <template slot="drawer-content">
      <div class="presence-drawer-container">
        <b-row v-if="selectedChild" class="separating-line">
          <b-col class="d-flex align-items-center mb-4">
            <div class="large-user-photo">
              <user-icon
                :id="1"
                :show-inline="true"
                :name="selectedChild.name"
                :short-name="selectedChild.shortName"
                :picture-object="selectedChild.profilePicture"
              />
            </div>
            <div class="child-name ml-4">
              {{ selectedChild | displayProfileNameWithMetadata }}
            </div>
          </b-col>
        </b-row>
        <aula-spinner v-if="isConfigLoading || isTemplateLoading" />
        <template v-else>
          <b-row
            v-if="
              presenceConfiguration[presenceModules.SPARE_TIME_ACTIVITY] &&
              presenceConfiguration[presenceModules.SPARE_TIME_ACTIVITY].readable
            "
            class="separating-line"
          >
            <b-col cols="12">
              <div>
                <span class="section-header">{{ 'PRESENCE_SPARE_TIME' | fromTextKey }}</span>
                <b-form-checkbox
                  v-model="isEditingSpareTime"
                  class="mt-3 mb-0"
                  :disabled="!presenceConfiguration[presenceModules.SPARE_TIME_ACTIVITY].editable"
                >
                  {{ 'PRESENCE_SEND_TO_SPARETIME_ACTIVITY' | fromTextKey }}
                </b-form-checkbox>
              </div>
            </b-col>
            <transition name="toggle">
              <b-col v-if="isEditingSpareTime" cols="12">
                <b-row>
                  <b-col>
                    <label class="mt-3">{{ 'PRESENCE_SELF_DECIDER_START_TIME' | fromTextKey }}</label>
                    <timepicker
                      id="spare-activity-start-time"
                      v-model="spareTimeActivity.startTime"
                      format="HH:mm"
                      hour-label="Time"
                      minute-label="Minut"
                      :class="{
                        'red-border': isVisibleInvalidActivityTimeAlert,
                      }"
                      :placeholder="'PRESENCE_PLACEHOLDER' | fromTextKey"
                      :aria-label="'ARIA_LABEL_PRESENCE_SPARE_ACTIVITY_FROM' | fromTextKey"
                      hide-clear-button
                      :disabled="!presenceConfiguration[presenceModules.SPARE_TIME_ACTIVITY].editable"
                      :minute-interval="timepickerSettings.FIFTEEN_MINUTE_INTERVAL"
                      :start="comeGoUtil.defaultStart()"
                      :end="comeGoUtil.defaultEnd()"
                      advanced-keyboard
                    />
                  </b-col>
                  <b-col>
                    <label class="mt-3">{{ 'PRESENCE_SELF_DECIDER_END_TIME' | fromTextKey }}</label>
                    <timepicker
                      id="spare-activity-end-time"
                      v-model="spareTimeActivity.endTime"
                      format="HH:mm"
                      hour-label="Time"
                      minute-label="Minut"
                      :class="{
                        'red-border': isVisibleInvalidActivityTimeAlert,
                      }"
                      :placeholder="'PRESENCE_PLACEHOLDER' | fromTextKey"
                      :aria-label="'ARIA_LABEL_PRESENCE_SPARE_ACTIVITY_TO' | fromTextKey"
                      hide-clear-button
                      :disabled="!presenceConfiguration[presenceModules.SPARE_TIME_ACTIVITY].editable"
                      :minute-interval="timepickerSettings.FIFTEEN_MINUTE_INTERVAL"
                      :start="comeGoUtil.defaultStart()"
                      :end="comeGoUtil.defaultEnd()"
                      advanced-keyboard
                    />
                  </b-col>
                  <b-col v-if="isVisibleInvalidActivityTimeAlert" cols="12" class="mt-1">
                    <b-alert variant="danger" class="mb-0" show>
                      {{ 'PRESENCE_ALERT_INVALID_START_AND_END_TIME' | fromTextKey }}
                    </b-alert>
                  </b-col>
                  <b-col :class="{ 'mt-3': isMobile }" cols="12">
                    <label>{{ 'PRESENCE_COMMENT_SPARETIME_ACTIVITY' | fromTextKey }}</label>
                    <b>{{ 'PRESENCE_COMMENT_MAX_CHARACTERS' | fromTextKey }}</b>
                    <b-textarea
                      id="spare-activity-comment"
                      v-model="spareTimeActivity.comment"
                      :aria-label="'ARIA_LABEL_PRESENCE_COMMENT_SPARETIME_ACTIVITY' | fromTextKey"
                      maxlength="250"
                      max-rows="1"
                      rows="1"
                      class="absence-input"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </transition>
          </b-row>
          <b-row
            v-if="
              presenceConfiguration[presenceModules.VACATION] != null &&
              presenceConfiguration[presenceModules.VACATION].readable
            "
            class="separating-line"
          >
            <b-col cols="12">
              <div class="section-header">
                {{ 'PRESENCE_ABSENCE' | fromTextKey }}
              </div>
              <div class="presence-icons">
                <div class="presence-icon-container">
                  <IconContainer
                    v-button
                    class="icon-circle"
                    :class="{
                      disabled: !presenceConfiguration[presenceModules.VACATION].editable,
                      active: isEditingVacation,
                    }"
                    @click="showVacationEditing"
                  >
                    <Icon :name="iconClassEnum.FERIE_IKON" />
                  </IconContainer>
                  <div class="icons-description">
                    {{ 'PRESENCE_VACATION' | fromTextKey }}
                  </div>
                </div>
                <transition name="toggle">
                  <vacation-form
                    v-if="isEditingVacation && presenceConfiguration[presenceModules.VACATION].readable"
                    :is-form-disabled="!presenceConfiguration[presenceModules.VACATION].editable"
                    :vacation-form-data="vacationFormData"
                    :is-disabled-start-date="false"
                    :child-ids="[selectedChild.id]"
                    :is-child-on-vacation="isChildOnVacation"
                    @cancelVacationForm="onCancelVacation"
                    @saveVacation="closeEditingTemplateDrawer"
                  />
                </transition>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="isPickUpInformationVisible" class="separating-line">
            <b-col cols="12">
              <div class="section-header">
                {{ 'PRESENCE_PICKUP_INFORMATION' | fromTextKey }}
              </div>
            </b-col>
            <b-col
              v-if="presenceConfiguration[presenceModules.PICKUP_TYPE].readable"
              :class="{ 'mt-3': isMobile }"
              cols="12"
              md="6"
            >
              <label>
                {{ 'PRESENCE_SELECT_EXIT_TYPE' | fromTextKey }}
                <span class="mandatory">*</span>
              </label>
              <aula-select
                id="pick-up-type"
                v-model="exitType"
                class="align-left"
                :class="{ 'red-border': showAlertNoPickUpType }"
                popper-class="align-left"
                :aria-label="'ARIA_LABEL_CHOOSE_PICKUP_TYPE' | fromTextKey"
                :disabled="!presenceConfiguration[presenceModules.PICKUP_TYPE].editable"
                @change="changeExitType"
              >
                <aula-option
                  v-for="(module, index) of activeModules"
                  :key="index"
                  :label="module.label"
                  :value="module.value"
                  :disabled="module.value === disabledExitType"
                />
              </aula-select>
              <b-alert variant="danger" :show="showAlertNoPickUpType">
                {{ 'PRESENCE_NO_PICKUP_TYPE' | fromTextKey }}
              </b-alert>
              <b-alert variant="danger" :show="isAlertInvalidConfigurationVisible">
                {{ 'PRESENCE_WARNING_INVALID_CONFIGURATION' | fromTextKey }}
              </b-alert>
            </b-col>
            <b-col
              v-if="
                presenceConfiguration[presenceModules.PICKUP_TYPE].readable &&
                (exitType === exitTypes.SEND_HOME || exitType === null)
              "
              cols="12"
              md="6"
            />
            <b-col
              v-if="
                (exitType === exitTypes.PICKED_UP_BY || exitType === exitTypes.GO_HOME_WITH) &&
                presenceConfiguration[presenceModules.PICKUP_TYPE].readable
              "
              cols="12"
              md="6"
              :class="{ 'mt-3': isMobile }"
            >
              <label>
                {{ 'PRESENCE_SELECT_GO_HOME_WITH' | fromTextKey }}
                <span v-if="exitType === exitTypes.GO_HOME_WITH" class="mandatory">*</span>
              </label>
              <aula-select
                v-if="exitType === exitTypes.PICKED_UP_BY && pickupResponsible[0]"
                id="exit-with"
                v-model="exitWith"
                :class="{ 'red-border': isAlertNoExitWithVisible }"
                :filterable="true"
                :aria-label="'ARIA_LABEL_CHOOSE_EXIT_WITH' | fromTextKey"
                :disabled="!presenceConfiguration[presenceModules.PICKUP_TYPE].editable"
                :placeholder="'SELECT' | fromTextKey"
                @change="isAlertNoExitWithVisible = false"
              >
                <aula-option
                  v-for="(option, i) in pickupResponsible[0].relatedPersons"
                  :key="'rp' + i"
                  :label="option.name + ' (' + option.relation + ')'"
                  :value="option.name + ' (' + option.relation + ')'"
                />
                <aula-option
                  v-for="(option, i) in pickupResponsible[0].pickupSuggestions"
                  :key="'pr' + i"
                  :label="option.pickupName"
                  :value="option.pickupName"
                />
              </aula-select>
              <aula-select
                v-if="exitType === exitTypes.GO_HOME_WITH"
                id="go-home-with"
                v-model="exitWith"
                :class="{ 'red-border': isAlertNoExitWithVisible }"
                :aria-label="'ARIA_LABEL_CHOOSE_EXIT_WITH' | fromTextKey"
                :filterable="true"
                :disabled="!presenceConfiguration[presenceModules.PICKUP_TYPE].editable"
                :placeholder="'SELECT' | fromTextKey"
                @change="isAlertNoExitWithVisible = false"
              >
                <aula-option
                  v-for="(option, i) in goHomeWithList"
                  :key="'hw' + i"
                  :label="option.fullName + ' (' + option.mainGroup + ')'"
                  :value="option.fullName + ' (' + option.mainGroup + ')'"
                />
              </aula-select>
              <b-alert variant="danger" :show="isAlertNoExitWithVisible">
                {{ 'PRESENCE_NO_EXIT_WITH_WARNING' | fromTextKey }}
              </b-alert>
            </b-col>
            <template v-if="exitType === exitTypes.SELF_DECIDER && !isPickUpTimesHidden">
              <b-col cols="12" md="3" :class="{ 'mt-3': isMobile }">
                <label>
                  {{ 'PRESENCE_SELF_DECIDER_START_TIME' | fromTextKey }}
                  <span class="mandatory">*</span>
                </label>
                <timepicker
                  id="check-in-time"
                  v-model="selfDeciderStartTime"
                  format="HH:mm"
                  hour-label="Time"
                  minute-label="Minut"
                  :class="{
                    'red-border':
                      isAlertNoSelfDeciderStartTimeVisible ||
                      isAlertSelfDeciderEndTimeBeforeSelfDeciderStartTimeVisible,
                  }"
                  :placeholder="'PRESENCE_PLACEHOLDER' | fromTextKey"
                  :aria-label="'ARIA_LABEL_PRESENCE_SELF_DECIDER_START_TIME' | fromTextKey"
                  hide-clear-button
                  :disabled="!presenceConfiguration[presenceModules.PICKUP_TIMES].editable"
                  :minute-interval="timepickerSettings.FIFTEEN_MINUTE_INTERVAL"
                  :start="comeGoUtil.defaultStart()"
                  :end="comeGoUtil.defaultEnd()"
                  advanced-keyboard
                  @change="isAlertNoSelfDeciderStartTimeVisible = false"
                />
                <b-alert v-if="isAlertNoSelfDeciderStartTimeVisible" variant="danger" show class="mb-0">
                  {{ 'PRESENCE_WARNING_NO_EXIT_TIME' | fromTextKey }}
                </b-alert>
              </b-col>
              <b-col cols="12" md="3" :class="{ 'mt-3': isMobile }">
                <label>
                  {{ 'PRESENCE_SELF_DECIDER_END_TIME' | fromTextKey }}
                  <span class="mandatory">*</span>
                </label>
                <timepicker
                  id="check-out-time"
                  v-model="selfDeciderEndTime"
                  format="HH:mm"
                  hour-label="Time"
                  minute-label="Minut"
                  :class="{
                    'red-border':
                      isAlertNoSelfDeciderEndTimeVisible || isAlertSelfDeciderEndTimeBeforeSelfDeciderStartTimeVisible,
                  }"
                  :placeholder="'PRESENCE_PLACEHOLDER' | fromTextKey"
                  :aria-label="'ARIA_LABEL_PRESENCE_SELF_DECIDER_END_TIME' | fromTextKey"
                  hide-clear-button
                  :disabled="!presenceConfiguration[presenceModules.PICKUP_TIMES].editable"
                  :minute-interval="timepickerSettings.FIFTEEN_MINUTE_INTERVAL"
                  :start="comeGoUtil.defaultStart()"
                  :end="comeGoUtil.defaultEnd()"
                  advanced-keyboard
                  @change="
                    (isAlertNoSelfDeciderEndTimeVisible = false),
                      (isAlertSelfDeciderEndTimeBeforeSelfDeciderStartTimeVisible = false),
                      (isAlertExitTimeBeforeEntryTimeVisible = false)
                  "
                />
                <b-alert v-if="isAlertNoSelfDeciderEndTimeVisible" variant="danger" show class="mb-0">
                  {{ 'PRESENCE_WARNING_NO_EXIT_TIME' | fromTextKey }}
                </b-alert>
                <b-alert
                  variant="danger"
                  :show="isAlertSelfDeciderEndTimeBeforeSelfDeciderStartTimeVisible"
                  class="mb-0"
                >
                  {{ 'PRESENCE_ALERT_INVALID_START_AND_END_TIME' | fromTextKey }}
                </b-alert>
                <b-alert variant="danger" :show="isAlertExitTimeBeforeEntryTimeVisible" class="mb-0">
                  {{ 'PRESENCE_ALERT_INVALID_ENTRY_TIME' | fromTextKey }}
                </b-alert>
              </b-col>
            </template>
            <b-col
              v-if="
                presenceConfiguration[presenceModules.DROP_OFF_TIME] !== null &&
                presenceConfiguration[presenceModules.DROP_OFF_TIME].readable
              "
              cols="12"
              md="6"
              :class="{ 'mt-3': isMobile }"
            >
              <label>
                {{ 'PRESENCE_DELIVERY_TIME_SHORT' | fromTextKey }}
              </label>
              <timepicker
                id="drop-off-time"
                v-model="entryTime"
                format="HH:mm"
                hour-label="Time"
                minute-label="Minut"
                :placeholder="'PRESENCE_PLACEHOLDER' | fromTextKey"
                :aria-label="'ARIA_LABEL_CHOOSE_CHECK_IN_TIME' | fromTextKey"
                hide-clear-button
                :disabled="!presenceConfiguration[presenceModules.DROP_OFF_TIME].editable"
                :minute-interval="timepickerSettings.FIFTEEN_MINUTE_INTERVAL"
                :start="comeGoUtil.defaultStart()"
                :end="comeGoUtil.defaultEnd()"
                :class="{
                  'red-border': isAlertNoEntryTimeVisible,
                }"
                advanced-keyboard
                @change="isAlertNoEntryTimeVisible = false"
              />
              <b-alert variant="danger" :show="isAlertNoEntryTimeVisible" class="mb-0">
                {{ 'PRESENCE_ALERT_INVALID_NO_TIMES_SELECTED' | fromTextKey }}
              </b-alert>
            </b-col>
            <b-col
              v-if="
                exitType !== exitTypes.SELF_DECIDER &&
                presenceConfiguration[presenceModules.PICKUP_TIMES] !== null &&
                presenceConfiguration[presenceModules.PICKUP_TIMES].readable
              "
              cols="12"
              md="6"
              :class="{ 'mt-3': isMobile }"
            >
              <label>
                {{ 'PRESENCE_SELECT_PICK_UP_TIME_SHORT' | fromTextKey }}
                <span v-if="exitTypes.SEND_HOME === exitType" class="mandatory"> * </span>
              </label>
              <timepicker
                id="pickup-time"
                v-model="exitTime"
                format="HH:mm"
                hour-label="Time"
                minute-label="Minut"
                :class="{
                  'red-border': isAlertExitTimeBeforeEntryTimeVisible || isAlertNoExitTimeVisible,
                }"
                :placeholder="'PRESENCE_PLACEHOLDER' | fromTextKey"
                :aria-label="'ARIA_LABEL_CHOOSE_CHECK_OUT_TIME' | fromTextKey"
                hide-clear-button
                :disabled="!presenceConfiguration[presenceModules.PICKUP_TIMES].editable"
                :minute-interval="timepickerSettings.FIFTEEN_MINUTE_INTERVAL"
                :start="comeGoUtil.defaultStart()"
                :end="comeGoUtil.defaultEnd()"
                advanced-keyboard
                @change="
                  isAlertExitTimeBeforeEntryTimeVisible = false;
                  isAlertNoExitTimeVisible = false;
                "
              />
              <b-alert variant="danger" :show="isAlertExitTimeBeforeEntryTimeVisible" class="mb-0">
                {{ 'PRESENCE_ALERT_INVALID_START_AND_END_TIME' | fromTextKey }}
              </b-alert>
              <b-alert variant="danger" :show="isAlertNoExitTimeVisible" class="mb-0">
                {{ 'PRESENCE_ALERT_INVALID_NO_TIMES_SELECTED' | fromTextKey }}
              </b-alert>
            </b-col>
          </b-row>
          <b-row
            v-if="
              presenceConfiguration[presenceModules.DAILY_MESSAGE] &&
              presenceConfiguration[presenceModules.DAILY_MESSAGE].readable
            "
            class="separating-line"
          >
            <b-col cols="12">
              <div class="section-header">
                {{ 'PRESENCE_COMMENT' | fromTextKey }}
                <span class="text-lowercase">{{ 'PRESENCE_COMMENT_MAX_CHARACTERS' | fromTextKey }}</span>
              </div>
              <b-textarea
                id="comment"
                v-model="templateComment"
                maxlength="250"
                max-rows="1"
                rows="5"
                class="absence-input mt-4"
                :disabled="!presenceConfiguration[presenceModules.DAILY_MESSAGE].editable"
                :placeholder="'PRESENCE_COMMENT_PLACE_HOLDER' | fromTextKey"
                :aria-label="'ARIA_LABEL_PRESENCE_COMMENT_PLACE_HOLDER' | fromTextKey"
              />
              <b-alert variant="danger" :show="templateComment.length >= 250" class="mt-1 mb-0">
                {{ 'PRESENCE_COMMENT_CHARACTER_LIMIT_REACHED' | fromTextKey }}
              </b-alert>
            </b-col>
          </b-row>
        </template>
        <aula-form-footer
          :is-loading="isSavingTemplate"
          :show-line="false"
          submit-text="BUTTON_SAVE"
          @submitClicked="validateAndSavePresenceForm"
          @cancelClicked="closeEditingTemplateDrawer"
        />
      </div>
      <aula-modal
        ref="deleteVacationModal"
        @cancelClicked="$refs.deleteVacationModal.hide()"
        @okClicked="savePresenceForm()"
      >
        {{ 'PRESENCE_WARNING_REMOVE_VACATION' | fromTextKey }}
      </aula-modal>
    </template>
  </drawer>
</template>

<script>
import VacationForm from '../../../shared/components/presence/VacationForm';
import { mapGetters, mapActions } from 'vuex';
import { types } from '../../store/types/types';
import { presenceDashboardContext } from '../../../shared/enums/presenceDashboardContext';
import { presenceModuleStatus } from '../../../shared/enums/presenceModuleStatus';
import { exitTypes } from '../../../shared/enums/exitTypes';
import { presenceModules } from '../../../shared/enums/presenceModules';
import moment from 'moment';
import { portalRoles } from '../../../shared/enums/portalRoles';
import Timepicker from '../../../shared/components/Timepicker';
import { timepickerSettings } from '../../../shared/enums/timepickerSettings';
import { comeGoUtil } from '../../../shared/utils/comeGoUtil';
import Icon from '../../../shared/components/Icon.vue';
import IconContainer from '../../../shared/components/IconContainer.vue';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import button from '../../../shared/directives/button';

export default {
  data: function () {
    return {
      comeGoUtil: comeGoUtil,
      timepickerSettings: timepickerSettings,
      isShown: false,
      selectedChild: null,
      selectedTemplateDate: null,
      isEditingSpareTime: false,
      isEditingVacation: false,
      activeModules: [],
      presenceConfiguration: {},
      isConfigLoading: true,
      isTemplateLoading: true,
      isSavingTemplate: false,
      disabledExitType: null,
      exitType: null,
      exitWith: null,
      entryTime: '',
      exitTime: '',
      selfDeciderStartTime: '',
      selfDeciderEndTime: '',
      isAlertNoExitWithVisible: false,
      isAlertNoExitTimeVisible: false,
      isAlertNoEntryTimeVisible: false,
      isAlertExitTimeBeforeEntryTimeVisible: false,
      isAlertNoSelfDeciderStartTimeVisible: false,
      isAlertNoSelfDeciderEndTimeVisible: false,
      isAlertSelfDeciderEndTimeBeforeSelfDeciderStartTimeVisible: false,
      isAlertInvalidConfigurationVisible: false,
      templateComment: '',
      spareTimeActivity: {
        startTime: '13:00',
        endTime: '14:00',
        comment: '',
      },
      vacationFormData: null,
      isChildOnVacation: false,
      focusTarget: null,
      presenceModules,
      exitTypes,
      showAlertNoPickUpType: false,
      vacationRemoved: false,
      portalRoles: portalRoles,
    };
  },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
      getPresenceConfiguration: types.GET_PRESENCE_CONFIGURATION,
      getDepartments: types.GET_DEPARTMENTS,
      filterOptions: types.GET_FILTER_OPTIONS,
      pickupResponsible: types.GET_PICK_UP_RESPONSIBLES,
      goHomeWithList: types.GET_GO_HOME_WITH_LIST,
      activeTemplateForDate: types.GET_ACTIVE_PRESENCE_TEMPLATE_FOR_DATE,
      profile: types.GET_CURRENT_PROFILE,
    }),
    chosenDepartment() {
      return this.getDepartments.find(d => d.id == this.filterOptions.departmentId);
    },
    exitIsPickedUpOrGoHomeWith() {
      return [exitTypes.PICKED_UP_BY, exitTypes.GO_HOME_WITH].includes(this.exitType);
    },
    exitIsSendHomeOrSelfDecider() {
      return [exitTypes.SELF_DECIDER, exitTypes.SEND_HOME].includes(this.exitType);
    },
    isPickUpTimesHidden() {
      return (
        !this.presenceConfiguration[presenceModules.PICKUP_TIMES].readable &&
        !this.presenceConfiguration[presenceModules.PICKUP_TIMES].editable
      );
    },
    isPickUpInformationVisible() {
      const isPickupTypeReadable =
        this.presenceConfiguration[presenceModules.PICKUP_TYPE] &&
        this.presenceConfiguration[presenceModules.PICKUP_TYPE].readable;
      const isPickupTimeReadable =
        this.presenceConfiguration[presenceModules.PICKUP_TIMES] &&
        this.presenceConfiguration[presenceModules.PICKUP_TIMES].readable;
      const isDropOffTimeReadable =
        this.presenceConfiguration[presenceModules.DROP_OFF_TIME] &&
        this.presenceConfiguration[presenceModules.DROP_OFF_TIME].readable;
      return (
        !this.isConfigLoading &&
        (this.presenceConfiguration.pickup ||
          this.presenceConfiguration.selfDecider ||
          this.presenceConfiguration.sendHome ||
          this.presenceConfiguration.goHomeWith) &&
        (isPickupTypeReadable || isPickupTimeReadable || isDropOffTimeReadable)
      );
    },
    isVisibleInvalidActivityTimeAlert() {
      let isVisible = false;
      if (
        this.isEditingSpareTime &&
        this.spareTimeActivity &&
        this.spareTimeActivity.startTime &&
        this.spareTimeActivity.endTime
      ) {
        const startTime = moment(this.spareTimeActivity.startTime, 'HH:mm', true);
        const endTime = moment(this.spareTimeActivity.endTime, 'HH:mm', true);
        if (!startTime.isValid() || !endTime.isValid() || endTime.isSameOrBefore(startTime)) {
          isVisible = true;
        }
      }
      return isVisible;
    },
    isVisibleInvalidNoTimesSelectedAlert() {
      if (
        this.exitType === null ||
        this.exitType !== exitTypes.SEND_HOME ||
        this.exitIsPickedUpOrGoHomeWith ||
        !this.entryTime
      ) {
        return false;
      }

      const hasInvalidSelectedTime = !this.exitTime;
      if (this.exitIsSendHomeOrSelfDecider) {
        if (this.exitType === exitTypes.SELF_DECIDER) {
          if (
            (this.activeTemplateForDate.selfDeciderStartTime === null ||
              this.activeTemplateForDate.selfDeciderEndTime === null) &&
            !this.entryTime
          ) {
            return true;
          }
        } else if (
          (this.activeTemplateForDate.entryTime === null || this.activeTemplateForDate.endTime === null) &&
          hasInvalidSelectedTime
        ) {
          return true;
        }
      } else {
        return this.isDefaultTimeSelected(hasInvalidSelectedTime);
      }

      return false;
    },
    hasExistingVacationRequest() {
      return this.vacationFormData && typeof this.vacationFormData.id === 'number';
    },
  },
  methods: {
    ...mapActions({
      loadPresenceConfiguration: types.LOAD_PRESENCE_CONFIGURATION,
      loadPickupResponsible: types.LOAD_PICK_UP_RESPONSIBLES,
      loadGoHomeWithList: types.LOAD_GO_HOME_WITH_LIST,
      loadPresenceTemplateForDate: types.LOAD_PRESENCE_TEMPLATE_FOR_DATE,
      updatePresenceTemplate: types.UPDATE_PRESENCE_TEMPLATE,
      deleteVacation: types.DELETE_EVENT,
    }),
    changeExitType() {
      this.showAlertNoPickUpType = false;
      this.isAlertNoExitWithVisible = false;
      this.isAlertNoExitTimeVisible = false;
      this.isAlertNoEntryTimeVisible = false;
      this.isAlertNoSelfDeciderStartTimeVisible = false;
      this.isAlertNoSelfDeciderEndTimeVisible = false;
      this.isAlertInvalidConfigurationVisible = false;
      this.exitWith = null;
      this.calculateTimeProperties();
    },
    calculateTimeProperties() {
      // if selfdecider times are set, exitTime will be set to selfDeciderEndTime
      if (this.selfDeciderEndTime && !this.exitTime) {
        this.exitTime = this.selfDeciderEndTime;
      }

      // if exitTime is set but no selfDecider then selfDecider times are calculated based on exitTime
      if (this.exitTime && !this.selfDeciderEndTime) {
        const exitTime = this.exitTime.split(':');
        this.selfDeciderEndTime = this.exitTime;
        this.selfDeciderStartTime = moment()
          .hours(exitTime[0])
          .minutes(exitTime[1])
          .subtract(1, 'hours')
          .format('HH:mm');
      }
    },
    populatePresenceConfiguration() {
      const employeeDashboard = this.getPresenceConfiguration.dashboardModuleSettings.find(
        d => d.presenceDashboardContext === presenceDashboardContext.EMPLOYEE_DASHBOARD
      );
      for (const presenceModule of employeeDashboard.presenceModules) {
        this.presenceConfiguration[presenceModule.moduleType] = {};
        this.presenceConfiguration[presenceModule.moduleType].readable =
          (presenceModule.permission !== presenceModuleStatus.DEACTIVATED &&
            presenceModule.permission === presenceModuleStatus.READABLE) ||
          presenceModule.permission === presenceModuleStatus.EDITABLE;
        this.presenceConfiguration[presenceModule.moduleType].editable =
          presenceModule.permission === presenceModuleStatus.EDITABLE;
      }
      this.presenceConfiguration.goHomeWith = this.getPresenceConfiguration.goHomeWith;
      this.presenceConfiguration.pickup = this.getPresenceConfiguration.pickup;
      this.presenceConfiguration.selfDecider = this.getPresenceConfiguration.selfDecider;
      this.presenceConfiguration.sendHome = this.getPresenceConfiguration.sendHome;
      this.activeModules = [];
      if (this.presenceConfiguration.pickup || this.exitType === exitTypes.PICKED_UP_BY) {
        this.activeModules.push({
          value: exitTypes.PICKED_UP_BY,
          label: this.$options.filters.fromTextKey('PRESENCE_PICK_UP_BY'),
          isDisabled: this.presenceConfiguration.pickup,
        });
      }
      if (this.presenceConfiguration.selfDecider || this.exitType === exitTypes.SELF_DECIDER) {
        this.activeModules.push({
          value: exitTypes.SELF_DECIDER,
          label: this.$options.filters.fromTextKey('PRESENCE_SELF_DECIDER'),
          isDisabled: this.presenceConfiguration.selfDecider,
        });
      }
      if (this.presenceConfiguration.sendHome || this.exitType === exitTypes.SEND_HOME) {
        this.activeModules.push({
          value: exitTypes.SEND_HOME,
          label: this.$options.filters.fromTextKey('PRESENCE_SEND_HOME'),
          isDisabled: this.presenceConfiguration.sendHome,
        });
      }
      if (this.presenceConfiguration.goHomeWith || this.exitType === exitTypes.GO_HOME_WITH) {
        this.activeModules.push({
          value: exitTypes.GO_HOME_WITH,
          label: this.$options.filters.fromTextKey('PRESENCE_GO_HOME_WITH_MODULE'),
          isDisabled: this.presenceConfiguration.goHomeWith,
        });
      }
      this.activeModules.map(item => (!item.isDisabled ? (this.disabledExitType = item.value) : null));
    },
    changeDrawerShow(value) {
      if (!value) {
        this.closeEditingTemplateDrawer();
      }
    },
    isDefaultTimeSelected(hasInvalidSelectedTime) {
      if (hasInvalidSelectedTime) {
        if (this.exitType === exitTypes.SELF_DECIDER) {
          if (
            this.activeTemplateForDate.selfDeciderStartTime === null ||
            this.activeTemplateForDate.selfDeciderEndTime === null
          ) {
            return true;
          }
        } else {
          if (this.activeTemplateForDate.entryTime === null || this.activeTemplateForDate.endTime === null) {
            return true;
          }
        }
      }
      return false;
    },
    closeEditingTemplateDrawer() {
      this.showAlertNoPickUpType = false;
      this.isAlertNoSelfDeciderStartTimeVisible = false;
      this.isAlertNoSelfDeciderEndTimeVisible = false;
      this.isAlertSelfDeciderEndTimeBeforeSelfDeciderStartTimeVisible = false;
      this.isAlertExitTimeBeforeEntryTimeVisible = false;
      this.isAlertNoExitTimeVisible = false;
      this.isAlertNoEntryTimeVisible = false;
      this.isAlertInvalidConfigurationVisible = false;
      this.isShown = false;
      this.$emit('onClosed');
    },
    showVacationEditing() {
      if (this.presenceConfiguration[presenceModules.VACATION].editable) {
        this.isEditingVacation = !this.isEditingVacation;
      }
      this.toggleVacationRemoved();
    },
    validateTemplate() {
      let isValid = true;

      if (
        this.presenceConfiguration[presenceModules.PICKUP_TYPE].editable &&
        this.presenceConfiguration[presenceModules.PICKUP_TYPE].readable &&
        this.exitType === null
      ) {
        this.showAlertNoPickUpType = true;
        isValid = false;
      }
      // if exitTime is hidden (configurations) and the exitTime has not been specified before
      // an employee should not be able to save with exitType "send home" or "self decider".
      if (this.isPickUpTimesHidden && this.exitIsSendHomeOrSelfDecider) {
        if (
          (this.exitType === exitTypes.SELF_DECIDER && !this.selfDeciderStartTime) ||
          (this.exitType === exitTypes.SEND_HOME && !this.exitTime)
        ) {
          this.isAlertInvalidConfigurationVisible = true;
          isValid = false;
        }
      }

      // We always validate entry and exit times against each other if both times have been specified, self decider times are handled separately
      if (this.exitType !== exitTypes.SELF_DECIDER && this.entryTime && this.exitTime) {
        isValid = this.validateEntryAgainstExitTimes();
      } else if (this.exitType === exitTypes.SELF_DECIDER) {
        isValid = this.validateSelfDeciderTimes();
      }

      if (this.entryTime && !moment(this.entryTime, 'HH:mm', true).isValid()) {
        isValid = false;
        this.isAlertNoEntryTimeVisible = true;
      }
      if (
        this.exitType !== exitTypes.SELF_DECIDER &&
        this.exitTime &&
        !moment(this.exitTime, 'HH:mm', true).isValid()
      ) {
        isValid = false;
        this.isAlertNoExitTimeVisible = true;
      }

      if (this.exitType === exitTypes.SEND_HOME) {
        !this.exitTime || !moment(this.exitTime, 'HH:mm', true).isValid()
          ? ((this.isAlertNoExitTimeVisible = true), (isValid = false))
          : (this.isAlertNoExitTimeVisible = false);
      }

      if (!this.exitWith && this.presenceConfiguration.goHomeWith && this.exitType === exitTypes.GO_HOME_WITH) {
        isValid = false;
        this.isAlertNoExitWithVisible = true;
      }
      if (this.isVisibleInvalidActivityTimeAlert || this.isVisibleInvalidNoTimesSelectedAlert) {
        isValid = false;
      }
      return isValid;
    },

    validateEntryAgainstExitTimes() {
      const entryTime = moment().hours(this.entryTime.split(':')[0]).minutes(this.entryTime.split(':')[1]);
      const exitTime = moment().hours(this.exitTime.split(':')[0]).minutes(this.exitTime.split(':')[1]);

      this.isAlertExitTimeBeforeEntryTimeVisible = exitTime.isSameOrBefore(entryTime);
      return !this.isAlertExitTimeBeforeEntryTimeVisible;
    },

    validateSelfDeciderTimes() {
      !this.selfDeciderStartTime || !moment(this.selfDeciderStartTime, 'HH:mm', true).isValid()
        ? (this.isAlertNoSelfDeciderStartTimeVisible = true)
        : (this.isAlertNoSelfDeciderStartTimeVisible = false);

      !this.selfDeciderEndTime || !moment(this.selfDeciderEndTime, 'HH:mm', true).isValid()
        ? (this.isAlertNoSelfDeciderEndTimeVisible = true)
        : (this.isAlertNoSelfDeciderEndTimeVisible = false);

      if (this.isAlertNoSelfDeciderStartTimeVisible || this.isAlertNoSelfDeciderEndTimeVisible) {
        return false;
      }

      const selfDeciderStartTime = moment()
        .hours(this.selfDeciderStartTime.split(':')[0])
        .minutes(this.selfDeciderStartTime.split(':')[1]);
      const selfDeciderEndTime = moment()
        .hours(this.selfDeciderEndTime.split(':')[0])
        .minutes(this.selfDeciderEndTime.split(':')[1]);
      this.isAlertSelfDeciderEndTimeBeforeSelfDeciderStartTimeVisible =
        selfDeciderEndTime.isSameOrBefore(selfDeciderStartTime);

      if (this.entryTime) {
        const entryTime = moment().hours(this.entryTime.split(':')[0]).minutes(this.entryTime.split(':')[1]);
        this.isAlertExitTimeBeforeEntryTimeVisible =
          selfDeciderStartTime.isBefore(entryTime) || selfDeciderEndTime.isSameOrBefore(entryTime);
      }

      return (
        !this.isAlertNoSelfDeciderStartTimeVisible &&
        !this.isAlertNoSelfDeciderEndTimeVisible &&
        !this.isAlertSelfDeciderEndTimeBeforeSelfDeciderStartTimeVisible &&
        !this.isAlertExitTimeBeforeEntryTimeVisible
      );
    },
    validateAndSavePresenceForm() {
      const isPresenceFormValid = this.validateTemplate();
      if (isPresenceFormValid === true) {
        if (this.vacationRemoved === true) {
          this.$refs.deleteVacationModal.show();
        } else {
          this.savePresenceForm();
        }
      }
    },
    savePresenceForm() {
      if (this.$refs.deleteVacationModal !== null) {
        this.$refs.deleteVacationModal.hide();
      }

      this.cancelVacation();
      this.updateTemplate();
    },
    updateTemplate() {
      this.isSavingTemplate = true;

      const params = {
        institutionProfileId: this.selectedChild.id,
        byDate: this.selectedTemplateDate,
        presenceActivity: {},
        comment: this.templateComment,
      };
      switch (this.exitType) {
        case exitTypes.PICKED_UP_BY:
          params.presenceActivity.activityType = exitTypes.PICKED_UP_BY;
          params.presenceActivity.pickup = {
            entryTime: this.entryTime,
            exitTime: this.exitTime,
            exitWith: this.exitWith,
          };
          break;
        case exitTypes.SELF_DECIDER:
          params.presenceActivity.activityType = exitTypes.SELF_DECIDER;
          params.presenceActivity.selfDecider = {
            entryTime: this.entryTime,
            exitStartTime: this.selfDeciderStartTime,
            exitEndTime: this.selfDeciderEndTime,
          };
          break;
        case exitTypes.SEND_HOME:
          params.presenceActivity.activityType = exitTypes.SEND_HOME;
          params.presenceActivity.sendHome = {
            entryTime: this.entryTime,
            exitTime: this.exitTime,
          };
          break;
        case exitTypes.GO_HOME_WITH:
          params.presenceActivity.activityType = exitTypes.GO_HOME_WITH;
          params.presenceActivity.goHomeWith = {
            entryTime: this.entryTime,
            exitTime: this.exitTime,
            exitWith: this.exitWith,
          };
          break;
        default:
          params.presenceActivity.activityType = null;
          params.presenceActivity.entryTime = this.entryTime;
          params.presenceActivity.exitTime = this.exitTime;
      }

      if (this.isEditingSpareTime) {
        params.spareTimeActivity = {
          startDate: this.spareTimeActivity.startTime,
          endDate: this.spareTimeActivity.endTime,
          comment: this.spareTimeActivity.comment,
        };
      }

      this.updatePresenceTemplate(params)
        .then(() => {
          this.closeEditingTemplateDrawer();
        })
        .finally(() => {
          this.isSavingTemplate = false;
        });
    },
    cancelVacation() {
      if (this.vacationRemoved && this.hasExistingVacationRequest) {
        const params = {
          eventForm: {
            id: this.vacationFormData.id,
          },
        };

        return this.deleteVacation(params);
      }
    },
    initPresenceConfiguration() {
      this.isConfigLoading = true;
      this.loadPresenceConfiguration({
        institutionCode: this.chosenDepartment.institutionCode,
      }).then(() => {
        this.populatePresenceConfiguration();
        this.isConfigLoading = false;

        if (this.presenceConfiguration[presenceModules.PICKUP_TYPE].readable) {
          if (this.getPresenceConfiguration.pickup) {
            this.loadPickupResponsible({
              uniStudentIds: [this.selectedChild.id],
            });
          }
          if (this.getPresenceConfiguration.goHomeWith) {
            this.loadGoHomeWithList({
              institutionProfileId: this.selectedChild.id,
            });
          }
        }
      });
    },
    onCancelVacation() {
      this.isEditingVacation = false;
      this.toggleVacationRemoved();
    },
    toggleVacationRemoved() {
      if (this.hasExistingVacationRequest && !this.isEditingVacation) {
        this.vacationRemoved = true;
      } else {
        this.vacationRemoved = false;
      }
    },
    initPresenceTemplateForDate() {
      this.isTemplateLoading = true;
      this.loadPresenceTemplateForDate({
        childId: this.selectedChild.id,
        date: this.selectedTemplateDate,
      }).then(() => {
        this.isTemplateLoading = false;
        this.isChildOnVacation = this.activeTemplateForDate.isOnVacation;

        this.isEditingSpareTime = this.activeTemplateForDate.spareTimeActivity != null;
        if (this.isEditingSpareTime) {
          this.spareTimeActivity.startTime = moment(
            this.activeTemplateForDate.spareTimeActivity.startTime,
            'HH:mm:ss',
            true
          ).format('HH:mm');
          this.spareTimeActivity.endTime = moment(
            this.activeTemplateForDate.spareTimeActivity.endTime,
            'HH:mm:ss',
            true
          ).format('HH:mm');
          this.spareTimeActivity.comment = this.activeTemplateForDate.spareTimeActivity.comment;
        } else {
          this.spareTimeActivity.startTime = '13:00';
          this.spareTimeActivity.endTime = '14:00';
          this.spareTimeActivity.comment = '';
        }

        this.isEditingVacation = this.activeTemplateForDate.isOnVacation;
        if (this.isEditingVacation) {
          this.vacationFormData = this.activeTemplateForDate.vacation;
        } else {
          this.vacationFormData = {
            startDateTime: this.selectedTemplateDate,
            endDateTime: null,
            description: null,
          };
        }
        this.exitType = this.activeTemplateForDate.activityType;
        this.exitWith = this.activeTemplateForDate.exitWith;

        if (this.activeTemplateForDate.selfDeciderStartTime === null) {
          this.selfDeciderStartTime = '';
        } else {
          this.selfDeciderStartTime = moment(this.activeTemplateForDate.selfDeciderStartTime, 'HH:mm:ss', true).format(
            'HH:mm'
          );
        }
        if (this.activeTemplateForDate.selfDeciderEndTime === null) {
          this.selfDeciderEndTime = '';
        } else {
          this.selfDeciderEndTime = moment(this.activeTemplateForDate.selfDeciderEndTime, 'HH:mm:ss', true).format(
            'HH:mm'
          );
        }
        if (this.activeTemplateForDate.entryTime === null) {
          this.entryTime = '';
        } else {
          this.entryTime = moment(this.activeTemplateForDate.entryTime || '08:00:00', 'HH:mm:ss', true).format('HH:mm');
        }
        if (this.activeTemplateForDate.exitTime === null) {
          this.exitTime = '';
        } else {
          this.exitTime = moment(this.activeTemplateForDate.exitTime || '16:00:00', 'HH:mm:ss', true).format('HH:mm');
        }
        this.templateComment = this.activeTemplateForDate.comment || '';
      });
    },
    show(child, date) {
      this.focusTarget = this.isMobile ? '.icon-Aula_arrow_new_item.aula-close-arrow' : '.icon-Aula_close.aula-close';
      this.isShown = true;
      this.selectedChild = child;
      this.selectedTemplateDate = date;

      this.initPresenceConfiguration();
      this.initPresenceTemplateForDate();
    },
  },
  components: {
    IconContainer,
    Icon,
    Timepicker,
    VacationForm,
  },
  directives: {
    button,
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables';
@import '../../../shared/assets/scss/core/breakpoints';
@import '../../../shared/assets/scss/components/presence/presence-checkin-dashboard';
@import '../../../shared/assets/scss/components/presence/presence-employee';
@import '../../../shared/assets/scss/core/animations.scss';
</style>
