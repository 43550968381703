<template>
  <header class="subscription-metadata">
    <IconContainer v-if="shownSubscription.sensitive">
      <Icon :name="iconClassEnum.LOCK" />
    </IconContainer>

    <div class="subscription-title-container selectable-text">
      <div class="subscription-title" :title="shownSubscription.subject">
        {{ shownSubscription.subject }}
      </div>
      <div class="subscription-creator">
        {{
          $t('messages.thread_metadata_1', {
            creator: getParticipantNames(shownSubscription.threadCreator),
          })
        }}
        <span class="datetime">
          {{
            $t('messages.thread_metadata_2', {
              startedAt: format(shownSubscription.threadStartedDateTime, dateFormatEnum.LONG_DATE_TIME_WITHOUT_YEAR),
            })
          }}
        </span>
      </div>
    </div>
  </header>
</template>
<script>
import { dateFormatEnum } from '../../../shared/enums/dateFormatEnum';
import { format } from '../../../shared/utils/dateUtil';
import Icon from '../../../shared/components/Icon.vue';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import IconContainer from '../../../shared/components/IconContainer.vue';

export default {
  components: { IconContainer, Icon },
  props: {
    shownSubscription: { Type: Object, default: null },
  },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
    dateFormatEnum() {
      return dateFormatEnum;
    },
    threadParticipants() {
      return this.shownSubscription.recipients.filter(r => r.leaveTime == null);
    },
  },
  methods: {
    format,
    getParticipantNames(item) {
      if (item == null) {
        return '';
      }

      if (item.metadata == null) {
        return item.fullName;
      }

      const children = item.metadata.split(',');

      if (children.length <= 3) {
        return item.fullName + ' (' + item.metadata + ')';
      }

      let name = '';
      const subMedatadata = children.slice(0, 3).join(',');
      name +=
        ' (' +
        subMedatadata +
        ' <span class="childExpand" title="Se mere" id="metadata' +
        item.mailBoxOwner.id +
        '">+</span> ';
      name +=
        '<span class="childList" id="full-metadata' +
        item.mailBoxOwner.id +
        '">' +
        children.slice(4).join(',') +
        '</span>)';

      return name;
    },
  },
};
</script>
<style scoped lang="scss">
.subscription-metadata {
  display: flex;
  flex-direction: row;
  gap: 4px;

  .subscription-title-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-height: 40px;
    width: 100%;

    .subscription-title {
      min-height: 40px;
      font-size: 22px;
      font-weight: 700;
      padding-top: 4px;
    }

    .subscription-creator {
      display: block;
      color: var(--color-grey-dim);
      min-height: 24px;

      .datetime {
        text-transform: capitalize;
      }
    }
  }

  .icon-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    color: var(--color-alert);
    background-color: transparent;

    .aula-icon {
      --font-size: 22px;
      padding-top: 8px;
    }
  }
}
</style>
