<template>
  <div v-if="isSectionVisible" class="specific-opening-hour-container">
    <div v-if="isMobile" class="mobile-box-title">
      {{ 'PRESENCE_SPECIFIC_OPENING_HOURS' | fromTextKey }}
    </div>
    <h2 v-else>{{ 'PRESENCE_SPECIFIC_OPENING_HOURS' | fromTextKey }}</h2>
    <template v-for="(subList, key) in splitSpecificOpeningHourData">
      <div
        :key="'table-' + key"
        :class="{
          'w-50': subList.length === 1 && selectedInstitutions.length !== 1,
        }"
        class="table"
      >
        <div class="table-row header">
          <template v-if="selectedInstitutions.length === 1">
            <div class="table-cell date-header">
              {{ selectedInstitutions.length === 1 ? 'PRESENCE_DATE_LABEL' : '' | fromTextKey }}
            </div>
            <div class="table-cell institution-header">
              {{ 'PRESENCE_OPENING_HOURS' | fromTextKey }}
            </div>
          </template>
          <template v-else>
            <div
              v-for="institutionOpeningHourData in subList"
              :key="institutionOpeningHourData.institutionCode"
              class="table-cell institution-header w-50"
            >
              {{ getInstitutionName(institutionOpeningHourData.institutionCode) }}
            </div>
          </template>
        </div>
        <div v-for="index in getNumberOfRowsForSubGroupTable(subList)" :key="index" class="table-row body">
          <template v-if="selectedInstitutions.length === 1 || isMobile">
            <div class="table-cell date-cell">
              {{
                getFormatDatePeriod(
                  subList[0].specificOpeningHours[index - 1].startDate,
                  subList[0].specificOpeningHours[index - 1].endDate
                )
              }}
            </div>
            <div class="table-cell">
              {{ subList[0].specificOpeningHours[index - 1].openTime }} -
              {{ subList[0].specificOpeningHours[index - 1].closeTime }}
            </div>
          </template>
          <template v-else>
            <div
              v-for="institutionOpeningHourData in subList"
              :key="'cell-' + institutionOpeningHourData.institutionCode"
              class="table-cell"
            >
              <template v-if="institutionOpeningHourData.specificOpeningHours[index - 1]">
                <div class="d-flex mb-1">
                  <div class="label">{{ 'PRESENCE_DATE_LABEL' | fromTextKey }}:</div>
                  {{
                    getFormatDatePeriod(
                      institutionOpeningHourData.specificOpeningHours[index - 1].startDate,
                      institutionOpeningHourData.specificOpeningHours[index - 1].endDate
                    )
                  }}
                </div>
                <div class="d-flex">
                  <div class="label">{{ 'PRESENCE_OPENING_HOURS' | fromTextKey }}:</div>
                  {{ institutionOpeningHourData.specificOpeningHours[index - 1].openTime }}
                  -
                  {{ institutionOpeningHourData.specificOpeningHours[index - 1].closeTime }}
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { types } from '../../store/types/types';
import dateTimeFormatMixin from '../../../shared/mixins/dateTimeFormatMixin';
import dataFormat from '../../../shared/mixins/dataFormatMixin';

export default {
  mixins: [dateTimeFormatMixin, dataFormat],
  props: {
    selectedInstitutions: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
      specificOpeningHoursForGuardian: types.GET_SPECIFIC_OPENING_HOURS_FOR_GUARDIAN,
    }),
    splitSpecificOpeningHourData() {
      const chunk = this.isMobile ? 1 : 2;
      return this.splitDataToChunks(this.specificOpeningHoursForGuardian, chunk);
    },
    isSectionVisible() {
      return this.specificOpeningHoursForGuardian.length > 0;
    },
  },
  methods: {
    getNumberOfRowsForSubGroupTable(subGroup) {
      const groupLengthArr = subGroup.map(item => item.specificOpeningHours.length);
      return Math.max(...groupLengthArr);
    },
    getInstitutionName(institutionCode) {
      const inst = this.selectedInstitutions.find(institution => institution.institutionCode === institutionCode);
      if (inst) {
        return inst.name;
      }
      return '';
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../../shared/assets/scss/core/variables';
@import '../../../shared/assets/scss/core/breakpoints';
@import '../../../shared/assets/scss/components/presence/presence';
</style>
