<template>
  <div class="aula-search" :title="'SEARCH_LABEL_CHILD' | fromTextKey">
    <div
      id="dropdown-search"
      class="header-dropdown"
      :class="{ active: showSearchDropdown }"
      :aria-label="'ARIA_LABEL_GLOBAL_SEARCH' | fromTextKey"
      tabindex="0"
      role="button"
      @click="startSearch"
      @keyup.enter="startSearch"
    >
      <span class="sr-only">{{ 'GLOBAL_SEARCH_PLACEHOLDER' | fromTextKey }}</span>
      <i v-if="!isChild" class="icon-Aula_search" aria-hidden="true" />
      <img v-else class="menu-icon" src="static/icons/icon-Aula_search_child.svg" aria-hidden="true" />
    </div>
    <transition
      name="slide-fade"
      :leave-active-class="isClickedCurrentTab ? 'slide-fade-leave-active' : 'slide-fade-leave-active-hide'"
      :leave-to-class="isClickedCurrentTab ? 'slide-fade-leave-to' : 'leave-to'"
    >
      <div v-show="showSearchDropdown" class="header-dropdown-menu-search" role="dialog">
        <div class="header-dropdown-title">
          {{ 'SEARCH_LABEL' | fromTextKey }}
        </div>
        <div class="header-dropdown-close">
          <i
            class="icon-Aula_close"
            role="button"
            tabindex="0"
            :aria-label="'ARIA_LABEL_CLOSE' | fromTextKey"
            @click="hideDropdown"
            @keydown.enter="hideDropdown"
          />
        </div>
        <div class="header-dropdown-search-field">
          <AulaSearchInput
            v-model="searchString"
            class="m-2"
            :aria-label="'GLOBAL_SEARCH_PLACEHOLDER' | fromTextKey"
            :placeholder="'GLOBAL_SEARCH_PLACEHOLDER' | fromTextKey"
            @onSearchClicked="search"
            @onClearClicked="clearSearchInput"
          />
        </div>
        <div v-if="!isMobile && !isSteppedUp" class="search-step-up-container">
          <div class="search-step-up">
            <div class="icon-container">
              <div class="icon-circle">
                <Icon :name="iconClassEnum.EXCLAMATION_SIGN" />
              </div>
            </div>
            <div class="step-up-text">
              {{ 'SEARCH_SENSITIVE_STEP_UP_TITLE' | fromTextKey }}:<br />
              <b-link class="link-text" @click="redirectToStepUp">
                {{ 'SEARCH_SENSITIVE_STEP_UP_CONTENT' | fromTextKey }}
              </b-link>
            </div>
          </div>
        </div>
        <template v-if="numberOfResults.countTotal > 0">
          <div class="search-filter-container">
            <div class="search-filter">
              {{ 'SEARCH_FILTER_LABEL' | fromTextKey }} (<span class="number-of-results-mobile"
                >({{ numberOfResults.countTotal }})</span
              >{{ numberOfResults.countTotal }})
            </div>

            <template v-if="numberOfResults.countProfiles > 0">
              <div
                class="header-dropdown-item-search filter-dropdown"
                tabindex="0"
                :class="{ active: showResult === docTypes.PROFILE }"
                @keydown.enter="setShowResult((filterValue = docTypes.PROFILE))"
              >
                <b-row
                  class="filter-dropdown-header row-no-margin"
                  @click="setShowResult((filterValue = docTypes.PROFILE))"
                >
                  <b-col cols="10">
                    {{ 'SEARCH_FILTER_PROFILES_LABEL' | fromTextKey }}
                    <span class="number-of-results-mobile">({{ numberOfResults.countProfiles }})</span>
                  </b-col>
                  <b-col cols="2" class="text-right number-of-results">
                    {{ numberOfResults.countProfiles }}
                  </b-col>
                </b-row>
                <template v-if="showResult === docTypes.PROFILE">
                  <b-form-checkbox
                    class="filter-dropdown-item"
                    :checked="showSubresults.includes(portalRoles.CHILD)"
                    @change="changeSubresults(portalRoles.CHILD)"
                  >
                    {{ 'SEARCH_FILTER_CHILDREN_LABEL' | fromTextKey }} ({{ numberOfResults.countChildren }})
                  </b-form-checkbox>
                  <b-form-checkbox
                    class="filter-dropdown-item"
                    :checked="showSubresults.includes(portalRoles.GUARDIAN)"
                    @change="changeSubresults(portalRoles.GUARDIAN)"
                  >
                    {{ 'SEARCH_FILTER_GUARDIANS_LABEL' | fromTextKey }} ({{ numberOfResults.countGuardians }})
                  </b-form-checkbox>
                  <b-form-checkbox
                    class="filter-dropdown-item"
                    :checked="showSubresults.includes(portalRoles.EMPLOYEE)"
                    @change="changeSubresults(portalRoles.EMPLOYEE)"
                  >
                    {{ 'SEARCH_FILTER_EMPLOYEES_LABEL' | fromTextKey }} ({{ numberOfResults.countEmployees }})
                  </b-form-checkbox>
                </template>
              </div>
            </template>
            <template v-if="numberOfResults.countGroups > 0">
              <div
                class="header-dropdown-item-search filter-dropdown"
                tabindex="0"
                role="button"
                :class="{ active: showResult === docTypes.GROUP }"
                @keydown.enter="setShowResult((filterValue = docTypes.GROUP))"
              >
                <b-row class="row-no-margin" @click="setShowResult((filterValue = docTypes.GROUP))">
                  <b-col cols="10">
                    {{ 'SEARCH_FILTER_GROUPS_LABEL' | fromTextKey }}
                    <span class="number-of-results-mobile">({{ numberOfResults.countGroups }})</span>
                  </b-col>
                  <b-col cols="2" class="text-right number-of-results">
                    {{ numberOfResults.countGroups }}
                  </b-col>
                </b-row>
                <template v-if="showResult == docTypes.GROUP">
                  <b-form-checkbox
                    class="filter-dropdown-item"
                    :checked="showGroupSubresults.includes(groupTypes.INSTITUTIONAL)"
                    @change="changeGroupSubresults(groupTypes.INSTITUTIONAL)"
                  >
                    {{ 'INSTITUTIONAL_GROUP' | fromTextKey }} ({{ numberOfResults.countInstitutionalGroups }})
                  </b-form-checkbox>
                  <b-form-checkbox
                    class="filter-dropdown-item"
                    :checked="showGroupSubresults.includes(groupTypes.MUNICIPAL)"
                    @change="changeGroupSubresults(groupTypes.MUNICIPAL)"
                  >
                    {{ 'MUNICIPAL_GROUP' | fromTextKey }} ({{ numberOfResults.countMunicipalGroups }})
                  </b-form-checkbox>
                </template>
              </div>
            </template>
            <template v-if="numberOfResults.countInternalDocuments > 0">
              <div
                class="header-dropdown-item-search"
                tabindex="0"
                role="button"
                :class="{
                  active: showResult === docTypes.INTERNAL_SECURE_FILE,
                }"
                @click="setShowResult((filterValue = docTypes.INTERNAL_SECURE_FILE))"
                @keydown.enter="setShowResult((filterValue = docTypes.INTERNAL_SECURE_FILE))"
              >
                <b-row class="row-no-margin">
                  <b-col cols="10">
                    {{ 'SEARCH_FILTER_INTERNAL_DOCUMENTS_LABEL' | fromTextKey }}
                    <span class="number-of-results-mobile">({{ numberOfResults.countInternalDocuments }})</span>
                  </b-col>
                  <b-col cols="2" class="text-right number-of-results">
                    {{ numberOfResults.countInternalDocuments }}
                  </b-col>
                </b-row>
              </div>
            </template>
            <template v-if="numberOfResults.countExternalDocuments > 0">
              <div
                class="header-dropdown-item-search"
                tabindex="0"
                role="button"
                :class="{
                  active: showResult === docTypes.EXTERNAL_SECURE_FILE,
                }"
                @click="setShowResult((filterValue = docTypes.EXTERNAL_SECURE_FILE))"
                @keydown.enter="setShowResult((filterValue = docTypes.EXTERNAL_SECURE_FILE))"
              >
                <b-row class="row-no-margin">
                  <b-col cols="10">
                    {{ 'SEARCH_FILTER_EXTERNAL_DOCUMENTS_LABEL' | fromTextKey }}
                    <span class="number-of-results-mobile">({{ numberOfResults.countExternalDocuments }})</span>
                  </b-col>
                  <b-col cols="2" class="text-right number-of-results">
                    {{ numberOfResults.countExternalDocuments }}
                  </b-col>
                </b-row>
              </div>
            </template>
            <template v-if="numberOfResults.countCommonFiles > 0">
              <div
                class="header-dropdown-item-search"
                tabindex="0"
                role="button"
                :class="{ active: showResult === docTypes.IMPORTANT_FILE }"
                @click="setShowResult((filterValue = docTypes.IMPORTANT_FILE))"
                @keydown.enter="setShowResult((filterValue = docTypes.IMPORTANT_FILE))"
              >
                <b-row class="row-no-margin">
                  <b-col cols="10">
                    {{ 'SEARCH_FILTER_COMMON_FILES_LABEL' | fromTextKey }}
                    <span class="number-of-results-mobile">({{ numberOfResults.countCommonFiles }})</span>
                  </b-col>
                  <b-col cols="2" class="text-right number-of-results">
                    {{ numberOfResults.countCommonFiles }}
                  </b-col>
                </b-row>
              </div>
            </template>
            <template v-if="numberOfResults.countThreadMessages > 0">
              <div
                class="header-dropdown-item-search"
                tabindex="0"
                role="button"
                :class="{ active: showResult === docTypes.THREAD_MESSAGE }"
                @click="setShowResult((filterValue = docTypes.THREAD_MESSAGE))"
                @keydown.enter="setShowResult((filterValue = docTypes.THREAD_MESSAGE))"
              >
                <b-row class="row-no-margin">
                  <b-col cols="10">
                    {{ 'SEARCH_FILTER_MESSAGES_LABEL' | fromTextKey }}
                    <span class="number-of-results-mobile">({{ numberOfResults.countThreadMessages }})</span>
                  </b-col>
                  <b-col cols="2" class="text-right number-of-results">
                    {{ numberOfResults.countThreadMessages }}
                  </b-col>
                </b-row>
              </div>
            </template>
            <template v-if="numberOfResults.countEvents > 0">
              <div
                class="header-dropdown-item-search"
                tabindex="0"
                role="button"
                :class="{ active: showResult === docTypes.EVENT }"
                @click="setShowResult((filterValue = docTypes.EVENT))"
                @keydown.enter="setShowResult((filterValue = docTypes.EVENT))"
              >
                <b-row class="row-no-margin">
                  <b-col cols="10">
                    {{ 'SEARCH_FILTER_EVENTS_LABEL' | fromTextKey }}
                    <span class="number-of-results-mobile">({{ numberOfResults.countEvents }})</span>
                  </b-col>
                  <b-col cols="2" class="text-right number-of-results">
                    {{ numberOfResults.countEvents }}
                  </b-col>
                </b-row>
              </div>
            </template>
            <template v-if="numberOfResults.countPosts > 0">
              <div
                class="header-dropdown-item-search"
                tabindex="0"
                role="button"
                :class="{ active: showResult === docTypes.POST }"
                @click="setShowResult((filterValue = docTypes.POST))"
                @keydown.enter="setShowResult((filterValue = docTypes.POST))"
              >
                <b-row class="row-no-margin">
                  <b-col cols="10">
                    {{ 'SEARCH_FILTER_POSTS_LABEL' | fromTextKey }}
                    <span class="number-of-results-mobile">({{ numberOfResults.countPosts }})</span>
                  </b-col>
                  <b-col cols="2" class="text-right number-of-results">
                    {{ numberOfResults.countPosts }}
                  </b-col>
                </b-row>
              </div>
            </template>
            <template v-if="numberOfResults.countMedias > 0">
              <div
                class="header-dropdown-item-search"
                tabindex="0"
                role="button"
                :class="{ active: showResult === docTypes.MEDIA }"
                @click="setShowResult((filterValue = docTypes.MEDIA))"
                @keydown.enter="setShowResult((filterValue = docTypes.MEDIA))"
              >
                <b-row class="row-no-margin">
                  <b-col cols="10">
                    {{ 'SEARCH_FILTER_MEDIA_LABEL' | fromTextKey }}
                    <span class="number-of-results-mobile">({{ numberOfResults.countMedias }})</span>
                  </b-col>
                  <b-col cols="2" class="text-right number-of-results">
                    {{ numberOfResults.countMedias }}
                  </b-col>
                </b-row>
              </div>
            </template>
          </div>
        </template>
      </div>
    </transition>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { types } from '../../store/types/types';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { docTypes } from '../../../shared/enums/docTypes';
import { groupTypes } from '../../../shared/enums/groupTypes';
import { institutionRole } from '../../../shared/enums/institutionRole';
import $ from 'jquery';
import AulaSearchInput from '../../../shared/components/AulaSearchInput';
import Icon from '../../../shared/components/Icon';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';

export default {
  components: { Icon, AulaSearchInput },
  data: function () {
    return {
      portalRoles: portalRoles,
      docTypes: docTypes,
      groupTypes: groupTypes,
      institutionRole: institutionRole,
      searchString: '',
      searchPlaceholder: 'Søg...',
      showSubresults: [portalRoles.CHILD, portalRoles.GUARDIAN, portalRoles.EMPLOYEE],
      showGroupSubresults: [groupTypes.INSTITUTIONAL, groupTypes.MUNICIPAL],
    };
  },
  computed: {
    ...mapGetters({
      isPreviewMode: types.GET_IS_PREVIEW_MODE,
      isMobile: types.GET_IS_MOBILE,
      profile: types.GET_CURRENT_PROFILE,
      clientHeight: types.GET_CLIENT_HEIGHT,
      showGroupsDropdown: types.GET_SHOW_GROUPS_DROPDOWN,
      showSearchDropdown: types.GET_SHOW_SEARCH_DROPDOWN,
      showProfileDropdown: types.GET_SHOW_PROFILE_DROPDOWN,
      searchResults: types.GET_GLOBAL_SEARCH_RESULTS,
      numberOfResults: types.GET_NUMBER_OF_RESULTS,
      showResult: types.GET_SHOW_RESULT,
      getStatusSearchBar: types.GET_STATUS_SEARCH_BAR,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
      activeChildren: types.GET_ACTIVE_CHILDREN,
      isSteppedUp: types.GET_GLOBAL_STEPPED_UP,
    }),
    iconClassEnum() {
      return iconClassEnum;
    },
    isClickedCurrentTab: function () {
      return this.showSearchDropdown || (!this.showGroupsDropdown && !this.showProfileDropdown);
    },
    isChild() {
      return this.profile.institutionRoles.includes(this.institutionRole.EARLY_STUDENT);
    },
  },
  directives: {
    focus: {
      inserted: function (el) {
        el.focus();
      },
    },
  },
  methods: {
    clearSearchInput() {
      this.searchString = '';
    },
    search() {
      this.searchString = this.searchString.trim();
      if (this.searchString != '') {
        this.showSubresults = [portalRoles.CHILD, portalRoles.GUARDIAN, portalRoles.EMPLOYEE];
        this.loadSearchResults({
          query: this.searchString,
          docType: this.showResult,
        });
        this.$router.push({ name: 'soeg' });
        setTimeout(function () {
          document.querySelector('.search-result-container .search-result-header').focus();
        }, 500);
        this.setSearchString(this.searchString);
      }
    },
    changeSubresults: function (value) {
      if (value === 'reload') {
        this.setShowSubresults({
          roles: (this.showSubresults = [portalRoles.CHILD, portalRoles.GUARDIAN, portalRoles.EMPLOYEE]),
          docType: docTypes.PROFILE,
        });
      } else {
        const index = this.showSubresults.indexOf(value);
        if (index > -1) {
          this.showSubresults.splice(index, 1);
        } else {
          this.showSubresults.push(value);
        }
        this.setShowSubresults({
          roles: this.showSubresults,
          docType: 'Profile',
        });
      }
    },
    changeGroupSubresults(groupType) {
      const index = this.showGroupSubresults.indexOf(groupType);
      if (index > -1) {
        this.showGroupSubresults.splice(index, 1);
      } else {
        this.showGroupSubresults.push(groupType);
      }
      this.setShowSubresults({
        groupTypes: this.showGroupSubresults,
        docType: docTypes.GROUP,
      });
    },
    startSearch: function () {
      if (!this.isPreviewMode) {
        this.setStatusSearchBar(!this.getStatusSearchBar);
        this.toggleHeaderDropdown({
          dropdown: 'search',
          visible: this.showSearchDropdown,
        });
        this.$router.push({ name: 'soeg' });
        Vue.nextTick(function () {
          $('.aula-search input.search-input').focus();
        });
      }
    },
    redirectToStepUp() {
      localStorage.setItem('redirectedurlafterstepup', window.location.href);
      localStorage.setItem('redirectedurlbeforestepup', window.location.href);
      window.location.href = '/auth/stepup.php';
    },
    hideDropdown() {
      this.$store.commit(types.MUTATE_HIDE_SEARCH_DROPDOWN);
      this.setStatusSearchBar(false);
    },
    ...mapActions({
      setSearchString: types.SET_SEARCH_STRING,
      loadSearchResults: types.LOAD_GLOBAL_SEARCH_RESULTS,
      toggleHeaderDropdown: types.TOGGLE_HEADER_DROPDOWN,
      setShowResult: types.SET_SHOW_RESULT,
      setShowSubresults: types.SET_SHOW_SUBRESULTS,
      setStatusSearchBar: types.SET_STATUS_SEARCH_BAR,
    }),
    ...mapMutations({
      resetSearchResults: types.MUTATE_RESET_SEARCH_RESULTS,
    }),
  },
  watch: {
    $route(to, from) {
      if (from.name === 'soeg') {
        this.hideDropdown();
      }
    },
    activeInstitutions() {
      if (this.$route.path.includes('/soeg')) {
        this.search();
      }
    },
    activeChildren() {
      if (this.$route.path.includes('/soeg')) {
        this.search();
      }
    },
    profile(to, from) {
      if (from.role !== to.role) {
        this.searchString = '';
        this.resetSearchResults();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/core/animations.scss';
.aula-search-input {
  @include breakpoint-lg-down() {
    --input-min-height: 40px;
    width: calc(100% - 45px);
  }
}
.aula-search {
  border-right: 1px solid $color-white;
  @include breakpoint-lg-down() {
    border-left: 1px solid $color-white;
  }
}
img.menu-icon {
  width: var(--default-menu-icon-size);
}

.small-text {
  text-transform: uppercase;
  .early-student & {
    color: #fff !important;
    text-transform: capitalize;
    font-family: Baloo;
    font-weight: normal;
    font-size: 15px !important;
  }
}

@include breakpoint-lg() {
  .header-dropdown-menu-search .header-dropdown-close {
    color: inherit;
  }
}

.header-dropdown-search-field {
  height: 58px;
  display: flex;
  align-items: center;
  background-color: var(--color-primary-dark);

  @include breakpoint-lg() {
    padding-left: 0;
    padding-top: 0;
    background-color: transparent;
  }
}

.header-dropdown-title {
  display: none;
  width: 50%;
  @include breakpoint-lg() {
    display: block;
    padding-top: 30px;
  }
}

.search-filter {
  font-size: 13px;
  margin: 50px 0 13px 0;
  display: none;
  @include breakpoint-lg() {
    display: block;
  }
}

.header-dropdown-menu-search {
  .icon-Aula_close {
    cursor: pointer;
  }
}

.filter-dropdown {
  .filter-dropdown-item {
    display: none;
    @include breakpoint-lg() {
      display: block;
    }
  }
  &.active {
    br {
      display: none;
      @include breakpoint-lg() {
        display: block;
      }
    }
    background-color: $color-white;
    @include breakpoint-lg-down() {
      background-color: $color-grey-dark;
    }
    @include breakpoint-lg() {
      height: 100%;
    }
  }
}
</style>
