var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.getPresenceActiveModules[_vm.presenceModules.VACATION] &&
    _vm.getPresenceActiveModules[_vm.presenceModules.VACATION].length > 0
  )?_c('div',{staticClass:"guardian-vacation-announcement-container"},[(!_vm.isMobile)?_c('h2',[_vm._v("\n    "+_vm._s(_vm._f("fromTextKey")('PRESENCE_REPORT_VACATION_ANNOUNCEMENT'))+"\n  ")]):_c('div',{staticClass:"mobile-box-title"},[_vm._v("\n    "+_vm._s(_vm._f("fromTextKey")('PRESENCE_REPORT_VACATION_ANNOUNCEMENT'))+"\n    "),_c('help-box',{attrs:{"id":"vacation-announcement-help-box","title":_vm.$options.filters.fromTextKey('PRESENCE_REPORT_VACATION_ANNOUNCEMENT'),"body":((_vm.$options.filters.fromTextKey(
        'PRESENCE_VACATION_ANNOUNCEMENT_DESCRIPTION_1'
      )) + " " + (_vm.$options.filters.fromTextKey('PRESENCE_VACATION_ANNOUNCEMENT_DESCRIPTION_2')))}})],1),_vm._v(" "),_c('div',{staticClass:"mb-3 d-flex justify-content-between"},[(!_vm.isMobile)?_c('div',[_vm._v("\n      "+_vm._s(_vm._f("fromTextKey")('PRESENCE_VACATION_ANNOUNCEMENT_DESCRIPTION_1'))+"\n      "+_vm._s(_vm._f("fromTextKey")('PRESENCE_VACATION_ANNOUNCEMENT_DESCRIPTION_2'))+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.hasCustodyOverActiveChildren)?_c('b-btn',{staticClass:"ml-lg-4 my-lg-0 mt-3 register-vacation-button",attrs:{"variant":"primary"},on:{"click":_vm.onRegisterVacationClicked}},[_vm._v("\n      "+_vm._s(_vm._f("fromTextKey")('BUTTON_CREATE'))+"\n    ")]):_vm._e()],1),_vm._v(" "),(_vm.isLoading)?_c('aula-spinner'):(_vm.splitVacationAnnouncementData.length > 0)?_vm._l((_vm.splitVacationAnnouncementData),function(subVacationAnnouncements,i){return _c('div',{key:i,staticClass:"table",class:{ 'w-50': subVacationAnnouncements.length === 1 && !_vm.isMobile }},[_c('div',{staticClass:"table-row header"},_vm._l((subVacationAnnouncements),function(childData,j){return _c('div',{key:j,staticClass:"table-cell"},[(childData.child)?_c('div',{staticClass:"d-flex align-items-center"},[_c('div',[_c('user-icon',{attrs:{"id":childData.child.id,"name":childData.child.name,"short-name":childData.child.shortName,"picture-object":childData.child.profilePicture,"user-icon-class":"small","show-inline":true}})],1),_vm._v(" "),_c('div',{staticClass:"ml-3 flex-grow-1"},[_vm._v("\n              "+_vm._s(childData.child.name)+"\n              "),_c('span',{staticClass:"font-weight-normal"},[_vm._v("("+_vm._s(childData.child.metadata)+")")])])]):_vm._e()])}),0),_vm._v(" "),_vm._l((_vm.getNumberOfRowsForSubGroupTable(subVacationAnnouncements)),function(index,j){return _c('div',{key:j,staticClass:"table-row body"},_vm._l((subVacationAnnouncements),function(childData,k){return _c('div',{key:k,staticClass:"table-cell",class:{
            'pointer-cursor': childData.vacationAnnouncements[index - 1],
          },attrs:{"tabindex":childData.vacationAnnouncements[index - 1] ? 0 : -1},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.hasCustodyOrExtendedAccess(childData.child.id)
              ? _vm.showVacationAnnouncement(childData.vacationAnnouncements[index - 1], true)
              : undefined},"click":function($event){_vm.hasCustodyOrExtendedAccess(childData.child.id)
              ? _vm.showVacationAnnouncement(childData.vacationAnnouncements[index - 1], true)
              : undefined}}},[(childData.vacationAnnouncements[index - 1])?_c('div',{staticClass:"cell-information d-flex justify-content-between"},[_c('div',[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"absence-label"},[_vm._v(_vm._s(_vm._f("fromTextKey")('PRESENCE_REPORT_PERIOD'))+":")]),_vm._v("\n                "+_vm._s(((_vm.convertDateFormat(childData.vacationAnnouncements[index - 1].startDate)) + " - " + (_vm.convertDateFormat(
                    childData.vacationAnnouncements[index - 1].endDate
                  ))))+"\n              ")]),_vm._v(" "),(childData.vacationAnnouncements[index - 1].description)?_c('div',{staticClass:"word-wrap d-flex"},[_c('div',{staticClass:"absence-label"},[_vm._v(_vm._s(_vm._f("fromTextKey")('PRESENCE_VACATION_NOTE_SHORT'))+":")]),_vm._v("\n                "+_vm._s(childData.vacationAnnouncements[index - 1].description)+"\n              ")]):_vm._e()]),_vm._v(" "),(_vm.isMobile)?_c('i',{staticClass:"icon icon-Aula_forward-arrow"}):(
                childData.vacationAnnouncements[index - 1].isEditable &&
                _vm.hasCustodyOrExtendedAccess(childData.child.id)
              )?[_c('b-btn',{staticClass:"edit-button",attrs:{"variant":"link","aria-label":_vm._f("fromTextKey")('ARIA_LABEL_EDIT')},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();return _vm.showVacationAnnouncement(childData.vacationAnnouncements[index - 1], false)},"click":function($event){$event.stopPropagation();return _vm.showVacationAnnouncement(childData.vacationAnnouncements[index - 1], false)}}},[_c('i',{staticClass:"icon icon-Aula_edit_pen"})])]:_vm._e()],2):_vm._e(),_vm._v(" "),(_vm.isMobile)?_c('hr'):_vm._e()])}),0)})],2)}):_c('div',{staticClass:"d-flex font-weight-bold"},[_vm._v("\n    "+_vm._s(_vm._f("fromTextKey")('PRESENCE_WARNING_VACATION_ANNOUNCEMENTS_NO_RESULTS'))+"\n  ")]),_vm._v(" "),_c('register-vacation-drawer',{ref:"registerVacationDrawer",on:{"onClosed":_vm.loadData}})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }