<!-- eslint-disable -->
<template>
  <div class="user">
    <user-icon
      class="icon"
      :id="instProfile.id"
      :key="instProfile.id"
      :name="instProfile.firstName + ' ' + instProfile.lastName"
      :short-name="instProfile.shortName"
      :picture-object="instProfile.profilePicture"
    />
    <div class="user-info">
      <div class="user-fullname" :class="{ main: !isChild }">
        {{ instProfile.firstName }} {{ instProfile.lastName }}
      </div>
      <div v-if="showInstName" class="user-institution">
        {{ instProfile.institutionName }}
      </div>
      <div v-if="instProfile.role != portalRoles.EMPLOYEE" class="user-address">
        {{ instProfile.adress | generateAdress }}
      </div>
    </div>
    <div class="user-update">
      <template v-if="instProfile.role != portalRoles.EMPLOYEE">
        <b-input-group>
          <b-input-group-prepend>
            <i class="icon icon-Aula_phone" role="figure" :aria-label="'ARIA_LABEL_HOME_PHONE' | fromTextKey" />
            <div class="contact-type">{{ 'PROFILE_HOME_PHONE_LABEL' | fromTextKey }}:</div>
          </b-input-group-prepend>
          <b-form-input
            v-model="instProfile.homePhoneNumber"
            class="onboarding-input"
            autocomplete="tel"
            type="tel"
            :aria-label="
              Vue.filter('fromTextKey')('ARIA_LABEL_INPUT_HOME_PHONENUMBER', {
                profileName: instProfile.firstName + ' ' + instProfile.lastName,
                institutionName: instProfile.institutionName,
              })
            "
            :class="{ 'red-border': !isHomePhoneNumberValid }"
          />
          <b-input-group-append>
            <i
              v-if="showSubmitButton('homePhoneNumber')"
              class="icon icon-Aula_edit_pen pen"
              role="figure"
              :aria-label="'ARIA_LABEL_EDIT' | fromTextKey"
            />
            <b-btn v-else-if="isHomePhoneNumberValid" variant="link" @click="updateProfile('homePhoneNumber')">
              {{ 'OK' | fromTextKey }}
            </b-btn>
            <b-alert variant="danger" :show="!isHomePhoneNumberValid">
              <span class="sr-only">{{
                'ARIA_LABEL_INVALID_HOME_PHONENUMBER'
                  | fromTextKey({
                    profileName: instProfile.firstName + ' ' + instProfile.lastName,
                    institutionName: instProfile.institutionName,
                  })
              }}</span>
            </b-alert>
          </b-input-group-append>
        </b-input-group>

        <b-input-group>
          <b-input-group-prepend>
            <i class="icon icon-Aula_phone" :aria-label="'ARIA_LABEL_MOBILE_PHONE' | fromTextKey" role="figure" />
            <div class="contact-type">{{ 'PROFILE_MOBILE_PHONE_LABEL' | fromTextKey }}:</div>
          </b-input-group-prepend>
          <b-form-input
            v-model="instProfile.mobilePhoneNumber"
            class="onboarding-input"
            type="tel"
            autocomplete="tel"
            :aria-label="
              Vue.filter('fromTextKey')('ARIA_LABEL_INPUT_MOBILE_PHONENUMBER', {
                profileName: instProfile.firstName + ' ' + instProfile.lastName,
                institutionName: instProfile.institutionName,
              })
            "
            :class="{ 'red-border': !isMobilePhoneNumberValid }"
          />
          <b-input-group-append>
            <i
              v-if="showSubmitButton('mobilePhoneNumber')"
              class="icon icon-Aula_edit_pen pen"
              :aria-label="'ARIA_LABEL_EDIT' | fromTextKey"
              role="figure"
            />
            <b-btn v-else-if="isMobilePhoneNumberValid" variant="link" @click="updateProfile('mobilePhoneNumber')">
              {{ 'OK' | fromTextKey }}
            </b-btn>
            <b-alert variant="danger" :show="!isMobilePhoneNumberValid">
              <span class="sr-only">{{
                'ARIA_LABEL_INVALID_MOBILE_PHONENUMBER'
                  | fromTextKey({
                    profileName: instProfile.firstName + ' ' + instProfile.lastName,
                    institutionName: instProfile.institutionName,
                  })
              }}</span>
            </b-alert>
          </b-input-group-append>
        </b-input-group>
      </template>
      <b-input-group v-if="!isChild">
        <b-input-group-prepend>
          <i class="icon icon-Aula_phone" :aria-label="'ARIA_LABEL_WORK_PHONE' | fromTextKey" role="figure" />
          <div class="contact-type">{{ 'PROFILE_WORK_PHONE_LABEL' | fromTextKey }}:</div>
        </b-input-group-prepend>
        <b-form-input
          v-model="instProfile.workPhoneNumber"
          class="onboarding-input"
          type="tel"
          autocomplete="tel"
          :aria-label="
            Vue.filter('fromTextKey')('ARIA_LABEL_INPUT_WORK_PHONENUMBER', {
              profileName: instProfile.firstName + ' ' + instProfile.lastName,
              institutionName: instProfile.institutionName,
            })
          "
          :class="{ 'red-border': !isWorkPhoneNumberValid }"
        />
        <b-input-group-append>
          <i
            v-if="showSubmitButton('workPhoneNumber')"
            class="icon icon-Aula_edit_pen pen"
            role="figure"
            :aria-label="'ARIA_LABEL_EDIT' | fromTextKey"
          />
          <b-btn v-else-if="isWorkPhoneNumberValid" variant="link" @click="updateProfile('workPhoneNumber')">
            {{ 'OK' | fromTextKey }}
          </b-btn>
          <b-alert variant="danger" :show="!isWorkPhoneNumberValid">
            <span class="sr-only">{{
              'ARIA_LABEL_INVALID_WORK_PHONENUMBER'
                | fromTextKey({
                  profileName: instProfile.firstName + ' ' + instProfile.lastName,
                  institutionName: instProfile.institutionName,
                })
            }}</span>
          </b-alert>
        </b-input-group-append>
      </b-input-group>
      <b-input-group>
        <b-input-group-prepend>
          <i class="icon icon-Aula_email email" :aria-label="'ARIA_LABEL_EMAIL' | fromTextKey" role="figure" />
          <div class="contact-type">{{ 'PROFILE_EMAIL_LABEL' | fromTextKey }}:</div>
        </b-input-group-prepend>
        <b-form-input
          v-model="instProfile.email"
          type="email"
          autocomplete="email"
          class="onboarding-input last"
          :aria-label="
            Vue.filter('fromTextKey')('ARIA_LABEL_INPUT_EMAIL', {
              profileName: instProfile.firstName + ' ' + instProfile.lastName,
              institutionName: instProfile.institutionName,
            })
          "
          :class="{ 'red-border': !isEmailValid }"
        />
        <b-input-group-append>
          <i
            v-if="showSubmitButton('email')"
            class="icon icon-Aula_edit_pen pen"
            role="figure"
            :aria-label="
              Vue.filter('fromTextKey')('ARIA_LABEL_EDIT', {
                profileName: instProfile.firstName + ' ' + instProfile.lastName,
                institutionName: instProfile.institutionName,
              })
            "
          />
          <b-btn v-else-if="isEmailValid" variant="link" @click="updateProfile('email')">
            {{ 'OK' | fromTextKey }}
          </b-btn>
          <b-alert variant="danger" :show="!isEmailValid">
            <span class="sr-only">{{
              'ARIA_LABEL_INVALID_EMAIL'
                | fromTextKey({
                  profileName: instProfile.firstName + ' ' + instProfile.lastName,
                  institutionName: instProfile.institutionName,
                })
            }}</span>
          </b-alert>
        </b-input-group-append>
      </b-input-group>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { portalRoles } from '../../../shared/enums/portalRoles';
import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';

export default {
  props: {
    instProfile: Object,
    profileIndex: Number,
    institutionProfileIndex: Number,
    childIndex: Number,
    showPortalRole: { type: Boolean, default: false },
    showInstName: { type: Boolean, default: false },
    isChild: { type: Boolean, default: false },
  },
  data: function () {
    return {
      orginalProfile: {},
      aulaContactInfo: {},
      portalRoles: portalRoles,
      disableSubmitButton: {
        homePhoneNumber: false,
        mobilePhoneNumber: false,
        workPhoneNumber: false,
        email: false,
      },
      Vue: Vue,
    };
  },
  computed: {
    isHomePhoneNumberValid() {
      if (!this.$options.filters.isPhoneNumber(this.instProfile.homePhoneNumber) && this.instProfile.homePhoneNumber) {
        this.disableSubmitButton.homePhoneNumber = true;
        return false;
      } else {
        this.disableSubmitButton.homePhoneNumber = false;
        return true;
      }
    },
    isMobilePhoneNumberValid() {
      if (
        !this.$options.filters.isPhoneNumber(this.instProfile.mobilePhoneNumber) &&
        this.instProfile.mobilePhoneNumber
      ) {
        this.disableSubmitButton.mobilePhoneNumber = true;
        return false;
      } else {
        this.disableSubmitButton.mobilePhoneNumber = false;
        return true;
      }
    },
    isWorkPhoneNumberValid() {
      if (!this.$options.filters.isPhoneNumber(this.instProfile.workPhoneNumber) && this.instProfile.workPhoneNumber) {
        this.disableSubmitButton.workPhoneNumber = true;
        return false;
      } else {
        this.disableSubmitButton.workPhoneNumber = false;
        return true;
      }
    },
    isEmailValid() {
      if (!this.$options.filters.isEmail(this.instProfile.email) && this.instProfile.email) {
        this.disableSubmitButton.email = true;
        return false;
      } else {
        this.disableSubmitButton.email = false;
        return true;
      }
    },
  },
  watch: {
    disableSubmitButton: {
      handler: function () {
        let isDisabled =
          this.disableSubmitButton.homePhoneNumber ||
          this.disableSubmitButton.mobilePhoneNumber ||
          this.disableSubmitButton.email;
        if (!this.isChild) {
          isDisabled = isDisabled || this.disableSubmitButton.workPhoneNumber;
        }
        this.$emit('disableSubmitButton', isDisabled, this.instProfile.id);
      },
      deep: true,
    },
  },
  mounted() {
    this.orginalProfile = cloneDeep(this.instProfile);
  },
  methods: {
    getAriaLabel(key) {
      return Vue.filter.fromTextKey(key, {
        profileName: this.instProfile.firstName + ' ' + this.instProfile.lastName,
        institutionName: this.instProfile.institutionName,
      });
    },
    updateProfile(attribute) {
      if (this.isChild) {
        this.$parent.userProfiles.filter(profile => profile.portalRole === portalRoles.GUARDIAN)[
          this.profileIndex
        ].children[this.childIndex].institutionProfile[attribute] = this.instProfile[attribute];
      } else {
        this.$parent.userProfiles[this.profileIndex].institutionProfiles[this.institutionProfileIndex][attribute] =
          this.instProfile[attribute];
      }
      this.orginalProfile[attribute] = this.instProfile[attribute];
    },
    showSubmitButton(attribute) {
      return this.orginalProfile[attribute] == this.instProfile[attribute];
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.user {
  display: block;
  margin-left: 5px;

  .icon {
    float: left;
  }

  .user-institution {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0px;
    margin-top: 10px;
  }
  .user-info {
    display: block;
    float: left;
    margin-top: 10px;
    margin-left: 15px;
    .user-fullname {
      font-size: 16px;
      font-weight: 900;
      line-height: 29px;
      @include breakpoint-lg-down() {
        line-height: 16px;
      }
      &.main {
        font-size: 24px;
        font-weight: 900;
        line-height: 29px;
        margin-bottom: 5px;
        @include breakpoint-lg-down() {
          font-size: 16px;
          line-height: 16px;
        }
      }
    }
    .user-address {
      display: block;
      float: left;
      font-size: 15px;
      line-height: 24px;
    }
  }
  .user-update {
    display: block;
    float: left;
    width: 100%;
    padding-top: 20px;
  }
  .input-group-prepend {
    position: absolute;
    top: 13px;
    left: 20px;
    font-size: 18px;
    z-index: 10;
    .contact-type {
      margin-left: 10px;
      padding-right: 5px;
      font-size: 15px;
      font-weight: 900;
    }
    .email {
      font-size: 12px;
      padding-top: 5px;
    }
  }
  .input-group-append {
    position: absolute;
    top: 14px;
    right: 20px;
    z-index: 10;
    .pen {
      margin-top: 4px;
      color: $color-grey-dunkel;
    }
    .alert {
      position: relative;
      top: -12px;
      right: -40px;
    }
  }
  .onboarding-input {
    margin-bottom: 0px;
    border-radius: 0px;
    border-top: 1px solid $color-grey-base;
    padding-right: 50px;
    padding-left: 130px;
    &.last {
      border-bottom: 1px solid $color-grey-base;
    }
  }
}
</style>
