<template>
  <div class="personal-reference-data mt-3">
    <div class="user-data header">
      <div v-if="!isMobile" class="user-icon header mr-0" />
      <div class="user-name header">
        {{ 'MASTER_DATA_USER' | fromTextKey }}
      </div>
      <div v-if="!isMobile" class="user-answer header" :class="{ full: isConsent }">
        {{ 'MASTER_DATA_ANWSER' | fromTextKey }}
      </div>
      <div v-if="!isConsent" class="user-answer-question header">
        {{ 'MASTER_DATA_OPTION_QUESTION' | fromTextKey }}
      </div>
    </div>
    <template v-for="(data, i) in getPersonalReferenceDataAnswer">
      <div
        v-if="getPersonalReferenceDataAnswer != null && getPersonalReferenceDataAnswer.length > 0"
        :key="i"
        class="user-data"
      >
        <div class="user-icon">
          <user-icon
            :name="data.name"
            :picture-object="data.profilePicture"
            :short-name="data.shortName"
            :show-inline="true"
            :user-icon-class="isMobile ? 'small' : ''"
          />
          <div v-if="isMobile" class="mobile-name">
            <router-link
              v-if="isChild"
              :to="{
                name: 'userview',
                params: { id: data.institutionProfileId },
              }"
            >
              {{ data | displayProfileNameWithMetadata }}
            </router-link>
            <template v-else>
              {{ data | displayProfileNameWithMetadata }}
            </template>
          </div>
        </div>
        <div v-if="!isMobile" class="user-name">
          <router-link v-if="isChild" :to="{ name: 'userview', params: { id: data.institutionProfileId } }">
            {{ data | displayProfileNameWithMetadata }}
          </router-link>
          <template v-else>
            {{ data | displayProfileNameWithMetadata }}
          </template>
        </div>
        <template v-if="!isMobile">
          <div class="user-answer" :class="{ full: isConsent }">
            <template v-if="!isConsent">
              <span v-if="data.yesNoResponse != null && data.yesNoResponse">{{ 'YES' | fromTextKey }}</span>
              <span v-if="data.yesNoResponse != null && !data.yesNoResponse">{{ 'NO' | fromTextKey }}</span>
            </template>
            <template v-else>
              <template v-if="!data.shouldAnswer">
                {{ 'MASTER_DATA_USER_NOT_RELATED' | fromTextKey }}
              </template>
              <template v-else>
                <template v-if="data.response == null && data.yesNoResponse == null">
                  {{ 'MASTER_DATA_USER_HAS_NOT_RECEIVED_YET' | fromTextKey }}
                </template>
                <template v-else>
                  <span v-if="data.response == consentAnswerType.DECLINED">{{ 'NO' | fromTextKey }}</span>
                  <span v-else-if="data.response == consentAnswerType.ACCEPTED">{{ 'YES' | fromTextKey }}</span>
                  <span v-else-if="data.response == consentAnswerType.NOT_AT_ALL">{{
                    'CONSENT_ANSWER_NOT_AT_ALL' | fromTextKey
                  }}</span>
                  <template v-else-if="consentSharingAnswer.includes(data.response)">
                    {{ 'CONSENT_ANSWER_YES_FOR' | fromTextKey }}
                    {{ ('CONSENT_ANSWER_' + data.response.toUpperCase()) | fromTextKey }}
                  </template>
                </template>
              </template>
            </template>
          </div>
          <div v-if="!isConsent" class="user-answer-question">
            <template v-if="!data.shouldAnswer">
              {{ 'MASTER_DATA_USER_NOT_RELATED' | fromTextKey }}
            </template>
            <template v-else-if="data.missingResponse">
              {{ 'MASTER_DATA_USER_HAS_NOT_RECEIVED_YET' | fromTextKey }}
            </template>
            <template v-else-if="data.yesNoResponse != null || data.response != null">
              <div v-if="data.yesNoResponse === true && question.optionalQuestionForYes != null">
                {{ question.optionalQuestionForYes }}
              </div>
              <div v-if="data.yesNoResponse === false && question.optionalQuestionForNo != null">
                {{ question.optionalQuestionForNo }}
              </div>
              <template v-if="data.response != null">
                {{ data.response }}
              </template>
            </template>
          </div>
        </template>
        <template v-else>
          <div v-if="!isConsent" class="user-answer-question">
            <template v-if="!data.shouldAnswer">
              {{ 'MASTER_DATA_USER_NOT_RELATED' | fromTextKey }}
            </template>
            <template v-else-if="data.missingResponse">
              {{ 'MASTER_DATA_USER_HAS_NOT_RECEIVED_YET' | fromTextKey }}
            </template>
            <template v-else-if="data.yesNoResponse != null || data.response != null">
              <div v-if="data.yesNoResponse === true">
                {{ 'YES' | fromTextKey
                }}<template v-if="question.optionalQuestionForYes != null">
                  - {{ question.optionalQuestionForYes }}
                </template>
              </div>
              <div v-if="data.yesNoResponse === false">
                {{ 'NO' | fromTextKey
                }}<template v-if="question.optionalQuestionForNo != null">
                  - {{ question.optionalQuestionForNo }}
                </template>
              </div>
              <template v-if="data.response != null">
                {{ data.response }}
              </template>
            </template>
          </div>
          <div v-else class="user-answer-question">
            <template v-if="!data.shouldAnswer">
              {{ 'MASTER_DATA_USER_NOT_RELATED' | fromTextKey }}
            </template>
            <template v-else>
              <template v-if="data.response == null && data.yesNoResponse == null">
                {{ 'MASTER_DATA_USER_HAS_NOT_RECEIVED_YET' | fromTextKey }}
              </template>
              <template v-else>
                <span v-if="data.response == consentAnswerType.DECLINED">{{ 'NO' | fromTextKey }}</span>
                <span v-else-if="data.response == consentAnswerType.ACCEPTED">{{ 'YES' | fromTextKey }}</span>
                <span v-else-if="data.response == consentAnswerType.NOT_AT_ALL">{{
                  'CONSENT_ANSWER_NOT_AT_ALL' | fromTextKey
                }}</span>
                <template v-else-if="consentSharingAnswer.includes(data.response)">
                  {{ 'CONSENT_ANSWER_YES_FOR' | fromTextKey }}
                  {{ ('CONSENT_ANSWER_' + data.response.toUpperCase()) | fromTextKey }}
                </template>
              </template>
            </template>
          </div>
        </template>
      </div>
    </template>
    <aula-spinner v-if="isLoadingMore" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { types } from '../../store/types/types';
import { consentAnswerType } from '../../../shared/enums/consentAnswerType';
import { contactsProfileTypeFilters } from '../../../shared/enums/contactsProfileTypeFilters';
export default {
  props: {
    question: { type: Object },
    profileType: { type: String, default: contactsProfileTypeFilters.CHILD },
    isLoadingMore: { type: Boolean, default: false },
  },
  data: function () {
    return {
      consentAnswerType: consentAnswerType,
      contactsProfileTypeFilters: contactsProfileTypeFilters,
      consentSharingAnswer: [
        consentAnswerType.CLASS_OR_STUE,
        consentAnswerType.INSTITUTION,
        consentAnswerType.YEAR_AND_SFO,
      ],
    };
  },
  computed: {
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
      getPersonalReferenceDataAnswer: types.GET_PERSONAL_REFERENCE_DATA_ANSWER,
    }),
    isChild() {
      return [
        contactsProfileTypeFilters.CHILD,
        contactsProfileTypeFilters.BOY,
        contactsProfileTypeFilters.GIRL,
      ].includes(this.profileType);
    },
    isConsent() {
      return this.question.type == 'consent';
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables';
@import '../../../shared/assets/scss/core/breakpoints';
@import '../../../shared/assets/scss/components/personalreferencedata/personalReferenceData';
</style>
