/**
 * Deep link functionality for calendar synchronization
 * Supports links to an event
 * Supports secure document links that is included in event description
 */

/**
 * Paths that support deeplink
 */
const SecureFilePathName = /\/dokumenter\/dokument/i;
const CalendarEventPathName = /\/kalender\/begivenhed/i;
const CalendarVacationRequestPathName = /\/kalender\/\d+\/ferieanmodning/i;
const CalendarParentalMeetingPathName = /\/kalender\/samtale/i;

const DeeplinkPatterns = [
  SecureFilePathName,
  CalendarEventPathName,
  CalendarVacationRequestPathName,
  CalendarParentalMeetingPathName,
];

/**
 * storage key to store deep link information
 */
const CalendarFeedDeepLinkInfoKey = 'calendarFeedDeepLinkInfo';

/**
 * Expiration timediff in ms.
 * 5 * 1000 * 60 = 5 minutes
 */
const CalendarFeedRedirectExpiration = 5 * 1000 * 60;

/**
 * Set data for deep linking
 */
export const setCalendarDeepLink = function () {
  if (checkIfDeepLinkPath()) {
    setCalendarDeepLinkData();
  }
};

const checkIfDeepLinkPath = function () {
  const hash = window.location.hash;

  const matches = DeeplinkPatterns.map(deepLinkPattern => deepLinkPattern.exec(hash));

  return matches.some(result => result !== null);
};

const setCalendarDeepLinkData = function () {
  const calendarDeepLinkInfo = {
    expiration: generateExpirationTimestamp(),
    url: window.location.hash,
  };

  localStorage.setItem(CalendarFeedDeepLinkInfoKey, JSON.stringify(calendarDeepLinkInfo));
};

/**
 * Handles the redirection to the deep link
 * This method should be invoked when the user has been logged in
 */
export const redirectToCalendarFeedDeepLink = function () {
  const deepLinkInfo = getDeepLinkInfo();

  if (isDeepLinkInfoValid(deepLinkInfo) === false) {
    return;
  }

  window.location.hash = deepLinkInfo.url;
};

const getDeepLinkInfo = function () {
  const deepLinkInfoJson = localStorage.getItem(CalendarFeedDeepLinkInfoKey);
  if (deepLinkInfoJson != null && deepLinkInfoJson.length > 0) {
    return JSON.parse(deepLinkInfoJson);
  }
  return null;
};

export const clearCalendarFeedDeepLinkInfo = function () {
  localStorage.removeItem(CalendarFeedDeepLinkInfoKey);
};

/**
 * Validate deepLinkinfo
 * @param {*} deepLinkInfo
 */
const isDeepLinkInfoValid = function (deepLinkInfo) {
  if (deepLinkInfo === null || deepLinkInfo.url === null) {
    return false;
  }

  if (deepLinkInfo.expiration != null && deepLinkInfo.expiration < new Date().getTime()) {
    return false;
  }

  return true;
};

const generateExpirationTimestamp = function () {
  const now = new Date();
  return now.getTime() + CalendarFeedRedirectExpiration;
};
