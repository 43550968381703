<template>
  <div class="handle-more-subscriptions-mobile">
    <div class="text">
      {{ numberOfSelectedThreads }}{{ 'MESSAGE_CHOSEN_HEADER' | fromTextKey }}
      <button type="button" class="unstyled-button close-icon" @click="closeHandleMore">
        <i class="icon-Aula_plus" />
      </button>
    </div>
    <div class="handle-buttons d-flex align-items-center">
      <button v-if="!hasDraftThreadsSelected" type="button" class="unstyled-button" @click="onChangeSubscriptionStatus">
        <i :class="isAllUnread ? 'icon-Aula_envelope_open' : 'icon-Aula_envelope_closed'" />
        <span class="button-text">{{
          isAllUnread ? 'MESSAGE_MARK_AS_READ_SHORT' : 'MESSAGE_MARK_AS_UNREAD_SHORT' | fromTextKey
        }}</span>
      </button>
      <button type="button" class="unstyled-button" @click="$refs.deleteModal.show()">
        <i class="icon-Aula_bin" /><span class="button-text">{{ 'MESSAGE_DELETE_THREAD' | fromTextKey }}</span>
      </button>
      <template v-if="!hasDraftThreadsSelected">
        <button
          v-if="!canMoveToFolder"
          type="button"
          class="unstyled-button"
          @click="markSelectedSubscriptions(!showMarkButton)"
        >
          <i :class="showMarkButton ? 'icon-Aula_star_solid colored' : 'icon-Aula_star'" />
          <span class="button-text">
            {{ 'MESSAGE_MARK' | fromTextKey }}
          </span>
        </button>
        <b-dropdown v-else ref="moreOptionsDropdown" class="handle-more-options p-0" no-caret>
          <template #button-content>
            <i class="icon-Aula_elipses" />
            <div class="button-text p-0">
              {{ 'MESSAGE_DROPDOWN_MORE' | fromTextKey }}
            </div>
          </template>
          <b-dropdown-item @click="changeMoveToFolderVisibility(true)">
            <div class="d-flex align-items-center">
              <i class="icon-Aula_folder" />
              <div class="button-text mt-0 ml-2">
                {{ 'MESSAGE_MOVE_FOLDER' | fromTextKey }}
              </div>
            </div>
          </b-dropdown-item>
          <b-dropdown-item @click="markSelectedSubscriptions(!showMarkButton)">
            <div class="d-flex align-items-center">
              <i :class="showMarkButton ? 'icon-Aula_star_solid colored' : 'icon-Aula_star'" />
              <div class="button-text mt-0 ml-2">
                {{ 'MESSAGE_MARK' | fromTextKey }}
              </div>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </div>
    <aula-modal
      ref="deleteModal"
      header-text="MESSAGE_CONFIRM_DELETE"
      ok-text="MESSAGE_DELETE"
      data-size="small"
      @cancelClicked="$refs.deleteModal.hide()"
      @okClicked="deleteSelectedSubscriptions()"
    >
      {{ 'MESSAGE_DELETE_MORE_THREADS_WARNING_1' | fromTextKey }}<br />
      {{ deletedThreadWarningMessage }} <br />
      {{ 'MESSAGE_DELETE_MORE_THREADS_WARNING_2' | fromTextKey }}
    </aula-modal>
    <move-to-folder-modal
      v-if="showMoveToFolder"
      :thread-ids="selectedThreadIds"
      :subscription-ids="selectedBundleIds"
      :chosen-folder-and-mail-owner="chosenFolderAndMailOwner"
      :folders="!!chosenFolderAndMailOwner.mailOwnerId ? commonInboxFolders : folders"
      @hide="changeMoveToFolderVisibility"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { mapGetters } from 'vuex';
import { types } from '../../store/types/types';
import MessageMoveToFolderModal from './MessageMoveToFolderModal.vue';
import moment from 'moment-timezone';
import { subscriptionStatus } from '../../../shared/enums/subscriptionStatus';
import { subscriptionTypes } from '../../../shared/enums/subscriptionTypes';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import difference from 'lodash/difference';
import { messageProviderKeyEnum } from '../../../shared/enums/messageProviderKeyEnum';
import { portalRoles } from '../../../shared/enums/portalRoles';

export default {
  inject: {
    getDeletedFolderId: messageProviderKeyEnum.GET_DELETED_FOLDER_ID,
    getCanMoveToDeletedMessageFolder: messageProviderKeyEnum.CAN_MOVE_TO_DELETED_MESSAGE_FOLDER,
    deleteOtpSubscriptions: messageProviderKeyEnum.DELETE_OTP_SUBSCRIPTIONS,
  },
  data: function () {
    return {
      moment: moment,
      showMoveToFolder: false,
      folderButtonClicked: false,
      selectedFolder: null,
      isAllUnread: false,
    };
  },
  computed: {
    ...mapGetters({
      selectedThreadIds: types.MESSAGES_GET_CHOSEN_THREAD_IDS,
      selectedSubscriptionIds: types.MESSAGES_GET_CHOSEN_SUBSCRIPTION_IDS,
      selectedBundleIds: types.MESSAGES_GET_CHOSEN_BUNDLE_IDS,
      folders: types.MESSAGES_GET_FOLDERS,
      chosenFolderAndMailOwner: types.MESSAGES_GET_CHOSEN_FOLDER_AND_MAIL_OWNER,
      profile: types.GET_CURRENT_PROFILE,
      subscriptionPressed: types.MESSAGES_GET_SUBSCRIPTIONS_ONPRESS_MOBILEVIEW,
      activeChildren: types.GET_ACTIVE_CHILDREN,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
      subscriptionsAndBundleThreads: types.MESSAGES_GET_SUBSCRIPTIONS_AND_BUNDLE_THREADS,
      threadBundles: types.MESSAGES_GET_THREAD_BUNDLES,
      hasPermission: types.HAS_PERMISSION,
      commonInboxFolders: types.MESSAGES_GET_FOLDERS_FOR_CURRENT_COMMON_INBOX,
    }),
    canMoveToDeletedMessageFolder() {
      return this.getCanMoveToDeletedMessageFolder();
    },
    deletedFolderId() {
      return this.getDeletedFolderId();
    },
    deletedThreadWarningMessage() {
      let message = this.$options.filters.fromTextKey('MESSAGE_DELETE_MORE_THREADS_INFO_2');

      if (this.chosenFolderAndMailOwner?.mailOwnerId) {
        message = this.$options.filters.fromTextKey('MESSAGE_DELETE_MORE_THREADS_INFO_2_COMMON');
      }
      if (this.canMoveToDeletedMessageFolder) {
        message += ` ${this.$options.filters.fromTextKey('MESSAGE_DELETE_MORE_THREADS_INFO_FROM_NORMAL_FOLDER')}`;
      }
      return message;
    },
    numberOfSelectedThreads() {
      // AULA-37947: count the number of draft messages also
      let count = this.selectedThreadIds.length;

      this.selectedBundleIds.forEach(bundleId => {
        const bundleExists = this.threadBundles.some(bundle => bundle.bundleId === bundleId);
        if (bundleExists === false) {
          const bundle = this.subscriptionsAndBundleThreads.find(
            item => item.subscriptionId === bundleId && item.subscriptionType === subscriptionTypes.BUNDLE
          );
          if (bundle != null) {
            count = count + bundle.numberOfBundleItems;
          }
        }
      });
      return count;
    },
    canMoveToFolder() {
      return this.hasPermission(permissionEnum.INBOX_FOLDERS);
    },
    showMarkButton() {
      let containsUnmarkedMessages = false;
      for (const subscription of this.selectedThreadIds) {
        const subscriptionChosen = this.subscriptionsAndBundleThreads.filter(
          item => item.id == subscription && item.marked
        );
        if (subscriptionChosen.length > 0) {
          containsUnmarkedMessages = true;
          return containsUnmarkedMessages;
        }
      }
      return containsUnmarkedMessages;
    },
    hasDraftThreadsSelected() {
      const draftThreads = this.selectedThreadIds.filter(subscription => subscription.toString().match(/^DRAFT/));
      return draftThreads.length > 0;
    },
  },
  methods: {
    ...mapActions({
      updateSelectedThreadIds: types.ACTION_UPDATE_SELECTED_THREAD_IDS,
      updateSelectedSubscriptionIds: types.ACTION_UPDATE_SELECTED_SUBSCRIPTION_IDS,
      updateSelectedBundleIds: types.ACTION_UPDATE_SELECTED_BUNDLE_IDS,
      switchMarkMessages: types.SWITCH_MARK_SUBSCRIPTIONS,
      deleteSubscriptions: types.DELETE_SUBSCRIPTIONS,
      changeSubscriptionPressed: types.SUBSCRIPTIONS_ONPRESS_MOBILEVIEW,
      updateSubscriptionStatus: types.MESSAGES_UPDATE_SUBSCRIPTION_STATUS,
      loadNotifications: types.LOAD_NOTIFICATIONS,
      leaveThreads: types.MESSAGES_LEAVE_THREADS,
      moveThreadsToFolder: types.MOVE_THREADS_TO_FOLDER,
    }),
    ...mapMutations({
      setSuccessMessage: types.MUTATE_SUCCESS_TEXT,
      mutateDeleteSubscriptions: types.MUTATE_DELETE_SUBSCRIPTIONS,
      mutateDeleteDraftThread: types.MUTATE_DELETE_DRAFT_THREAD,
    }),
    folderSelected(folderId) {
      this.selectedFolder = folderId;
    },
    changeMoveToFolderVisibility(value) {
      this.showMoveToFolder = value;
    },
    async deleteSelectedSubscriptions() {
      this.$refs.deleteModal.hide();
      const draftThreadIds = this.selectedThreadIds.filter(item => item.match && item.match(/^DRAFT/));
      const allSelectedSubscriptionIds = [...this.selectedBundleIds, ...this.selectedSubscriptionIds];
      if (this.canMoveToDeletedMessageFolder) {
        if (allSelectedSubscriptionIds.length > 0) {
          await this.leaveThreads({
            subscriptionIds: allSelectedSubscriptionIds,
          });
          await this.moveThreadsToFolder({
            subscriptionIds: allSelectedSubscriptionIds,
            folderId: this.deletedFolderId,
          });
          this.setSuccessMessage('SUCCESS_TOAST_MESSAGES_DELETE_THREADS_FROM_NORMAL_FOLDER');
        }
        for (const threadId of draftThreadIds) {
          this.mutateDeleteDraftThread({
            id: this.profile.id,
            key: threadId,
          });
        }
        this.mutateDeleteSubscriptions({
          threadIds: difference(this.selectedThreadIds, draftThreadIds),
          subscriptionIds: this.selectedBundleIds,
          id: this.profile.id,
        });
      } else if (this.profile.role === portalRoles.OTP) {
        this.deleteOtpSubscriptions(this.subscriptionsAndBundleThreads, this.deleteSubscriptions);
      } else {
        this.deleteSubscriptions({
          threadIds: this.selectedThreadIds,
          subscriptionIds: this.selectedBundleIds,
        });
      }
      this.loadNotifications({
        activeChildrenIds: this.activeChildren,
        activeInstitutionCodes: this.activeInstitutions,
      });
      this.unselectMessages();
    },
    markSelectedSubscriptions(isMarked = true) {
      this.switchMarkMessages({
        threadIds: this.selectedThreadIds,
        subscriptionIds: this.selectedBundleIds,
        isMarked: isMarked,
      }).then(() => {
        this.unselectMessages();
      });
    },
    async onChangeSubscriptionStatus() {
      const selectedSubscriptions = this.subscriptionsAndBundleThreads.filter(
        subscription =>
          this.selectedThreadIds.includes(subscription.id) && subscription.subscriptionType !== subscriptionTypes.BUNDLE
      );
      const selectedSubscriptionIds = selectedSubscriptions.map(subscription => subscription.subscriptionId);
      const subscriptionIdsToUpdate = selectedSubscriptionIds.concat(this.selectedBundleIds);
      const statusToUpdate = this.isAllUnread ? subscriptionStatus.READ : subscriptionStatus.UNREAD;

      await this.updateSubscriptionStatus({
        subscriptionIds: subscriptionIdsToUpdate,
        status: statusToUpdate,
      });

      this.isAllUnread = !this.isAllUnread;
      this.unselectMessages();

      this.loadNotifications({
        activeChildrenIds: this.activeChildren,
        activeInstitutionCodes: this.activeInstitutions,
      });
    },
    closeHandleMore() {
      this.changeSubscriptionPressed(false);
      this.unselectMessages();
    },
    unselectMessages() {
      this.changeSubscriptionPressed(false);
      for (const item of this.subscriptionsAndBundleThreads) {
        item.checked = false;
      }
      this.updateSelectedThreadIds([]);
      this.updateSelectedSubscriptionIds([]);
      this.updateSelectedBundleIds([]);
    },
    setIsAllUnreadStatus() {
      const selectedSubscriptions = this.subscriptionsAndBundleThreads.filter(subscription => {
        if (subscription.subscriptionType === subscriptionTypes.BUNDLE) {
          return this.selectedBundleIds.includes(subscription.subscriptionId);
        }
        return this.selectedThreadIds.includes(subscription.id);
      });
      this.isAllUnread = selectedSubscriptions.every(subscription => subscription.read === false);
    },
  },
  watch: {
    selectedThreadIds() {
      this.setIsAllUnreadStatus();
    },
    selectedBundleIds() {
      this.setIsAllUnreadStatus();
    },
  },
  mounted() {
    this.setIsAllUnreadStatus();
  },
  components: {
    'move-to-folder-modal': MessageMoveToFolderModal,
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.unstyled-button {
  border: none;
  padding: 5px;
  background: none;
  color: $color-white;
}

.button-text {
  padding: 10px;
}

.handle-more-subscriptions-mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 115px;
  background-color: $color-primary-base;
  z-index: 1050;

  .theme-guardian & {
    background-color: $color-primary-dark-guardian;
  }
  .theme-employee & {
    background-color: $color-primary-dark-employee;
  }
  .theme-child & {
    background-color: $color-primary-dark-child;
  }
  .text {
    width: 100%;
    color: $color-white;
    line-height: 30px;
    font-size: 18px;
    margin-left: 23px;
    margin-top: 10px;
    font-weight: bold;
  }
  .handle-buttons {
    margin-left: 20px;
    margin-top: 30px;
  }
  .close-icon {
    position: absolute;
    right: 5%;
    color: $color-white;
    transform: rotate(45deg);
  }
  .colored {
    color: $color_alert;
  }
}
</style>
