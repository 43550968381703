<template>
  <ThreadCompact class="thread-compact-item color-white position-relative hoverable selectable indicator">
    <template #recipients>
      <div class="d-flex">
        <div class="d-flex align-items-center">
          <b-form-checkbox
            :checked="selected"
            class="thread-checkbox"
            tabindex="-1"
            @change="onCheckBoxClicked"
            @click.native="$event.stopPropagation()"
          >
            <span class="sr-only">{{ 'ARIA_LABEL_CHOOSE_MESSAGE' | fromTextKey }}</span>
          </b-form-checkbox>
          <div class="bundle-icon ml-1 mr-2">
            <i class="icon icon-Aula_down-arrow" />
          </div>
        </div>
        <keyword-highlight class="recipient-name recipient-text" :queries="searchQuery">
          {{ firstRecipient }}
        </keyword-highlight>
        <div v-if="extraRecipientsCount" class="recipient-text pl-1">+{{ extraRecipientsCount }}</div>
      </div>
    </template>
    <template #name>
      <span class="mute-icon">
        <i class="icon-Bell_2" />
      </span>
      <span class="sensitive-icon">
        <i class="icon-Aula_lock color-alert selected" />
      </span>
      <div class="badge badge-danger draft-badge">
        {{ 'MESSAGE_TOOLBAR_FILTER_DRAFT' | fromTextKey }}
      </div>
      <strong>
        <keyword-highlight :queries="searchQuery">
          {{ title }}
        </keyword-highlight>
      </strong>
      <keyword-highlight class="latest-message" :queries="searchQuery">
        {{ message | stripHTML }}
      </keyword-highlight>
      <span class="bundle-item-total">
        {{ numberOfBundleItems }}
        {{ numberOfBundleItems === 1 ? 'MESSAGE_THREAD_TEXT_SINGLE' : 'MESSAGE_THREAD_TEXT_PLURAL' | fromTextKey }}
      </span>
    </template>
    <template #date>
      <div class="thread-date pr-1">
        <post-time class="last-message-sent-time" :time="date" />
        <i v-if="marked" class="icon-Aula_star_solid" />
      </div>
      <ThreadActions
        class="thread-actions pr-1"
        :marked="marked"
        :read="read"
        @onMarkImportantClicked="onMarkImportantClicked"
        @onMarkAsReadClicked="onMarkAsReadClicked"
        @onMoveToFolderClicked="onMoveToFolderClicked"
        @onDeleteClicked="onDeleteClicked"
      />
    </template>
  </ThreadCompact>
</template>
<script>
import KeywordHighlight from 'vue-text-highlight';
import ThreadCompact from './ThreadCompact';
import ThreadActions from './ThreadActions';

export default {
  components: {
    ThreadActions,
    ThreadCompact,
    KeywordHighlight,
  },
  props: {
    date: { type: String, default: '' },
    title: { type: String, default: '' },
    searchQuery: { type: Array, default: () => [] },
    firstRecipient: { type: String, default: '' },
    extraRecipientsCount: { type: Number, default: null },
    message: { type: String, default: '' },
    selected: { type: Boolean, default: false },
    marked: { type: Boolean, default: false },
    read: { type: Boolean, default: false },
    numberOfBundleItems: { type: Number, default: null },
  },
  methods: {
    onCheckBoxClicked(value) {
      this.$emit('onCheckBoxClicked', value);
    },
    onMarkImportantClicked() {
      this.$emit('onMarkImportantClicked');
    },
    onMarkAsReadClicked() {
      this.$emit('onMarkAsReadClicked');
    },
    onMoveToFolderClicked() {
      this.$emit('onMoveToFolderClicked');
    },
    onDeleteClicked() {
      this.$emit('onDeleteClicked');
    },
  },
};
</script>
<style scoped lang="scss">
.thread-compact-item {
  &:hover .thread-checkbox {
    visibility: visible;
  }
  .bundle-icon {
    font-size: 0.5rem;
    visibility: hidden;
    vertical-align: middle;
    transform: rotate(0);
    transition: transform 200ms linear;

    .bundle & {
      visibility: visible;
    }
    .bundle-open & {
      transform: rotate(180deg);
    }
  }
  .recipient-text {
    font-size: 0.9rem;
  }
  .recipient-name {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .draft-badge {
    font-size: 0.6rem;
    align-self: center;
    display: none;
    line-height: 12px;
    .has-draft & {
      display: initial;
    }
  }
  .thread-checkbox {
    visibility: hidden;
    .selected &,
    .multiple-selected &{
      visibility: visible;
    }
  }
  .sensitive-icon {
    display: none;
    font-size: 0.9rem;
    color: var(--color-alert);
    .sensitive & {
      display: initial;
    }
  }
}

.bundle-container {
  .recipient-name {
    padding-left: 1rem;
  }
}

.thread-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  i.icon-Aula_star_solid {
    color: var(--color-alert);
    font-size: 0.6rem;
    margin-top: 3px;
  }
  .last-message-sent-time {
    font-size: 0.9rem;
  }
}
.thread-actions {
  display: none;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
}
.unread .thread-compact-item {
  font-weight: bold;
}

.unread .thread-compact-item,
.active-thread .thread-compact-item {
  --thread-background-color: var(--color-grey-base);
}

.selected .selectable {
  --thread-background-color: var(--color-grey-base);
}
.hoverable:hover {
  --thread-background-color: var(--color-grey-dark);
  .thread-date {
    display: none;
  }
  .thread-actions {
    display: flex;
  }
}

.indicator::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 7px;
  height: 100%;
  background-color: var(--thread-indicator-color, var(--color-grey-dunkel));
  .subscription.unread & {
    --thread-indicator-color: var(--color-primary-darker);
  }
  .subscription.active-thread & {
    --thread-indicator-color: var(--color-primary-light);
  }
}
.mute-icon {
  display: none;
  .subscription.muted & {
    display: initial;
  }
}
</style>
