<template>
  <div>
    <label :for="id"><slot name="label" /></label>
    <b-input-group class="advance-search-input">
      <b-input :id="id" type="text" v-bind="{ ...$props, ...$attrs }" v-on="$listeners" />
    </b-input-group>
  </div>
</template>

<script>
export default {
  name: 'MessageAdvancedSearchInput',
  data() {
    return {
      id: null,
    };
  },
  mounted() {
    this.id = String(this._uid);
  },
};
</script>

<style lang="scss" scoped>
.advance-search-input {
  input {
    border: unset;
  }
}
</style>
