<template>
  <div>
    <div v-if="contacts.length > 0" class="column-dropdown d-print-none">
      <label for="columnSelect">
        {{ 'CONTACT_CHOOSE_COLUMNS' | fromTextKey }}
      </label>
      <AulaSelect id="columnSelect" v-model="columns">
        <AulaOption v-for="(option, index) in columnOptions" :key="index" :label="option" :value="option" />
      </AulaSelect>
    </div>
    <ContactsFragmentNoResult v-if="contacts.length === 0" />
    <TableContainer v-else class="table">
      <TableHead class="head">
        <TableRow>
          <TableHeading class="color-grey-base heading">
            <ContactsFragmentSorting
              :direction="orderDirection"
              :sort-by="contactSortingTypes.NAME"
              :sorting-by="sortBy"
              @sort="onSortBy"
            >
              {{ 'CONTACT_NAME' | fromTextKey }}
            </ContactsFragmentSorting>
          </TableHeading>
          <TableHeading v-for="num in columns" :key="num" class="color-grey-base heading" />
        </TableRow>
      </TableHead>
      <TableBody class="body">
        <TableRow v-for="contact in contacts" :key="contact.id + '_' + contact.fullName">
          <TableCell class="contact-name-cell cell">
            {{ contact | displayProfileNameWithMetadata }}
          </TableCell>
          <TableCell v-for="num in columns" :key="num" class="cell" />
        </TableRow>
      </TableBody>
    </TableContainer>
  </div>
</template>
<script lang="js">

import TableCell from "../../libs/table/components/TableCell";
import TableBody from "../../libs/table/components/TableBody";
import ContactsFragmentSorting from "./ContactsFragmentSorting";
import TableHeading from "../../libs/table/components/TableHeading";
import TableRow from "../../libs/table/components/TableRow";
import TableHead from "../../libs/table/components/TableHead";
import TableContainer from "../../libs/table/components/TableContainer";
import {providerKeyTypes} from "../../enums/providerKeyTypes";
import {contactSortingTypes} from "../../enums/contactSortingTypes";
import ContactsFragmentNoResult from "./ContactsFragmentNoResult";
import {collapseDirectionEnum} from "../../libs/table/enums/collapseDirectionEnum";

export default {
  name: 'ContactsFragmentDesktopChecklistList',
  components: {
    ContactsFragmentNoResult,
    TableCell,
    TableBody,
    ContactsFragmentSorting,
    TableHeading,
    TableRow,
    TableHead,
    TableContainer,
  },
  inject: {
    getContacts: providerKeyTypes.contacts,
    getOrderDirection: providerKeyTypes.orderDirection,
    getSortBy: providerKeyTypes.sortBy,
    changeSortBy: providerKeyTypes.onSortBy,
  },
  data() {
    return {
      contactSortingTypes,
      collapseDirectionEnum,
      columns: 5,
      maxColumns: 20,
    };
  },
  computed: {
    contacts() {
      return this.getContacts();
    },
    orderDirection() {
      return this.getOrderDirection();
    },
    sortBy() {
      return this.getSortBy();
    },
    columnOptions() {
      return new Array(this.maxColumns).fill('').map((v, index) => index + 1);
    }
  },
  methods: {
    onSortBy(sortBy) {
      this.changeSortBy(sortBy);
    },
  }
};
</script>
<style lang="scss" scoped>
.column-dropdown {
  max-width: 33.33336%;
  margin-top: 30px;
  margin-bottom: 25px;
}

.contact-name-cell {
  width: 320px;
  max-width: 320px;
  word-break: break-all;
}

@media print {
  .table {
    .head {
      .heading {
        font-size: 20px;
        padding: 10px;
      }
    }

    .body {
      .cell {
        padding: 10px;
        font-size: 17px;
      }
    }
  }
}
</style>
