<template>
  <b-row>
    <b-col v-for="(media, i) in mediaList" :key="i" cols="6" md="4" xl="3" class="mb-3">
      <b-row>
        <b-col>
          <MediaCard
            v-if="selectMode"
            :media="media"
            :show-select-box="true"
            :can-delete-media="false"
            :show-delete-media="showDeleteMedia"
            :aria-label="media.title"
            tabindex="0"
            role="checkbox"
            :aria-checked="media.selected"
            @onDeleteMediaClick="onDeleteMediaClick(media)"
            @click="onMediaSelected(media)"
          />
          <MediaCard
            v-else
            v-button
            :media="media"
            :can-delete-media="
              canDeleteMedia ||
              media.currentUserCanDelete ||
              institutions.some(inst => inst.institutionProfileId == media.creator.id).length > 0
            "
            :show-delete-media="showDeleteMedia"
            :aria-label="'GALLERY_SHOW_MEDIA' | fromTextKey({ description: media.title })"
            @onDeleteMediaClick="onDeleteMediaClick(media)"
            @click="onOpenMedia(media)"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import MediaCard from './MediaCard.vue';
import { mapGetters } from 'vuex';
import { types } from '../../../src/store/types/types';
import Button from '../../directives/button';

export default {
  name: 'MediaList',
  directives: {
    Button,
  },
  components: { MediaCard },
  props: {
    mediaList: { type: Array, default: () => [] },
    selectMode: { type: Boolean, default: false },
    canDeleteMedia: { type: Boolean, default: false },
    showDeleteMedia: { type: Boolean, default: true },
  },
  computed: {
    ...mapGetters({
      institutions: types.GET_INSTITUTIONS,
    }),
  },
  methods: {
    onMediaSelected(media) {
      this.$emit('onMediaSelected', media);
    },
    onDeleteMediaClick(media) {
      this.$emit('onDeleteMediaClick', media);
    },
    onOpenMedia(media) {
      this.$emit('onOpenMedia', media);
    },
  },
};
</script>
