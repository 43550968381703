// ---------- MESSAGES ----------

// - MESSAGE TOOLBAR -
export const MESSAGE_TOOLBAR_NEW_MESSAGE = 'Opret besked';
export const MESSAGE_TOOLBAR_CREATE_NEW_MESSAGE = 'Opret ny besked';
export const MESSAGE_TOOLBAR_NEW_MESSAGE_MOBILE = 'SKRIV';
export const MESSAGE_TOOLBAR_FOLDERS = 'Mapper';
export const MESSAGE_TOOLBAR_INBOX = 'Indbakke';
export const MESSAGE_TOOLBAR_COMMON_INBOX = 'Fællespostkasse indbakke';

export const MESSAGE_TOOLBAR_FILTER_ALL = 'Alle';
export const MESSAGE_TOOLBAR_FILTER_UNREAD = 'Ulæst';
export const MESSAGE_TOOLBAR_FILTER_MARKED = 'Markeret med stjerne';
export const MESSAGE_TOOLBAR_FILTER_DRAFT = 'Kladde';

export const MESSAGE_TOOLBAR_FILTER_LABEL_ALL = 'Filtrer';
export const MESSAGE_TOOLBAR_FILTER_LABEL_UNREAD = 'Ulæst';
export const MESSAGE_TOOLBAR_FILTER_LABEL_MARKED = 'Stjerne';
export const MESSAGE_TOOLBAR_FILTER_LABEL_DRAFT = 'Kladde';
export const MESSAGE_TOOLBAR_COMMON_INBOXES = 'Fællespostkasser';
export const MESSAGE_TOOLBAR_PERSONAL_INBOXES = 'Personlig postkasse';
export const MESSAGE_TOOLBAR_CREATE_AUTO_REPLY = 'Opret autosvar';
export const MESSAGE_TOOLBAR_CREATE_NEW_FOLDER = 'Opret ny mappe';
export const MESSAGE_TOOLBAR_CREATE_NEW_FOLDER_INPUT = 'Skriv navn på mappe';
export const MESSAGE_TOOLBAR_FOLDER_INBOX = 'Indbakke';
export const MESSAGE_TOOLBAR_FOLDER_SENT = 'Sendt';
export const MESSAGE_TOOLBAR_FOLDER_DELETED = 'Slettet';
export const MESSAGE_TOOLBAR_SORT_DATE = 'Dato';
export const MESSAGE_TOOLBAR_SORT_SUBJECT = 'Emne';
export const MESSAGE_TOOLBAR_INVALID_NAME = 'Navnet på mappen kan max være 100 tegn';
export const MESSAGE_TOOLBAR_DELETE_MODAL_TITLE = 'Slet mappe';
export const MESSAGE_TOOLBAR_UPDATE_MODAL_TITLE = 'Omdøb mappe';
export const MESSAGE_TOOLBAR_DELETE_MODAL_TEXT1 = 'Denne mappe er ikke tom alle beskeder i mappen vil blive slettet';
export const MESSAGE_TOOLBAR_DELETE_MODAL_TEXT2 = 'Er du sikker på du vil slette denne mappe?';
export const MESSAGE_TOOLBAR_DELETE_FOLDER_TEXT1 =
  'Hvis denne mappe ikke er tom, vil alle beskeder i mappen blive slettet.';
export const MESSAGE_TOOLBAR_DELETE_FOLDER_TEXT2 = 'Er du sikker på, at du vil slette mappen?';
export const MESSAGE_TOOLBAR_DELETE_MODAL_NO = 'Fortryd';
export const MESSAGE_TOOLBAR_DELETE_MODAL_YES = 'Ja';
export const MESSAGE_TOOLBAR_EDIT_AUTO_REPLY = 'Rediger autosvar';
export const MESSAGE_TOOLBAR_THREAD_VIEW = 'Visning';
export const MESSAGE_TOOLBAR_THREAD_VIEW_STANDARD = 'Standard';
export const MESSAGE_TOOLBAR_THREAD_VIEW_COMPACT = 'Kompakt';

// - MESSAGE LIST -
export const MESSAGE_THREAD_TEXT_SINGLE = 'beskedtråd';
export const MESSAGE_THREAD_TEXT_PLURAL = 'beskedtråde';
export const MESSAGE_LIST_SEARCH_MESSAGES = 'Søg i beskeder';
export const MESSAGE_LIST_SHOW_MORE = 'Hent flere beskeder';
export const MESSAGE_UNDER_FIFTEEN_WARNING =
  'Du kan ikke læse indholdet, da det kræver at du er logget ind med et højere sikkerhedsniveau.';

// - NEW MESSAGE -
export const MESSAGE_HEADER_TITLE = 'Beskeder';
export const MESSAGE_NEW_TITLE = 'Opret besked';
export const MESSAGE_SEND_MESSAGE = 'Send besked';
export const MESSAGE_CREATE_MULTIPLE_THREADS = 'Opret besked enkeltvist til hver modtager';
export const MESSAGE_CREATE_MULTIPLE_THREADS_TITLE = 'Opret besked enkeltvist';
export const MESSAGE_ADD_BCC_TITLE = 'Tilføj BCC';
export const MESSAGE_LABEL_BCC = 'BCC';
export const MESSAGE_ADD_BCC_HELP =
  'BCC-feltet har erstattet “Opret besked enkeltvist til hver modtager” og virker på samme måde: Hvis du vælger at oprette denne besked til hver modtager, sendes beskeden enkeltvist til hver enkelt modtager. Det betyder, at modtagerne ikke kan se, hvem beskeden ellers er sendt til eller hvad de svarer, og de kan kun svare dig.';
export const MESSAGE_CREATE_MULTIPLE_THREADS_HELP =
  'Hvis du vælger at oprette denne besked til hver modtager, sendes beskeden enkeltvist til hver enkelt modtager fremfor i en samlet samtale med alle modtagere. Det betyder, at modtagerne ikke kan se, hvem beskeden ellers er sendt til og de kan kun svare dig.';
export const MESSAGE_NEW_TITLE_FORWARD_THREAD = 'Videresend samtale';
export const MESSAGE_NEW_TITLE_ANSWER_DIRECTLY = 'Svar afsender direkte';
export const MESSAGE_LABEL_FROM = 'Fra';
export const MESSAGE_LABEL_TO = 'Til';
export const MESSAGE_LABEL_CONTENTS = 'Besked';
export const MESSAGE_LABEL_SUBJECT = 'Emne';
export const MESSAGE_LABEL_DATE = 'Dato';
export const MESSAGE_LABEL_SUBJECT_PLACEHOLDER = 'Skriv emne';
export const MESSAGE_OPTION_GROUP_LABEL_PRIVATE_INBOX = 'Personlige postkasser';
export const MESSAGE_OPTION_GROUP_LABEL_COMMON_INBOX = 'Fællespostkasser';
export const MESSAGE_REPLY_LABEL = 'Besked som du svarer på';
export const MESSAGE_RESPOND = 'SVAR';
export const MESSAGE_RESPOND_PLACEHOLDER = 'Du svarer';
export const MESSAGE_RESPOND_AS_COMMON_INBOX_PLACEHOLDER = 'på vegne af';
export const MESSAGE_RESPOND_AS_COMMON_INBOX_PLACEHOLDER_TO = 'til';
export const MESSAGE_RESPOND_TO_ALL = 'SVAR ALLE';
export const MESSAGE_RESPOND_DISABLED = 'Du kan ikke skrive i en samtale som du har forladt.';
export const MESSAGE_RESPOND_DISABLED_TIMESTAMP_PREFIX = 'Du har forladt samtalen';
export const MESSAGE_RESPOND_ONLY_ONE =
  'Bemærk, at du er den eneste deltager af denne tråd. Det er derfor kun dig selv, der kan se beskederne.';
export const MESSAGE_LABEL_SENDER = 'Afsender';
export const MESSAGE_PLACEHOLDER_SENDER = 'Vælg afsender';
export const MESSAGE_PLACEHOLDER_RECEIVER = 'Søg efter personer eller grupper, du vil skrive til';
export const MESSAGE_SEND_BUTTON = 'SEND';
export const MESSAGE_CONTENT = 'TEKST';
export const MESSAGE_SHOW_BCC = '+ Tilføj BCC-felt';
export const MESSAGE_HIDE_BCC = '- Fjern BCC-felt';
export const MESSAGE_SHOW_BCC_APP = 'Tilføj BCC-felt';
export const MESSAGE_HIDE_BCC_APP = 'Fjern BCC-felt';
export const MESSAGE_SEARCH_PLACEHOLDER = 'Søg';
export const MESSAGE_SEARCH_NO_RESULT = 'Intet resultat';
export const MESSAGE_BLOCK_COMMUNICATION =
  'Du kan ikke skrive beskeder, fordi du har fået fjernet rettigheden til at skrive i Aula.';
export const MESSAGE_BLOCK_COMMUNICATION_TITLE = 'ADVARSEL';
export const MESSAGE_BLOCK_COMMUNICATION_BODY =
  'Du er blevet blokeret fra at kommunikere i Aula. Kontakt institutionens administrator for mere information';
export const MESSAGE_RECIPIENT_MISSING = 'Der er ikke valgt nogen modtager.';
export const MESSAGE_SUBJECT_MISSING = 'Emnefeltet er ikke udfyldt.';
export const MESSAGE_MESSAGE_MISSING = 'Beskedfeltet er ikke udfyldt.';
export const MESSAGE_UNKNOWN_EMAIL = 'Ukendt email';
export const MESSAGE_FOLLOWING_EMAIL_DONT_NOT_EXIST = 'Følgende email findes ikke: ';
export const MESSAGE_FOLLOWING_EMAILS_DOES_NOT_EXIST = 'Følgende emails findes ikke: ';
export const MESSAGE_COULD_NOT_SEND_MESSAGE_CHECK_INTERNET =
  'Beskeden kunne ikke sendes. Tjek din internetforbindelse.';
export const MESSAGE_DELIVERY_FAILED = 'Beskeden kunne ikke sendes';
export const MESSAGE_VALIDATE_RECIPIENTS = 'Du skal angive en modtager';
export const MESSAGE_VALIDATE_RECIPIENTS_WITH_BCC = 'Du skal angive mindst én modtager, her eller som BCC';
export const MESSAGE_VALIDATE_RECIPIENTS_LIMIT =
  'Beskeden kan ikke sendes, da der er valgt mere end 400 modtagere. Overvej at lave et opslag til de relevante grupper.';
export const MESSAGE_VALIDATE_RECIPIENTS_TOO_MANY_EMPLOYEE =
  'Beskeden kan ikke sendes, da der er valgt mere end 400 modtagere. Overvej at lave et opslag til de relevante grupper';
export const MESSAGE_VALIDATE_RECIPIENTS_TOO_MANY = 'Beskeden kan ikke sendes, da der er valgt mere end 400 modtagere.';
export const MESSAGE_VALIDATE_RECIPIENTS_TOO_MANY_WITH_PERMISSION =
  'Beskeden sendes til mere end 400 modtagere. Den bliver derfor splittet op i flere beskedtråde.';
export const MESSAGE_VALIDATE_RECIPIENTS_TOO_MANY_MULTIPLE =
  'Beskeden kan ikke sendes, da der er valgt mere end 100 modtagere. Overvej at lave et opslag til de relevante grupper.';
export const MESSAGE_VALIDATE_SUBJECT = 'Du skal angive et emne';
export const MESSAGE_VALIDATE_OTP_SENDER = 'Du skal vælge en afsender';
export const MESSAGE_VALIDATE_TEXT = 'Du skal skrive en besked';
export const MESSAGE_VALIDATE_TEXT_LENGTH = 'Teksten er for lang';

// - MESSAGE CONVERSATION -
export const MESSAGE_UNREAD_MESSAGES_PART_1 = 'Du har ';
export const MESSAGE_UNREAD_MESSAGES_PART_2 = ' ulæste beskeder i tråden';
export const MESSAGE_MANY_UNREAD_MESSAGES = 'Du har 40+ ulæste beskeder';
export const MESSAGE_CONVERSATION_HEADER = 'Startet af';
export const MESSAGE_CONVERSATION_HEADER_PART_1 = 'Samtalen er startet';
export const MESSAGE_CONVERSATION_HEADER_PART_2 = 'af';
export const MESSAGE_SENT_TO = 'Deltagere';
export const MESSAGE_AND = 'og';
export const MESSAGE_EDITED = 'Ændret';
export const MESSAGE_SEEN = 'Set';
export const MESSAGE_SEEN_BY = 'Set af {seenByCount} ud af {messageReceivers}';
export const MESSAGE_EDITING_MESSAGE = 'Rediger besked';
export const MESSAGE_TEXTAREA_PLACEHOLDER = 'Svarmuligheder';
export const MESSAGE_REPLY_TO_RECIPIENT = 'Svar';
export const MESSAGE_REPLY_DIRECTLY = 'direkte';
export const MESSAGE_SEEN_BY_ALL = 'Set af alle';
export const MESSAGE_ANOTHER = 'andren';
export const MESSAGE_OTHERS = 'andre';
export const MESSAGE_DELETE_THREAD = 'Slet';
export const MESSAGE_FORWARDING = 'Videresend';
export const MESSAGE_FORWARDED_NOTE = 'Ovenstående beskeder er videresendt';
export const MESSAGE_REPLY_NOTE = 'Denne besked er oprindeligt fra en anden samtale';
export const MESSAGE_MOVE_FOLDER = 'Flyt';
export const MESSAGE_AUTOREPLY_LABEL = 'Autosvar fra:';
export const MESSAGE_LEAVE_CONVERSATION = 'Forladt samtalen';
export const MESSAGE_MESSAGE_SENSITIVE = 'Følsomt indhold';
export const MESSAGE_CHOOSE_MESSAGE = 'Vælg en besked, du vil læse';
export const MESSAGE_STEP_UP_TITLE_FOR_ONBOARDING = 'BEMÆRK';
export const MESSAGE_STEP_UP_FOR_ONBOARDING_1 = 'Du mangler at udfylde nogle informationer';
export const MESSAGE_STEP_UP_FOR_ONBOARDING_2 =
  'Du skal derfor logge ind på et højere sikkerhedsniveau for at gøre dette.';
export const MESSAGE_STEP_UP_NOTIFICATION_AUTHORIZED_1 =
  'Indholdet kræver, at du er logget ind med et højere sikkerhedsniveau.';
export const MESSAGE_STEP_UP_NOTIFICATION_AUTHORIZED_MESSAGE =
  'Beskeden er markeret som følsom. Du skal derfor logge ind med et højere sikkerhedsniveau for læse beskeden.';
export const MESSAGE_STEP_UP_NOTIFICATION_AUTHORIZED_2 = 'Vil du fortsætte?';
export const MESSAGE_STEP_UP_NOTIFICATION_UNAUTHORIZED =
  'Du kan ikke læse indholdet, da det kræver at du er logget ind med et højere sikkerhedsniveau.';
export const WARNING_STEPUP_MARK_MESSAGE_AS_SENSITIVE = 'Du er ved at markere beskeden som følsom.';
export const WARNING_STEPUP_MARK_MESSAGE_AS_SENSITIVE_2 =
  'Er du sikker på, at beskeden indeholder følsomme personoplysninger?';
export const MESSAGE_LIST_GET_OLDER = 'Hent ældre beskeder';
export const MESSAGE_NO_ACCESS_TO_WRITE = 'Du har desværre ikke tilladelse til at svare på denne tråd';
export const MESSAGE_INVITATIONS_ADDED = 'Invitationen blev tilføjet beskeden for alle modtagere.';
export const MESSAGE_INVITATIONS_NOT_AVAILABLE = 'Invitationen kan ikke besvares længere.';
export const MESSAGE_VACATION_REQUEST_ADDED = 'Ferieanmodningen blev tilføjet til beskeden for alle modtagere.';
export const MESSAGE_VACATION_REQUEST_NOT_AVAILABLE =
  'Linket til ferieanmodningen blev fjernet. Er ferieanmodningen stadig aktiv og er deadline for svar ikke overskredet, kan du stadig ændre dit svar under Komme/gå.';
export const MESSAGE_COMMON_INBOX_DESCRIPTION = 'Fællespostkasse';

// MESSAGES FORWARD
export const MESSAGE_FORWARD_CONVERSATION_PART_1 = 'Samtalen er oprindeligt fra';
export const MESSAGE_FORWARD_CONVERSATION_PART_2 = 'af';
export const MESSAGE_FORWARD_CONVERSATION_PART_3 = 'og var sendt til:';
export const MESSAGE_FORWARD_CONVERSATION_PART_4 = 'og';
export const MESSAGE_FORWARD_CONVERSATION_OTHER = 'anden';
export const MESSAGE_FORWARD_CONVERSATION_OTHERS = 'andre';

// - MESSAGE EVENTS
export const MESSAGE_NEW_RECIPIENT_ADDED_BY_YOU = 'blev tilføjet til samtalen af dig';
export const MESSAGE_NEW_RECIPIENT_ADDED = 'blev tilføjet til samtalen af';
export const MESSAGE_OTHER_RECIPIENTS = '{recipientsCount} andre';
export const MESSAGE_OTHER_RECIPIENTS_ADDED_TITLE = 'Blev tilføjet';
export const MESSAGE_OTHER_RECIPIENTS_LEFT_TITLE = 'Følger ikke';
export const MESSAGE_USER_LEFT_THE_CONVERSATION = 'følger ikke længere med i samtalen.';
export const MESSAGE_USER_SEPARATOR_TEXT = ' og ';
export const MESSAGE_USER_SELF = 'Du';
export const MESSAGE_IS_DELETED = 'Beskeden er slettet.';

// - HANDLE MORE MESSAGES -
export const MESSAGE_DELETE = 'Slet';
export const MESSAGE_ARCHIVATE = 'Flyt';
export const MESSAGE_MARK = 'Marker';
export const MESSAGE_MARK_LONG = 'Marker med stjerne';
export const MESSAGE_REMOVE_MARK = 'Fjern';
export const MESSAGE_REMOVE_MARK_LONG = 'Fjern stjerne';
export const MESSAGE_MARK_AS_READ = 'Marker læst';
export const MESSAGE_MARK_AS_UNREAD = 'Marker ulæst';
export const MESSAGE_MARK_AS_READ_SHORT = 'Læst';
export const MESSAGE_MARK_AS_UNREAD_SHORT = 'Ulæst';
export const MESSAGE_OPEN_MESSAGE = 'Åbn besked';
export const MESSAGE_CHOSEN_HEADER = ' beskeder valgt';
export const MESSAGE_MOVE_TO_FOLDER = 'Flyt til mappe';
export const MESSAGE_CHOOSE_FOLDER = 'Vælg hvilken mappe, du vil flytte beskederne til:';
export const MESSAGE_MOVE = 'FLYT';
export const MESSAGE_CANCEL = 'Annuller';
export const MESSAGE_OK = 'OK';
export const MESSAGE_DELETE_MORE_THREADS_WARNING_1 = 'Du er ved at slette flere beskedtråde.';
export const MESSAGE_DELETE_MORE_THREADS_WARNING_2 = 'Er du sikker på, at du vil slette dem?';
export const MESSAGE_DELETE_MORE_THREADS_HEADER = 'ADVARSEL';
export const MESSAGE_DELETE_OK = 'JA';

// MESSAGE - SUBSCRIPTION ACTIONS
export const MESSAGE_ACTION_MARK_AS_UNREAD = 'Marker som ulæst';
export const MESSAGE_ACTION_MARK_AS_READ = 'Marker som læst';

// - BLOCKED COMMUNICATION MODALS
export const MESSAGE_MODAL_WARNING =
  'Du kan som Værge ikke kommunikere til andre Værger, da en administrator har blokeret din kommunikationskanal. Vil du forsætte og skrive til de medlemmer af gruppen?';
export const MESSAGE_MODAL_WARNING_BEGINNING = 'Du kan som';
export const MESSAGE_MODAL_WARNING_PROFILEMIDDLE = 'ikke kommunikere med';
export const MESSAGE_MODAL_WARNING_PROFILEEND =
  ', da en administrator har tilføjet en blokeret kommunikationskanal. Du kan prøve med en bruger der har en anden profiltype, eller du kan prøve at finde personens kontaktinformationer under kontakt i Aula.';
export const MESSAGE_MODAL_WARNING_MIDDLE = 'ikke kommunikere til andre';
export const MESSAGE_MODAL_WARNING_END =
  ', da en administrator har blokeret din kommunikationskanal. Vil du forsætte og skrive til de medlemmer af gruppen?';
export const WARNING_ALL_RECIPIENTS_HAVE_BLOCK_COMMUNICATION_CHANNEL =
  'Du er blokeret fra at kommunikere med de valgte modtagere. Beskeden kan derfor ikke sendes.';
export const WARNING_THERE_IS_PARTICIPANT_IN_THE_THREAD_HAVE_BLOCKED_COMMUNICATION_CHANNELS =
  'Du er blevet blokeret fra at kommunikere med en eller flere af deltagere i denne besked. Du kan derfor ikke længere skrive i denne besked.';
export const WARNING_THE_COMMUNICATION_CHANNEL_IS_BLOCKED =
  'Handlingen kunne ikke udføres pga. blokerede kommunikationskanaler.';
export const WARNING_THE_COMMUNICATION_CHANNEL_IS_BLOCKED_USERS =
  'Handlingen kunne ikke udføres pga. blokerede kommunikationskanaler. Skal vi fjerne følgende modtagere fra din liste?';
export const WARNING_THE_COMMUNICATION_CHANNEL_IS_BLOCKED_USERS_REMOVE =
  'Handlingen kunne ikke udføres pga. blokerede kommunikationskanaler. Du skal fjerne følgende modtagere fra din liste';
export const WARNING_FORWARD_MESSAGE =
  'Når du videresender en besked med en sikker fil, kan de kun åbne filen, hvis den allerede er delt med dem. Vil du fortsætte?';
export const WARNING_NEW_RECIPIENTS_ADDED =
  'Når du tilføjer en modtager til en beskedtråd med en sikker fil, kan de kun åbne filen, hvis den allerede er delt med dem. Vil du fortsætte?';

// - ADD RECIPIENTS
export const MESSAGE_ADD_RECIPIENTS_HEADER = 'Tilføj modtagere';
export const MESSAGE_ADD_RECIPIENTS_NO_RECIPIENTS = 'Du skal angive en modtager';
export const MESSAGE_ADD_RECIPIENTS_EXISTED_RECIPIENTS = 'Du kan ikke tilføje eksisterende modtagere:';
export const MESSAGE_ADD_RECIPIENTS_EXTERNAL =
  'Vær opmærksom på, at du har valgt en modtager uden for din egen forvaltningsmyndighed.';
export const MESSAGE_ADD_RECIPIENTS_UNKNOWN =
  'Kunne ikke sende besked, da du prøver at sende til modtagere som Aula ikke genkender';
export const MESSAGE_MANY_RECIPIENTS =
  'Oprettelse af denne besked kan godt tage lang tid, da der er over 150 modtagere';

// -THREAD -
export const MESSAGE_CONFIRM_DELETE = 'ADVARSEL';
export const MESSAGE_DELETE_THREAD_INFO_1 = 'Du er ved at slette hele beskedtråden.';
export const MESSAGE_DELETE_THREAD_INFO_2 = 'Beskedtråden slettes kun for dig.';
export const MESSAGE_DELETE_MORE_THREADS_INFO_2 = 'Beskedtrådene slettes kun for dig.';
export const MESSAGE_DELETE_THREAD_INFO_2_COMMON = 'Beskedtråden slettes hos alle modtagere af fællespostkassen.';
export const MESSAGE_DELETE_MORE_THREADS_INFO_2_COMMON =
  'Beskedtrådene slettes hos alle modtagere af fællespostkassen.';
export const MESSAGE_DELETE_THREAD_INFO_3 = 'Er du sikker på, at du vil slette den?';
export const MESSAGE_DELETE_THREAD_INFO_FROM_NORMAL_FOLDER =
  "Du vil forlade tråden og vil ikke kunne se nyt indhold i tråden, men du kan stadig finde den under 'Slettet post'.";
export const MESSAGE_DELETE_MORE_THREADS_INFO_FROM_NORMAL_FOLDER =
  "Du forlader trådene og vil ikke kunne se nyt indhold i dem, men du kan stadig finde dem under 'Slettet post'.";

export const MESSAGE_LEAVE_MODAL_HEADER = 'ADVARSEL';
// - AUTOREPLY -
export const MESSAGE_CREATE_AUTOREPLY = 'OPRET AUTOSVAR';
export const MESSAGE_CREATE_SENSITIVE = 'Marker som følsom';
export const MESSAGE_CREATE_SENSITIVE_MOBILE = 'Følsom';
export const MESSAGE_MARKED_AS_SENSITIVE = 'Markeret som følsom';
export const MESSAGE_START_NEW_THREAD = 'Samtalestarter';
export const MESSAGE_START_NEW_THREAD_TO_ALL = 'Alle i beskedsamtalen';
export const MESSAGE_ANSWER_TO_ALL = 'SVAR TIL ALLE';
export const MESSAGE_EDIT_AUTOREPLY = 'REDIGER AUTOSVAR';
export const MESSAGE_LABEL_AUTO_STARTDATE = 'STARTDATO';
export const MESSAGE_LABEL_AUTO_STARTTIME = 'STARTTIDSPUNKT';
export const MESSAGE_LABEL_AUTO_ENDDATE = 'SLUTDATO';
export const MESSAGE_LABEL_AUTO_ENDTIME = 'SLUTTIDSPUNKT';
export const MESSAGE_PLACEHOLDER_SELECT_DATE = 'dd/mm/åååå';
export const MESSAGE_PLACEHOLDER_SELECT_TIME = 'tt/mm';
export const MESSAGE_AUTOREPLY_BUTTON_CREATE = 'OPRET';
export const MESSAGE_BUTTON_CANCEL = 'Annuller';
export const MESSAGE_BUTTON_DELETE = 'Slet';
export const MESSAGE_BUTTON_EDIT = 'REDIGER';
export const MESSAGE_BUTTON_OK = 'Ok';
export const MESSAGE_REQUIRE_TEXT = 'Du skal skrive en tekst';
export const MESSAGE_TYPING_PLACEHOLDER = 'Skriv her...';
export const MESSAGE_ARIA_LABEL_TYPING_PLACEHOLDER = 'Skriv indhold her';
export const MESSAGE_WARNING_HEADER = 'ADVARSEL';
export const MESSAGE_WARNING_BODY = 'Du har et autosvar aktiveret, er du sikker på at du vil sende denne besked?';
export const MESSAGE_WARNING_SEND = 'SEND';
export const MESSAGE_WARNING_CANCEL = 'Annuller';
export const MESSAGE_SAVE = 'Gem';
export const MESSAGE_STARTDATE_NEEDS_TO_BE_FILLED = 'Startdatoen mangler at blive udfyldt.';
export const MESSAGE_AUTOREPLY_CREATED_TITLE = 'Autosvar er gemt';
export const MESSAGE_AUTOREPLY_CREATED_TEXT = 'Dit autosvar er nu gemt';
export const MESSAGE_SOMETHING_WENT_WRONG_TITLE = 'Noget gik galt';
export const MESSAGE_AUTOREPLY_WAS_NOT_SAVED = 'Dit autosvar blev desværre ikke gemt.';
export const MESSAGE_SEE_MORE_RECIPIENTS = 'Se flere modtagere';
export const MESSAGE_DELETE_MESSAGE_WARNING_1 = 'Du er ved at slette din besked.';
export const MESSAGE_DELETE_MESSAGE_WARNING_2 = 'Beskeden slettes for alle.';
export const MESSAGE_DELETE_MESSAGE_WARNING_3 = 'Er du sikker på, at du ønsker at slette beskeden?';
export const ARIA_LABEL_MESSAGE_OPTION_CLOSE = 'Luk';

export const MESSAGE_SHAREABLE_SECURE_FILES =
  'Du kan kun dele sikre filer med andre medarbejdere eller de omhandlende børns forældre. For at sende beskeden skal du fjerne en eller flere modtagere eller fjerne vedhæftningen af den sikre fil.';
export const MESSAGE_FAIL_HEADER = 'FEJL';

export const MESSAGE_LEAVE_MODAL_BODY_1 = 'Du er ved at forlade beskedtråden.';
export const MESSAGE_LEAVE_MODAL_BODY_2 = 'Er du sikker på, at du vil forlade den?';
export const MESSAGE_LEAVE_MODAL_OK = 'JA';
export const MESSAGE_LEAVE_MODAL_CANCEL = 'ANNULLER';

export const MESSAGE_SENSITIVE_MODAL_TITLE = 'ADVARSEL';
export const MESSAGE_SENSITIVE_MODAL_BODY_ONE =
  'Du er ved at sende en besked, der indeholder følsomme personoplysninger til en modtager uden for egen forvaltningsmyndighed. Dette kan være imod lovgivningen.';
export const MESSAGE_SENSITIVE_MODAL_BODY_TWO = 'Er du sikker på, at du vil sende denne besked?';

export const MESSAGE_DROPDOWN_ADD_RECIPIENTS = 'Tilføj modtagere';
export const MESSAGE_DROPDOWN_MUTE = 'Slå notifikationer fra';
export const MESSAGE_DROPDOWN_MUTE_HELP =
  'Du kan slå notifikationer fra, så du ikke længere modtager notifikationer,\nnår der kommer nye beskeder i samtalen.';
export const MESSAGE_DROPDOWN_UNMUTE = 'Slå notifikationer til';
export const MESSAGE_DROPDOWN_UNMUTE_HELP =
  'Du kan slå notifikationer til, så du igen modtager notifikationer,\nnår der kommer nye beskeder i samtalen.';
export const MESSAGE_DROPDOWN_LEAVE = 'Forlad samtalen';
export const MESSAGE_DROPDOWN_LEAVE_HELP =
  'Du kan forlade en samtale, hvilket betyder,\nat du ikke længere modtager nye beskeder i samtalen,\nhvis nogle af deltagerne skriver en ny besked.';
export const MESSAGE_DROPDOWN_SENSITIVE_HELP =
  'Du kan markere en besked som følsom,\nhvis den indeholder følsomme personoplysninger.\nDet betyder, at modtagerne skal logge ind på et\nhøjere sikkerhedsniveau, for at kunne læse beskederne.';
export const MESSAGE_DROPDOWN_MORE = 'Flere';
export const MESSAGE_DROPDOWN_SENSITIVE = 'Marker som følsom';
export const MESSAGE_DROPDOWN_UNSENSITIVE = 'Fjern marker som følsom';
export const MESSAGE_DROPDOWN_CONNECT = 'Tilknyt beskeder til sikker fil';
export const MESSAGE_CANCEL_HEADER = 'Annuller besked';
export const MESSAGE_DELETE_HEADER = 'ADVARSEL';
export const MESSAGE_PROFILE_EMPLOYEE = 'Medarbejdere';
export const MESSAGE_PROFILE_CHILD = 'Barn';
export const MESSAGE_PROFILE_GUARDIAN = 'Værge';
export const MESSAGE_ADDED_TO_SECURE_DOCUMENT_TOASTR =
  'Beskedtråden er vedhæftet den sikre fil. Der kan gå op til et minut før filen er færdigbehandlet og kan åbnes';
export const MESSAGE_SEND_REMINDER_SUCCESSFULLY = 'Rykker blev sendt.';

// - MOBILE NAVIGATION -
export const MESSAGE_BAR_TEXT = 'BESKED';
export const MESSAGE_ATTACH_DOCUMENT_SELECT_ALL = 'Vælg hele tråden';
export const MESSAGE_ATTACH_BUTTON_CANCEL = 'Fortryd';
export const MESSAGE_ATTACH_BUTTON_OK = 'Tilknyt';

// - UNMARK SENSITIVE MESSAGE
export const UNMARK_AS_SENSITIVE_MESSAGE =
  'Du er ved at fjerne markeringen af, at beskeden indeholder følsomme personoplysninger.';
export const UNMARK_AS_SENSITIVE_MESSAGE_2 = 'Er du sikker på, at du vil fortsætte?';
export const MESSAGE_DISCARD_WARNING = 'Tekstfeltet er ikke tomt. Hvis du fortsætter gemmes dette indhold ikke.';
export const MESSAGE_DISCARD_WARNING_2 = 'Er du sikker på, at du vil fortsætte?';
export const MESSAGE_DISCARD_EDITING_WARNING = 'Ændringerne vil ikke blive gemt, hvis du annullerer.';
export const MESSAGE_DISCARD_EDITING_WARNING_2 = 'Er du sikker på, at du vil fortsætte?';
export const MESSAGE_DISCARD_DRAFT_ALL_USERS =
  "Du er ved at lukke beskedfeltet for 'Svar til alle'. Det medfører, at din tekst bliver slettet.";
export const MESSAGE_DISCARD_DRAFT_1 = 'Du er ved at lukke beskedfeltet. Det medfører, at din tekst bliver slettet.';
export const MESSAGE_DISCARD_DRAFT_2 = 'Ønsker du at fortsætte?';

// - SCREEN READER TEXTS
export const MESSAGE_SR_MARK_FAVORITE = 'Marker favorit';
export const MESSAGE_SR_NO_TITLE = 'Ingen titel';
export const MESSAGE_SR_NOT_READ = 'Ikke læst';
export const MESSAGE_SR_CREATED = 'Oprettet:';
export const MESSAGE_SR_SEND_TO = 'Sendt til:';
export const MESSAGE_SR_READ_MESSAGES = 'Læs beskeder';
export const MESSAGE_SR_MARK_AS_FAVORITE = 'Marker som favorit';
export const MESSAGE_SR_UNMARK_FAVORITE = 'Fjern favorit markering';
export const MESSAGE_SR_MOVE_TO_FOLDER = 'Flyt til anden mappe';
export const MESSAGE_SR_DELETE_THREAD = 'Slet denne tråd';
export const MESSAGE_SEARCH_RESULT_LINE_ONE = 'Der vises kun den relevante beskedtråd';
export const MESSAGE_SEARCH_RESULT_LINE_TWO = 'Klik her for at gå til indbakken og se alle beskedtråde';
export const ARIA_LABEL_MESSAGE_SUBJECT = 'Skriv emne';
export const ARIA_LABEL_MOVE_THREAD_TO_FOLDER = 'Flyt denne tråd til mappe';
export const ARIA_LABEL_MARK_THREAD = 'Marker tråden';
export const ARIA_LABEL_UNMARK_THREAD = 'Fjern markeringen af tråden';
export const ARIA_LABEL_CHECK_THE_THREAD = 'Vælg denne tråd';
export const ARIA_LABEL_SENSITIVE_THREAD = 'Følsom tråd';
export const ARIA_LABEL_FOLD_SUBFOLDERS = 'Fold undermappe';
export const ARIA_LABEL_UNFOLD_SUBFOLDERS = 'Fold undermapper ud';

export const MESSAGE_ADVANCED_SEARCH = 'Avanceret Søgning';
export const MESSAGE_ADVANCED_SEARCH_CLEAR_ALL = 'Ryd alle';
export const MESSAGE_ADVANCED_SEARCH_KEYWORD = 'Nøgleord';
export const MESSAGE_ADVANCED_SEARCH_SUBJECT = 'Emne';
export const MESSAGE_ADVANCED_SEARCH_CONTENT = 'Beskedindhold';
export const MESSAGE_ADVANCED_SEARCH_FILTER = 'Filtrer';
export const MESSAGE_ADVANCED_SEARCH_CREATOR = 'Opretter af samtale';
export const MESSAGE_ADVANCED_SEARCH_ATTACHMENT = 'Søg kun i beskeder med vedhæftede filer';
export const MESSAGE_ADVANCED_SEARCH_KEYWORD_PLACEHOLDER = 'Indtast nøgleord, du vil søge efter';
export const MESSAGE_ADVANCED_SEARCH_SUBJECT_PLACEHOLDER = 'Indtast emne, du vil søge efter';
export const MESSAGE_ADVANCED_SEARCH_CONTENT_PLACEHOLDER = 'Indtast beskedindhold, du vil søge efter';
export const MESSAGE_ADVANCED_SEARCH_PEOPLE_PLACEHOLDER = 'Søg efter en eller flere personer';

export const MESSAGE_ADVANCED_SEARCH_INBOX = 'Du søger nu i din egen ';
export const MESSAGE_ADVANCED_SEARCH_INBOX_1 = ' og alle undermapper hertil';
export const MESSAGE_ADVANCED_SEARCH_INBOX_2 = 'i undermappen ';
export const MESSAGE_ADVANCED_SEARCH_COMMON_INBOX = 'Du søger nu i fællesindbakken ';
export const MESSAGE_ADVANCED_SEARCH_COMMON_INBOX_1 = 'og alle undermapper hertil';
export const MESSAGE_ADVANCED_SEARCH_COMMON_INBOX_2 = 'i undermappen';

export const MESSAGE_SELF_LATEST_MESSAGE_PREFIX = 'Dig';
export const MESSAGE_SELF_READ_MESSAGE = 'Dig';
export const MESSAGE_NO_MORE_THREADS = 'Der er ikke flere beskeder';
