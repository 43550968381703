<template>
  <aula-modal
    ref="presenceDistributionModal"
    :show-cancel="false"
    :header-text-from-textkey="false"
    :header-text="modalHeader"
    @okClicked="$refs.presenceDistributionModal.hide()"
  >
    <div class="presence-distribution-container">
      <template v-if="!isLoading && presenceDistributionData">
        <div class="mx-2">
          <b-row class="distribution-data-row d-flex justify-content-between">
            <div>
              {{
                isBeforeToday
                  ? 'PRESENCE_WEEK_OVERVIEW_CHECK_IN_PAST'
                  : 'PRESENCE_WEEK_OVERVIEW_CHECK_IN_FUTURE' | fromTextKey
              }}
            </div>
            <div>{{ presenceDistributionData.numberPresent }}</div>
          </b-row>
          <b-row class="distribution-data-row d-flex justify-content-between">
            <div>
              {{ 'PRESENCE_STATE_VACATION' | fromTextKey }}
            </div>
            <div>{{ presenceDistributionData.numberOnVacation }}</div>
          </b-row>
          <b-row class="distribution-data-row d-flex justify-content-between">
            <div>
              {{ 'PRESENCE_STATE_SICK' | fromTextKey }}
            </div>
            <div>{{ presenceDistributionData.numberSick }}</div>
          </b-row>
        </div>
        <b-row class="coming-children-label">
          {{
            isBeforeToday
              ? 'PRESENCE_COMING_CHILDREN_LABEL_PAST'
              : 'PRESENCE_COMING_CHILDREN_LABEL_FUTURE' | fromTextKey
          }}
        </b-row>
        <div class="mx-2 mb-4">
          <template v-if="presenceDistributionData.isDistributionEnabled">
            <b-row
              v-for="interval in presenceDistributionData.intervals"
              :key="`interval-${interval.startTime}`"
              :class="{ 'highlighted-interval': interval.isCurrent }"
              class="distribution-data-row d-flex justify-content-between"
            >
              <div>
                {{ `${interval.startTime} - ${interval.endTime}` }}
              </div>
              <div>{{ interval.numberOfChildren }}</div>
            </b-row>
          </template>
          <template v-else>
            <b-row class="mt-2 mt-lg-0">
              {{ 'PRESENCE_TIME_CONFIGURATION_DISABLED_WARNING' | fromTextKey }}
            </b-row>
          </template>
        </div>
      </template>
      <aula-spinner v-else />
    </div>
  </aula-modal>
</template>

<script>
import moment from 'moment-timezone';
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { types } from '../../store/types/types';

export default {
  data: function () {
    return {
      modalHeader: '',
      isBeforeToday: null,
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
      presenceDistributionData: types.GET_PRESENCE_DISTRIBUTION,
    }),
  },
  methods: {
    ...mapActions({
      getPresenceDistribution: types.LOAD_PRESENCE_DISTRIBUTION,
    }),
    getHeader(presenceDate) {
      const date = moment(presenceDate);
      const dateNumberToName = Vue.filter('dateNumberToName')(date.isoWeekday());
      return `${dateNumberToName.slice(0, 3)}. D. ${date.format('DD. MMM.')}`;
    },
    checkDateIsBeforeToday(presenceDate) {
      return moment(presenceDate).isBefore(new Date(), 'day');
    },
    initPresenceDistribution(presenceDate) {
      this.isLoading = true;
      this.getPresenceDistribution({ date: presenceDate }).then(() => {
        this.isLoading = false;
      });
    },
    show(presenceDate) {
      this.modalHeader = this.getHeader(presenceDate);
      this.isBeforeToday = this.checkDateIsBeforeToday(presenceDate);

      this.initPresenceDistribution(presenceDate);
      this.$refs.presenceDistributionModal.show();
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables';
@import '../../../shared/assets/scss/core/breakpoints';
@import '../../../shared/assets/scss/components/presence/presence-employee';
</style>
