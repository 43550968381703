<template>
  <div v-if="widgetData" class="aula-widget-placeholder">
    <template v-if="ready">
      <template v-if="widgetData.type === widgetTypesEnum.SSO">
        <widget-link
          :is-mobile="true"
          :supplier="widgetData.widgetSupplier"
          :s-s-oaction="widgetData.url"
          :widget-title="widgetData.name"
          widget-type="SSO"
          :widget-i-d="widgetData.widgetId"
          :widget-version="widgetData.widgetVersion"
        />
      </template>
      <template v-else>
        <widget-content
          :is-mobile="true"
          placement="full"
          :supplier="widgetData.widgetSupplier"
          :widget-type="widgetData.type"
          :widget-i-d="widgetData.widgetId"
          :widget-version="widgetData.widgetVersion"
          :widget-url="widgetData.url"
          :notifications="getWidgetNotifications(widgetData)"
        />
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { types } from "../../store/types/types";
import WidgetContent from "./WidgetContent";
import WidgetLink from "./WidgetLink";
import { moduleWidgetPlacements } from "../../../shared/enums/moduleWidgetPlacements";
import { widgetTypes } from "../../../shared/enums/widgetTypes";
import { widgetUtil } from "../../../shared/utils/widgetUtil";
import isEmpty from "lodash/isEmpty";

export default {
  data: function () {
    return {
      widgetTypesEnum: widgetTypes,
      ready: false
    };
  },
  computed: {
    ...mapGetters({
      menuItems: types.GET_MENU_ITEMS,
      activeGroup: types.GET_ACTIVE_GROUP,
      notifications: types.GET_NOTIFICATIONS
    }),
    widgetData() {
      if (isEmpty(this.menuItems)) {
        return null;
      }

      let widget = [];

      if (this.menuItems[moduleWidgetPlacements.OWN_PAGE].length > 0) {
        widget = this.menuItems[moduleWidgetPlacements.OWN_PAGE].filter(
          w => "W" + w.widgetId + "V" + w.widgetVersion == this.$route.params.id
        );
      }

      if (widget.length == 0 && this.menuItems[moduleWidgetPlacements.BELOW_OF_CALENDAR].length > 0) {
        widget = this.menuItems[moduleWidgetPlacements.BELOW_OF_CALENDAR].filter(
          w => "W" + w.widgetId + "V" + w.widgetVersion == this.$route.params.id
        );
      }

      if (widget.length == 0 && this.menuItems[moduleWidgetPlacements.RIGHT_OF_CALENDAR].length > 0) {
        widget = this.menuItems[moduleWidgetPlacements.RIGHT_OF_CALENDAR].filter(
          w => "W" + w.widgetId + "V" + w.widgetVersion == this.$route.params.id
        );
      }

      if (widget.length == 0 && this.menuItems[moduleWidgetPlacements.RIGHT_OF_OVERVIEW].length > 0) {
        widget = this.menuItems[moduleWidgetPlacements.RIGHT_OF_OVERVIEW].filter(
          w => "W" + w.widgetId + "V" + w.widgetVersion == this.$route.params.id
        );
      }

      if (
        widget.length == 0 &&
        this.$route.query.group != undefined &&
        this.activeGroup.validGroupWidgets != undefined
      ) {
        const groupWidget = this.activeGroup.validGroupWidgets.filter(
          w => "W" + w.widget.widgetId + "V" + w.widget.widgetVersion == this.$route.params.id
        );
        if (groupWidget.length != 0) {
          widget = [groupWidget[0].widget];
        }
      }

      if (widget.length > 0) {
        widget[0].component = this.$route.params.id;
        return widget[0];
      }

      return null;
    }
  },
  methods: {
    ...mapActions({
      setCurrentWeek: types.SET_CURRENT_WEEK,
      setActiveFilters: types.SET_ACTIVE_FILTERS_DIRECT,
      setActiveGroup: types.SET_ACTIVE_GROUP
    }),
    setParams() {
      let institutionFilter = this.$route.query.institutionFilter;
      let childFilter = this.$route.query.childFilter;
      const currentWeekNumber = this.$route.query.currentWeekNumber;
      const group = this.$route.query.group;

      if (institutionFilter == 0 || institutionFilter == undefined) {
        institutionFilter = [];
      } else if (typeof institutionFilter != "object") {
        institutionFilter = [institutionFilter];
      }
      if (childFilter == 0 || childFilter == undefined) {
        childFilter = [];
      } else if (typeof childFilter != "object") {
        childFilter = [childFilter];
      }
      this.setActiveFilters({
        institutionCodes: institutionFilter,
        children: childFilter
      });

      if (currentWeekNumber != 0 && currentWeekNumber != undefined) {
        this.setCurrentWeek({ week: currentWeekNumber });
      }

      if (group != 0 && group != undefined) {
        this.setActiveGroup({ groupId: group });
      }

      this.ready = true;
    },
    getWidgetNotifications(widget) {
      return widgetUtil.getNotifications(this.notifications, { widgetId: widget.id });
    }
  },
  watch: {
    $route() {
      this.setParams();
    }
  },
  mounted() {
    const em = this;
    setTimeout(
      function () {
        em.setParams();
      },
      2000,
      em
    );
  },
  components: {
    WidgetContent,
    WidgetLink
  }
};
</script>

<style>
html,
body {
  padding: 0;
}
</style>
<style scoped lang="scss">
.aula-widget-placeholder {
  padding: 5px;

  /deep/ .aula-widget {
    padding: 0;
  }
}
</style>
