<template>
  <div class="presence-daily-overview" :class="{ 'mt-3': !isMobile }">
    <aula-spinner v-if="isLoading" />
    <div v-for="(child, index) of dailyOverview" v-else :key="index" class="box">
      <div class="box-title-container d-flex align-items-center px-1">
        <div v-if="isMobile" class="user-icon-mobile ml-4">
          <user-icon
            :id="child.id"
            class="larger-mobile-user-icon"
            :name="child.institutionProfile.name"
            :picture-object="child.institutionProfile.profilePicture"
            :short-name="child.institutionProfile.shortName"
          />
          <div class="status-icon" :class="showPresenceState(child).class">
            <i :class="showPresenceState(child).icon" role="figure" :aria-label="showPresenceState(child).label" />
          </div>
        </div>
        <div class="box-title" tabindex="0" :class="{ 'child-info-mobile': isMobile }">
          {{ child.institutionProfile.name }} ({{ child.mainGroup.name }})
        </div>
      </div>
      <div class="box-content inline">
        <div v-if="!isMobile" class="user-icon large">
          <user-icon
            :id="child.id"
            :name="child.institutionProfile.name"
            :picture-object="child.institutionProfile.profilePicture"
            :short-name="child.institutionProfile.shortName"
          />
          <div :class="showPresenceState(child).class" class="status-icon">
            <i :class="showPresenceState(child).icon" role="figure" :aria-label="showPresenceState(child).label" />
          </div>
        </div>
        <div class="user-activities" role="contentinfo" tabindex="0">
          <template v-if="isTodayClosedDays(child.institutionProfile.institutionCode)">
            <span class="closed-day-text">
              {{ 'PRESENCE_CLOSED_DAY_DAILY_OVERVIEW' | fromTextKey }}:
              {{ todayClosedDaysString(child.institutionProfile.institutionCode) }}
            </span>
          </template>
          <template v-else>
            <div v-if="isSpecificOpenHours(child)" class="specific-open-hours-info-container">
              <div class="aula-alert mb-3">
                <div class="icon-box-exclamation">
                  <div class="circle">
                    <i class="icon-Aula_exclamation_sign" />
                  </div>
                </div>
                <div class="alert-text">
                  <strong> {{ 'PRESENCE_NOTE' | fromTextKey }}: </strong>
                  {{ 'PRESENCE_SPECIFIC_TIME_INFO' | fromTextKey }}:
                  {{ getInstitutionSpecificOpenHours(child) }}
                </div>
              </div>
            </div>
            <strong>{{ 'PRESENCE_STATUS' | fromTextKey }}</strong
            >: {{ getPresenceStatusText(child) }}<br />
            <template v-if="child.checkInTime != null">
              <strong>{{ 'PRESENCE_DELIVERY_TIME_AFTER' | fromTextKey }}</strong>
              {{ 'AT' | fromTextKey }}
              <span>{{ moment(child.checkInTime, 'HH:mm:ss').format('HH:mm') }}</span
              ><br />
            </template>
            <template v-if="child.checkOutTime != null">
              <strong>{{ 'PRESENCE_PICK_UP_TIME_AFTER' | fromTextKey }}</strong>
              {{ 'AT' | fromTextKey }}
              <span>{{ moment(child.checkOutTime, 'HH:mm:ss').format('HH:mm') }}</span
              ><br />
            </template>
            <template v-if="child.sleepIntervals.length > 0 && showChildActivity(child)">
              <strong>{{ 'PRESENCE_SLEEP' | fromTextKey }}</strong>
              <span v-for="(sleep, index) of child.sleepIntervals" :key="index">
                {{ moment(sleep.startTime, 'HH:mm:ss').format('HH:mm') }} -
                <template v-if="sleep.endTime"
                  >{{ moment(sleep.endTime, 'HH:mm:ss').format('HH:mm')
                  }}<template v-if="index < child.sleepIntervals.length - 1">, </template></template
                >
                <template v-else
                  >{{ 'PRESENCE_WITHOUT_END_TIME_SLEEPING' | fromTextKey
                  }}<template v-if="index < child.sleepIntervals.length - 1">, </template></template
                >
              </span>
              <br />
            </template>
            <template v-if="child.activityType === exitTypes.PICKED_UP_BY && showChildActivity(child)">
              <strong>
                {{ child.exitWith ? 'PRESENCE_ACTIVITY_PICKED_UP_BY' : 'PRESENCE_ACTIVITY_PICKED_UP' | fromTextKey }}:
              </strong>
              {{ child.exitWith }}
              <span v-if="child.isDefaultExitTime === true">
                {{ 'AT' | fromTextKey }}
                {{ 'PRESENCE_TIME_NOT_SPECIFIED_PARENTHESIS' | fromTextKey }}
              </span>
              <span v-else-if="child.checkOutTime != null">
                {{ 'AT' | fromTextKey }}
                {{ moment(child.checkOutTime, 'HH:mm:ss').format('HH:mm') }}
              </span>
              <span
                v-else-if="child.exitTime != null"
                :class="{
                  'alert-specific-time': child.isPlannedTimesOutsideOpeningHours === true,
                }"
              >
                {{ 'AT' | fromTextKey }}
                {{ moment(child.exitTime, 'HH:mm:ss').format('HH:mm') }}
              </span>
              <br />
            </template>
            <template v-if="child.activityType === exitTypes.GO_HOME_WITH && showChildActivity(child)">
              <strong>{{ 'PRESENCE_ACTIVITY_GO_HOME_WITH' | fromTextKey }}:</strong>
              {{ child.exitWith }}
              <span v-if="child.isDefaultExitTime === true">
                {{ 'AT' | fromTextKey }}
                {{ 'PRESENCE_TIME_NOT_SPECIFIED_PARENTHESIS' | fromTextKey }}
              </span>
              <span
                v-else-if="child.exitTime != null"
                :class="{
                  'alert-specific-time': child.isPlannedTimesOutsideOpeningHours === true,
                }"
              >
                {{ 'AT' | fromTextKey }}
                {{ moment(child.exitTime, 'HH:mm:ss').format('HH:mm') }}
              </span>
              <br />
            </template>
            <template v-if="child.activityType === exitTypes.SEND_HOME && showChildActivity(child)">
              <strong>{{ 'PRESENCE_ACTIVITY_SEND_HOME' | fromTextKey }}</strong>
              <span
                v-if="showSendHome(child)"
                :class="{
                  'alert-specific-time': child.isPlannedTimesOutsideOpeningHours === true,
                }"
                >: {{ 'AT' | fromTextKey }} {{ moment(child.exitTime, 'HH:mm:ss').format('HH:mm') }}</span
              >
              <br />
            </template>
            <template v-if="child.activityType === exitTypes.SELF_DECIDER && showChildActivity(child)">
              <strong>{{ 'PRESENCE_ACTIVITY_SELF_DECIDER' | fromTextKey }}</strong>
              <span
                v-if="showSelfDecider(child)"
                :class="{
                  'alert-specific-time': child.isPlannedTimesOutsideOpeningHours === true,
                }"
                >: {{ 'AT' | fromTextKey }}
                {{ moment(child.selfDeciderStartTime, 'HH:mm:ss').format('HH:mm') }}
                -
                {{ moment(child.selfDeciderEndTime, 'HH:mm:ss').format('HH:mm') }}</span
              ><br />
            </template>
            <div
              v-if="child.isPlannedTimesOutsideOpeningHours === true && showChildActivity(child)"
              class="specific-open-hours-alert-container mt-3"
            >
              <div class="aula-alert mb-3">
                <div class="icon-box-exclamation">
                  <div class="circle">
                    <i class="icon-Aula_exclamation_sign" />
                  </div>
                </div>
                <div class="alert-text">
                  {{ 'PRESENCE_ALERT_OUTSIDE_SPECIFIC_HOURS' | fromTextKey }}
                </div>
              </div>
            </div>
            <template v-if="child.spareTimeActivity != null && showChildActivity(child)">
              <strong>{{ 'PRESENCE_SPARE_TIME' | fromTextKey }}:</strong>
              {{ 'AT' | fromTextKey }}
              {{ moment(child.spareTimeActivity.startTime, 'HH:mm:ss').format('HH:mm') }}
              -
              {{ moment(child.spareTimeActivity.endTime, 'HH:mm:ss').format('HH:mm') }}
              <br />
              <span v-if="child.spareTimeActivity.comment"
                ><strong>{{ 'PRESENCE_COMMENT' | fromTextKey }}:</strong> {{ child.spareTimeActivity.comment }}<br
              /></span>
            </template>
            <template v-if="child.comment != null && child.comment != ''">
              <strong>{{ 'PRESENCE_DAILY_COMMENT' | fromTextKey }}:</strong>
              <div>
                {{ child.comment }}
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { types } from '../../store/types/types';
import { mapActions, mapGetters } from 'vuex';
import { presenceStates } from '../../../shared/enums/presenceStates';
import { exitTypes } from '../../../shared/enums/exitTypes';
import { presenceModules } from '../../../shared/enums/presenceModules';
import { presenceOpenHours } from '../../../shared/enums/presenceOpenHours';
import moment from 'moment-timezone';

export default {
  data: function () {
    return {
      isLoading: false,
      exitTypes: exitTypes,
      presenceStates: presenceStates,
      presenceOpenHours: presenceOpenHours,
      presenceStatusText: {
        [presenceStates.NOT_PRESENT]: 'PRESENCE_STATE_NOT_PRESENT',
        [presenceStates.SICK]: 'PRESENCE_STATE_SICK',
        [presenceStates.REPORTED_ABSENT]: 'PRESENCE_STATE_VACATION',
        [presenceStates.PRESENT]: 'PRESENCE_STATE_PRESENT',
        [presenceStates.FIELDTRIP]: 'PRESENCE_STATE_FIELDTRIP',
        [presenceStates.SLEEPING]: 'PRESENCE_STATE_SLEEPING',
        [presenceStates.SPARE_TIME_ACTIVITY]: 'PRESENCE_ACTIVITY_SPARE_TIME',
        [presenceStates.PHYSICAL_PLACEMENT]: 'PRESENCE_STATE_PHYSICAL_PLACEMENT',
        [presenceStates.CHECKED_OUT]: 'PRESENCE_ACTIVITY_CHECK_OUT',
      },
    };
  },
  computed: {
    ...mapGetters({
      children: types.GET_CHILDREN,
      activeChildren: types.GET_ACTIVE_CHILDREN,
      isMobile: types.GET_IS_MOBILE,
      dailyOverview: types.GET_DAILY_OVERVIEW,
      getPresenceStates: types.GET_PRESENCE_STATES_GUARDIAN,
      states: types.GET_PRESENCE_STATES,
      getAllPresenceModules: types.GET_ALL_PRESENCE_MODULES,
      getOpenHours: types.GET_PRESENCE_OPEN_HOURS_GUARDIAN_DASHBOARD,
      holidayAndClosedDays: types.GET_HOLIDAY_CLOSED_DAY,
      getPresenceActiveModules: types.GET_PRESENCE_ACTIVE_MODULES,
    }),
  },
  methods: {
    ...mapActions({
      loadPresenceStates: types.LOAD_PRESENCE_STATES,
      loadDailyOverview: types.LOAD_DAILY_OVERVIEW,
      loadHolidayClosedDays: types.LOAD_HOLIDAY_CLOSED_DAY,
    }),
    showChildActivity(child) {
      return ![presenceStates.SICK, presenceStates.REPORTED_ABSENT].includes(child.status);
    },
    showSelfDecider(child) {
      if (
        this.getPresenceActiveModules.selfDecider?.includes(child.institutionProfile.id) &&
        child.selfDeciderStartTime &&
        child.selfDeciderEndTime
      ) {
        return true;
      }
      return false;
    },
    showSendHome(child) {
      if (this.getPresenceActiveModules.sendHome?.includes(child.institutionProfile.id) && child.exitTime) {
        return true;
      }
      return false;
    },
    showPresenceState(child) {
      const presenceState = {};
      if (!this.canShowStatus(child)) {
        return presenceState;
      }
      if (child.location != null && child.status == presenceStates.PHYSICAL_PLACEMENT) {
        presenceState.label = child.location.name;
        presenceState.icon = child.location.symbol;
        presenceState.class = 'physical-placement';
      } else {
        const groupStatus = this.states.find(state => state.options.find(option => option.value == child.status));
        if (groupStatus) {
          const state = groupStatus.options.find(option => option.value == child.status);
          if (state) {
            presenceState.label = state.label;
            presenceState.icon = state.icon;
            presenceState.class = state.class;
          }
        }
      }
      return presenceState;
    },
    getPresenceStatusText(child) {
      if (this.canShowStatus(child)) {
        let status = this.$options.filters.fromTextKey(this.presenceStatusText[child.status]);
        if (child.status === presenceStates.PHYSICAL_PLACEMENT) {
          status = child.location != null ? child.location.name : '';
        }
        return status;
      }
      return '';
    },
    loadDailyOverviewByChildIds() {
      const activeChildren = this.children.filter(child => this.activeChildren.includes(child.id));
      if (activeChildren.length > 0) {
        this.isLoading = true;
        if (this.getPresenceStates.length > 0) {
          const presenceChildrenIds = this.getPresenceStates.map(s => s.uniStudentId);
          this.loadDailyOverview({ childIds: presenceChildrenIds }).then(() => {
            this.isLoading = false;
          });
        }
      }
    },
    canShowStatus(child) {
      switch (child.status) {
        case presenceStates.SPARE_TIME_ACTIVITY:
          return (
            this.getAllPresenceModules[presenceModules.SPARE_TIME_ACTIVITY].indexOf(child.institutionProfile.id) > -1
          );
        default:
          return this.presenceStatusText[child.status] != null;
      }
    },
    getInstitutionSpecificOpenHours(child) {
      if (this.isSpecificOpenHours(child)) {
        const institutionSpecificOpenHours = this.getOpenHours.openingHoursOverviewDto.find(
          institution => institution.institutionCode == child.institutionProfile.institutionCode
        );
        return `${institutionSpecificOpenHours.openingHoursDto[0].openTime} - ${institutionSpecificOpenHours.openingHoursDto[0].closeTime}`;
      }
    },
    isSpecificOpenHours(child) {
      if (this.getOpenHours) {
        const institutionSpecificOpenHours = this.getOpenHours.openingHoursOverviewDto.find(
          institution => institution.institutionCode == child.institutionProfile.institutionCode
        );

        if (institutionSpecificOpenHours?.openingHoursDto?.length > 0) {
          const instituionOpenHoursType = institutionSpecificOpenHours.openingHoursDto[0].type;
          if (instituionOpenHoursType === presenceOpenHours.SPECIFIC_OPEN_HOURS) {
            return true;
          }
        }

        return false;
      }
    },
    loadClosedDaysForToday() {
      const activeChildInstitutions = this.children.filter(child => this.activeChildren.includes(child.id));
      let childInstitionCodeList = activeChildInstitutions.map(child => child.institutionCode);
      // Filter out the duplicated institution codes because BE does not do it
      childInstitionCodeList = childInstitionCodeList.filter(function (item, pos) {
        return childInstitionCodeList.indexOf(item) == pos;
      });

      this.isLoading = true;
      this.loadHolidayClosedDays({
        institutionCodes: childInstitionCodeList,
      }).then(() => {
        this.isLoading = false;
      });
    },
    todayClosedDaysString(institutionCode) {
      const institutionClosedDays = this.holidayAndClosedDays.find(date => date.institutionCode === institutionCode);
      if (institutionClosedDays) {
        const today = moment().format('YYYY-MM-DD');
        const isClosedDay = institutionClosedDays.closedDaysOverview.closedDays.find(date => {
          const momentStartDate = moment(date.startDate);
          const momentEndDate = moment(date.endDate);

          return moment(today).isBetween(momentStartDate, momentEndDate, undefined, '[]');
        });

        if (isClosedDay) {
          return isClosedDay.name;
        }

        return null;
      }
    },
    isTodayClosedDays(institutionCode) {
      const institutionClosedDays = this.holidayAndClosedDays.find(date => date.institutionCode === institutionCode);
      if (institutionClosedDays) {
        const today = moment().format('YYYY-MM-DD');
        const isClosedDay = institutionClosedDays.closedDaysOverview.closedDays.find(date => {
          const momentStartDate = moment(date.startDate);
          const momentEndDate = moment(date.endDate);

          return moment(today).isBetween(momentStartDate, momentEndDate, undefined, '[]');
        });

        if (isClosedDay) {
          return true;
        }

        return false;
      }

      return false;
    },
  },
  watch: {
    activeChildren() {
      this.loadPresenceStateByChildIds();
      this.loadClosedDaysForToday();
    },
  },
  mounted() {
    this.loadDailyOverviewByChildIds();
    this.loadClosedDaysForToday();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/presence/_presence.scss';
</style>
