<template>
  <nav id="aula-menu" v-track-element-height class="aula-menu" @heightUpdate="setMenuHeight">
    <template v-for="(item, i) in primaryMenus">
      <a
        v-if="item.type === moduleTypes.ADMINISTRATION || item.type === moduleTypes.OTP_ADMINISTRATION"
        :key="i"
        v-track-element-height
        :aria-label="getScreenReaderLabel(item)"
        class="menu-link stepup-menu"
        tabindex="0"
        @click="menuClick(item)"
        @keydown.enter.space="menuClick(item)"
        @heightUpdate="setMenuItemHeight"
      >
        <menu-item aria-hidden="true" :item="item" :badges="calculateBadges(item.type)" @menuClick="menuClick" />
      </a>
      <a
        v-else
        :key="i"
        v-track-element-height
        class="menu-link"
        :class="{
          'router-link-active': getIsLinkActive(item) && !isMoreMenuOpened,
        }"
        :aria-label="getScreenReaderLabel(item)"
        tabindex="0"
        @click="menuClick(item)"
        @keydown.enter.space="menuClick(item)"
        @heightUpdate="setMenuItemHeight"
      >
        <menu-item aria-hidden="true" :item="item" :badges="getBadgeCountForMenu(item)" @menuClick="menuClick" />
      </a>
    </template>
    <AulaButton
      v-if="showMoreMenuButton"
      v-track-element-height
      class="more menu-item"
      variant="link"
      :aria-label="'NAVIGATE_MORE_LINKS' | fromTextKey"
      @focus="removeHiddenClass"
      @mouseover="removeHiddenClass"
      @mouseout="addHiddenClass"
      @click="removeHiddenClass"
      @heightUpdate="setMoreMenuButtonHeight"
    >
      <span class="sr-only">{{ 'NAVIGATE_MORE_LINKS' | fromTextKey }}</span>
      <div class="position-relative" aria-hidden="true">
        <badge-notification
          v-if="hasNotificationsOnMoreMenu"
          css="more-menu-badge"
          :show-exclamation-icon-instead-of-amount="true"
        />
        <i class="icon-Aula_elipses" />
      </div>
    </AulaButton>
    <div id="more-menu" class="scrollbar hidden" @mouseover="removeHiddenClass" @mouseout="addHiddenClass">
      <template v-for="(item, i) in moreMenus">
        <MoreMenuItem
          :key="i"
          :menu-item="item"
          :badge-count="getBadgeCountForMenu(item)"
          :aria-label="getScreenReaderLabel(item)"
          tabindex="0"
          role="link"
          @click="menuClick(item)"
          @keydown.enter.space="menuClick(item)"
          @focusout="i == moreMenus.length - 1 ? addHiddenClass() : ''"
        />
      </template>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { types } from '../../store/types/types';
import BadgeNotification from '../../../shared/components/BadgeNotification.vue';
import MenuItem from './MenuItem.vue';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import { notificationAreas } from '../../../shared/enums/notificationAreas';
import { notificationTypes } from '../../../shared/enums/notificationTypes';
import { moduleWidgetPlacements } from '../../../shared/enums/moduleWidgetPlacements';
import { moduleTypes } from '../../../shared/enums/moduleTypes';
import $ from 'jquery';
import { portalRoles } from '../../../shared/enums/portalRoles';
import TrackElementHeight from '../../../shared/directives/track-element-height';
import MoreMenuItem from '../../../shared/PageFragments/MenuFragments/MoreMenuItem.vue';
import { menuUtil } from '../../../shared/utils/menuUtil';
import AulaButton from '../../../shared/components/AulaButton.vue';

export default {
  directives: {
    TrackElementHeight,
  },
  data: function () {
    return {
      moduleTypes,
      isMoreMenuOpened: false,
      hideMoreMenuTimeoutKey: null,
      menuHeight: 0,
      menuItemHeight: 0,
      moreMenuButtonHeight: 0,
    };
  },
  computed: {
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
      isImpersonationMode: types.GET_IS_IMPERSONATION_MODE,
      menuPlacementDictionary: types.GET_MENU_ITEMS,
      institutions: types.GET_INSTITUTIONS,
      profile: types.GET_CURRENT_PROFILE,
      notifications: types.GET_NOTIFICATIONS,
      activeChildren: types.GET_ACTIVE_CHILDREN,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
      hasPermission: types.HAS_PERMISSION,
      isSteppedUp: types.GET_GLOBAL_STEPPED_UP,
      pageConfiguration: types.GET_PAGE_CONFIGURATION,
    }),
    menuItems() {
      return menuUtil.getMenuItemsList(this.menuPlacementDictionary, [
        moduleWidgetPlacements.DEFAULT,
        moduleWidgetPlacements.LEFT_MENU,
        moduleWidgetPlacements.OWN_PAGE,
      ]);
    },
    menus() {
      const menuItems = [...this.menuItems];
      const { supportRole, isGroupHomeAdmin } = this.profile;
      const menus = menuItems.filter(menu => {
        const isAdministrationMenu = menuUtil.getIsAdministrationMenu(menu);
        const isOTPAdministrationMenu = menuUtil.getIsOTPAdministrationMenu(menu);
        const isAdministrativeMenu = menuUtil.getIsAdministrativeMenu(menu);
        const hasPermission = this.checkPermission(menu);
        let addToMenu = true;

        if (this.isMobile && isAdministrativeMenu) {
          addToMenu = false;
        } else if (isAdministrationMenu) {
          addToMenu = hasPermission || supportRole;
        } else if (isOTPAdministrationMenu) {
          addToMenu = isGroupHomeAdmin;
        }

        return addToMenu;
      });

      return menuUtil.getSortedMenuItems(menus);
    },
    menuAmount() {
      const menuItemHeight = this.menuItemHeight;
      const moreMenuButtonHeight = this.moreMenuButtonHeight;
      const menuHeight = this.menuHeight - moreMenuButtonHeight;
      if (menuHeight === 0 || menuItemHeight === 0) {
        return this.menus.length;
      }
      let amount = Math.floor(menuHeight / menuItemHeight);
      if (this.isMobile) {
        if (this.menus.length <= 4) {
          amount = 4;
        } else {
          amount = 3;
        }
      }
      return amount;
    },
    hasNotificationsOnMoreMenu() {
      const hasModuleNotifications = this.moreMenus.some(moreMenu => this.calculateBadges(moreMenu.type) > 0);
      return Boolean(hasModuleNotifications || this.hasWidgetNotifications);
    },
    hasWidgetNotifications() {
      const menuWidgetIds = this.moreMenus.filter(item => item.widgetId != null).map(item => item.id);
      return this.notifications.some(
        notification =>
          notification.notificationType === notificationTypes.BADGE && menuWidgetIds.includes(notification.widgetId)
      );
    },
    primaryMenus() {
      return this.menus.slice(0, this.menuAmount);
    },
    moreMenus() {
      return this.menus.slice(this.menuAmount);
    },
    showMoreMenuButton() {
      return this.moreMenus.length > 0;
    },
  },
  methods: {
    ...mapActions({
      toggleHeaderDropdown: types.TOGGLE_HEADER_DROPDOWN,
      deleteNotifications: types.DELETE_NOTIFICATIONS,
      loadNotifications: types.LOAD_NOTIFICATIONS,
    }),
    ...mapMutations({
      setStepUpNotification: types.MUTATE_SET_NOTIFICATION_STEP_UP,
    }),
    getIsLinkActive(item) {
      let path = item.url;
      if (item.widgetId) {
        path = '/widgets/W' + item.widgetId + 'V' + item.widgetVersion;
      }

      return this.$route.path.match(`^${path}`);
    },
    setMenuHeight(height) {
      this.menuHeight = height;
    },
    setMenuItemHeight(height) {
      this.menuItemHeight = height;
    },
    setMoreMenuButtonHeight(height) {
      this.moreMenuButtonHeight = height;
    },
    getBadgeCountForMenu(item) {
      if (item.widgetId) {
        return this.getWidgetBadgeCount(item.id);
      }
      return this.calculateBadges(item.type);
    },
    getWidgetBadgeCount(widgetId) {
      return this.notifications.filter(
        notification => notification.notificationType === notificationTypes.BADGE && notification.widgetId === widgetId
      ).length;
    },
    getHasWidgetNotification(notification, notificationArea) {
      const widgetConfigurations = this.getWidgetConfigurations(notification.widgetId);
      let widgetPlacements = [];
      if (notificationArea === notificationAreas.CALENDAR) {
        widgetPlacements = [moduleWidgetPlacements.RIGHT_OF_CALENDAR, moduleWidgetPlacements.BELOW_OF_CALENDAR];
      }
      if (notificationArea === notificationAreas.POSTS) {
        widgetPlacements = [moduleWidgetPlacements.RIGHT_OF_OVERVIEW];
      }
      return widgetConfigurations.some(config => widgetPlacements.includes(config.placement));
    },
    getWidgetConfigurations(widgetId) {
      return this.pageConfiguration.widgetConfigurations.filter(config => config.widget.id === widgetId);
    },
    calculateBadges(module) {
      let amount = 0;
      const notificationArea = menuUtil.getNotificationAreaFromModule(module);
      if (notificationArea == null) {
        return amount;
      }
      const albumsWithNotification = [];
      for (const notification of this.notifications) {
        if (this.getHasWidgetNotification(notification, notificationArea)) {
          amount++;
          continue;
        }
        if (
          notification.notificationArea === notificationArea &&
          notification.notificationType === notificationTypes.BADGE
        ) {
          const institution = this.institutions.find(
            inst => inst.institutionProfileId == notification.institutionProfileId
          );
          if (
            institution == null ||
            this.activeInstitutions.includes(institution.institutionCode) ||
            this.profile.role === portalRoles.OTP
          ) {
            if (notificationArea === notificationAreas.GALLERY) {
              if (albumsWithNotification.indexOf(notification.albumId) === -1) {
                albumsWithNotification.push(notification.albumId);
                amount++;
              }
            } else {
              amount++;
            }
          }
        }
      }
      return amount;
    },
    checkPermission(item) {
      if (item.type === moduleTypes.ADMINISTRATION) {
        if (this.hasPermission(permissionEnum.ADMIN_MODULE)) {
          return true;
        } else {
          return false;
        }
      } else {
        if (Array.isArray(item.permissions)) {
          for (const permission of item.permissions) {
            if (this.hasPermission(permission)) {
              return true;
            }
          }
          return false;
        } else {
          return true;
        }
      }
    },
    checkStepUp(menu) {
      if (this.isSteppedUp) {
        if (menu.type === moduleTypes.OTP_ADMINISTRATION) {
          window.location.href = this.$options.filters.getSiteUrl('group_home_admin');
        } else {
          this.$router.push(menu.url);
        }

        if (menu.type === moduleTypes.ADMINISTRATION) {
          window.location.href = this.$options.filters.getSiteUrl('administration');
        } else {
          this.$router.push(menu.url);
        }
      } else {
        let redirectedUrl;
        if (menu.type === moduleTypes.OTP_ADMINISTRATION) {
          redirectedUrl = this.$options.filters.getSiteUrl('group_home_admin');
        } else if (menu.type === moduleTypes.ADMINISTRATION) {
          redirectedUrl = this.$options.filters.getSiteUrl('administration');
        } else {
          const router = this.$router.resolve({ path: menu.url });
          redirectedUrl = window.location.origin + '/' + router.href;
        }
        this.setStepUpNotification({
          showStepUpNotification: true,
          redirectedUrl: redirectedUrl,
        });
      }
    },
    positionOfModule(moduleType) {
      for (const index in this.menuPlacementDictionary.Default) {
        if (this.menuPlacementDictionary.Default[index].type == moduleType) {
          return index;
        }
      }
      for (const index in this.menuPlacementDictionary.LeftMenu) {
        if (this.menuPlacementDictionary.LeftMenu[index].type == moduleType) {
          return index + this.menuPlacementDictionary.Default.length;
        }
      }
      return 0;
    },
    hideDropdownMenu() {
      this.toggleHeaderDropdown({
        dropdown: '',
        visible: this.showSearchDropdown,
      });
    },
    removeHiddenClass() {
      this.clearHideMoreMenuTimeout();
      this.isMoreMenuOpened = true;
      const moreMenuSelector = '#more-menu';
      const aulaMenuSelector = '#aula-menu';
      $(moreMenuSelector).removeClass('hidden');
      if (!this.isMobile && $(moreMenuSelector).height() < $(aulaMenuSelector).height()) {
        $(moreMenuSelector).css({ 'min-height': $(aulaMenuSelector).height() + 'px' });
      }
    },
    addHiddenClass() {
      this.clearHideMoreMenuTimeout();
      this.hideMoreMenuTimeoutKey = setTimeout(() => {
        this.isMoreMenuOpened = false;
        $('#more-menu').addClass('hidden');
        this.clearHideMoreMenuTimeout();
      }, 200);
    },
    clearHideMoreMenuTimeout() {
      if (this.hideMoreMenuTimeoutKey !== null) {
        clearTimeout(this.hideMoreMenuTimeoutKey);
        this.hideMoreMenuTimeoutKey = null;
      }
    },
    async menuClick(menu) {
      $('#more-menu').addClass('hidden');
      this.hideDropdownMenu();
      if (
        menu.type === moduleTypes.ADMINISTRATION ||
        menu.type === moduleTypes.OTP_ADMINISTRATION ||
        menu.type === moduleTypes.PERSONAL_REFERENCE_DATA
      ) {
        this.checkStepUp(menu);
      } else {
        let url = menu.url;
        if (menu.widgetId) {
          url = '/widgets/W' + menu.widgetId + 'V' + menu.widgetVersion;
        }
        await this.$router.push(url);
        if (!this.$route.path.includes('kalender')) {
          this.loadNotifications({
            activeInstitutionCodes: this.activeInstitutions,
            activeChildrenIds: this.activeChildren,
          });
        }
      }
    },
    getScreenReaderLabel(menuItem) {
      const lowercasedMenuName = menuItem.name.toLowerCase();
      const badgeCount = this.getBadgeCountForMenu(menuItem);
      let label = this.$options.filters.fromTextKey('NAVIGATE_TO') + lowercasedMenuName;
      if (badgeCount > 0) {
        label = this.$options.filters.fromTextKey('BADGE_COUNT_NAVIGATE_TO', {
          count: badgeCount,
          destination: lowercasedMenuName,
        });
      }
      return label;
    },
  },
  watch: {
    showMoreMenuButton(show) {
      if (show === false) {
        this.moreMenuButtonHeight = 0;
      }
    },
  },
  components: {
    AulaButton,
    MoreMenuItem,
    BadgeNotification,
    MenuItem,
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/breakpoints.scss';
.early-student .aula-menu .menu-item {
  --aula-badge-box-shadow: none;
}

.hidden {
  display: none !important;
}

.aula-menu {
  .early-student & {
    background: linear-gradient(180deg, #549ec7 0%, #45b7c1 100%);
  }

  .menu-link {
    /deep/ .menu-item {
      .early-student & {
        img.menu-icon {
          transition: transform 0.1s ease-in-out;

          @include breakpoint-lg-down() {
            --menu-icon-size: 22px;
          }
        }

        @include breakpoint-lg-down() {
          padding-bottom: 1px;
          justify-content: flex-end;
        }
      }
    }

    /deep/ .menu-item:hover {
      .early-student & {
        background-color: transparent !important;
        img.menu-icon {
          transform: scale(1.2);
        }
      }
    }
  }

  .menu-link:hover {
    .early-student & {
      background-color: transparent;
    }
  }

  .menu-link.router-link-active {
    --aula-badge-box-shadow-color: var(--color-primary-darker);

    /deep/ .menu-item {
      .early-student & {
        background-color: rgba(0, 0, 0, 0.3) !important;
      }
    }
  }

  .menu-item.more {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    font-size: 20px;

    .early-student & {
      background-color: transparent;
    }

    &:hover,
    &:focus-visible {
      .early-student & {
        background-color: transparent !important;
      }
    }

    .icon-Aula_elipses {
      .early-student & {
        color: var(--color-white);
      }
    }
  }
}
</style>
