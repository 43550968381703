<template>
  <div>
    <div id="notification-clear-all-module">
      <div class="ml-3 mt-2 font-weight-bold">
        {{ 'NOTIFICATION_CLEAR_FROM' | fromTextKey }}
      </div>
      <aula-list class="aula-list">
        <aula-list-item
          v-for="(module, index) in availableAreas"
          :key="index"
          class="aula-list-item module-item pl-1 ml-3 mt-2"
          :label="module.name"
          :value="module.value"
        >
          <template #before>
            <b-form-checkbox
              :key="index"
              :label="module.name"
              :class="{ 'with-checkbox': true }"
              @change="updateSelectedAreas(module.value, $event)"
            >
              {{ module.name }}
            </b-form-checkbox>
          </template>
        </aula-list-item>
      </aula-list>
    </div>
  </div>
</template>

<script>
import { NotificationSetting } from '../../../src/business/notificationSetting';
import AulaList from '../../components/AulaList';
import AulaListItem from '../../components/AulaListItem';

export default {
  name: 'NotificationAreaClearMobile',
  components: { AulaListItem, AulaList },
  props: {
    availableAreas: { type: Array, default: () => [] },
    notificationSettingModel: { type: NotificationSetting, default: null },
  },
  methods: {
    updateSelectedAreas(moduleValue, value) {
      this.$emit('updateAreaDictionary', moduleValue, value);
    },
  },
};
</script>

<style lang="scss" scoped>
.aula-list-item {
  padding: 14px 24px;
  border-bottom-width: 1px;
}

.aula-list {
  padding: 0;
}

.module-item {
  --border-bottom-color: var(--color-grey-darker);
}
</style>
