<template>
  <ContactsFragmentNoResult v-if="contacts.length === 0" />
  <TableContainer v-else class="table">
    <TableHead class="head">
      <TableRow>
        <TableHeading class="color-grey-base heading">
          <ContactsFragmentSorting
            :direction="orderDirection"
            :sort-by="contactSortingTypes.NAME"
            :sorting-by="sortBy"
            @sort="onSortBy"
          >
            {{ 'CONTACT_NAME' | fromTextKey }}
          </ContactsFragmentSorting>
        </TableHeading>
        <TableHeading class="color-grey-base heading">
          <ContactsFragmentSorting
            :direction="orderDirection"
            :sort-by="contactSortingTypes.BIRTHDAY"
            :sorting-by="sortBy"
            @sort="onSortBy"
          >
            {{ 'SORT_BY_BIRTHDAY' | fromTextKey }}
          </ContactsFragmentSorting>
        </TableHeading>
      </TableRow>
    </TableHead>
    <TableBody class="body">
      <TableRow v-for="contact in contacts" :key="contact.id + '_' + contact.fullName">
        <TableCell class="contact-name-cell cell">
          {{ contact | displayProfileNameWithMetadata }}
        </TableCell>
        <TableCell class="cell birthday-cell">
          {{ getBirthday(contact) }}
        </TableCell>
      </TableRow>
    </TableBody>
  </TableContainer>
</template>
<script lang="js">

import TableCell from "../../libs/table/components/TableCell";
import TableBody from "../../libs/table/components/TableBody";
import ContactsFragmentSorting from "./ContactsFragmentSorting";
import TableHeading from "../../libs/table/components/TableHeading";
import TableRow from "../../libs/table/components/TableRow";
import TableHead from "../../libs/table/components/TableHead";
import TableContainer from "../../libs/table/components/TableContainer";
import {providerKeyTypes} from "../../enums/providerKeyTypes";
import {contactSortingTypes} from "../../enums/contactSortingTypes";
import ContactsFragmentNoResult from "./ContactsFragmentNoResult";
import {portalRoles} from "../../enums/portalRoles";

export default {
  name: 'ContactsFragmentDesktopBirthdayList',
  components: {
    ContactsFragmentNoResult,
    TableCell,
    TableBody,
    ContactsFragmentSorting,
    TableHeading,
    TableRow,
    TableHead,
    TableContainer,
  },
  inject: {
    getContacts: providerKeyTypes.contacts,
    getOrderDirection: providerKeyTypes.orderDirection,
    getSortBy: providerKeyTypes.sortBy,
    changeSortBy: providerKeyTypes.onSortBy,
  },
  data() {
    return {
      contactSortingTypes,
    }
  },
  computed: {
    contacts() {
      return this.getContacts();
    },
    orderDirection() {
      return this.getOrderDirection();
    },
    sortBy() {
      return this.getSortBy();
    },
  },
  methods: {
    onSortBy(sortBy) {
      this.changeSortBy(sortBy);
    },
    getBirthday(contact) {
      if (contact.birthday == null) {
        return this.$options.filters.fromTextKey('UNKNOW');
      }

      return this.$options.filters.shortDate(
        contact.birthday,
        contact.role !== portalRoles.EMPLOYEE
      );
    },
  }
};
</script>
<style lang="scss" scoped>
.contact-name-cell {
  word-break: break-all;
}

.birthday-cell {
  width: 160px;
  max-width: 160px;
}

@media print {
  .table {
    .head {
      .heading {
        font-size: 20px;
        padding: 10px;
      }
    }

    .body {
      .cell {
        padding: 10px;
        font-size: 18px;
      }
    }
  }
}
</style>
