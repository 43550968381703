<template>
  <div class="d-flex align-items-center">
    <AulaSwitch v-bind="{ ...$attrs }" ref="switchRef" :value="value" v-on="$listeners" />
    <label class="switch-label" @click="onLabelClick"><slot /></label>
  </div>
</template>
<script lang="js">
import AulaSwitch from "./AulaSwitch.vue";

export default {
  name: 'AulaSwitchButton',
  components: { AulaSwitch },
  props: {
    value: {
      required: true,
      type: Boolean,
    }
  },
  methods: {
    onLabelClick() {
      this.$refs.switchRef.$el.click();
    },
  }
}
</script>
<style lang="scss" scoped>
.switch-label {
  text-transform: none;
  font-weight: unset;
  margin-left: 10px;
  margin-bottom: 0;
}
</style>
