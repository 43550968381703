<template>
  <div id="aula-comeandgo" class="page-layout-padding guardian">
    <div class="presence-header-container">
      <alerts
        class="p-3 p-lg-0 m-0 mb-lg-4"
        :filtered-areas="[notificationAreas.PRESENCE]"
        :parent="parentTypes.PRESENCE"
      />
      <template v-if="!isMobile">
        <div class="presence-header">
          <h1 class="presence-title" tabindex="-1">
            {{ 'PRESENCE_PAGETITLE' | fromTextKey }}
          </h1>
        </div>
        <div v-if="hasPresenceChildren" class="config-sections">
          <ul class="nav mt-3">
            <li v-for="(menu, i) in comeAndGoMenus" :key="i" class="nav-item">
              <div
                class="nav-link text-uppercase d-flex"
                tabindex="0"
                role="button"
                :aria-selected="menu == defaultTab"
                :class="{ active: menu == defaultTab }"
                @click="defaultTab = menu"
                @keydown.enter="defaultTab = menu"
              >
                {{ menu | fromTextKey }}
                <badge-notification
                  v-if="tabsWithBadge.includes(menu)"
                  :amount="badgeNumber"
                  class="tab-menu-badge small ml-1"
                  border
                />
              </div>
            </li>
          </ul>
        </div>
      </template>
      <template v-else>
        <b-navbar v-if="hasPresenceChildren" class="mobile-page-navbar mb-2">
          <b-navbar-nav>
            <b-nav-item
              v-for="(menu, i) in comeAndGoMenus"
              :key="'mobile' + i"
              :class="{ active: menu == defaultTab }"
              @click="defaultTab = menu"
            >
              {{ menu | fromTextKey }}
              <badge-notification
                v-if="tabsWithBadge.includes(menu)"
                :amount="badgeNumber"
                class="tab-menu-badge small ml-1"
                border
              />
            </b-nav-item>
          </b-navbar-nav>
        </b-navbar>
      </template>
      <aula-spinner v-if="isLoading" />
      <template v-else-if="!hasPresenceChildren">
        <div class="mt-3">
          {{ 'PRESENCE_WARNING_HAS_NOT_PRESENCE_CHILD' | fromTextKey }}
        </div>
      </template>
      <template v-else>
        <daily-overview v-if="defaultTab === 'PRESENCE_DAILY_OVERVIEW'" />
        <presence-absences v-if="defaultTab === 'PRESENCE_ABSENCE_TAG'" />
        <presence-times v-if="defaultTab === 'PRESENCE_TIME_TAG'" />
        <presence-pickup-responsibles v-if="defaultTab === 'PRESENCE_PICKUP_RESPONSIBLE_TAG'" ref="pickupResponsible" />
        <opening-hours-and-closed-days v-if="defaultTab === 'PRESENCE_GENERAL_OPENING_HOURS_AND_CLOSED_DAYS'" />
      </template>
    </div>
  </div>
</template>
<script>
import { types } from '../../store/types/types';
import { mapActions, mapGetters } from 'vuex';
import PresenceTimes from './PresenceTimes';
import PresenceAbsences from './PresenceAbsences';
import DailyOverview from './DailyOverview';
import PresencePickupResponsibles from './PresencePickupResponsibles';
import { presenceModules } from '../../../shared/enums/presenceModules';
import { pickupTypes } from '../../../shared/enums/pickupTypes';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import { notificationAreas } from '../../../shared/enums/notificationAreas';
import Alerts from '../../../shared/components/Alerts';
import BadgeNotification from '../../../shared/components/BadgeNotification';
import { notificationTypes } from '../../../shared/enums/notificationTypes';
import moment from 'moment-timezone';
import OpeningHoursAndClosedDays from '../presence_shared/OpeningHoursAndClosedDays';

export default {
  data: function () {
    return {
      isLoading: false,
      defaultTab: 'PRESENCE_DAILY_OVERVIEW',
      tabsWithBadge: ['PRESENCE_ABSENCE_TAG'],
      presenceModules,
      parentTypes,
      notificationAreas,
    };
  },
  computed: {
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
      children: types.GET_CHILDREN,
      profile: types.GET_CURRENT_PROFILE,
      institutions: types.GET_INSTITUTIONS,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
      activeChildren: types.GET_ACTIVE_CHILDREN,
      getPresenceStates: types.GET_PRESENCE_STATES_GUARDIAN,
      getPresenceActiveModules: types.GET_PRESENCE_ACTIVE_MODULES,
      notifications: types.GET_NOTIFICATIONS,
      getOpenHours: types.GET_PRESENCE_OPEN_HOURS_GUARDIAN,
    }),
    hasPresenceChildren() {
      return this.getPresenceStates != null && this.getPresenceStates.length > 0;
    },
    comeAndGoMenus() {
      const menu = [];
      if (this.hasPresenceChildren) {
        menu.push('PRESENCE_DAILY_OVERVIEW');
        menu.push('PRESENCE_TIME_TAG');
        menu.push('PRESENCE_ABSENCE_TAG');
        if (
          this.getPresenceActiveModules[pickupTypes.PICKUP.key] != null &&
          this.getPresenceActiveModules[pickupTypes.PICKUP.key].length > 0
        ) {
          menu.push('PRESENCE_PICKUP_RESPONSIBLE_TAG');
        }
        menu.push('PRESENCE_GENERAL_OPENING_HOURS_AND_CLOSED_DAYS');
      }
      return menu;
    },
    badgeNumber() {
      let amount = 0;
      for (const notification of this.notifications) {
        if (
          notification.notificationArea === notificationAreas.PRESENCE &&
          notification.notificationType === notificationTypes.BADGE
        ) {
          const institution = this.institutions.find(
            inst => inst.institutionProfileId == notification.institutionProfileId
          );
          if (institution == null || this.activeInstitutions.includes(institution.institutionCode)) {
            amount++;
          }
        }
      }
      return amount;
    },
  },
  methods: {
    ...mapActions({
      loadPresenceStates: types.LOAD_PRESENCE_STATES,
      loadPresenceConfigurationByChildIds: types.LOAD_PRESENCE_CONFIGURATIONS_CHILD_IDS,
      loadNotifications: types.LOAD_NOTIFICATIONS,
      loadOpenHours: types.LOAD_PRESENCE_OPEN_HOURS_GUARDIAN_DASHBOARD,
    }),
    loadPresenceStatesByChildIds() {
      if ([portalRoles.GUARDIAN, portalRoles.OTP].indexOf(this.profile.role) > -1) {
        this.isLoading = true;
        const activeChildren = this.children.filter(child => this.activeChildren.includes(child.id));
        const childIds = activeChildren.map(child => child.id);
        this.loadPresenceStates({ institutionProfileIds: childIds }).then(() => {
          const childIds = this.getPresenceStates.map(c => c.uniStudentId);
          if (childIds.length > 0) {
            this.loadPresenceConfigurationByChildIds({
              childIds: childIds,
            }).then(() => {
              this.isLoading = false;
              if (
                (this.getPresenceActiveModules[presenceModules.REPORT_SICK] == null ||
                  this.getPresenceActiveModules[presenceModules.REPORT_SICK].length == 0) &&
                (this.getPresenceActiveModules[presenceModules.VACATION] == null ||
                  this.getPresenceActiveModules.vacation.length == 0)
              ) {
                this.defaultTab = 'PRESENCE_DAILY_OVERVIEW';
              }
            });
          } else {
            this.isLoading = false;
          }
        });
      }
    },
    loadNotificationsForOwnCalendar() {
      this.loadNotifications({
        activeChildrenIds: this.activeChildren,
        activeInstitutionCodes: this.activeInstitutions,
      });
    },
    loadPresenceHoursByChildInstitution() {
      const activeChildren = this.children.filter(child => this.activeChildren.includes(child.id));
      const childInstitutionCodeList = Array.from(new Set(activeChildren.map(child => child.institutionCode)));

      if (this.defaultTab === 'PRESENCE_DAILY_OVERVIEW') {
        const today = moment().format('YYYY-MM-DD');
        this.loadOpenHours({
          startDate: today,
          endDate: today,
          institutionCodes: childInstitutionCodeList,
        });
      }
    },
  },
  watch: {
    defaultTab() {
      this.loadPresenceHoursByChildInstitution();
    },
    activeChildren() {
      this.loadPresenceHoursByChildInstitution();
      this.loadPresenceStatesByChildIds();
    },
  },
  mounted() {
    if (this.children != null && this.children.length > 0) {
      this.loadPresenceStatesByChildIds();
      this.loadNotificationsForOwnCalendar();
      this.loadPresenceHoursByChildInstitution();
    }
    setTimeout(function () {
      document.querySelector('#aula-comeandgo .presence-title')?.focus();
    }, 500);
  },
  components: {
    OpeningHoursAndClosedDays,
    BadgeNotification,
    Alerts,
    DailyOverview,
    PresenceTimes,
    PresenceAbsences,
    PresencePickupResponsibles,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/presence/_presence.scss';

.tab-menu-badge {
  --position: static;
}
</style>
