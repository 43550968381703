<template>
  <div class="background">
    <div class="aula-onboarding">
      <b-container>
        <b-row class="header noaccess">
          <b-col cols="12" class="aula-logo">
            <div class="logo">
              <i class="icon-Logo" aria-hidden="true" />
            </div>
          </b-col>
        </b-row>
        <b-row class="onboarding noaccess">
          <b-col cols="12" class="p-0 onboarding-wrapper">
            <div class="onboard-content">
              <h1>{{ noaccessTitle }}</h1>
              <a href="/auth/logout.php">{{ noaccessLink }}</a>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  data() {
    return {
      noaccessTitle: null,
      noaccessLink: null,
    };
  },
  mounted() {
    const type = this.$route.query.type;
    switch (type) {
      case 'unauthorized':
        document.title = Vue.filter('fromTextKey')('UNAUTHORIZED_PAGE_TITLE');
        this.noaccessTitle = Vue.filter('fromTextKey')('UNAUTHORIZED_TITLE');
        this.noaccessLink = Vue.filter('fromTextKey')('UNAUTHORIZED_LINK');
        break;
      case 'deactivatedUser':
        document.title = Vue.filter('fromTextKey')('DEACTIVATED_USER_TITLE');
        this.noaccessTitle = Vue.filter('fromTextKey')('DEACTIVATED_USER_TITLE');
        this.noaccessLink = Vue.filter('fromTextKey')('NO_ACCESS');
        break;
      case 'accessNotGrantedYet':
        document.title = Vue.filter('fromTextKey')('ACCESS_NOT_GRANTED_YET_PAGE_TITLE');
        this.noaccessTitle = Vue.filter('fromTextKey')('ACCESS_NOT_GRANTED_YET_TITLE');
        this.noaccessLink = Vue.filter('fromTextKey')('ACCESS_NOT_GRANTED_YET_LINK');
        break;
      case 'missingUserSystemRole':
        document.title = Vue.filter('fromTextKey')('MISSING_USER_SYSTEM_ROLE_PAGE_TITLE');
        this.noaccessTitle = Vue.filter('fromTextKey')('MISSING_USER_SYSTEM_ROLE_TITLE');
        this.noaccessLink = Vue.filter('fromTextKey')('MISSING_USER_SYSTEM_ROLE_LINK');
        break;
      default:
        document.title = Vue.filter('fromTextKey')('NO_ACCESS_PAGE_TITLE');
        this.noaccessTitle = Vue.filter('fromTextKey')('COULD_NOT_LOGIN');
        this.noaccessLink = Vue.filter('fromTextKey')('NO_ACCESS');
        break;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../assets/scss/core/variables.scss';
@import '../../assets/scss/core/breakpoints.scss';
@import '../../assets/scss/components/login/onboarding.scss';
</style>
